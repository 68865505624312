import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import { formatDimensionedQuantity } from './formatDimensionedQuantity';
import { ChargeTimeStat, RuntimeStat } from './types';

interface Props extends BoxProps {
    /**
     * The time to charge the batteries from 5% -> 95%
     */
    dcChargeTime: ChargeTimeStat;

    /**
     * The example system running times based with avergate loading
     */
    exampleRuntimes: RuntimeStat[];
}

/**
 * Responsible for displaying system runtime characteristics
 */
export default function DeviceOperationDetails({ dcChargeTime, exampleRuntimes, ...props }: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Box {...props}>
            <Text as="p" mb={4}>
                {t('renter_reservation_form.runtime_description')}
            </Text>

            <Box mb={2}>
                {exampleRuntimes.map(x => (
                    <Text as="p" key={x.value} color="secondary">{`${formatDimensionedQuantity(
                        x.averageLoad,
                    )} = ${formatDimensionedQuantity(x)}`}</Text>
                ))}
            </Box>

            <Text as="p" mb={4}>
                {t('renter_reservation_form.dc_charge_description', {
                    chargeInput: formatDimensionedQuantity(dcChargeTime.chargePower),
                    value: formatDimensionedQuantity(dcChargeTime),
                })}
            </Text>

            <Text as="p" color="secondary" maxWidth={350}>
                {t('renter_reservation_form.service_note')}
            </Text>
        </Box>
    );
}
