import { Fragment, ReactElement } from 'react';
import { DateTime } from 'luxon';
import { Bar, Cell, YAxis } from 'recharts';

import { resolveDateTime } from 'app/core/date-time';
import makeSequence from 'app/core/makeSequence';
import { ResolvableDateTime } from 'app/core/types';

import { Theme } from 'styles/theme';

import { CHART_MIN_BAR_WIDTH, getBarStyle } from '../config';
import { FaultGroup } from '../types';

interface Props {
    start?: ResolvableDateTime;
    isSpacer?: boolean;
    maxFaults: number;
    data?: FaultGroup[];
    theme: Theme;
}

/**
 * Render function to layout and style bars in the bar chart for historical faults chart.
 * Note: this needs to be a render function for ReCharts to work properly.
 */
export default function renderBars({ start, isSpacer, maxFaults, data, theme }: Props): ReactElement[] {
    const _start = resolveDateTime(start);
    const pillBorderRadius = parseInt(theme.shape.pillBorderRadius + '', 10);

    return makeSequence({ end: maxFaults }).map(x => (
        <Fragment key={`bar-f${x}`}>
            {/*
             * HACK(Derek): These additional hidden yAxis are here to enable the bars to stack without
             *   recharts collapsing the horizontal space between them.
             */}
            <YAxis key={`y-axis-f${x}`} dataKey="name" yAxisId={`f${x}`} hide={true} type="category" />

            <Bar
                minPointSize={CHART_MIN_BAR_WIDTH}
                maxBarSize={20}
                dataKey={`f${x}`}
                yAxisId={`f${x}`}
                isAnimationActive={false}
            >
                {(data ?? []).map(faultHistory => {
                    const targetFault = faultHistory?.faults?.[x];
                    const isEndClipped = !targetFault?.resolved;
                    const isStartClipped = DateTime.fromISO(targetFault?.firstSeen) < _start;
                    const resolvedStyle = isSpacer
                        ? { fill: 'transparent' }
                        : getBarStyle({ theme })[faultHistory?.severity ?? ''];

                    return (
                        <Cell
                            key={`${faultHistory.name}-f${x}-cell-${targetFault?.firstSeen}-${targetFault?.resolved}`}
                            {...resolvedStyle}
                            radius={[
                                isStartClipped ? 0 : pillBorderRadius,
                                isEndClipped ? 0 : pillBorderRadius,
                                isEndClipped ? 0 : pillBorderRadius,
                                isStartClipped ? 0 : pillBorderRadius,
                            ]}
                        />
                    );
                })}
            </Bar>
        </Fragment>
    ));
}
