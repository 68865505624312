import { ISO8601 } from 'app/core/types';

import { GetAccountQueryHookResult, useGetAccountQuery } from 'generated/graphql';

import { User } from '../types';
import { PartialAccount } from './types';

/**
 * Account for admin account page
 */
export interface Account extends Omit<PartialAccount, 'billingAddressLocation'> {
    moxionRepID?: string;
    pointOfContact?: string;
    billingAddress?: PartialAccount['billingAddressLocation'];
    moxionInternalNotes?: string;
    teamMembers: User[];
    creationDate: ISO8601;
    netsuiteCustomerIDs: string[];
}

function extractAccountFromData(data: GetAccountQueryHookResult['data'], searchTerm?: string): Account | undefined {
    if (!data) {
        return undefined;
    }

    const baseAccount = data.getAccount;
    const users = data.listUsers.edges.map(({ node }) => node);

    const teamMembers: User[] = users.map(user => ({
        ...user,
        userID: user.id,
        isInvitePending: false,
        status: user.status,
        roles: user.roles.map(role => role.name),
        accountID: baseAccount.id,
        accountName: baseAccount.name ?? '',
        name: user.name ?? undefined, // NOTE(will): invited users may not have a name; should we fall back to 'invited user' or their email?
    }));

    const filteredTeamMembers = teamMembers.filter(x =>
        new RegExp(searchTerm ?? '', 'ig').test((x.name ?? '') + x.email),
    );

    const moxionRepID = baseAccount.moxionReps?.[0]?.id;

    return {
        ...baseAccount,
        name: baseAccount.name,
        moxionRepID,
        pointOfContact: baseAccount.pointOfContact ?? undefined,
        billingAddress: baseAccount.billingAddressLocation ?? undefined,
        moxionInternalNotes: baseAccount.moxionInternalNotes ?? undefined,
        teamMembers: filteredTeamMembers,
        creationDate: baseAccount.createdAt,
        netsuiteCustomerIDs: (baseAccount.netsuiteCustomers ?? []).map(customer => customer.id),
    };
}

export default function useAccount(
    accountID: string,
    searchTerm?: string,
): {
    account: Account | undefined;
    isAccountLoading: boolean;
    hasError: boolean;
    forbiddenFields: string[];
    refetchAccount: GetAccountQueryHookResult['refetch'];
} {
    const {
        data,
        loading: isAccountLoading,
        error: accountError,
        refetch: refetchAccount,
    } = useGetAccountQuery({
        errorPolicy: 'all',
        variables: {
            id: accountID,
        },
    });

    // Moxion internal notes is a fobidden field for any user not associated with the moxion power account.
    // This filters out the forbidden field error as it is currently, however we need to ensure this is type of error
    // and the shape of it is a known part of the contract with the backend. OR! have the backend not throw and return a "null" value
    // I'm not sure we need to know the field was forbidden
    const gqlErrors = accountError?.graphQLErrors.filter(error => !/^forbidden$/i.test(error.message)) ?? [];

    function toAccountFieldName(gqlFieldPath: string): string {
        if (gqlFieldPath === 'getAccount.moxionInternalNotes') return 'moxionInternalNotes';

        return '';
    }

    const forbiddenFields = (accountError?.graphQLErrors.filter(error => /^forbidden$/i.test(error.message)) ?? []).map(
        x => toAccountFieldName((x.path ?? []).join('.')),
    );

    const account: Account | undefined = extractAccountFromData(data, searchTerm);

    return {
        account,
        isAccountLoading,
        hasError: gqlErrors.length > 0 || (accountError?.clientErrors?.length ?? 0) > 0 || !!accountError?.networkError,
        refetchAccount,
        forbiddenFields,
    };
}
