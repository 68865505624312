import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { Button, MpuSchedule, UnitPageLayout, UnitsMap, UnitStatus } from 'app/components';
import { DeviceInstance } from 'app/core/data';
import { MPUDetailSectionID } from 'app/core/Navigation/constants';
import getPathByName from 'app/core/Navigation/getPathByName';

import { RelativeTime, useI18n } from 'i18n';

import UpdateUnitForm from './EditMPUDetailsForm';

interface Props {
    accountID?: string;
    deviceInstance?: DeviceInstance;
    isLoading?: boolean;
    isNotFound?: boolean;

    buildDetails?: ReactElement | null;

    activeFaultCount: number;
    activeFaults: ReactElement;

    historicalSummary: ReactElement;

    historicalFaults: ReactElement;

    editMPUDetailsCTA?: ReactElement;

    enableMPUDelete?: boolean;
    enableMPUEdit?: boolean;
    enableRentalManagement?: boolean;

    onDeleteDevice: () => void;
}

export default function PageLayout({
    accountID,
    deviceInstance,
    buildDetails,
    isLoading,
    isNotFound,
    activeFaultCount,
    activeFaults,
    historicalFaults,
    historicalSummary,
    editMPUDetailsCTA,
    enableMPUDelete = false,
    enableMPUEdit = false,
    enableRentalManagement = false,
    onDeleteDevice,
}: Props): ReactElement {
    const { t } = useI18n();

    return (
        <UnitPageLayout
            isLoading={isLoading}
            isNotFound={isNotFound}
            exitDest={getPathByName('MPUS_INDEX')}
            activeFaultCount={activeFaultCount}
            mpuName={deviceInstance?.name}
        >
            <UnitPageLayout.ContentStack>
                <UnitPageLayout.Section
                    heading={t('unit_page.heading.basic_info')}
                    cta={enableMPUEdit ? editMPUDetailsCTA : null}
                    id={MPUDetailSectionID.Details}
                    loading={isLoading}
                >
                    <UpdateUnitForm
                        accountID={accountID}
                        unit={deviceInstance}
                        readOnly
                        hideSaveCTA
                        excludeFields={enableRentalManagement ? [] : ['serviceAreaID', 'assetID']}
                    />
                </UnitPageLayout.Section>

                {buildDetails && (
                    <UnitPageLayout.Section
                        heading={t('admin_unit_page.build_detail.section_title')}
                        id={MPUDetailSectionID.BuildDetails}
                    >
                        {buildDetails}
                    </UnitPageLayout.Section>
                )}

                {enableRentalManagement && deviceInstance && (
                    <UnitPageLayout.Section heading={t('unit_page.heading.schedule')} id={MPUDetailSectionID.Schedule}>
                        <Box p={2} sx={{ minHeight: 300 }}>
                            <MpuSchedule deviceInstanceID={deviceInstance?.id} />
                        </Box>
                    </UnitPageLayout.Section>
                )}

                <UnitsMap id={MPUDetailSectionID.Map} units={deviceInstance ? [deviceInstance] : []} />

                <UnitPageLayout.Section
                    id={MPUDetailSectionID.Status}
                    heading={t('unit_page.heading.status')}
                    subheading={t('unit_page.unit_status_last_updated', {
                        dateTime: (
                            <RelativeTime
                                key="unit-status-update-timestamp"
                                time={deviceInstance?.latestDataTimestamp}
                            />
                        ),
                    })}
                >
                    <Box mb={5}>{activeFaults}</Box>

                    <UnitStatus deviceInstanceID={deviceInstance?.id} />
                </UnitPageLayout.Section>

                {historicalSummary}

                {historicalFaults}

                {enableMPUDelete && (
                    <Box sx={{ mt: 4 }}>
                        <Button color="danger" ctaType="secondary" onClick={onDeleteDevice}>
                            {t('admin_unit_page.cta.delete')}
                        </Button>
                    </Box>
                )}
            </UnitPageLayout.ContentStack>
        </UnitPageLayout>
    );
}
