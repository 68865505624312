import { useFormik } from 'formik';
import { object, string } from 'yup';

import { Stack } from '@mui/material';

import { AccountLocationSelector } from 'app/components';

import { ReservationItemUpdateInput } from 'generated/graphql';

import { useI18n } from 'i18n';

interface EditAddressesFormProps {
    formID: string;
    reservationItemID: string;
    deliveryLocationID?: string;
    pickupLocationID?: string;
    onSubmit: (values: ReservationItemUpdateInput) => void;
    accountID: string;
}

export default function EditAddressesForm({
    formID,
    reservationItemID,
    deliveryLocationID,
    pickupLocationID,
    onSubmit,
    accountID,
}: EditAddressesFormProps) {
    const { t } = useI18n();

    const form = useFormik<ReservationItemUpdateInput>({
        enableReinitialize: true,

        initialValues: {
            id: reservationItemID,
            deliveryLocationID,
            pickupLocationID,
        },

        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: object().shape({
            id: string().required(),
            deliveryLocationID: string().required(),
            pickupLocationID: string().required(),
        }),

        onSubmit,
    });

    return (
        <Stack component="form" onSubmit={form.handleSubmit} id={formID} gap={6}>
            <AccountLocationSelector
                value={form.values.deliveryLocationID}
                name="deliveryLocationID"
                onChange={e => form.setFieldValue('deliveryLocationID', e.value?.id)}
                fieldName={t('admin_reservation_page.addresses_modal.label.delivery_location')}
                accountID={accountID}
                showIcon
            />

            <AccountLocationSelector
                value={form.values.pickupLocationID}
                name="pickupLocationID"
                onChange={e => form.setFieldValue('pickupLocationID', e.value?.id)}
                fieldName={t('admin_reservation_page.addresses_modal.label.pickup_location')}
                accountID={accountID}
                showIcon
            />
        </Stack>
    );
}
