import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { ReactComponent as ZeroStateIllustration } from 'app/assets/illustrations/moxion-construction-site.svg';
import Text from 'app/components/primitives/Text';

interface Props {
    cta?: ReactElement;
    title?: ReactElement | string;
    message?: ReactElement | string;
}

/**
 * Layout component for common zero state, error or other cases that help inform the user of the state they're in generally at a page level.
 */
const ZeroState = ({ title, message, cta }: Props): ReactElement => (
    <Box
        sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}
    >
        <Box sx={{ width: { xs: '80%', md: '50%' }, mb: 5 }}>
            <ZeroStateIllustration />
        </Box>

        <Box maxWidth={600}>
            {!!title && (
                <Text variant="h3" as="div" gutterBottom>
                    {title}
                </Text>
            )}

            {!!message && (
                <Text as="p" gutterBottom>
                    {message}
                </Text>
            )}

            {!!cta && <Box>{cta}</Box>}
        </Box>
    </Box>
);

export default ZeroState;
