import { GetTeamMembersForSettingsQuery, useGetTeamMembersForSettingsQuery } from 'generated/graphql';

import { TeamMember } from './types';

export const formatTeamMembers = (teamResult?: GetTeamMembersForSettingsQuery['getAccount']): TeamMember[] => {
    return (teamResult?.users ?? []).map((x): TeamMember => {
        return {
            name: x.name ?? undefined,
            email: x.email,
            status: x.status,
        };
    });
};

export default function useAccountTeam({ accountID }) {
    const { loading, error, data, refetch } = useGetTeamMembersForSettingsQuery({ variables: { id: accountID } });

    return {
        isLoading: loading,
        error,
        refetch,
        members: formatTeamMembers(data?.getAccount),
    };
}
