import { ComponentProps, ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';

import { Button, ModalDialog } from 'app/components';

import { useI18n } from 'i18n';

import AddMPUForm from './AddMPUForm';

interface AddUnitDialogProps {
    /**
     * Unique identifier for the dialog for associating the
     * controlling button or other interactive UI
     */
    id: string;

    /**
     * Whether the dialog should be open or not
     */
    open: boolean;

    /**
     * Event handler to be callback when tthe dialog shoudl be closed
     */
    onClose: () => void;

    /**
     * Event handler called when user submits the form
     */
    onAddUnit: ComponentProps<typeof AddMPUForm>['onSubmit'];
}

/**
 * Displays the MPU provisioning form in a dialog
 */
export default function AddMPUDialog({ id, open = false, onClose, onAddUnit }: AddUnitDialogProps): ReactElement {
    const { t } = useI18n();

    const _t = (key: string): string => t(`admin_unit_index_page.add_mpu_dialog.${key}`);

    const dialogTitleID = useId();
    const formID = useId();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    return (
        <ModalDialog
            id={id}
            open={open}
            onClose={onClose}
            aria-labelledby={dialogTitleID}
            title={<Box id={dialogTitleID}>{_t('title')}</Box>}
            primaryCTA={
                <Button form={formID} type="submit" disabled={!isFormValid}>
                    {_t('form.submit_cta_label')}
                </Button>
            }
        >
            <AddMPUForm
                id={formID}
                hideSubmitCta
                onSubmit={async values => {
                    await onAddUnit(values);
                    onClose();
                }}
                onValidityChange={({ isValid }) => setIsFormValid(isValid)}
            />
        </ModalDialog>
    );
}
