import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Text } from 'app/components';
import { useSession } from 'app/core/Session';
import { AccountRoleName, UserRoleName } from 'app/core/Session/Session';

import useI18n from 'i18n/useI18n';

import AccountSettings from './AccountSettings/AccountSettings';
import APISettings from './APISettings/APISettings';
import { EventSubscriptions } from './EventSubscriptions';

function isValidTab(tabName) {
    return /^(profile|notifications|team)$/.test(tabName);
}

function initializeTabTo(tabName, fallback = 'profile') {
    if (!isValidTab(tabName)) return fallback;

    return tabName;
}

type SettingsTab = 'profile' | 'notifications' | 'team' | 'api';

export function ProfilePage() {
    const { t } = useI18n();
    const [searchParams, setSearchParams] = useSearchParams({ tab: 'profile' });
    const tabQueryParam = searchParams.get('tab');
    const session = useSession();

    const [activeTab, setActiveTab] = useState<SettingsTab>(initializeTabTo(tabQueryParam));
    const { id: userID, primaryAccountID: primaryUserAccountID, email: userEmail } = session.getSession();
    const showAPITab = session.hasAnyUserRoleOf([
        UserRoleName.AccountAdmin,
        UserRoleName.Account_Manager,
        UserRoleName.MoxionAdmin,
    ]);
    const showNotificationsTab = !session.hasAccountRole(AccountRoleName.Owner);

    useEffect(() => {
        setActiveTab(initializeTabTo(tabQueryParam, activeTab));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabQueryParam]);

    return (
        <>
            <Helmet>
                <title>{t('profile_page.meta.title')}</title>
                <meta name="description" content={t('profile_page.meta.description')} />
            </Helmet>

            <Text variant="h1" gutterBottom>
                {t('settings.title')}
            </Text>

            <Text variant="body" as="p" gutterBottom>
                {t('settings.description')}
            </Text>

            <Box my={4}>
                <Tabs
                    value={activeTab}
                    onChange={(_, selectedActiveTab) => {
                        setActiveTab(selectedActiveTab);
                        setSearchParams({ tab: selectedActiveTab });
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label={t('settings.screen_reader.tabs')}
                >
                    <Tab value="profile" label={t('settings.tab.profile')} />
                    {showNotificationsTab && <Tab value="notifications" label={t('settings.tab.notifications')} />}
                    <Tab value="team" label={t('settings.tab.team')} />

                    {showAPITab && <Tab value="api" label={t('settings.tab.api')} />}
                </Tabs>

                <Divider sx={{ marginTop: '-1px' }} />
            </Box>

            {activeTab === 'profile' && (
                <Box component="section" mb={6}>
                    {t('user_details.label.email')}: {userEmail}
                </Box>
            )}

            {activeTab === 'notifications' && showNotificationsTab && <EventSubscriptions userID={userID} />}

            {activeTab === 'team' && <AccountSettings accountId={primaryUserAccountID} />}

            {activeTab === 'api' && <APISettings accountID={primaryUserAccountID} />}
        </>
    );
}
