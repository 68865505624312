import { ComponentProps, ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Duration } from 'luxon';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Alert from 'app/components/primitives/Alert';
import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import { useAlerts } from 'app/core/Alert';

import { AlertSeverity } from 'generated/graphql';

import { useI18n } from 'i18n';

const ALERT_SEVERITY_TO_COLOR_MAP: { [key: string]: ComponentProps<typeof Alert>['severity'] } = {
    [AlertSeverity.Critical]: 'error',
    [AlertSeverity.Major]: 'warning',
};

const AnimatedBox = motion(Box);

const PREVIEW_COUNT = 3;

/**
 * Extends Stack to allow layout properties (i.e. "m", "mb", etc or sx props)
 * Avoid setting any non spacing related stylings
 */
interface Props extends Omit<ComponentProps<typeof Stack>, 'direction | children | spacing | useFlexGap'> {
    /**
     * The reservation ID
     */
    reservationID: string | undefined;

    /**
     * How frequently in milliseconds to retry fetching active alerts
     * @default
     */
    pollInterval?: number;

    /**
     * Event handler to notify consumers of the total alert count
     */
    onAlertCountChange?: (event: { value: number }) => void;

    /**
     * Notify when the loading state changes, because the alerts can affect page height and hence scrolling it may
     * be helpful in some circumstances to know when the element is loaded before scrolling or for any other purpose.
     */
    onLoadingChange?: (event: { value: boolean }) => void;
}

/**
 * Responsible for listing all active alerts associated with a reservation with the provided ID
 */
export default function ActiveReservationAlerts({
    reservationID,
    pollInterval = Duration.fromObject({ minutes: 5 }).toMillis(),
    onLoadingChange,
    onAlertCountChange,
    ...propsForStack
}: Props): ReactElement {
    const { t, format } = useI18n();

    const { loading: isLoading, data: activeAlerts } = useAlerts({ reservationID, pollInterval, t, format });
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const overflowCount = activeAlerts.length - PREVIEW_COUNT;
    const isOverflowed = overflowCount > 0;

    let activeAlertsToDisplay = activeAlerts;

    if (isOverflowed) {
        activeAlertsToDisplay = isExpanded ? activeAlerts : activeAlerts.slice(0, PREVIEW_COUNT);
    }

    const alertCount = activeAlerts.length;

    useEffect(() => {
        onAlertCountChange?.({
            value: alertCount,
        });
    }, [alertCount, onAlertCountChange]);

    useEffect(() => {
        onLoadingChange?.({
            value: isLoading,
        });
    }, [isLoading, onLoadingChange]);

    return (
        <Stack {...propsForStack} spacing={4}>
            <AnimatePresence initial={false}>
                {activeAlertsToDisplay.map(({ type, name, severity, timestamp, reservation, mpu, action }) => (
                    <AnimatedBox
                        key={`${type}${reservation.id}${mpu.id}`}
                        layout
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0, margin: 0 }}
                    >
                        <Alert
                            iconName="warning"
                            severity={ALERT_SEVERITY_TO_COLOR_MAP[severity]}
                            title={name}
                            message={format.dateTime(timestamp)}
                            cta={<Button to={action?.to}>{action?.label}</Button>}
                        />
                    </AnimatedBox>
                ))}
            </AnimatePresence>

            {isOverflowed && (
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        ctaType="tertiary"
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        <Stack direction="row" spacing={2}>
                            <span>
                                {isExpanded
                                    ? t('active_reservation_alerts.view_less_alerts')
                                    : t('active_reservation_alerts.view_more_alerts', { count: overflowCount })}
                            </span>
                            <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
                        </Stack>
                    </Button>
                </Box>
            )}
        </Stack>
    );
}
