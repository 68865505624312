import { ComponentPropsWithoutRef, CSSProperties, ReactElement, ReactNode } from 'react';
import { motion, Variants } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Tooltip } from 'app/components/compounds/Tooltip';
import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import Text from 'app/components/primitives/Text';
import { withProps } from 'app/components/withProps';

import spacing from 'styles/theme/spacing';

import { EXPANSION_TRANSITION_DURATION, EXPANSION_TRANSITION_EASE, EXPANSION_TRANSITION_TWEEN } from './constants';
import { useIsScheduleRowExpanded, useToggleScheduleRowExpansion } from './hooks';

const barLabelsVariants = {
    collapsed: {
        height: 0,
        marginBottom: 0,
    },
    expanded: {
        height: 'auto',
        marginBottom: spacing(4),
    },
} as const satisfies Variants;

function LabelsWrapper({ children, isExpanded }: { children: ReactNode; isExpanded: boolean }): ReactElement {
    const mode = isExpanded ? barLabelsVariants.expanded : barLabelsVariants.collapsed;

    return (
        <motion.div animate={mode} initial={mode} transition={EXPANSION_TRANSITION_TWEEN}>
            <Stack
                spacing={2}
                sx={{
                    opacity: isExpanded ? 1 : 0,
                    pointerEvents: isExpanded ? undefined : 'none',
                    transform: isExpanded ? undefined : 'translateY(-25%)',
                    transitionProperty: 'opacity, transform',
                    transitionDuration: EXPANSION_TRANSITION_DURATION,
                    transitionTimingFunction: EXPANSION_TRANSITION_EASE,
                }}
            >
                {children}
            </Stack>
        </motion.div>
    );
}

/**
 * An icon button that changes its icon based on
 * whether its associated container is expanded.
 */
export function ExpansionToggleButton({
    isExpanded,
    ...otherProps
}: { isExpanded?: boolean } & ComponentPropsWithoutRef<typeof Button>): ReactElement {
    return (
        <Button
            color="utility"
            ctaType="tertiary"
            data-testid="expansion-toggle"
            icon={
                <Icon
                    name="chevron-up"
                    sx={{
                        transform: isExpanded ? undefined : 'rotate(180deg)',
                        transitionProperty: 'transform',
                        transitionDuration: EXPANSION_TRANSITION_DURATION,
                        transitionTimingFunction: EXPANSION_TRANSITION_EASE,
                    }}
                />
            }
            size="sm"
            {...otherProps}
        />
    );
}

interface CollapsibleAsideCellProps {
    /**
     * Content to be used as labels for events in the row.
     *
     * @example
     *
     * <CollapsibleAsideCell>
     *   <CollapsibleAsideCell.Label>Pizza</CollapsibleAsideCell.Label>
     *   <CollapsibleAsideCell.Label>Ice cream</CollapsibleAsideCell.Label>
     * </CollapsibleAsideCell>
     */
    children: ReactNode;
    /**
     * Content to be rendered as a call-to-action.
     *
     * @example
     *
     * <CollapsibleAsideCell cta={<Button>Click me</Button>} />
     */
    cta: ReactNode;
    /**
     * The heading for the row. Typically a `string`.
     */
    heading: ReactNode;
    /**
     * The index of the row the cell is rendered in.
     * This is provided to consuming component by `react-window`.
     */
    index: number;
    /**
     * Content to render inside of the tooltip attached to the heading icon.
     */
    tooltip?: ReactNode;
    /**
     * Required by `react-window` to position the cell.
     * This is provided to consuming component by `react-window`.
     */
    style: CSSProperties;
}

/**
 * A reusable component for rendering collapsible cells
 * in the aside list of a `Schedule` component.
 */
export function CollapsibleAsideCell({
    children,
    cta,
    heading,
    index: rowIndex,
    style,
    tooltip,
}: CollapsibleAsideCellProps): ReactElement {
    const isExpanded = useIsScheduleRowExpanded(rowIndex);
    const handleExpansionToggleClick = useToggleScheduleRowExpansion(rowIndex);

    return (
        <Stack
            spacing={4}
            style={style}
            sx={{
                transitionProperty: 'top, height',
                transitionDuration: EXPANSION_TRANSITION_DURATION,
                transitionTimingFunction: EXPANSION_TRANSITION_EASE,
            }}
        >
            <Stack direction="row" justifyContent="space-between" pr={6}>
                <Stack alignItems="center" direction="row" justifyContent="start" spacing={2}>
                    <Text maxLines={1} variant="h5">
                        {heading}
                    </Text>
                    {tooltip && (
                        <Tooltip content={tooltip}>
                            <Icon name="information-circle" />
                        </Tooltip>
                    )}
                </Stack>
                <ExpansionToggleButton isExpanded={isExpanded} onClick={handleExpansionToggleClick} />
            </Stack>
            <Stack>
                <LabelsWrapper isExpanded={isExpanded}>{children}</LabelsWrapper>
                <Box>{cta}</Box>
            </Stack>
        </Stack>
    );
}

/**
 * For use as `children` of the `CollapsibleAsideCell` component to render labels.
 *
 * {@link CollapsibleAsideCell}
 */
CollapsibleAsideCell.Label = withProps(Text, props => ({ as: 'div', ...props } as const));
