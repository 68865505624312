import { ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';

import { Button, ModalDialog } from 'app/components';

import { useI18n } from 'i18n';

import CreateAccountForm from './CreateAccountForm';

interface CreateAccountFormInputs {
    name: string;
    industry: string;
    restrictedUserDomains: string[];
}

interface Props {
    id: string;
    open: boolean;
    onClose: () => void;
    onCreateAccount: (values: CreateAccountFormInputs) => Promise<{ id: string; name: string }>;
}

/**
 * Responsible for the creation of basic accounts and inviting users to the newly created account
 */
export default function CreateAccountDialog({
    id,
    open = false,
    onClose = () => {},
    onCreateAccount = () => Promise.resolve({ id: '', name: '' }),
}: Props): ReactElement {
    const { t } = useI18n();
    const dialogTitleID = useId();
    const formID = useId();

    const [shouldDisableCTA, setShouldDisableCTA] = useState<boolean>(false);

    return (
        <ModalDialog
            id={id}
            open={open}
            onClose={onClose}
            title={
                <Box id={dialogTitleID}>{t('admin_account_index_page.create_account_dialog.create_account_title')}</Box>
            }
            primaryCTA={
                <Button form={formID} type="submit" disabled={shouldDisableCTA}>
                    {t('admin_account_index_page.cta.create_account')}
                </Button>
            }
        >
            <CreateAccountForm
                id={formID}
                hideSubmitCTA
                onFormStateChange={({ shouldDisableCTA }) => setShouldDisableCTA(shouldDisableCTA)}
                onSubmit={async accountDetails => {
                    const savedAccountDetails = await onCreateAccount(accountDetails);

                    onClose();

                    return savedAccountDetails;
                }}
            />
        </ModalDialog>
    );
}
