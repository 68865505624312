// types
import { Box, Button, Grid } from '@mui/material';
// components
import { TableContainerProps } from '@mui/material/TableContainer';

import { Accordion, AccordionDetails, AccordionSummary } from 'app/components/compounds/Accordion';
import Icon from 'app/components/primitives/Icon';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import { APIKeyCreatedModal } from './APIKeyCreatedModal/APIKeyCreatedModal';
import { CreateAPIKeyModal } from './CreateAPIKeyModal/CreateAPIKeyModal';
import { CreateServiceAccountModal } from './CreateServiceAccountModal/CreateServiceAccountModal';
import { ServiceAccountAPIKeysTable } from './ServiceAccountAPIKeysTable/ServiceAccountAPIKeysTable';
import { ServiceAccountDetailsRow } from './ServiceAccountDetailsRow/ServiceAccountDetailsRow';
import { ServiceAccount } from './types';

interface Props extends TableContainerProps {
    serviceAccounts?: ServiceAccount[];
    onAddServiceAccount?: () => void;
    onAddAPIKey?: (serviceAccountID: string) => void;
    onAddAPIKeyConfirmed?: (serviceAccountID: string) => void;
    onClose: (event: unknown, reason: string) => void;
    onCreateServiceAccount?: (sa: { name: string }) => void;
    isCreateServiceAccountModalOpen?: boolean;
    createAPIKeyServiceAccountID?: string;
    createdAPIKeyToken?: string;
    onAPIKeyCreatedDialogClosed?: () => void;
}

/**
 * Display of all account team members and important information about each member
 */
const ServiceAccounts = ({
    serviceAccounts = [],
    onAddServiceAccount,
    onCreateServiceAccount,
    isCreateServiceAccountModalOpen,
    onAddAPIKey,
    onAddAPIKeyConfirmed,
    createAPIKeyServiceAccountID,
    createdAPIKeyToken,
    onAPIKeyCreatedDialogClosed,
    onClose,
    ...props
}: Props): JSX.Element => {
    const { t } = useI18n();
    // TODO loading / zero state
    return (
        <Box>
            <Box display="flex" flexDirection="row" p={4}>
                <Grid container display="flex" alignItems="center" minHeight={24}>
                    <Grid item xs={3}>
                        {t('service_accounts.service_account_name')}
                    </Grid>

                    <Grid item xs={1} lg={3}>
                        {t('service_accounts.service_account_id')}
                    </Grid>

                    <Grid item xs={2} lg={3}>
                        {t('service_accounts.service_account_created_at')}
                    </Grid>
                </Grid>
            </Box>

            {serviceAccounts.map((seriviceAccount, index) => {
                const { id } = seriviceAccount;

                return (
                    <Accordion key={`accordion-${id}`}>
                        {/* @ts-ignore */}
                        <AccordionSummary
                            variant="contained"
                            id={`panel-${index}-header`}
                            aria-controls={`panel-${index}-content`}
                        >
                            <ServiceAccountDetailsRow {...seriviceAccount} />
                        </AccordionSummary>

                        <AccordionDetails id={`panel-${index}-content`} aria-labelledby={`panel-${index}-header`}>
                            <Text>{t('api_keys.api_keys')}</Text>
                            <Box sx={{ overflowX: 'auto' }}>
                                <ServiceAccountAPIKeysTable serviceAccount={seriviceAccount} />
                            </Box>
                            {onAddAPIKey && (
                                <Button
                                    variant="outlined"
                                    sx={{ mb: 4 }}
                                    startIcon={<Icon name="add" size="sm" />}
                                    onClick={() => onAddAPIKey(id)}
                                >
                                    <Text variant="detail">{t('service_accounts.create_api_key_dialog.title')}</Text>
                                </Button>
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}

            {onAddServiceAccount && (
                <Button
                    variant="outlined"
                    sx={{ mb: 4, mt: 4 }}
                    startIcon={<Icon name="add" size="sm" />}
                    onClick={onAddServiceAccount}
                >
                    <Text variant="detail">{t('service_accounts.create_service_account_dialog.title')}</Text>
                </Button>
            )}

            {onCreateServiceAccount && (
                <CreateServiceAccountModal
                    onCreateServiceAccount={onCreateServiceAccount}
                    onClose={onClose}
                    isOpen={!!isCreateServiceAccountModalOpen}
                />
            )}

            {onAddAPIKeyConfirmed && createAPIKeyServiceAccountID && (
                <CreateAPIKeyModal
                    serviceAccountID={createAPIKeyServiceAccountID}
                    onClose={onClose}
                    isOpen={!!createAPIKeyServiceAccountID}
                    onAddAPIKeyConfirmed={onAddAPIKeyConfirmed}
                />
            )}
            {createdAPIKeyToken && onAPIKeyCreatedDialogClosed && (
                <APIKeyCreatedModal
                    token={createdAPIKeyToken ?? ''}
                    isOpen={!!createdAPIKeyToken}
                    onClose={onAPIKeyCreatedDialogClosed}
                />
            )}
        </Box>
    );
};

export default ServiceAccounts;
