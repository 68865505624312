import { Duration } from 'luxon';

import { Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

export function MPUTileTimeToEmpty({
    lowTimeToEmptyThreshold = Duration.fromObject({ hours: 48 }).as('seconds'),
    timeToEmpty,
}: {
    /**
     * The threshold at which the MPU is considered low on time to empty in seconds.
     *
     * @default 172800 // 48 hours
     */
    lowTimeToEmptyThreshold: number | undefined;
    /**
     * The time to empty of the MPU in seconds.
     */
    timeToEmpty: number | undefined;
}) {
    const { t, format } = useI18n();

    if (timeToEmpty === undefined) {
        return <Text variant="detail">{t('mpu_tile.time_to_empty.unknown')}</Text>;
    }

    const isLow = timeToEmpty !== undefined && timeToEmpty <= lowTimeToEmptyThreshold;
    const duration = format.durationFull(timeToEmpty);
    // Only render the first two segments of the duration
    const message = typeof duration === 'string' ? duration : duration.values.slice(0, 2).join(' ');

    return (
        <Text color={isLow ? 'danger' : 'primary'} variant="detail" maxLines={1} preserveHeight={1}>
            {message}
        </Text>
    );
}
