import { ReactElement, ReactNode } from 'react';

import { AccountRoleName } from 'app/core/Session';

import { MUIMarginProps } from 'styles/theme/types';

import CompactRoleSelector from './CompactRoleSelector';
import InlineRoleSelector from './InlineRoleSelector';

interface Props extends MUIMarginProps {
    /**
     * The presentational variant for role selection / assignement
     */
    variant?: 'inline' | 'compact';

    /**
     * The form control label
     */
    fieldName?: ReactNode;

    /**
     * Additional helper text or inline errors
     */
    caption?: ReactNode;

    /**
     * The general name describing what the value represents
     */
    name?: string;

    /**
     * The selected value
     */
    value?: string[];

    /**
     * The ID of the form to include the control into when rendered outside of the form
     */
    form?: string;

    /**
     * Whether to disable the control
     */
    disabled?: boolean;

    /**
     * Whether to render the input as read only
     */
    readOnly?: boolean;

    /**
     * Whether the input is required
     */
    required?: boolean;

    /**
     * Event handler to be called when the value changes
     */
    onChange?: (event: { name?: string; value: string[] }) => void;
}

/**
 * Responsible for surfacing the roles available to assign to an Account / org
 */
export default function RoleSelector({
    variant = 'inline',
    fieldName,
    caption,
    name,
    value,
    disabled,
    readOnly,
    required,
    onChange,
}: Props): ReactElement {
    // Note(Logan): We choose not to dynamically fetch for now
    const allAccountRoles = Object.values(AccountRoleName);

    if (variant === 'compact' || readOnly) {
        return (
            <CompactRoleSelector
                options={allAccountRoles}
                fieldName={fieldName}
                caption={caption}
                name={name}
                value={value}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                onChange={onChange}
            />
        );
    }

    return (
        <InlineRoleSelector
            options={allAccountRoles}
            fieldName={fieldName}
            caption={caption}
            name={name}
            value={value}
            disabled={disabled}
            required={required}
            onChange={onChange}
        />
    );
}
