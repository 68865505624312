import EventEmitter from '../EventEmitter';
import { GenericObject } from '../types';
import { ILayerConnector, IOpenableComponentConnector, LayerItemFacade, LayerItemOptions, LayerOptions } from './types';

const defaultLayerOptions = {
    autoDismiss: true,
    dismissAfterMS: 4000,
};

/**
 * Provides a stripped down API for the layer item
 */
function restrictLayerItemAPI(layer: OpenableComponentConnector): LayerItemFacade {
    return {
        id: layer.id,
        pauseAutoDismiss: layer.pauseAutoDismiss.bind(layer),
        resumeAutoDismiss: layer.resumeAutoDismiss.bind(layer),
        update: layer.update.bind(layer),
        close: layer.close.bind(layer),
        on: layer.on.bind(layer),
    };
}

/**
 * Responsible enabling imperative control of openable components. Components that can be openable
 * must expect "isOpen" and "onClose" props.
 * @private Only to be used with it's collaborator LayerConnector
 */
class OpenableComponentConnector implements IOpenableComponentConnector {
    private eventEmitter = new EventEmitter();
    private timeoutID: null | number = null;

    public props: GenericObject;
    public id: string;

    private options: LayerItemOptions;
    private releaseListeners = () => {};

    constructor({
        props,
        options,
    }: { props?: GenericObject | ((x: LayerItemFacade) => GenericObject); options?: LayerItemOptions } = {}) {
        this.id = crypto.randomUUID();

        const layerFacade = restrictLayerItemAPI(this);
        const resolvedProps = typeof props === 'function' ? props(layerFacade) : props;

        this.props = { ...resolvedProps, isOpen: false, onClose: this.close.bind(this) };
        this.options = { ...defaultLayerOptions, ...options };
    }

    get Component() {
        return this.options.Component;
    }

    /**
     * Request an item to be displayed (opened)
     */
    open() {
        if (this.props.isOpen) return;

        this._update({ isOpen: true });

        this.eventEmitter.emit('open', { id: this.id });

        if (this.options.autoDismiss) {
            const onFocus = () => {
                this.resumeAutoDismiss();
            };
            const onBlur = () => {
                this.pauseAutoDismiss();
            };

            window.addEventListener('focus', onFocus);
            window.addEventListener('blur', onBlur);

            this.releaseListeners = () => {
                window.addEventListener('focus', onFocus);
                window.addEventListener('blur', onBlur);
            };

            this.resumeAutoDismiss();
        }
    }

    /**
     * Pause auto dismissal
     */
    pauseAutoDismiss() {
        clearTimeout(this.timeoutID ?? undefined);
    }

    /**
     * Resets the auto dismiss timer.
     */
    resumeAutoDismiss() {
        this.timeoutID = window.setTimeout(() => {
            this.close();
        }, this.options.dismissAfterMS);
    }

    private _update(props) {
        this.props = { ...this.props, ...props };

        this.eventEmitter.emit('update', { props: this.props });
    }

    private getAllowedProps(props: GenericObject = {}): GenericObject {
        // Remove restricted props,
        // open must be set with open / close only
        const { open, onClose, ...allowedProps } = props;

        return allowedProps;
    }

    /**
     * Request an update to the connected component
     */
    update(props) {
        this._update(this.getAllowedProps(props));
    }

    /**
     * Request the connected component to close and notify interested parties
     */
    close() {
        if (!this.props.isOpen) return;

        this._update({ isOpen: false });

        this.eventEmitter.emit('close', { id: this.id });
        this.eventEmitter.off();
        this.releaseListeners();
    }

    /**
     * Register callbacks to be called when events are emitted. Events include:
     * - open
     * - close
     * - update
     * @returns method for unregistering the callback
     */
    on(eventName, callback) {
        return this.eventEmitter.on(eventName, callback);
    }
}

/**
 * Responsible for linking a request to add a new item to a specific layer (i.e. toast or dialog) to
 * the component responsible for displaying or any consumer interested in updates add / remove of items
 * targeted to the layer
 */
export default class LayerConnector implements ILayerConnector {
    private eventEmitter = new EventEmitter();

    private options: LayerOptions;

    private items: OpenableComponentConnector[] = [];
    private pendingItems: OpenableComponentConnector[] = [];
    private onCloseByID: { [id: string]: () => void } = {};

    constructor({ options }: { options?: LayerOptions } = {}) {
        this.options = { limit: Infinity, ...defaultLayerOptions, ...options };
    }

    /**
     * Creates and adds a new item to be eventually displayed. The display rules will
     * be determined by the options.
     */
    add(props = {}, options = {}) {
        const layer = new OpenableComponentConnector({
            props,
            options: { ...this.options, ...options },
        });

        const off = layer.on('close', () => this.remove(layer.id));
        this.onCloseByID[layer.id] = off;

        if (this.isLimitReached()) {
            this.pendingItems.push(layer);
        } else {
            this.show(layer);
        }

        return restrictLayerItemAPI(layer);
    }

    private show(layer) {
        this.items = this.items.concat(layer);

        layer.open();

        this.eventEmitter.emit('update', { items: this.items });
    }

    private isLimitReached() {
        return this.items.length >= (this.options.limit ?? Infinity);
    }

    getAllItems() {
        return this.items;
    }

    /**
     * Remove an item from the layer
     */
    remove(itemID: string) {
        const off = this.onCloseByID[itemID];

        if (typeof off === 'function') {
            off();
        }

        const item = this.items.find(x => x.id === itemID);

        this.items = this.items.filter(x => x.id !== itemID);
        this.pendingItems = this.pendingItems.filter(x => x.id !== itemID);

        item?.close();

        if (!this.isLimitReached() && this.pendingItems.length > 0) {
            this.show(this.pendingItems.shift());
        } else {
            this.eventEmitter.emit('update', { items: this.items });
        }
    }

    /**
     * Remove all items including any pending items
     */
    removeAll() {
        this.pendingItems = [];
        this.items = [];
        this.eventEmitter.emit('update', { items: this.items });
    }

    /**
     * Subscribe to updates to items being added and removed
     */
    on(eventName, callback) {
        return this.eventEmitter.on(eventName, callback);
    }
}
