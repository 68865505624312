import CSSDimension from 'design-system/CSSDimension';

/**
 * TextField is the basis for most Form controls in MUI.
 *
 * Note(derek): for some reason, although MuiTextField uses MuiInput as the default
 * control the themed version of Input does not get applied within MuiTextField
 */
const MuiTextField = {
    variants: [
        {
            props: { variant: 'outlined' },
            style: ({ theme }) => ({
                '.MuiOutlinedInput-root': {
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.customShadows.subtle,
                    backgroundColor: theme.palette.background.primary.main,
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),

                    '&.Mui-focused': {
                        boxShadow: theme.customShadows.focusHighlight,
                    },
                },

                // Specificity bump here is to overcome the Autocomplete styles
                '.MuiOutlinedInput-root.MuiOutlinedInput-root': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },

                // Specificity bump here is to overcome the Autocomplete styles
                '.MuiOutlinedInput-input.MuiOutlinedInput-input.MuiOutlinedInput-input': {
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(3),
                    paddingLeft: 0,
                    paddingRight: 0,
                    lineHeight: CSSDimension.fromPixels(24).as('rem'),
                    height: CSSDimension.fromPixels(24).as('rem'),
                },

                '.MuiOutlinedInput-root:not(.MuiInputBase-fullWidth)': {
                    // TODO(derek): figure out inline input width. maybe 240 is a good default but should be set per usage
                    // consider ch units.
                    // width: 240,
                },

                // "The" default outline
                '.MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.border.light}`,

                    // Removes notch in border where the label goes
                    // in the default
                    '> legend': {
                        boxSizing: 'border-box',
                        width: 0,
                    },
                },

                '.MuiOutlinedInput-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart': {
                    paddingLeft: theme.spacing(2),
                },

                '.MuiOutlinedInput-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
                    paddingRight: theme.spacing(2),
                },

                // For some reason select does not use input adornment and absolutely positions the icon.
                // Tried using relative position but then clicks are not handled for opening and closing
                // the select menu.
                '.MuiOutlinedInput-input.MuiSelect-select.MuiSelect-select': {
                    paddingRight: theme.spacing(5),
                },

                '> .MuiFormLabel-root': {
                    // Removes the shrink behavior and positioning
                    position: 'relative',
                    transition: 'none',
                    transform: 'none',
                    marginBottom: theme.spacing(3),

                    // Text wrap behavior, let the label text wrap
                    whiteSpace: 'unset',
                    overflow: 'auto',
                    textOverflow: 'unset',

                    // Text Styles
                    color: theme.palette.text.primary,
                    ...theme.typography.detailMedium,

                    // We'll add our own asterisk back so we can control the label layout
                    '> .MuiFormLabel-asterisk': {
                        display: 'none',
                    },

                    '&.Mui-focused': {
                        color: theme.palette.text.primary,
                    },
                },

                '> .MuiFormHelperText-root': {
                    marginTop: theme.spacing(3),
                },

                // Textarea / multiline tweaks
                '.MuiOutlinedInput-root.MuiInputBase-multiline': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },

                // Error state tweaks
                '.MuiOutlinedInput-root.Mui-error.Mui-focused': {
                    boxShadow: theme.customShadows.errorHighlight,
                },

                '> .MuiFormLabel-root.Mui-error': {
                    color: theme.palette.text.danger,
                },

                '> .MuiFormLabel-root.Mui-focused.Mui-error': {
                    color: theme.palette.text.danger,
                },

                // Disabled state tweaks
                '.MuiOutlinedInput-root.Mui-disabled': {
                    backgroundColor: theme.palette.background.disabled.main,
                    borderColor: theme.palette.background.disabled.main,
                    boxShadow: 'none',
                    textFillColor: theme.palette.text.secondary,
                    color: theme.palette.text.secondary,
                },

                '.MuiOutlinedInput-root.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.background.disabled.main,
                },

                '> .MuiFormLabel-root.Mui-disabled': {
                    color: theme.palette.text.secondary,
                },

                '> .MuiFormHelperText-root.Mui-disabled': {
                    color: theme.palette.text.secondary,
                },

                // "Static" / readOnly tweaks
                '.MuiOutlinedInput-root.Mui-readOnly': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    paddingLeft: 0,
                    paddingRight: 0,
                    color: theme.palette.text.secondary,
                    textFillColor: theme.palette.text.secondary,

                    '&.Mui-focused.Mui-focused': {
                        boxShadow: 'none',
                    },

                    '> .MuiOutlinedInput-notchedOutline': {
                        display: 'none',
                    },
                },
            }),
        },
    ],
    defaultProps: {
        SelectProps: {
            MenuProps: {
                sx: { mt: 2 },
            },
        },
    },
};

const MuiFormHelperText = {
    styleOverrides: {
        root: ({ theme }) => ({
            marginLeft: 0,

            ...theme.typography.detail,
        }),
    },
};

export default { MuiTextField, MuiFormHelperText };
