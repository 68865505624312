import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import Text from 'app/components/primitives/Text';
import AccountLocationSelector from 'app/components/widgets/AccountLocationSelector';

import { useI18n } from 'i18n';

interface Props {
    /**
     * Account ID to fetch associated locations for
     */
    accountID?: string | null | undefined;

    /**
     * The controlled value for dropoff location — represents location ID
     */
    dropoffID?: string | null | undefined;

    /**
     * The controlled value for pickup location — represents location ID
     */
    pickupID?: string | null | undefined;

    /**
     * Whether the ddropoffID and pickupID should be linked / the same
     */
    isLinked?: boolean | null | undefined;

    /**
     * The totall number of units being requested
     */
    unitCount?: number;

    /**
     * The unit number in the request
     */
    unitNumber?: number;

    /**
     * Whether the units should be sent to the same location
     */
    isSameLocation?: boolean;

    /**
     * Any validation errors on the pickup location field
     */
    pickupError?: string;

    /**
     * Any validation errors on the dropoff location field
     */
    dropoffError?: string;

    /**
     * Event handler called when the dropoff control is focused
     */
    onDropoffFocused?: (event: any) => void;
    /**
     * Event handler called when the dropoff control is blurred
     */
    onDropoffBlurred?: (event: any) => void;
    /**
     * Event handler called when the pickup control is focused
     */
    onPickupFocused?: (event: any) => void;
    /**
     * Event handler called when the pickup control is blurred
     */
    onPickupBlurred?: (event: any) => void;
    /**
     * Event handler called when either the dropoff or pickup location is changed
     */
    onChange: (event: {
        value: {
            dropoffID: string | null | undefined;
            pickupID: string | null | undefined;
            isLinked: boolean | null | undefined;
        };
    }) => void;
}

/**
 * Responsible for collecting a single pickup / dropoff location for a unit request
 */
export default function PickupDropoffSelector({
    accountID = '1244',
    dropoffID,
    pickupID,
    isLinked,
    unitCount,
    unitNumber,
    isSameLocation,
    pickupError,
    dropoffError,
    onDropoffFocused,
    onDropoffBlurred,
    onPickupFocused,
    onPickupBlurred,
    onChange,
}: Props): ReactElement {
    const { t } = useI18n();

    const deliveryLabel = isSameLocation
        ? t('renter_reservation_form.dropoff_label_same_location', { count: unitCount ?? 1 })
        : t('renter_reservation_form.dropoff_label_independent', {
              count: (unitNumber ?? 0) + 1,
          });

    const pickupLabel = isSameLocation
        ? t('renter_reservation_form.pickup_label_same_location', { count: unitCount ?? 1 })
        : t('renter_reservation_form.pickup_label_independent', {
              count: (unitNumber ?? 0) + 1,
          });

    return (
        <Box>
            <Text as="p" mb={1}>
                {deliveryLabel}
            </Text>

            <AccountLocationSelector
                required
                error={!!dropoffError}
                caption={dropoffError}
                accountID={accountID}
                value={dropoffID}
                onFocus={onDropoffFocused}
                onBlur={onDropoffBlurred}
                onChange={({ value }) => {
                    const newDropoffID = value?.id ?? dropoffID;

                    onChange({
                        value: {
                            isLinked,
                            dropoffID: newDropoffID,
                            pickupID: isLinked ? newDropoffID : pickupID,
                        },
                    });
                }}
            />

            {isLinked && (
                <Box mt={1}>
                    <Button
                        ctaType="tertiary"
                        icon={<Icon name="add-circle" />}
                        onClick={() => {
                            onChange({
                                value: {
                                    isLinked: false,
                                    dropoffID,
                                    pickupID,
                                },
                            });
                        }}
                    >
                        {t('renter_reservation_form.unlink_pickup_dropoff_locations_label')}
                    </Button>
                </Box>
            )}

            {!isLinked && (
                <Box mt={2}>
                    <Text as="p" mb={1}>
                        {pickupLabel}
                    </Text>
                    <AccountLocationSelector
                        required
                        accountID={accountID}
                        value={pickupID}
                        error={!!pickupError}
                        caption={pickupError}
                        onFocus={onPickupFocused}
                        onBlur={onPickupBlurred}
                        onChange={({ value }) => {
                            onChange({
                                value: {
                                    isLinked,
                                    dropoffID,
                                    pickupID: value?.id ?? pickupID,
                                },
                            });
                        }}
                    />
                </Box>
            )}
        </Box>
    );
}
