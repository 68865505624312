import { DateTime as LuxonDateTime } from 'luxon';

import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

import { Icon, Text } from 'app/components';

import { getSupportEmail } from 'environment';

import { ReservationStatus } from 'generated/graphql';

import { useI18n } from 'i18n';

interface UnitWarningProps {
    reservationID: string;
    status: string;
    start: string;
    end: string;
}

export function UnitWarning({ reservationID, status, start, end }: UnitWarningProps) {
    const { t } = useI18n();

    let now = LuxonDateTime.now();
    let warning_translation_key: string | undefined;

    if (status === ReservationStatus.Started && LuxonDateTime.fromISO(end) < now) {
        warning_translation_key = 'renter_dashboard.reservation_item_table.warning.started_status_after_end_date';
    }

    if (status === ReservationStatus.Started && LuxonDateTime.fromISO(start) > now) {
        warning_translation_key = 'renter_dashboard.reservation_item_table.warning.started_status_after_end_date';
    }

    if (warning_translation_key) {
        return (
            <Tooltip
                title={
                    <Text
                        renderLink={({ text }) => (
                            <Link
                                color="inherit"
                                href={`mailto:${getSupportEmail()}?subject=${t(
                                    `${warning_translation_key}.email_subject`,
                                    {
                                        reservationID,
                                    },
                                )}`}
                            >
                                {text}
                            </Link>
                        )}
                    >
                        {t(`${warning_translation_key}.text`)}
                    </Text>
                }
                arrow
            >
                <Icon name="warning" color="warning" size="sm" />
            </Tooltip>
        );
    }

    return null;
}
