import { DateTime, Duration } from 'luxon';
import * as yup from 'yup';

import { Assignment } from 'generated/graphql';

import {
    Allocation,
    AllocationSchedule,
    ExpectedAllocationSchedules,
    PersistedAllocation,
    PreparedAllocation,
} from './types';

/**
 * The minimum duration of one device instance schedule.
 * To help ensure consistency, this value should be used for any date calculations involving Allocations.
 * One minute was chosen as a sane default to be able to see the difference in times between two schedules in the UI.
 */
export const MIN_SCHEDULE_DURATION = Duration.fromObject({ minute: 1 });

/**
 * Determines whether the given collection of schedules meet the expectations of being
 * associated with an allocation.
 *
 * @remarks
 *
 * This function will evolve significantly as we add more features to support more flexible schedules.
 */
export function isExpectedSchedulesForAllocation(
    schedules?: AllocationSchedule[] | null,
): schedules is ExpectedAllocationSchedules {
    return (
        schedules?.length === 1 &&
        schedules?.filter(schedule => schedule.assignment === Assignment.Discharging).length === 1
    );
}

export const PendingAllocationSchema = yup.object().shape({
    end: yup
        .string()
        .required()
        .test('isValidEndDateTime', 'end is not after start', function (value) {
            if (!value) return false;

            const endDateTime = DateTime.fromISO(value);
            const startDateTime = DateTime.fromISO(this.parent.start);
            const isEndAfterStart = endDateTime > startDateTime;
            const isValidDuration = endDateTime.diff(startDateTime) >= MIN_SCHEDULE_DURATION;

            return isEndAfterStart && isValidDuration;
        }),
    id: yup.string().required(),
    isPersisted: yup.bool().oneOf([false]).required(),
    mpuID: yup.string(),
    mpuName: yup.string(),
    start: yup.string().required(),
    unitID: yup.string().required(),
});

const PreparedAllocationSchema = yup.object().shape({
    ...PendingAllocationSchema.fields,
    mpuID: yup.string().required(),
    mpuName: yup.string().required(),
});

const PersistedAllocationSchema = yup.object().shape({
    ...PreparedAllocationSchema.fields,
    isPersisted: yup.bool().oneOf([true]).required(),
});

export function isValidPreparedAllocation(allocation: Partial<Allocation>): allocation is PreparedAllocation {
    return PreparedAllocationSchema.isValidSync(allocation);
}

export function isValidPersistedAllocation(allocation: Partial<Allocation>): allocation is PersistedAllocation {
    return PersistedAllocationSchema.isValidSync(allocation);
}

export function isPersistedAllocation(allocation: Allocation): allocation is PersistedAllocation {
    return allocation.isPersisted;
}
