interface MatchedTextSegment {
    value: string;
    index: number;
    isMatch?: boolean;
}

/**
 * Seeks all string segments which matches a given input string
 */
export default function annotateMatches({ text, input }: { text?: string; input: string }): MatchedTextSegment[] {
    const re = new RegExp(input, 'ig');
    const matches: RegExpMatchArray[] = Array.from((text ?? '').matchAll(re));

    const interimResult = matches.reduce((acc: MatchedTextSegment[], cur: RegExpMatchArray): MatchedTextSegment[] => {
        const lastMatch: MatchedTextSegment = acc[acc.length - 1];
        const lastMatchIndex = lastMatch?.index ? lastMatch.index + input.length : 0;

        return acc
            .concat(
                (cur.index ?? 0) - lastMatchIndex > 0
                    ? { value: text?.substring(lastMatchIndex, cur.index) ?? '', index: lastMatchIndex }
                    : [],
            )
            .concat({ value: cur[0] ?? '', index: cur?.index ?? 0, isMatch: true });
    }, []);

    const lastMatch: MatchedTextSegment = interimResult[interimResult.length - 1];
    const lastMatchIndex = !!lastMatch ? lastMatch.index + input.length : 0;

    return interimResult.concat(
        lastMatchIndex < (text?.length ?? 0)
            ? { value: text?.substring(lastMatchIndex) ?? '', index: lastMatchIndex }
            : [],
    );
}
