import { GetUserQueryHookResult, useGetUserQuery } from 'generated/graphql';

import { ServerUser, User } from './types';

const nullUserDetails: User = {
    id: '',
    active: null,
    name: '',
    email: '',
    phoneNumber: '',
    accountName: '',
    accountID: null,
    roles: [],
};

function toUser(serverUser?: ServerUser | null): User {
    if (!serverUser) return nullUserDetails;

    return {
        id: serverUser?.id ?? '',
        active: serverUser?.active ?? null,
        name: serverUser?.name ?? '',
        email: serverUser?.email ?? '',
        phoneNumber: serverUser?.phoneNumber ?? '',
        accountName: serverUser?.accounts?.[0].name ?? '',
        accountID: serverUser?.accounts?.[0]?.id ?? null,
        roles: (serverUser?.roles || []).map(x => x.name),
    };
}

/**
 * Responsible for getting basic user details from the GQL server
 */
export default function useUserDetails({ id }: { id: string }): {
    data: User;
    loading: boolean;
    error: GetUserQueryHookResult['error'];
} {
    const { data, loading, error } = useGetUserQuery({
        variables: { userID: id },
    });

    const user = toUser(data?.getUser);

    return {
        loading,
        error,
        data: user,
    };
}
