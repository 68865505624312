import { AnyAction } from 'typescript-fsa';
import { v4 as uuidV4 } from 'uuid';

import { handleUnknownError } from 'app/core/error';

import {
    findMatchingPendingAllocation,
    getAllocation,
    getLatestAllocationForUnit,
    getPendingAllocationIndex,
    getUnit,
    getUnitAllocations,
} from './accessors';
import {
    deletePendingAllocation,
    insertPendingAllocation,
    insertPendingAllocationAfter,
    insertPendingAllocationBefore,
    insertPendingAllocationCollection,
    updatePendingAllocation,
    updateReservationItems,
} from './actions';
import { selectPersistedAllocationCount, selectPersistedAllocations, selectUnitCount, selectUnits } from './selectors';
import { getUnallocatedTimeSpans } from './transformers';
import {
    PendingAllocation,
    PendingAllocationPatch,
    ReservationScheduleOptions,
    ReservationScheduleState,
} from './types';
import { PendingAllocationSchema } from './utils';

function makePendingAllocation({
    end,
    start,
    unitID,
}: Pick<PendingAllocation, 'end' | 'start' | 'unitID'>): PendingAllocation {
    return {
        end,
        id: uuidV4(),
        isPersisted: false,
        mpuID: undefined,
        mpuName: undefined,
        start,
        unitID,
    };
}

function reduceInsertPendingAllocation(
    state: ReservationScheduleState,
    action: ReturnType<typeof insertPendingAllocation>,
): ReservationScheduleState {
    const unitID = action.payload;
    const unit = getUnit(state, unitID);

    if (!unit) return state;

    const latestAllocation = getLatestAllocationForUnit(state, unitID);
    const start = latestAllocation?.end ?? unit.start;
    const pendingAllocation = makePendingAllocation({
        end: unit.end,
        start,
        unitID: unit.id,
    });

    try {
        // This shouldn't fail.
        PendingAllocationSchema.validateSync(pendingAllocation);
    } catch (error) {
        handleUnknownError(error);

        return state;
    }

    return {
        ...state,
        pendingAllocations: [...state.pendingAllocations, pendingAllocation],
    };
}
function reduceInsertPendingAllocationCollection(
    state: ReservationScheduleState,
    action: ReturnType<typeof insertPendingAllocationCollection>,
): ReservationScheduleState {
    const { unitID, timeSpans } = action.payload;
    const unit = getUnit(state, unitID);

    if (!unit) return state;

    const collection = timeSpans.map(({ end, start }) =>
        makePendingAllocation({
            end,
            start,
            unitID: unit.id,
        }),
    );

    return {
        ...state,
        pendingAllocations: [...state.pendingAllocations, ...collection],
    };
}

function reduceInsertPendingAllocationAfter(
    state: ReservationScheduleState,
    action: ReturnType<typeof insertPendingAllocationAfter>,
): ReservationScheduleState {
    const prevAllocationID = action.payload;
    const prevAllocation = getAllocation(state, prevAllocationID);

    if (!prevAllocation) return state;

    const unit = getUnit(state, prevAllocation.unitID);

    if (!unit) return state;

    const prevAllocationIndex = unit.allocations.findIndex(({ id }) => id === prevAllocation.id);

    if (prevAllocationIndex === -1) return state;

    const nextAllocation = unit.allocations.at(prevAllocationIndex + 1);
    const start = prevAllocation.end;
    const end = nextAllocation?.start ?? unit.end;
    const pendingAllocation = makePendingAllocation({
        end,
        start,
        unitID: unit.id,
    });

    return {
        ...state,
        pendingAllocations: [...state.pendingAllocations, pendingAllocation],
    };
}

function reduceInsertPendingAllocationBefore(
    state: ReservationScheduleState,
    action: ReturnType<typeof insertPendingAllocationAfter>,
): ReservationScheduleState {
    const nextAllocationID = action.payload;
    const nextAllocation = getAllocation(state, nextAllocationID);

    if (!nextAllocation) return state;

    const unit = getUnit(state, nextAllocation.unitID);

    if (!unit) return state;

    const nextAllocationIndex = unit.allocations.findIndex(({ id }) => id === nextAllocation.id);

    if (nextAllocationIndex === -1) return state;

    const prevAllocation = nextAllocationIndex === 0 ? undefined : unit.allocations.at(nextAllocationIndex - 1);
    const end = nextAllocation.start;
    const start = prevAllocation?.end ?? unit.start;
    const pendingAllocation = makePendingAllocation({
        end,
        start,
        unitID: unit.id,
    });

    return {
        ...state,
        pendingAllocations: [...state.pendingAllocations, pendingAllocation],
    };
}

function reduceDeletePendingAllocation(
    state: ReservationScheduleState,
    action: ReturnType<typeof deletePendingAllocation>,
): ReservationScheduleState {
    const allocationID = action.payload;
    const allocation = getAllocation(state, allocationID);

    if (!allocation || allocation.isPersisted) return state;

    const { pendingAllocations } = state;
    const allocationIndex = getPendingAllocationIndex(state, allocationID);

    if (allocationIndex === undefined) return state;

    const nextPendingAllocations = [...pendingAllocations];

    nextPendingAllocations.splice(allocationIndex, 1);

    const nextState = {
        ...state,
        pendingAllocations: nextPendingAllocations,
    };

    const { unitID } = allocation;

    const nextUnitHasAllocations = getUnitAllocations(nextState, unitID).length > 0;

    if (!nextUnitHasAllocations) {
        // A unit must have at least one allocation.
        return reduceInsertPendingAllocation(nextState, insertPendingAllocation(unitID));
    }

    return nextState;
}

function reducePendingAllocationUpdate(
    state: ReservationScheduleState,
    allocationPatch: PendingAllocationPatch,
): ReservationScheduleState {
    const { pendingAllocations } = state;
    const allocationID = allocationPatch.id;
    const allocationIndex = getPendingAllocationIndex(state, allocationID);

    if (allocationIndex === undefined) return state;

    const nextAllocation = {
        ...pendingAllocations[allocationIndex],
        ...allocationPatch,
    };
    const nextPendingAllocations = [...pendingAllocations];

    nextPendingAllocations.splice(allocationIndex, 1, nextAllocation);

    return {
        ...state,
        pendingAllocations: nextPendingAllocations,
    };
}

/**
 * Fills all unallocated time for units with pending allocations.
 */
function fillUnallocatedTime(state: ReservationScheduleState) {
    return selectUnits(state).reduce((nextState, nextUnit) => {
        const unitID = nextUnit.id;
        const timeSpans = getUnallocatedTimeSpans(nextUnit);

        if (!timeSpans.length) return nextState;

        return reduceInsertPendingAllocationCollection(
            nextState,
            insertPendingAllocationCollection({ unitID, timeSpans }),
        );
    }, state);
}

/**
 * Ensures that each unit has at least one allocation.
 */
function ensureMinimumAllocations(state: ReservationScheduleState) {
    return selectUnits(state).reduce((nextState, nextUnit) => {
        if (nextUnit.allocations.length > 0) return nextState;

        return reduceInsertPendingAllocation(nextState, insertPendingAllocation(nextUnit.id));
    }, state);
}

/**
 * Removes any pending allocations that have been persisted.
 */
function removeLeftoverPendingAllocations(state: ReservationScheduleState) {
    const persistedAllocations = selectPersistedAllocations(state);

    return persistedAllocations.reduce((nextState, persistedAllocation) => {
        const preparedAllocation = findMatchingPendingAllocation(nextState, persistedAllocation);

        if (!preparedAllocation) return nextState;

        return reduceDeletePendingAllocation(nextState, deletePendingAllocation(preparedAllocation.id));
    }, state);
}

function reduceUpdateReservationItems(
    state: ReservationScheduleState,
    action: ReturnType<typeof updateReservationItems>,
): ReservationScheduleState {
    const nextState: ReservationScheduleState = { ...state, reservationItems: action.payload };

    const unitCount = selectUnitCount(state);
    const nextUnitCount = selectUnitCount(nextState);

    // While the number of units is currently static for a reservation,
    // they may not yet be loaded upon component initialization.
    const unitCountHasChanged = unitCount !== nextUnitCount;

    if (unitCountHasChanged) {
        return fillUnallocatedTime(nextState);
    }

    const persistedAllocationCount = selectPersistedAllocationCount(state);
    const nextPersistedAllocationCount = selectPersistedAllocationCount(nextState);
    const persistedAllocationWasDeleted = nextPersistedAllocationCount < persistedAllocationCount;
    const pendingAllocationWasPersisted = nextPersistedAllocationCount > persistedAllocationCount;

    if (persistedAllocationWasDeleted) {
        return ensureMinimumAllocations(nextState);
    }
    if (pendingAllocationWasPersisted) {
        return removeLeftoverPendingAllocations(nextState);
    }

    return nextState;
}

export function reservationScheduleReducer(
    state: ReservationScheduleState,
    action: AnyAction,
): ReservationScheduleState {
    if (insertPendingAllocation.match(action)) {
        return reduceInsertPendingAllocation(state, action);
    }
    if (insertPendingAllocationCollection.match(action)) {
        return reduceInsertPendingAllocationCollection(state, action);
    }
    if (insertPendingAllocationAfter.match(action)) {
        return reduceInsertPendingAllocationAfter(state, action);
    }
    if (insertPendingAllocationBefore.match(action)) {
        return reduceInsertPendingAllocationBefore(state, action);
    }
    if (deletePendingAllocation.match(action)) {
        return reduceDeletePendingAllocation(state, action);
    }
    if (updatePendingAllocation.match(action)) {
        return reducePendingAllocationUpdate(state, action.payload);
    }
    if (updateReservationItems.match(action)) {
        return reduceUpdateReservationItems(state, action);
    }

    return state;
}

export function createDefaultReservationScheduleState({
    defaultReservationItems: reservationItems,
}: ReservationScheduleOptions): ReservationScheduleState {
    // Reservation items may not be loaded yet when the schedule component initializes,
    // as a result, even though reservation items are currently static for a reservation,
    // they may change during the component lifecycle, and the change must be handled.
    return fillUnallocatedTime({
        pendingAllocations: [],
        reservationItems,
    });
}
