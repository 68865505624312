import { useFormik } from 'formik';
import { object, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from '@mui/material';

import { useI18n } from 'i18n';

interface CreateServiceAccountModalProps {
    onCreateServiceAccount: (sa: { name: string }) => void;
    onClose: (event: unknown, reason: string) => void;
    isOpen: boolean;
}

export function CreateServiceAccountModal({ onCreateServiceAccount, onClose, isOpen }: CreateServiceAccountModalProps) {
    const { t } = useI18n();
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: object().shape({
            name: string().required(t('service_accounts.create_service_account_dialog.name_required')),
        }),
        async onSubmit(values) {
            try {
                onCreateServiceAccount(values);
            } catch (error: unknown) {
                throw error;
            }

            close({}, 'submit');
        },
    });

    const close = (event, reason) => {
        if (reason !== 'backdropClick') {
            formik.handleReset(event);
            onClose(event, reason);
        }
    };

    return (
        <Dialog id={''} open={isOpen} onClose={close} aria-labelledby="add-unit-dialog-title">
            <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                <DialogTitle id="add-unit-dialog-title">
                    {t('service_accounts.create_service_account_dialog.title')}
                </DialogTitle>

                <Divider />

                <DialogContent
                    sx={{
                        width: {
                            md: 500,
                        },
                    }}
                >
                    <Stack spacing={4}>
                        <TextField
                            variant="standard"
                            required
                            label={t('service_accounts.create_service_account_dialog.service_account_name')}
                            name="name"
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={t('service_accounts.create_service_account_dialog.name_required')}
                            onChange={formik.handleChange}
                        />
                    </Stack>
                </DialogContent>

                <DialogActions sx={{ px: 5, pb: 4 }}>
                    <Button onClick={event => close(event, 'cancel')}>{t('cta.cancel')}</Button>

                    <Button type="submit" variant="contained" disabled={!formik.isValid}>
                        {t('service_accounts.create_service_account_dialog.create')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
