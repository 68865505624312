import { ReactElement } from 'react';

import Paper from '@mui/material/Paper';

import { Cluster, PageHeader, SectionHeader, Tag } from 'app/components';
import { useSession } from 'app/core/Session';
import { SessionData } from 'app/core/types';

import { useI18n } from 'i18n';

import FaultsTable from './FaultsTable';
import useFaults from './useFaults';

function formatFaultCount(count: number): string {
    const isCountUnknown = count === -1;

    if (isCountUnknown) return '';

    return ` (${count})`;
}

/**
 * This function resolves the capabilities / features to expose the any given user
 * basd on their user roles.
 */
function resolveUsersCapabilities({
    userRoles,
    primaryAccountID,
}: Pick<SessionData, 'userRoles' | 'primaryAccountID'>): {
    /**
     * The owning accountID for MPUs the user should be able to access.
     */
    ownerAccountID?: string;

    /**
     * Whether the user is part of the MPU rental operations (i.e. Energy Services)
     */
    enableRentalManagement: boolean;
} {
    const hasGlobalAccess = !!userRoles.find(x =>
        ['MoxionAdmin', 'MoxionFleetManager', 'MoxionFieldOperator'].includes(x),
    );

    return {
        ownerAccountID: hasGlobalAccess ? undefined : primaryAccountID,
        enableRentalManagement: hasGlobalAccess,
    };
}

export default function FaultsPage(): ReactElement {
    const { t } = useI18n();
    const session = useSession().getSession();

    const { ownerAccountID, enableRentalManagement } = resolveUsersCapabilities(session);

    const {
        loading,

        counts,

        faults,

        // Sort
        sortModel,
        onSortModelChange,

        // Filters
        activeFilter,

        showAll,
        showCritical,

        // Pagination
        currentPage,
        resultCount,
        pageSize,
        pageSizeOptions,

        onPageChange,
        onPageSizeChange,
    } = useFaults({ ownerAccountID });

    const excludeColumns = ([] as string[]).concat(
        enableRentalManagement ? [] : ['serviceAreaName'],
        ownerAccountID ? ['ownerName'] : [],
    );

    return (
        <>
            <PageHeader title="Faults" />

            <SectionHeader
                mb={5}
                title={t('faults_page.fault_count_title', { count: !resultCount && loading ? -1 : resultCount })}
            />

            <Cluster mb={5}>
                <Tag
                    outlined
                    label={`All${formatFaultCount(counts.total)}`}
                    selected={activeFilter === 'all'}
                    onClick={showAll}
                />

                <Tag
                    outlined
                    label={`Critical${formatFaultCount(counts.critical)}`}
                    selected={activeFilter === 'critical'}
                    onClick={showCritical}
                />
            </Cluster>

            <Paper variant="outlined" sx={{ overflow: 'auto' }}>
                <FaultsTable
                    excludeColumns={excludeColumns}
                    page={currentPage}
                    rowCount={resultCount}
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                    loading={loading}
                    faults={faults}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                />
            </Paper>
        </>
    );
}
