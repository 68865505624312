import { Telemetry, TelemetryPoint, TelemetryValue } from 'generated/graphql';

import { TranslateFunction } from 'i18n';

import { CellSignalBar, ChargingStatus, isCellSignalBar, MetricName, RunMode, VoltageSelection } from './constants';
import { scaleToKilo } from './scaleToKilo';

// TODO(Morris): Why are these snake_case? These are magic numbers to me; let's make these enums.
type mode_selection_state = 0 | 1 | 2 | null | undefined | number;
type voltage_selection_state = 0 | 1 | 2 | 3 | 4 | null | undefined | number;
type charge_state = 0 | 1 | 2 | 3 | 4 | null | undefined | number;
type pcs_state = 0 | 1 | 2 | 3 | 4 | null | undefined | number;

/**
 * Dependencies used to resolve telemetry values
 */
interface ResolverDeps {
    t: TranslateFunction;
}

type TelemetryCollection = (Telemetry | TelemetryPoint)[];

interface UnitTelemetry {
    /**
     * The operating mode for the unit (i.e. run, standby, off)
     */
    mode?: RunMode;

    /**
     * An integer representing the number of bars for an MPU's cellular connection signal quality.
     */
    cellSignalBars?: CellSignalBar;

    /**
     * The voltage selection for the unit
     */
    voltageSelection?: VoltageSelection;

    /**
     * The charging status of the unit (i.e. initializing, charging, etc)
     */
    chargingStatus?: ChargingStatus;

    /**
     * The number of seconds until the unit is full
     */
    timeToFull?: number | null;

    /**
     * The charging device's power rating
     */
    chargeInputPower?: number;

    /**
     * The line voltages
     */
    line1LoadVoltage?: number;
    line2LoadVoltage?: number;
    line3LoadVoltage?: number;
    lineNLoadVoltage?: number;

    /**
     * The line currents
     */
    line1LoadCurrent?: number;
    line2LoadCurrent?: number;
    line3LoadCurrent?: number;
    lineNLoadCurrent?: number;

    /**
     * The maximum line current that the unit supports
     */
    maxLineCurrent?: number;

    /**
     * The momentary total power consumed
     */
    totalLoadPower?: number;

    /**
     * The percent energy utilization of the battery
     */
    stateOfCharge?: number;

    /**
     * total power - charge power
     */
    netPower?: number;

    /**
     * The time in seconds for the unit to be drained / unusable
     */
    timeToEmpty?: number | null;

    /**
     * Available energy in kilowatt hours
     */
    packEnergyAvailable?: number;

    switchMode?: mode_selection_state;
    pcsState?: pcs_state;

    cellSignalStrength?: number;
    cellSignalQuality?: number;

    line1LoadPower?: number;
    line2LoadPower?: number;
    line3LoadPower?: number;
    lineNLoadPower?: number;
}

/**
 * @privateRemarks The `UnitStatus` name is being used a component name.
 */
export type UnitStatusType = Pick<
    UnitTelemetry,
    | 'packEnergyAvailable'
    | 'mode'
    | 'cellSignalBars'
    | 'voltageSelection'
    | 'chargingStatus'
    | 'timeToFull'
    | 'chargeInputPower'
    | 'line1LoadVoltage'
    | 'line2LoadVoltage'
    | 'line3LoadVoltage'
    | 'lineNLoadVoltage'
    | 'line1LoadCurrent'
    | 'line2LoadCurrent'
    | 'line3LoadCurrent'
    | 'lineNLoadCurrent'
    | 'maxLineCurrent'
    | 'totalLoadPower'
    | 'stateOfCharge'
    | 'netPower'
    | 'timeToEmpty'
>;

const unitTelemetryNameByMetricName: Partial<Record<MetricName, keyof UnitTelemetry>> = {
    [MetricName.PackEnergyAvailable]: 'packEnergyAvailable',
    [MetricName.TimeToEmpty]: 'timeToEmpty',
    [MetricName.StateOfCharge]: 'stateOfCharge',
    [MetricName.NetPower]: 'netPower',

    [MetricName.Line1LoadVoltage]: 'line1LoadVoltage',
    [MetricName.Line2LoadVoltage]: 'line2LoadVoltage',
    [MetricName.Line3LoadVoltage]: 'line3LoadVoltage',
    [MetricName.LineNLoadVoltage]: 'lineNLoadVoltage',

    [MetricName.Line1LoadCurrent]: 'line1LoadCurrent',
    [MetricName.Line2LoadCurrent]: 'line2LoadCurrent',
    [MetricName.Line3LoadCurrent]: 'line3LoadCurrent',
    [MetricName.NeutralCurrent]: 'lineNLoadCurrent',

    [MetricName.Line1LoadPower]: 'line1LoadPower',
    [MetricName.Line2LoadPower]: 'line2LoadPower',
    [MetricName.Line3LoadPower]: 'line3LoadPower',
    [MetricName.LineNLoadPower]: 'lineNLoadPower',

    [MetricName.TotalLoadPower]: 'totalLoadPower',

    [MetricName.ChargeInputPower]: 'chargeInputPower',
    [MetricName.TimeToFull]: 'timeToFull',

    [MetricName.SwitchMode]: 'switchMode',
    [MetricName.PcsState]: 'pcsState',
    [MetricName.ChargingStatus]: 'chargingStatus',
    [MetricName.VoltageSelection]: 'voltageSelection',

    [MetricName.CellSignalStrength]: 'cellSignalStrength',
    [MetricName.CellSignalQuality]: 'cellSignalQuality',
};

/**
 * We map the metric names to provide some separation from the backend values
 */
function renameMetric(metric: MetricName): MetricName | keyof UnitTelemetry {
    return unitTelemetryNameByMetricName[metric] || metric;
}

function resolveMode(
    { switchMode, pcsState }: { switchMode: mode_selection_state; pcsState: pcs_state },
    { t }: ResolverDeps,
): RunMode {
    if (typeof switchMode !== 'number' && typeof switchMode !== 'number') return RunMode.Unknown;

    if (switchMode === 0) return RunMode.Off;
    if (pcsState === 2 || pcsState === 3) return RunMode.Run;

    return RunMode.Standby;
}

function resolveChargeState(chargeState: charge_state, { t }: ResolverDeps): ChargingStatus {
    switch (chargeState) {
        case 0:
            return ChargingStatus.NotConnected;
        case 1:
            return ChargingStatus.NotConnected;
        case 2:
            return ChargingStatus.Initializing;
        case 3:
            return ChargingStatus.Charging;
        default:
            return ChargingStatus.Unknown;
    }
}

function resolveVoltageSelection(voltageSelection: voltage_selection_state, { t }: ResolverDeps): VoltageSelection {
    switch (voltageSelection) {
        case 0:
            return VoltageSelection.Invalid;
        case 1:
            return VoltageSelection.US480;
        case 2:
            return VoltageSelection.US240;
        case 3:
            return VoltageSelection.US208;
        default:
            return VoltageSelection.Unknown;
    }
}

function isTelemetry(value: Telemetry | TelemetryPoint): value is Telemetry {
    return !!(value as Telemetry).datapoints;
}

function getCurrentTelemetryValue(telemetry: Telemetry | TelemetryPoint): TelemetryValue['value'] {
    if (isTelemetry(telemetry)) {
        return telemetry.datapoints?.slice(-1)[0]?.value;
    }

    return telemetry.value;
}

function transformValue(metricName: MetricName, value: number | null | undefined, deps: ResolverDeps) {
    if (metricName === MetricName.VoltageSelection) {
        return resolveVoltageSelection(value, deps);
    }

    if (metricName === MetricName.ChargingStatus) {
        return resolveChargeState(value, deps);
    }

    if (/^(p_|load_|e_|pcs_total_power)/.test(metricName)) {
        return scaleToKilo(value);
    }

    return value;
}

/**
 * Transforms a single value for consumption in the frontend.
 * Does not resolve for computed values that require multiple metrics.
 */
function resolveCurrentValue(
    metricName: MetricName,
    telemetry: Telemetry | TelemetryPoint,
    deps: ResolverDeps,
): string | number | undefined | null {
    const currentValue = getCurrentTelemetryValue(telemetry);

    return transformValue(metricName, currentValue, deps);
}

function getCurrentValues(telemetries: TelemetryCollection, deps: ResolverDeps): UnitTelemetry {
    const baseStatus: UnitTelemetry = {
        voltageSelection: VoltageSelection.Unknown,
    };

    return telemetries.reduce((acc, cur) => {
        const metricName = cur.metric as MetricName;
        const key = renameMetric(metricName);
        const value = resolveCurrentValue(metricName, cur, deps);

        return { ...acc, [key]: value };
    }, baseStatus);
}

function resolveNumberOfBars({
    cellSignalQuality,
    cellSignalStrength,
}: {
    cellSignalQuality: number | null | undefined;
    cellSignalStrength: number | null | undefined;
}): CellSignalBar | undefined {
    if (cellSignalQuality == null && cellSignalStrength == null) {
        return undefined;
    }

    const percent = Math.min(cellSignalQuality ?? 0, cellSignalStrength ?? 0);
    const NUMBER_OF_BARS = 4;

    const result = Math.ceil(percent / (100 / NUMBER_OF_BARS));

    return isCellSignalBar(result) ? result : undefined;
}

/**
 * Responsible for retrieving the current system status for a given unit associated with a reservation item
 */
export default function extractUnitStatusesFromTelemetry<T extends keyof UnitStatusType>(
    telemetries: TelemetryCollection,
    { t }: ResolverDeps,
): Pick<UnitStatusType, T> {
    const currentValues = getCurrentValues(telemetries, { t });

    /**
     * We pull any metrics which are used to compute final displayed metrics here such that they don't show
     * up in the returned object
     */
    const { cellSignalQuality, cellSignalStrength, pcsState, switchMode, ...resultantValues } = currentValues;
    const { netPower, timeToEmpty, timeToFull } = resultantValues;

    resultantValues.cellSignalBars = resolveNumberOfBars({ cellSignalQuality, cellSignalStrength });

    // TODO(derek):
    //   We're hard coding 100A here for now until we have multiple products with different max line currents
    //   We will need to do some work to get this hoisted out of the frontend working with backend and embedded teams
    resultantValues.maxLineCurrent = 100;

    // By observation the telemetry values that come back can be sparse / not share the same timestamps.
    // This seems generally okay in that we're requesting a short duration to get spot values but we may want
    // to use the same reference point to pull out values at that time. In the meantime, this ensures we're
    // only showing either "time to full" OR "time to empty" in the case where we end up pulling both values from
    // datapoints (even if for a single timestamp we should never see both metrics)
    resultantValues.timeToEmpty = !!timeToFull && (netPower ?? 0) > 0 ? -1 : timeToEmpty;
    resultantValues.timeToFull = (netPower ?? 0) <= 0 ? -1 : timeToFull;

    resultantValues.mode = resolveMode({ pcsState, switchMode }, { t });

    return resultantValues;
}
