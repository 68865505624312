import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import {
    getDatadogApplicationID,
    getDatadogClientToken,
    getDatadogMaskUserInput as getDatadogPrivacyLevel,
    getGitSHA,
    getGQLOrigin,
    getReactAppEnv,
} from 'environment';

import { ISession } from '../types';

export default function initDataDog(session: ISession) {
    // listens to 'signedIn' events and sets the DataDog user context
    const registerSignInHandler = (session: ISession) => {
        session.on('signedIn', event => {
            // user id
            const id: string | undefined = event.data ? event.data['id'] : undefined;

            // user email
            const email: string | undefined = event.data ? event.data['email'] : undefined;

            if (id && email) {
                const user = { id, email };
                // TODO(will): do we actually need to set both, or does setting one set all?
                datadogRum.setUser(user);
                datadogLogs.setUser(user);
            }
        });
    };

    // listens to 'signedOut' events and clears the DataDog user context
    const registerSignOutHandler = function (session: ISession) {
        session.on('signedOut', () => {
            // TODO(will): do we actually need to clear both, or does clearing one clear all?
            datadogRum.clearUser();
            datadogLogs.clearUser();
        });
    };

    // initialize Datadog RUM
    // see https://docs.datadoghq.com/real_user_monitoring/browser/#npm for all config options
    const initializeRUM = function (applicationID: string, clientToken: string) {
        const gqlEndpoint = getGQLOrigin();

        // see: https://docs.datadoghq.com/real_user_monitoring/browser/#npm for all config options
        const datadogRumConfig: RumInitConfiguration = {
            applicationId: applicationID,
            clientToken: clientToken,
            site: 'datadoghq.com',
            service: 'moxie-frontend',
            env: getReactAppEnv(),
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: getDatadogPrivacyLevel(),
            allowedTracingUrls: gqlEndpoint ? [gqlEndpoint] : undefined,

            // NOTE(will): we use the repo git sha as our version number
            version: getGitSHA(),
        };

        datadogRum.init(datadogRumConfig);
    };

    const initializeLogs = function (applicationID: string, clientToken: string) {
        datadogLogs.init({
            clientToken: clientToken,
            // TODO(will): move to config
            site: 'datadoghq.com',
            service: 'moxie-frontend',
            env: getReactAppEnv(),

            sessionSampleRate: 100,
            forwardErrorsToLogs: true,
            forwardReports: ['csp_violation', 'deprecation', 'intervention'],

            version: getGitSHA(),
        });
    };

    const applicationID = getDatadogApplicationID();
    const clientToken = getDatadogClientToken();

    if (applicationID && clientToken) {
        // TODO(will): config switch for enabling/disabling RUM entirely
        initializeRUM(applicationID, clientToken);

        // TODO(will): config switch for enabling/disabling logging entirely
        initializeLogs(applicationID, clientToken);

        registerSignInHandler(session);
        registerSignOutHandler(session);
    }
}
