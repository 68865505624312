import { ComponentProps, forwardRef, ReactElement, ReactNode, Ref } from 'react';

import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import TransitionContentHeight from '../../layout/TransitionContentHeight';
import Text from '../../Text';

interface Props
    extends Omit<
        OutlinedTextFieldProps,
        'variant' | 'InputProps' | 'classes' | 'color' | 'defaultValue' | 'InputLabelProps' | 'size' | 'helperText'
    > {
    /**
     * Counterpart to "error" prop. This is the positive take on that field.
     */
    valid?: boolean;

    /**
     * Designs call this "static" state but adopting the HTML attribute name here
     * as defined by MUI's TextField
     */
    readOnly?: boolean;

    /**
     * Adds additional UI to the start of the Input, likely an Icon or IconButton (size="sm")
     */
    startAdornment?: ReactNode;

    /**
     * Adds additional UI to the end of the Input, likely an Icon or IconButton (size="sm")
     */
    endAdornment?: ReactNode;

    /**
     * Field name is the primary text for the Input's label.
     * It is optional so the Input can be used outside of forms
     * as a more "app-y" general purpose input
     */
    fieldName?: ReactNode;

    /**
     * Caption supplies hints and inline error messages related to the
     * Input field
     */
    caption?: ReactNode;

    /**
     * The minimum required characters for the input value
     * @link {MDN docs | https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#minlength}
     */
    minLength?: number;

    /**
     * The maximum characters for the input value, text entered after this limit is met
     * will be ignored
     * @link {MDN docs | https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#maxlength}
     */
    maxLength?: number;

    /**
     * Ref to the inputs container required for use with MUI Autocomplete to position the Menu
     */
    rootRef?: Ref<HTMLDivElement>;
}

/**
 * Presenter component for making a `TextField` label look like an `Input` field name.
 *
 * TODO: Replace with `FieldLabel` component
 */
function InputFieldName({
    fieldName,
    readOnly,
    required,
}: {
    fieldName?: ReactNode;
    readOnly?: boolean;
    required?: boolean;
}): ReactElement {
    return (
        <>
            {fieldName}{' '}
            {!readOnly && required && (
                <Text as="span" variant="inherit" color="danger">
                    *
                </Text>
            )}
        </>
    );
}

/**
 * Styling props for components compatible with MUI's `TextField`
 * to make said component look like the MoxieDS `Input` component.
 */
export function useInputStyleProps({
    hasCaption,
    valid,
    type,
}: {
    hasCaption?: boolean;
    valid?: boolean;
    type?: string;
} = {}): Pick<ComponentProps<typeof TextField>, 'className' | 'sx' | 'variant'> {
    const isHiddenType = type === 'hidden';

    return {
        className: valid ? 'is-valid' : '',
        variant: 'outlined',
        sx: theme => ({
            height: isHiddenType ? 0 : null,
            visibility: isHiddenType ? 'hidden' : null,
            position: isHiddenType ? 'absolute' : null,

            '> .MuiFormHelperText-root': {
                marginTop: hasCaption ? null : 0,
                transition: 'margin 0.25s ease',
            },

            '&.is-valid .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                    boxShadow: theme.customShadows.successHighlight,
                },

                '> .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.border.success,
                },

                '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.border.success,
                },
            },
        }),
    };
}

/**
 * Input is responsible for collecting text or text like inputs from a user (i.e. password, etc)
 * @link {Input (Form) Component Design Docs | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=7%3A3769&mode=dev}
 */
export default forwardRef(function Input(
    {
        valid,
        startAdornment,
        endAdornment,
        readOnly,
        fieldName,
        caption,
        required,
        minLength,
        maxLength,
        type,
        rootRef,
        value,

        inputProps,
        ...props
    }: Props,
    ref: Ref<HTMLInputElement>,
): ReactElement {
    const shouldRenderLabel = !!fieldName;
    const shouldRenderCaption = !!caption;

    const { className, variant, sx } = useInputStyleProps({ hasCaption: shouldRenderCaption, valid, type });

    return (
        <TextField
            {...props}
            /**
             * Force MUI into controlled mode
             */
            value={value ?? ''}
            type={type}
            className={className}
            variant={variant}
            sx={normalizeSxProp(sx).concat(
                readOnly
                    ? {
                          '.MuiSelect-select': {
                              cursor: 'text',
                          },
                      }
                    : [],
                props.sx ?? [],
            )}
            InputLabelProps={{
                // MUI will hide the placeholder unless we force shrink
                // shrink is their default input label behavior which animates
                // the label when the input is typed into but we do not use this
                // and actively disable it
                shrink: true,
            }}
            label={
                shouldRenderLabel ? (
                    <InputFieldName fieldName={fieldName} readOnly={readOnly} required={required} />
                ) : null
            }
            // We animate the caption / helperText such that changes to the
            // helper text don't cause an immediate layout shift and jerky
            // repositioning of elements below the Input
            helperText={<TransitionContentHeight content={caption} />}
            inputRef={ref}
            SelectProps={{
                ...props.SelectProps,
                IconComponent: readOnly ? 'div' : undefined,
            }}
            InputProps={{
                startAdornment,
                endAdornment,
                readOnly,
                ref: rootRef,

                inputProps: {
                    /**
                     * MUI Autocomplete collaborates with the underlying input element
                     * via inputProps. Without this things mostly work except for the
                     * popupIcon button does not properly open the autocomplete menu.
                     * This is due to the misplaced onMouseDown event handler if the inputProps
                     * are flatened into this components props.
                     */
                    ...inputProps,

                    minLength,
                    maxLength,

                    /**
                     * Default hide 1 password, expose this for signin fields
                     * For now simply enable it for email and password fields but
                     * we may want to expose this a a prop
                     */
                    'data-1p-ignore': /^(email|password)$/.test(type ?? '') ? false : true,
                },
            }}
            FormHelperTextProps={{
                // TODO(derek): The default FormHelperTextProp is a P, but
                // it is invalid to have a descendant div in a P tag.
                // component is totally allowed on FormHelperText and this works
                // but typescript is unhappy for some reason
                // @ts-ignore
                component: 'div',
            }}
        />
    );
});
