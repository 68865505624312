import { TIME_CELL_HEIGHT } from 'app/components/compounds/Schedule/constants';

import CSSDimension from 'design-system/CSSDimension';

export enum Lane {
    Unavailable,
    Available,
    Offline,
}

const HEADING_BOTTOM_PADDING = CSSDimension.fromPixels(16);

/**
 * These static values are necessary dynamically calculate the row height when expanded.
 */
export const dimensions = {
    ALLOCATION_HEIGHT: CSSDimension.fromPixels(164),
    EXPANDED_OFFSET_BASE: CSSDimension.fromPixels(150),
    HEADING_BOTTOM_PADDING,
    HEADING_HEIGHT: CSSDimension.fromPixels(HEADING_BOTTOM_PADDING.asValue('px') + TIME_CELL_HEIGHT.asValue('px')),
    ROW_COLLAPSED_HEIGHT: CSSDimension.fromPixels(113),
    ROW_EXPANDED_HEIGHT: CSSDimension.fromPixels(205),
} as const;

/**
 * The result of a delete allocation procedure.
 */
export enum DeleteAllocationResult {
    /**
     * When a schedule for an allocation were successfully deleted.
     */
    Error = 'Error',
    /**
     * When all schedule for an allocation were successfully deleted.
     */
    Success = 'Success',
    /**
     * When the user denied a confirmation step necessary to complete the procedure.
     * i.e. The user clicked "Cancel" on a confirmation dialog, after the application
     * detects an unexpected timeline for device instance schedules.
     */
    UserDenied = 'UserDenied',
}
