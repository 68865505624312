import { memo, ReactElement } from 'react';
import { DateTime } from 'luxon';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

import { resolveDateTime } from 'app/core/date-time';
import { ResolvableDateTime } from 'app/core/types';

import { TranslateFunction, useI18n } from 'i18n';

import useTheme from 'styles/theme';

import { CHART_X_AXIS_HEIGHT } from '../config';
import { FaultGroup } from '../types';
import renderBars from './renderBars';
import renderFaultNameAxis from './renderFaultNameAxis';
import renderTimeAxis from './renderTimeAxis';

interface Props {
    data?: FaultGroup[];

    start?: ResolvableDateTime;

    end?: ResolvableDateTime;

    xAxisOnly?: boolean;

    isScrubberLocked?: boolean;

    onScrubTime?: (event: { value: { timestamp: number; x: number } }) => void;

    onScrubberLockChange?: (event: { value: boolean }) => void;
}

/**
 * Formats the historical faults data for display with ReCharts
 */
function toSeries(
    data: FaultGroup[] | undefined,
    { t, start, end }: { t: TranslateFunction; start: number; end: number },
): { [faultInstanceOrName: string]: string | [start: number, end: number] }[] {
    return (data ?? []).map(x => {
        return {
            name: t(`device_fault.${x?.name.toLowerCase()}.name`),
            ...x.faults.reduce((acc, cur, index) => {
                const faultStartTime = DateTime.fromISO(cur.firstSeen).toMillis();
                const faultEndTime = cur.resolved
                    ? DateTime.fromISO(cur.resolved).toMillis()
                    : DateTime.now().toMillis();

                return {
                    ...acc,
                    [`f${index}`.toLowerCase()]: [
                        faultStartTime > start ? faultStartTime : start,
                        faultEndTime < end ? faultEndTime : end,
                    ],
                };
            }, {}),
        };
    });
}

function getMaxFaults(data) {
    return (data ?? []).map(x => x?.faults.length).sort((a, b) => b - a)[0] ?? 1;
}

function HistoricalFaultsChart({
    start,
    end,
    data,
    xAxisOnly,
    isScrubberLocked,
    onScrubTime,
    onScrubberLockChange,
}: Props): ReactElement {
    const theme = useTheme();
    const { t, format } = useI18n();

    const maxFaults = getMaxFaults(data);
    const _start = resolveDateTime(start).toMillis();
    const _end = resolveDateTime(end).toMillis();
    const _data = toSeries(data, { t, start: _start, end: _end });

    const onMouseMove = x => {
        if (isScrubberLocked) return;

        if (!!x) {
            onScrubTime?.({
                value: {
                    timestamp: DateTime.fromJSDate(x.xValue).toMillis(),
                    x: x.chartX,
                },
            });
        }
    };

    const onChartClick = () => {
        onScrubberLockChange?.({ value: !isScrubberLocked });
    };

    return (
        <ResponsiveContainer width="100%" height={xAxisOnly ? CHART_X_AXIS_HEIGHT : '100%'}>
            <BarChart
                margin={{ top: 0, right: 0, left: parseInt(theme.spacing(3), 10), bottom: 0 }}
                layout="vertical"
                stackOffset="none"
                data={_data}
                onClick={onChartClick}
                onMouseMove={onMouseMove}
            >
                {/* NOTE(Derek): Strangely the tooltip is required for the chart event handlers to get the chart points as arguments */}
                <Tooltip shared={false} content={() => null} />

                <CartesianGrid strokeDasharray="3 3" horizontal={false} />

                {renderTimeAxis({
                    hide: !xAxisOnly,
                    start: _start,
                    end: _end,
                    formatTimeAxis: ts => format.timeAxis(ts),
                })}
                {renderFaultNameAxis({ isSpacer: xAxisOnly })}

                {renderBars({ start, isSpacer: xAxisOnly, maxFaults, data, theme })}
            </BarChart>
        </ResponsiveContainer>
    );
}

// The chart is being memoized as it can contain hundreds or thousands of nodes
export default memo(HistoricalFaultsChart);
