// types
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import EditAddressModal, { SelectedLocation } from 'app/components/compounds/EditAddressModal';
import Icon from 'app/components/primitives/Icon';
import Text from 'app/components/primitives/Text';

import { AccountLocationInput, AccountLocationUpdateInput } from 'generated/graphql';

import { useI18n } from 'i18n';

import { AccountInfo } from '../../../pages/ProfilePage/AccountSettings/useAccountSettings';

export interface AccountLocationsProps {
    accountLocations: NonNullable<AccountInfo['billingAddressLocation']>[];
    onEdit?: (id: string) => void;
    onAdd?: () => void;
    isEditModalOpen: boolean;
    onCloseEditModal: () => void;
    updatingID?: string;
    onSave?: (
        id: string,
        accountLocationUpdateInput: Omit<AccountLocationUpdateInput, 'accountID' | 'id'>,
    ) => Promise<boolean>;
    onDelete?: (id: string) => Promise<void>;
    onSaveNew?: (accountLocationInput: Omit<AccountLocationInput, 'accountId'>) => Promise<boolean>;
    onLocationSelected?: (location: SelectedLocation) => Promise<void>;

    sx?: SxProps<Theme>;
}

export function AccountLocations({
    accountLocations,
    onAdd,
    onEdit,
    isEditModalOpen,
    onCloseEditModal,
    updatingID,
    onSave,
    onDelete,
    onSaveNew,
    onLocationSelected,

    sx,
}: AccountLocationsProps) {
    const { t } = useI18n();

    return (
        <Box>
            <TableContainer sx={sx}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingY: 3 }}>{t('account_settings.label.contact_name')}</TableCell>
                            <TableCell sx={{ paddingY: 3 }}>{t('account_settings.label.phone_number')}</TableCell>
                            <TableCell sx={{ paddingY: 3 }}>{t('account_settings.label.address')}</TableCell>

                            {onEdit && <TableCell sx={{ paddingY: 5 }} />}
                            {onDelete && <TableCell sx={{ paddingY: 5 }} />}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {accountLocations?.map(location => {
                            return (
                                <TableRow key={location.id}>
                                    <TableCell sx={{ paddingY: 5 }}>{location.contactName}</TableCell>

                                    <TableCell sx={{ paddingY: 5 }}>{location.contactPhone}</TableCell>

                                    <TableCell sx={{ paddingY: 5 }}>{location.formattedAddress}</TableCell>

                                    {onEdit && onSave && (
                                        <TableCell sx={{ paddingY: 5 }}>
                                            <IconButton onClick={() => onEdit(location.id)}>
                                                <Icon name="edit" size="sm" />
                                            </IconButton>
                                        </TableCell>
                                    )}

                                    {onDelete && (
                                        <TableCell sx={{ paddingY: 5 }}>
                                            <IconButton
                                                onClick={() => {
                                                    onDelete(location.id);
                                                }}
                                            >
                                                <Icon name="trash" size="sm" />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {onLocationSelected && (
                <EditAddressModal
                    isOpen={isEditModalOpen}
                    onClose={onCloseEditModal}
                    onSave={onLocationSelected}
                    accountLocationToEdit={accountLocations.find(location => location.id === updatingID)}
                />
            )}

            {onAdd && onSaveNew && (
                <Button variant="outlined" sx={{ mb: 6 }} startIcon={<Icon name="add" size="sm" />} onClick={onAdd}>
                    <Text variant="detail">{t('profile_page.cta.new_address')}</Text>
                </Button>
            )}
        </Box>
    );
}
