import { ReservationStatus, useUpdateReservationStatusMutation } from 'generated/graphql';

/**
 * Hook responsible for providing the means to update a reservations status
 */
export default function useUpdateReservationStatus({
    onError,
    onCompleted,
}: {
    onError: () => void;
    onCompleted: (data: { status: ReservationStatus }) => void;
}): (input: { id: string; status: string }) => Promise<void> {
    const [updateReservationStatusMutation] = useUpdateReservationStatusMutation();

    return async ({ id, status }) => {
        const reservationStatus = ReservationStatus[status ?? ''];

        // TODO(Derek): Strangely the mutation does update the cache but does not cause
        // the useQuery hook for getReservation does not cause a re-render. This appears related
        // to fetchPolicies but more investigation is required.
        await updateReservationStatusMutation({
            variables: {
                id,
                status: reservationStatus,
            },
            // TODO(Derek): Strangely the optimistic response does not appear to be applied
            // on the first change. I believe this has to do with the active fetchPolicy, but more
            // investigation is required.
            optimisticResponse: {
                updateReservation: {
                    __typename: 'Reservation',
                    id,
                    status: reservationStatus,
                },
            },
            onError,
            onCompleted: data => {
                return onCompleted({ status: data?.updateReservation?.status });
            },
        });
    };
}
