import { enhanceAsyncError } from 'app/core/error';

import { useUpdateDeviceInstanceMutation } from 'generated/graphql';

import { UnitUpdateInput } from './types';

/**
 * Hook responsible for producing a function for syncing MPU detail updates with the server
 */
export default function useUpdateMPUDetails(): (details: UnitUpdateInput) => Promise<void> {
    const [updateDeviceInstanceMutation] = useUpdateDeviceInstanceMutation();

    return async function updateMPUDetails(values) {
        await enhanceAsyncError(
            updateDeviceInstanceMutation({
                variables: {
                    input: {
                        id: values.id,
                        name: values.name,
                        deviceModelID: values.modelID,
                        assetID: values.assetID,
                        ownerAccountID: values.ownerID,
                        externalID: values.externalID || null,
                        serialNumber: values.serialNumber,
                        serviceAreaID: values.serviceAreaID === 'unassigned' ? null : values.serviceAreaID,
                    },
                },
            }),
        );
    };
}
