import { DateTime, Duration } from 'luxon';

import { MergeEventsPayload, ScheduleEvent } from './types';

function mergeEventsFallback<E extends ScheduleEvent>({ earliestStart, eventA, latestEnd }: MergeEventsPayload<E>): E {
    return {
        ...eventA,
        end: latestEnd,
        start: earliestStart,
    };
}
/**
 * Event bars snap to days in the chart, this causes the events overlap in the chart
 * even if their timestamps do not.
 * As a result, we must push the start and end timestamps to the start and the end of the day,
 * to calculate when the events overlap.
 */
function getScheduleEventDays({ end, start }: ScheduleEvent): { end: DateTime; start: DateTime } {
    return {
        end: DateTime.fromISO(end).endOf('day'),
        start: DateTime.fromISO(start).startOf('day'),
    };
}

/**
 * A function called to merge two schedule events.
 *
 * Events are merged when two or more events have overlapping days,
 * and an event's duration would be completely covered by other events.
 * This generally occurs when multiple events occur within the same day.
 */
type MergeEventsFn<E extends ScheduleEvent> = (payload: MergeEventsPayload<E>) => E;

/**
 * Merges events that have days which completely overlap with days of other events.
 * Event bars snap to days in the chart, so events should be merged to display multiple events on one day.
 */
export function mergeOverlappingEvents<E extends ScheduleEvent>(
    mergeEvents: MergeEventsFn<E> = mergeEventsFallback,
    events: E[] = [],
): E[] {
    /** Events to process. */
    const eventQueue = new Map(events.map(event => [event.id, event] as const));
    /** Merged events. */
    const nextEvents = new Map<E['id'], E>();

    while (eventQueue.size > 0) {
        /** The last event in the queue. */
        const subject = Array.from(eventQueue.values()).at(eventQueue.size - 1);

        if (!subject) break;

        const subjectDateTimes = getScheduleEventDays(subject);
        const relevantEvents = [...Array.from(eventQueue.values()), ...Array.from(nextEvents.values())];
        const overlappingEvents = relevantEvents.filter(event => {
            // An event can't overlap itself
            if (event.id === subject.id) return false;

            const eventDateTimes = getScheduleEventDays(event);

            // `true` if the events overlap each other.
            // Because the start and end times for each event
            // are rounded to the start and end of the day,
            // the date times can be the same.
            return subjectDateTimes.start <= eventDateTimes.end && eventDateTimes.start <= subjectDateTimes.end;
        });

        // If the subject event has doesn't overlap with any other events,
        // it can be rendered in the chart as is.
        if (!overlappingEvents.length) {
            nextEvents.set(subject.id, subject);
            eventQueue.delete(subject.id);
            continue;
        }

        const totalSubjectDuration = subjectDateTimes.end.diff(subjectDateTimes.start).toMillis();
        /** How much time does the subject event have that doesn't overlap with other events. */
        const leftoverSubjectDuration = overlappingEvents.reduce<number>((result, overlappingEvent) => {
            const overlappingEventDateTimes = getScheduleEventDays(overlappingEvent);
            const overlappingEventStartsBeforeSubject =
                overlappingEventDateTimes.start <= subjectDateTimes.start &&
                overlappingEventDateTimes.end <= subjectDateTimes.end;
            const overlapDuration = overlappingEventStartsBeforeSubject
                ? overlappingEventDateTimes.end.toMillis() - subjectDateTimes.start.toMillis()
                : subjectDateTimes.end.toMillis() - overlappingEventDateTimes.start.toMillis();

            return result - overlapDuration;
        }, totalSubjectDuration);

        // Allow for 1 millisecond of margin of error, because date time math is still imperfect after all these years.
        const almostOneDay = Duration.fromObject({ day: 1 }).minus({ millisecond: 1 }).toMillis();

        // If the subject has at least one day of time that doesn't overlap with other events,
        // it can be rendered in the chart as is.
        if (leftoverSubjectDuration >= almostOneDay) {
            nextEvents.set(subject.id, subject);
            eventQueue.delete(subject.id);
            continue;
        }

        // Otherwise the event should be merged into another event.

        // The subject will be merged with the last overlapping event.
        const lastOverlappingEvent = overlappingEvents[overlappingEvents.length - 1];

        // Ensure that both events are completely removed from relevant events,
        // so that their parts aren't included in the leftover duration calculations again.
        eventQueue.delete(subject.id);
        nextEvents.delete(subject.id);
        eventQueue.delete(lastOverlappingEvent.id);
        nextEvents.delete(lastOverlappingEvent.id);

        const lastOverlappingEventDateTimes = getScheduleEventDays(lastOverlappingEvent);
        const latestEnd =
            DateTime.fromMillis(
                Math.max(subjectDateTimes.end.toMillis(), lastOverlappingEventDateTimes.end.toMillis()),
            ).toISO() ?? '';
        const earliestStart =
            DateTime.fromMillis(
                Math.min(subjectDateTimes.start.toMillis(), lastOverlappingEventDateTimes.start.toMillis()),
            ).toISO() ?? '';

        const mergedEvent = mergeEvents({
            earliestStart,
            eventA: lastOverlappingEvent,
            eventB: subject,
            latestEnd,
        });

        // Add the merged event to the queue for it to be
        // potentially merged with other events.
        eventQueue.set(mergedEvent.id, mergedEvent);
    }

    return Array.from(nextEvents.values());
}
