import { useState } from 'react';
import { DateTime, Duration } from 'luxon';

import { ISO8601 } from 'app/core/types';

import { useListActiveAlertsQuery } from 'generated/graphql';

import { FormatObject, TranslateFunction } from 'i18n';

import toAlert from './toAlert';
import { Alert } from './types';

interface Input {
    reservationID?: string;
    pollInterval?: number;
    t: TranslateFunction;
    format: FormatObject;
}

interface Output {
    loading: boolean;
    lastUpdateTimestamp: ISO8601 | null;
    data: Alert[];
}

const POLL_INTERVAL = Duration.fromObject({ minutes: 5 }).toMillis();

/**
 * Responsible for fetching and transforming active alerts
 */
export default function useAlerts({ reservationID, pollInterval, t, format }: Input): Output {
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<ISO8601 | null>(null);

    const { loading, data } = useListActiveAlertsQuery({
        pollInterval: pollInterval ?? POLL_INTERVAL,
        // Setting this to true seems to be the only way to ensure onCompleted is called
        // when the query is polled
        notifyOnNetworkStatusChange: true,
        variables: {
            reservationIDs: reservationID ? [reservationID] : undefined,
        },

        onCompleted: () => {
            setLastUpdateTimestamp(DateTime.now().toISO());
        },
    });

    const result = (data?.listActiveAlerts ?? []).map(x => toAlert(x, { t, format }));

    return {
        loading,
        lastUpdateTimestamp,
        data: result,
    };
}
