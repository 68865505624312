import { ComponentProps, ReactElement } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import useReservations from './useReservations';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {}

/**
 * Provides ability to select multiple reservations
 */
export default function MultiReservationSelector({ value, onChange, ...props }: Props): ReactElement {
    const { loading, reservations } = useReservations();

    return (
        <MultiSelect
            {...props}
            loading={loading}
            disabled={!props?.readOnly && !!props?.disabled}
            value={value}
            options={reservations.map(x => ({ id: x?.id, label: x?.name || x?.id.substring(0, 5) }))}
            onChange={onChange}
        />
    );
}
