// For some reason importing from `app/components` or `app/components/primitives`
// breaks the build for the app and storyboook
import Icon from 'app/components/primitives/Icon';

export default {
    MuiCheckbox: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: 0,

                '&:not(.Mui-checked) > .mx-icon': {
                    fill: theme.palette.background.infoDark.main,
                },

                '&:not(.Mui-checked).Mui-disabled > .mx-icon': {
                    fill: theme.palette.text.disabled,
                },

                '&.Mui-disabled.Mui-checked > .mx-icon': {
                    fill: theme.palette.background.infoDark.light,
                },

                '> .MuiTouchRipple-root': {
                    padding: theme.spacing(4),
                    boxSizing: 'content-box',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                },
            }),
        },
        defaultProps: {
            icon: <Icon name="checkbox-blank" />,
            checkedIcon: <Icon name="checkbox" />,
            indeterminateIcon: <Icon name="checkbox-indeterminate" />,
        },
    },
};
