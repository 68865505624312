export default function getDayTextColor({ dayBackgroundColor, disabled, outsideCurrentMonth, theme }) {
    if (!!dayBackgroundColor) {
        return theme.palette.getContrastText(dayBackgroundColor ?? theme.palette.common.white);
    }

    if (disabled) return theme.palette.text.disabled;

    if (outsideCurrentMonth) {
        return theme.palette.text.secondary;
    }

    return theme.palette.text.primary;
}
