import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers';

import { TranslateFunction } from 'i18n';

import { DateTimeRangeError } from './constants';
import { DateTimeRangeValidationError } from './types';

/**
 * Translates the validation error into a error message to display to the user
 */
export default function formatErrorMessage({
    endError,
    minEndDate,
    minStartDate,
    rangeError,
    startError,
    t,
}: {
    endError: DateValidationError | TimeValidationError;
    minEndDate: string;
    minStartDate: string;
    rangeError: DateTimeRangeValidationError;
    startError: DateValidationError | TimeValidationError;
    t: TranslateFunction;
}) {
    if (!startError && !endError) return null;

    const messages: string[] = [];

    if (startError === 'disablePast') {
        messages.push(t('error.start_date_in_past'));
    }

    if (startError === 'minDate') {
        messages.push(t('error.start_date_before_min_date', { date: minStartDate }));
    }

    if (endError === 'disablePast') {
        messages.push(t('error.end_date_in_past'));
    }

    if (endError === 'minDate') {
        messages.push(t('error.end_date_before_min_date', { date: minEndDate }));
    }

    if (rangeError === DateTimeRangeError.StartAfterEnd) {
        messages.push(t('error.end_date_time_after_start'));
    }

    return messages.join(' ');
}
