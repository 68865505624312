import { ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import TransitionContentHeight from '../layout/TransitionContentHeight';
import Text from '../Text';

interface Props {
    /**
     * The unique ID indentifying the caption element, used to associated input elements with
     * the descriptive text.
     */
    id?: string;

    /**
     * Whether the associated form input field is in an error state
     */
    error?: boolean;

    /**
     * The caption content to display, helper text or inline error
     */
    caption?: ReactNode;
}

/**
 * Responsible for layout and styling for input field captions
 */
export default function FieldCaption({ id, caption, error }: Props): ReactElement {
    const shouldRenderCaption = !!caption;

    return (
        <Box
            maxWidth="md"
            sx={{
                mt: shouldRenderCaption ? 3 : 0,
                transition: 'margin 0.25s ease',
            }}
        >
            <TransitionContentHeight
                content={
                    <Text id={id} aria-live="polite" variant="detail" color={error ? 'danger' : 'secondary'}>
                        {caption}
                    </Text>
                }
            />
        </Box>
    );
}
