import { Box } from '@mui/material';

import { CircularProgress } from 'app/components/primitives';
import { MPUDetailSectionID } from 'app/core/Navigation/constants';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { Tooltip } from '../Tooltip';
import { MPUTileHealth } from './constants';

export function MPUTileStateOfCharge({
    health,
    lowStateOfChargeThreshold = 20,
    mpuID,
    stateOfCharge,
}: {
    /**
     * A value representing the overall health of the MPU.
     */
    health: `${MPUTileHealth}` | undefined;
    /**
     * The threshold at which the MPU is considered low on charge.
     * A number between 0 and 100.
     *
     * @default 20
     */
    lowStateOfChargeThreshold: number | undefined;
    /**
     * The ID of the MPU.
     */
    mpuID: string | undefined;
    /**
     * A number between 0 and 100 representing the state of charge of the MPU.
     */
    stateOfCharge: number | undefined;
}) {
    const { t } = useI18n();
    const isLow = stateOfCharge !== undefined && stateOfCharge <= lowStateOfChargeThreshold;
    const hasDarkBackground = health === MPUTileHealth.CriticalFault || health === MPUTileHealth.Disconnected;
    const href = mpuID
        ? getPathByName('MPU_DETAIL', { hash: MPUDetailSectionID.Status, params: { mpuID: mpuID } })
        : undefined;

    return (
        <Tooltip content={t('mpu_tile.tooltip.state_of_charge')}>
            {/**
             * This `Box` is used as the anchor for the tooltip,
             * because `CircularProgress` can't properly set a `ref`.
             * This is because `CircularProgress` uses `Layer` which uses
             * styled-components with the `Box` element, causing `ref`
             * to not be set as expected.
             *
             * TODO(Morris): Refactor `Layer` to not use `styled-components`.
             */}
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <CircularProgress
                    color={isLow ? 'danger' : 'success'}
                    light={hasDarkBackground}
                    size="extra-small"
                    to={href}
                    value={stateOfCharge}
                />
            </Box>
        </Tooltip>
    );
}
