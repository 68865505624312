import { useCallback } from 'react';

import { useActivateUserMutation, useDeactivateUserMutation } from 'generated/graphql';

type UpdateActiveFn = (input?: { id?: string }) => Promise<void>;

/**
 * Responsible for initiating the network interaction to activate a user who has beeen deactivated
 */
export function useActivateUser(userID?: string): UpdateActiveFn {
    const [activateUserMutation] = useActivateUserMutation();

    return useCallback(
        async ({ id }: { id?: string } = {}) => {
            await activateUserMutation({ variables: { userID: id || userID || '' } });
        },
        [activateUserMutation, userID],
    );
}

/**
 * Responsible for initiating the network interaction to deactivate a user who is active
 */
export function useDeactivateUser(userID?: string): UpdateActiveFn {
    const [deactivateUserMutation] = useDeactivateUserMutation();

    return useCallback(
        async ({ id }: { id?: string } = {}) => {
            await deactivateUserMutation({ variables: { userID: id || userID || '' } });
        },
        [deactivateUserMutation, userID],
    );
}
