import { ReactElement } from 'react';

import { PossibleDate } from './types';
import useRelativeTime from './useRelativeTime';

interface Props {
    /**
     * The date time to display as relative (i.e. "1 hour ago")
     */
    time: PossibleDate;

    /**
     * The number of seconds to wait before updating the relative time string
     * default: 1
     */
    updateInterval?: number;
}

export default function RelativeTime({ time, updateInterval = 1 }: Props): ReactElement {
    const { relativeTime } = useRelativeTime(time, updateInterval * 1000);

    return <>{relativeTime}</>;
}
