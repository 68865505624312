import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { array, object, string } from 'yup';

import Stack from '@mui/material/Stack';

import { AccountLocationSelector, Input } from 'app/components';
import { useSession } from 'app/core/Session';
import useReservation from 'app/pages/AdminReservationPage/useReservation';

import { useI18n } from 'i18n';

import { useReservationItems } from '../../ReservationSchedule/hooks';
import DirectionSelector from './components/DirectionSelector';
import LineItemTable from './components/LineItemTable';
import MPUSelector from './components/MPUSelector';
import SpecialInstructionsInput from './components/SpecialInstructionsInput';

const SPACING = 5;

interface Address {
    id: string;
    formattedAddress: string;
}

interface MPU {
    id: string;
    name: string;
}

interface FormFields {
    billOfLadingNumber: string;
    moxionAddress: Address;
    clientAddress: Address;
    direction: string;
    mpu: MPU;
    specialInstructions: string;
    trailerNumber: string;
    lineItems: {
        quantity: string;
        type: string;
        weight: string;
        hmx: string;
        description: string;
    }[];
}

interface BillOfLadingInternalArg {
    billOfLadingNumber: string; // 8675309
    moxionAddress: string; // 123 Fake Street, \n Fake City, Fake State, Fake Zip
    clientAddress: string; // 123 Fake Street, \n Fake City, Fake State, Fake Zip
    direction: string; // Outbound
    mpu: string; // C-3PO
    specialInstructions: string; // Please handle with care.
    trailerNumber: string; // 123456
    lineItems: {
        quantity: string; // 1
        type: string; // Box
        weight: string; // 100 lbs
        hmx: string; // N/A
        description: string; // This is a box.
    }[];
}

interface BillOfLadingInternalProps {
    onSubmit: (values: BillOfLadingInternalArg) => void;
    formId?: string;
}

const BillOfLadingInternal: React.FC<BillOfLadingInternalProps> = ({ onSubmit, formId }) => {
    // Pull in Reservation for Initial Data
    const { reservationID = '' } = useParams();
    const { reservation } = useReservation({
        reservationID,
    });
    const { data: reservationItems } = useReservationItems(reservationID);

    const { t } = useI18n();

    // Dependencies
    const accountID = reservation?.account?.id;
    const ownerAccountID = useSession().getSession().primaryAccountID;
    const mpus =
        Object.values(
            reservationItems?.reduce((acc, item) => {
                const { deviceInstanceSchedule } = item;

                const reservationItemMPUsMap =
                    deviceInstanceSchedule?.reduce((acc, schedule) => {
                        const { deviceInstance } = schedule;
                        const { id } = deviceInstance;

                        return {
                            ...acc,
                            [id]: deviceInstance,
                        };
                    }, {}) ?? {};

                return { ...acc, ...reservationItemMPUsMap };
            }, []) ?? [],
        ) ?? [];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            billOfLadingNumber: '',
            moxionAddress: {
                id: '',
                formattedAddress: '',
            },
            clientAddress: {
                id: '',
                formattedAddress: '',
            },
            direction: 'outbound',
            mpu: {
                id: '',
                name: '',
            },
            specialInstructions: '',
            trailerNumber: '',
            lineItems: [{ quantity: '', type: '', weight: '', hmx: 'N/A', description: '' }],
        },
        validationSchema: object().shape({
            billOfLadingNumber: string().optional(),
            name: string().optional(),
            moxionAddress: object().optional().shape({
                id: string().optional(),
                formattedAddress: string().optional(),
            }),
            clientAddress: object().optional().shape({
                id: string().optional(),
                formattedAddress: string().optional(),
            }),
            direction: string().optional(),
            mpu: object().optional().shape({
                id: string().optional(),
                name: string().optional(),
            }),
            specialInstructions: string().optional(),
            trailerNumber: string().optional(),
            lineItems: array().optional(),
        }),
        onSubmit: (values: FormFields) => {
            const moxionAddress = values.moxionAddress.formattedAddress;
            const clientAddress = values.clientAddress.formattedAddress;
            const mpu = values.mpu.name;
            onSubmit({
                ...values,
                moxionAddress,
                clientAddress,
                mpu,
            });
        },
    });

    return (
        <Stack spacing={SPACING} component="form" onSubmit={formik.handleSubmit} id={formId}>
            <Input
                name="billOfLadingNumber"
                value={formik.values.billOfLadingNumber}
                type="number"
                fieldName={t('documents.components.bill_of_lading_number')}
                onChange={e => {
                    formik.setFieldValue('billOfLadingNumber', e.target.value);
                }}
                inputProps={{
                    min: 1,
                }}
            />

            <AccountLocationSelector
                required
                name="moxionAddress"
                accountID={ownerAccountID}
                value={formik.values.moxionAddress?.id}
                onChange={e => {
                    if (e?.value?.formattedAddress) {
                        formik.setFieldValue('moxionAddress', e.value);
                    }
                }}
                fieldName={t('documents.components.moxion_address')}
            />

            <AccountLocationSelector
                required
                accountID={accountID}
                value={formik.values.clientAddress?.id}
                onChange={e => {
                    if (e?.value?.formattedAddress) {
                        formik.setFieldValue('clientAddress', e.value);
                    }
                }}
                name="clientAddress"
                fieldName={t('documents.components.client_address')}
            />

            <DirectionSelector
                value={formik.values.direction}
                onChange={value => {
                    formik.setFieldValue('direction', value);
                }}
            />

            <MPUSelector
                value={formik.values.mpu}
                mpus={mpus}
                onChange={value => {
                    formik.setFieldValue('mpu', value);
                }}
            />

            <SpecialInstructionsInput
                value={formik.values.specialInstructions}
                onChange={value => {
                    formik.setFieldValue('specialInstructions', value);
                }}
            />

            <Input
                name="trailerNumber"
                value={formik.values.trailerNumber}
                fieldName={t('documents.components.trailer_number')}
                onChange={e => {
                    formik.setFieldValue('trailerNumber', e.target.value);
                }}
            />

            <LineItemTable
                name="lineItems"
                rows={formik.values.lineItems}
                columns={[
                    { id: 'quantity', label: t('documents.components.quantity') },
                    { id: 'type', label: t('documents.components.type') },
                    { id: 'weight', label: t('documents.components.weight') },
                    { id: 'hmx', label: t('documents.components.hmx') },
                    { id: 'description', label: t('documents.components.description') },
                ]}
                onChange={rows => {
                    formik.setFieldValue('lineItems', rows);
                }}
            />
        </Stack>
    );
};

export default BillOfLadingInternal;
