import { ReservationItemInput, useAddItemsToReservationMutation } from 'generated/graphql';

import { ReservationDetail } from '../useReservation';

export default function useAddUnitsToReservation(
    reservation: Pick<ReservationDetail, 'id' | 'start' | 'end' | 'items'>,
) {
    const [addItemsToReservation] = useAddItemsToReservationMutation();

    async function addUnitsToReservation(unitsToAddCount: number) {
        const reservationItemsToAdd: ReservationItemInput[] = [];

        const unitNameBaseIndex = reservation.items.length;

        const start = reservation.start.toISO();
        const end = reservation.end.toISO();

        if (reservation.items.length === 0) {
            throw new Error('unable to add units to a reservation without reservation items');
        } else if (!(start && end)) {
            throw new Error('unable to determine start and end dates for new units');
        }

        const reservationItemBase = {
            deviceModelID: reservation.items[0].deviceModel.id ?? '',
            quantity: 1,

            start,
            deliveryDate: start,
            deliveryLocationID: reservation.items[0].deliveryLocation.id,

            end,
            pickupDate: end,
            pickupLocationID: reservation.items[0].pickupLocation.id,
        };

        for (let i = 0; i < unitsToAddCount; i++) {
            reservationItemsToAdd.push({
                ...reservationItemBase,
                name: `Unit ${unitNameBaseIndex + i + 1}`,
            });
        }

        await addItemsToReservation({
            variables: { input: { reservationID: reservation.id, reservationItems: reservationItemsToAdd } },
        });
    }

    return { addUnitsToReservation };
}
