import { useState } from 'react';
import { maxBy, minBy } from 'lodash';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';

import { Point, Series } from './types';

interface ChartZoomAPI {
    start: () => void;
    updateSelection: () => void;
    apply: () => void;
    reset: () => void;
    isActive: boolean;

    selectionStart: number | null;
    selectionEnd: number | null;

    maxYConstraint: string | number;
    minYConstraint: string | number;
    minXConstraint: string | number;
    maxXConstraint: string | number;
}

/**
 * Hook that handles the zoom state for a specific rechart chart component
 * @param series
 * @returns
 */
const useChartZoom = ({ series }: { series: Series[] }): ChartZoomAPI => {
    const [mouseDownPoint, setMouseDownPoint] = useState<number | null>(null);
    const [mousePoint, setMousePoint] = useState<number | null>(null);
    const [left, setLeft] = useState<number | string>('auto');
    const [right, setRight] = useState<number | string>('auto');

    const [bottom, setBottom] = useState<number | string>('auto');
    const [top, setTop] = useState<number | string>('auto');

    const zoom: CategoricalChartProps['onMouseUp'] = e => {
        try {
            // find min/max timestamp for selection
            let minX: number | undefined, maxX: number | undefined;

            if (mouseDownPoint === mousePoint || mouseDownPoint === null || mousePoint === null) {
                return;
            } else if (mouseDownPoint < mousePoint) {
                minX = mouseDownPoint;
                maxX = mousePoint;
            } else {
                minX = mousePoint;
                maxX = mouseDownPoint;
            }

            setLeft(minX);
            setRight(maxX);

            // find global min/max value across all visible series points
            let minY: number | undefined, maxY: number | undefined;

            for (const s of series) {
                // only look at points within the selected bounds
                const filteredData: Point[] = [];

                for (const point of s.data) {
                    if (new Date(point.timestamp).getTime() >= minX && new Date(point.timestamp).getTime() <= maxX) {
                        filteredData.push(point);
                    }
                }

                const seriesMinY = minBy(filteredData, d => d?.value)?.value;

                if (seriesMinY) {
                    if (minY) {
                        minY = Math.min(minY, seriesMinY);
                    } else {
                        minY = seriesMinY;
                    }
                }

                const seriesMaxY = maxBy(filteredData, d => d?.value)?.value;

                if (seriesMaxY) {
                    if (maxY) {
                        maxY = Math.max(maxY, seriesMaxY);
                    } else {
                        maxY = seriesMaxY;
                    }
                }
            }

            // add a 20% margin
            if (typeof minY === 'number' && typeof maxY === 'number') {
                const spread = Math.abs(maxY - minY);
                minY = minY - spread * 0.2;
                maxY = maxY + spread * 0.2;
            }

            setBottom(minY ?? 'auto');
            setTop(maxY ?? 'auto');
        } finally {
            setMouseDownPoint(null);
            setMousePoint(null);
        }
    };

    const onMouseDown: CategoricalChartProps['onMouseDown'] = e => {
        if (!e) {
            return;
        }
        setMouseDownPoint(e.activeLabel);
    };

    const onMouseMove: CategoricalChartProps['onMouseMove'] = e => {
        if (!e) {
            return;
        }

        if (mouseDownPoint) {
            setMousePoint(e.activeLabel);
        }
    };

    const zoomReset = () => {
        setLeft('auto');
        setRight('auto');
        setTop('auto');
        setBottom('auto');
        setMouseDownPoint(null);
        setMousePoint(null);
    };

    const isZoomed = top !== 'auto' || bottom !== 'auto' || left !== 'auto' || right !== 'auto';

    return {
        start: onMouseDown,
        updateSelection: onMouseMove,
        apply: zoom,
        reset: zoomReset,
        isActive: isZoomed,

        selectionStart: mouseDownPoint,
        selectionEnd: mousePoint,

        maxYConstraint: top,
        minYConstraint: bottom,
        minXConstraint: left,
        maxXConstraint: right,
    };
};

export default useChartZoom;
