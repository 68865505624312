import { GetReservationItemLatestTelemetryQueryHookResult } from 'generated/graphql';

import { useI18n } from 'i18n';

import { MetricName } from './constants';
import extractUnitStatusesFromTelemetry, { UnitStatusType } from './extractUnitStatusesFromTelemetry';
import useLatestTelemetryData from './useLatestTelemetryData';

interface Input {
    /**
     * The reservation item's id to pull telemetry for
     */
    reservationItemID?: string | undefined;

    /**
     * The device instance id to pull telemetry for
     */
    deviceInstanceID?: string | undefined;

    /**
     * Which telemetry to pull
     */
    metricNames: MetricName[];

    /**
     * Whether to enable polling for updated unit status
     */
    enablePolling?: boolean;

    /**
     * How frequently to check for updated status
     */
    pollInterval?: number;
}

interface Output extends Pick<GetReservationItemLatestTelemetryQueryHookResult, 'loading' | 'error'> {
    data: UnitStatusType;
}

/**
 * Responsible for retrieving the current system status for a given unit associated with a reservation item
 */
export default function useUnitStatus({
    reservationItemID = '',
    deviceInstanceID = '',
    metricNames,
    pollInterval = 10000, // To disable polling, set to 0
}: Input): Output {
    const { t } = useI18n();

    const { loading, error, data } = useLatestTelemetryData({
        reservationItemID,
        deviceInstanceID,
        metricNames,
        pollInterval,
    });

    return {
        loading,
        error,
        data: extractUnitStatusesFromTelemetry(data, { t }),
    };
}
