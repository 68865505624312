import { ComponentProps, MouseEvent, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Button from 'app/components/primitives/interactive/Button';
import MoxLogo from 'app/components/primitives/MoxLogo';

import ContactMenu from './ContactMenu';
import NavLink from './NavLink';
import PrimaryNavContainer from './PrimaryNavContainer';

type ButtonProps = ComponentProps<typeof Button>;

export interface NavLinkData {
    /**
     * The local routes path (as defined and accepted by react-router-dom)
     */
    to: string;
    /**
     * Event handler to know when a link was clicked
     */
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    /**
     * The iconography that complements the link label text (required by the designs)
     */
    icon: JSX.Element;
    /**
     * The link label text describing where the link leads
     */
    label: string;
}

interface Props {
    /**
     * Unique identifier to associate toggle controls for accessibility
     */
    id: string;

    /**
     * Whether the mobile nav is open and visible
     */
    isOpen: boolean;
    /**
     * Callback to notifiy consumers of intent to close the mobile nav
     */
    onClose: () => void;
    /**
     * All of the primary nav links to render
     */
    navLinks: NavLinkData[];
    /**
     * Optional primary CTA render function
     */
    renderPrimaryCTA?: (props: ButtonProps) => ReactElement;
    /**
     * The nav width
     */
    width?: number | string;
}

const NAV_BG_COLOR = 'background.contrast.main';

/**
 * Layout and presentation for our left nav where all top level navigation links live in the app
 * Includes both mobile and desktop layouts.
 */
export default function PrimaryNav({
    id,
    isOpen,
    onClose,
    navLinks,
    renderPrimaryCTA,
    width = '100%',
}: Props): ReactElement {
    const hasValidPrimaryCTA = typeof renderPrimaryCTA === 'function';

    return (
        <PrimaryNavContainer id={id} backgroundColor={NAV_BG_COLOR} isOpen={isOpen} onClose={onClose} width={width}>
            <Box px={7} pt={8} pb={7} position="sticky" top={0} sx={{ backgroundColor: NAV_BG_COLOR, zIndex: 1 }}>
                <MoxLogo type="h6" color1="background.contrast.contrastText" color2="brand.accent" />
            </Box>

            <Stack spacing={2} px={5} mb={8}>
                {navLinks.map(({ label, ...props }) => (
                    <NavLink
                        key={label}
                        {...props}
                        fullWidth
                        onClick={() => {
                            if (typeof props.onClick === 'function') {
                                props.onClick();
                            }

                            onClose();
                        }}
                    >
                        {label}
                    </NavLink>
                ))}
            </Stack>

            {hasValidPrimaryCTA && (
                <Box position="sticky" bottom={0} width="100%" sx={{ backgroundColor: NAV_BG_COLOR }}>
                    <Divider sx={{ borderColor: 'border.dark' }} />

                    {hasValidPrimaryCTA && (
                        <Box px={5} pt={8} pb={12}>
                            {renderPrimaryCTA({
                                color: 'primary',
                                fullWidth: true,
                                onClick: () => {
                                    onClose();
                                },
                            })}
                        </Box>
                    )}
                </Box>
            )}

            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,

                    // We want to fill the left nav container and make room for the scroll bar if it's required
                    // Without the light fixed padding below the scrollbar will be blocked by this UI
                    width: 'inherit',
                    pr: 4,
                }}
            >
                <Box sx={{ p: 5, backgroundColor: NAV_BG_COLOR }}>
                    <ContactMenu />
                </Box>
            </Box>
        </PrimaryNavContainer>
    );
}
