import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { AccountSelector, Button, UserRoleSelector } from 'app/components';

import { useI18n } from 'i18n';

import { FormStateChange } from '../../types';
import { User } from '../types';

interface UserRolesFormFields {
    userID: User['id'];

    accountID: User['accountID'];

    roles: User['roles'];
}

type SubmittedFields = Pick<User, 'id' | 'accountID' | 'roles'>;

interface EditUserRolesFormProps extends Partial<UserRolesFormFields> {
    /**
     * The unique id identifying the form element
     */
    id?: string;

    /**
     * Whether to include the submit CTA in the form
     */
    hideSubmitCTA?: boolean;

    /**
     * Whether to render all form fields as readOnly
     */
    readOnly?: boolean;

    /**
     * Whether to reset the form when the "mode" switches to readOnly
     * All changes will be discarded.
     * @defaultValue true
     */
    enableResetOnModeChange?: boolean;

    /**
     * Event handler to be called when the form state is changed
     */
    onFormStateChange?: (event: FormStateChange<SubmittedFields>) => void;

    /**
     * Event handler to be called when the user initiates a form submission
     */
    onSubmit: (userChanges: { value: SubmittedFields }) => Promise<void>;
}

/**
 * Responsible for rendering a form for editing basic user details, including accountID and roles
 */
export default function EditUserRolesForm({
    hideSubmitCTA,
    readOnly,
    id,
    userID,
    accountID,
    roles,
    enableResetOnModeChange = true,
    onFormStateChange,
    onSubmit,
}: EditUserRolesFormProps): ReactElement {
    const { t } = useI18n();

    const form = useFormik<UserRolesFormFields>({
        initialValues: {
            userID: userID ?? '',
            accountID: accountID ?? '',
            roles: roles ?? [],
        },
        enableReinitialize: true,

        validateOnMount: false,
        validateOnChange: true,
        validateOnBlur: true,

        validationSchema: object().shape({
            userID: string().required(),
            accountID: string().required(),
            roles: array().of(string()).optional(),
        }),

        onSubmit: async values => {
            const { userID, ...rest } = values;

            await onSubmit({ value: { id: userID, ...rest } });
        },
    });

    const { isValid, dirty, values } = form;
    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        const { userID, ...rest } = values;

        onFormStateChange?.({
            shouldDisableCTA,
            isDirty: dirty,
            isValid: isValid,
            values: { id: userID, ...rest },
        });
    }, [shouldDisableCTA, dirty, isValid, values, onFormStateChange]);

    const resetForm = form.resetForm.bind(form);

    useEffect(() => {
        if (enableResetOnModeChange && readOnly) {
            resetForm();
        }
    }, [readOnly, resetForm, enableResetOnModeChange]);

    return (
        <Box id={id} component="form" maxWidth="md" onSubmit={form.handleSubmit}>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                    <AccountSelector
                        readOnly={readOnly}
                        required={false}
                        disabled
                        fullWidth
                        name="accountID"
                        fieldName={t('admin_user_page.label.account')}
                        value={values.accountID}
                        onChange={({ value }) => {
                            form.setFieldValue('accountID', value?.id, true);
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <UserRoleSelector
                        error={!!form.errors.roles}
                        caption={form.errors.roles}
                        readOnly={readOnly}
                        variant="compact"
                        name="roles"
                        fieldName={t('admin_user_page.label.roles')}
                        value={values.roles}
                        onChange={({ name, value }) => {
                            form.setFieldValue('roles', value, true);
                        }}
                    />
                </Grid>
            </Grid>

            {!hideSubmitCTA && (
                <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_user_page.cta.save_changes')}
                    </Button>
                </Box>
            )}
        </Box>
    );
}
