enum GroupedInputVariant {
    Center = 'center',
    Left = 'left',
    Right = 'right',
}

/**
 * Style overrides to apply to a TextField or component which makes use of a TextField component with
 * variant equal to "outlined" when you want to group two inputs together. Each TextField will need the appropriate
 * styles applied via this function.
 */
export default function makeGroupedInputStyle(variant: `${GroupedInputVariant}`) {
    /**
     * Border radius CSS properties to zero-out on the input component
     */
    const borderRadiusProperties: string[] = [];

    if (variant === GroupedInputVariant.Left || variant === GroupedInputVariant.Center) {
        borderRadiusProperties.push('borderTopRightRadius');
        borderRadiusProperties.push('borderBottomRightRadius');
    }
    if (variant === GroupedInputVariant.Right || variant === GroupedInputVariant.Center) {
        borderRadiusProperties.push('borderTopLeftRadius');
        borderRadiusProperties.push('borderBottomLeftRadius');
    }

    const styles = {
        '& .MuiOutlinedInput-root': Object.fromEntries(
            borderRadiusProperties.map(propertyName => [propertyName, '0px !important']),
        ),
        '& .MuiOutlinedInput-root.Mui-focused': {
            boxShadow: 'none !important',
        },
    };

    return styles;
}
