import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import {
    AccountInviteForm,
    AccountLocations,
    Button,
    EditableSection,
    Input,
    PhoneField,
    SectionHeader,
    StandaloneAccountTeamMembers,
    Text,
    useAccountTeam,
} from 'app/components';

import { getSupportEmail } from 'environment';

import { useI18n } from 'i18n';

import useAccountSettings from './useAccountSettings';

export interface AccountUpdateEvent {
    account?: {
        id?: string | null;
        company?: string | null;
        companyPhone?: string | null;
        addressNumber?: string | null;
        addressStreet?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressPostal?: string | null;
    };
}

interface Props {
    accountId: string;
    onAccountUpdate?: (event: AccountUpdateEvent) => unknown;
}

/**
 * The container component for account (organization) settings is responsible for displaying the users organization details
 * and allowing them to edit any editable field syncing changes with the server.
 */
const AccountSettings = ({ accountId, onAccountUpdate = () => {} }: Props): JSX.Element => {
    const {
        error,

        updateAccount,
        refetchAccount,

        savedCompanyName,
        savedCompanyPhone,
        savedRestrictedUserDomains,

        companyName,
        companyPhone,
        billingAddressLocation,
        restrictedUserDomains,

        setCompanyName,
        setCompanyPhone,
        setRestrictedUserDomains,
    } = useAccountSettings({ accountId, onAccountUpdate });

    const team = useAccountTeam({ accountID: accountId });

    const { t } = useI18n();

    const suggestedDomains = new Set([...savedRestrictedUserDomains]);

    return (
        <>
            <section>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {/* NOTE(will): when we have RBAC/enable user-editable domains, migrate this section to EditableSection */}
                        <Box mb={7}>
                            <Text variant="h2" mb={2}>
                                {t('account_settings.heading.domains')}
                            </Text>

                            <FormGroup>
                                <FormLabel component={'legend'} sx={{ mb: 2 }}>
                                    {t('account_settings.domains_section.legend.text_one')}
                                    <span> </span>
                                    {/* NOTE(will): added space around Link because auto-formatter was removing spaces */}
                                    <Link
                                        href={`mailto:${getSupportEmail()}?subject=${t(
                                            'account_settings.domains_section.legend.email_subject',
                                        )}`}
                                    >
                                        {t('account_settings.domains_section.legend.link_text')}
                                    </Link>
                                    <span> </span>
                                    {t('account_settings.domains_section.legend.text_two')}
                                </FormLabel>

                                <Autocomplete<string, true, false, true>
                                    id="restricted-user-domains"
                                    // TODO(will): once we have role-based access control [ RBAC ] allow 'org admins' to
                                    // set their own org domains.
                                    disabled={true}
                                    freeSolo // allows arbitrary input
                                    multiple // allows multiple selected
                                    autoSelect // auto-accepts text when focus is lost
                                    filterSelectedOptions // filters already-selected options from list
                                    value={restrictedUserDomains}
                                    onChange={(_event, domains) => {
                                        setRestrictedUserDomains(domains);
                                    }}
                                    options={Array.from(suggestedDomains)}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <TextField {...params} variant="filled" />
                                    )}
                                />
                            </FormGroup>
                        </Box>

                        <Box component="section" mt={4} mb={2}>
                            <Text variant="h2" mb={2}>
                                {t('account_settings.heading.invite_user')}
                            </Text>

                            <Text as="p" mb={2}>
                                {t('account_settings.subheading.invite_user')}
                            </Text>

                            <AccountInviteForm
                                accountID={accountId}
                                onInviteSent={async _ => {
                                    await team.refetch();
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Text variant="h2" mb={2}>
                    {t('account_settings.heading.your_team')}
                </Text>

                <StandaloneAccountTeamMembers sx={{ mb: 7 }} accountID={accountId} />

                {!!error && (
                    <div>
                        <Text as="p">{t('account_settings.fetch_error')}</Text>

                        <Button onClick={() => refetchAccount()}>{t('cta.retry')}</Button>
                    </div>
                )}

                {!error && (
                    <Box maxWidth="md">
                        <EditableSection
                            mb={5}
                            header={<SectionHeader title={t('account_settings.heading.company')} />}
                            saveCTA={
                                <Button
                                    onClick={() => updateAccount({ company: companyName, contactPhone: companyPhone })}
                                    disabled={companyName === savedCompanyName && companyPhone === savedCompanyPhone}
                                />
                            }
                            onCancel={() => {
                                setCompanyName(savedCompanyName);
                                setCompanyPhone(savedCompanyPhone);
                            }}
                        >
                            {({ isEditing }) => (
                                <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                                    <Input
                                        readOnly={!isEditing}
                                        value={companyName ?? ''}
                                        fieldName={t('account_settings.label.company')}
                                        name="company"
                                        autoComplete="organization"
                                        onChange={({ target: { value } }) => {
                                            setCompanyName(value);
                                        }}
                                        fullWidth
                                    />

                                    <PhoneField
                                        readOnly={!isEditing}
                                        value={companyPhone}
                                        fieldName={t('account_settings.label.phone_number')}
                                        name="contactPhone"
                                        onChange={({ target: { value } }) => {
                                            setCompanyPhone(value);
                                        }}
                                        fullWidth
                                    />
                                </Stack>
                            )}
                        </EditableSection>

                        <Text variant="h2">{t('account_settings.heading.address')}</Text>

                        <AccountLocations
                            accountLocations={billingAddressLocation ? [billingAddressLocation] : []}
                            accountID={accountId}
                            sx={{ mb: 7 }}
                            canEdit
                            canAdd={!billingAddressLocation}
                            isBillingAddress
                        />
                    </Box>
                )}
            </section>
        </>
    );
};

export default AccountSettings;
