import Skeleton from '@mui/material/Skeleton';

import { DIMENSION_EXTRA_SMALL } from 'app/components/primitives/Progress/CircularProgress';

import { MPUTileStructure } from './MPUTileStructure';

function IndicatorSkeleton() {
    return <Skeleton variant="circular" height="30px" width="30px" />;
}

export function MPUTileSkeleton() {
    return (
        <MPUTileStructure
            assignment={<Skeleton variant="rounded" width="8ch" height="30px" sx={{ borderRadius: '15px' }} />}
            externalID={undefined}
            firmwareVersion={<Skeleton variant="text" width="8ch" />}
            indicators={
                <>
                    <IndicatorSkeleton />
                    <IndicatorSkeleton />
                </>
            }
            modelName={<Skeleton variant="text" width="8ch" />}
            mpuName={<Skeleton variant="text" width="12ch" height="36px" sx={{ borderRadius: '14px' }} />}
            ownerName={<Skeleton variant="text" width="12ch" />}
            serialNumber={<Skeleton variant="text" width="8ch" />}
            serviceArea={<Skeleton variant="text" width="12ch" />}
            stateOfCharge={
                <Skeleton
                    variant="circular"
                    height={DIMENSION_EXTRA_SMALL.as('px')}
                    width={DIMENSION_EXTRA_SMALL.as('px')}
                />
            }
            timeToEmpty={<Skeleton variant="text" width="16ch" />}
        />
    );
}
