import { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Button, Dialog, Icon, Text } from 'app/components';
import { useLayer } from 'app/core/layers';

import { useI18n } from 'i18n';

import { filtersDebugTestID } from './constants';
import FilterForm from './forms';
import SavedFilters from './SavedFilters';
import SaveFilterModal from './SaveFilterModal';
import type { FilterModalProps } from './types';
import useFilters from './useFilters';
import { getValidFilter } from './validation';

const FilterModal = ({ ownerAccountIDs, filterKey, onClose, title, debug }: FilterModalProps) => {
    const { removeFilter, filters, filter, saveFilter, addFilter } = useFilters(filterKey);
    const [stagedFields, setStagedFields] = useState(filter?.fields || {});
    const { t } = useI18n();
    const { dialog } = useLayer();
    const formId = `${filterKey}-filter-form`;

    const isStagedFiltersValid = Object.keys(getValidFilter({ fields: stagedFields })).length > 0;

    const onRequestSaveFilter = () => {
        dialog.add(
            ({ close }) => ({
                onClose: close,
                formId: `${filterKey}-save-filter-form`,
                onSubmit: ({ filterName }) => {
                    saveFilter({ fields: stagedFields }, filterName);
                    close();
                },
            }),
            {
                Component: SaveFilterModal,
            },
        );
    };

    const onSubmit = ({ type, ...values }) => {
        const { fields: validFields } = getValidFilter({ fields: values });
        const hasValidValues = Object.keys(validFields).length > 0;
        if (hasValidValues) {
            addFilter({ fields: validFields });
            setStagedFields(validFields);
        } else {
            removeFilter();
            setStagedFields({});
        }

        onClose();
    };

    return (
        <Dialog
            onClose={onClose}
            title={title}
            footerContent={
                <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
                    <Button
                        ctaType="secondary"
                        color="danger"
                        onClick={() => {
                            removeFilter();
                            setStagedFields({});
                            onClose();
                        }}
                        sx={{ marginInlineEnd: 'auto' }}
                    >
                        {t('filters.clear_all_filters')}
                    </Button>
                    <Stack direction="row" spacing={2}>
                        <Button
                            ctaType="tertiary"
                            onClick={onRequestSaveFilter}
                            disabled={!isStagedFiltersValid}
                            icon={<Icon name="add-circle" />}
                        >
                            {t('filters.save_filter')}
                        </Button>
                        <Button ctaType="primary" type="submit" form={formId} disabled={!isStagedFiltersValid}>
                            {t('filters.apply')}
                        </Button>
                    </Stack>
                </Stack>
            }
        >
            <Stack spacing={5}>
                <SavedFilters ownerAccountIDs={ownerAccountIDs} filterKey={filterKey} />

                <Text variant="h5">{t('filters.apply_a_filter')}</Text>

                {debug && (
                    <Box sx={{ mb: 10, backgroundColor: 'rgba(0,255,0,0.1)', p: 5 }} data-testid={filtersDebugTestID}>
                        <pre>{JSON.stringify(filters, null, '\t')}</pre>
                    </Box>
                )}

                <FilterForm
                    type={filterKey}
                    ownerAccountIDs={ownerAccountIDs}
                    formId={formId}
                    onSubmit={onSubmit}
                    initialValues={stagedFields}
                    onChange={values => setStagedFields(values)}
                />
            </Stack>
        </Dialog>
    );
};

export default FilterModal;
