import { createContext, ReactElement, useContext, useEffect, useRef } from 'react';

import { PermissiveChildrenProp } from '../../types';

const FocusTrapContext = createContext<any>(null);

/**
 * Responsible for providing the focus trap API to the react components in the app.
 */
export default function FocusTrapProvider({
    focusTrapAPI,
    children,
}: {
    focusTrapAPI: any;
    children: PermissiveChildrenProp;
}): ReactElement {
    useEffect(() => {
        focusTrapAPI?.init();

        return () => {
            focusTrapAPI?.destroy();
        };
    }, [focusTrapAPI]);

    return <FocusTrapContext.Provider value={focusTrapAPI}>{children}</FocusTrapContext.Provider>;
}

export function useFocusTrapAPI() {
    return useContext(FocusTrapContext);
}

/**
 * Enables components to apply a focus trap to a given element based on some condition, generally this will be isOpen
 * but could be anything
 */
export function useFocusTrap({
    enabled,
    shouldTrap,
    withinElement,
    onEscape,
    onClickAway,
}: {
    enabled?: boolean;
    shouldTrap: boolean;
    withinElement?: HTMLElement;
    onEscape?: () => void;
    onClickAway?: () => void;
}) {
    const focus = useFocusTrapAPI();
    const trapID = useRef();

    useEffect(() => {
        if (!enabled) return;

        if (shouldTrap) {
            trapID.current = focus.trap({ withinElement, onEscape, onClickAway });
        } else {
            focus.release(trapID.current);
        }

        return () => {
            focus.release(trapID.current);
        };
    }, [enabled, focus, shouldTrap, withinElement, onEscape, onClickAway]);
}
