import { IconName } from 'app/assets/icons/types';
import { Icon } from 'app/components/primitives';
import { IconColor } from 'app/components/primitives/Icon';
import { RunMode } from 'app/core/data';

import { TranslateFunction, useI18n } from 'i18n';

import { Tooltip } from '../Tooltip';

const contentByMode = {
    [RunMode.Off]: {
        color: 'disabled',
        iconName: 'power',
        tooltip: t => t('mpu_tile.mode.off'),
    },
    [RunMode.Run]: {
        color: 'success',
        iconName: 'run',
        tooltip: t => t('mpu_tile.mode.run'),
    },
    [RunMode.Standby]: {
        color: 'success',
        iconName: 'power',
        tooltip: t => t('mpu_tile.mode.standby'),
    },
} as const satisfies Record<
    Exclude<RunMode, RunMode.Unknown>,
    {
        color: IconColor;
        iconName: IconName;
        tooltip: (t: TranslateFunction) => string;
    }
>;

export function MPUTileRunModeIcon({ mode }: { mode: `${RunMode}` | undefined }) {
    const { t } = useI18n();

    if (mode === undefined || mode === RunMode.Unknown) {
        return null;
    }

    const { color, iconName, tooltip } = contentByMode[mode];

    return (
        <Tooltip content={tooltip(t)}>
            <Icon color={color} name={iconName} />
        </Tooltip>
    );
}
