import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { Text } from 'app/components';

import { useI18n } from 'i18n';

import { CustomerReservationsPageReservation } from '../useReservationsPageData';

interface ReservationTableRowProps {
    reservation: Omit<CustomerReservationsPageReservation, 'reservationItems'> & {
        reservationItems: NonNullable<CustomerReservationsPageReservation['reservationItems']>;
    };
}

export function ReservationItemTable({ reservation }: ReservationTableRowProps) {
    const { t, format } = useI18n();

    const { reservationItems } = reservation;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width={`${reservation.isCurrent ? 20 : 30}%`}>
                        <Text variant="detailMedium">{t('reservation_table.reservation_item_table.header.unit')}</Text>
                    </TableCell>

                    {reservation.isCurrent && (
                        <>
                            <TableCell width="7.5%">
                                <Tooltip title={t('acronym_tooltips.soc')} arrow>
                                    <Box display="inline-flex">
                                        <Text variant="detailMedium">
                                            {t('reservation_table.reservation_item_table.header.soc')}
                                        </Text>
                                    </Box>
                                </Tooltip>
                            </TableCell>

                            <TableCell width="7.5%">
                                <Tooltip title={t('acronym_tooltips.ette')} arrow>
                                    <Box display="inline-flex">
                                        <Text variant="detailMedium">
                                            {t('reservation_table.reservation_item_table.header.ette')}
                                        </Text>
                                    </Box>
                                </Tooltip>
                            </TableCell>

                            <TableCell width="20%">
                                <Text variant="detailMedium">
                                    {t('reservation_table.reservation_item_table.header.location')}
                                </Text>
                            </TableCell>
                        </>
                    )}

                    <TableCell width={`${reservation.isCurrent ? 22.5 : 35}%`}>
                        <Text variant="detailMedium">
                            {t('reservation_table.reservation_item_table.header.delivery_address')}
                        </Text>
                    </TableCell>

                    <TableCell width={`${reservation.isCurrent ? 22.5 : 35}%`}>
                        <Text variant="detailMedium">
                            {t('reservation_table.reservation_item_table.header.pickup_address')}
                        </Text>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {reservationItems.map((ri, i) => (
                    <TableRow
                        key={ri.id}
                        sx={{
                            ':last-child > *': { borderBottom: 'none' },
                        }}
                    >
                        <TableCell>{ri.name}</TableCell>

                        {reservation.isCurrent && (
                            <>
                                <TableCell>{format.soc(ri.stateOfCharge?.value).toString()}</TableCell>

                                <TableCell>{format.ette(ri.timeToEmpty?.value).toString()}</TableCell>

                                <TableCell>{format.latLong(ri.latestDeviceLocation)}</TableCell>
                            </>
                        )}

                        <TableCell>{ri.deliveryAddress}</TableCell>

                        <TableCell>{ri.pickupAddress}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
