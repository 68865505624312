import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { AccountSelector, Button, Input, UserRoleSelector } from 'app/components';

import { useI18n } from 'i18n';

import { FormStateChange } from '../types';

interface InviteUserFormFields {
    accountID: string;

    email: string;

    roles: string[];
}

type SubmittedFields = InviteUserFormFields;

interface Props extends Partial<InviteUserFormFields> {
    /**
     * The unique id identifying the form element
     */
    id?: string;

    /**
     * Whether to include the submit CTA in the form
     */
    hideSubmitCTA?: boolean;

    /**
     * Whether or not to allow selecting an account for inviting a user to
     */
    allowAccountSelection?: boolean;

    /**
     * Whether or not to allow selecting roles for the invited user
     */
    allowRoleSelection?: boolean;

    /**
     * Event handler to be called when the form state is changed
     */
    onFormStateChange?: (event: FormStateChange<SubmittedFields>) => void;

    /**
     * Event handler to be called when the user initiates a form submission
     */
    onSubmit: (userChanges: { value: SubmittedFields }) => Promise<void>;
}

/**
 * Responsible for collecting fields for inviting a user to a specific account
 */
export default function InviteUserForm({
    id,
    accountID,
    email,
    roles,
    hideSubmitCTA = false,
    allowAccountSelection = true,
    allowRoleSelection = true,
    onFormStateChange,
    onSubmit,
}: Props): ReactElement {
    const { t } = useI18n();

    const form = useFormik<InviteUserFormFields>({
        initialValues: {
            accountID: accountID ?? '',
            email: email ?? '',
            roles: roles ?? [],
        },
        enableReinitialize: true,

        validateOnMount: false,
        validateOnChange: true,
        validateOnBlur: true,

        validationSchema: object().shape({
            accountID: string().required(t('invite_user_page.account_required_message')),
            email: string()
                .email(t('invite_user_page.email_invalid_message'))
                .required(t('invite_user_page.email_required_message')),
            roles: array().of(string()).optional(),
        }),

        onSubmit: async value => {
            await onSubmit({ value });
        },
    });

    const { isValid, dirty, values, touched, errors } = form;
    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({
            shouldDisableCTA,
            isDirty: dirty,
            isValid,
            values,
        });
    }, [shouldDisableCTA, dirty, isValid, values, onFormStateChange]);

    return (
        <Grid container id={id} component="form" spacing={4} onSubmit={form.handleSubmit}>
            <Grid item xs={12} md={6}>
                <AccountSelector
                    fullWidth
                    required
                    error={touched.accountID && !!form.errors.accountID}
                    disabled={!allowAccountSelection}
                    fieldName={t('invite_user_page.account_label')}
                    caption={touched.accountID && form.errors.accountID}
                    name="accountID"
                    value={values.accountID}
                    onChange={({ value }) => {
                        form.setFieldValue('accountID', value?.id, true);
                    }}
                    onBlur={form.handleBlur}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Input
                    fullWidth
                    required
                    error={touched.email && !!form.errors.email}
                    fieldName={t('invite_user_page.email_label')}
                    caption={touched.email && form.errors.email}
                    name="email"
                    value={values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
            </Grid>

            {allowRoleSelection && (
                <Grid item xs={12} md={6}>
                    <UserRoleSelector
                        fieldName={t('invite_user_page.roles_label')}
                        caption={touched.roles && errors.roles}
                        error={!!errors.roles}
                        name="roles"
                        value={values.roles}
                        onChange={({ value }) => form.setFieldValue('roles', value)}
                        onBlur={() => {
                            form.setFieldTouched('roles', true);
                        }}
                    />
                </Grid>
            )}

            {!hideSubmitCTA && (
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button type="submit" disabled={shouldDisableCTA}>
                            {t('invite_user_page.cta.save_changes')}
                        </Button>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}
