import { useEffect, useRef, useState } from 'react';
import { DateTime, Duration, Interval } from 'luxon';

import { resolveDateTime } from 'app/core/date-time';
import { ISO8601 } from 'app/core/types';

export enum IntervalType {
    ReservationItem = 'reservationItem',
    DeviceInstance = 'deviceInstance',
}

export type IntervalKey = '24h' | '7d' | '14d' | '30d' | '90d' | 'all';

const EPOCH = DateTime.fromMillis(0);

const intervals = new Map([
    [
        '24h',
        {
            id: '24h',
            make: (): Interval => {
                return Interval.before(DateTime.now(), Duration.fromObject({ hours: 24 }));
            },
        },
    ],
    [
        '7d',
        {
            id: '7d',
            make: (): Interval => {
                return Interval.before(DateTime.now(), Duration.fromObject({ days: 7 }));
            },
        },
    ],
    [
        '14d',
        {
            id: '14d',
            make: (): Interval => {
                return Interval.before(DateTime.now(), Duration.fromObject({ days: 14 }));
            },
        },
    ],
    [
        '30d',
        {
            id: '30d',
            make: (): Interval => {
                return Interval.before(DateTime.now(), Duration.fromObject({ days: 30 }));
            },
        },
    ],
    [
        'all',
        {
            id: 'all',
            types: [IntervalType.ReservationItem],
            make: (): Interval => {
                return Interval.fromDateTimes(EPOCH, DateTime.now());
            },
        },
    ],
]);

export const getOptions = (type?: IntervalType): string[] => {
    return Array.from(intervals)
        .filter(entry => {
            if (type && entry[1].types) {
                return entry[1].types.includes(type);
            }

            return true;
        })
        .map(entry => entry[0]);
};

export const createIntervalFromKey = (
    key: string,
    options: { minDate?: ISO8601; maxDate?: ISO8601 } = {},
): Interval | undefined | null => {
    const { minDate, maxDate } = options;
    const interval = intervals.get(key);

    const targetInterval = interval?.make() || intervals.get('all')?.make();
    const _minDate = minDate ? DateTime.fromISO(minDate) : EPOCH;
    const _maxDate = maxDate ? DateTime.fromISO(maxDate) : DateTime.now();

    const scopedInterval = Interval.fromDateTimes(_minDate, _maxDate);

    return targetInterval?.intersection(scopedInterval);
};

function resolveIntervalType({ unitID, mpuID }) {
    if (unitID) return IntervalType.ReservationItem;
    if (mpuID) return IntervalType.DeviceInstance;

    return undefined;
}

const POLL_INTERVAL = Duration.fromObject({ minutes: 5 }).toMillis();

export function useDiscreteInterval({
    mpuID,
    unitID,
    minDate,
    maxDate,
    intervalKey,
    enablePolling = false,
    pollInterval = POLL_INTERVAL,
}: {
    mpuID?: string;
    unitID?: string;
    minDate?: ISO8601;
    maxDate?: ISO8601;
    intervalKey: IntervalKey;
    enablePolling?: boolean;
    pollInterval?: number;
}): { type?: IntervalType; interval: Interval | undefined | null } {
    const [interval, setLuxonInterval] = useState<Interval | null | undefined>(null);
    const intervalID = useRef<number | undefined>();

    useEffect(() => {
        if (!enablePolling) {
            setLuxonInterval(createIntervalFromKey(intervalKey, { minDate, maxDate }));
            clearInterval(intervalID.current);
            return;
        }

        setLuxonInterval(createIntervalFromKey(intervalKey, { minDate, maxDate }));

        if (resolveDateTime(maxDate) <= DateTime.now()) return;

        intervalID.current = window.setInterval(() => {
            setLuxonInterval(createIntervalFromKey(intervalKey, { minDate, maxDate }));
        }, pollInterval);

        return () => {
            clearInterval(intervalID.current);
        };
    }, [enablePolling, pollInterval, intervalKey, minDate, maxDate]);

    return {
        type: resolveIntervalType({ mpuID, unitID }),
        interval,
    };
}
