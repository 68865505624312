import { MutationFunctionOptions } from '@apollo/client';

import { enhanceAsyncError } from 'app/core/error';

import { CreateDeviceInstanceInput, useCreateDeviceInstanceMutation } from 'generated/graphql';

/**
 * Responsible for communicating requests for new MPUs to our GQL backend.
 */
export default function useAddMPU({
    onCompleted,
    onError,
}: {
    onCompleted: MutationFunctionOptions['onCompleted'];
    onError: MutationFunctionOptions['onError'];
}): (input: CreateDeviceInstanceInput) => Promise<void> {
    const [createUnit] = useCreateDeviceInstanceMutation();
    const addNewUnit = async (unitMeta: CreateDeviceInstanceInput) => {
        await enhanceAsyncError(
            createUnit({
                variables: {
                    input: {
                        name: unitMeta.name,
                        assetID: unitMeta.assetID,
                        deviceModelID: unitMeta.deviceModelID,
                        ownerAccountID: unitMeta.ownerAccountID,
                        serialNumber: unitMeta.serialNumber,
                        /**
                         * external IDs must be unique within an account except null
                         * this means empty strings will not work, cast to null.
                         */
                        externalID: unitMeta.externalID?.trim() || null,
                    },
                },
                onError,
                onCompleted,
            }),
        );
    };

    return addNewUnit;
}
