import { KnownServiceErrorKind } from './constants';
import { KnownServiceError } from './KnownServiceError';

/**
 * Determines whether the given error is a `KnownServiceError` of the given kind.
 */
export function isKnownServiceError<T extends KnownServiceErrorKind>(
    kind: T,
    error: unknown,
): error is KnownServiceError<T> {
    return error instanceof KnownServiceError && error.kind === kind;
}

/**
 * A type guards that can be used to determine whether given value
 * is a `KnownServiceError` of the given kind.
 */
type KnownServiceErrorTypeGuard<T extends KnownServiceErrorKind> = (error: unknown) => error is KnownServiceError<T>;

/**
 * An object of type guards for `KnownServiceError`
 */
type KnownServiceErrorTypeGuards = {
    [K in KnownServiceErrorKind as `is${K}Error`]: KnownServiceErrorTypeGuard<K>;
};

/**
 * Creates an object of type guards that can be used to determine whether given value
 * is a `KnownServiceError` of the given kind.
 */
function makeKnownServiceErrorTypeGuards() {
    return Object.fromEntries(
        Object.values(KnownServiceErrorKind).map(kind => {
            return [`is${kind}Error`, (error: unknown) => isKnownServiceError(kind, error)] as const;
        }),
    ) as KnownServiceErrorTypeGuards;
}

export const {
    isAssetAlreadyExistsError,
    isScheduleDeleteBugError,
    isScheduleConflictError,
    isSerialNumberConflictError,
} = makeKnownServiceErrorTypeGuards();
