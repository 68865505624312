/**
 * Used for referencing relevant translations or other type dependent data
 */
export enum MetricChartType {
    LineLoads = 'line_loads',
    LineVoltages = 'line_voltages',
    LineCurrents = 'line_currents',
    Power = 'power',
    Soc = 'soc',
    AvailableEnergy = 'available_energy',
    PackTemp = 'max_pack_temp',
    All = 'all',
    EnergyUsage = 'energy_usage',
}

export namespace MetricChartType {
    const keys = Object.keys(MetricChartType);
    const values = Object.values(MetricChartType).map(x => x.toString());

    const byValue = values.reduce(
        (acc, cur, index) => ({
            ...acc,
            [cur]: MetricChartType[keys[index]],
        }),
        {},
    );

    export function parse(val: string): MetricChartType {
        return byValue[val];
    }
}
