import { Fragment } from 'react';
import { sanitize } from 'dompurify';

import 'react-dom';

type renderLink = (x: { id: string; text: string }) => JSX.Element;

interface Props {
    text: string;
    renderLink: renderLink;
}

function toDOM(html: string): DocumentFragment {
    const template = document.createElement('template');

    template.innerHTML = html;

    return template.content;
}

/**
 * Responsible for parsing and replacing links found in translations. It relies on all
 * anchor tags having an href attribute with a unique id which identifies the link in the
 * translation.
 *
 * @example
 * <TranslationWithLinks
 *   text="I have a <a href=\"someID\">link</a> in my translation."
 *   renderLink={({ id, text }) => <Link to="/wherever/based/on/id">{text}</Link>}
 * />
 */
export default function TranslationWithLinks({ text, renderLink }: Props): JSX.Element {
    const domFragment = toDOM(text);

    const x = Array.from(domFragment.childNodes).map((node: ChildNode) => {
        const { textContent } = node;

        if (node instanceof HTMLAnchorElement)
            return { key: textContent, content: renderLink({ id: node.href, text: textContent ?? '' }) };

        return { key: textContent, content: <span dangerouslySetInnerHTML={{ __html: sanitize(node) }} /> };
    });

    return (
        <>
            {x.map(y => (
                <Fragment key={y.key}>{y.content}</Fragment>
            ))}
        </>
    );
}
