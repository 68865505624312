import { cloneElement, ComponentProps, isValidElement, ReactElement, ReactNode } from 'react';

import Stack from '@mui/material/Stack';

import { IconColor } from 'app/components/primitives/Icon';
import Text from 'app/components/primitives/Text';

interface Props {
    color?: ComponentProps<typeof Text>['color'];

    icon?: ReactElement<{ color: IconColor }> | null;

    text: ReactNode;

    direction?: 'row' | 'column';
}

/**
 * Simple layout component to display an icon directly next to some body text
 */
export default function IconAndText({ icon, text, color, direction = 'row' }: Props): ReactElement {
    return (
        <Stack direction={direction} alignItems="center" spacing={direction === 'column' ? 2 : 1}>
            {isValidElement(icon) && (
                <Stack direction="row" alignItems="center">
                    {cloneElement(icon, { color: icon.props.color ?? color })}
                </Stack>
            )}
            <Text color={color}>{text}</Text>
        </Stack>
    );
}
