import { ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import Text from '../../primitives/Text';

interface Props {
    /**
     * The first line of content
     */
    line1: ReactNode;

    /**
     * The second line of content
     */
    line2?: ReactNode;
}

/**
 * Presentational component responsible for handling cell layout. Two line layout is only supported for
 * DataGrids with density of standard or greater. This component is optional in the event you only have a single
 * line as the text styling is set in the theme.
 */
export default function DataGridCellContent({ line1, line2 }: Props): ReactElement {
    return (
        <Box width="100%" sx={{ textWrap: 'wrap' }}>
            <Text as="div">{line1}</Text>

            {line2 && (
                <Text as="div" variant="detail" color="secondary">
                    {line2}
                </Text>
            )}
        </Box>
    );
}
