import { cloneElement, ComponentProps, forwardRef, isValidElement, ReactElement, ReactNode, Ref } from 'react';

import Box from '@mui/material/Box';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import { withProps } from '../../withProps';

// import Icon from '../Icon';
// import Button from '../interactive/Button';
// import Text from '../Text';

interface Props {
    /**
     * The categories of alert visual treatments
     */
    severity?: 'normal' | 'info' | 'success' | 'warning' | 'error';

    /**
     * The title briefly describing the alert
     */
    title?: ReactNode;

    /**
     * The descriptive message for the Alert / Toast
     */
    message: ReactNode;

    /**
     * The icon to use instead of the default based on severity
     */
    iconName?: ComponentProps<typeof Icon>['name'];

    /**
     * Whether to show the icon
     */
    hideIcon?: boolean;

    /**
     * Call to Action to render, to take action on a specific alert
     */
    cta?: ReactElement<{ color: 'primary' | 'utility' | 'danger' }>;

    /**
     * The vertical alignment of the CTA.
     */
    ctaAlign?: 'center' | 'end' | 'start';

    /**
     * Event handler to be called when manually or automatically closed
     */
    onClose?: () => void;
}

const attributesBySeverity = {
    normal: {
        backgroundColor: 'background.primary.main',
        borderColor: 'border.light',
        titleColor: 'primary',
        iconName: 'information-circle',
        iconColor: 'primary',
    },
    info: {
        backgroundColor: 'background.info.main',
        borderColor: 'border.info',
        titleColor: 'info',
        iconName: 'information-circle',
        iconColor: 'info',
    },
    success: {
        backgroundColor: 'background.success.main',
        borderColor: 'border.success',
        titleColor: 'success',
        iconName: 'checkmark-circle',
        iconColor: 'success',
    },
    warning: {
        backgroundColor: 'background.warning.main',
        borderColor: 'border.warning',
        titleColor: 'warning',
        iconName: 'warning',
        iconColor: 'warning',
    },
    error: {
        backgroundColor: 'background.danger.main',
        borderColor: 'border.danger',
        titleColor: 'danger',
        iconName: 'warning',
        iconColor: 'danger',
    },
} as const;

function resolveCTAColor({ severity }) {
    if (severity === 'info') return 'primary';
    if (severity === 'error') return 'danger';

    return 'utility';
}

/**
 * Presentation component for displaying alerts, alarms or notifications to capture the users attention
 * @link {Alert in Figma | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=685%3A3336&mode=dev}
 */
const Alert = forwardRef(function Alert(
    { severity = 'info', title, message, cta, ctaAlign, iconName, hideIcon = false, onClose }: Props,
    ref: Ref<HTMLElement>,
): ReactElement {
    const { t } = useI18n();

    const attr = attributesBySeverity[severity];
    const hasOnClose = typeof onClose === 'function';
    const hasCta = isValidElement(cta);

    return (
        <Box
            ref={ref}
            sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                py: 4,
                pl: 5,
                pr: 4,
                gap: 4,
                backgroundColor: attr.backgroundColor,
                borderColor: attr.borderColor,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: `${theme.shape.borderRadius}px`,
            })}
        >
            {!hideIcon && <Icon name={iconName || attr.iconName} color={attr.iconColor} />}

            <Box display="flex" gap={4} justifyContent="space-between" width="100%">
                <Box>
                    <Text variant="h4" as="div" color={attr.titleColor}>
                        {title ?? t(`alert.default_title.${severity}`)}
                    </Text>
                    <Text as="div">{message}</Text>
                </Box>

                {(hasCta || hasOnClose) && (
                    <Box sx={ctaAlign ? { display: 'flex', alignItems: ctaAlign } : {}}>
                        {hasCta && cloneElement(cta, { color: resolveCTAColor({ severity }) })}

                        {!hasCta && hasOnClose && (
                            <Button
                                color="utility"
                                ctaType="tertiary"
                                icon={<Icon name="close" color={attr.iconColor} />}
                                onClick={onClose}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
});

export default Alert;

/**
 * Presentational component for displaying ephemeral messages typically for feedback about an async action such
 * as an interaction with an API
 * @link {Toast in Figma | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=304%3A1674&mode=dev}
 */
export const Toast = withProps(Alert, props => ({
    ...props,
    hideIcon: true,
}));
