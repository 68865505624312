import { createContext, ReactElement, useContext } from 'react';

import { Experience, IExperienceSwitcher } from 'app/core/types';

import EventEmitter, { EventEmitterCallback } from '../EventEmitter';
import { AccountRoleName } from '../Session/Session';
import { ISession } from '../Session/types';
import { RouteName } from './getPathByName';

/**
 * The experience is stored and namespaced by userID to support multiple users.
 * We're also using sessionStorage here so that new tabs act independently.
 * Tabs can be duplicated and the switching of experience from one tab will not
 * affect the other.
 */
function makeKey({ userID }) {
    return `${userID}:experience`;
}

function read({ userID }) {
    return sessionStorage.getItem(makeKey({ userID }));
}

function write({ userID, value }) {
    return sessionStorage.setItem(makeKey({ userID }), value);
}

function erase({ userID }) {
    return sessionStorage.removeItem(makeKey({ userID }));
}

const nullExperienceSwitcher: IExperienceSwitcher = {
    switchExperience() {},
    forgetExperience() {},
    getExperience() {
        return null;
    },
    getNextExperience() {
        return null;
    },
    getHomePathName() {
        return 'HOME';
    },
    onSwitchExperience() {
        return () => {};
    },
};

export default class ExperienceSwitcher implements IExperienceSwitcher {
    private session: Pick<ISession, 'getSession' | 'hasAccountRole'>;
    private eventEmitter = new EventEmitter();

    constructor({ session }) {
        this.session = session;
    }

    /**
     * Attempts to switch the experience to the given targetExperience.
     */
    switchExperience(targetExperience: Experience) {
        const { id: userID } = this.session.getSession();
        const isRenter = this.session.hasAccountRole(AccountRoleName.Renter);
        const isOwner = this.session.hasAccountRole(AccountRoleName.Owner);

        const currentExperience = this.getExperience();

        if (currentExperience === targetExperience || !(isRenter && isOwner)) return;

        write({ userID, value: targetExperience });

        this.eventEmitter.emit('switchExperience', { targetExperience });
    }

    /**
     * Forgets the persisted experience, to restore the default experience
     */
    forgetExperience({ userID }: { userID: string }) {
        erase({ userID });
    }

    /**
     * Gets the currently resolved experience
     */
    getExperience(): Experience {
        const { id } = this.session.getSession();

        const isRenter = this.session.hasAccountRole(AccountRoleName.Renter);
        const isOwner = this.session.hasAccountRole(AccountRoleName.Owner);
        const targetExperience = read({ userID: id });

        if ((isRenter && isOwner && targetExperience === 'Owner') || (!targetExperience && isOwner)) {
            return 'Owner';
        }

        return 'Renter';
    }

    /**
     * Gets the experience to switch to from the current experience
     */
    getNextExperience(): Experience {
        const currentExperience = this.getExperience();

        const isRenter = this.session.hasAccountRole(AccountRoleName.Renter);
        const isOwner = this.session.hasAccountRole(AccountRoleName.Owner);

        if (!isRenter || !isOwner) return null;

        switch (currentExperience) {
            case 'Renter':
                return 'Owner';

            case 'Owner':
            /** falls through */
            default:
                return 'Renter';
        }
    }

    /**
     * Returns the home path name associated with the active experience
     */
    getHomePathName(): RouteName {
        const { isAdmin } = this.session.getSession();
        const currentExperience = this.getExperience();

        switch (currentExperience) {
            case 'Renter':
                return 'RENTER_DASHBOARD';
            case 'Owner':
            /** falls through */
            default:
                return isAdmin ? 'RENTAL_MGMT_RESERVATIONS' : 'MPUS_INDEX';
        }
    }

    /**
     * Attach event listeners to listen for when the experience is to be switched
     */
    onSwitchExperience(callback: EventEmitterCallback<{ targetExperience: Experience }>): () => void {
        return this.eventEmitter.on('switchExperience', callback);
    }
}

const ExperienceSwitcherContext = createContext<IExperienceSwitcher>(nullExperienceSwitcher);

interface Props {
    experienceSwitcher: IExperienceSwitcher;
    children: ReactElement;
}

/**
 * Context provider for sharing the experience switcher API
 */
export function ExperienceSwitcherProvider({ experienceSwitcher, children }: Props) {
    return (
        <ExperienceSwitcherContext.Provider value={experienceSwitcher}>{children}</ExperienceSwitcherContext.Provider>
    );
}

/**
 * Context hook responsible for getting the apps experience switcher instance.
 */
export function useExperienceSwitcher() {
    return useContext(ExperienceSwitcherContext);
}
