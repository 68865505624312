import { ReactElement, useEffect, useId, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GridSortModel } from '@mui/x-data-grid';

import {
    ActiveFaultSummary,
    AutoSizer,
    Button,
    Icon,
    MpuIndexSchedule,
    PageHeader,
    UnitsMap,
    ZeroState,
} from 'app/components';
import Filters, { mpuFilterType, useFilters } from 'app/components/widgets/Filters';
import FilterResultsHeader from 'app/components/widgets/Filters/FilterResultsHeader';
import { useLayer } from 'app/core/layers';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useSession } from 'app/core/Session';
import { SessionData } from 'app/core/types';

import { RelativeTime, useI18n } from 'i18n';

import AddMPUDialog from './AddMPUDialog';
import MPUsTable, { MPUS_TABLE_ROWS_PER_PAGE_OPTIONS } from './MPUsTable';
import { MPUsTileView } from './MPUsTileView';
import useAddMPU from './useAddMPU';
import useMPUs from './useMPUs';

enum View {
    List = 'list',
    Map = 'map',
    Schedule = 'schedule',
    Tile = 'tile',
}

function isValidView(view: string | null): view is View {
    return Object.values<string | null>(View).includes(view);
}

function initializeViewTo(view: string | null): View {
    if (!isValidView(view)) return View.List;

    return view;
}

const filterToQuery = filter => {
    const { serviceAreaName, ownerName, mode, name, serialNumber, externalID } = filter?.fields ?? {};
    const getID = (item: { id: string }) => item.id;

    const serviceAreaIDs = serviceAreaName?.length ? serviceAreaName.map(getID) : undefined;
    const ownerAccountIDs = ownerName?.length ? ownerName.map(getID) : [];
    const modeIDs = mode?.length ? mode.map(getID) : undefined;
    const deviceNames = name?.length ? name.map(getID) : undefined;
    const serialNumbers = serialNumber?.length ? serialNumber.map(getID) : undefined;
    const externalIDs = externalID?.length ? externalID.map(getID) : undefined;

    return {
        serviceAreaIDs,
        ownerAccountIDs,
        modeIDs,
        deviceNames,
        serialNumbers,
        externalIDs,
    };
};

/**
 * This function resolves the capabilities / features to expose the any given user
 * basd on their user roles.
 */
function resolveUsersCapabilities({
    userRoles,
    primaryAccountID,
}: Pick<SessionData, 'userRoles' | 'primaryAccountID'>): {
    /**
     * The owning accountIDs the user should be able to access.
     */
    ownerAccountIDs: string[];

    /**
     * Whether the user is allowed to provision new MPUs
     */
    enableMPUProvisioning: boolean;

    /**
     * Whether the user is part of the MPU rental operations (i.e. Energy Services)
     */
    enableRentalManagement: boolean;
} {
    const hasGlobalAccess = !!userRoles.find(x =>
        ['MoxionAdmin', 'MoxionFleetManager', 'MoxionFieldOperator'].includes(x),
    );

    return {
        ownerAccountIDs: hasGlobalAccess ? [] : [primaryAccountID],
        enableMPUProvisioning: !!userRoles.find(x => ['MoxionAdmin'].includes(x)),
        enableRentalManagement: hasGlobalAccess,
    };
}

/**
 * Default to the smallest page size for the MPUs table.
 */
const DEFAULT_PAGE_SIZE = MPUS_TABLE_ROWS_PER_PAGE_OPTIONS[0];

export default function MPUIndexPage(): ReactElement {
    const session = useSession().getSession();
    const { toast } = useLayer();
    const { ownerAccountIDs, enableMPUProvisioning, enableRentalManagement } = resolveUsersCapabilities(session);

    const [searchParams, setSearchParams] = useSearchParams();

    const { filter, tags, removeFilterValueByID, removeFilter } = useFilters(mpuFilterType);
    const queryFilter = filterToQuery(filter);

    const { t } = useI18n();

    const addMPUDialogId = useId();
    const [isAddMPUDialogOpen, setIsAddMPUDialogOpen] = useState(false);
    const [activeView, setActiveView] = useState<View>(initializeViewTo(searchParams.get('view')));

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'activeFaultCodes', sort: 'asc' }]);

    const isPaginatedView = [View.List, View.Tile].includes(activeView);
    /**
     * The limit is only applied for views that support pagination.
     */
    const limit = isPaginatedView ? pageSize : undefined;

    const {
        loading,
        error,
        refetch: refetchAllUnits,
        lastUpdateTimestamp,
        data: { mpus, totalCount: totalMPUCount, shownUnitCount },
        fetchNextPage: fetchNextMPUPage,
        fetchPrevPage: fetchPrevMPUPage,
        hasNextPage: hasNextMPUPage,
        pageIndex,
        resetPageIndex,
    } = useMPUs({ enableRentalManagement, ownerAccountIDs, limit, sortModel, filter: queryFilter });

    const addMPU = useAddMPU({
        onError: () => {
            toast.add({
                severity: 'error',
                message: t('admin_unit_index_page.add_mpu_error_message'),
            });
        },
        onCompleted: () => {
            toast.add({
                severity: 'success',
                message: t('admin_unit_index_page.add_mpu_success_message'),
            });

            refetchAllUnits();
        },
    });

    useEffect(() => {
        resetPageIndex();
        refetchAllUnits();
        // Only invoke the effect when the view changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeView]);

    return (
        <>
            <PageHeader
                title={t('admin_unit_index_page.title')}
                subtitle={t('admin_unit_index_page.subtitle', {
                    timestamp: <RelativeTime key={lastUpdateTimestamp} time={lastUpdateTimestamp} />,
                })}
            />

            <Box maxWidth="lg" m="auto">
                <ActiveFaultSummary mb={5} accountID={ownerAccountIDs[0]} viewAllHref={getPathByName('MPU_FAULTS')} />
            </Box>

            <Stack direction="row" justifyContent="flex-end" spacing={2} mb={3}>
                <FilterResultsHeader
                    tags={tags}
                    count={totalMPUCount}
                    onRemoveTag={tag => {
                        removeFilterValueByID(tag.id);
                    }}
                    onClearAll={() => {
                        removeFilter();
                    }}
                />

                <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                    <Filters ownerAccountIDs={ownerAccountIDs} filterKey="mpus" title="Filter MPUs" />

                    {enableMPUProvisioning && (
                        <Box display="flex" justifyContent="flex-end" mb={5}>
                            <Button
                                icon={<Icon name="add-circle" />}
                                aria-expanded={isAddMPUDialogOpen}
                                aria-controls={addMPUDialogId}
                                onClick={() => setIsAddMPUDialogOpen(true)}
                            >
                                {t('admin_unit_index_page.cta.add_new_unit')}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Stack>

            {/* TODO: Let the buttons determine the width of the button group, at screen sizes above `xs`.  */}
            <Box display="flex" justifyContent="center" mb={5} sx={{ width: { xs: '100%', md: 400 }, mx: 'auto' }}>
                <ToggleButtonGroup
                    fullWidth
                    exclusive
                    value={activeView}
                    onChange={(_, value: View | null) => {
                        if (!!value) {
                            // Create a new URLSearchParams object based on the current search parameters
                            const newSearchParams = new URLSearchParams(searchParams);

                            // Update the specific search parameter
                            newSearchParams.set('view', value);

                            // Set the updated search parameters to the URL
                            setSearchParams(newSearchParams);
                            setActiveView(value);
                        }
                    }}
                >
                    {enableRentalManagement && (
                        <ToggleButton value={View.Schedule}>
                            {t('admin_unit_index_page.cta.schedule_view')}
                        </ToggleButton>
                    )}
                    <ToggleButton value={View.List}>{t('admin_unit_index_page.cta.list_view')}</ToggleButton>
                    {enableRentalManagement && (
                        <ToggleButton value={View.Tile}>{t('admin_unit_index_page.cta.tile_view')}</ToggleButton>
                    )}
                    <ToggleButton value={View.Map}>{t('admin_unit_index_page.cta.map_view')}</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {error && (
                <ZeroState
                    title={t('admin_unit_index_page.zero_state.fetch_error.title')}
                    message={t('admin_unit_index_page.zero_state.fetch_error.message')}
                    cta={
                        <Button ctaType="secondary" onClick={() => refetchAllUnits()}>
                            {t('cta.retry')}
                        </Button>
                    }
                />
            )}

            {!error && activeView === View.List && (
                <Paper elevation={0} sx={{ overflowX: 'auto' }}>
                    <MPUsTable
                        mpus={mpus}
                        excludeFields={
                            enableRentalManagement ? [] : ['assignment', 'serviceAreaName', 'particleDeviceID']
                        }
                        loading={loading}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                        pageIndex={pageIndex}
                        onPageChange={newPageIndex => {
                            if (newPageIndex > pageIndex) {
                                fetchNextMPUPage();
                            } else if (newPageIndex < pageIndex) {
                                fetchPrevMPUPage();
                            }
                        }}
                        totalRowCount={totalMPUCount}
                    />
                </Paper>
            )}

            {!error && activeView === View.Map && (
                <Box>
                    {shownUnitCount !== totalMPUCount && (
                        <Box mb={5}>
                            <Alert severity="info">
                                {t('admin_unit_index_page.missing_units_explanation', {
                                    count: totalMPUCount - shownUnitCount,
                                })}
                            </Alert>
                        </Box>
                    )}

                    <Box sx={{ height: '75vh', minHeight: 400 }}>
                        <UnitsMap units={mpus} size="fill" />
                    </Box>
                </Box>
            )}

            {enableRentalManagement && activeView === View.Schedule && (
                <Card variant="outlined" sx={{ padding: 5, mb: 5 }}>
                    <Box
                        sx={{
                            // TODO(Morris): Reference values from tokens
                            // 80px = heading height
                            // 8px = spacing multiplicand
                            // 15 = spacing multiplier
                            height: `calc(100vh - ${8 * 15 + 80}px)`,
                        }}
                    >
                        <AutoSizer>
                            {({ height, width }) => <MpuIndexSchedule mpus={mpus} height={height} width={width} />}
                        </AutoSizer>
                    </Box>
                </Card>
            )}

            {enableRentalManagement && activeView === View.Tile && (
                <MPUsTileView
                    currentPage={mpus}
                    error={error}
                    fetchNextPage={fetchNextMPUPage}
                    hasNextPage={hasNextMPUPage}
                    loading={loading}
                    pageSize={pageSize}
                />
            )}

            <AddMPUDialog
                id={addMPUDialogId}
                open={isAddMPUDialogOpen}
                onClose={() => setIsAddMPUDialogOpen(false)}
                onAddUnit={addMPU}
            />
        </>
    );
}
