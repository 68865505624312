import { ComponentProps, FocusEvent, forwardRef, ReactElement, ReactNode, Ref, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import FieldCaption from 'app/components/primitives/FieldCaption';
import FieldLabel from 'app/components/primitives/FieldLabel';
import Button from 'app/components/primitives/interactive/Button';
import Input from 'app/components/primitives/interactive/Input';
import Text from 'app/components/primitives/Text';
import {
    DOMAIN_MAX_CHARACTERS,
    DOMAIN_MIN_CHARACTERS,
    DomainValidationHint,
    validateDomain,
} from 'app/core/validation';

import { useI18n } from 'i18n';

import Domains from './Domains';

type ValidationHint = `${DomainValidationHint}` | 'DOMAIN_ALREADY_INCLUDED';

interface Props extends Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'onChange' | 'onBlur' | 'onFocus'> {
    /**
     * Represents the current domain being authored
     */
    value?: string;

    /**
     * All domains which have been added and should be displayed
     */
    domains?: string[];

    /**
     * Explanatory text describing the list of domains
     */
    domainsLabel?: ReactNode;

    /**
     * Where to place the domain tags
     */
    domainsPlacement?: 'input' | 'below';

    /**
     * Event handler to be called when the control is blurred
     */
    onBlur?: (event: FocusEvent<HTMLDivElement>) => void;

    /**
     * Event handler to be called when the control is focused
     */
    onFocus?: (event: FocusEvent<HTMLDivElement>) => void;

    /**
     * Event handler to be called when the value is updated
     */
    onChange?: (event: { name?: string; value: string }) => void;

    /**
     * Event handler to be called when the value is submitted and should be added to the list of domains
     */
    onAdd?: (event: { name?: string; value: string[]; errors?: ValidationHint[] }) => void;

    /**
     * Event handler to be called when a domain should be removed from the list of domains
     */
    onRemove?: (event: { name?: string; value: string[] }) => void;

    /**
     * Event handler called when the validation errors change based on the value
     */
    onValidationError?: (event: { errors: ValidationHint[] }) => void;
}

/**
 * Responsible for inputting domains
 */
export default forwardRef(function DomainInput(
    {
        fieldName,
        caption,
        name,
        value = '',
        error = false,
        disabled = false,
        readOnly = false,
        domains,
        domainsLabel,
        domainsPlacement = 'below',
        onBlur,
        onFocus,
        onChange,
        onAdd,
        onRemove,
        onValidationError,
        ...props
    }: Props,
    ref: Ref<HTMLInputElement>,
): ReactElement {
    const { t } = useI18n();
    const isDuplicateDomain = (domains ?? []).includes(value);
    const validationErrors = (validateDomain(value).errors as ValidationHint[]).concat(
        isDuplicateDomain ? ['DOMAIN_ALREADY_INCLUDED'] : [],
    );
    const hasValidationErrors = validationErrors.length > 0;
    const shouldInlineTags = domainsPlacement === 'input' || readOnly;

    function handleCommit() {
        onAdd?.({
            name,
            value: hasValidationErrors ? domains ?? [] : (domains ?? []).concat(value),
            errors: hasValidationErrors ? validationErrors : undefined,
        });

        if (!hasValidationErrors) {
            onChange?.({
                name,
                value: '',
            });
        }
    }

    useEffect(() => {
        onValidationError?.({
            errors: validationErrors,
        });
        // We use the serialized errors to notify consumers
        // only when validationErrors change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationErrors.join(',')]);

    return (
        <Stack onFocus={onFocus} onBlur={onBlur}>
            <FieldLabel error={error} disabled={disabled} readOnly={readOnly} fieldName={fieldName} />

            <Stack direction="row" spacing={3}>
                <Input
                    {...props}
                    inputRef={ref}
                    disabled={disabled}
                    readOnly={readOnly}
                    error={error}
                    minLength={DOMAIN_MIN_CHARACTERS}
                    maxLength={DOMAIN_MAX_CHARACTERS}
                    sx={
                        shouldInlineTags
                            ? {
                                  '.MuiInputBase-root': {
                                      flexWrap: 'wrap',

                                      '> input': {
                                          maxWidth: readOnly ? 0 : 200,
                                      },
                                  },
                              }
                            : null
                    }
                    fullWidth
                    name="restrictedUserDomains"
                    startAdornment={
                        shouldInlineTags ? (
                            <Domains readOnly={readOnly} name={name} domains={domains} onRemove={onRemove} my={2} />
                        ) : null
                    }
                    value={value}
                    onChange={({ target: { value: newValue } }) => {
                        onChange?.({
                            name,
                            value: newValue,
                        });
                    }}
                    inputProps={{
                        onKeyDown: event => {
                            const { key } = event;

                            if (key === 'Enter') {
                                // We are preventing default here such that
                                // enter will not submit a form
                                event.preventDefault();
                                handleCommit();
                            }
                        },
                    }}
                />

                {!readOnly && (
                    <Button disabled={!validateDomain(value).isValid} onClick={handleCommit}>
                        {t('domain_input.cta.add')}
                    </Button>
                )}
            </Stack>

            <Box pr={8}>
                <FieldCaption error={error} caption={caption} />

                {(domains?.length ?? 0) > 0 && !!domainsLabel && domainsPlacement === 'below' && !readOnly && (
                    <Text mt={3} as="div" variant="detail">
                        {domainsLabel}
                    </Text>
                )}

                {domainsPlacement === 'below' && !readOnly && (
                    <Domains name={name} domains={domains} onRemove={onRemove} mt={2} />
                )}
            </Box>
        </Stack>
    );
});
