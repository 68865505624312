import { Duration } from 'luxon';

import { ActiveFaultsSummaryQueryResult, useActiveFaultsSummaryQuery } from 'generated/graphql';

export default function useActiveFaultSummary({ accountID }: { accountID?: string }): {
    /**
     * Returns -1 if the total is unknown (i.e. loading)
     */
    totalFaults?: number;

    /**
     * The fault counts sorted by criticality. Most critical first.
     */
    counts: { severity: string; count: number }[];

    /**
     * Force refetch for summary counts
     */
    refetch: ActiveFaultsSummaryQueryResult['refetch'];
} {
    const { loading, data, refetch } = useActiveFaultsSummaryQuery({
        variables: {
            input: {
                accountID,
            },
        },

        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });

    /**
     * Apollo Client in development mode throws an error regarding modifying a read-only property
     * so we're copying the returned data before sorting.
     */
    const counts = [...(data?.countActiveFaultsBySeverity ?? [])].sort(a => {
        return /CRITICAL/i.test(a.severity) ? -1 : 1;
    });

    const totalFaults = loading
        ? -1
        : counts.reduce((acc, cur) => {
              return acc + cur.count;
          }, 0);

    return {
        counts,
        totalFaults,
        refetch,
    };
}
