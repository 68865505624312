import { forwardRef, ReactElement, Ref, useCallback } from 'react';
import get from 'lodash/get';

import { Theme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { IconProps as MuiIconProps } from '@mui/material/Icon';

import { IconName } from 'app/assets/icons/types';
import { normalizeSxProp } from 'app/components/normalizeSxProp';

import useLazyIcon from './useLazyIcon';

const SIZES = {
    sm: '1rem',
    md: '1.5rem',
    lg: '2.25rem',
} as const;

const FROM_MUI_FONT_SIZE = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
} as const;

export type IconSize = keyof typeof SIZES;

/**
 * The `string` type widens the type to `string | null`, making the type ineffective for type checking,
 * but the `Theme` and 'inherit' types allow for auto complete.
 */
export type IconColor = keyof Theme['palette']['text'] | 'inherit' | string | null;

interface IconProps extends Omit<BoxProps, 'color'> {
    /**
     * The name of icon to apply. The names can be seen in the storybook or
     * in the filename for the icon SVG
     *
     * This prop is only optional when spacer is true
     */
    name?: IconName;

    /**
     * Whether the icon is used as a spacer or not. Useful for saving
     * the exact amount of space for a particular icon size.
     */
    spacer?: boolean;

    /**
     * The supported sizes for the icon component
     */
    size?: IconSize;

    /**
     * This prop should never be used, but it's here such that
     * the Icons used within MUI match the expected interface
     */
    fontSize?: MuiIconProps['fontSize'];

    /**
     * The key into the themes color palette (i.e. 'primary.main')
     */
    color?: IconColor;

    /**
     * label to describe the icon for users who rely on a screen reader
     */
    label?: string;

    /**
     * Debug boundary highlights the edge of the icon to visualize spacing
     * and alignment of icons when developing or showing designers where there
     * might be issues
     *
     * NOTE: For development purposes only!
     */
    debugBoundary?: boolean;

    /**
     * Debug placement helps to highlight when icons may not be balanced
     *
     * NOTE: For development purposes only!
     */
    debugPlacement?: boolean;
}

/**
 * Responsible for providing access to our icon set
 */
export default forwardRef(function Icon(
    {
        name,
        color,
        label,
        size,
        fontSize,
        spacer = false,
        debugBoundary = false,
        debugPlacement = false,
        sx,
        ...props
    }: IconProps,
    ref: Ref<HTMLElement | null>,
): ReactElement {
    const { ref: inViewRef, IconSvg } = useLazyIcon(name, { skip: !name || spacer });

    const dimension = SIZES[size ?? FROM_MUI_FONT_SIZE[fontSize ?? '']] || SIZES.md;

    /**
     * Combine refs
     */
    const setRefs = useCallback(
        (node: HTMLElement) => {
            if (ref != null && typeof ref === 'object') {
                // TODO(derek): figure out how to type this mutable ref
                // @ts-ignore
                ref.current = node;
            } else if (typeof ref === 'function') {
                ref(node);
            }

            inViewRef(node);
        },
        [inViewRef, ref],
    );

    return (
        <Box
            {...props}
            ref={setRefs}
            aria-label={label}
            className={`mx-icon ${props.className}`}
            sx={[
                theme => ({
                    display: 'inline-flex',
                    height: dimension,
                    width: dimension,

                    // MUI depends on em units in some cases when expecting an Icon
                    fontSize: dimension,

                    // to prevent collapse in a flexbox layout
                    minWidth: dimension,

                    fill: color
                        ? // The Mui Icon allowed passing the top level color name and implicitly selected main
                          // We allow selecting any color in the palette (for now) by path but if not found will
                          // fallback to the Mui Icon color prop behavior else if nothing is found match the text
                          // color
                          get(theme.palette, `text.${color}`) ??
                          get(theme.palette, `${color}.main`) ??
                          get(theme.palette, color) ??
                          'currentColor'
                        : 'currentColor',

                    '> svg': {
                        fill: 'inherit',
                    },

                    borderRadius: debugPlacement ? '9999px' : null,
                    outline: debugBoundary || debugPlacement ? '1px solid black' : null,
                }),
                ...normalizeSxProp(sx),
            ]}
        >
            {!spacer && <IconSvg />}
        </Box>
    );
});
