import { ComponentProps, FocusEventHandler, ReactNode, useCallback } from 'react';

import { TimeValidationError } from '@mui/x-date-pickers';

import BusinessHourSelector from 'app/components/primitives/interactive/BusinessHourSelector';
import { makeDateTimeChangeEvent, TimeChangeEvent } from 'app/components/primitives/interactive/date-time-inputs';
import { hourToISOTime, isoTimeToHour, PossibleISO8601 } from 'app/core/date-time';
import { IANATimeZone, ISO8601 } from 'app/core/types';

interface AllocationTimeSelectorProps {
    /**
     * The caption to display below the input.
     */
    caption?: ReactNode;
    /**
     * Determines whether the input is disabled.
     */
    disabled?: boolean;
    /**
     * Determines whether the input is in an error state.
     */
    error?: boolean;
    /**
     * The name of the field displayed above the input.
     */
    fieldName?: string;
    /**
     * The maximum time that can be selected.
     */
    maxTime?: ISO8601;
    /**
     * The minimum time that can be selected.
     */
    minTime?: ISO8601;
    /**
     * The name of the input.
     */
    name: string;
    /**
     * A callback that is fired when the input loses focus.
     */
    onBlur?: FocusEventHandler;
    /**
     * A callback that is fired when the input value changes.
     */
    onChange: (event: TimeChangeEvent) => void;
    /**
     * Determines whether the input is required.
     */
    required?: boolean;
    /**
     * The time zone for the given value.
     */
    timeZone?: IANATimeZone;
    /**
     * The value of the input.
     *
     * @example
     *
     * '06:00:00'
     */
    value: ISO8601 | null;
}

const DEFAULT_HOUR = 6;

export function AllocationSwapTimeSelector({
    caption,
    disabled,
    error,
    fieldName,
    maxTime,
    minTime,
    name,
    onBlur,
    onChange,
    required,
    timeZone,
    value,
}: AllocationTimeSelectorProps) {
    type HourChangeHandler = ComponentProps<typeof BusinessHourSelector>['onChange'];

    const handleChange = useCallback<HourChangeHandler>(
        event => {
            const nextValue = hourToISOTime(event.value.hour || DEFAULT_HOUR, event.value.timeZone);

            onChange?.(
                makeDateTimeChangeEvent<PossibleISO8601.Time, TimeValidationError>({
                    name,
                    timeZone: event.value.timeZone,
                    value: nextValue,
                }),
            );
        },
        [onChange, name],
    );

    const hour = value ? isoTimeToHour(value, timeZone) : undefined;
    const closingHour = maxTime ? isoTimeToHour(maxTime, timeZone) : undefined;
    const openingHour = minTime ? isoTimeToHour(minTime, timeZone) : undefined;

    return (
        <BusinessHourSelector
            caption={caption}
            closingHour={closingHour}
            disabled={disabled}
            error={error}
            fieldName={fieldName}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            openingHour={openingHour}
            required={required}
            timeZone={timeZone}
            value={hour}
        />
    );
}
