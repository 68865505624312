import { useGetAccountRolesForAccountQuery } from 'generated/graphql';

interface UseAccountRolesArgs {
    targetAccountID?: string;
}

export function useAccountRoles({ targetAccountID }: UseAccountRolesArgs) {
    const { data } = useGetAccountRolesForAccountQuery({
        variables: { accountID: targetAccountID ?? '' },
        skip: !targetAccountID,
    });

    const accountRoleNames = (data?.getAccount?.accountRoles ?? []).map(role => role.name);

    return { accountRoleNames };
}
