import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import { MUIMarginPropName } from 'styles/theme/types';

type Props = Omit<ComponentProps<typeof Box>, MUIMarginPropName>;

export default function DataGridFillCell({ children, ...props }: Props): ReactElement {
    return (
        <Box
            {...props}
            sx={normalizeSxProp(props.sx).concat(theme => ({
                height: '100%',
                width: `calc(100% + ${theme.spacing(4)} + ${theme.spacing(4)})`,
                mx: -4,
            }))}
        >
            {children}
        </Box>
    );
}
