import { ComponentProps, ReactNode, useMemo } from 'react';
import { DateTime } from 'luxon';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { IANATimeZone, ISO8601 } from 'app/core/types';

import DateRangeCalendar from '../DateRangeCalendar';

type CalendarProps = Omit<
    ComponentProps<typeof DateRangeCalendar>,
    'candidate' | 'end' | 'maxDate' | 'minDate' | 'start' | 'value'
>;

interface DateRangePopoverProps extends CalendarProps {
    /**
     * The element to which the popover should be anchored.
     */
    anchor: HTMLElement | null;
    /**
     * Candidate end or start date (depending on selectionTarget)
     */
    candidate: ISO8601 | null;
    /**
     * The end date of the range.
     */
    end: ISO8601 | null;
    /**
     * The time zone of the end date.
     */
    endTimeZone?: IANATimeZone;
    /**
     * The error message to display.
     */
    errorMessage?: ReactNode;
    /**
     * The maximum date that can be selected.
     */
    maxDate?: ISO8601 | null;
    /**
     * The minimum date that can be selected.
     */
    minDate?: ISO8601 | null;
    /**
     * Determines whether the popover is open.
     */
    open: boolean;
    /**
     * The start date of the range.
     */
    start: ISO8601 | null;
    /**
     * The time zone of the start date.
     */
    startTimeZone?: IANATimeZone;
    /**
     * The currently selected date.
     *
     * @example
     *
     * '2021-10-01'
     */
    value: ISO8601 | null;
}

export function DateRangePopover({
    anchor,
    candidate,
    end,
    endTimeZone,
    errorMessage,
    maxDate,
    minDate,
    open,
    selectionTarget,
    start,
    startTimeZone,
    value,
    ...otherProps
}: DateRangePopoverProps) {
    /**
     * A time zone must be set for the `candidate` and `value` props,
     * otherwise `DateRangeCalendar` will render incorrectly.
     */
    const timeZone = (selectionTarget === 'start' ? startTimeZone : endTimeZone) ?? 'local';

    const candidateDateTime = useMemo(() => {
        return candidate ? DateTime.fromISO(candidate, { zone: timeZone }) : null;
    }, [candidate, timeZone]);

    const endDateTime = useMemo(() => {
        return end ? DateTime.fromISO(end, { zone: endTimeZone }) : null;
    }, [end, endTimeZone]);

    const minDateDateTime = useMemo(() => {
        return minDate ? DateTime.fromISO(minDate, { zone: startTimeZone }) : undefined;
    }, [minDate, startTimeZone]);

    const maxDateDateTime = useMemo(() => {
        return maxDate ? DateTime.fromISO(maxDate, { zone: endTimeZone }) : undefined;
    }, [endTimeZone, maxDate]);

    const startDateTime = useMemo(() => {
        return start ? DateTime.fromISO(start, { zone: startTimeZone }) : null;
    }, [start, startTimeZone]);

    const valueDateTime = useMemo(() => {
        return value ? DateTime.fromISO(value, { zone: timeZone }) : undefined;
    }, [value, timeZone]);

    return (
        <Popper open={open} anchorEl={anchor} placement="bottom-start" disablePortal sx={{ zIndex: 10 }}>
            <Paper sx={{ my: 2, pb: 4, px: 2, width: '340px' }}>
                <DateRangeCalendar
                    candidate={candidateDateTime}
                    end={endDateTime}
                    minDate={minDateDateTime}
                    maxDate={maxDateDateTime}
                    selectionTarget={selectionTarget}
                    start={startDateTime}
                    value={valueDateTime}
                    {...otherProps}
                />
                {errorMessage && (
                    <Box mt={1} mx={1}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Box>
                )}
            </Paper>
        </Popper>
    );
}
