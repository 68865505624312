import { createSelector } from 'reselect';

import { makeUnits } from './transformers';
import { Allocation, PersistedAllocation, ReservationScheduleState, Unit } from './types';
import { isPersistedAllocation } from './utils';

export function selectPendingAllocations(state: ReservationScheduleState) {
    return state.pendingAllocations;
}

function selectReservationItems(state: ReservationScheduleState) {
    return state.reservationItems;
}

export const selectUnits = createSelector(
    selectPendingAllocations,
    selectReservationItems,
    (pendingAllocations, reservationItems): readonly Unit[] => {
        return makeUnits(reservationItems, pendingAllocations);
    },
);

export const selectUnitCount = createSelector(selectUnits, (units): number => {
    return units.length;
});

export const selectHasSchedules = createSelector(selectReservationItems, (reservationItems): boolean => {
    return !!reservationItems.find(item => !!item.deviceInstanceSchedule.length);
});

const selectAllAllocations = createSelector(selectUnits, (units): Allocation[] => {
    return units.reduce<Allocation[]>((result, unit) => [...result, ...unit.allocations], []);
});

export const selectPersistedAllocations = createSelector(selectAllAllocations, (allocations): PersistedAllocation[] => {
    return allocations.filter(isPersistedAllocation);
});

export const selectPersistedAllocationCount = createSelector(selectPersistedAllocations, (allocations): number => {
    return allocations.length;
});

export const selectAllocationByID = createSelector(
    selectAllAllocations,
    (allocations): Record<Allocation['id'], Allocation | undefined> => {
        return Object.fromEntries(allocations.map(allocation => [allocation.id, allocation]));
    },
);

export const selectUnitByID = createSelector(selectUnits, (units): Record<Unit['id'], Unit | undefined> => {
    return Object.fromEntries(units.map(unit => [unit.id, unit]));
});
