import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react';
import { To } from 'react-router-dom';

import { Box, Paper, Stack } from '@mui/material';

import { ZeroState } from 'app/components/compounds';
import { Icon, Link, LoadingOverlay, SectionHeader, Text } from 'app/components/primitives';
import { IconAndText, Layer } from 'app/components/primitives/layout';

import { useI18n } from 'i18n';

import { withProps } from '../../withProps';
import { PageHeader } from '../AppChrome';

interface UnitLayoutSectionProps extends ComponentPropsWithoutRef<typeof Box> {
    heading?: ReactNode;
    subheading?: ReactNode;
    cta?: ReactElement | null;
    loading?: boolean;
}

function Section({ children, heading, subheading, cta, loading, ...otherProps }: UnitLayoutSectionProps) {
    return (
        <Layer anchor {...otherProps}>
            <Paper elevation={0} sx={{ p: 4, borderWidth: 1, borderColor: 'border.light', borderStyle: 'solid' }}>
                {!!heading && (
                    <SectionHeader mb={4} headingLevel="H2" title={heading} subtitle={subheading} cta={cta} />
                )}

                <Layer anchor>
                    {children}

                    <LoadingOverlay loading={loading} />
                </Layer>
            </Paper>
        </Layer>
    );
}

const AnchorLayer = withProps(Layer, props => ({
    ...props,
    minHeight: '100%',
    anchor: true,
}));

const ContentStack = withProps(Stack, props => ({
    ...props,
    spacing: 5,
    mb: 6,
    sx: { overflowX: 'hidden' },
}));

interface UnitPageLayoutProps {
    /**
     * Content rendered when the page is not loading or
     */
    children: ReactNode;

    /**
     * The destination of the exit hyperlink rendered when the page is rendering missing content.
     */
    exitDest: To;

    /**
     * Determines whether the requested data is considered loading
     */
    isLoading?: boolean;

    /**
     * Determines whether the unit is considered missing
     */
    isNotFound?: boolean;

    /**
     * The name of the MPU being displayed
     */
    mpuName?: string;

    /**
     * The total number of faults associated with the MPU
     */
    activeFaultCount?: number;
}

/**
 * Components for creating unit details pages.
 *
 * @example
 *
 * <UnitPageLayout exitDest="/">
 *     <UnitPageLayout.ContentStack>
 *         My unit details content
 *     </UnitPageLayout.ContentStack>
 * </UnitPageLayout>
 */
export function UnitPageLayout({
    children,
    isLoading,
    isNotFound,
    exitDest,
    mpuName,
    activeFaultCount = 0,
}: UnitPageLayoutProps) {
    const { t } = useI18n();

    function renderContent() {
        if (isNotFound) {
            return (
                <ZeroState
                    title={t('unit_page.zero_state.title')}
                    message={
                        <Text renderLink={({ text }) => <Link to={exitDest}>{text}</Link>}>
                            {t('unit_page.zero_state.message')}
                        </Text>
                    }
                />
            );
        }

        return <>{children}</>;
    }

    return (
        <AnchorLayer>
            <PageHeader
                title={isLoading ? '' : t('unit_page.title', { name: mpuName })}
                subtitle={
                    activeFaultCount > 0 ? (
                        <Link color="danger" to="#status">
                            <IconAndText
                                icon={<Icon name="alert-circle" />}
                                text={t('unit_page.subtitle_faults', { count: activeFaultCount })}
                            />
                        </Link>
                    ) : null
                }
            />

            {renderContent()}
        </AnchorLayer>
    );
}

UnitPageLayout.ContentStack = ContentStack;
UnitPageLayout.Section = Section;
