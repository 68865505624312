import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { MPU } from './types';
import useMPUs from './useMPUs';

type UseInfiniteMPUsProps = Pick<ReturnType<typeof useMPUs>, 'error' | 'fetchNextPage' | 'hasNextPage' | 'loading'> & {
    currentPage: MPU[];
};

/**
 * A hook responsible for integrating infinite scroll behavior with MPU pagination.
 *
 * This hook is used to fetch the next page of MPUs when the user scrolls to the bottom of the page.
 * Component-level caching is used to combine the fetched pages into a single collection of MPUs.
 *
 * TODO(Morris): Update MPUs on an interval to keep the data fresh.
 * TODO(Derek): Consider using a `fieldPolicy` instead https://www.apollographql.com/docs/react/pagination/core-api/#why-do-i-need-a-field-policy.
 */
export function useInfiniteScrollMPUs({
    currentPage,
    error,
    fetchNextPage,
    hasNextPage,
    loading,
}: UseInfiniteMPUsProps) {
    const [cursorRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: !!error,
    });

    const [mpus, setMPUs] = useState<MPU[]>([]);

    /**
     * Every time the current page collection changes,
     * we want to append the new MPUs to the existing collection,
     * and dedupe the collection.
     */
    useEffect(() => {
        if (currentPage) {
            setMPUs(prev => {
                const allMPUs = [...prev, ...currentPage];

                // Dedupe the MPUs by ID while maintaining the order of the fetched results
                return Array.from(new Map(allMPUs.map(mpu => [mpu.id, mpu])).values());
            });
        }
    }, [currentPage]);

    const scrollCursorElement = <div ref={cursorRef}></div>;

    return {
        mpus,
        rootRef,
        scrollCursorElement,
    };
}
