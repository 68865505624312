import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

import Text from '../Text';

const flipTextOnMobile = {
    textOrientation: { xs: 'sideways', sm: 'auto' },
    writingMode: { xs: 'vertical-lr', sm: 'unset' },
};

export default function LinearPercentageScale(props: BoxProps): ReactElement {
    return (
        <Box {...props} width="100%" display="flex" justifyContent="space-between">
            <Text sx={flipTextOnMobile}>0%</Text>
            <Text sx={flipTextOnMobile}>50%</Text>
            <Text sx={flipTextOnMobile}>100%</Text>
        </Box>
    );
}
