import { ComponentProps, useCallback } from 'react';

import MuiAutocomplete from '@mui/material/Autocomplete';

import Input from './Input';

/**
 * Responsible for integrating the Input component with MUI's Autocomplete component
 * via the renderInput render prop
 */
export default function useInputForAutocomplete(
    props: Partial<ComponentProps<typeof Input>>,
): ComponentProps<typeof MuiAutocomplete>['renderInput'] {
    return useCallback(
        params => {
            return (
                <Input
                    {...params}
                    {...params.InputProps}
                    /**
                     * MUI's Autocomplete sends event handlers to be attached to
                     * the input element via inputProps.
                     */
                    inputProps={params.inputProps}
                    rootRef={params.InputProps.ref}
                    {...props}
                />
            );
        },
        [props],
    );
}
