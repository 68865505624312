import { DateTime } from 'luxon';
import * as yup from 'yup';

import {
    combineDateAndTime,
    makeMaxStartDateTest,
    makeMaxStartTimeTest,
    makeMinEndDateTest,
    makeMinEndTimeTest,
} from 'app/core/date-time';
import { ISO8601 } from 'app/core/types';

import { TranslateFunction } from 'i18n';

import type { ReservationScheduleAllocationFormValues } from './ReservationScheduleAllocation';
import { Allocation, Unit } from './types';
import { MIN_SCHEDULE_DURATION } from './utils';

export function getMaxEndDateTime({
    nextAllocationEnd,
    unitEnd,
}: {
    nextAllocationEnd: ISO8601 | undefined;
    unitEnd: ISO8601;
}) {
    const maxEndDateTime = nextAllocationEnd
        ? DateTime.fromISO(nextAllocationEnd)
              // Leave room for the minimum schedule duration
              .minus(MIN_SCHEDULE_DURATION)
        : DateTime.fromISO(unitEnd);

    return maxEndDateTime;
}

export function getMinStartDateTime({
    prevAllocationStart,
    unitStart,
}: {
    prevAllocationStart: ISO8601 | undefined;
    unitStart: ISO8601;
}) {
    const minStartDateTime = prevAllocationStart
        ? DateTime.fromISO(prevAllocationStart)
              // Leave room for the minimum schedule duration
              .plus(MIN_SCHEDULE_DURATION)
        : DateTime.fromISO(unitStart);

    return minStartDateTime;
}

export function makeValidationSchema({
    nextAllocation,
    prevAllocation,
    t,
    unit,
}: {
    nextAllocation?: Allocation;
    prevAllocation?: Allocation;
    t: TranslateFunction;
    unit?: Unit;
}) {
    const swapMaxTime: yup.TestConfig<ISO8601 | null | undefined> = {
        name: 'swap_max_time',
        message: t('reservation_schedule.validation.swap_max_time'),
        test: (endTime, context): boolean => {
            const { endDate, endTimeZone } = context.parent as ReservationScheduleAllocationFormValues;

            if (!endDate || !endTime || !unit) return false;

            const endDateTime = combineDateAndTime(endDate, endTime, endTimeZone);

            if (!endDateTime.isValid) return false;

            const unitEndDateTime = DateTime.fromISO(unit.end);
            const occursOnSameDay =
                endDateTime.setZone(endTimeZone).toISODate() === unitEndDateTime.setZone(endTimeZone).toISODate();
            const swapTimeMaxDateTime = occursOnSameDay ? unitEndDateTime : endDateTime.endOf('day');

            return endDateTime <= swapTimeMaxDateTime;
        },
    };

    const swapMinTime: yup.TestConfig<ISO8601 | null | undefined> = {
        name: 'swap_min_time',
        message: t('reservation_schedule.validation.swap_min_time'),
        test: (endTime, context): boolean => {
            const { endDate, endTimeZone, startDate, startTime, startTimeZone } =
                context.parent as ReservationScheduleAllocationFormValues;

            if (!startDate || !startTime || !endDate || !endTime || !unit) return false;

            const endDateTime = combineDateAndTime(endDate, endTime, endTimeZone);
            const startDateTime = combineDateAndTime(startDate, startTime, startTimeZone);

            if (!endDateTime.isValid || !startDateTime.isValid) return false;

            const unitStartDateTime = DateTime.fromISO(unit.start);
            const occursOnSameDay =
                startDateTime.setZone(startTimeZone).toISODate() ===
                unitStartDateTime.setZone(startTimeZone).toISODate();
            const swapTimeMinDateTime = occursOnSameDay
                ? unitStartDateTime
                      // Leave room for the minimum schedule duration
                      .plus(MIN_SCHEDULE_DURATION)
                : startDateTime.startOf('day');

            return endDateTime >= swapTimeMinDateTime;
        },
    };

    const startMinDateTime: yup.TestConfig<ISO8601 | null | undefined> = {
        name: 'start_min_date_time',
        message: t('reservation_schedule.validation.start_min_date_time'),
        test: (startDate, context): boolean => {
            const { startTime, startTimeZone } = context.parent as ReservationScheduleAllocationFormValues;

            if (!startDate || !startTime || !unit) return false;

            const startDateTime = combineDateAndTime(startDate, startTime, startTimeZone);

            if (!startDateTime.isValid) return false;

            const minStartDateTime = getMinStartDateTime({
                prevAllocationStart: prevAllocation?.start,
                unitStart: unit.start,
            });

            return startDateTime >= minStartDateTime;
        },
    };

    const endMaxDateTime: yup.TestConfig<ISO8601 | null | undefined> = {
        name: 'end_max_date_time',
        message: t('reservation_schedule.validation.end_max_date_time'),
        test: (endDate, context): boolean => {
            const { endTime, endTimeZone } = context.parent as ReservationScheduleAllocationFormValues;

            if (!endDate || !endTime || !unit) return false;

            const endDateTime = combineDateAndTime(endDate, endTime, endTimeZone);

            if (!endDateTime.isValid) return false;

            const maxEndDateTime = getMaxEndDateTime({
                nextAllocationEnd: nextAllocation?.end,
                unitEnd: unit.end,
            });

            return endDateTime <= maxEndDateTime;
        },
    };

    return yup.object().shape({
        endDate: yup.string().required().test(makeMinEndDateTest(t)).test(endMaxDateTime),
        endTime: yup.string().required().test(makeMinEndTimeTest(t)).test(swapMaxTime).test(swapMinTime),
        endTimeZone: yup.string().required(),
        startDate: yup.string().required().test(makeMaxStartDateTest(t)).test(startMinDateTime),
        startTime: yup.string().required().test(makeMaxStartTimeTest(t)),
        startTimeZone: yup.string().required(),
    });
}
