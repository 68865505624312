import React, { useEffect } from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

interface OktaProviderProps {
    children: React.ReactNode;
}

// TODO(will): pick up from environment instead of hard coding
const oktaAuth = new OktaAuth({
    issuer: 'https://login.moxionpower.com/oauth2/default',
    clientId: '0oa5arc2uruLlGfDs5d7',
    redirectUri: window.location.origin + '/login/callback',
});

const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
};

export function OktaProvider({ children }: OktaProviderProps) {
    const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri) => {
        window && window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    useEffectOnMount(() => {
        // enable services
        oktaAuth.start();

        // disable services on tear down
        return () => {
            oktaAuth.stop();
        };
    });

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
}
