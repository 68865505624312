import { ComponentProps, useState } from 'react';
import { SingleExecutionResult } from '@apollo/client';
import { Outlet, useParams } from 'react-router-dom';

import { GridFilterModel, GridPaginationModel } from '@mui/x-data-grid';

import { Button, ModalDialog } from 'app/components';
import { useLayer } from 'app/core/layers';
import { AccountRoleName } from 'app/core/Session';

import {
    AccountInput,
    AssociateNetSuiteCustomerWithAccountMutation,
    useAssociateNetSuiteCustomerWithAccountMutation,
    useUpdateAccountBasicsMutation,
} from 'generated/graphql';

import { useI18n } from 'i18n';

import { USERS_TABLE_ROWS_PER_PAGE_OPTIONS } from '../UsersTable/UsersTable';
import useUnsavedChangesBlocker from '../useUnsavedChangesBlocker';
import AccountDetails from './AccountDetails';
import AccountDomains from './AccountDomains';
import AccountRoles from './AccountRoles';
import { AccountPage } from './presentation';
import TeamMembers from './TeamMembers';
import useAccount from './useAccount';
import { useUpdateAccountRoles } from './useAccountRoles';

interface Params {
    accountID?: string;
}

export function AccountPageContainer() {
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: USERS_TABLE_ROWS_PER_PAGE_OPTIONS[0],
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [teamMembersFilterModel, setTeamMembersFilterModel] = useState<GridFilterModel>({ items: [] });

    const { t } = useI18n();
    const { toast } = useLayer();

    const showSaveSuccessToast = () => {
        toast.add({ severity: 'success', message: t('admin_account_page.account_updates_saved') });
    };
    const showSaveErrorToast = () => {
        toast.add({ severity: 'error', message: t('admin_account_page.account_updates_failed') });
    };

    const { accountID = '' }: Params = useParams();

    const { account, isAccountLoading, hasError, forbiddenFields, refetchAccount } = useAccount(accountID, searchTerm);

    const [updateAccountDetails] = useUpdateAccountBasicsMutation();
    const updateAccountRoles = useUpdateAccountRoles(accountID, [
        ...(account?.accountRoles?.map(ar => ar.name as AccountRoleName) ?? []),
    ]);

    const [associateNetsuiteCustomerAccountWithAccount] = useAssociateNetSuiteCustomerWithAccountMutation();

    const onSaveAccountDetails: ComponentProps<typeof AccountDetails>['onSubmit'] = async ({ value }) => {
        try {
            const { moxionRepID, netsuiteCustomerIDs, ...rest } = value;
            const accountInput: AccountInput = {
                ...rest,
                moxionRepUserIDs: moxionRepID ? [moxionRepID] : undefined,
            };

            await updateAccountDetails({
                variables: {
                    accountInput,
                },
            });

            if (account && netsuiteCustomerIDs && netsuiteCustomerIDs.length > account.netsuiteCustomerIDs.length) {
                const mutations: Promise<
                    SingleExecutionResult<
                        AssociateNetSuiteCustomerWithAccountMutation,
                        Record<string, any>,
                        Record<string, any>
                    >
                >[] = [];

                for (const netsuiteCustomerID of netsuiteCustomerIDs) {
                    mutations.push(
                        associateNetsuiteCustomerAccountWithAccount({
                            variables: { input: { accountID: account.id, netsuiteCustomerID } },
                        }),
                    );
                }

                await Promise.all(mutations);
            }

            showSaveSuccessToast();
        } catch (error) {
            showSaveErrorToast();
        }
    };

    const onSaveAccountDomains: ComponentProps<typeof AccountDomains>['onSubmit'] = async ({ value }) => {
        try {
            await updateAccountDetails({
                variables: {
                    accountInput: value,
                },
            });
            showSaveSuccessToast();
        } catch (error) {
            showSaveErrorToast();
        }
    };

    const onSaveAccountRoles: ComponentProps<typeof AccountRoles>['onSubmit'] = async ({ value }) => {
        try {
            if (await updateAccountRoles(value.accountRoleNames)) {
                showSaveSuccessToast();
            }
        } catch (error) {
            showSaveErrorToast();
        }
    };

    const { remindOfUnsavedChanges, stashChangesFor, stayOnPage, proceedWithoutSaving, saveAndProceed } =
        useUnsavedChangesBlocker();

    return (
        <>
            <AccountPage
                isLoading={isAccountLoading}
                error={hasError}
                account={account}
                detailsSection={
                    <AccountDetails
                        accountID={account?.id ?? accountID}
                        name={account?.name ?? ''}
                        industry={account?.industry ?? ''}
                        creationDate={account?.creationDate}
                        netsuiteCustomerIDs={account?.netsuiteCustomerIDs}
                        moxionRepID={account?.moxionRepID}
                        pointOfContact={account?.pointOfContact}
                        contactPhone={account?.contactPhone ?? ''}
                        billingAddressLocationID={account?.billingAddress?.id}
                        moxionInternalNotes={account?.moxionInternalNotes}
                        excludeFields={forbiddenFields}
                        onSubmit={onSaveAccountDetails}
                        onFormStateChange={stashChangesFor<Parameters<typeof onSaveAccountDetails>['0']['value']>(
                            'details',
                            onSaveAccountDetails,
                        )}
                    />
                }
                domainsSection={
                    <AccountDomains
                        accountID={accountID}
                        restrictedUserDomains={account?.restrictedUserDomains}
                        onSubmit={onSaveAccountDomains}
                        onFormStateChange={stashChangesFor<Parameters<typeof onSaveAccountDomains>['0']['value']>(
                            'domains',
                            onSaveAccountDomains,
                        )}
                    />
                }
                rolesSection={
                    <AccountRoles
                        accountID={accountID}
                        accountRoleNames={account?.accountRoles?.map(ar => ar.name) ?? []}
                        onSubmit={onSaveAccountRoles}
                        onFormStateChange={stashChangesFor<Parameters<typeof onSaveAccountRoles>['0']['value']>(
                            'roles',
                            onSaveAccountRoles,
                        )}
                    />
                }
                teamMembersSection={
                    <TeamMembers
                        loading={isAccountLoading}
                        teamMembers={account?.teamMembers ?? []}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        accountID={accountID}
                        filterModel={teamMembersFilterModel}
                        onFilterModelChange={setTeamMembersFilterModel}
                        totalRowCount={account?.teamMembers.length ?? 0}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                    />
                }
            />

            <Outlet
                context={{
                    onInviteSent: refetchAccount,
                }}
            />

            <ModalDialog
                open={remindOfUnsavedChanges}
                onClose={stayOnPage}
                title={t('admin_account_page.unsaved_changes_dialog_title')}
                tertiaryCTA={<Button onClick={proceedWithoutSaving}>{t('cta.do_not_save')}</Button>}
                primaryCTA={<Button onClick={saveAndProceed}>{t('cta.save')}</Button>}
            >
                {t('admin_account_page.unsaved_changes_dialog_message')}
            </ModalDialog>
        </>
    );
}
