import { RefObject } from 'react';
import { easeInOut } from 'framer-motion';
import { FixedSizeList, GridOnScrollProps, VariableSizeGrid, VariableSizeList } from 'react-window';
import { Deferred } from 'ts-deferred';

import { DEFAULT_ANIMATE_DURATION } from './constants';
import { MultiGridController, MultiGridScrollPosition } from './types';

/**
 * Consolidate all refs into one object for portability
 */
interface MultiGridApiParams<ItemData> {
    asideList: RefObject<VariableSizeList<ItemData>>;
    bodyGrid: RefObject<VariableSizeGrid<ItemData>>;
    headingList: RefObject<FixedSizeList<ItemData>>;
    initialScrollLeft: number;
    initialScrollTop: number;
    latestScroll: RefObject<GridOnScrollProps>;
}

export function makeMultiGridController<ItemData>(params: MultiGridApiParams<ItemData>): MultiGridController<ItemData> {
    const { asideList, bodyGrid, headingList, initialScrollLeft, initialScrollTop, latestScroll } = params;

    function resetAfterRowIndex(index: number, shouldForceUpdate?: boolean): void {
        asideList.current?.resetAfterIndex(index, shouldForceUpdate);
        bodyGrid.current?.resetAfterRowIndex(index, shouldForceUpdate);
    }

    function resetAfterColumnIndex(index: number, shouldForceUpdate?: boolean): void {
        bodyGrid.current?.resetAfterColumnIndex(index, shouldForceUpdate);
    }

    function scrollTo({ scrollLeft, scrollTop }: MultiGridScrollPosition): void {
        if (scrollLeft !== undefined) {
            headingList.current?.scrollTo(scrollLeft);
        }
        if (scrollTop !== undefined) {
            asideList.current?.scrollTo(scrollTop);
        }
        bodyGrid.current?.scrollTo({ scrollLeft, scrollTop });
    }

    async function animateTo(
        { scrollLeft, scrollTop }: MultiGridScrollPosition,
        duration = DEFAULT_ANIMATE_DURATION,
    ): Promise<void> {
        const startedAt = performance.now();
        const scrollLeftStart = latestScroll.current?.scrollLeft ?? initialScrollLeft;
        const scrollTopStart = latestScroll.current?.scrollTop ?? initialScrollTop;
        const scrollLeftEnd = scrollLeft ?? scrollLeftStart;
        const scrollTopEnd = scrollTop ?? scrollTopStart;
        const scrollLeftDiff = scrollLeftEnd - scrollLeftStart;
        const scrollTopDiff = scrollTopEnd - scrollTopStart;

        let isComplete = false;
        let progress = 0;

        const completion = new Deferred<void>();

        const update = () => {
            if (isComplete) return;

            progress = (performance.now() - startedAt) / duration;

            if (progress >= 1) {
                progress = 1;
                isComplete = true;
                completion.resolve();
            }

            const easedProgress = easeInOut(progress);
            const nextScrollLeft = scrollLeftStart + scrollLeftDiff * easedProgress;
            const nextScrollTop = scrollTopStart + scrollTopDiff * easedProgress;

            scrollTo({
                scrollLeft: nextScrollLeft,
                scrollTop: nextScrollTop,
            });

            requestAnimationFrame(update);
        };

        requestAnimationFrame(update);

        return completion.promise;
    }

    return {
        animateTo,
        resetAfterColumnIndex,
        resetAfterRowIndex,
        scrollTo,
    };
}
