import { FocusEvent, MutableRefObject, ReactElement, ReactNode, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { DateField, DateValidationError } from '@mui/x-date-pickers';

import FieldGroup from 'app/components/compounds/FieldGroup/FieldGroup';
import { DEFAULT_TIME_ZONE } from 'app/core/date-time';
import { IANATimeZone } from 'app/core/types';

import { useI18n } from 'i18n';

import BusinessHourSelector from '../BusinessHourSelector';
import Input from '../Input';

export interface BusinessDateTimeFieldChangeEvent {
    value: {
        date: DateTime | null | undefined;
        hour: number | null | undefined;
        timeZone: IANATimeZone;
    };
}

interface Props {
    /**
     * The legend content contextualizing the field inputs
     */
    fieldName?: ReactNode;

    /**
     * Additional helper text or inline error
     */
    caption?: ReactNode;

    /**
     * Whether to render the field as readOnly / static
     */
    readOnly?: boolean;

    /**
     * Ref to the dateInput element for imperative handling of focus
     */
    dateInputRef?: MutableRefObject<HTMLInputElement | null>;
    /**
     * Ref to the timeInput element for imperative handling of focus
     */
    timeInputRef?: MutableRefObject<HTMLInputElement | null>;

    /**
     * Unique ID to assign a label to the dateInput element
     */
    id?: string;

    /**
     * The selected date
     */
    date: DateTime | null | undefined;
    /**
     * The selected hour
     */
    hour: number | null | undefined;
    /**
     * The time zone for the DateTime
     */
    timeZone: IANATimeZone | undefined;

    /**
     * Whether or not to allow inputting dates in the past, will show error treatment if invalid
     */
    disablePast?: boolean;
    /**
     * The minimum date to allow, if set and date is before minDate then will show error treatement
     */
    minDate?: DateTime;
    /**
     * The maximum date to allow, if set and date is after maxDate then will show error treatement
     */
    maxDate?: DateTime;

    /**
     * Whether or not the user can interact with the control
     */
    disabled?: boolean;

    /**
     * Whether the field value is required
     */
    required?: boolean;

    /**
     * Whether the date field value has validation errors
     */
    dateError?: boolean;

    /**
     * Whether the time field value has validation errors
     */
    timeError?: boolean;

    /**
     * Force visual treatment for focus
     */
    focused?: boolean;

    /**
     * Whether to fill the parent elements width or not
     */
    fullWidth?: boolean;

    /**
     * Event handler to be called when either the date or the hour changes
     */
    onFocus?: (event: FocusEvent) => void;
    /**
     * Event handler to be called when either the date or the hour changes
     */
    onBlur?: (event: FocusEvent) => void;

    /**
     * Event handler to be called when either the date or the hour changes
     */
    onChange: (event: BusinessDateTimeFieldChangeEvent) => void;
    /**
     * Event handler to be called when a validation error occurs based on the provided configuration
     */
    onError?: (event: DateValidationError) => void;
}

/**
 * Combination input for selecting a date and business hour
 */
export default function BusinessDateTimeField({
    // refs
    dateInputRef,
    timeInputRef,

    // a11y
    id,

    // values
    date,
    hour,
    timeZone = DEFAULT_TIME_ZONE,

    // validation
    disablePast = false,
    minDate,
    maxDate,
    required = true,
    dateError = false,
    timeError = false,

    // labels
    fieldName,
    caption,

    // display / states
    disabled = false,
    readOnly,
    focused,
    fullWidth = false,

    // event handlers
    onBlur = () => {},
    onFocus = () => {},
    onChange,
    onError = (event: DateValidationError) => {},
}: Props): ReactElement {
    const { format } = useI18n();

    const internalDateInputRef = useRef<HTMLInputElement | null>(null);
    const internalHourInputRef = useRef<HTMLInputElement | null>(null);

    const resolvedDateInputRef = dateInputRef ?? internalDateInputRef;
    const resolvedTimeInputRef = timeInputRef ?? internalHourInputRef;

    const [activeSection, setActiveSection] = useState<null | number | any>(null);

    const hasError = dateError || timeError;
    const readOnlyDateTime = !!date ? date.set({ hour: hour ?? undefined }).setZone(timeZone ?? '') : '';

    return (
        <FieldGroup
            disabled={disabled}
            error={hasError}
            fieldName={fieldName}
            focused={focused}
            fullWidth={fullWidth}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={readOnly}
            readOnlyContent={<Input readOnly value={format.dateTime(readOnlyDateTime)} />}
            required={required}
        >
            <FieldGroup.Segment>
                {/* TODO(Morris): Replace MUI DateField with our DateField component. */}
                <DateField
                    fullWidth={fullWidth}
                    disabled={disabled}
                    required={required}
                    focused={focused}
                    id={id}
                    minDate={minDate}
                    maxDate={maxDate}
                    disablePast={disablePast}
                    inputRef={inputEl => {
                        resolvedDateInputRef.current = inputEl;
                    }}
                    InputProps={{
                        error: dateError,
                    }}
                    value={date}
                    sx={{
                        '& .MuiOutlinedInput-input': {
                            width: '14ch',
                        },
                    }}
                    onSelectedSectionsChange={section => {
                        setActiveSection(section);
                    }}
                    onKeyDown={({ key }) => {
                        if (key === 'ArrowRight' && activeSection === 2) {
                            resolvedTimeInputRef?.current?.focus();
                        }
                    }}
                    onError={onError}
                    onChange={newValue => {
                        onChange({
                            value: {
                                hour,
                                date: newValue,
                                timeZone,
                            },
                        });
                    }}
                />
            </FieldGroup.Segment>
            <FieldGroup.Segment>
                <BusinessHourSelector
                    fullWidth={fullWidth}
                    disabled={disabled}
                    required={required}
                    readOnly={readOnly}
                    error={timeError}
                    ref={resolvedTimeInputRef}
                    name="hour"
                    value={hour}
                    timeZone={timeZone}
                    onKeyDown={({ key }) => {
                        const { selectionStart, selectionEnd } = resolvedTimeInputRef?.current ?? {};
                        const isCursorAtBegining = selectionStart === selectionEnd && selectionStart === 0;

                        if (isCursorAtBegining && key === 'ArrowLeft') {
                            resolvedDateInputRef?.current?.focus();
                        }
                    }}
                    onChange={({ value: { hour: newHour, timeZone } }) =>
                        onChange({
                            value: {
                                hour: newHour,
                                date: date?.setZone(timeZone),
                                timeZone,
                            },
                        })
                    }
                />
            </FieldGroup.Segment>
        </FieldGroup>
    );
}
