import createActionFactory from 'typescript-fsa';

import { ISO8601 } from 'app/core/date-time';

import { ScheduleEvent } from './types';

const createAction = createActionFactory('Schedule');

/**
 * Action payload for capturing mouse interactions on bars.
 */
type BarMouseActionPayload = {
    eventId: ScheduleEvent['id'];
};

/**
 * Dispatched when a bar is clicked.
 */
export const clickBar = createAction<BarMouseActionPayload>('clickBar');
/**
 * Ends a bar's hover state.
 */
export const endBarHover = createAction('endBarHover');
/**
 * Dispatched when the pointer stops hovering over a cell that belongs to a bar.
 */
export const requestBarHoverEnd = createAction('requestBarHoverEnd');
/**
 * Start's a bar's hover state.
 *
 * @remarks
 *
 * Only one bar can enter a hover state at a time.
 */
export const startBarHover = createAction<BarMouseActionPayload>('startBarHover');

/**
 * Collapse bars in a row into a single bar.
 */
export const collapseRow = createAction<{ rowIndex: number }>('collapseRow');
/**
 * Expand a row in the grid. Bars a repositioned by on their `expandedOffset`.
 */
export const expandRow = createAction<{ rowIndex: number }>('expandRow');
/**
 * Toggles the expansion of bars in a row.
 */
export const toggleRowExpansion = createAction<{ rowIndex: number }>('toggleRowExpansion');

/**
 * Scrolls the schedule forward in time.
 */
export const nextDateRange = createAction('nextDateRange');
/**
 * Scroll the schedule backwards in time.
 */
export const prevDateRange = createAction('prevDateRange');
/**
 * Scroll the schedule to the given date.
 */
export const scrollToDate = createAction<ISO8601>('scrollToDate');
