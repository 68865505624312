import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import Icon from 'app/components/primitives/Icon';
import Layer from 'app/components/primitives/layout/Layer';

interface Props {
    /**
     * Unique identifier to associate toggle controls for accessibility (mobile view only)
     */
    id: string;

    /**
     * Whether the mobile nav is open and visible
     */
    isOpen: boolean;

    /**
     * Callback to notifiy consumers of intent to close the mobile nav
     */

    onClose: () => void;

    /**
     * The nav width
     */
    width?: number | string;

    /**
     * The background color of the primary nav (must exist in MUI theme)
     */
    backgroundColor: string;

    /**
     * The primary nav content to render
     */
    children: JSX.Element | (JSX.Element | boolean)[] | null | undefined;
}

export default function PrimaryNavContainer({
    id,
    isOpen,
    onClose,
    width,
    backgroundColor,
    children,
}: Props): ReactElement {
    return (
        <>
            {/* Desktop Representation */}
            <Box sx={{ minWidth: width, width: width, display: { xs: 'none', md: 'flex' } }}>
                <Box
                    component="nav"
                    position="fixed"
                    sx={theme => ({
                        width: width,
                        height: '100vh',
                        backgroundColor,
                        zIndex: theme.zIndex.appBar,
                        overflowY: 'auto',
                    })}
                >
                    {children}
                </Box>
            </Box>

            {/* Mobile Representation */}
            <Drawer
                id={id}
                open={isOpen}
                onClose={onClose}
                sx={{ display: { xs: 'flex', md: 'none' } }}
                variant="persistent"
            >
                <Layer
                    component="nav"
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        backgroundColor,
                    }}
                    anchor
                >
                    {children}

                    <Layer
                        relAnchorX="right"
                        relAnchorY="top"
                        x="right"
                        y="top"
                        sx={{
                            color: 'background.contrast.contrastText',
                            zIndex: 1,
                            ml: -2,
                            mt: 2,
                        }}
                    >
                        <IconButton onClick={onClose} color="inherit">
                            <Icon name="close" />
                        </IconButton>
                    </Layer>
                </Layer>
            </Drawer>
        </>
    );
}
