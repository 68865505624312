import { ReactElement } from 'react';

import { useI18n } from 'i18n';

import Icon from '../../../primitives/Icon';
import Metric from './Metric';

interface Props {
    /**
     * The MPUs charging status
     */
    value?: string;
}

function ChargingIcon({ chargingStatus }: { chargingStatus: string }): ReactElement | null {
    if (chargingStatus === 'unknown') return null;
    if (chargingStatus === 'charging') return <Icon name="charging" />;
    if (chargingStatus === 'initializing') return <Icon name="charging-initializing" />;

    return <Icon name="charging-off" />;
}

/**
 * Represents an MPUs chargingStatus based on emitted metrics
 */
export default function ChargingStatus({ value }: Props): ReactElement {
    const { t } = useI18n();
    const _chargingStatus = value ?? 'unknown';

    const _value = _chargingStatus === 'unknown' ? t('unknown') : t(`charge_state.${_chargingStatus}`);

    return (
        <Metric
            variant="card"
            label={t('unit_status.charge_input_label')}
            icon={<ChargingIcon chargingStatus={_chargingStatus} />}
            value={_value}
        />
    );
}
