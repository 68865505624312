import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';

import { useScheduleDaysFromOffset } from 'app/components/compounds/Schedule/hooks';
import Layer from 'app/components/primitives/layout/Layer';
import LoadingOverlay from 'app/components/primitives/LoadingOverlay';
import { useToastErrorHandler } from 'app/core/error';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import { useAddDowntimePeriodDialog } from '../DowntimePeriodDialog';
import { useMpuScheduleInstance } from './hooks';
import { MpuSchedulePresenter } from './MpuSchedulePresenter';
import { DeviceInstanceWithSchedules, MpuScheduleEvent, MpuScheduleFilter } from './types';
import { useScheduleNavigationHandler } from './useScheduleNavigationHandler';

interface MpuScheduleContainerProps {
    /**
     * Additional schedule events to be displayed in the schedule.
     */
    additionalEvents?: MpuScheduleEvent[];
    /**
     * The ID of a device instance.
     */
    deviceInstanceID: string;
    /**
     * Determines the starting point of the initially displayed time frame.
     * Determines the initial time frame in combination with the `daysDisplayed` option.
     *
     * @defaultValue Defaults to the start of the current day in local time.
     */
    initialDate?: ISO8601;
    /**
     * Determines whether the MPU schedules can be modified.
     */
    readOnly?: boolean;
    /**
     * Filters `DeviceInstanceSchedule`s to be displayed in the schedule.
     */
    scheduleFilter?: MpuScheduleFilter;
}

const fallbackDeviceInstance: DeviceInstanceWithSchedules = {
    id: '',
    name: '',
    assetID: '',
    modelID: '',
    schedules: [],
};

/**
 * A widget that wraps the `Schedule` component to display a schedule for a collection of MPUs.
 */
export function MpuScheduleContainer({
    additionalEvents,
    deviceInstanceID,
    initialDate: initialDateInput,
    readOnly,
    scheduleFilter,
}: MpuScheduleContainerProps): ReactElement | null {
    const { t } = useI18n();
    const initialDate = useMemo(() => {
        return initialDateInput || (DateTime.now().startOf('day').minus({ days: 2 }).toISO() ?? '');
    }, [initialDateInput]);
    const daysDisplayed = 14;
    const { end, futureLength, historyLength, start } = useScheduleDaysFromOffset({
        daysDisplayed,
        initialDate,
        offset: 365,
    });
    const handleError = useToastErrorHandler();
    const { data, error, loading, refetch } = useMpuScheduleInstance({
        end,
        deviceInstanceID: deviceInstanceID,
        start,
    });
    const deviceInstance = data ?? fallbackDeviceInstance;
    const mpuID = deviceInstance.id;

    useEffect(() => {
        if (error) handleError(error, t('mpu_schedule.error.schedule_load_failed'));
    }, [error, handleError, t]);

    const handleSubmit = useCallback(() => refetch(), [refetch]);

    const addDowntimeDialog = useAddDowntimePeriodDialog({
        onScheduleChange: handleSubmit,
    });

    const handleAddDowntime = useCallback(() => {
        addDowntimeDialog.open({ mpuID });
    }, [mpuID, addDowntimeDialog]);

    const handleScheduleNavigation = useScheduleNavigationHandler({
        onScheduleChange: handleSubmit,
        readOnly,
    });

    return (
        <Layer anchor>
            <MpuSchedulePresenter
                additionalEvents={additionalEvents}
                daysDisplayed={daysDisplayed}
                deviceInstance={deviceInstance}
                futureLength={futureLength}
                historyLength={historyLength}
                initialDate={initialDate}
                loading={loading}
                onAddDowntime={handleAddDowntime}
                onScheduleNavigation={handleScheduleNavigation}
                readOnly={readOnly}
                scheduleFilter={scheduleFilter}
            />
            <LoadingOverlay loading={loading} />
        </Layer>
    );
}
