import { DateTime } from 'luxon';

/**
 * The MPUs approximate build date is encoded into the serial number. This method extracts the build date
 * given a valid serial number.
 */
export default function parseBuildDateFromSerialNumber(serialNumber: string): DateTime {
    const year = parseInt('20' + serialNumber.substring(7, 9), 10);
    const dayOfYear = parseInt(serialNumber.substring(9, 12), 10);

    if (Number.isNaN(year) || Number.isNaN(dayOfYear)) return DateTime.fromISO('');

    return DateTime.fromObject({
        year,
        ordinal: dayOfYear,
    });
}
