import { ReactElement, useState } from 'react';
import { Duration } from 'luxon';
import { useNavigate, useParams } from 'react-router-dom';

import {
    ActiveDeviceFaults,
    Button,
    Cluster,
    ConfirmDialog,
    HistoricalFaults,
    HistoricalSummaryAccordion,
    IntervalKey,
    IntervalSelector,
    MetricChartDownloader,
    UnitPageLayout,
    useDiscreteInterval,
} from 'app/components';
import { useGetUnitDetails, useUpdateMPUDetails } from 'app/core/data';
import { useToastErrorHandler } from 'app/core/error';
import { useLayer } from 'app/core/layers';
import { useScrollToPageSection } from 'app/core/Navigation';
import { MPUDetailSectionID } from 'app/core/Navigation/constants';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useSession } from 'app/core/Session';
import { SessionData } from 'app/core/types';

import { useDeleteDeviceInstanceMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

import EditMPUDialog from './EditMPUDetailsDialog';
import MPUBuildDetailsSection from './MPUBuildDetailsSection';
import PageLayout from './PageLayout';

export const sectionIDs = Object.values(MPUDetailSectionID);

type Params = {
    mpuID: string;
};

/**
 * This function resolves the capabilities / features to expose the any given user
 * based on their user roles.
 */
function resolveUsersCapabilities({ userRoles }: Pick<SessionData, 'userRoles'>): {
    /**
     * Whether the user is allowed to delete an MPU
     */
    enableMPUDelete: boolean;

    /**
     * Whether the user is allowed to edit the details of an MPU
     */
    enableMPUEdit: boolean;

    /**
     * Whether the user is part of the MPU rental operations (i.e. Energy Services)
     */
    enableRentalManagement: boolean;

    /**
     * Whether the user is shown a link to Moxion's Grafana dashboard for this MPU
     */
    enableViewGrafana: boolean;
} {
    const isSuperAdmin = !!userRoles.find(x => ['MoxionAdmin'].includes(x));
    const isEnergyServicesOperator = !!userRoles.find(x =>
        ['MoxionAdmin', 'MoxionFleetManager', 'MoxionFieldOperator'].includes(x),
    );
    const isFleetManager = !!userRoles.find(x => ['MoxionFleetManager'].includes(x));

    return {
        enableMPUDelete: isSuperAdmin,
        enableMPUEdit: isSuperAdmin,
        enableRentalManagement: isEnergyServicesOperator,
        enableViewGrafana: isFleetManager || isSuperAdmin,
    };
}

export default function MPUDetailPage(): ReactElement {
    const { t } = useI18n();
    const { toast } = useLayer();
    const navigate = useNavigate();

    const { primaryAccountID, userRoles } = useSession().getSession();
    const { mpuID: deviceInstanceID = '' } = useParams<Params>();
    const { enableMPUDelete, enableMPUEdit, enableRentalManagement, enableViewGrafana } = resolveUsersCapabilities({
        userRoles,
    });

    const [isEditMPUDialogOpen, setIsEditMPUDialogOpen] = useState(false);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
    const [activeFaultCount, setActiveFaultCount] = useState<number>(0);

    const {
        loading: isDeviceInstanceLoading,
        error: deviceInstanceError,
        unit,
        refetch,
    } = useGetUnitDetails({
        id: deviceInstanceID,
    });

    const [intervalKey, setIntervalKey] = useState<IntervalKey>('24h');
    const { type, interval } = useDiscreteInterval({
        mpuID: deviceInstanceID,
        intervalKey: intervalKey,
        enablePolling: true,
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });

    const [historicalFaultsIntervalKey, setHistoricalFaultsIntervalKey] = useState<IntervalKey>('24h');
    const { type: historicalFaultsIntervalType, interval: historicalFaultsInterval } = useDiscreteInterval({
        mpuID: deviceInstanceID,
        intervalKey: historicalFaultsIntervalKey,
        enablePolling: true,
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });

    useScrollToPageSection({
        isLoading: isDeviceInstanceLoading,
        sectionIDs,
    });

    const [deleteDeviceInstanceMutation] = useDeleteDeviceInstanceMutation();
    const updateMPUDetails = useUpdateMPUDetails();
    const handleError = useToastErrorHandler();

    const handleConfirmDeleteClick = async () => {
        try {
            await deleteDeviceInstanceMutation({
                variables: { deviceInstanceID },
                update(cache, { data }) {
                    // remove the deleted device instance from the cache
                    const id = cache.identify(data?.deleteDeviceInstance ?? {});
                    cache.evict({ id });
                    cache.gc();
                },
            });
        } catch (error) {
            toast.add({ severity: 'error', message: t('admin_unit_page.feedback.delete_unit_failed') });

            throw error;
        }

        toast.add({
            severity: 'success',
            message: t('admin_unit_page.feedback.delete_unit_succeeded', { name: unit.name }),
        });

        navigate(getPathByName('MPUS_INDEX'));

        setIsDeleteConfirmationDialogOpen(false);
    };

    return (
        <>
            <PageLayout
                enableMPUDelete={enableMPUDelete}
                enableMPUEdit={enableMPUEdit}
                enableRentalManagement={enableRentalManagement}
                accountID={primaryAccountID}
                deviceInstance={unit}
                isLoading={isDeviceInstanceLoading}
                isNotFound={!!deviceInstanceError}
                buildDetails={enableRentalManagement ? <MPUBuildDetailsSection mpuID={deviceInstanceID} /> : null}
                activeFaultCount={activeFaultCount}
                activeFaults={
                    <ActiveDeviceFaults
                        mpuID={unit?.id}
                        onFaultCountChange={({ value }) => setActiveFaultCount(value)}
                    />
                }
                historicalFaults={
                    <UnitPageLayout.Section
                        id={MPUDetailSectionID.HistoricalFaults}
                        heading={t('unit_page.heading.historical_faults')}
                        cta={
                            <IntervalSelector
                                type={historicalFaultsIntervalType}
                                value={historicalFaultsIntervalKey}
                                onChange={({ value }) => {
                                    setHistoricalFaultsIntervalKey(value);
                                }}
                            />
                        }
                    >
                        <HistoricalFaults
                            mpuID={deviceInstanceID}
                            start={historicalFaultsInterval?.start?.toISO()}
                            end={historicalFaultsInterval?.end?.toISO()}
                        />
                    </UnitPageLayout.Section>
                }
                historicalSummary={
                    <UnitPageLayout.Section
                        id={MPUDetailSectionID.HistoricalSummary}
                        heading={t('unit_page.heading.historical_summary')}
                        cta={
                            <Cluster>
                                {enableViewGrafana && unit.name && (
                                    <Button
                                        to={`https://moxion.grafana.net/d/NkSxU69nz/unit-details?var-DEVICE_NAME=${unit.name}`}
                                        ctaType="tertiary"
                                    >
                                        {t('cta.view_grafana')}
                                    </Button>
                                )}
                                <MetricChartDownloader
                                    label={t('cta.download_all')}
                                    includeCharts={['all']}
                                    mpuID={deviceInstanceID}
                                    start={interval?.start ?? ''}
                                    end={interval?.end ?? ''}
                                />

                                <IntervalSelector
                                    type={type}
                                    value={intervalKey}
                                    onChange={({ value }) => {
                                        setIntervalKey(value);
                                    }}
                                />
                            </Cluster>
                        }
                    >
                        <HistoricalSummaryAccordion
                            mpuID={unit?.id}
                            start={interval?.start ?? ''}
                            end={interval?.end ?? ''}
                        />
                    </UnitPageLayout.Section>
                }
                editMPUDetailsCTA={
                    <Button
                        ctaType="secondary"
                        onClick={() => {
                            setIsEditMPUDialogOpen(true);
                        }}
                    >
                        {t('admin_unit_page.cta.edit')}
                    </Button>
                }
                onDeleteDevice={() => {
                    setIsDeleteConfirmationDialogOpen(true);
                }}
            />

            <ConfirmDialog
                isOpen={isDeleteConfirmationDialogOpen}
                title={t('admin_unit_page.confirm_delete_title')}
                message={t('admin_unit_page.confirm_delete_message')}
                confirmCta={<Button onClick={handleConfirmDeleteClick}>{t('cta.confirm')}</Button>}
                onClose={() => {
                    setIsDeleteConfirmationDialogOpen(false);
                }}
            />

            <EditMPUDialog
                accountID={primaryAccountID}
                unit={unit}
                open={isEditMPUDialogOpen}
                onClose={() => setIsEditMPUDialogOpen(false)}
                onChange={async values => {
                    try {
                        await updateMPUDetails(values);
                    } catch (error) {
                        handleError(error, t('admin_unit_page.feedback.unit_updates_failed'));
                    }

                    // TODO(derek):
                    // After the above mutation runs the cache is updated however the query which returns
                    // the MPU details returns stale data. This appears to be a bug in Apollo Client
                    // and adjusting the fetch policy to "cache-only" appears to "work" but is not exactly
                    // what we want.
                    // https://github.com/apollographql/apollo-client/issues/5963
                    refetch();

                    toast.add({
                        severity: 'success',
                        message: t('admin_unit_page.feedback.unit_updates_saved', { name: unit.name }),
                    });
                }}
            />
        </>
    );
}
