import React from 'react';
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const tableRowStyles: Style = {
    flexDirection: 'row',
    // justifyItems: 'stretch',
    alignItems: 'stretch',
    width: '100%',
};

interface TableRowProps {
    children: React.ReactNode;
    style?: Style;
}

const TableRow: React.FC<TableRowProps> = ({ children, style = {} }) => {
    return <View style={{ ...tableRowStyles, ...style }}>{children}</View>;
};

export default TableRow;
