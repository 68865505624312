import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';

import Text from 'app/components/primitives/Text';
import { PermissiveChildrenProp } from 'app/core/types';

import Layer from '../Layer';

interface Props
    extends Pick<ComponentProps<typeof Text>, 'variant'>,
        Pick<ComponentProps<typeof Layer>, 'x' | 'y' | 'relAnchorX' | 'relAnchorY'> {
    numLines?: number;

    zeroHeight?: boolean;

    children: PermissiveChildrenProp;
}

/**
 * Layout helper useful for a couple of scenarios:
 *
 * 1. You'd like to place a component in another component without affecting it's height
 * 2. You'd like to align your component more accurately to a given line of text
 */
export default function AlignToText({
    variant = 'body',
    zeroHeight,
    numLines = 1,
    x,
    y,
    relAnchorX,
    relAnchorY,
    children,
}: Props): ReactElement {
    return (
        <Layer anchor sx={{ height: 'fit-content' }}>
            {/* Horizontal spacer: ensure the width for the component is preserved */}
            <Box aria-hidden="true" sx={{ visibility: 'hidden', height: 0 }}>
                {children}
            </Box>

            {/* Vertical spacer */}
            {!zeroHeight && <Text sx={{ display: 'flex !important' }} variant={variant} preserveHeight={numLines} />}

            <Layer x={x} y={y} relAnchorX={relAnchorX} relAnchorY={relAnchorY}>
                {children}
            </Layer>
        </Layer>
    );
}
