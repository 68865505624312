import { ComponentProps, FunctionComponent, ReactElement, useMemo } from 'react';

import Box from '@mui/material/Box';

import { InnerWrapperProps } from 'app/components/compounds/MultiGrid';
import {
    makeCellAvailabilityForAllRows,
    Schedule,
    ScheduleInitialDateButtonToolbar,
} from 'app/components/compounds/Schedule';
import { normalizeSxProp, SxPropMixin } from 'app/components/normalizeSxProp';
import { AutoSizer } from 'app/components/primitives/AutoSizer';
import { withProps } from 'app/components/withProps';
import { ISO8601 } from 'app/core/types';

import useTheme from 'styles/theme';

import { MPU_HEADING_HEIGHT, RowIndex } from './constants';
import { MpuAsideCell, MpuHeadingCell, MpuToolbarRight } from './presenters';
import { ScheduleNavigationEvent, useMpuTooltipContentRenderer } from './tooltip-content';
import { deviceInstanceToMpuScheduleEvents, makeMpuScheduleAvailabilityLayer } from './transformers';
import { DeviceInstanceWithSchedules, MpuScheduleEvent, MpuScheduleFilter } from './types';

const MpuScheduleWrapper = withProps(Box, ({ sx, ...otherProps }) => ({
    sx: [{ minHeight: '288px' }, ...normalizeSxProp(sx)],
    ...otherProps,
}));

/**
 * Disable vertical scrolling in the body grid.
 */
const BodyOuter = withProps<InnerWrapperProps>(Box, ({ style, ...otherProps }) => ({
    // `react-window` sets `overflow: auto` using the `style` prop,
    // so we have to use the `style` prop to disable vertical scrolling.
    style: {
        ...style,
        overflow: undefined,
        overflowX: 'auto',
        overflowY: 'hidden',
    } as const,
    ...otherProps,
})) as FunctionComponent<InnerWrapperProps>;

interface MpuSchedulePresenterProps extends SxPropMixin {
    /**
     * Additional schedule events to be displayed in the schedule.
     */
    additionalEvents?: MpuScheduleEvent[];
    /**
     * The number of days visible at a time in the body grid.
     * Defaults to `14`.
     */
    daysDisplayed?: number;
    /**
     * The device instance to render events for.
     */
    deviceInstance: DeviceInstanceWithSchedules;
    /**
     * The number of days available to be viewed forward in the schedule.
     */
    futureLength?: number;
    /**
     * The number of days available to be viewed backwards in the schedule.
     */
    historyLength?: number;
    /**
     * Determines the starting point of the initially displayed time frame.
     * Determines the initial time frame in combination with the `daysDisplayed` option.
     */
    initialDate: ISO8601;
    /**
     * When `true`, the component renders a loading overlay.
     */
    loading?: boolean;
    /**
     * A callback invoked when the user requests for a downtime to be added.
     */
    onAddDowntime?: () => void;
    /**
     * A callback invoked when the user navigates to a schedule event.
     */
    onScheduleNavigation?: (event: ScheduleNavigationEvent) => void;
    /**
     * Determines whether the MPU schedules can be modified.
     */
    readOnly?: boolean;
    /**
     * Filters `DeviceInstanceSchedule`s to be displayed in the schedule.
     */
    scheduleFilter?: MpuScheduleFilter;
}

/**
 * A widget that wraps the `Schedule` component to display a schedule for a given MPU.
 */
export function MpuSchedulePresenter({
    additionalEvents,
    daysDisplayed = 14,
    deviceInstance,
    futureLength = 7,
    historyLength = 7,
    initialDate,
    loading,
    onAddDowntime,
    onScheduleNavigation,
    readOnly,
    scheduleFilter,
    sx,
}: MpuSchedulePresenterProps): ReactElement | null {
    const theme = useTheme();
    const eventsFromMpu = useMemo(() => {
        return deviceInstanceToMpuScheduleEvents(theme, deviceInstance, scheduleFilter);
    }, [deviceInstance, scheduleFilter, theme]);
    const events = useMemo(() => {
        return [...eventsFromMpu, ...(additionalEvents ?? [])];
    }, [additionalEvents, eventsFromMpu]);

    const getCellAvailability = useMemo(() => {
        return makeCellAvailabilityForAllRows({
            availabilityRow: RowIndex.Available,
            makeLayer: layer => makeMpuScheduleAvailabilityLayer(theme, layer),
        });
    }, [theme]);

    const renderTooltipContent = useMpuTooltipContentRenderer({ onScheduleNavigation });

    type ToolbarLeftProps = Partial<ComponentProps<typeof ScheduleInitialDateButtonToolbar>>;
    type ToolbarRightProps = Partial<ComponentProps<typeof MpuToolbarRight>>;

    return (
        <MpuScheduleWrapper sx={sx}>
            <AutoSizer>
                {({ height, width }) => (
                    <Schedule<void, MpuScheduleEvent>
                        daysDisplayed={daysDisplayed}
                        events={events}
                        futureLength={futureLength}
                        getCellAvailability={getCellAvailability}
                        headingHeight={MPU_HEADING_HEIGHT.asValue('px')}
                        height={height}
                        historyLength={historyLength}
                        initialDate={initialDate}
                        itemData={undefined}
                        loading={loading}
                        renderTooltipContent={renderTooltipContent}
                        rowCount={4}
                        slots={{
                            asideCell: MpuAsideCell,
                            bodyOuter: BodyOuter,
                            headingCell: MpuHeadingCell,
                            toolbarLeft: ScheduleInitialDateButtonToolbar,
                            toolbarRight: MpuToolbarRight,
                        }}
                        slotProps={{
                            toolbarLeft: {
                                isToday: true,
                            } satisfies ToolbarLeftProps,
                            toolbarRight: {
                                onAddDowntime,
                                readOnly,
                            } satisfies ToolbarRightProps,
                        }}
                        width={width}
                    />
                )}
            </AutoSizer>
        </MpuScheduleWrapper>
    );
}
