import { ReactElement } from 'react';

import { FAULT_SEVERITIES } from '../config';
import ActiveFaults from './ActiveFaults';
import getCoincidentFaultsBySeverity from './getCoincidentFaultsBySeverity';
import Layout from './Layout';
import TimeMarker from './TimeMarker';

interface Props {
    width?: number;

    x?: number | null;

    timestamp?: number;

    isLocked?: boolean;

    data?: any;

    minFaultDuration?: number;
}

/**
 * Responsible for highlighting all active faults for a given timestamp
 */
export default function TimeScrubber({ minFaultDuration, width, x, timestamp, isLocked, data }: Props): ReactElement {
    const coincidentFaultsBySeverity = getCoincidentFaultsBySeverity({ data, timestamp, minFaultDuration });
    const hasCoincidentFaults = !!Object.keys(coincidentFaultsBySeverity).length;

    return (
        <Layout
            width={width}
            x={x}
            isLocked={isLocked}
            showInfoPanel={hasCoincidentFaults}
            infoPanel={
                <>
                    {FAULT_SEVERITIES.map(severity => {
                        const f = coincidentFaultsBySeverity[severity] ?? [];

                        return f.length > 0 ? (
                            <ActiveFaults key={`active-faults-${severity}`} severity={severity} faults={f} />
                        ) : null;
                    })}
                </>
            }
            timeMarker={<TimeMarker timestamp={timestamp} isLocked={isLocked} />}
        />
    );
}
