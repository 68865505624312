const storage = typeof window !== 'undefined' ? window.localStorage : null;

export const getItem = (key: string): string | boolean => {
    if (!storage) {
        return false;
    }

    try {
        const encodedValue = storage.getItem(key);
        if (encodedValue) {
            const decodedValue = JSON.parse(atob(encodedValue)) ?? '';

            return decodedValue;
        } else {
            return false;
        }
    } catch (error) {
        // console.error('Error decoding value:', error);
        return false;
    }
};

export const setItem = (key: string, value: any): boolean => {
    if (!storage) {
        return false;
    }

    try {
        const encodedValue = btoa(JSON.stringify(value));

        storage.setItem(key, encodedValue);

        return true;
    } catch (error) {
        // console.error('Error encoding value:', error);
        return false;
    }
};

export const removeItem = (key: string): void => {
    if (!storage) {
        return;
    }

    storage.removeItem(key);
};

export const clear = (): void => {
    if (!storage) {
        return;
    }

    storage.clear();
};
