import { useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Alert from '@mui/material/Alert';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useI18n } from 'i18n';

interface ResetPasswordFormValues {
    email: string;
    token: string;
    password: string;
    confirmedPassword: string;
}

interface Props extends Omit<BoxProps, 'onSubmit'> {
    /**
     * Email of the user requesting password reset
     * this is required for succesful password reset but may not be provided for some reason
     */
    email?: string;
    /**
     * token for verifying password reset
     * this is required for succesful password reset but may not be provided for some reason
     */
    token?: string;
    /**
     * An error message to show to the user, pass falsey value to suppress
     */
    errorMessage?: string | null | JSX.Element;
    /**
     * Event handler to signify when a user has completed the form and all form fields are
     * valid.
     */
    onSubmit: (result: { values: Pick<ResetPasswordFormValues, 'password'> }) => Promise<void>;
}

export default function ResetPasswordForm({ email, token, errorMessage, onSubmit, ...props }: Props) {
    const { t } = useI18n();

    const formik = useFormik<ResetPasswordFormValues>({
        enableReinitialize: true,

        initialValues: {
            password: '',
            confirmedPassword: '',
            email: email ?? '',
            token: token ?? '',
        },

        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: object().shape({
            email: string().required(),
            token: string().required(),
            password: string().password().required(t('signin_page.password_required_message')),
            confirmedPassword: string()
                .required(t('reset_password_page.confirm_password_required'))
                .test({
                    name: 'passwords-match',
                    message: t('reset_password_page.passwords_must_match'),
                    test: (confirmedPassword, context): boolean => {
                        return context.parent.password === confirmedPassword;
                    },
                }),
        }),

        async onSubmit({ password }) {
            await onSubmit({ values: { password } });
        },
    });

    // Only validate email and token on mount, formik object changes on every render and will
    // casue the page to lock up
    useEffect(() => {
        formik.validateField('email');
        formik.validateField('token');
    }, []); // eslint-disable-line

    const hasErrors = Object.keys(formik.errors).length > 0;

    return (
        <Box {...props}>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2} mb={4}>
                    <Stack>
                        <FormLabel error={!!formik.errors.password} required htmlFor="password-1" sx={{ mb: 1 }}>
                            {t('signin_page.password_label')}
                        </FormLabel>
                        <TextField
                            fullWidth
                            id="password-1"
                            name="password"
                            value={formik.values.password}
                            size="small"
                            type="password"
                            autoComplete="new-password"
                            error={!!formik.errors.password}
                            helperText={t('signup_page.password_guidelines')}
                            onChange={event => {
                                if (formik.touched.password && formik.errors.password) {
                                    formik.validateField('password');
                                }

                                formik.handleChange(event);
                            }}
                            onFocus={() => {
                                formik.setFieldTouched('password');
                            }}
                            onBlur={() => {
                                formik.validateField('password');
                            }}
                        />
                    </Stack>

                    <Stack>
                        <FormLabel
                            error={!!formik.errors.confirmedPassword}
                            required
                            htmlFor="password-2"
                            sx={{ mb: 1 }}
                        >
                            {t('reset_password_page.confirm_password_label')}
                        </FormLabel>
                        <TextField
                            fullWidth
                            id="password-2"
                            name="confirmedPassword"
                            value={formik.values.confirmedPassword}
                            size="small"
                            type="password"
                            autoComplete="new-password"
                            error={!!formik.errors.confirmedPassword}
                            helperText={formik.errors.confirmedPassword ?? ' '}
                            onChange={event => {
                                if (formik.touched.confirmedPassword && formik.errors.confirmedPassword) {
                                    formik.validateField('confirmedPassword');
                                }

                                formik.handleChange(event);
                            }}
                            onFocus={() => {
                                formik.setFieldTouched('confirmedPassword');
                            }}
                            onBlur={() => {
                                formik.validateField('confirmedPassword');
                            }}
                        />
                    </Stack>
                </Stack>

                {errorMessage && (
                    <Alert sx={{ mb: 4 }} severity="error">
                        {errorMessage}
                    </Alert>
                )}

                <Button type="submit" variant="contained" fullWidth sx={{ mb: 7 }} disabled={hasErrors}>
                    {t('cta.reset_password')}
                </Button>
            </form>
        </Box>
    );
}
