import { ReactElement } from 'react';

import { Stack } from '@mui/material';

import { Cluster, Icon, Tag, Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { MUIMarginProps } from 'styles/theme/types';

import { FilterField } from './types';

interface Props extends MUIMarginProps {
    /**
     * The number of applied filter criteria
     */
    count: number;

    /**
     * The individual filter criteria
     */
    tags: FilterField[];

    /**
     * Event handler to be called when a filter criteria is removed
     */
    onRemoveTag: (event: { id: string; label: string }) => void;

    /**
     * Event handler to be called when all filter critera is to be removed
     */
    onClearAll: () => void;
}

/**
 * Responsible for providing users feedback about what filters are applied.
 */
export default function FilterResultsHeader({
    count,
    onRemoveTag,
    tags,
    onClearAll,
    ...marginProps
}: Props): ReactElement {
    const { t } = useI18n();

    if (!tags?.length) {
        return <></>;
    }

    return (
        <Stack {...marginProps} spacing={2}>
            <Text variant="body" color="secondary">
                {t('filters.results_count', { count })}
            </Text>

            <Cluster gap={2}>
                {tags.map(({ label = '', id }) => (
                    <Tag
                        label={label}
                        key={id}
                        outlined
                        onClick={() => onRemoveTag({ id, label })}
                        endIcon={<Icon name="close" />}
                    />
                ))}

                <Tag
                    label={t('filters.clear_all')}
                    key="clear-all"
                    outlined
                    onClick={() => onClearAll()}
                    color="normal"
                    endIcon={<Icon name="close" />}
                />
            </Cluster>
        </Stack>
    );
}
