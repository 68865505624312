import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { AccountLocationSelector, Button, Input, NetsuiteCustomerSelector, PhoneField } from 'app/components';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import { FormStateChange } from '../../types';
import MoxionRepSelector from './MoxionRepSelector';

const SPACING = 5;

interface UpdateAccountInput {
    id: string;
    name?: string;
    industry?: string;
    netsuiteCustomerIDs?: string[];
    moxionRepID?: string;
    pointOfContact?: string;
    contactPhone?: string;
    billingAddressLocationID?: string;
    moxionInternalNotes?: string;
}

export interface EditAccountFormProps extends Omit<UpdateAccountInput, 'id'> {
    /**
     * The unique ID for the account
     */
    accountID: string;

    /**
     * The unique ID for the form
     */
    formID: string;

    /**
     * Whether the form is readOnly or not
     */
    readOnly?: boolean;

    /**
     * Date on which the account was created
     */
    creationDate?: ISO8601;

    /**
     * Whether to hide the submit button if being provided elsewhere outside the form
     */
    hideSubmitCTA?: boolean;

    /**
     * Whether to reset the form when going from edit mode to readOnly
     */
    enableResetOnModeChange?: boolean;

    /**
     * Which fields to exclude from the form
     */
    excludeFields?: String[];

    /**
     * Event handler called when the form state changes
     */
    onFormStateChange?: (event: FormStateChange<UpdateAccountInput>) => void;

    /**
     * Event handler called when the user initiates the form submission
     */
    onSubmit: (input: { value: UpdateAccountInput }) => Promise<void>;
}

export default function EditAccountForm({
    formID,
    accountID,
    name: company,
    industry,
    netsuiteCustomerIDs,
    moxionRepID,
    pointOfContact,
    contactPhone,
    billingAddressLocationID,
    moxionInternalNotes,
    creationDate,

    excludeFields = [],
    readOnly = false,
    hideSubmitCTA = false,
    enableResetOnModeChange = true,

    onFormStateChange,
    onSubmit,
}: EditAccountFormProps) {
    const { t, format } = useI18n();

    const form = useFormik<UpdateAccountInput>({
        initialValues: {
            id: accountID,
            name: company,
            industry,
            netsuiteCustomerIDs,
            moxionRepID,
            pointOfContact,
            contactPhone: contactPhone,
            billingAddressLocationID: billingAddressLocationID,
            moxionInternalNotes,
        },
        enableReinitialize: true,

        validateOnMount: false,
        validateOnChange: true,
        validateOnBlur: false,
        // TODO: find a way to restrict the schema keys to UserInput
        validationSchema: object().shape({
            company: string().required(t('admin_account_page.details.validation.company_is_required')),
            industry: string(),
            netsuiteCustomerIDs: array(string()),
            moxionRepID: string(),
            pointOfContact: string(),
            contactPhone: string(),
            billingAddressLocationID: string().required(
                t('admin_account_page.details.validation.billing_address_is_required'),
            ),
            moxionInternalNotes: string(),
        }),
        onSubmit: async values => {
            await onSubmit({ value: values });
        },
    });

    const { isValid, dirty, values, errors } = form;
    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({
            shouldDisableCTA,
            isDirty: dirty,
            isValid,
            values,
        });
    }, [shouldDisableCTA, dirty, onFormStateChange, isValid, values]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resetForm = useMemo(() => form.resetForm.bind(form), []);

    useEffect(() => {
        if (enableResetOnModeChange && readOnly) {
            resetForm();
        }
    }, [readOnly, resetForm, enableResetOnModeChange]);

    return (
        <Grid container rowSpacing={2} columnSpacing={6}>
            <Grid item md={6} xs={12}>
                <Stack component="form" id={formID} onSubmit={form.handleSubmit} spacing={SPACING}>
                    <Input
                        name="company"
                        fieldName={t('admin_account_page.details.label.company')}
                        value={values.name}
                        fullWidth
                        readOnly={readOnly}
                        required
                        error={!!errors.name}
                        caption={errors.name}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />

                    <Input
                        name="industry"
                        fieldName={t('admin_account_page.details.label.industry')}
                        value={values.industry}
                        fullWidth
                        readOnly={readOnly}
                        error={!!errors.industry}
                        caption={errors.industry}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />

                    <Input
                        fieldName={t('admin_account_page.details.label.id')}
                        value={accountID}
                        fullWidth
                        readOnly={readOnly}
                        disabled={!readOnly}
                    />

                    <Input
                        fieldName={t('admin_account_page.details.label.creation_date')}
                        value={format.date(creationDate)}
                        fullWidth
                        readOnly={readOnly}
                        disabled={!readOnly}
                    />

                    <NetsuiteCustomerSelector
                        name="netsuiteCustomerIDs"
                        fieldName={t('admin_account_page.details.label.netsuite_customer')}
                        value={values.netsuiteCustomerIDs}
                        fullWidth
                        readOnly={readOnly}
                        error={!!errors.netsuiteCustomerIDs}
                        caption={errors.netsuiteCustomerIDs}
                        onBlur={form.handleBlur}
                        onChange={({ name, selectedOptions }) => {
                            if (name) {
                                form.setFieldValue(
                                    name,
                                    selectedOptions.map(option => option.id),
                                );
                            }
                        }}
                        disabledOptions={netsuiteCustomerIDs}
                    />
                </Stack>
            </Grid>

            <Grid item md={6} xs={12}>
                <Stack spacing={SPACING}>
                    <MoxionRepSelector
                        name="moxionRepID"
                        fieldName={t('admin_account_page.details.label.representative')}
                        value={values.moxionRepID}
                        fullWidth
                        readOnly={readOnly}
                        error={!!errors.moxionRepID}
                        caption={errors.moxionRepID}
                        onBlur={form.handleBlur}
                        onChange={({ value }) => {
                            form.setFieldValue('moxionRepID', value?.id);
                        }}
                    />

                    <Input
                        name="pointOfContact"
                        fieldName={t('admin_account_page.details.label.administrator')}
                        value={values.pointOfContact}
                        fullWidth
                        readOnly={readOnly}
                        error={!!errors.pointOfContact}
                        caption={errors.pointOfContact}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />

                    <PhoneField
                        name="contactPhone"
                        fieldName={t('admin_account_page.details.label.phone_number')}
                        value={values.contactPhone}
                        fullWidth
                        readOnly={readOnly}
                        error={!!errors.contactPhone}
                        caption={errors.contactPhone}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />

                    <AccountLocationSelector
                        accountID={accountID}
                        name="billingAddressLocationID"
                        fieldName={t('admin_account_page.details.label.billing_address')}
                        value={values.billingAddressLocationID}
                        fullWidth
                        readOnly={readOnly}
                        required
                        error={!!errors.billingAddressLocationID}
                        caption={errors.billingAddressLocationID}
                        onBlur={form.handleBlur}
                        onChange={({ value }) => {
                            form.setFieldValue('billingAddressLocationID', value?.id);
                        }}
                    />

                    {!excludeFields.includes('moxionInternalNotes') && readOnly && (
                        <Input
                            name="moxionInternalNotes"
                            fieldName={t('admin_account_page.details.label.internal_notes')}
                            value={values.moxionInternalNotes}
                            fullWidth
                            multiline
                            minRows={1}
                            maxRows={3}
                            readOnly={readOnly}
                            error={!!errors.moxionInternalNotes}
                            caption={errors.moxionInternalNotes}
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                        />
                    )}
                </Stack>
            </Grid>

            {!excludeFields.includes('moxionInternalNotes') && !readOnly && (
                <Grid item xs={12} mt={SPACING}>
                    <Input
                        name="moxionInternalNotes"
                        fieldName={t('admin_account_page.details.label.internal_notes')}
                        value={values.moxionInternalNotes}
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={5}
                        readOnly={readOnly}
                        error={!!errors.moxionInternalNotes}
                        caption={errors.moxionInternalNotes}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />
                </Grid>
            )}

            {!hideSubmitCTA && (
                <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_account_page.cta.save_changes')}
                    </Button>
                </Box>
            )}
        </Grid>
    );
}
