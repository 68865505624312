import { ApolloError } from '@apollo/client';

import { KnownServiceErrorKind } from './constants';

/**
 * An error that wraps a given `ApolloError` that has been recognized
 * as a known service error of a specific kind.
 */
export class KnownServiceError<T extends KnownServiceErrorKind = KnownServiceErrorKind> extends Error {
    public apolloError: ApolloError;
    public kind: T;

    constructor(kind: T, apolloError: ApolloError) {
        super(apolloError.message);

        this.kind = kind;
        this.apolloError = apolloError;
    }
}
