export function getRightSegmentColor({
    day,
    candidate,
    start,
    end,
    selectionTarget,

    color1,
    color2,
}) {
    const isRangeReversed = start > end;
    const _start = isRangeReversed ? end : start;
    const _end = isRangeReversed ? start : end;

    if (isRangeReversed && _start?.startOf('day') <= day && _end?.startOf('day') > day) {
        return null;
    }

    if (selectionTarget === 'start') {
        if (_start?.startOf('day') > day && candidate?.startOf('day') <= day) {
            return color2;
        }

        if (_start?.startOf('day') <= day && candidate?.startOf('day') > day && candidate <= _end) {
            return color2;
        }
    }

    if (selectionTarget === 'end' || selectionTarget === 'auto') {
        if (_start?.startOf('day') <= day && _end?.startOf('day') > day && candidate >= _end) {
            return color1;
        }

        if (_start?.startOf('day') <= day && candidate?.startOf('day') > day) {
            return color2;
        }
    }

    if (!isRangeReversed && _start?.startOf('day') <= day && _end?.startOf('day') > day) {
        return color1;
    }

    return null;
}

export function getLeftSegmentColor({
    day,
    candidate,
    start,
    end,
    selectionTarget,

    color1,
    color2,
}) {
    const isRangeReversed = start > end;
    const _start = isRangeReversed ? end : start;
    const _end = isRangeReversed ? start : end;

    if (isRangeReversed && _start?.startOf('day') < day && _end?.startOf('day') >= day) {
        return null;
    }

    if (selectionTarget === 'start') {
        if (_start?.startOf('day') >= day && candidate?.startOf('day') < day) {
            return color2;
        }

        if (_start?.startOf('day') < day && candidate?.startOf('day') >= day && candidate <= _end) {
            return color2;
        }
    }

    if (selectionTarget === 'end' || selectionTarget === 'auto') {
        if (_start?.startOf('day') < day && _end?.startOf('day') >= day && candidate >= _end) {
            return color1;
        }

        if (_start?.startOf('day') < day && candidate?.startOf('day') >= day) {
            return color2;
        }
    }

    if (!isRangeReversed && _start?.startOf('day') < day && _end?.startOf('day') >= day) {
        return color1;
    }

    return null;
}

export default function getRangeColors(args) {
    return [getLeftSegmentColor(args), getRightSegmentColor(args)];
}
