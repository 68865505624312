import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

import Box from '@mui/material/Box';

import { MUIMarginProps } from 'styles/theme/types';

const MotionBox = motion(Box);

interface Props extends MUIMarginProps {
    content?: ReactNode;
    children?: ReactNode;
}

/**
 * Responsible for gracefully growing and shrinking content container affecting
 * surrounding component layouts to prevent "pop-ins" or other jerky transitions.
 */
export default function TransitionContentHeight({ content, children, ...marginProps }: Props): ReactElement {
    const ref = useRef<HTMLElement>(null);
    const [transitionDuration, setTransitionDuration] = useState<number>(0.25);
    const [height, setHeight] = useState<number | 'auto'>('auto');

    useEffect(() => {
        const handleResize = () => {
            const h = ref.current?.getBoundingClientRect().height ?? 0;

            if (height !== 'auto') {
                // This allows larger content changes to take a longer duration
                // but not linearly such that the transition goes on too long
                const transitionDuration = Math.log(Math.abs(height - h)) / 10;

                setTransitionDuration(transitionDuration);
            }
            setHeight(h);
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        handleResize();

        return () => {
            resizeObserver.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MotionBox
            {...marginProps}
            animate={{ height }}
            component="span"
            layout="size"
            sx={{ display: 'block', overflow: 'hidden' }}
            transition={{ duration: transitionDuration }}
        >
            <Box component="span" ref={ref} sx={{ display: 'block' }}>
                {content || children}
            </Box>
        </MotionBox>
    );
}
