import { ComponentProps, ReactElement } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import { useI18n } from 'i18n';

import useServiceAreas from './useServiceAreas';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {
    accountID?: string;
}

/**
 * Provides ability to select multiple service areas
 */
export default function MultiServiceAreaSelector({ accountID, value, onChange, ...props }: Props): ReactElement {
    const { t } = useI18n();

    const { loading, serviceAreas } = useServiceAreas({ accountID, t });

    return (
        <MultiSelect
            {...props}
            disabled={!props?.readOnly && (!!props?.disabled || !accountID || loading)}
            value={value}
            options={serviceAreas.map(x => ({ id: x?.id, label: x?.name }))}
            onChange={onChange}
        />
    );
}
