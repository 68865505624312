import { MapRef } from 'react-map-gl';

/**
 * Mapbox-gl doesn't support hover states like the DOM, but it can be implemented with "featureState" on a data source.
 * This method tracks the hover state on a feature and will add / remove the featureState "hover" depending on the mouse position
 * @link {mapbox-gl docs example | https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/}
 */
export default function enableHoverOnMarkers(layerId: string, map: MapRef): () => void {
    let hoveredUnitID: string | number | null = null;

    function onMouseEnter(event) {
        if (!map) return;

        map.getCanvas().style.cursor = 'pointer';

        if (event?.features?.length === 0) return;

        // Reset hover state before activating it on the entered feature
        if (hoveredUnitID) {
            map.removeFeatureState({
                source: 'units',
                id: hoveredUnitID,
            });
        }

        hoveredUnitID = event?.features?.[0]?.id ?? null;

        // Activate hover state
        map.setFeatureState(
            {
                source: 'units',
                id: hoveredUnitID ?? undefined,
            },
            {
                hover: true,
            },
        );
    }

    function onMouseLeave(event) {
        if (!map) return;

        if (hoveredUnitID) {
            map.setFeatureState(
                {
                    source: 'units',
                    id: hoveredUnitID,
                },
                {
                    hover: false,
                },
            );
        }

        hoveredUnitID = null;

        map.getCanvas().style.cursor = '';
    }

    map.on('mouseenter', layerId, onMouseEnter);
    map.on('mouseleave', layerId, onMouseLeave);

    return () => {
        map.off('mouseenter', layerId, onMouseEnter);
        map.off('mouseleave', layerId, onMouseLeave);
    };
}
