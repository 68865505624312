import { useId } from 'react';

import { Button, ModalDialog } from 'app/components';

import { AccountLocation, ReservationItemUpdateInput } from 'generated/graphql';

import { useI18n } from 'i18n';

import EditAddressesForm from './EditAddressesForm';

interface EditAddressesModalLocation extends Pick<AccountLocation, 'id' | 'contactName' | 'formattedAddress'> {}

interface EditAddressesModalProps {
    /**
     * Unique identifier for the dialog for associating the
     * controlling button or other interactive UI
     */
    id: string;
    isOpen: boolean;
    onClose: () => void;
    onSave: (values: ReservationItemUpdateInput) => void;

    unitName: string;
    deliveryLocation?: EditAddressesModalLocation;
    pickupLocation?: EditAddressesModalLocation;
    reservationItemID: string;
    accountID: string;
}

export default function EditAddressesModal({
    id,
    isOpen,
    onClose,
    onSave,
    unitName,
    deliveryLocation,
    pickupLocation,
    reservationItemID,
    accountID,
}: EditAddressesModalProps) {
    const { t } = useI18n();
    const formID = useId();

    return (
        <ModalDialog
            id={id}
            title={t('admin_reservation_page.addresses_modal.title', { unitName })}
            open={isOpen}
            onClose={onClose}
            primaryCTA={
                <Button ctaType="primary" type="submit" form={formID}>
                    {t('cta.save')}
                </Button>
            }
        >
            <EditAddressesForm
                formID={formID}
                deliveryLocationID={deliveryLocation?.id}
                pickupLocationID={pickupLocation?.id}
                onSubmit={values => {
                    onSave(values);
                    onClose();
                }}
                reservationItemID={reservationItemID}
                accountID={accountID}
            />
        </ModalDialog>
    );
}
