import { CustomerReservationsPage } from './presentation';
import { DialogProvider } from './useDialogContext';

export function CustomerReservationsPageContainer() {
    return (
        <DialogProvider>
            <CustomerReservationsPage />
        </DialogProvider>
    );
}
