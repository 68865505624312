import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import { GridColDef, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';

import {
    DataGrid,
    DataGridCellContent,
    DataGridFillCell,
    Icon,
    Layer,
    Link,
    LoadingIndicator,
    ZeroState,
} from 'app/components';

import { useI18n } from 'i18n';

import { Fault } from '../types';

interface Props {
    loading?: boolean;

    faults: Fault[];
    excludeColumns?: string[];

    // Pagination
    page: number;
    rowCount: number;
    pageSize: number;
    pageSizeOptions: number[];
    onPageSizeChange: (newPageSize: number) => void;
    onPageChange: (newPage: number) => void;

    // Sort
    sortModel?: GridSortModel;
    onSortModelChange?: (sortModel: GridSortModel) => void;
}

function LoadingOverlay() {
    return (
        <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
            <LoadingIndicator />
        </Layer>
    );
}
function NoRowsOverlay() {
    const { t } = useI18n();
    const _t = (key: string, params = {}) => t(`faults_page.${key}`, params);

    return (
        <Box m={6}>
            <ZeroState title={_t('table.no_rows_title')} message={_t('table.no_rows_message')} />
        </Box>
    );
}

export default function MPUFaultsTable({
    loading,
    faults,
    excludeColumns = [],

    page,
    pageSize = 20,
    pageSizeOptions,
    rowCount,

    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
}: Props): ReactElement {
    const { t, format } = useI18n();
    const _t = (key: string, params = {}) => t(`faults_page.${key}`, params);

    const rows: GridRowsProp<Fault> = faults.slice();
    const allColumns: GridColDef<Fault>[] = [
        {
            field: 'severity',
            headerName: '',
            sortable: false,
            hideable: false,
            filterable: false,
            width: 50,
            renderCell(params: GridRenderCellParams<Fault, string>) {
                const fault = params.row;

                return (
                    <DataGridFillCell
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor:
                                fault.severity === 'CRITICAL' ? 'background.dangerDark.main' : 'transparent',
                            color: fault.severity === 'CRITICAL' ? 'background.dangerDark.contrastText' : null,
                        }}
                    >
                        <Icon name="alert-circle" />
                    </DataGridFillCell>
                );
            },
        },
        {
            field: 'name',
            headerName: _t('table.header.fault'),
            hideable: false,
            filterable: false,
            flex: 4,
            valueGetter(params: GridRenderCellParams<Fault, boolean>) {
                return t(`device_fault.${params.row.name.toLowerCase()}.name`);
            },
        },
        {
            field: 'mpu',
            headerName: _t('table.header.mpu'),
            flex: 2,
            hideable: false,
            sortable: false,
            filterable: false,
            renderCell(params: GridRenderCellParams<Fault, number>) {
                const fault = params.row;

                return (
                    <DataGridCellContent
                        line1={<Link to={fault.mpuDetailHref}>{fault.mpuName}</Link>}
                        line2={fault.mpuModelName}
                    />
                );
            },
        },
        {
            field: 'firstSeen',
            headerName: _t('table.header.first_seen'),
            flex: 3,
            hideable: false,
            filterable: false,
            renderCell(params: GridRenderCellParams<Fault, number>) {
                const fault = params.row;

                return (
                    <DataGridCellContent line1={format.date(fault.firstSeen)} line2={format.time(fault.firstSeen)} />
                );
            },
        },
        {
            field: 'lastUpdate',
            headerName: _t('table.header.last_update'),
            flex: 3,
            hideable: false,
            filterable: false,
            renderCell(params: GridRenderCellParams<Fault, number>) {
                const fault = params.row;

                return (
                    <DataGridCellContent line1={format.date(fault.lastUpdate)} line2={format.time(fault.lastUpdate)} />
                );
            },
        },
        {
            field: 'ownerName',
            headerName: _t('table.header.owner'),
            flex: 3,
            filterable: false,
            sortable: false,
            valueGetter(params: GridRenderCellParams<Fault, boolean>) {
                return params.row.ownerName;
            },
        },
        {
            field: 'serviceAreaName',
            headerName: _t('table.header.service_area'),
            flex: 3,
            filterable: false,
            sortable: false,
            valueGetter(params: GridRenderCellParams<Fault, boolean>) {
                return params.row.serviceAreaName ?? t('service_area_unassigned');
            },
        },
    ];

    const columns = allColumns.filter(col => !excludeColumns.includes(col.field));

    // render the grid
    return (
        <DataGrid
            // rendering
            loading={loading}
            density="comfortable"
            autoHeight
            // pagination
            pagination
            paginationMode="server"
            onPaginationModelChange={model => {
                if (model.page !== page) {
                    onPageChange(model.page);
                }
                if (model.pageSize !== pageSize) {
                    onPageSizeChange(model.pageSize);
                }
            }}
            paginationModel={{ page, pageSize }}
            pageSizeOptions={pageSizeOptions}
            rowCount={rowCount}
            // data
            sortingMode="server"
            filterMode="server"
            rows={rows}
            columns={columns}
            sx={{ minWidth: 1150, minHeight: '80vh' }}
            disableRowSelectionOnClick={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            components={{
                LoadingOverlay,
                NoRowsOverlay,
            }}
        />
    );
}
