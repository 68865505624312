import { Button } from 'app/components';
import { useLayer } from 'app/core/layers';

import { useI18n } from 'i18n';

import AlertSeverityLevelLegend from './AlertSeverityLevelLegend';

export default function useSeverityLevelLegend() {
    const { t } = useI18n();
    const { dialog } = useLayer();

    return () => {
        return dialog.add(({ close }) => ({
            title: t('alerts_page.severity_levels_title'),
            children: <AlertSeverityLevelLegend />,
            tertiaryCTA: null,
            primaryCTA: <Button onClick={close}>{t('cta.ok')}</Button>,
        }));
    };
}
