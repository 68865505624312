import { createGlobalStyle } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  html {
    overflow-x: hidden;
  }

  #root {
      height: 100%;
  }

  .mox-alert {
    //FIXME set the correct color for light background
    color: #FF4D4D;
  }

  // Google Autocomplete options list
  .pac-container {
      z-index: 1501;
    }
`;
