import { useState } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';

import getPathByName from 'app/core/Navigation/getPathByName';

import { getSupportEmail } from 'environment';

import { useLogInUserMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

function useSignInMutation() {
    const client = useApolloClient();
    const [signIn] = useLogInUserMutation();

    return async ({ email, password }) => {
        await client.cache.reset();

        const result = await signIn({
            variables: {
                loginInput: {
                    email,
                    password: btoa(password),
                },
            },
        });

        return result.data?.loginUser;
    };
}

export default function useSignIn() {
    const { t } = useI18n();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const signIn = useSignInMutation();
    const [errorMessage, setErrorMessage] = useState<string>('');

    return {
        run: async ({ values: { email, password } }) => {
            const nextRoute = searchParams.get('next');

            try {
                await signIn({ email, password });
            } catch (error: unknown) {
                if (error instanceof ApolloError) {
                    if (error.graphQLErrors.length === 0) {
                        setErrorMessage(
                            t('signin_page.generic_error_message', {
                                supportEmail: getSupportEmail(),
                            }),
                        );
                        return;
                    }

                    for (const err of error.graphQLErrors) {
                        // eslint-disable-next-line no-console
                        console.error(err);

                        if (err.extensions.code === 'UNAUTHENTICATED') {
                            const reason = err.extensions.reason;
                            switch (reason) {
                                case 'UnknownEmail':
                                    setErrorMessage(t('signin_page.signin_failed_unknown_email'));
                                    break;
                                case 'InvalidPassword':
                                    setErrorMessage(t('signin_page.signin_failed_invalid_password'));
                                    break;
                                case 'InvalidTOTP':
                                    setErrorMessage(t('signin_page.signin_failed_invalid_totp'));
                                    break;
                                case 'UserDeactivated':
                                    setErrorMessage(
                                        t('signin_page.signin_failed_user_deactivated', {
                                            supportEmail: getSupportEmail(),
                                        }),
                                    );
                                    break;
                                default:
                                    setErrorMessage(t('signin_page.signin_failed_message'));
                                    break;
                            }
                        } else {
                            setErrorMessage(
                                t('signin_page.generic_error_message', {
                                    supportEmail: getSupportEmail(),
                                }),
                            );
                        }
                    }
                } else {
                    setErrorMessage(t('signin_page.signin_failed_message'));
                    throw error;
                }
            }

            navigate(nextRoute ?? getPathByName('HOME'));
        },
        errorMessage,
    };
}
