import { DateTime } from 'luxon';

import { mergeSeries } from 'app/core/data/deviceTelemetry';
import { createCSVDataDownload, createJSONDataDownload } from 'app/core/download';
import { Series } from 'app/core/types';

interface MakeDownloadableFileInputs {
    data: Series[];
    name: string;
    fileFormat?: 'json' | 'csv';
}

const adaptDataToCSVData = data => {
    const mergedData = mergeSeries(data, { shouldAddUnitToLabel: true });

    const columns = ['timestamp', ...data.map(x => `${x.seriesName} (${x.units})`)];
    const rows = mergedData.map(item => ({
        ...item,
        timestamp: DateTime.fromMillis(item.timestamp).toISO(),
    }));

    return { columns, rows };
};

export default function makeDownloadableFile({ data, name, fileFormat = 'json' }: MakeDownloadableFileInputs) {
    if (fileFormat === 'csv') {
        const mergedData = adaptDataToCSVData(data);

        return createCSVDataDownload(name, mergedData);
    }

    return createJSONDataDownload(name, data);
}
