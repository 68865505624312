import { DateTime } from 'luxon';

import { UNASSIGNED_SERVICE_AREA_OPTION_ID } from 'app/components';

import {
    GetReservationQuery,
    GetReservationQueryResult,
    ReservationStatus,
    ReservationType,
    useGetReservationQuery,
} from 'generated/graphql';

import { getDaysUntilNextUnallocatedDay, isAllocationRequired } from 'utils/reservation';

export interface ReservationDetail {
    id: string;
    title: string;
    name: string | undefined;
    serviceAreaID: string | undefined;
    requestDate: DateTime;
    status: ReservationStatus;
    userNotes: string;
    start: DateTime;
    end: DateTime;
    account: GetReservationQuery['getReservation']['account'] | null;
    userRequested: GetReservationQuery['getReservation']['userRequested'] | null;
    fulfillerNotes: string;
    serviceType: ReservationType;
    unitCount: number;

    items: {
        id: string;
        name: string;
        deviceModel: {
            id: string;
        };
        deliveryLocation: GetReservationQuery['getReservation']['reservationItems'][0]['deliveryLocation'];
        pickupLocation: GetReservationQuery['getReservation']['reservationItems'][0]['pickupLocation'];
        daysUntilNextUnallocatedDay: number | null;
        isAllocationRequired: boolean;
    }[];
}

interface Result extends Pick<GetReservationQueryResult, 'loading' | 'error' | 'refetch'> {
    /**
     * Transformed reservation with all relevent data for display
     */
    reservation: ReservationDetail;
}

const nullReservation = {
    id: '',
    title: '',
    name: undefined,
    serviceArea: undefined,
    status: ReservationStatus.Requested,
    createdAt: '',
    account: { id: '' },
    userRequested: null,
    reservationItems: [],
    additionalNotes: '',
    fulfillerNotes: '',
    type: '',
};

function toReservationType(value?: string): ReservationType {
    const key = Object.values(ReservationType).findIndex(x => x === value);
    const enumValue = ReservationType[Object.keys(ReservationType)[key]];

    return typeof key === 'number' ? enumValue : undefined;
}

/**
 * Reservation items own start and end BUT! they the should all be the same so just to be
 * careful we'll find the full range to represent the start and end for the reservation
 */
function getStartAndEndDatesFromItems(reservationItems) {
    let minStart;
    let minEnd;

    reservationItems.forEach(item => {
        const start = DateTime.fromISO(item.start);
        const end = DateTime.fromISO(item.end);

        if (!minStart || start < minStart) {
            minStart = start;
        }

        if (!minEnd || end < minEnd) {
            minEnd = end;
        }
    });

    return {
        start: minStart,
        end: minEnd,
    };
}

/**
 * Responsible for simplifying / processing data for display
 */
export default function useReservation({ reservationID }): Result {
    const { loading, error, data, refetch } = useGetReservationQuery({
        variables: { reservationId: reservationID },
        // NOTE(derek): This is pretty aggressive and means we can only refetch manually
        // but seems to be the way forward in preventing a refetch when the
        // updateReservation mutation is called (some of the time)
        // https://github.com/apollographql/apollo-client/issues/7938
        nextFetchPolicy: 'cache-only',
    });

    const reservation = data?.getReservation ?? nullReservation;
    let reservationItems = reservation?.reservationItems ?? [];

    // sort reservation items by name, with unnamed first
    reservationItems = reservationItems
        .filter(item => !item.alias)
        .concat(
            reservationItems
                .filter(item => !!item.alias)
                .sort((a, b) => ((a.alias as string) < (b.alias as string) ? -1 : 1)),
        );

    return {
        loading,
        error,
        refetch,

        reservation: {
            id: reservation.id,
            title: `Reservation ${reservation.id.substring(0, 5)} ${
                reservation.name ? `(${reservation.name.trim()})` : ''
            }`,
            name: reservation.name ?? undefined,
            serviceAreaID:
                reservation.serviceArea == null ? UNASSIGNED_SERVICE_AREA_OPTION_ID : reservation.serviceArea?.id,
            requestDate: DateTime.fromISO(reservation.createdAt),
            status: reservation.status,
            userNotes: reservation.additionalNotes ?? '',
            ...getStartAndEndDatesFromItems(reservationItems),
            account: reservation.account,
            userRequested: reservation.userRequested,
            fulfillerNotes: reservation.fulfillerNotes ?? '',
            serviceType: toReservationType(reservation.type),
            unitCount: reservationItems.length,

            // Item metadata
            items: reservationItems.map((item, index) => ({
                id: item.id,
                name: item.alias ?? `Unit ${index + 1}`,
                deviceModel: {
                    id: item.deviceModel.id,
                },
                deliveryLocation: item.deliveryLocation,
                pickupLocation: item.pickupLocation,
                daysUntilNextUnallocatedDay: getDaysUntilNextUnallocatedDay(item),
                isAllocationRequired: isAllocationRequired(item),
            })),
        },
    };
}
