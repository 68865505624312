import CSSDimension from '../CSSDimension';

// Font Family
export const FONT_FAMILY = ['Inter', 'Arial', 'Helvetica', 'Sans-Serif'];

// Font Weights
export const FONT_WEIGHT = {
    REGULAR: 400,
    MEDIUM: 500,
    BOLD: 700,
} as const;

// Type Scale
export const TYPE_SCALE = {
    // Main type scale
    TEXT_1: new CSSDimension({ value: 32, unit: 'px' }),
    TEXT_2: new CSSDimension({ value: 24, unit: 'px' }),
    TEXT_3: new CSSDimension({ value: 20, unit: 'px' }),
    TEXT_4: new CSSDimension({ value: 18, unit: 'px' }),
    TEXT_5: new CSSDimension({ value: 16, unit: 'px' }),
    TEXT_6: new CSSDimension({ value: 14, unit: 'px' }),

    // Separate scale primarily for data dashboard usage
    DATA_1: new CSSDimension({ value: 50, unit: 'px' }),
    DATA_2: new CSSDimension({ value: 30, unit: 'px' }),
} as const;

// Font Styles
const h1 = {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: TYPE_SCALE.TEXT_1.as('rem'),
    lineHeight: 36 / TYPE_SCALE.TEXT_1.asValue('px'),
    letterSpacing: '-0.04em',
};

const h2 = {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: TYPE_SCALE.TEXT_2.as('rem'),
    lineHeight: 28 / TYPE_SCALE.TEXT_2.asValue('px'),
    letterSpacing: '-0.02em',
};

const h3 = {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: TYPE_SCALE.TEXT_3.as('rem'),
    lineHeight: 28 / TYPE_SCALE.TEXT_3.asValue('px'),
    letterSpacing: '-0.015em',
};

const h4 = {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: TYPE_SCALE.TEXT_4.as('rem'),
    lineHeight: 24 / TYPE_SCALE.TEXT_4.asValue('px'),
    letterSpacing: '-0.015em',
};

const h5 = {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: TYPE_SCALE.TEXT_5.as('rem'),
    lineHeight: 20 / TYPE_SCALE.TEXT_5.asValue('px'),
};

const body = {
    fontSize: TYPE_SCALE.TEXT_5.as('rem'),
    lineHeight: 24 / TYPE_SCALE.TEXT_5.asValue('px'),
    letterSpacing: '-0.02em',
    fontWeight: FONT_WEIGHT.REGULAR,
};

const bodyMedium = {
    ...body,
    fontWeight: FONT_WEIGHT.MEDIUM,
};

const detail = {
    fontSize: TYPE_SCALE.TEXT_6.as('rem'),
    lineHeight: 20 / TYPE_SCALE.TEXT_6.asValue('px'),
    letterSpacing: '-0.02em',
    fontWeight: FONT_WEIGHT.REGULAR,
};

const detailMedium = {
    ...detail,
    fontWeight: FONT_WEIGHT.MEDIUM,
};

const data = {
    fontSize: TYPE_SCALE.DATA_1.as('rem'),
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: 60 / TYPE_SCALE.DATA_1.asValue('px'),
    letterSpacing: '-0.055em',
};

const dataSmall = {
    ...data,
    fontSize: TYPE_SCALE.DATA_2.as('rem'),
    lineHeight: 32 / TYPE_SCALE.DATA_2.asValue('px'),
};

const dataLabel = {
    fontSize: TYPE_SCALE.TEXT_5.as('rem'),
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: 24 / TYPE_SCALE.TEXT_5.asValue('px'),
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
};

export const FONT_STYLES = {
    h1,
    h2,
    h3,
    h4,
    h5,

    body,
    bodyMedium,

    detail,
    detailMedium,

    data,
    dataSmall,
    dataLabel,
} as const;
