import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import { ISO8601 } from 'app/core/types';

import { useGetUnitAssignmentScheduleAvailableDeviceInstanceIDsQuery } from 'generated/graphql';

import { useMpuIndexScheduleDeviceInstances } from '../MpuIndexSchedule/hooks';
import { DeviceInstanceWithSchedules } from '../MpuSchedule';

/**
 * Fetches a list of IDs of MPUs that're available for a given time frame.
 */
function useAvailableDeviceInstancesIDs({
    end,
    ownerAccountID,
    start,
}: {
    end: ISO8601;
    ownerAccountID: string;
    start: ISO8601;
}) {
    const { data, error, loading, refetch } = useGetUnitAssignmentScheduleAvailableDeviceInstanceIDsQuery({
        variables: {
            availableDeviceInstancesInput: {
                end,
                ownerAccountID,
                start,
            },
        },
    });

    const ids = useMemo(() => {
        return data?.availableDeviceInstances.map(({ id }) => id) || [];
    }, [data]);

    return { data: ids, error, loading, refetch };
}

/**
 * Extends the `useMpuIndexScheduleDeviceInstances` hook, by sorting the results
 * by putting available MPUs before others.
 */
export function useUnitAssignmentDeviceInstances({
    end,
    ownerAccountID,
    start,
}: {
    /**
     * The end of the time frame to assign an MPU to.
     * This would typically be the start of a reservation allocation.
     */
    end: ISO8601;
    ownerAccountID: string;
    /**
     * The start of the time frame to assign an MPU to.
     * This would typically be the end of a reservation allocation.
     */
    start: ISO8601;
}) {
    const {
        data: availableIDs,
        loading: availableIDsLoading,
        refetch: refetchAvailableIDs,
    } = useAvailableDeviceInstancesIDs({ end, ownerAccountID, start });

    /**
     * Using the list of available MPU IDs, it sorts a collection of device instances
     * to put available MPUs before others.
     */
    const sort = useCallback(
        (list: DeviceInstanceWithSchedules[]): DeviceInstanceWithSchedules[] => {
            return list.sort((deviceInstanceA, deviceInstanceB) => {
                return (
                    -1 *
                    (Number(availableIDs.includes(deviceInstanceA.id)) -
                        Number(availableIDs.includes(deviceInstanceB.id)))
                );
            });
        },
        [availableIDs],
    );

    /**
     * An expanded range to fetch device instance schedules for.
     *
     * The range given to this hook is typically the time frame
     * of a reservation allocation.
     * However, the grid is padded with additional days to provide more context
     * to the end-user to make an informed decision.
     * To fill these days, we expand the range of schedule we fetch.
     */
    const expandedRange = useMemo(
        () => ({
            end: DateTime.fromISO(end).plus({ week: 1 }).toISO() ?? '',
            start: DateTime.fromISO(start).minus({ week: 1 }).toISO() ?? '',
        }),
        [end, start],
    );

    const {
        deviceInstances,
        handleItemsRendered,
        handleSearchChange,
        loading: deviceInstancesLoading,
        refetchMpuList,
    } = useMpuIndexScheduleDeviceInstances({ sort, ...expandedRange });

    /**
     * Refetch all data provided by this hook.
     */
    const refetch = useCallback(async () => {
        await Promise.all([refetchAvailableIDs(), refetchMpuList()]);
    }, [refetchAvailableIDs, refetchMpuList]);

    return {
        deviceInstances,
        handleItemsRendered,
        handleSearchChange,
        loading: availableIDsLoading || deviceInstancesLoading,
        refetch,
    };
}
