import { ChangeEventHandler, ReactElement, useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from '@mui/material/utils';

import { Button, Icon, Input } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { ContentProps } from '../MultiGrid';
import { ScheduleToolbarWrapper } from './ScheduleToolbarWrapper';

interface ScheduleFilterPresenterProps extends ContentProps<any> {
    /**
     * Determines whether the filter button is rendered.
     */
    enableFilter?: boolean;
    /**
     * Callback handler called when the search input value changes.
     */
    onSearchChange?: (searchValue: string) => void;
}

/**
 * Presentational component for adding a search box and filter menu to the `Schedule` component.
 *
 * @example
 *
 * <Schedule
 *     slots={{
 *         toolbarRight: ScheduleFilterToolbar,
 *     }}
 *     slotProps={{
 *         toolbarRight: {
 *              onSearchChange: (value) => console.log(value)
 *         },
 *     }}
 * />
 */
export function ScheduleFilterToolbar({ enableFilter, onSearchChange }: ScheduleFilterPresenterProps): ReactElement {
    const { t } = useI18n();
    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchListener = useMemo((): ChangeEventHandler<HTMLInputElement> => {
        return debounce((event): void => onSearchChange?.(event.target.value), 300);
    }, [onSearchChange]);

    const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        event => {
            setSearchValue(event.target.value);
            debouncedSearchListener(event);
        },
        [debouncedSearchListener],
    );

    return (
        <ScheduleToolbarWrapper variant="right">
            <Box sx={{ width: '200px' }}>
                <Input
                    value={searchValue}
                    onChange={handleSearchChange}
                    placeholder={t('schedule_view.search')}
                    startAdornment={
                        <InputAdornment position="start">
                            <Icon name="search" />
                        </InputAdornment>
                    }
                />
            </Box>
            {/* TODO(Morris): Implement backend filtering. */}
            {enableFilter && (
                <Button ctaType="secondary" icon={<Icon name="filter" />}>
                    {t('schedule_view.filter')}
                </Button>
            )}
        </ScheduleToolbarWrapper>
    );
}
