import { DateTime } from 'luxon';

import { MetricName } from 'app/core/data';

import {
    BaseReservationItemForCustomerReservationsPageFragment,
    FullReservationItemForCustomerReservationsPageFragment,
    ReservationStatus,
} from 'generated/graphql';

import {
    CustomerReservationsPageReservation,
    CustomerReservationsPageReservationItem,
    InterimReservatonTableReservation,
} from './useReservationsPageData';

/**
 * transforms GQL ReservationItem into a ReservationItem designed for ReservationTable
 * @param ri GQL ReservationItem (fragment)
 * @param shouldBeAugmented determines whether to augment with telemetry & location data
 */
export function transformReservationItem(
    ri: Omit<
        BaseReservationItemForCustomerReservationsPageFragment & FullReservationItemForCustomerReservationsPageFragment,
        'reservation'
    >,
    shouldBeAugmented = true,
): CustomerReservationsPageReservationItem & { createdAt: string } {
    let transformedReservationItem: CustomerReservationsPageReservationItem & { createdAt: string } = {
        id: ri.id,
        name: ri.alias || undefined,
        start: DateTime.fromISO(ri.start),
        end: DateTime.fromISO(ri.end),
        createdAt: ri.createdAt,
        deliveryAddress: ri.deliveryLocation?.formattedAddress || '',
        pickupAddress: ri.pickupLocation?.formattedAddress || '',
    };

    if (!shouldBeAugmented) return transformedReservationItem;

    const telemetryPoints = ri.assignedDevice?.latestTelemetry?.telemetryPoints;

    const stateOfCharge = telemetryPoints?.find(({ metric }) => metric === MetricName.StateOfCharge);
    const timeToEmpty = telemetryPoints?.find(({ metric }) => metric === MetricName.TimeToEmpty);

    return {
        ...transformedReservationItem,
        stateOfCharge,
        timeToEmpty,
        latestDeviceLocation: ri.assignedDevice?.latestDeviceLocation || undefined,
    };
}

/**
 * transforms InterimReservatonTableReservation into a Reservation designed for ReservationTable
 * @param r reservation
 */
export function transformReservation(
    r: InterimReservatonTableReservation,
    generateFallbackName: (id: string) => string,
): CustomerReservationsPageReservation {
    const start = DateTime.min(...r.reservationItems.map(ri => ri.start));
    const end = DateTime.max(...r.reservationItems.map(ri => ri.end));

    const isCancelledOrDeclined = r.status === ReservationStatus.Cancelled || r.status === ReservationStatus.Declined;
    const isCurrent = start <= DateTime.now() && end >= DateTime.now() && !isCancelledOrDeclined;

    return {
        ...r,
        name: r.name ?? generateFallbackName(r.id),
        start,
        end,
        unitCount: r.reservationItems.length,
        isCurrent,
        isMonitorable: isCurrent,
        isReviewable: end < DateTime.now() && !isCancelledOrDeclined,
        reservationItems: r.reservationItems
            // strip out createdAt from reservation items, and provide fallback name
            .map(({ createdAt, ...ri }, index) => ({
                ...ri,
                name: ri.name ?? `Unit ${index + 1}`,
            })),
    };
}

/**
 * transformer that takes an array of reservation items and returns an array of reservations
 * @param reservationItems array GQL ReservationItem (fragment) objects
 * @param transform tranformer for each element of reservationItems
 */
export function invertReservationItemAndReservationRelationship(
    reservationItems: (BaseReservationItemForCustomerReservationsPageFragment &
        FullReservationItemForCustomerReservationsPageFragment)[],
    transform: typeof transformReservationItem,
): InterimReservatonTableReservation[] {
    const reservationMap = reservationItems.reduce((acc, ri) => {
        const reservationID = ri.reservation.id;
        const reservation = acc.get(reservationID);

        const transformedReservationItem = transform(ri);

        acc.set(reservationID, {
            id: ri.reservation.id,
            name: ri.reservation.name || undefined,
            reservationItems: !reservation
                ? [transformedReservationItem]
                : reservation.reservationItems.concat(transformedReservationItem),
            additionalNotes: ri.reservation.additionalNotes || undefined,
            isCurrent: false,
            isMonitorable: false,
            status: ri.reservation.status,
        });

        return acc;
    }, new Map());

    return Array.from(reservationMap).map(
        ([_, reservationMetadata]) => reservationMetadata,
    ) as InterimReservatonTableReservation[];
}
