import {
    GlobalListDeviceModelsQuery,
    GlobalListDeviceModelsQueryHookResult,
    useGlobalListDeviceModelsQuery,
} from 'generated/graphql';

export type DeviceModel = GlobalListDeviceModelsQuery['listDeviceModels'][0];

export interface IdentityMap<T> {
    [key: string]: T;
}

interface UseDeviceModelsResult extends Pick<GlobalListDeviceModelsQueryHookResult, 'loading' | 'error'> {
    defaultDeviceModel: DeviceModel | undefined;
    deviceModels: DeviceModel[];
    deviceModelsById: IdentityMap<DeviceModel>;
}

function storeById(items: DeviceModel[]): IdentityMap<DeviceModel> {
    return items.reduce((acc, cur) => {
        return { ...acc, [cur.id]: cur };
    }, {});
}

function getDefaultDeviceModel(items: DeviceModel[]): DeviceModel | undefined {
    return items.find((x: DeviceModel) => /mp-75/i.test(x.name ?? '')) || items[items.length - 1];
}

export default function useDeviceModels(): UseDeviceModelsResult {
    const { data, loading, error } = useGlobalListDeviceModelsQuery();

    const deviceModels = data?.listDeviceModels ?? [];
    const deviceModelsById = storeById(deviceModels);
    const defaultDeviceModel = getDefaultDeviceModel(deviceModels);

    return {
        loading,
        error,
        defaultDeviceModel,
        deviceModels,
        deviceModelsById,
    };
}
