import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import { ContentProps } from '../MultiGrid';
import { BoxWithoutScrollbars } from '../MultiGrid/BoxWithoutScrollbars';

/**
 * TODO(Morris): Figure out why `HTMLDivElement` doesn't work for the Ref type, and remove `any` usage.
 */
export const ScheduleAsideOuter = forwardRef<any, ComponentPropsWithoutRef<typeof BoxWithoutScrollbars>>(
    function ScheduleAsideOuter({ sx, ...otherProps }, ref) {
        return (
            <BoxWithoutScrollbars
                {...otherProps}
                ref={ref}
                sx={[
                    theme => ({
                        boxShadow: `1px 0px ${theme.palette.grey[300]}`,
                        height: '100%',
                        width: '100%',
                    }),
                    ...normalizeSxProp(sx),
                ]}
            />
        );
    },
);

export function ScheduleAsideCornerContent(_props: ContentProps<any>) {
    return <ScheduleAsideOuter />;
}
