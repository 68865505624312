import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal, { ModalProps } from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Icon from 'app/components/primitives/Icon';
import Layer from 'app/components/primitives/layout/Layer';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import ReservationRequestForm, { ReservationRequest } from '../ReservationRequestForm';
import UnitSpecsAndOperationPanel, { mp75Specs } from '../UnitSpecsAndOperationPanel';

type CloseReasons = 'backdropClick' | 'escapeKeyDown' | 'cancelled' | 'submitSuccess';

interface Props extends Omit<ModalProps, 'onClose' | 'children'> {
    /**
     * The users ID to make the request on behalf of
     */
    userID: string | undefined;

    /**
     * The accounts ID to make request on behalf of
     */
    accountID: string | undefined;

    /**
     * The event handler to be called when a reservation request is submitted by the user
     */
    onRequestReservation: (event: { value: ReservationRequest }) => Promise<void> | void;

    /**
     * The event handler to be called when the modal is requested to close
     */
    onClose: (event: {}, reason: CloseReasons) => void;
}

const panelSpacing = { py: { xs: 4, md: 5 }, px: { xs: 4, md: 5 }, mx: { xs: -4, md: 0 } };

const FormPanel = ({ userID, accountID, onSubmit }) => (
    <Paper elevation={0} sx={panelSpacing}>
        <Box maxWidth={350}>
            <ReservationRequestForm userID={userID} accountID={accountID} onSubmit={onSubmit} />
        </Box>
    </Paper>
);

const SpecsPanel = () => (
    <Paper elevation={0} sx={panelSpacing}>
        <UnitSpecsAndOperationPanel {...mp75Specs} />
    </Paper>
);

/**
 * Responsible for displaying rental request form in a modal alongside the device specs
 */
export default function RenterReservationRequestModal({
    userID,
    accountID,
    open,
    onClose,
    onRequestReservation,
    ...props
}: Props): ReactElement {
    const { t } = useI18n();

    const close = (event: {}, reason: CloseReasons) => {
        if (typeof onClose === 'function') {
            onClose(event, reason);
        }
    };

    const onSubmit = async event => {
        await onRequestReservation(event);

        close(event, 'submitSuccess');
    };

    return (
        <Modal {...props} open={open} onClose={onClose}>
            <Layer anchor width="100%" height="100%" pt={6} sx={{ backgroundColor: 'background.default' }}>
                <Layer relAnchorX="right" relAnchorY="top" x="right" y="top" mt={5} ml={-5}>
                    <IconButton onClick={event => close(event, 'cancelled')}>
                        <Icon name="close" size="lg" />
                    </IconButton>
                </Layer>

                <Stack height="100%">
                    <Container maxWidth="lg">
                        <Text variant="h1" as="h2" gutterBottom mr={5}>
                            {t('renter_reservation_form.modal_title')}
                        </Text>
                    </Container>

                    <Box sx={{ overflowY: 'auto', flexGrow: 1, height: '100%' }}>
                        <Container sx={{ pb: 6 }} maxWidth="lg">
                            <Grid container spacing={2}>
                                {/*
                                 * The form and specs are duplicated to allow switching the source order
                                 * at mobile breakpoints
                                 */}
                                <Grid item xs={12} md={5} lg={6}>
                                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <FormPanel userID={userID} accountID={accountID} onSubmit={onSubmit} />
                                    </Box>

                                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                        <SpecsPanel />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={7} lg={6}>
                                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <SpecsPanel />
                                    </Box>

                                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                        <FormPanel userID={userID} accountID={accountID} onSubmit={onSubmit} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Stack>
            </Layer>
        </Modal>
    );
}
