import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
    getGridSingleSelectOperators,
    getGridStringOperators,
    GridColDef,
    GridFilterModel,
    GridFilterOperator,
    GridMenuParams,
    GridRenderCellParams,
    GridRowsProp,
    GridSortModel,
} from '@mui/x-data-grid';

import {
    DataGrid,
    DataGridCellContent,
    Icon,
    Layer,
    Link,
    LoadingIndicator,
    Text,
    Tooltip,
    ZeroState,
} from 'app/components';
import { isSOCLow, isTTELow } from 'app/core/data';
import { formatURLState } from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { FaultCodesBySeverity, MPU } from './types';
import useAccountFilterOptions from './useAccountFilterOptions';
import useServiceAreaFilterOptions from './useServiceAreaFilterOptions';

export const MPUS_TABLE_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

const SINGLE_SELECT_OPERATOR = {
    is: getGridSingleSelectOperators().find(operator => operator.value === 'is') as GridFilterOperator<
        MPU,
        string | number | null,
        any
    >,
};

const STRING_OPERATOR = {
    contains: getGridStringOperators().find(operator => operator.value === 'contains') as GridFilterOperator<
        MPU,
        string | number | null,
        any
    >,
    isAnyOf: getGridStringOperators().find(operator => operator.value === 'isAnyOf') as GridFilterOperator<
        MPU,
        string | number | null,
        any
    >,
};

interface Props {
    mpus: MPU[];
    excludeFields?: string[];
    loading: boolean;
    sortModel: GridSortModel;
    onSortModelChange: (sortModel: GridSortModel) => void;
    filterModel?: GridFilterModel;
    onFilterModelChange?: (filterModel: GridFilterModel) => void;
    pageSize: number;
    onPageSizeChange: (pageSize: number) => void;
    pageIndex: number;
    onPageChange: (pageIndex: number) => void;
    totalRowCount: number;
}

function getFaultSortingValue(codes: FaultCodesBySeverity): number {
    return [...codes.entries()].reduce((acc, cur) => {
        const [severity, faultCodes] = cur;
        const severityMultiplier = /^CRITICAL$/i.test(severity) ? 100 : 1;

        return acc + faultCodes.length * severityMultiplier;
    }, 0);
}

function LoadingOverlay() {
    return (
        <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
            <LoadingIndicator />
        </Layer>
    );
}

function NoRowsOverlay() {
    const { t } = useI18n();

    return (
        <Box m={7}>
            <ZeroState
                title={t('admin_unit_index_page.table.no_results_title')}
                message={t('admin_unit_index_page.table.no_results_message')}
            />
        </Box>
    );
}

export default function MPUsTable({
    excludeFields = [],
    mpus,
    loading,
    sortModel,
    onSortModelChange,
    filterModel,
    onFilterModelChange,
    pageSize,
    onPageSizeChange,
    pageIndex,
    onPageChange,
    totalRowCount,
}: Props): ReactElement {
    const { t, format } = useI18n();

    const { accountValueOptions, fetchAccountValueOptions } = useAccountFilterOptions();
    const { serviceAreaValueOptions, fetchServiceAreaValueOptions } = useServiceAreaFilterOptions();

    const rows: GridRowsProp<MPU> = mpus.slice();
    const allColumns: GridColDef<MPU>[] = [
        {
            field: 'activeFaultCodes',
            headerName: '',
            width: 80,
            filterable: false,
            sortable: false,
            valueGetter: ctx => ctx.row.activeFaultCodes,
            sortComparator: (a, b) => {
                return getFaultSortingValue(a) - getFaultSortingValue(b);
            },
            renderCell: params => {
                const activeFaultCodes = params.value;

                if (activeFaultCodes.size === 0) return <></>;

                return (
                    <Tooltip
                        placement="bottom-start"
                        content={
                            <Stack spacing={3}>
                                {[...activeFaultCodes.entries()].map(entry => {
                                    const [severity, codes] = entry;

                                    return (
                                        <Box key={severity}>
                                            <Text as="div">
                                                <Link
                                                    color="inherit"
                                                    to={formatURLState({ path: params.row.href, hash: 'status' })}
                                                >
                                                    {t('admin_unit_index_page.table.faults_count_tooltip', {
                                                        severity: t(`fault_severity.${severity}`),
                                                        count: codes.length,
                                                    })}
                                                </Link>
                                            </Text>

                                            {codes.map(code => (
                                                <Text as="div" variant="detail">
                                                    {t(`device_fault.${code.toLowerCase()}.name`)}
                                                </Text>
                                            ))}
                                        </Box>
                                    );
                                })}
                            </Stack>
                        }
                    >
                        <Icon color={activeFaultCodes.has('critical') ? 'danger' : undefined} name="alert-circle" />
                    </Tooltip>
                );
            },
        },
        {
            field: 'name',
            headerName: t('admin_unit_index_page.table.header.name'),
            flex: 1,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            valueGetter: ctx => ctx.row.name,
            renderCell: params => <Link to={params.row.href}>{params.value}</Link>,
        },
        {
            field: 'serialNumber',
            headerName: t('admin_unit_index_page.table.header.serial_number'),
            flex: 1,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            valueGetter: ctx => ctx.row.serialNumber,
        },

        {
            field: 'externalID',
            headerName: t('admin_unit_index_page.table.header.external_unit_id'),
            flex: 1,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            valueGetter: ctx => ctx.row.externalID,
        },
        {
            field: 'ownerName',
            headerName: t('admin_unit_index_page.table.header.owner'),
            flex: 1,
            type: 'singleSelect',
            filterOperators: [SINGLE_SELECT_OPERATOR.is],
            valueOptions: accountValueOptions,
            valueGetter: ctx => ctx.row.ownerName,
            renderCell(params: GridRenderCellParams<MPU, string>) {
                return <DataGridCellContent line1={params.row.ownerName} />;
            },
        },
        {
            field: 'modelName',
            headerName: t('admin_unit_index_page.table.header.device_model'),
            flex: 1,
            filterable: false,
            sortable: false,
            valueGetter: ctx => ctx.row.modelName,
        },
        {
            field: 'mode',
            headerName: t('admin_unit_index_page.table.header.run_mode'),
            flex: 1,
            filterable: false,
            sortable: false,
            valueGetter: ctx => t(`mode_selection_state.${ctx.row.mode}`),
        },
        {
            field: 'stateOfCharge',
            headerName: t('admin_unit_index_page.table.header.soc'),
            flex: 1,
            filterable: false,
            sortable: false,
            valueGetter: ctx => ctx.row.stateOfCharge,
            renderCell: ({ value }) => (
                <Text color={isSOCLow(value) ? 'danger' : 'primary'}>{format.soc(value).toString()}</Text>
            ),
        },
        {
            field: 'timeToEmpty',
            headerName: t('admin_unit_index_page.table.header.time_to_empty'),
            flex: 1,
            filterable: false,
            sortable: false,
            valueGetter: ctx => ctx.row.timeToEmpty,
            renderCell: ({ value }) => (
                <Text color={isTTELow(value) ? 'danger' : 'primary'}>{format.timeLeftShort(value).toString()}</Text>
            ),
        },
        {
            field: 'assignment',
            headerName: t('admin_unit_index_page.table.header.assignment'),
            flex: 1,
            filterable: false,
            sortable: false,
            valueGetter: ctx => ctx.row.currentAssignment,
        },

        {
            field: 'serviceAreaName',
            headerName: t('admin_unit_index_page.table.header.service_area'),
            flex: 1,
            type: 'singleSelect',
            filterOperators: [SINGLE_SELECT_OPERATOR.is],
            valueOptions: serviceAreaValueOptions,
            valueGetter: ctx => ctx.row.serviceAreaName,
            renderCell(params: GridRenderCellParams<MPU, string>) {
                return <DataGridCellContent line1={params.row.serviceAreaName} />;
            },
        },

        {
            field: 'particleDeviceID',
            headerName: t('admin_unit_index_page.table.header.particle_device_id'),
            flex: 1,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            valueGetter: ctx => ctx.row.particleDeviceID,
            hideable: true,
        },
    ];
    const columns = allColumns.filter(x => !excludeFields.includes(x.field));

    /**
     * returns true if opened menu is for column with given field;
     * returns null if header element not found (as failsafe)
     */
    function isMenuForColumn(columnField: string, params: GridMenuParams) {
        const column = columns.find(column => column.field === columnField);

        if (!column) {
            return false;
        }

        // find the column header element
        // there's no way to access this via the DataGrid API, hence accessing via the DOM
        const el = params.target?.closest('.MuiDataGrid-columnHeader')?.querySelector('.MuiDataGrid-columnHeaderTitle');

        if (!el) {
            // no element found, so return null to distinguish from false
            return null;
        }

        return el?.textContent === column.headerName;
    }

    // render the grid
    return (
        <DataGrid
            loading={loading}
            density="standard"
            // rendering
            autoHeight
            // pagination
            paginationModel={{ page: pageIndex, pageSize }}
            onPaginationModelChange={paginationModel => {
                if (paginationModel.page !== pageIndex) {
                    onPageChange(paginationModel.page);
                }
                if (paginationModel.pageSize !== pageSize) {
                    onPageSizeChange(paginationModel.pageSize);
                }
            }}
            pageSizeOptions={MPUS_TABLE_ROWS_PER_PAGE_OPTIONS}
            initialState={{ columns: { columnVisibilityModel: { particleDeviceID: false } } }}
            pagination
            paginationMode="server"
            // data
            rows={rows}
            rowCount={totalRowCount}
            columns={columns}
            sx={{
                minWidth: 1600,
                minHeight: '80vh',
            }}
            disableRowSelectionOnClick={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            sortingMode="server"
            disableColumnFilter={filterModel === undefined ? true : false}
            onFilterModelChange={onFilterModelChange}
            filterModel={filterModel}
            filterMode="server"
            components={{
                LoadingOverlay,
                NoRowsOverlay,
            }}
            onMenuOpen={params => {
                const isMenuForOwnerNameColumn = isMenuForColumn('ownerName', params);
                if (isMenuForOwnerNameColumn || isMenuForOwnerNameColumn === null) {
                    fetchAccountValueOptions();
                }

                const isMenuForServiceAreaColumn = isMenuForColumn('serviceAreaName', params);
                if (isMenuForServiceAreaColumn || isMenuForServiceAreaColumn === null) {
                    fetchServiceAreaValueOptions();
                }
            }}
        />
    );
}
