import { FunctionComponent, MouseEventHandler, useCallback } from 'react';

import { endBarHover } from 'app/components/compounds/Schedule/actions';
import { useScheduleDispatch } from 'app/components/compounds/Schedule/ScheduleContext';

import { Assignment } from 'generated/graphql';

import { MpuScheduleEvent, MpuScheduleEventSchedule } from '../types';
import {
    ChargingTooltip,
    DowntimeTooltip,
    ReservedTooltip,
    TransitTooltip,
    UnknownScheduleTooltip,
} from './presenters';

const contentByAssignment = {
    [Assignment.Charging]: ChargingTooltip,
    [Assignment.Maintenance]: DowntimeTooltip,
    [Assignment.Discharging]: ReservedTooltip,
    [Assignment.Transit]: TransitTooltip,
} as const satisfies Record<Assignment, FunctionComponent<any>>;

/**
 * An event dispatched when navigation is requested for a schedule event.
 *
 * - When a link in a schedule event tooltip is clicked.
 */
export type ScheduleNavigationEvent = {
    /**
     * When available, a callback that closes the tooltip that triggered the navigation event.
     */
    closeTooltip?: () => void;
    /**
     * The `DeviceInstanceSchedule` to navigate to.
     */
    deviceInstanceSchedule: MpuScheduleEventSchedule;
    /**
     * Prevents the default navigation behavior.
     */
    preventDefault: () => void;
    /**
     * The `MpuScheduleEvent` associated with the navigation.
     */
    scheduleEvent: MpuScheduleEvent;
};

/**
 * A component that renders tooltip content for a given `DeviceInstanceSchedule`.
 *
 * _Responsible for:_
 *
 * - Rendering the correct tooltip content based on the schedule's assignment.
 * - Translating the link's `MouseEvent` into a `ScheduleNavigationEvent`.
 */
export function TooltipContentSection({
    onScheduleNavigation,
    schedule,
    scheduleEvent,
}: {
    onScheduleNavigation?: (event: ScheduleNavigationEvent) => void;
    schedule: MpuScheduleEventSchedule;
    scheduleEvent: MpuScheduleEvent;
}) {
    const dispatch = useScheduleDispatch();
    const { assignment } = schedule;
    const Content = assignment ? contentByAssignment[assignment] : UnknownScheduleTooltip;

    const handleLinkClick = useCallback<MouseEventHandler>(
        event => {
            onScheduleNavigation?.({
                closeTooltip: () => dispatch(endBarHover()),
                deviceInstanceSchedule: schedule,
                preventDefault: () => event.preventDefault(),
                scheduleEvent,
            });
        },
        [dispatch, onScheduleNavigation, schedule, scheduleEvent],
    );

    return <Content onLinkClick={handleLinkClick} schedule={schedule} />;
}
