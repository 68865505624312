import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { enUS } from '@mui/x-data-grid';
import { Localization } from '@mui/x-data-grid/utils/getGridLocalization';

export const DATA_GRID_NAMESPACE = 'dataGrid';

function formatLanguageAsMuiLocale(language = 'en-US') {
    if (!language.match(/\w{2}-\w{2}/)) return 'enUS';
    return language.replace('-', '');
}

/**
 * Returns promise representing i18next initialization, resolves with translate function.
 * Use translate function provided by `useI18n` instead of this.
 */
function makeI18n() {
    i18next
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        .use(
            resourcesToBackend((language, namespace, callback) => {
                // TODO(derek): make translation lookup more robust
                import(`./translations/${language}/${namespace}.json`)
                    .then(({ default: resources }) => {
                        callback(null, resources);
                    })
                    .catch(error => {
                        callback(error, null);
                    });

                const muiLocale = formatLanguageAsMuiLocale(language);

                if (!i18next.hasResourceBundle(language, DATA_GRID_NAMESPACE)) {
                    // dynamically import the correct locale for the current language
                    import(`@mui/x-data-grid/locales/${muiLocale}.js`)
                        .then(module => {
                            const locale: Localization = module[muiLocale];

                            i18next.addResourceBundle(
                                language,
                                DATA_GRID_NAMESPACE,
                                locale.components.MuiDataGrid.defaultProps.localeText,
                                true,
                                true,
                            );
                        })
                        .catch(() => {
                            // if the desired locale doesn't exist, fall back to enUS
                            i18next.addResourceBundle(
                                language,
                                DATA_GRID_NAMESPACE,
                                enUS.components.MuiDataGrid.defaultProps.localeText,
                                true,
                                true,
                            );
                        });
                }
            }),
        )
        .use(ICU)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            /**
             * Options for i18next-language-detector
             */
            detection: {
                order: ['querystring', 'navigator', 'htmlTag'],

                lookupQuerystring: 'locale',

                caches: [],
            },

            /**
             * Options for i18next-icu
             */
            // i18nFormat: {},

            fallbackLng: 'en-US',
            supportedLngs: ['en-US'],

            load: 'currentOnly',

            /**
             * This option lets us lazy load translation files (namespaces / resources)
             */
            partialBundledLanguages: true,

            /**
             * Adds debug logging for local development
             */
            debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });

    // I18next sets the html lang attribute but does not set the dir attribute
    // We'll want to set the appropriate dir attribute to style the app appropriately
    i18next.on('languageChanged', language => {
        document.getElementsByTagName('html')[0].setAttribute('dir', i18next.dir(language));
    });

    return i18next;
}

export default makeI18n;
