import { useMemo } from 'react';
import * as luxon from 'luxon';

import { useI18n } from 'i18n';

import {
    ReservationStatus,
    TelemetryPoint,
    useListCancelledReservationsForCustomerReservationsPageQuery,
    useListCurrentReservationsForCustomerReservationsPageQuery,
    useListDeclinedReservationsForCustomerReservationsPageQuery,
    useListPastReservationsForCustomerReservationsPageQuery,
    useListUpcomingReservationsForCustomerReservationsPageQuery,
} from '../../../generated/graphql';
import {
    invertReservationItemAndReservationRelationship,
    transformReservation,
    transformReservationItem,
} from './transformers';

/**
 * maximum time (in milliseconds) to either side of 01 January, 1970 UTC
 */
const MAX_MS_EPOCH = 86_400_000_000_000;

/**
 * ReservationItem designed for ReservationTable
 */
export interface CustomerReservationsPageReservationItem {
    /**
     * ID of reservation item
     */
    id: string;
    /**
     * name of reservation item
     */
    name?: string;
    /**
     * telemetry value containing latest SOC of reservation item's assigned unit
     */
    stateOfCharge?: Pick<TelemetryPoint, 'timestamp' | 'value'>;
    /**
     * telemetry value containing latest ETTE of reservation item's assigned unit
     */
    timeToEmpty?: Pick<TelemetryPoint, 'timestamp' | 'value'>;
    /**
     * start date of reservation item
     */
    start: luxon.DateTime;
    /**
     * end date of reservation item
     */
    end: luxon.DateTime;
    /**
     * latest known location of reservation item's assigned unit
     */
    latestDeviceLocation?: {
        lat: number;
        lon: number;
    };
    /**
     * address to which the unit will be delivered
     */
    deliveryAddress: string;
    /**
     * address from which the unit will be picked up
     */
    pickupAddress: string;
}

/**
 * Reservation designed for ReservationTable
 */
export interface CustomerReservationsPageReservation {
    /**
     * ID of reservation
     */
    id: string;
    /**
     * name of reservation
     */
    name: string;
    /**
     * earliest start date of reservation's reservation items
     */
    start: luxon.DateTime;
    /**
     * earliest end date of reservation's reservation items
     */
    end: luxon.DateTime;
    /**
     * whether the current time is within the start and end dates
     */
    isCurrent: boolean;
    /**
     * Whether or not the customer can view the monitoring dashboard associated with their reservation
     */
    isMonitorable: boolean;
    /**
     * Whether or not the customer can rewview the usage data from their reservation
     */
    isReviewable: boolean;
    /**
     * number of units under this reservation
     */
    unitCount: number;
    /**
     * array of ReservationItem objects designed for ReservationTable
     */
    reservationItems: CustomerReservationsPageReservationItem[];
    /**
     * notes regarding reservation
     */
    additionalNotes?: string;
}

export interface InterimReservatonTableReservation
    extends Omit<
        CustomerReservationsPageReservation,
        'name' | 'start' | 'end' | 'unitCount' | 'reservationItems' | 'isCurrent' | 'isMonitorable' | 'isReviewable'
    > {
    name?: string;
    reservationItems: (CustomerReservationsPageReservationItem & { createdAt: string })[];
    status: ReservationStatus;
}

enum DeviceAssignment {
    Discharging = 'discharging',
}

function isReservationCancelledOrDeclined(r: InterimReservatonTableReservation) {
    return r => r.status !== ReservationStatus.Cancelled && r.status !== ReservationStatus.Declined;
}

/**
 * hook to fetch data for CustomerReservationsPage and return transformed data
 */
export function useReservationsPageData(requesterAccountID: string): {
    currentReservations: CustomerReservationsPageReservation[];
    pastReservations: CustomerReservationsPageReservation[];
    upcomingReservations: CustomerReservationsPageReservation[];
    cancelledReservations: CustomerReservationsPageReservation[];
    declinedReservations: CustomerReservationsPageReservation[];
    loading: boolean;
    errorMessage: string | undefined;
} {
    const { t } = useI18n();
    const now = useMemo(() => luxon.DateTime.now(), []);

    const {
        loading: isListCurrentReservationLoading,
        error: listCurrentReservationsError,
        data: listCurrentReservationsData,
    } = useListCurrentReservationsForCustomerReservationsPageQuery({
        variables: {
            requesterAccountID,
            assignment: DeviceAssignment.Discharging,
            start: now.startOf('minute').toISO() ?? '',
            end: now.toISO() ?? '',
            telemetryEnd: now.toISO() ?? '',
        },
    });

    const {
        loading: isListUpcomingReservationLoading,
        error: listUpcomingReservationsError,
        data: listUpcomingReservationsData,
    } = useListUpcomingReservationsForCustomerReservationsPageQuery({
        variables: {
            requesterAccountID,
            start: now.toISO() ?? '',
            end: new Date(MAX_MS_EPOCH).toISOString(),
        },
    });

    const {
        loading: isListPastReservationLoading,
        error: listPastReservationsError,
        data: listPastReservationsData,
    } = useListPastReservationsForCustomerReservationsPageQuery({
        variables: {
            requesterAccountID,
            start: new Date(-MAX_MS_EPOCH).toISOString(),
            end: now.toISO() ?? '',
        },
    });

    const {
        loading: isListCancelledReservationLoading,
        error: listCancelledReservationsError,
        data: listCancelledReservationsData,
    } = useListCancelledReservationsForCustomerReservationsPageQuery({
        variables: {
            requesterAccountID,
        },
    });

    const {
        loading: isListDeclinedReservationLoading,
        error: listDeclinedReservationsError,
        data: listDeclinedReservationsData,
    } = useListDeclinedReservationsForCustomerReservationsPageQuery({
        variables: {
            requesterAccountID,
        },
    });

    function generateFallbackReservationName(id: string) {
        return t('reservation_table.reservation_fallback_title', {
            id: id.substring(0, 5),
        });
    }

    const currentReservations: CustomerReservationsPageReservation[] = invertReservationItemAndReservationRelationship(
        listCurrentReservationsData?.listReservationItems ?? [],
        transformReservationItem,
    )
        // TODO:(sam) make a plan with Will to get the status filter added to the query
        .filter(r => !isReservationCancelledOrDeclined(r))
        .map(r => transformReservation(r, generateFallbackReservationName));

    let upcomingReservations: CustomerReservationsPageReservation[] = invertReservationItemAndReservationRelationship(
        listUpcomingReservationsData?.listReservationItems ?? [],
        ri => transformReservationItem(ri, false),
    )
        .filter(r => !isReservationCancelledOrDeclined(r))
        .filter(r => !currentReservations.map(res => res.id).includes(r.id))
        .map(r => transformReservation(r, generateFallbackReservationName));

    const pastReservations: CustomerReservationsPageReservation[] = invertReservationItemAndReservationRelationship(
        listPastReservationsData?.listReservationItems ?? [],
        ri => transformReservationItem(ri, false),
    )
        .filter(r => !isReservationCancelledOrDeclined(r))
        .filter(r => !currentReservations.map(res => res.id).includes(r.id))
        .map(r => transformReservation(r, generateFallbackReservationName));

    const cancelledReservations: CustomerReservationsPageReservation[] = (
        listCancelledReservationsData?.listReservations ?? []
    ).map(r => {
        let transformed: InterimReservatonTableReservation = {
            ...r,
            name: r.name ?? undefined,
            additionalNotes: r.additionalNotes ?? undefined,
            reservationItems: r.reservationItems.map(ri => transformReservationItem(ri, false)),
        };

        return transformReservation(transformed, generateFallbackReservationName);
    });

    const declinedReservations: CustomerReservationsPageReservation[] = (
        listDeclinedReservationsData?.listReservations ?? []
    ).map(r => {
        let transformed: InterimReservatonTableReservation = {
            ...r,
            name: r.name ?? undefined,
            additionalNotes: r.additionalNotes ?? undefined,
            reservationItems: r.reservationItems.map(ri => transformReservationItem(ri, false)),
        };

        return transformReservation(transformed, generateFallbackReservationName);
    });

    const errorMessage = useMemo(() => {
        if (
            !!listCurrentReservationsError ||
            !!listPastReservationsError ||
            !!listUpcomingReservationsError ||
            !!listCancelledReservationsError ||
            !!listDeclinedReservationsError
        ) {
            return t('reservation_table.error_message.load');
        }
    }, [
        listCancelledReservationsError,
        listCurrentReservationsError,
        listDeclinedReservationsError,
        listPastReservationsError,
        listUpcomingReservationsError,
        t,
    ]);

    return {
        currentReservations,
        pastReservations,
        upcomingReservations,
        cancelledReservations,
        declinedReservations,
        loading:
            isListCurrentReservationLoading ||
            isListPastReservationLoading ||
            isListUpcomingReservationLoading ||
            isListCancelledReservationLoading ||
            isListDeclinedReservationLoading,
        errorMessage,
    };
}
