/**
 * @see {@link https://moxionpower.atlassian.net/wiki/spaces/SWE/pages/247300106/Telemetry+Schemas | Telemetry Schemas}
 */
export enum MetricName {
    // System status
    PcsState = 'pcs_state',
    SwitchMode = 'mode_selection_state',
    CellSignalStrength = 'cell_signal_pcnt',
    CellSignalQuality = 'cell_quality_pcnt',
    ChargingStatus = 'charge_state',
    VoltageSelection = 'voltage_selection_state',

    // Available energy
    PackEnergyAvailable = 'e_avail',
    StateOfCharge = 'soc',
    TimeToEmpty = 's_time_to_empty',
    NetPower = 'p_ess',
    TotalDeviceLoadPower = 'p_load',

    // Power out
    TotalLoadPower = 'pcs_total_power',

    // Line voltage
    Line1LoadVoltage = 'v_a',
    Line2LoadVoltage = 'v_b',
    Line3LoadVoltage = 'v_c',
    LineNLoadVoltage = 'v_n',
    SinglePhaseVoltage = 'v_s',

    // Line current
    Line1LoadCurrent = 'i_a',
    Line2LoadCurrent = 'i_b',
    Line3LoadCurrent = 'i_c',
    NeutralCurrent = 'i_n',
    SinglePhaseCurrent = 'i_s',

    // Line load
    Line1LoadPower = 'load_a',
    Line2LoadPower = 'load_b',
    Line3LoadPower = 'load_c',
    LineNLoadPower = 'load_n',
    TotalSinglePhasePowerDraw = 'load_s',

    // Power in
    TimeToFull = 's_time_to_full',
    ChargeInputPower = 'p_charge',

    // Temperatures
    OldPackMaxTemp = 'temp_ess_max',
    PackMaxTemp = 'ess_temp_max',

    // Derived Metrics
    TotalEnergyDischarged = 'energy/discharged',
    TotalEnergyCharged = 'energy/charged',
}

export enum RunMode {
    Off = 'off',
    Run = 'run',
    Standby = 'on_standby',
    Unknown = 'unknown',
}

export function isRunMode(value: string | undefined): value is RunMode {
    return Object.values(RunMode).includes(value as RunMode);
}

/**
 * An integer representing the number of bars for an MPU's cellular connection signal quality.
 */
export enum CellSignalBar {
    Disconnected = 0,
    Poor = 1,
    Fair = 2,
    Good = 3,
    Excellent = 4,
}

export function isCellSignalBar(value: number | undefined): value is CellSignalBar {
    return Object.values(CellSignalBar).includes(value as CellSignalBar);
}

export enum VoltageSelection {
    Invalid = 'invalid',
    Unknown = 'unknown',
    US208 = '208',
    US240 = '240',
    US480 = '480',
}

export enum ChargingStatus {
    Charging = 'charging',
    Initializing = 'initializing',
    NotConnected = 'not_connected',
    Unknown = 'unknown',
}
