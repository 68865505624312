import { ReactElement } from 'react';

import { useI18n } from 'i18n';

import { MUIMarginProps } from 'styles/theme/types';

import Metric, { MetricGroup } from './Metric';

interface Props extends MUIMarginProps {
    /**
     * The time in seconds until the batteries are considered full, time is linear wrt to charge input power.
     */
    timeToFull?: number | null | undefined;

    /**
     * The charge input power to the unit in kW (typical value is 40kW can be as much as 100kW)
     */
    chargeInputPower?: number | null | undefined;
}

/**
 * Responsible for displaying power input statistics
 */
export default function PowerIn({ timeToFull, chargeInputPower, ...props }: Props): ReactElement {
    const { t, format } = useI18n();

    const formattedTimeToFull = format.timeLeftShort(timeToFull);

    return (
        <MetricGroup {...props}>
            <Metric label={t('unit_status.power_in_heading')} {...format.power(chargeInputPower)} />

            <Metric label={t('unit_status.time_to_full_label')} {...formattedTimeToFull} />
        </MetricGroup>
    );
}
