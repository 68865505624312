import CSSDimension from '../CSSDimension';

/**
 * Spacing ramp is intended generally for spacing between elements and should be applied as pixels.
 * @link {Figma Docs | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=7%3A5077&mode=dev}
 */
const SPACING = {
    0: CSSDimension.fromPixels(0),
    xxs: CSSDimension.fromPixels(4),
    xs: CSSDimension.fromPixels(8),
    sm: CSSDimension.fromPixels(12),
    md: CSSDimension.fromPixels(16),
    lg: CSSDimension.fromPixels(24),
    xl: CSSDimension.fromPixels(32),
    xxl: CSSDimension.fromPixels(48),
    xxxl: CSSDimension.fromPixels(64),
} as const;

export const SPACING_RAMP = [0, 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'];

export default SPACING;
