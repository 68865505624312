import {
    cloneElement,
    ComponentProps,
    ComponentPropsWithRef,
    forwardRef,
    isValidElement,
    ReactElement,
    Ref,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import MuiButton from '@mui/material/ButtonBase';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import { MUIMarginProps } from 'styles/theme/types';

import Icon, { IconSize } from '../../Icon';
import Layer from '../../layout/Layer';
import Text from '../../Text';

type MuiButtonProps = ComponentProps<typeof MuiButton>;
type NativeButtonProps = ComponentPropsWithRef<'button'>;

interface Props extends MUIMarginProps, NativeButtonProps {
    /**
     * To be used sparingly but may be necessary for certain layout cases (i.e. responsive widths)
     */
    sx?: MuiButtonProps['sx'];

    /**
     * Presentational variant for type of CTA (call to action)
     * primary is most prominent while tertiary is least
     */
    ctaType?: 'primary' | 'secondary' | 'tertiary';

    /**
     * Whether to display button in danger colors for important irrevisible actions
     */
    danger?: boolean;

    /**
     * The button color
     */
    color?: 'primary' | 'danger' | 'utility' | 'utility-light';

    /**
     * Whether the button is disabled
     */
    disabled?: boolean;

    /**
     * Whether to fill the parents width.
     */
    fullWidth?: boolean;

    /**
     * The size of the button to render
     */
    size?: 'sm' | 'md';

    /**
     * Applied to icon only buttons, will render button as a circle
     */
    circle?: boolean;

    /**
     * Icon to add to the start of the button
     */
    icon?: ReactElement<{ size: IconSize }>;

    /**
     * The link destination
     */
    to?: string;

    /**
     * Button content
     */
    children?: MuiButtonProps['children'];

    /**
     * Test ID for identifying the button in tests
     */
    'data-testid'?: string;

    /**
     * This prop is for design documentation only and must not be used
     */
    demo?: 'hover' | 'focus';

    /**
     * The event handler to be called when the user clicks on the button
     */
    onClick?: MuiButtonProps['onClick'];
}

// Our button text will have a height of 24px / with or without a 24px icon
// - Medium button (icon only or text) final height is 48px
// - Small button final height is 36px
// - Small (icon only) 32px
function resolvePadding({ size, children }) {
    if (!children) {
        return {
            py: size === 'sm' ? 2 : 3,
            px: size === 'sm' ? 2 : 3,
        };
    }

    return {
        // `0.75` is used to achieve 36px of height
        // 0.75 * 8 * 2 + 24 = 36
        py: size === 'sm' ? 0.75 : 3,
        px: 4,
    };
}

function resolveSizeAndShape({ size, fullWidth, circle, children, theme }) {
    return {
        width: fullWidth ? '100%' : null,
        ...resolvePadding({ size, children }),
        borderRadius: circle && !children ? '999px' : `${theme.shape.borderRadius}px`,
    };
}

function resolveColors({ ctaType, color }) {
    if (color === 'utility') {
        if (ctaType === 'tertiary') {
            return {
                backgroundColor: 'transparent',
                color: 'text.primary',
                hoverColor: 'darken.10',
            };
        }

        return {
            backgroundColor: 'darken.5',
            color: 'text.primary',
            hoverColor: 'darken.10',
        };
    }

    if (color === 'utility-light') {
        if (ctaType === 'tertiary') {
            return {
                backgroundColor: 'transparent',
                color: 'text.contrast',
                hoverColor: 'lighten.10',
            };
        }

        return {
            backgroundColor: 'lighten.5',
            color: 'text.contrast',
            hoverColor: 'lighten.10',
        };
    }

    if (color === 'danger') {
        if (ctaType === 'secondary') {
            return {
                backgroundColor: 'background.danger.main',
                color: 'text.danger',
                hoverColor: 'background.danger.dark',
            };
        } else if (ctaType === 'tertiary') {
            return {
                backgroundColor: 'transparent',
                color: 'text.danger',
                hoverColor: 'background.danger.main',
            };
        }

        return {
            backgroundColor: 'background.dangerDark.main',
            color: 'background.dangerDark.contrastText',
            hoverColor: 'background.dangerDark.dark',
        };
    }

    if (ctaType === 'secondary') {
        return {
            backgroundColor: 'background.info.main',
            color: 'text.info',
            hoverColor: 'background.info.dark',
        };
    } else if (ctaType === 'tertiary') {
        return {
            backgroundColor: 'transparent',
            color: 'text.info',
            hoverColor: 'background.info.main',
        };
    }

    return {
        backgroundColor: 'background.infoDark.main',
        color: 'background.infoDark.contrastText',
        hoverColor: 'background.infoDark.dark',
    };
}

const iconSpacer = <Icon spacer size="md" />;

function resolveIcon({
    size,
    icon,
    children,
}: {
    size: IconSize;
    icon?: ReactElement<{ size: IconSize }>;
    children?: MuiButtonProps['children'];
}): ReactElement {
    if (isValidElement(icon)) {
        return cloneElement<{ size: IconSize }>(icon, { size });
    }

    if (!children) {
        return iconSpacer;
    }

    return <></>;
}

/**
 * Button component to match our design system. Margins can be added to the button directly however consider applying margin
 * using layout components or box instead.
 *
 * @link {Figma Documentation | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=134%3A6640&mode=dev}
 */
export default forwardRef(function Button(
    {
        type = 'button',
        ctaType = 'primary',
        color = 'primary',
        size = 'md',
        circle = true,
        onClick,
        fullWidth = false,
        disabled = false,
        icon,
        demo,
        to,

        sx,

        m,
        mb,
        ml,
        mr,
        mt,
        my,
        mx,

        children,

        ...props
    }: Props,
    ref: Ref<HTMLButtonElement>,
): ReactElement {
    const { backgroundColor, color: textColor, hoverColor } = resolveColors({ ctaType, color });

    return (
        <Box
            sx={normalizeSxProp(sx).concat({
                display: fullWidth ? 'flex' : 'inline-flex',
                width: fullWidth ? '100%' : null,

                m,
                mb,
                ml,
                mr,
                mt,
                my,
                mx,
            })}
        >
            <MuiButton
                {...props}
                type={type}
                to={to}
                component={to ? RouterLink : 'button'}
                ref={ref}
                className={demo === 'hover' ? 'demo-hover' : ''}
                focusRipple
                disabled={disabled}
                sx={theme => ({
                    position: 'relative',
                    wordBreak: 'keep-all',
                    whiteSpace: 'nowrap',

                    ...resolveSizeAndShape({ size, fullWidth, circle, children, theme }),

                    backgroundColor,
                    color: textColor,

                    gap: 1,

                    // Prevents buttons to be stretched in a flexbox (align-items: stretch) along with the above wrapping box
                    alignSelf: 'flex-start',

                    '&:hover,&.demo-hover': {
                        backgroundColor: hoverColor,
                    },

                    '&:disabled': {
                        backgroundColor: 'background.disabled.main',
                        color: 'text.disabled',
                    },
                })}
                onClick={onClick}
            >
                {resolveIcon({ size, icon, children })}
                {!!children && <Text variant="bodyMedium">{children}</Text>}

                {/* Hit area */}
                <Layer
                    sx={{
                        minHeight: '44px',
                        width: '100%',
                        minWidth: '44px',
                    }}
                />
            </MuiButton>
        </Box>
    );
});
