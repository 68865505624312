import { ReactElement, useEffect, useId, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import Layer from 'app/components/primitives/layout/Layer';
// TODO(Derek): Refactor getPathByName usage out by passing in "to" / "href" props
import getPathByName from 'app/core/Navigation/getPathByName';
import { Experience } from 'app/core/types';

import { useI18n } from 'i18n';

import useTheme from 'styles/theme';

interface Props {
    /**
     * The experience to switch to
     */
    targetExperience?: Experience | null;

    /**
     * Event handler to be called when user signifies intent to sign out
     */
    onSignOut: () => void;

    /**
     * Event handler to be called when the user initiates switching the experience
     */
    onSwitchExperience?: (event: { value: Experience | null }) => void;
}

/**
 * Ensures the menu option for switching target experience does not change when the menu is closed.
 * Update should still be accepted for as long as the menu is open. This is to avoid a flash of the
 * updated target experience when the experience is being switched and the menu is closed.
 */
function useUpdateTargetExperienceOnOpenOnly({
    isOpen,
    targetExperience,
}: {
    isOpen: boolean;
    targetExperience?: Experience | null;
}): Experience | null {
    const [cachedTargetExperience, setTargetExperience] = useState<Experience | null>(targetExperience ?? null);

    useEffect(() => {
        if (isOpen) {
            setTargetExperience(targetExperience ?? null);
        }
    }, [isOpen, targetExperience]);

    return cachedTargetExperience;
}

/**
 * Responsible for layout and behavior of the user settings dropdown menu
 */
export default function SettingsDropdown({ onSignOut, onSwitchExperience, targetExperience }: Props): ReactElement {
    const { t } = useI18n();
    const theme = useTheme();

    const id = useId();
    const [isOpen, setIsOpen] = useState(false);
    const trigger = useRef(null);

    const cachedTargetExperience = useUpdateTargetExperienceOnOpenOnly({ isOpen, targetExperience });

    return (
        <Layer anchor>
            <Button
                ctaType="secondary"
                circle={false}
                ref={trigger}
                aria-haspopup="true"
                aria-controls={id}
                aria-expanded={isOpen ? 'true' : undefined}
                value=""
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                icon={<Icon name="person-circle" />}
            />

            <Menu
                id={id}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                anchorEl={trigger.current}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                disableScrollLock
                elevation={4}
                sx={{ mt: 2 }}
                MenuListProps={{
                    sx: {
                        borderRadius: `${theme.shape.borderRadius}px`,
                        width: '160px',
                    },
                }}
            >
                <Stack divider={<Divider sx={{ m: '0 !important' }} />}>
                    <MenuItem component={RouterLink} to={getPathByName('PROFILE')} onClick={() => setIsOpen(false)}>
                        {t('primary_navigation.settings')}
                    </MenuItem>

                    {!!cachedTargetExperience && (
                        <MenuItem
                            onClick={() => {
                                setIsOpen(false);
                                onSwitchExperience?.({ value: cachedTargetExperience });
                            }}
                        >
                            {t(`primary_navigation.setting_switch_to.${cachedTargetExperience?.toLowerCase()}`)}
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={() => {
                            onSignOut();
                            setIsOpen(false);
                        }}
                    >
                        {t('primary_navigation.sign_out')}
                    </MenuItem>
                </Stack>
            </Menu>
        </Layer>
    );
}
