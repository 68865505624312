import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import heroImageUrl from 'app/assets/images/background-1.jpg';
import MoxLogo from 'app/components/primitives/MoxLogo';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

/**
 * EntryWay is the common layout shared by all authN pages (i.e. signup, signin, password reset).
 */
export default function EntryWay({ title, message, children }) {
    const { t } = useI18n();

    return (
        <Box component={Text} height="100vh" variant="body">
            <Grid container overflow="auto">
                <Grid item xs={12} md={7} lg={5}>
                    <Stack
                        sx={{
                            p: { xs: 5, md: 8 },
                            minHeight: '100vh',
                            backgroundColor: 'common.white',
                        }}
                        justifyContent="space-between"
                    >
                        <header>
                            <MoxLogo />
                        </header>

                        <Box mx="auto" my={8} width={{ xs: '80%', md: '65%', xl: '55%' }}>
                            <Text variant="data" as="h1" mb={6}>
                                {title}
                            </Text>

                            {!!message && (
                                <Text variant="body" as="p" mb={6} color="secondary">
                                    {message}
                                </Text>
                            )}

                            <Stack>{children}</Stack>
                        </Box>

                        <Box component="footer" display="flex" justifyContent="center">
                            <Stack direction="row" spacing={7}>
                                <Link variant="detail">{t('signin_page.terms_of_service')}</Link>
                                <Link variant="detail">{t('signin_page.privacy_policy')}</Link>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={7}
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'black',
                        display: { xs: 'none', md: 'flex' },
                        position: 'fixed',
                        top: 0,
                        right: 0,
                    }}
                >
                    <Box
                        sx={{
                            background: `url("${heroImageUrl}") no-repeat`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            minHeight: '100vh',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Stack p={7}>
                            <Text variant="h1" as="pre" sx={{ overflow: 'hidden' }}>
                                {t('signin_page.moxion_motto')}
                            </Text>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
