import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import {
    formatDimensionedQuantity,
    formatDimensions,
    formatQuantity,
    formatTolerance,
    formatVoltage,
} from './formatDimensionedQuantity';
import { DimensionedQuantity, PhysicalDimensions, VoltageMode } from './types';

interface Props extends BoxProps {
    /**
     * The supported voltage modes and associated system limits
     */
    configurations: Omit<VoltageMode, 'runtime'>[];

    /**
     * Comparaable Diesel Generator KVA rating
     */
    dieselGeneratorComp: DimensionedQuantity;

    /**
     * System frequency (HZ)
     */
    frequency: DimensionedQuantity;

    /**
     * The units physical dimensions
     */
    unitDimensions: PhysicalDimensions;

    /**
     * The unit + trailer physical dimensions
     */
    outsideDimensions: PhysicalDimensions;

    /**
     * The unit + trailers weight
     */
    totalWeight: DimensionedQuantity;
}

/**
 * Responsible for displaying unit specifications, electrical and physical characteristics
 */
export default function DeviceSpecDetails({
    dieselGeneratorComp,
    frequency,
    unitDimensions,
    outsideDimensions,
    totalWeight,
    configurations,
    ...props
}: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Box id="spec-tabbed-content" role="tabpanel">
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.voltage_label')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={x.voltage.value} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatVoltage(x.voltage)}</Text>
                    </Grid>
                ))}

                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.voltage_tolerance_label')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={x.voltage.value} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatTolerance(x.voltageTolerance)}</Text>
                    </Grid>
                ))}

                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.continuous_power_label')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={x.continuousPower.value} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatDimensionedQuantity(x.continuousPower)}</Text>
                    </Grid>
                ))}

                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.max_power_label')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={x.maxPower.value} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatDimensionedQuantity(x.maxPower)}</Text>
                    </Grid>
                ))}

                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.max_phase_current_label')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={`${x.voltage.value}${x.maxPhaseCurrent.value}`} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatDimensionedQuantity(x.maxPhaseCurrent)}</Text>
                    </Grid>
                ))}

                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.max_neutral_current_label')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={`${x.voltage.value}${x.maxNeutralCurrent.value}`} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatDimensionedQuantity(x.maxNeutralCurrent)}</Text>
                    </Grid>
                ))}

                <Grid item xs={4}>
                    <Text as="p">{t('renter_reservation_form.power_factor')}</Text>
                </Grid>

                {configurations.slice(0, 3).map((x, i) => (
                    <Grid key={`${x.voltage.value}${x.powerFactor.value}`} item xs={i === 2 ? 2 : 3}>
                        <Text color="secondary">{formatQuantity(x.powerFactor, { decimalPlaces: 1 })}</Text>
                    </Grid>
                ))}
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={4}>
                <Box display="flex" gap={1} flexWrap="wrap">
                    <Text as="p">{t('renter_reservation_form.diesel_generator_comp_label')}</Text>
                    <Text color="secondary">{formatDimensionedQuantity(dieselGeneratorComp)}</Text>
                </Box>

                <Box display="flex" gap={2} flexWrap="wrap">
                    <Text as="p">{t('renter_reservation_form.frequency_label')}</Text>
                    <Text color="secondary">{formatDimensionedQuantity(frequency)}</Text>
                </Box>

                <Box display="flex" gap={2} flexWrap="wrap">
                    <Text as="p">{t('renter_reservation_form.unit_dimensions_label')}</Text>
                    <Text color="secondary">{formatDimensions(unitDimensions)}</Text>
                </Box>

                <Box display="flex" gap={2} flexWrap="wrap">
                    <Text as="p">{t('renter_reservation_form.unit_dimensions_with_trailer_label')}</Text>
                    <Text color="secondary">{formatDimensions(outsideDimensions)}</Text>
                </Box>

                <Box display="flex" gap={2} flexWrap="wrap">
                    <Text as="p">{t('renter_reservation_form.unit_weight_with_trailer_label')}</Text>
                    <Text color="secondary">{formatDimensionedQuantity(totalWeight)}</Text>
                </Box>
            </Stack>
        </Box>
    );
}
