import { ReactElement } from 'react';

import { IconName } from 'app/assets/icons/types';
import Icon from 'app/components/primitives/Icon';
import { CellSignalBar } from 'app/core/data';

interface Props {
    /**
     * number of bars
     */
    numberOfBars?: CellSignalBar | null;

    /**
     * The icon size
     */
    size?: 'sm' | 'md' | 'lg';
}

function getIconForBars(numberOfBars?: CellSignalBar | null): IconName {
    if (numberOfBars == null || numberOfBars === CellSignalBar.Disconnected) {
        return 'cellular-off';
    }

    return `cellular-${numberOfBars}`;
}

/**
 * Illustrates signal strength graphically
 */
export default function SignalStrength({ numberOfBars, size }: Props): ReactElement {
    return <Icon name={getIconForBars(numberOfBars)} size={size} />;
}
