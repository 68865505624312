import { ReactElement } from 'react';
import { XAxis } from 'recharts';

interface Props {
    /**
     * Whether the axis shoudl be hidden
     */
    hide: boolean;

    /**
     * format function for producing the tick text
     */
    formatTimeAxis: (ts: Date) => string;

    start: number;

    end: number;
}

/**
 * Render function to produce the time axis for historical faults chart
 * Note: this needs to be a render function for ReCharts to work properly.
 */
export default function renderTimeAxis({ hide, formatTimeAxis, start, end }: Props): ReactElement {
    return (
        <XAxis
            hide={hide}
            scale="time"
            axisLine={false}
            padding={{ left: 8, right: 8 }}
            type="number"
            minTickGap={36}
            tickCount={20}
            domain={[start, end]}
            textAnchor="middle"
            angle={0}
            height={30}
            tickFormatter={formatTimeAxis}
        />
    );
}
