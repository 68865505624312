import { ComponentProps } from 'react';

import MPUTile from 'app/components/compounds/MPUTile';
import { isMPUTileAssignment, MPUTileAssignment, MPUTileHealth } from 'app/components/compounds/MPUTileContent';
import { CellSignalBar, isRunMode } from 'app/core/data';

import { Assignment } from 'generated/graphql';

import { MPU } from './types';

function getMPUTileAssignment(assignment: Assignment | undefined): MPUTileAssignment | undefined {
    const resolvedAssignment = assignment ?? MPUTileAssignment.Available;

    return isMPUTileAssignment(resolvedAssignment) ? resolvedAssignment : undefined;
}

function getMPUTileHealth({
    cellSignalBars,
    criticalFaultCodes,
    nonCriticalFaultCodes,
}: {
    cellSignalBars;
    criticalFaultCodes: MPU['criticalFaultCodes'];
    nonCriticalFaultCodes: MPU['nonCriticalFaultCodes'];
}): MPUTileHealth {
    if (criticalFaultCodes?.length) {
        return MPUTileHealth.CriticalFault;
    }
    if (cellSignalBars === CellSignalBar.Disconnected) {
        return MPUTileHealth.Disconnected;
    }
    if (nonCriticalFaultCodes?.length) {
        return MPUTileHealth.NonCriticalFault;
    }

    return MPUTileHealth.NoFault;
}

/**
 * Converts an `MPU` to the props required by `MPUTile`.
 *
 * TODO(Morris): Implement `firmwareVersion`
 */
export function mpuToMPUTileProps(mpu: MPU): ComponentProps<typeof MPUTile> {
    const {
        cellSignalBars,
        criticalFaultCodes,
        currentAssignmentValue,
        externalID,
        id,
        mode,
        modelName,
        name,
        nonCriticalFaultCodes,
        ownerName,
        serialNumber,
        serviceAreaName,
        stateOfCharge,
        timeToEmpty,
    } = mpu;

    return {
        assignment: getMPUTileAssignment(currentAssignmentValue),
        cellSignalBars: cellSignalBars ?? undefined,
        criticalFaultCodes,
        externalID,
        health: getMPUTileHealth({
            cellSignalBars,
            criticalFaultCodes,
            nonCriticalFaultCodes,
        }),
        mode: isRunMode(mode) ? mode : undefined,
        modelName: modelName ?? undefined,
        mpuID: id,
        mpuName: name,
        nonCriticalFaultCodes,
        ownerName,
        serialNumber,
        serviceAreaName,
        stateOfCharge: stateOfCharge ?? undefined,
        timeToEmpty: timeToEmpty ?? undefined,
    };
}
