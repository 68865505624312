import { ReactElement } from 'react';
import { TOptions } from 'i18next';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { Button, Cluster, IconAndText, Tag, Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { MUIMarginProps } from 'styles/theme/types';

import useActiveAlertSummary from './useActiveAlertsSummary';

interface Props extends MUIMarginProps {
    /**
     * The location of the alerts page
     */
    viewAllHref?: string;
}

function resolveTagPropsBySeverity(severity): {
    color: 'warning' | 'danger';
    outlined: boolean;
} {
    if (/MAJOR/i.test(severity)) {
        return {
            color: 'warning',
            outlined: false,
        };
    }

    return {
        color: 'danger',
        outlined: false,
    };
}

/**
 * Displays an alert summary for reservations
 */
export default function ActiveAlertSummary({ viewAllHref, ...props }: Props): ReactElement {
    const { t } = useI18n();

    const _t = (key: string, options?: TOptions) => t(`active_alert_summary.${key}`, options);

    const { totalAlerts, counts } = useActiveAlertSummary();

    return (
        <Box
            component={Paper}
            variant="outlined"
            p={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            {...props}
        >
            <Cluster
                gap={0}
                sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box my={2} ml={1}>
                    <Text variant="h4">{_t('total_alerts', { count: totalAlerts })}</Text>
                    <Text>{_t('reservations_subtitle')}</Text>
                </Box>

                <Cluster gap={5}>
                    {counts.map(x => (
                        <IconAndText
                            key={x.severity}
                            icon={<Tag {...resolveTagPropsBySeverity(x.severity)} label={x.count} />}
                            text={<Text>{_t(`severity.${x.severity.toLowerCase()}`)}</Text>}
                        />
                    ))}
                </Cluster>

                {/* Counter balance to achieve proper responsive layout */}
                <Box />
            </Cluster>

            <Button ctaType="secondary" to={viewAllHref}>
                {_t('view_all_cta')}
            </Button>
        </Box>
    );
}
