import { AccountRoleName } from 'app/core/Session';

import { useAddAccountRolesMutation, useRemoveAccountRolesMutation } from 'generated/graphql';

/**
 * Hook to update AccountRoles for an Account. The APIs are add/remove rather than update, so the
 * currnetRoles parameter is used to diff current from desired and split them into add/remove.
 * @param accountID ID of the account in question
 * @param currentRoles set of current roles for account
 */
export function useUpdateAccountRoles(accountID: string, currentRoles: AccountRoleName[]) {
    const [addAccountRolesMutation] = useAddAccountRolesMutation();
    const [removeAccountRolesMutation] = useRemoveAccountRolesMutation();

    const updateAccountRoles = async (wantedRoles: string[]): Promise<boolean> => {
        const toAdd = wantedRoles.filter(wanted => !currentRoles.includes(wanted as AccountRoleName));
        const toRemove = currentRoles.filter(have => !wantedRoles.includes(have));

        if (toAdd.length > 0) {
            await addAccountRolesMutation({
                variables: {
                    input: { accountID, accountRoleNames: toAdd },
                },
            });
        }

        if (toRemove.length > 0) {
            await removeAccountRolesMutation({
                variables: {
                    input: { accountID, accountRoleNames: toRemove },
                },
            });
        }
        return toAdd.length > 0 || toRemove.length > 0;
    };

    return updateAccountRoles;
}
