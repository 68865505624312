import { useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { Link, Text } from 'app/components';

import { getSupportEmail } from 'environment';

import { useI18n } from 'i18n';

interface SignInFormValues {
    email: string;
    password: string;
}

interface Props {
    /**
     * Passed email to use as initial value
     */
    email?: string | null;
    /**
     * An error message to show to the user, pass falsey value to suppress
     */
    errorMessage?: string | null;
    /**
     * An informational message to show to the user, pass falsey value to suppress
     */
    infoMessage?: string | null;
    /**
     * Event handler to signify when a user initiates the reset password flow. Only
     * valid emails will be passwed.
     */
    onForgotPassword: (result: { email: string }) => Promise<void>;
    /**
     * Event handler to signify when a user has completed the form and all form fields are
     * valid.
     */
    onSubmit: (result: { values: SignInFormValues }) => Promise<void>;
}

export default function SignInForm({ email, infoMessage, errorMessage, onSubmit, onForgotPassword }: Props) {
    const { t } = useI18n();
    const [message, setMessage] = useState<string>('');

    const emailSchema = string()
        .email(t('signin_page.email_invalid_message'))
        .required(t('signin_page.email_required_message'));
    const formik = useFormik<SignInFormValues>({
        enableReinitialize: true,

        initialValues: {
            email: email ?? '',
            password: '',
        },

        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: object().shape({
            email: emailSchema,
            password: string().required(t('signin_page.password_required_message')),
        }),

        async onSubmit(values) {
            await onSubmit({ values });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Stack>
                    <FormLabel error={!!formik.errors.email} htmlFor="email-input-1" sx={{ mb: 1 }}>
                        {t('signin_page.email_label')}
                    </FormLabel>
                    <TextField
                        fullWidth
                        id="email-input-1"
                        size="small"
                        helperText={formik.errors.email ?? ' '}
                        name="email"
                        autoComplete="username"
                        error={!!formik.errors.email}
                        value={formik.values.email}
                        onChange={event => {
                            if (formik.touched.email && formik.errors.email) {
                                formik.validateField('email');
                            }

                            formik.handleChange(event);
                        }}
                        onFocus={() => {
                            formik.setFieldTouched('email');
                        }}
                        onBlur={() => {
                            formik.validateField('email');
                            setMessage('');
                        }}
                    />
                </Stack>

                <Stack>
                    <FormLabel error={!!formik.errors.password} htmlFor="password-input-1" sx={{ mb: 1 }}>
                        {t('signin_page.password_label')}
                    </FormLabel>
                    <TextField
                        fullWidth
                        id="password-input-1"
                        name="password"
                        value={formik.values.password}
                        size="small"
                        type="password"
                        autoComplete="current-password"
                        error={!!formik.errors.password}
                        helperText={formik.errors.password ?? ' '}
                        onChange={formik.handleChange}
                        onBlur={() => {
                            formik.validateField('password');
                        }}
                    />
                </Stack>
            </Stack>

            <Box mt={0} mb={5} display="flex" justifyContent="flex-end">
                <Link
                    variant="detail"
                    onClick={() => {
                        if (emailSchema.isValidSync(formik.values.email)) {
                            onForgotPassword({ email: formik.values.email });
                        } else {
                            setMessage(t('signin_page.forgot_password_missing_email'));
                        }

                        formik.setFieldError('password', undefined);
                    }}
                >
                    {t('signin_page.forgot_password')}
                </Link>
            </Box>

            {message && (
                <Alert sx={{ mb: 4 }} severity="warning">
                    {message}
                </Alert>
            )}

            {infoMessage && (
                <Alert sx={{ mb: 4 }} severity="info">
                    {infoMessage}
                </Alert>
            )}

            {/**
             * We supress signin errors if the infoMessage is present which represents instructions on
             * how to complete a password reset
             */}
            {!infoMessage && errorMessage && (
                <Alert sx={{ mb: 2 }} severity="error">
                    <Text
                        variant="detail"
                        as="p"
                        color="secondary"
                        renderLink={({ text }) => <Link to={`mailto:${getSupportEmail()}`}>{text}</Link>}
                    >
                        {errorMessage}
                    </Text>
                </Alert>
            )}

            <Button type="submit" variant="contained" fullWidth sx={{ mb: 7 }}>
                {t('cta.sign_in')}
            </Button>
        </form>
    );
}
