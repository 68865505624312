import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { DateTimeSpan, ISO8601 } from 'app/core/types';

import { toggleRowExpansion } from './actions';
import { useScheduleContext, useScheduleDispatch, useScheduleState } from './ScheduleContext';
import { TimeConverter } from './TimeConverter';
import type { ScheduleCellCoordinates, ScheduleCellDetails, ScheduleDays, ScheduleEvent } from './types';

export function useToggleScheduleRowExpansion(rowIndex: number): () => void {
    const dispatch = useScheduleDispatch();

    return useCallback(() => dispatch(toggleRowExpansion({ rowIndex })), [dispatch, rowIndex]);
}

export function useDateRangeDates({
    daysDisplayed,
    historyLength,
    timeConverter,
}: {
    daysDisplayed: number;
    historyLength: number;
    timeConverter: TimeConverter;
}) {
    const makeDateRangeDates = useCallback(
        (columnStartIndex: number): DateTimeSpan => {
            const startDateTime = timeConverter.columnIndexToDateTime(columnStartIndex);
            const start = startDateTime.toISO() ?? '';
            const end = startDateTime.plus({ days: daysDisplayed - 1 }).toISO() ?? '';

            return { end, start };
        },
        [daysDisplayed, timeConverter],
    );

    const [dateRangeDates, setDateRangeDates] = useState<DateTimeSpan>(() => makeDateRangeDates(historyLength));

    const updateDateRange = useCallback(
        (columnStartIndex: number): void => setDateRangeDates(makeDateRangeDates(columnStartIndex)),
        [makeDateRangeDates],
    );

    return [dateRangeDates, updateDateRange] as const;
}

export function useScheduleDaysFromTimeSpan({
    end,
    maxDaysDisplayed,
    minDaysDisplayed,
    paddingDays,
    start,
}: {
    /**
     * The end of the time span to calculate schedule days configuration for.
     */
    end: ISO8601;
    /**
     * The maximum number of days that can be displayed
     */
    maxDaysDisplayed: number;
    /**
     * The minimum number of days that can be displayed
     */
    minDaysDisplayed: number;
    /**
     * The preferred number of days that should use to add spacing to the givin time span.
     * The given number of days is added the before start and after the end of the time span.
     */
    paddingDays: number;
    /**
     * The start of the time span to calculate schedule days configuration for.
     */
    start: ISO8601;
}): ScheduleDays {
    return useMemo(() => {
        // NOTE(will): start/end are matched to start/end of day s.t. other queries/mutations
        // can easily match up their variables to the exact same time for cache coherence.
        const startDateTime = DateTime.fromISO(start).startOf('day');
        const endDateTime = DateTime.fromISO(end).endOf('day');

        const timeSpanDurationInDays = Math.ceil(endDateTime.diff(startDateTime).as('days'));
        const preferredDaysDisplayed = paddingDays + timeSpanDurationInDays + paddingDays;
        const daysDisplayed = Math.max(Math.min(preferredDaysDisplayed, maxDaysDisplayed), minDaysDisplayed);
        const futureLength = Math.max(preferredDaysDisplayed - maxDaysDisplayed, 0);
        const initialDate = startDateTime.minus({ days: paddingDays }).toISO() ?? '';

        return {
            daysDisplayed,
            end,
            futureLength,
            historyLength: 0,
            initialDate,
            start,
        };
    }, [maxDaysDisplayed, minDaysDisplayed, paddingDays, end, start]);
}

export function useScheduleDaysFromOffset({
    daysDisplayed,
    initialDate,
    offset,
}: {
    daysDisplayed: number;
    initialDate: ISO8601;
    offset: number;
}): ScheduleDays {
    return useMemo(() => {
        // NOTE(will): start/end are matched to start/end of day s.t. other queries/mutations
        // can easily match up their variables to the exact same time for cache coherence.
        const initialDateTime = DateTime.fromISO(initialDate).startOf('day');

        return {
            daysDisplayed,
            end: initialDateTime.plus({ days: offset }).toISO() ?? '',
            futureLength: Math.max(offset - daysDisplayed, 0),
            historyLength: offset,
            initialDate: initialDateTime.toISO() ?? '',
            start: initialDateTime.minus({ days: offset }).toISO() ?? '',
        };
    }, [daysDisplayed, initialDate, offset]);
}

/**
 * Creates a callback function used by `MultiGrid` to dynamically determine row heights.
 *
 * The heights of cells are manually updated by calling `MultiGridApi.resetAfterRowIndex`.
 */
export function useRowHeightAccessor({
    collapsedRowHeight,
    currentExpandedRowIndexes,
    expandedRowHeight,
    getRowHeight,
}: {
    collapsedRowHeight: number;
    currentExpandedRowIndexes: number[];
    expandedRowHeight: number;
    getRowHeight: ((rowIndex: number, expandedRowIndexes: number[]) => number) | undefined;
}): (rowIndex: number) => number {
    /**
     * A reference is used to access `expandedRowIndexes`, because we don't want to
     * recreate `rowHeightCallback` when it changes.
     */
    const expandedRowIndexesRef = useRef<number[]>(currentExpandedRowIndexes);

    // Ensure that the reference is always up-to-date whenever the state changes.
    expandedRowIndexesRef.current = currentExpandedRowIndexes;

    // To avoid re-rending the `MultiGrid` component, this function should only be recreated
    // when the defined row heights change.
    return useCallback(
        (rowIndex: number) => {
            const expandedRowIndexes = expandedRowIndexesRef.current;

            if (getRowHeight) {
                return getRowHeight(rowIndex, expandedRowIndexes);
            }

            return expandedRowIndexes.includes(rowIndex) ? expandedRowHeight : collapsedRowHeight;
        },
        [expandedRowHeight, getRowHeight, collapsedRowHeight],
    );
}

/**
 * Creates a unique array containing every row index from the given events.
 */
export function useScheduleEventRowIndexes(events: ScheduleEvent[]): number[] {
    return useMemo(() => Array.from(new Set(events.map(({ rowIndex }) => rowIndex))), [events]);
}

/**
 * Access the schedule context to determine whether the given row is expanded.
 */
export function useIsScheduleRowExpanded(rowIndex: number): boolean {
    return useScheduleState(({ expandedRowIndexes }) => expandedRowIndexes.includes(rowIndex));
}

/**
 * Asynchronously retrieves cell details for the given coordinates
 */
export function useCellDetails({ columnIndex, rowIndex }: ScheduleCellCoordinates): ScheduleCellDetails {
    const getCellDetails = useScheduleContext(payload => payload.data.getCellDetails);
    const [cellDetails, setCellDetails] = useState<ScheduleCellDetails>({
        layers: [],
        markers: [],
        ranges: [],
    });

    useEffect(() => {
        getCellDetails({ columnIndex, rowIndex }, { waitForAnimationFrame: true }).then(setCellDetails);
    }, [columnIndex, getCellDetails, rowIndex]);

    return cellDetails;
}
