import { ComponentProps } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import { useListMpUsForSelectorQuery } from 'generated/graphql';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {
    ownerAccountIDs: string[];
}

const MultiMPUSelector = ({ ownerAccountIDs, ...props }: Props) => {
    const { data } = useListMpUsForSelectorQuery({ variables: { ownerAccountIDs } });

    const options = data?.listDeviceInstances?.edges?.map(({ node }) => ({ label: node.name, id: node.name })) || [];

    return <MultiSelect {...props} label="MPU" placeholder="Select MPU" options={options} />;
};

export default MultiMPUSelector;
