import CSSDimension from 'design-system/CSSDimension';
import SPACING from 'design-system/spacing';

export enum ScheduleEventVariant {
    /**
     * Translucent background, dashed borders with color, icon/text with color.
     * If available, the `secondaryColor` property will be used as the event bar's background,
     * instead of the `color` property with alpha adjusted.
     */
    Ghost = 'Ghost',
    /**
     * No background, dashed borders with color, icon/text with color.
     */
    Outline = 'Outline',
    /**
     * Opaque background color, no borders, and white icon/text.
     */
    Solid = 'Solid',
}

export const DEFAULT_DAYS_DISPLAYED = 14;
export const DEFAULT_FUTURE_LENGTH = 90;
export const DEFAULT_HISTORY_LENGTH = 90;

export const BAR_HEIGHT = CSSDimension.fromPixels(24);
export const DATE_RANGE_HEIGHT = CSSDimension.fromPixels(48);
/** Space between the date range and grid */
export const VERTICAL_SPACING = SPACING.lg;
export const TIME_CELL_HEIGHT = CSSDimension.fromPixels(48);

export const DEFAULT_COLLAPSED_ROW_HEIGHT = CSSDimension.fromPixels(32);
export const DEFAULT_EXPANDED_ROW_HEIGHT = CSSDimension.fromPixels(96);

export const EXPANSION_TRANSITION_DURATION = '300ms';
export const EXPANSION_TRANSITION_EASE = 'ease-out';
/**
 * @type {import('framer-motion').Tween}
 *
 * `Tween` is exported from `framer-motion`, but for whatever reason
 * importing the type causes the Storybook dependency named `react-docgen-typescript`,
 * to crash the entire process.
 */
export const EXPANSION_TRANSITION_TWEEN = {
    type: 'tween',
    duration: 0.3,
    ease: 'easeOut',
};
