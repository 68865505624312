import { ReactElement } from 'react';
import { Duration, DateTime as LuxonDateTime } from 'luxon';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Text from 'app/components/primitives/Text';

import { useI18n, useRelativeTime } from 'i18n';

interface PopupContentProps {
    /**
     * Name of unit
     */
    name: string;

    /**
     * The units model name (i.e. "MP-75")
     */
    modelName?: string | null;

    /**
     * The (estimated) time to empty of the unit in seconds
     */
    timeToEmpty?: number | null;

    /**
     * The state of charge (%) of the unit
     */
    stateOfCharge?: number | null | undefined;

    /**
     * The alert threshold for state of charge
     */
    socAlertBoundary: number;

    /**
     * The time of the location (lat, lon) shown on the map
     */
    locationTimestamp?: string | null | undefined;

    /**
     * link location to view details
     */
    href?: string;
}

export default function UnitPopupContent({
    name,
    modelName,
    stateOfCharge,
    timeToEmpty,
    socAlertBoundary,
    locationTimestamp,
    href,
}: PopupContentProps): ReactElement {
    const { t, format } = useI18n();
    const _modelName = !modelName ? '' : `(${modelName})`;
    const { relativeTime } = useRelativeTime(
        LuxonDateTime.fromISO(locationTimestamp || ''),
        Duration.fromObject({ seconds: 30 }).as('milliseconds'),
    );

    return (
        <Box px={5} py={4}>
            <Text as="p" mb={2}>
                {name} {_modelName}
            </Text>

            <Box display="flex" sx={{ flexWrap: 'wrap', gap: 2 }}>
                <Text as="p" color="secondary">
                    {t('metric.soc.label')}:&nbsp;
                </Text>
                <Text as="p" color={(stateOfCharge ?? 100) < socAlertBoundary ? 'danger' : undefined} fontWeight="bold">
                    {format.soc(stateOfCharge).toString()}
                </Text>
            </Box>

            <Box display="flex" sx={{ flexWrap: 'wrap', gap: 2 }}>
                <Text as="p" color="secondary">
                    {t('metric.s_time_to_empty.label')}:&nbsp;
                </Text>
                <Text as="p" fontWeight="bold">
                    {format.ette(timeToEmpty).toString()}
                </Text>
            </Box>

            {(!!locationTimestamp || !!href) && (
                <Box display="flex" flexWrap="wrap" gap={1} justifyContent="space-between" mt={2}>
                    {!!href && (
                        <Link component={RouterLink} to={href}>
                            {t('metric.see_details')}
                        </Link>
                    )}

                    {!!locationTimestamp && (
                        <Text variant="detail">{t('unit_map.popup.last_updated', { relativeTime })}</Text>
                    )}
                </Box>
            )}
        </Box>
    );
}
