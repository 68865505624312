import Tooltip from '@mui/material/Tooltip';
import { DataGridProps, GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { DataGrid, Text } from 'app/components';
import { byStartingTimestamp } from 'app/core/date-time';
import { ReservationItem } from 'app/pages/RenterAccountDashboard/useRenterDashboardData';

import { useI18n } from 'i18n';

import { UnitWarning } from './UnitWarning';

interface ReservationItemTableProps {
    reservationItems: ReservationItem[];

    onRowClick?: DataGridProps['onRowClick'];
}

export function ReservationItemTable({ reservationItems, onRowClick }: ReservationItemTableProps) {
    const { t, format } = useI18n();

    const rows: GridRowsProp<ReservationItem> = reservationItems.slice();
    const columns: GridColDef<ReservationItem>[] = [
        {
            field: 'reservationName',
            headerName: t('renter_dashboard.reservation_item_table.heading.reservation'),
            flex: 2,
            valueGetter: ctx => ctx.row.reservationName ?? ctx.row.deliveryLocation,
        },
        {
            field: 'assignedDevice.name',
            headerName: t('renter_dashboard.reservation_item_table.heading.unit'),
            flex: 1,
            valueGetter: ctx =>
                `${ctx.row.name} (${ctx.row.hasAssignment ? ctx.row.officialName : t('unit_allocation.unassigned')})`,
        },
        {
            field: 'currentLocation',
            headerName: t('renter_dashboard.reservation_item_table.heading.location'),
            flex: 2,
            valueGetter: ctx => ctx.row.currentLocation,
            valueFormatter: ctx => {
                return format.latLong(ctx.value);
            },
        },
        {
            field: 'interval',
            headerName: t('renter_dashboard.reservation_item_table.heading.dates'),
            flex: 2,
            valueGetter: ctx => ctx.row,
            valueFormatter: ctx => {
                const ri: ReservationItem = ctx.value;
                return format.dateRangeDayAndTime(ri);
            },
            sortComparator: byStartingTimestamp,
        },
        {
            field: 'stateOfCharge',
            headerName: t('renter_dashboard.reservation_item_table.heading.soc'),
            flex: 1,
            valueGetter: ctx => ctx.row.stateOfCharge,
            valueFormatter: ctx => {
                const soc: ReservationItem['stateOfCharge'] = ctx.value;
                return format.soc(soc).toString();
            },
            renderHeader: params => (
                <Tooltip title={t('acronym_tooltips.soc')} arrow>
                    <Text variant="detailMedium">{params.colDef.headerName}</Text>
                </Tooltip>
            ),
        },
        {
            field: 'timeToEmpty',
            headerName: t('renter_dashboard.reservation_item_table.heading.ette'),
            flex: 1,
            valueGetter: ctx => ctx.row.timeToEmpty,
            valueFormatter: ctx => {
                const ette: ReservationItem['timeToEmpty'] = ctx.value;
                return format.ette(ette).toString();
            },
            renderHeader: params => (
                <Tooltip title={t('acronym_tooltips.ette')} arrow>
                    <Text variant="detailMedium">{params.colDef.headerName}</Text>
                </Tooltip>
            ),
        },
        {
            field: 'warning',
            headerName: '',
            flex: 0.5,
            renderCell: params => {
                const reservationItem: ReservationItem = params.row;
                return (
                    <UnitWarning
                        reservationID={reservationItem.id}
                        status={reservationItem.status}
                        start={reservationItem.start}
                        end={reservationItem.end}
                    />
                );
            },
        },
    ];

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            sx={{
                minWidth: 1000,
                minHeight: 500,
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
            }}
            autoHeight
            onRowClick={onRowClick}
        />
    );
}
