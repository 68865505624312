import { useId } from 'react';
import { DateTime } from 'luxon';

import { Accordion, AccordionDetails, AccordionSummary } from 'app/components/compounds/Accordion';
import Text from 'app/components/primitives/Text';
import { MetricChartType } from 'app/components/widgets/telemetry/types';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import MetricChartDownloader from '../MetricChartDownloader';
import MetricChart from './MetricChart';

interface Props {
    /**
     * Determines whether the chart is expanded by default
     */
    defaultExpanded?: boolean;

    /**
     * The identifier for a reservationItem to get telemetry by (do not also pass mpuID)
     */
    unitID?: string;

    /**
     * The identifier for a device instance to get telemetry by (do not also pass unitID)
     */
    mpuID?: string;

    /**
     * The chart type, for referencing relevant translations or other type dependent data
     */
    type: MetricChartType;

    /**
     * The start of the interval to fetch data for
     */
    start?: ISO8601 | DateTime;

    /**
     * The end of the interval to fetch data for
     */
    end?: ISO8601 | DateTime;
}

export function MetricChartAccordion(props: Props) {
    const { defaultExpanded, unitID, mpuID, type, start, end } = props;
    const { t } = useI18n();
    const ariaId = useId();

    return (
        <Accordion defaultExpanded={defaultExpanded} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary aria-controls={`${ariaId}-control`} id={`${ariaId}-header`}>
                <Text>{t(`chart.${type}.title`)}</Text>
            </AccordionSummary>

            <AccordionDetails>
                <MetricChart
                    mpuID={mpuID}
                    unitID={unitID}
                    type={type}
                    start={start}
                    end={end}
                    downloadCTA={
                        <MetricChartDownloader
                            unitID={unitID}
                            mpuID={mpuID}
                            includeCharts={[type]}
                            start={start}
                            end={end}
                        />
                    }
                />
            </AccordionDetails>
        </Accordion>
    );
}
