import { Badge } from '@mui/material';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';

interface AlertsItemProps {
    viewAllAlertsHref: string;
    alertCount?: number;
}

export default function AlertsItem({ viewAllAlertsHref, alertCount }: AlertsItemProps) {
    return (
        <Badge
            badgeContent={alertCount}
            color="error"
            sx={{
                '& .MuiBadge-badge': {
                    right: 6,
                    top: 6,
                    borderRadius: 0.5,
                },
            }}
        >
            <Button to={viewAllAlertsHref} icon={<Icon name="warning" />} circle={false} ctaType="secondary" />
        </Badge>
    );
}
