import { MouseEventHandler, ReactElement, useCallback } from 'react';

import { AsideCellProps } from 'app/components/compounds/MultiGrid';
import { CollapsibleAsideCell } from 'app/components/compounds/Schedule';
import { Button } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { MpuInfoTooltipContent } from '../MpuIndexSchedule';
import { UnitAssignmentItemData } from './types';

export function UnitAssignmentAsideCell({ index, style, data }: AsideCellProps<UnitAssignmentItemData>): ReactElement {
    const { t } = useI18n();
    const { onAssignButtonClick, deviceInstances } = data;
    const deviceInstance = deviceInstances.at(index);
    const handleAssignButtonClick = useCallback<MouseEventHandler>(
        () => onAssignButtonClick(index),
        [index, onAssignButtonClick],
    );

    return (
        <CollapsibleAsideCell
            cta={
                <Button ctaType="primary" size="sm" onClick={handleAssignButtonClick}>
                    {t('unit_assignment_schedule.assign')}
                </Button>
            }
            index={index}
            heading={deviceInstance?.name || t('unknown')}
            tooltip={<MpuInfoTooltipContent deviceInstance={deviceInstance} />}
            style={style}
        >
            <CollapsibleAsideCell.Label>{t('unit_assignment_schedule.bar.unavailable')}</CollapsibleAsideCell.Label>
            <CollapsibleAsideCell.Label>{t('unit_assignment_schedule.bar.available')}</CollapsibleAsideCell.Label>
            <CollapsibleAsideCell.Label>{t('unit_assignment_schedule.bar.allocation')}</CollapsibleAsideCell.Label>
        </CollapsibleAsideCell>
    );
}
