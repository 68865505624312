import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { SortDirection, User, UserPaginationFilters, UserStatus } from 'generated/graphql';

const ALLOWED_STRING_OPERATORS = ['contains', 'isAnyOf'];

export default class DataGridToGQLAdaptor {
    private static gridSortToGQLSortMap: { [key: string]: SortDirection } = {
        asc: SortDirection.Asc,
        desc: SortDirection.Desc,
    };
    private static gridFieldNameToGQLFieldMap: { [key: string]: keyof User } = {
        name: 'name',
        email: 'email',
        status: 'status',
    };

    public static getGQLSortBy(sortModel?: GridSortModel): { field: keyof User; direction: SortDirection } {
        if (!sortModel || sortModel.length === 0 || !sortModel[0].sort) {
            return { field: 'name', direction: SortDirection.Asc };
        }

        // NOTE: cannot handle sorting by multiple fields at this point
        return {
            field: this.gridFieldNameToGQLFieldMap[sortModel[0].field],
            direction: this.gridSortToGQLSortMap[sortModel[0].sort],
        };
    }

    public static getGQLFilter(filterModel?: GridFilterModel): UserPaginationFilters {
        if (!filterModel) {
            return {};
        }

        let status: UserStatus | undefined;
        const statusFilterItem = filterModel.items.find(item => item.field === 'status');

        if (statusFilterItem && statusFilterItem.operator === 'is' && statusFilterItem.value) {
            status = statusFilterItem.value;
        }

        let accountIDs: string[] | undefined;
        const accountNameFilterItem = filterModel.items.find(item => item.field === 'accountName');

        if (accountNameFilterItem && accountNameFilterItem.operator === 'is' && accountNameFilterItem.value) {
            accountIDs = [].concat(accountNameFilterItem.value);
        }

        let roleNames: string[] | undefined;
        const rolesFilterItem = filterModel.items.find(item => item.field === 'roles');

        if (rolesFilterItem && rolesFilterItem.operator === 'is' && rolesFilterItem.value) {
            roleNames = [].concat(rolesFilterItem.value);
        }

        let names: string[] | undefined;
        const nameFilterItem = filterModel.items.find(item => item.field === 'name');

        if (nameFilterItem && ALLOWED_STRING_OPERATORS.includes(nameFilterItem.operator) && nameFilterItem.value) {
            names = [].concat(nameFilterItem.value);
        }

        let emails: string[] | undefined;
        const emailFilterItem = filterModel.items.find(item => item.field === 'email');

        if (emailFilterItem && ALLOWED_STRING_OPERATORS.includes(emailFilterItem.operator) && emailFilterItem.value) {
            emails = [].concat(emailFilterItem.value);
        }

        return { status, accountIDs, roleNames, names, emails };
    }
}
