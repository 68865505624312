import { DateTime, Duration } from 'luxon';

import { selectAllocationByID, selectPendingAllocations, selectUnitByID, selectUnits } from './selectors';
import { Allocation, PendingAllocation, PersistedAllocation, ReservationScheduleState, Unit } from './types';

export function getUnitFromRowIndex(state: ReservationScheduleState, rowIndex: number): Unit | undefined {
    return selectUnits(state).at(rowIndex);
}

export function getAllocation(state: ReservationScheduleState, allocationID: string): Allocation | undefined {
    return selectAllocationByID(state)[allocationID];
}

export function getAllocationNeighbors(
    state: ReservationScheduleState,
    allocationID: string,
): { next: Allocation | undefined; prev: Allocation | undefined } {
    const allocation = getAllocation(state, allocationID);

    const empty = {
        next: undefined,
        prev: undefined,
    };

    if (!allocation) return empty;

    const unit = getUnit(state, allocation?.unitID);

    if (!unit) return empty;

    const unitAllocationIndex = unit.allocations.findIndex(({ id }) => id === allocationID);

    return {
        next: unitAllocationIndex === -1 ? undefined : unit.allocations.at(unitAllocationIndex + 1),
        prev: unitAllocationIndex <= 0 ? undefined : unit.allocations.at(unitAllocationIndex - 1),
    };
}

export function getUnitPrevAllocation(state: ReservationScheduleState, allocationID: string): Allocation | undefined {
    return getAllocationNeighbors(state, allocationID).prev;
}

export function getUnitNextAllocation(state: ReservationScheduleState, allocationID: string): Allocation | undefined {
    return getAllocationNeighbors(state, allocationID).next;
}

export function getPendingAllocationIndex(state: ReservationScheduleState, allocationID: string): number | undefined {
    const index = state.pendingAllocations.findIndex(({ id }) => id === allocationID);

    return index === -1 ? undefined : index;
}
export function getRowIndexFromUnitID(state: ReservationScheduleState, unitID: string): number | undefined {
    const rowIndex = selectUnits(state).findIndex(unit => unit.id === unitID);

    return rowIndex < 0 ? undefined : rowIndex;
}

export function getUnit(state: ReservationScheduleState, unitID: string): Unit | undefined {
    return selectUnitByID(state)[unitID];
}

export function getUnitAllocations(state: ReservationScheduleState, unitID: string): Allocation[] {
    return selectUnitByID(state)[unitID]?.allocations ?? [];
}

export function getLatestAllocationForUnit(state: ReservationScheduleState, unitID: string): Allocation | undefined {
    // Allocation should already be sorted by the start date & time.
    const allocations = getUnitAllocations(state, unitID);

    return allocations.at(allocations.length - 1);
}

export function hasGapWithUnitStart(state: ReservationScheduleState, allocationID: string): boolean {
    const allocation = getAllocation(state, allocationID);
    const prevAllocation = getUnitPrevAllocation(state, allocationID);

    if (!allocation || prevAllocation) return false;

    const unit = getUnit(state, allocation.unitID);

    if (!unit) return false;

    const durationBetweenUnitStart = DateTime.fromISO(allocation.start).diff(DateTime.fromISO(unit.start));

    return durationBetweenUnitStart.toMillis() > Duration.fromObject({ minutes: 1 }).toMillis();
}

export function hasGapWithPrevAllocation(state: ReservationScheduleState, allocationID: string): boolean {
    const allocation = getAllocation(state, allocationID);
    const prevAllocation = getUnitPrevAllocation(state, allocationID);

    if (!allocation || !prevAllocation) return false;

    const durationBetweenPrevAllocation = DateTime.fromISO(allocation.start).diff(DateTime.fromISO(prevAllocation.end));

    return durationBetweenPrevAllocation.toMillis() > Duration.fromObject({ minutes: 1 }).toMillis();
}

export function hasGapWithNextAllocation(state: ReservationScheduleState, allocationID: string): boolean {
    const allocation = getAllocation(state, allocationID);
    const nextAllocation = getUnitNextAllocation(state, allocationID);

    if (!allocation || !nextAllocation) return false;

    const durationBetweenNextAllocation = DateTime.fromISO(nextAllocation.start).diff(DateTime.fromISO(allocation.end));

    return durationBetweenNextAllocation.toMillis() > Duration.fromObject({ minutes: 1 }).toMillis();
}

/**
 * Finds a pending allocation that matches the given allocation details.
 */
export function findMatchingPendingAllocation(
    state: ReservationScheduleState,
    match: Pick<PersistedAllocation, 'end' | 'start' | 'unitID'>,
): PendingAllocation | undefined {
    return selectPendingAllocations(state).find(
        allocation =>
            allocation.unitID === match.unitID &&
            DateTime.fromISO(allocation.end).toMillis() === DateTime.fromISO(match.end).toMillis() &&
            DateTime.fromISO(allocation.start).toMillis() === DateTime.fromISO(match.start).toMillis(),
    );
}
