import { ReactElement, useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import { useScheduleDaysFromOffset } from 'app/components/compounds/Schedule/hooks';

import { ScheduleChangeEvent } from '../DowntimePeriodDialog';
import { useScheduleNavigationHandler } from '../MpuSchedule/useScheduleNavigationHandler';
import { useMpuIndexScheduleDeviceInstances } from './hooks';
import { MpuIndexSchedulePresenter } from './MpuIndexSchedulePresenter';

interface MpuIndexScheduleContainerProps {
    /**
     * Total height of the component in pixels.
     */
    height: number;
    /**
     * Determines the starting point of the initially displayed time frame.
     * Determines the initial time frame in combination with the `daysDisplayed` option.
     */
    initialDate?: string;
    /**
     * Total width of the component in pixels.
     */
    width: number;

    mpus: any;
}

/**
 * A widget that wraps the `Schedule` component to display a schedule for a collection of MPUs.
 */
export function MpuIndexScheduleContainer({
    height,
    initialDate: initialDateInput,
    width,
    mpus,
}: MpuIndexScheduleContainerProps): ReactElement {
    const initialDate = useMemo(() => {
        return initialDateInput || (DateTime.now().startOf('day').toISO() ?? '');
    }, [initialDateInput]);
    const daysDisplayed = 14;
    const { end, futureLength, historyLength, start } = useScheduleDaysFromOffset({
        daysDisplayed,
        initialDate,
        offset: 365,
    });

    const { deviceInstances, handleItemsRendered, handleSearchChange, loading, reloadMpuSchedules } =
        useMpuIndexScheduleDeviceInstances({
            end,
            start,
        });

    const handleSubmit = useCallback(
        ({ value: { mpuID } }: ScheduleChangeEvent) => {
            reloadMpuSchedules(mpuID);
        },
        [reloadMpuSchedules],
    );

    const handleScheduleNavigation = useScheduleNavigationHandler({
        onScheduleChange: handleSubmit,
    });

    return (
        <MpuIndexSchedulePresenter
            daysDisplayed={daysDisplayed}
            deviceInstances={mpus || deviceInstances}
            futureLength={futureLength}
            height={height}
            historyLength={historyLength}
            initialDate={initialDate}
            loading={loading}
            onItemsRendered={handleItemsRendered}
            onScheduleNavigation={handleScheduleNavigation}
            onSearchChange={handleSearchChange}
            width={width}
        />
    );
}
