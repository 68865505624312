import { memo, ReactElement } from 'react';

import Box from '@mui/material/Box';

import { BodyCellProps } from '../MultiGrid';
import { useCellDetails } from './hooks';
import { ScheduleCellPresenter } from './ScheduleCellPresenter';
import { ScheduleEventCellLayer } from './ScheduleEventCellLayer';

/**
 * Renders each cell in the body grid.
 */
export const ScheduleEventCell = memo(function ScheduleEventCell({
    columnIndex,
    isScrolling,
    rowIndex,
    style,
}: BodyCellProps): ReactElement {
    const { layers, markers, ranges } = useCellDetails({ columnIndex, rowIndex });

    return (
        <ScheduleCellPresenter markers={markers} ranges={ranges} style={style}>
            <Box position="relative">
                {layers.map((layer, index) => (
                    <ScheduleEventCellLayer
                        columnIndex={columnIndex}
                        isScrolling={isScrolling}
                        // Including the event ID in the `key` is necessary to ensure
                        // that layers for different events don't transition into each
                        // other when event state changes.
                        //
                        // Some funky (kinda cool tbh) animations occur when layers for different events
                        // transition in each other.
                        key={`${columnIndex}${rowIndex}${index}${layer.eventId}`}
                        layer={layer}
                        rowIndex={rowIndex}
                    />
                ))}
            </Box>
        </ScheduleCellPresenter>
    );
});
