import { cloneElement, useRef } from 'react';

import { DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
    /**
     * Controls visibility of dialog
     */
    isOpen: boolean;
    /**
     * Callback called when the dialog is closed by any means
     */
    onClose: () => void;
    /**
     * The Dialog title
     */
    title: string;
    /**
     * A descriptive message or prompt to make it clear what is being confirmed
     */
    message: string;
    /**
     * Any additional context specific UI to render
     */
    details?: JSX.Element | null;
    /**
     * The CTA controlling the confirm action
     */
    confirmCta: JSX.Element | null;
}

/**
 * The ConfirmDialog is used to prompt the user to confirm an action before it is completed.
 * This is especially important for destructive actions or actions that are difficult or impossible
 * to undo.
 */
export default function ConfirmDialog({ isOpen, title, message, details, onClose = () => {}, confirmCta }: Props) {
    const { current: id } = useRef(crypto.randomUUID());

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby={id}>
            <DialogTitle id={id}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText gutterBottom>{message}</DialogContentText>

                {!!details && <DialogContentText component="div">{details}</DialogContentText>}
            </DialogContent>
            <DialogActions sx={{ gap: 1 }}>
                <Button variant="text" onClick={onClose}>
                    Cancel
                </Button>

                {!!confirmCta &&
                    cloneElement(confirmCta, {
                        variant: 'contained',
                        onClick: async () => {
                            const { onClick } = confirmCta?.props;

                            if (typeof onClick === 'function') {
                                await onClick();
                            }

                            onClose();
                        },
                    })}
            </DialogActions>
        </Dialog>
    );
}
