import { useLayer } from 'app/core/layers';

import FilterButton from './FilterButton';
import FilterModal from './FilterModal';
import { FiltersProps } from './types';
import useFilters from './useFilters';

export * from './constants';

export { useFilters };

const Filters = ({ filterKey, ownerAccountIDs, title = '', debug }: FiltersProps) => {
    const { dialog } = useLayer();

    const onFilterButtonClick = () =>
        dialog.add(
            ({ close }) => ({
                filterKey,
                ownerAccountIDs,
                title,
                onClose: close,
                debug,
            }),
            {
                Component: FilterModal,
            },
        );

    return <FilterButton filterKey={filterKey} onClick={onFilterButtonClick} />;
};

export default Filters;
