import { Duration } from 'luxon';

export const SOC_THRESHOLD = 20;

/**
 * SOC is a percentage represented by a number between 0 - 100.
 * A low SOC is one which is at or below 20%.
 */
export function isSOCLow(soc?: number): boolean {
    return typeof soc === 'number' && soc <= SOC_THRESHOLD;
}

/**
 * TTE or Time to Empty is a number representing the predicted number of seconds of runtime left.
 * A low TTE is one which is at or below 2 days of runtime left.
 */
export function isTTELow(tte?: number): boolean {
    return typeof tte === 'number' && Duration.fromObject({ seconds: tte }) <= Duration.fromObject({ days: 2 });
}
