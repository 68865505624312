import { useEffect, useRef } from 'react';

// Taken from https://stackoverflow.com/questions/469357/html-text-input-allow-only-numeric-input
function setInputFilter(
    textbox: HTMLInputElement & { oldValue: string; oldSelectionStart: number | null; oldSelectionEnd: number | null },
    inputFilter: (x: string) => boolean,
    errMsg: string,
) {
    if (!textbox) return () => {};

    const events = ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop', 'focusout'];

    function handleEvent(e: Event) {
        if (inputFilter(textbox.value)) {
            // Accepted value.
            if (['keydown', 'mousedown', 'focusout'].indexOf(e.type) >= 0) {
                textbox.classList.remove('input-error');
                textbox.setCustomValidity('');
            }

            textbox.oldValue = textbox.value;
            textbox.oldSelectionStart = textbox.selectionStart;
            textbox.oldSelectionEnd = textbox.selectionEnd;
        } else if (textbox.hasOwnProperty('oldValue')) {
            // Rejected value: restore the previous one.
            textbox.classList.add('input-error');
            textbox.setCustomValidity(errMsg);
            textbox.reportValidity();
            textbox.value = textbox.oldValue;
            textbox.setSelectionRange(textbox.oldSelectionStart, textbox.oldSelectionEnd);
        } else {
            // Rejected value: nothing to restore.
            textbox.value = '';
        }
    }

    events.forEach(function (event) {
        textbox.addEventListener(event, handleEvent);
    });

    return () => {
        events.forEach(function (event) {
            textbox.removeEventListener(event, handleEvent);
        });
    };
}

/**
 * Sets up an input filter, given a ref to an element and the filter function
 */
export default function useInputFilter({ inputRef, filter, errorMessage }) {
    const stopListening = useRef(() => {});

    useEffect(() => {
        stopListening.current();

        stopListening.current = setInputFilter(inputRef.current, filter, errorMessage);
        // We just need mount / unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef]);
}
