import { ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { GridSortModel } from '@mui/x-data-grid';

import { AlignToText, Button, Cluster, Icon, PageHeader, SectionHeader, Tag, Text } from 'app/components';
import { useAlerts } from 'app/core/Alert';

import { RelativeTime, useI18n } from 'i18n';

import AlertsTable from './AlertsTable';
import { AlertFilter } from './config';
import useAddOrEditFilter from './useAddOrEditFilter';
import useAlertsFilter from './useAlertsFilter';
import useFilterStorage from './useFilterStorage';
import useSeverityLevelLegend from './useSeverityLevelLegend';

export default function AlertsPage(): ReactElement {
    const { t, format } = useI18n();

    const { loading, lastUpdateTimestamp, data } = useAlerts({
        t,
        format,
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const filterStorage = useFilterStorage({ searchParams, setSearchParams });
    const {
        activeFilter,
        customFilterCriteria,
        customFieldFilterCriteria,

        filterAlerts,
        setActiveFilter,
        resetFilter,
        commitCustomFilterCriteria,
        removeFilterCriterion,
    } = useAlertsFilter({ filterStorage });

    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'timestamp', sort: 'asc' }]);

    const alerts = filterAlerts(data);

    const showSeverityLegend = useSeverityLevelLegend();
    const showFilterOptions = useAddOrEditFilter({
        filters: customFilterCriteria,
        onSubmit: ({ value }) => {
            commitCustomFilterCriteria(value);
        },
        onClearFilters: resetFilter,
    });

    return (
        <Box>
            <PageHeader
                title={t('alerts_page.title')}
                subtitle={t('alerts_page.subtitle', {
                    dateTime: <RelativeTime key="last-update-time" time={lastUpdateTimestamp} />,
                })}
            />

            <SectionHeader
                mb={3}
                title={t('alerts_page.alert_count_title', { count: alerts.length })}
                cta={
                    <Button
                        aria-haspopup="dialog"
                        ctaType="secondary"
                        icon={<Icon name="filter" />}
                        onClick={showFilterOptions}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Text variant="inherit">{t('alerts_page.cta.filter')}</Text>

                            {customFieldFilterCriteria.length > 0 ? (
                                <AlignToText>
                                    <Tag label={customFieldFilterCriteria.length} />
                                </AlignToText>
                            ) : null}
                        </Stack>
                    </Button>
                }
            />

            <Cluster mb={5}>
                <Tag
                    name="filter-all"
                    selected={activeFilter === AlertFilter.all}
                    label={
                        activeFilter === AlertFilter.custom
                            ? t('alerts_page.cta.clear_all')
                            : t('alerts_page.quick_filter.all')
                    }
                    outlined
                    onClick={() => {
                        if (activeFilter === AlertFilter.custom) {
                            resetFilter();
                        } else {
                            setActiveFilter('all');
                        }
                    }}
                />

                {activeFilter !== AlertFilter.custom ? (
                    <Tag
                        name="filter-critical"
                        selected={activeFilter === 'critical'}
                        label={t('alerts_page.quick_filter.critical')}
                        outlined
                        onClick={() => setActiveFilter('critical')}
                    />
                ) : null}

                {customFieldFilterCriteria.map(x => (
                    <Tag
                        key={x.id}
                        selected
                        label={x.label}
                        outlined
                        onClick={() => removeFilterCriterion(x)}
                        endIcon={<Icon name="close" />}
                    />
                ))}
            </Cluster>

            <Paper variant="outlined" sx={{ mb: 5 }}>
                <AlertsTable
                    loading={loading}
                    alerts={alerts}
                    sortModel={sortModel}
                    onSortModelChange={sortModel => setSortModel(sortModel)}
                />
            </Paper>

            <Button aria-haspopup="dialog" ctaType="tertiary" onClick={showSeverityLegend}>
                {t('alerts_page.severity_levels_cta_label')}
            </Button>
        </Box>
    );
}
