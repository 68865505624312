import { useState } from 'react';

import { useResetPasswordMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

export default function useResetPassword() {
    const { t } = useI18n();
    const [infoMessage, setInfoMessage] = useState<string>('');

    const [resetPassword] = useResetPasswordMutation();

    return {
        run: async ({ email }) => {
            try {
                await resetPassword({ variables: { email } });
            } catch (error) {
                // TODO(derek): differentiate errors, need API support
            }

            setInfoMessage(t('signin_page.reset_password_sent_message', { email }));
        },
        infoMessage,
    };
}
