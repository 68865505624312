import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { ListAccountsQueryResult, useListAccountsQuery } from 'generated/graphql';

import DataGridToGqlAdaptor from './DataGridToGqlAdaptor';
import { Account, ServerAccount } from './types';

interface Input {
    searchTerm?: string;
    limit?: number;
    cursor?: string;
    sortModel?: GridSortModel;
    filterModel?: GridFilterModel;
}

interface Output extends Pick<ListAccountsQueryResult, 'loading' | 'error' | 'refetch'> {
    data: Account[];
    totalCount: number;
    fetchNextPage: () => void;
    fetchPrevPage: () => void;
}

/**
 * Simplify and prep the returned model from the GQL server for display
 */
export function toAccount(gqlAccount: ServerAccount | null | undefined): Account {
    return { id: gqlAccount?.id ?? '', name: gqlAccount?.name ?? '', industry: gqlAccount?.industry ?? '' };
}

/**
 * Responsible for fetching accounts data from the GQL server and preparing the
 * return data for display
 */
export default function useAccounts({ searchTerm, limit = 1_000_000, cursor, sortModel, filterModel }: Input): Output {
    const { loading, error, data, refetch } = useListAccountsQuery({
        variables: {
            first: limit,
            after: cursor,
            sortBy: DataGridToGqlAdaptor.getGqlSortBy(sortModel),
            filters: DataGridToGqlAdaptor.getGqlFilter(filterModel),
        },
    });

    const accounts =
        data?.listAccounts.edges
            .map(edge => edge.node)
            .map(toAccount)
            .filter(x => new RegExp(searchTerm ?? '', 'ig').test(x.name)) ?? [];

    function fetchNextPage() {
        if (data?.listAccounts.pageInfo.hasNextPage) {
            const nextCursor = data.listAccounts.pageInfo.endCursor;
            refetch({ last: undefined, before: undefined, first: limit, after: nextCursor });
        }
    }

    function fetchPrevPage() {
        if (data?.listAccounts.pageInfo.hasPreviousPage) {
            const prevCursor = data.listAccounts.pageInfo.startCursor;
            refetch({ last: limit, before: prevCursor, first: undefined, after: undefined });
        }
    }

    return {
        loading,
        error,
        refetch,
        data: accounts,
        totalCount: data?.listAccounts.pageInfo.totalEdges ?? 0,
        fetchNextPage,
        fetchPrevPage,
    };
}
