import { DataGrid, DataGridProps } from '@mui/x-data-grid';

import CSSDimension from 'design-system/CSSDimension';

import { useI18n } from 'i18n';

// compact (cellHeightSmall): 36px (same as mui default)

// standard (cellHeightMedium)
const cellHeightMedium = CSSDimension.fromPixels(48).as('rem');

// comfortable (cellHeightLarge)
const cellHeightLarge = CSSDimension.fromPixels(64).as('rem');

const overlayHeight = CSSDimension.fromPixels(500).as('rem');
/**
 * Moxion version of the DataGrid component in MUI
 * @link {DataGrid API Docs | https://v5.mui.com/x/api/data-grid/data-grid/}
 */
export default function LocalizedDataGrid(props: DataGridProps) {
    const { getDataGridLocaleText } = useI18n();
    const localeText = getDataGridLocaleText();

    return (
        <DataGrid
            {...props}
            sx={{
                ...props.sx,
                '--DataGrid-overlayHeight': overlayHeight,
                /**
                 * These styles are defined here because they are not be applied when defined on the Theme.
                 * Important is used to override styles applied inline by the component.
                 */
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-row': {
                    minHeight: `${cellHeightMedium} !important`,
                    maxHeight: `${cellHeightMedium} !important`,
                },

                '&.MuiDataGrid-root--densityStandard  .MuiDataGrid-cell': {
                    minHeight: `${cellHeightMedium} !important`,
                    maxHeight: `${cellHeightMedium} !important`,
                },

                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-row': {
                    minHeight: `${cellHeightLarge} !important`,
                    maxHeight: `${cellHeightLarge} !important`,
                },

                '&.MuiDataGrid-root--densityComfortable  .MuiDataGrid-cell': {
                    minHeight: `${cellHeightLarge} !important`,
                    maxHeight: `${cellHeightLarge} !important`,
                },
            }}
            localeText={localeText}
        />
    );
}
