import { ComponentProps, ComponentPropsWithoutRef, memo, ReactElement, useCallback, useEffect, useRef } from 'react';
import {
    FixedSizeList,
    GridOnItemsRenderedProps,
    GridOnScrollProps,
    ListOnScrollProps,
    VariableSizeGrid,
    VariableSizeList,
} from 'react-window';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import { CallbackOrMutableRef, normalizeRef } from 'app/components/useCombineRefs';
import { withProps } from 'app/components/withProps';

import { BoxWithoutScrollbars } from './BoxWithoutScrollbars';
import { makeMultiGridController } from './controller';
import { getMultiGridDimensions } from './dimensions';
import {
    AsideCellProps,
    BodyCellProps,
    ContentProps,
    HeadingCellProps,
    InnerWrapperProps,
    MaterialGridSizeStrict,
    MultiGridController,
} from './types';

type VariableSizeGridProps = ComponentPropsWithoutRef<typeof VariableSizeGrid>;

/**
 * Component slots for the `MultiGrid` component
 *
 * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slots-prop | MUI Slots}
 */
export interface MultiGridSlots<ItemData = unknown> {
    /**
     * A component for rendering each item in the aside list.
     */
    asideCell?: (props: AsideCellProps<ItemData>) => ReactElement | null;
    /**
     * A component rendered as the scrolling container for the aside list.
     */
    asideOuter?: (props: InnerWrapperProps) => ReactElement | null;
    /**
     * A component for rendering each cell in the body grid.
     */
    bodyCell?: (props: BodyCellProps<ItemData>) => ReactElement | null;
    /**
     * A component rendered as the wrapper for all cell in the body grid.
     */
    bodyInner?: (props: InnerWrapperProps) => ReactElement | null;
    /**
     * A component rendered as the scrolling wrapper for the `bodyInner` component.
     */
    bodyOuter?: (props: InnerWrapperProps) => ReactElement | null;
    /**
     * A component to be rendered in the top-left corner of the grid.
     */
    cornerContent?: (props: ContentProps<ItemData>) => ReactElement | null;
    /**
     * A component for rendering each item in the heading horizontal list.
     */
    headingCell?: (props: HeadingCellProps<ItemData>) => ReactElement | null;
    /**
     * A component rendered as the wrapper for all items in the heading horizontal list.
     */
    headingInner?: (props: InnerWrapperProps) => ReactElement | null;
}

/**
 * Component props for slots in the `MultiGrid` component
 *
 * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slotprops-prop | MUI Slot Props}
 */
export interface MultiGridSlotProps<ItemData = unknown> {
    cornerContent?: Partial<ContentProps<ItemData>>;
}

interface MultiGridProps<ItemData = unknown> {
    /**
     * A MUI grid size to determine the width of the aside.
     */
    asideGridSize?: MaterialGridSizeStrict;
    /**
     * The number of the columns displayed at one time in the body grid.
     * Affects the width of each body cell.
     */
    bodyColumnsDisplayed: number;
    /**
     * The total number of columns in the grid.
     */
    columnCount: number;
    /**
     * A reference to a controller object for interacting with the component.
     */
    controllerRef?: CallbackOrMutableRef<MultiGridController<ItemData> | undefined>;
    /**
     * Enables providing the `isScrolling` prop to body cells.
     *
     * @see VariableSizeGrid.
     */
    enableScrollingUpdates?: ComponentProps<typeof VariableSizeGrid>['useIsScrolling'];
    /**
     * A function called to retrieve the height in pixels of each row.
     */
    getRowHeight: VariableSizeGridProps['rowHeight'];
    /**
     * Height of the heading in pixels.
     */
    headingHeight: number;
    /**
     * Total height of the component in pixels.
     */
    height: number;
    /**
     * Initial left scroll  position in pixels.
     */
    initialScrollLeft?: number;
    /**
     * Initial top scroll  position in pixels.
     */
    initialScrollTop?: number;
    /**
     * Arbitrary data provided to each cell.
     */
    itemData: ItemData;
    /**
     * A function called whenever the body grid renders a different set of cells.
     */
    onItemsRendered?: (props: GridOnItemsRenderedProps) => void;
    /**
     * A function called whenever the body grid is scrolled.
     */
    onScroll?: VariableSizeGridProps['onScroll'];
    /**
     * The total number of rows in the grid.
     */
    rowCount: number;
    /**
     * Props to be provided to each respective slot component.
     */
    slotProps?: MultiGridSlotProps<ItemData>;
    /**
     * Component slots
     *
     * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slots-prop | MUI Slots}.
     */
    slots?: MultiGridSlots<ItemData>;
    /**
     * Total width of the component in pixels.
     */
    width: number;
}

const MultiGridCell = withProps(Box, props => ({
    ...props,
    sx: theme => ({
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
    }),
}));

function HeadingCellDefault({ index, style }: HeadingCellProps): ReactElement {
    return <MultiGridCell style={style}>{index}</MultiGridCell>;
}

function AsideCellDefault({ index, style }: AsideCellProps): ReactElement {
    return <MultiGridCell style={style}>{index}</MultiGridCell>;
}

function BodyCellDefault({ columnIndex, rowIndex, style }: BodyCellProps): ReactElement {
    return (
        <MultiGridCell style={style}>
            {columnIndex}, {rowIndex}
        </MultiGridCell>
    );
}
const MultiGridStyle = {
    // TODO(Morris): Remove to allow the heading to trigger scrolling.
    // This currently fixes a unknown padding issue with the MUI grid component.
    Heading: { overflowX: 'hidden' },
} as const;

function MultiGridComponent<ItemData>(props: MultiGridProps<ItemData>): ReactElement {
    const {
        columnCount,
        controllerRef,
        enableScrollingUpdates,
        getRowHeight,
        initialScrollLeft = 0,
        initialScrollTop = 0,
        itemData,
        onItemsRendered: handleItemsRendered,
        onScroll,
        rowCount,
        slotProps = {},
        slots = {},
    } = props;
    const {
        asideCell: AsideCell = AsideCellDefault,
        asideOuter: AsideOuter = BoxWithoutScrollbars,
        bodyCell: BodyCell = BodyCellDefault,
        bodyInner: BodyInner = Box,
        bodyOuter: BodyOuter = Box,
        cornerContent: CornerContent,
        headingCell: HeadingCell = HeadingCellDefault,
        headingInner: HeadingInner = Box,
    } = slots;

    const headingList = useRef<FixedSizeList<ItemData>>(null);
    const asideList = useRef<VariableSizeList<ItemData>>(null);
    const bodyGrid = useRef<VariableSizeGrid<ItemData>>(null);
    const latestScroll = useRef<GridOnScrollProps | null>(null);

    const handleBodyScroll = useCallback(
        (props: GridOnScrollProps) => {
            onScroll?.(props);

            latestScroll.current = props;

            const { scrollLeft, scrollTop, scrollUpdateWasRequested } = props;

            // NOTE(Morris): `react-window` calls the `onScroll` handlers whenever the lists or grids move;
            // either manually by the end-user, or programmatically by the component.
            // When `react-window` sets `scrollUpdateWasRequested` to `true`, the scroll event
            // was programmatic. For example, it'll result in an infinite loop of scroll events,
            // if we don't ignore programmatic events for animations.
            if (scrollUpdateWasRequested) return;

            if (scrollLeft !== undefined) {
                headingList.current?.scrollTo(scrollLeft);
            }
            if (scrollTop !== undefined) {
                asideList.current?.scrollTo(scrollTop);
            }
        },
        [onScroll],
    );

    const handleAsideScroll = useCallback((props: ListOnScrollProps) => {
        const { scrollOffset, scrollUpdateWasRequested } = props;

        if (scrollUpdateWasRequested) return;

        bodyGrid.current?.scrollTo({ scrollTop: scrollOffset });
    }, []);

    // TODO(Morris): Uncomment when reliance on `overflow-x` is removed.
    // const handleHeadingScroll = useCallback((props: ListOnScrollProps) => {
    //     const { scrollOffset, scrollUpdateWasRequested } = props;

    //     if (scrollUpdateWasRequested) return;

    //     bodyGrid.current?.scrollTo({ scrollLeft: scrollOffset });
    // }, []);

    useEffect(() => {
        if (!controllerRef) return;

        const api = makeMultiGridController({
            asideList,
            bodyGrid,
            headingList,
            initialScrollLeft,
            initialScrollTop,
            latestScroll,
        });

        normalizeRef(controllerRef)(api);
    }, [controllerRef, initialScrollLeft, initialScrollTop]);

    const {
        asideGridSize,
        asideHeight,
        asideWidth,
        bodyCellWidth,
        bodyGridSize,
        bodyHeight,
        bodyWidth,
        containerHeight,
        containerWidth,
        headingCellWidth,
        headingGridSize,
        headingHeight,
        headingWidth,
    } = getMultiGridDimensions(props);

    const getBodyColumnWidth = useCallback(() => bodyCellWidth, [bodyCellWidth]);

    return (
        <Grid container style={{ height: containerHeight, width: containerWidth }}>
            <Grid xs={asideGridSize}>
                {CornerContent && <CornerContent data={itemData} {...slotProps.cornerContent} />}
            </Grid>
            <Grid xs={headingGridSize}>
                <FixedSizeList<ItemData>
                    height={headingHeight}
                    initialScrollOffset={initialScrollLeft}
                    innerElementType={HeadingInner}
                    itemCount={columnCount}
                    itemData={itemData}
                    itemSize={headingCellWidth}
                    layout="horizontal"
                    // TODO(Morris): Uncomment when reliance on `overflow-x` is removed.
                    // onScroll={handleHeadingScroll}
                    outerElementType={BoxWithoutScrollbars}
                    ref={headingList}
                    style={MultiGridStyle.Heading}
                    width={headingWidth}
                >
                    {HeadingCell}
                </FixedSizeList>
            </Grid>
            <Grid xs={asideGridSize}>
                <VariableSizeList<ItemData>
                    height={asideHeight}
                    initialScrollOffset={initialScrollTop}
                    itemCount={rowCount}
                    itemData={itemData}
                    itemSize={getRowHeight}
                    onScroll={handleAsideScroll}
                    outerElementType={AsideOuter}
                    ref={asideList}
                    width={asideWidth}
                >
                    {AsideCell}
                </VariableSizeList>
            </Grid>
            <Grid xs={bodyGridSize}>
                <VariableSizeGrid<ItemData>
                    columnCount={columnCount}
                    columnWidth={getBodyColumnWidth}
                    height={bodyHeight}
                    initialScrollLeft={initialScrollLeft}
                    initialScrollTop={initialScrollTop}
                    innerElementType={BodyInner}
                    itemData={itemData}
                    onItemsRendered={handleItemsRendered}
                    onScroll={handleBodyScroll}
                    outerElementType={BodyOuter}
                    ref={bodyGrid}
                    rowCount={rowCount}
                    rowHeight={getRowHeight}
                    useIsScrolling={enableScrollingUpdates}
                    width={bodyWidth}
                >
                    {BodyCell}
                </VariableSizeGrid>
            </Grid>
        </Grid>
    );
}

/**
 * Renders a grid with a frozen heading row and a frozen aside column.
 */
// This is necessary for `React.memo` to be used with components with generic types.
export const MultiGrid = memo(MultiGridComponent) as typeof MultiGridComponent;
