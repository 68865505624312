import {
    CellAvailability,
    CellAvailabilityCallback,
    CellAvailabilityInfo,
    ScheduleCellCoordinates,
    ScheduleCellLayer,
    ScheduleEvent,
} from './types';

type AvailabilityLayerMixin = (layer: ScheduleCellLayer) => ScheduleCellLayer;
type AvailabilityEventFilter = (params: {
    coordinates: ScheduleCellCoordinates;
    event: ScheduleEvent;
    info: CellAvailabilityInfo;
}) => boolean;

function getAvailabilityColumnIndexes({
    coordinates,
    filterEvent = () => true,
    info,
}: {
    coordinates: ScheduleCellCoordinates;
    filterEvent: AvailabilityEventFilter | undefined;
    info: CellAvailabilityInfo;
}): { endColumnIndex: number; startColumnIndex: number } {
    const { columnIndex } = coordinates;
    const { daysDisplayed, events, futureLength, historyLength, timeConverter } = info;

    /** The start column index of given available cell. */
    const availableCellStartColumnIndex = columnIndex;
    /** The end column index of given available cell. */
    const availableCellEndColumnIndex = columnIndex + 1;
    /** The latest end column index for any event before the start time of the given available cell. */
    let availabilityStartColumnIndex: number | undefined = undefined;
    /** The earliest start column index for any event after the end time of the given available cell. */
    let availabilityEndColumnIndex: number | undefined = undefined;

    for (const event of events) {
        if (!filterEvent({ coordinates, event, info })) continue;

        const eventStartColumnIndex = timeConverter.startTimestampToColumnIndex(event.start);
        const eventEndColumnIndex = timeConverter.endTimestampToColumnIndex(event.end);

        if (
            eventStartColumnIndex < availableCellStartColumnIndex &&
            eventEndColumnIndex <= availableCellStartColumnIndex &&
            (!availabilityStartColumnIndex || eventEndColumnIndex > availabilityStartColumnIndex)
        ) {
            availabilityStartColumnIndex = eventEndColumnIndex;
        }
        if (
            eventStartColumnIndex >= availableCellEndColumnIndex &&
            eventEndColumnIndex > availableCellEndColumnIndex &&
            (!availabilityEndColumnIndex || eventStartColumnIndex < availabilityEndColumnIndex)
        ) {
            availabilityEndColumnIndex = eventStartColumnIndex;
        }
    }

    const lastColumnIndex = historyLength + daysDisplayed + futureLength;
    const FIRST_COLUMN_INDEX = 0;

    return {
        endColumnIndex: availabilityEndColumnIndex ?? lastColumnIndex,
        startColumnIndex: availabilityStartColumnIndex ?? FIRST_COLUMN_INDEX,
    };
}

/** This value is simply a sane default. It should be overridden. */
const DEFAULT_AVAILABILITY_COLOR = 'green';

export const filterEventForOneRow: AvailabilityEventFilter = ({ coordinates, event }) => {
    return event.rowIndex === coordinates.rowIndex;
};

function makeAvailabilityLayerForOneRow({
    coordinates,
    filterEvent,
    info,
}: {
    coordinates: ScheduleCellCoordinates;
    filterEvent: AvailabilityEventFilter | undefined;
    info: CellAvailabilityInfo;
}): ScheduleCellLayer {
    const { rowIndex } = coordinates;
    const { endColumnIndex, startColumnIndex } = getAvailabilityColumnIndexes({
        coordinates,
        filterEvent: filterEvent || filterEventForOneRow,
        info,
    });

    return {
        color: DEFAULT_AVAILABILITY_COLOR,
        eventId: `availability-${startColumnIndex}-${endColumnIndex}-${rowIndex}`,
        eventEndColumnIndex: endColumnIndex,
        eventStartColumnIndex: startColumnIndex,
        isAvailability: true,
        rowIndex,
    };
}

function makeAvailabilityLayerForAllRows({
    availabilityRow,
    coordinates,
    filterEvent,
    info,
}: {
    availabilityRow: number;
    coordinates: ScheduleCellCoordinates;
    filterEvent: AvailabilityEventFilter | undefined;
    info: CellAvailabilityInfo;
}): ScheduleCellLayer {
    const { endColumnIndex, startColumnIndex } = getAvailabilityColumnIndexes({ coordinates, info, filterEvent });

    return {
        color: DEFAULT_AVAILABILITY_COLOR,
        eventId: `availability-${startColumnIndex}-${endColumnIndex}`,
        eventEndColumnIndex: endColumnIndex,
        eventStartColumnIndex: startColumnIndex,
        isAvailability: true,
        rowIndex: availabilityRow,
    };
}

function hasRecognizedLayer({
    coordinates,
    filterEvent,
    info,
    layers,
}: {
    coordinates: ScheduleCellCoordinates;
    filterEvent: AvailabilityEventFilter | undefined;
    info: CellAvailabilityInfo;
    layers: ScheduleCellLayer[];
}): boolean {
    if (!filterEvent) {
        return layers.length > 0;
    }

    const recognizedLayers = layers.filter(layer => {
        const event = info.events.find(({ id }) => id === layer.eventId);

        if (!event) {
            // This shouldn't happen.
            throw new Error('Invalid cell layer.');
        }

        return filterEvent({ coordinates, event, info });
    });

    return recognizedLayers.length > 0;
}

function hasLayerInCell({
    coordinates,
    filterEvent,
    info,
}: {
    coordinates: ScheduleCellCoordinates;
    filterEvent: AvailabilityEventFilter | undefined;
    info: CellAvailabilityInfo;
}): boolean {
    return hasRecognizedLayer({
        coordinates,
        filterEvent,
        info,
        layers: info.cellLayers,
    });
}

function hasLayerInColumn({
    coordinates,
    filterEvent,
    info,
}: {
    coordinates: ScheduleCellCoordinates;
    filterEvent: AvailabilityEventFilter | undefined;
    info: CellAvailabilityInfo;
}): boolean {
    return hasRecognizedLayer({
        coordinates,
        filterEvent,
        info,
        layers: info.columnLayers,
    });
}

/**
 * Provides logic for calculating and rendering availability in the context of one single row.
 * Availability is calculated on a per row basis. For a time frame to considered available for a row,
 * said rows must not have events within said time frame.
 */
export function makeCellAvailabilityForOneRow({
    filterEvent,
    makeLayer,
}: {
    filterEvent?: AvailabilityEventFilter;
    makeLayer?: AvailabilityLayerMixin;
}): CellAvailabilityCallback {
    return async function getCellAvailabilityForOneRow(coordinates, info): Promise<CellAvailability> {
        if (hasLayerInCell({ coordinates, filterEvent, info })) {
            return { isAvailable: false };
        }

        const availabilityLayer = makeAvailabilityLayerForOneRow({
            coordinates,
            filterEvent,
            info,
        });
        const layer = makeLayer ? makeLayer(availabilityLayer) : availabilityLayer;

        return { isAvailable: true, layer };
    };
}

/**
 * Provides logic for calculating and rendering availability in the context of all rows.
 * For a time frame to considered available, every row must not have events within said time frame.
 */
export function makeCellAvailabilityForAllRows({
    availabilityRow,
    filterEvent,
    makeLayer,
}: {
    availabilityRow: number;
    filterEvent?: AvailabilityEventFilter;
    makeLayer?: AvailabilityLayerMixin;
}): CellAvailabilityCallback {
    return async function getCellAvailabilityForAllRows(coordinates, info): Promise<CellAvailability> {
        if (
            coordinates.rowIndex !== availabilityRow ||
            hasLayerInColumn({ coordinates, filterEvent, info }) ||
            hasLayerInCell({ coordinates, filterEvent, info })
        ) {
            return { isAvailable: false };
        }

        const availabilityLayer = makeAvailabilityLayerForAllRows({
            availabilityRow,
            coordinates,
            filterEvent,
            info,
        });
        const layer = makeLayer ? makeLayer(availabilityLayer) : availabilityLayer;

        return { isAvailable: true, layer };
    };
}

/**
 * Prevents the Schedule from rendering availability.
 */
export async function disableCellAvailability(): Promise<CellAvailability> {
    return { isAvailable: false };
}
