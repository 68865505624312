import { FocusEvent, ReactElement, useId, useRef } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import useTheme from '@mui/material/styles/useTheme';

import FieldCaption from 'app/components/primitives/FieldCaption';
import FieldLabel from 'app/components/primitives/FieldLabel';
import Icon from 'app/components/primitives/Icon';

import CSSDimension from 'design-system/CSSDimension';

import { useI18n } from 'i18n';

import useInputFilter from '../useInputFilter';

const INPUT_HEIGHT = CSSDimension.fromPixels(48).as('rem');

function IncrementButton({ children, ...props }: ButtonBaseProps): ReactElement {
    const theme = useTheme();

    return (
        <ButtonBase
            {...props}
            type="button"
            sx={{
                height: INPUT_HEIGHT,
                width: '48px',
                transition: 'background-color 400ms ease, color 400ms ease',
                '&:hover': { backgroundColor: theme.palette.action.hover },
                '&:focus': { backgroundColor: theme.palette.action.focus },
                '&:disabled': { color: theme.palette.text.disabled },
            }}
        >
            {children}
        </ButtonBase>
    );
}

interface Props extends Omit<BoxProps, 'onChange'> {
    /**
     * The name of the field to render in the label
     */
    fieldName?: string;

    /**
     * The unique identifier for the input control, used to associate labels and other accessibility attributes
     */
    id?: string;

    /**
     * Whether the field input is required or not
     */
    required?: boolean;

    /**
     * Whether the field input is readOnly / static or not
     */
    readOnly?: boolean;

    /**
     * Whether the field input is disabled
     */
    disabled?: boolean;

    /**
     * The name associated with the value
     */
    name?: string;

    /**
     * The current set value
     */
    value?: number | null;

    /**
     * The lower limit for the number value
     */
    min?: number;

    /**
     * The upper limit for the number value
     */
    max?: number;

    /**
     * Whether the field has a validation error
     */
    error?: boolean;

    /**
     * Text to contextualize / help the user. When the error prop is true will be styled as an error
     */
    caption?: string | null;

    /**
     * Event handler called when the entire number field component gains focus. Focus moving between internal controls will not trigger this handler.
     */
    onFocus?: (event: FocusEvent) => void;

    /**
     * Event handler called when the entire number field component loses focus. Focus moving between internal controls will not trigger this handler.
     */
    onBlur?: (event: FocusEvent) => void;

    /**
     * Event handler for when the value is updated
     */
    onChange?: (result: { name?: string; value: number | null }) => void;
}

const DEFAULT_VALUE = 1;

/**
 * Controlled input for entering an integer number value
 */
export default function NumberField({
    id,
    fieldName,
    caption,
    name,
    value = DEFAULT_VALUE,
    required = false,
    readOnly = false,
    disabled = false,
    min = 0,
    max = 100,
    error = false,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
}: Props): ReactElement {
    const theme = useTheme();
    const { t } = useI18n();
    const inputRef = useRef();
    const maxLength = max.toString().length;
    const _value = value === null ? DEFAULT_VALUE : value;
    const captionID = useId();
    const fallbackID = useId();
    const inputID = id ?? fallbackID;

    useInputFilter({
        inputRef,
        filter: (v: string): boolean => /^\d*$/.test(v),
        errorMessage: t('number_field.invalid_character_warning'),
    });

    const increment = () => {
        if (_value < max) {
            onChange({ name, value: _value + 1 });
        }
    };

    const decrement = () => {
        if (_value > min) {
            onChange({ name, value: _value - 1 });
        }
    };

    return (
        <Box
            onFocus={event => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    onFocus(event);
                }
            }}
            onBlur={event => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    onBlur(event);
                }
            }}
        >
            <FieldLabel
                fieldName={fieldName}
                htmlFor={inputID}
                error={error}
                disabled={disabled}
                readOnly={readOnly}
                required={readOnly}
                gutterBottom
            />

            <Box
                className={readOnly ? 'Mui-readOnly' : ''}
                onKeyDown={event => {
                    const { key } = event;

                    if (key === 'ArrowUp') {
                        increment();
                        event.preventDefault();
                    } else if (key === 'ArrowDown') {
                        decrement();
                        event.preventDefault();
                    }
                }}
                display="inline-flex"
                sx={{
                    position: 'relative',
                    backgroundColor: disabled ? 'background.disabled.main' : 'background.primary.main',
                    borderRadius: `${theme.shape.borderRadius}px`,

                    '&:not(.Mui-readOnly):after': {
                        pointerEvents: 'none',
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        // Slightly less than 8px to account for the offset
                        borderRadius: 'inherit',

                        outline: `1px solid ${
                            disabled
                                ? theme.palette.background.disabled.main
                                : error
                                ? theme.palette.error.main
                                : theme.palette.divider
                        }`,
                        outlineOffset: '-1px',
                        boxShadow: disabled ? null : theme.customShadows.subtle,
                    },

                    '&:hover:after': {
                        // TODO(derek): add to themes palette. For some reason this color isn't in the palette but is the resultant color on
                        // the TextField controls underline (hover state)
                        outline: disabled
                            ? 'none'
                            : `1px solid ${error ? theme.palette.border.danger : theme.palette.darken[87]}`,
                    },

                    '&:focus-within:after': {
                        boxShadow: error ? theme.customShadows.errorHighlight : theme.customShadows.focusHighlight,

                        outline: `2px solid ${error ? theme.palette.border.danger : theme.palette.border.info}`,
                        outlineOffset: '-2px',
                    },
                }}
            >
                {!readOnly && (
                    <IncrementButton
                        aria-controls={id}
                        aria-label={t('number_field.decrease_button_aria_label')}
                        disabled={disabled || _value <= min}
                        onClick={decrement}
                    >
                        <Icon name="remove" />
                    </IncrementButton>
                )}

                <InputBase
                    className={readOnly ? 'Mui-readOnly' : ''}
                    inputRef={inputRef}
                    required={required}
                    value={value ?? ''}
                    sx={{
                        height: INPUT_HEIGHT,

                        px: 4,
                        borderColor: disabled ? theme.palette.background.disabled.main : theme.palette.divider,
                        borderWidth: '1px',
                        borderLeftStyle: 'solid',
                        borderRightStyle: 'solid',

                        '& > .MuiInputBase-input': {
                            textAlign: 'center',
                            width: `${maxLength}ch`,

                            '&:disabled': {
                                textFillColor: 'text.secondary',
                                color: 'text.secondary',
                            },
                        },

                        '&.Mui-readOnly': {
                            px: 0,
                            borderStyle: 'none',

                            '> .MuiInputBase-input': {
                                textAlign: 'left',
                            },
                        },
                    }}
                    inputProps={{
                        id: inputID,
                        pattern: '\\d*',
                        maxLength,
                        readOnly,
                        disabled,
                        'aria-describedby': captionID,
                    }}
                    onChange={({ target: { value } }) => {
                        const result = parseInt(value, 10);

                        if (result > max) {
                            onChange({ name, value: max });
                        } else if (result < min) {
                            onChange({ value: min });
                        } else {
                            onChange({ name, value: !value ? null : result });
                        }
                    }}
                />

                {!readOnly && (
                    <IncrementButton
                        aria-controls={id}
                        aria-label={t('number_field.increase_button_aria_label')}
                        disabled={disabled || _value >= max}
                        onClick={increment}
                    >
                        <Icon name="add" />
                    </IncrementButton>
                )}
            </Box>

            <FieldCaption id={captionID} caption={caption} error={error} />
        </Box>
    );
}
