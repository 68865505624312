export const getValidFilter = filter => {
    const { fields = {} } = filter;
    const validFields = Object.entries(fields).reduce((acc, [key, value]) => {
        const isArrayOfObjects = Array.isArray(value) && value.length && value.every(v => typeof v === 'object');

        // If no values skip
        if (!value || !isArrayOfObjects) {
            return acc;
        }

        return {
            ...acc,
            [key]: value,
        };
    }, {});

    return {
        ...filter,
        fields: validFields,
    };
};
