import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Layer, ZeroState } from 'app/components';
import { getFormDataFromSearchParams } from 'app/components/widgets/Documents/forms';

import BillOfLadingInternalTemplate from './templates/BillOfLadingInternal';

const ErrorView = () => {
    const navigate = useNavigate();
    return (
        <Layer fill="screen">
            <ZeroState
                title="Invalid Document Type"
                message="Please select a document type and fill out the form to generate a document."
                cta={<Button onClick={() => navigate(-1)}>Go Back</Button>}
            />
        </Layer>
    );
};

const DocumentPage = () => {
    const { documentType } = useParams();
    const data = getFormDataFromSearchParams();

    if (!documentType || !data) return <ErrorView />;

    switch (documentType) {
        case 'bill-of-lading-internal':
            return <BillOfLadingInternalTemplate data={data} />;
        // case 'bill-of-lading-external':
        //     return <BillOfLadingExternalTemplate data={data} />;
        default:
            return <ErrorView />;
    }
};

export default DocumentPage;
