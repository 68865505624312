import { MetricChartType } from 'app/components/widgets/telemetry/types';
import { MetricName } from 'app/core/data';

export default function resolveMetricNamesFromType(type: MetricChartType): MetricName[] {
    // TODO(Morris): Use a `Record` object or `Map` instead. e.g. `METRIC_TELEMETRY_MAPPING_V1`
    switch (type) {
        case MetricChartType.LineLoads:
            return [
                MetricName.Line1LoadPower,
                MetricName.Line2LoadPower,
                MetricName.Line3LoadPower,
                MetricName.TotalSinglePhasePowerDraw,
            ];
        case MetricChartType.LineVoltages:
            return [
                MetricName.Line1LoadVoltage,
                MetricName.Line2LoadVoltage,
                MetricName.Line3LoadVoltage,
                MetricName.SinglePhaseVoltage,
            ];
        case MetricChartType.LineCurrents:
            return [
                MetricName.Line1LoadCurrent,
                MetricName.Line2LoadCurrent,
                MetricName.Line3LoadCurrent,
                MetricName.NeutralCurrent,
                MetricName.SinglePhaseCurrent,
            ];
        case MetricChartType.Power:
            return [MetricName.NetPower, MetricName.ChargeInputPower, MetricName.TotalDeviceLoadPower];
        case MetricChartType.Soc:
            return [MetricName.StateOfCharge];
        case MetricChartType.AvailableEnergy:
            return [MetricName.PackEnergyAvailable];
        case MetricChartType.PackTemp:
            return [MetricName.PackMaxTemp, MetricName.OldPackMaxTemp];
        case MetricChartType.EnergyUsage:
            return [MetricName.TotalEnergyDischarged, MetricName.TotalEnergyCharged];

        case MetricChartType.All:
            const seed: MetricName[] = [];

            return Object.keys(MetricChartType).reduce((acc: MetricName[], cur: string) => {
                if (cur === 'All') return acc;

                return acc.concat(resolveMetricNamesFromType(MetricChartType[cur]));
            }, seed);
        default:
            return [];
    }
}
