import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

type SxPropArray<Theme extends object> = Extract<SxProps<Theme>, ReadonlyArray<any>>;

/**
 * Facilitates components to passing along the `sx` prop.
 *
 * @see {@link https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop | MUI SX prop docs}
 *
 * @example
 *
 * <Box sx={[
 *     { p: 3 },
 *     ...normalizeSxProp(props.sx),
 * ]} />
 */
export function normalizeSxProp<Theme extends object>(sx?: SxProps<Theme>): SxPropArray<Theme> {
    if (sx === undefined) return [];

    return Array.isArray(sx) ? sx : [sx];
}

export type SxPropMixin = { sx?: SxProps<Theme> };
