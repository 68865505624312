import { ReactElement } from 'react';

import Text from '../Text';
import annotateMatches from './annotateMatches';

interface Props {
    /**
     * A source string to match the input against
     */
    text: string;

    /**
     * A string to check if exits in the source text and render as "highlighted"
     */
    input: string;
}

/**
 * Responsible for accenting where a specific input string matches a given source text
 */
export default function HighlightMatches({ text, input }: Props): ReactElement {
    const result = annotateMatches({ text, input });

    return (
        <>
            {result.map((x, i) => (
                <Text key={`segment-${i}`} as="span" variant="inherit" color={x.isMatch ? 'info' : 'inherit'}>
                    {x.value}
                </Text>
            ))}
        </>
    );
}
