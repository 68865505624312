import { ReservationStatus, useListReservationsForSelectorQuery } from 'generated/graphql';

export default function useReservations() {
    const { loading, data } = useListReservationsForSelectorQuery({
        variables: {
            statuses: [ReservationStatus.Requested, ReservationStatus.Confirmed, ReservationStatus.Started],
        },
    });

    return {
        loading,
        reservations: data?.listReservations ?? [],
    };
}
