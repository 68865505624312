import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';

import {
    ActiveAlertsPreview,
    DataGrid,
    DataGridCellContent,
    Icon,
    Layer,
    LoadingIndicator,
    Text,
    Tooltip,
    ZeroState,
} from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { ReservationStatus } from 'generated/graphql';

import { useI18n } from 'i18n';

import { Reservation } from '../../types';

interface Props {
    reservations: Reservation[];
    loading: boolean;
    sortModel: GridSortModel;
    hideStatus?: boolean;
    onSortModelChange: (sortModel: GridSortModel) => void;
}

export function ReservationTable({ reservations, loading, sortModel, onSortModelChange }: Props): ReactElement {
    const { t, format } = useI18n();

    function generateTooltipContent(units: Reservation['units']) {
        return (
            <Stack spacing={3}>
                {units.map(unit => (
                    // TODO(Derek): unit id is optional until it is added to the query for Reservation
                    <Stack key={unit.id ?? unit.name} spacing={1}>
                        <Text>
                            {unit.name}:{' '}
                            {unit.assignedMPUs.map((mpu, i) => {
                                const content = (
                                    <Text key={mpu.name} fontWeight={mpu.isCurrent ? 'bold' : undefined}>
                                        {mpu.name ?? t('admin_reservation_index_page.units_tooltip_unassigned')}
                                        {i < unit.assignedMPUs.length - 1 && ', '}
                                    </Text>
                                );

                                if (mpu.id) {
                                    return (
                                        <Link
                                            key={mpu.id}
                                            href={
                                                mpu.id &&
                                                getPathByName('MPU_DETAIL', {
                                                    params: { mpuID: mpu.id },
                                                })
                                            }
                                            color="text.contrast"
                                        >
                                            {content}
                                        </Link>
                                    );
                                }

                                return content;
                            })}
                        </Text>
                    </Stack>
                ))}
            </Stack>
        );
    }

    // pagination
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({ page: 0, pageSize: 25 });

    const rows: GridRowsProp<Reservation> = reservations.slice();
    const columns: GridColDef<Reservation>[] = [
        {
            field: 'state',
            headerName: t('admin_reservation_index_page.table.header.reservation_state'),
            width: 100,
            valueGetter: ctx => ctx.row.alerts,
            renderCell(params: GridRenderCellParams<Reservation, Reservation['alerts']>) {
                const { isFullyAllocated, alerts } = params.row;

                if (!alerts?.length && isFullyAllocated) {
                    return (
                        <Tooltip
                            placement="bottom-start"
                            content={t('admin_reservation_index_page.fully_allocated_message')}
                        >
                            <Icon name="checkmark" color="success" />
                        </Tooltip>
                    );
                }

                return <ActiveAlertsPreview placement="bottom-start" alerts={alerts} />;
            },
        },
        {
            field: 'requestDate',
            headerName: t('admin_reservation_index_page.table.header.request_date'),
            flex: 1,
            renderCell(params: GridRenderCellParams<Reservation, number>) {
                return <DataGridCellContent line1={format.date(params.row.requestDate)} />;
            },
        },
        {
            field: 'serviceType',
            headerName: t('admin_reservation_index_page.table.header.service_type'),
            flex: 1,
            valueGetter: ctx => t(`service_type.${ctx.row.serviceType?.toLowerCase()}`),
        },
        {
            field: 'start',
            headerName: t('admin_reservation_index_page.table.header.start_date'),
            flex: 1,
            hideable: false,
            renderCell(params: GridRenderCellParams<Reservation, number>) {
                return (
                    <DataGridCellContent line1={format.date(params.row.start)} line2={format.time(params.row.start)} />
                );
            },
        },
        {
            field: 'end',
            headerName: t('admin_reservation_index_page.table.header.end_date'),
            flex: 1,
            hideable: false,
            renderCell(params: GridRenderCellParams<Reservation, number>) {
                return <DataGridCellContent line1={format.date(params.row.end)} line2={format.time(params.row.end)} />;
            },
        },
        {
            field: 'id',
            headerName: t('admin_reservation_index_page.table.header.reservation_name_or_id'),
            flex: 1,
            hideable: false,
            renderCell(params: GridRenderCellParams<Reservation, number>) {
                const href = getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', {
                    params: { reservationID: params.row.id },
                });

                return (
                    <DataGridCellContent
                        line1={
                            <Tooltip content={params.row.id} placement="top" arrow>
                                <Link component={RouterLink} to={href}>
                                    {params.row.shortID}
                                </Link>
                            </Tooltip>
                        }
                        line2={params.row.name}
                    />
                );
            },
        },
        {
            field: 'requesterOrgName',
            headerName: t('admin_reservation_index_page.table.header.company_name'),
            flex: 1,
            /**
             * Sort by user name then company
             */
            valueGetter: ctx => ctx.row.requesterName + ctx.row.requesterOrgName,
            renderCell(params: GridRenderCellParams<Reservation, number>) {
                return <DataGridCellContent line1={params.row.requesterOrgName} line2={params.row.requesterName} />;
            },
        },

        {
            field: 'serviceAreaName',
            headerName: t('admin_reservation_index_page.table.header.service_area'),
            flex: 1,
            valueGetter: ctx => ctx.row.serviceAreaName,
        },

        {
            field: 'unitQuantity',
            headerName: t('admin_reservation_index_page.table.header.quantity'),
            flex: 1,
            valueGetter: ctx => ctx.row.unitQuantity,
            renderCell(params: GridRenderCellParams<Reservation, number>) {
                if (
                    params.row.status === ReservationStatus.Confirmed ||
                    params.row.status === ReservationStatus.Started
                ) {
                    return (
                        <DataGridCellContent
                            line1={
                                <Tooltip content={generateTooltipContent(params.row.units)} placement="top" arrow>
                                    <Text>{params.value}</Text>
                                </Tooltip>
                            }
                        />
                    );
                }

                return params.value;
            },
        },
    ];

    // render the grid
    return (
        <DataGrid
            loading={loading}
            density="comfortable"
            autoHeight
            // rendering
            // pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[25, 50, 100]}
            pagination
            // data
            rows={rows}
            columns={columns}
            sx={{ minWidth: 1150, minHeight: '80vh' }}
            disableRowSelectionOnClick={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            components={{
                LoadingOverlay: () => (
                    <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
                        <LoadingIndicator />
                    </Layer>
                ),
                NoRowsOverlay: () => (
                    <Box m={6}>
                        <ZeroState title="No results" message="There are no reservations to view in this category" />
                    </Box>
                ),
            }}
        />
    );
}
