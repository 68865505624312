import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { LayerProvider } from 'app/core/layers';

import { getGitRef, getGitSHA } from 'environment';

import { GlobalStyle } from 'styles/global-styles';

/**
 * Responsible for setting up any no visual but shared code to be applied to
 * all pages in the application
 */
export default function PageSetup({ children }) {
    const { i18n } = useTranslation();

    return (
        <>
            <Helmet titleTemplate="%s - Moxion" defaultTitle="Moxion" htmlAttributes={{ lang: i18n.language }}>
                <meta name="description" content="Moxion" />
                <meta name="git-sha" content={getGitSHA()} />
                <meta name="git-ref" content={getGitRef()} />
            </Helmet>

            <LayerProvider>{children}</LayerProvider>

            <GlobalStyle />
        </>
    );
}
