import { MouseEventHandler, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Button, Icon, Text } from 'app/components/primitives';
import { withProps } from 'app/components/withProps';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import { DATE_RANGE_HEIGHT } from './constants';

const Wrapper = withProps(
    Stack,
    props =>
        ({
            alignItems: 'center',
            direction: 'row',
            height: DATE_RANGE_HEIGHT.as('px'),
            justifyContent: 'center',
            spacing: 5,
            ...props,
        } as const),
);

const DateRangeWrapper = withProps(
    Text,
    props =>
        ({
            as: 'div',
            variant: 'h4',
            whiteSpace: 'nowrap',
            ...props,
        } as const),
);

const DateWrapper = withProps(
    Box,
    props =>
        ({
            component: 'span',
            sx: {
                display: 'inline-block',
                width: '80px',
                whiteSpace: 'nowrap',
            },
            ...props,
        } as const),
);

const DateSeparator = withProps(
    Box,
    props =>
        ({
            children: ' - ',
            component: 'span',
            sx: {
                display: 'inline-block',
                textAlign: 'center',
                width: '16px',
                whiteSpace: 'nowrap',
            },
            ...props,
        } as const),
);

interface ScheduleDateRangeProps {
    endDate: ISO8601;
    onNextButtonClick: MouseEventHandler;
    onPrevButtonClick: MouseEventHandler;
    startDate: ISO8601;
}

/**
 * Displays the visible time frame and forward and back controls.
 */
export function ScheduleDateRange({
    endDate,
    onNextButtonClick,
    onPrevButtonClick,
    startDate,
}: ScheduleDateRangeProps): ReactElement {
    const { format } = useI18n();

    return (
        <Wrapper>
            <Button
                color="utility"
                ctaType="tertiary"
                icon={<Icon name="chevron-back" />}
                onClick={onPrevButtonClick}
                size="sm"
            />

            <DateRangeWrapper>
                <DateWrapper textAlign="right">{format.dateCompact(startDate)}</DateWrapper>
                <DateSeparator />
                <DateWrapper textAlign="left">{format.dateCompact(endDate)}</DateWrapper>
            </DateRangeWrapper>

            <Button
                color="utility"
                ctaType="tertiary"
                icon={<Icon name="chevron-forward" />}
                onClick={onNextButtonClick}
                size="sm"
            />
        </Wrapper>
    );
}
