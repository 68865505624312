import { DimensionedQuantity, DimensionedQuantityTolerance, PhysicalDimensions, Quantity, Voltage } from './types';

interface FormatQuantityOptions {
    /**
     * The number of decimal places to display
     */
    decimalPlaces?: number;
}

interface FormatDimensionedQuantityOptions {
    /**
     * Remove space in front of the unit
     */
    removeSpace?: boolean;
}

interface FormatVoltageOptions {
    /**
     * Whether to include the phase information
     */
    includePhase?: boolean;
}

export function formatQuantity(quantity: Quantity, { decimalPlaces = 0 }: FormatQuantityOptions = {}): string {
    return `${quantity.isApproximate ? '~' : ''}${quantity.value.toFixed(decimalPlaces)}`;
}

export function formatDimensionedQuantity(
    quantity: DimensionedQuantity,
    { removeSpace = false }: FormatDimensionedQuantityOptions = {},
): string {
    return `${quantity.isApproximate ? '~' : ''}${quantity.value}${removeSpace ? '' : ' '}${quantity.unit}`;
}

export function formatVoltage(voltage: Voltage, { includePhase = true }: FormatVoltageOptions = {}): string {
    return `${formatDimensionedQuantity(voltage, { removeSpace: true })}${includePhase ? `/${voltage?.phase}P` : ''}`;
}

export function formatDimensions(dimensions: PhysicalDimensions): string {
    const removeSpace = true;

    return `${dimensions.isApproximate ? '~' : ''}${formatDimensionedQuantity(dimensions.length, {
        removeSpace,
    })} x ${formatDimensionedQuantity(dimensions.width, {
        removeSpace,
    })} x ${formatDimensionedQuantity(dimensions.height, { removeSpace })} (LxWxH)`;
}

export function formatTolerance(tolerance: DimensionedQuantityTolerance): string {
    return `${tolerance.isApproximate ? '~' : ''}${tolerance.minValue} - ${tolerance.maxValue} ${tolerance.unit}`;
}
