import { ComponentProps, ReactElement } from 'react';

import { Icon } from 'app/components/primitives';

import ActiveFaultsTooltip from './ActiveFaultsTooltip';

interface Props extends Omit<ComponentProps<typeof ActiveFaultsTooltip>, 'children'> {
    /**
     * The size of the trigger icon
     */
    iconSize?: ComponentProps<typeof Icon>['size'];
}

/**
 * Responsible for surfacing quick preview of faults associated with a specific MPU
 */
export default function ActiveFaultsPreview({
    iconSize,
    criticalFaultCodes,
    nonCriticalFaultCodes,
    faultsHREF,
    ...tooltipProps
}: Props): ReactElement {
    const hasCriticalFaultCodes = (criticalFaultCodes?.length ?? 0) > 0;

    return (
        <ActiveFaultsTooltip
            criticalFaultCodes={criticalFaultCodes}
            nonCriticalFaultCodes={nonCriticalFaultCodes}
            faultsHREF={faultsHREF}
            {...tooltipProps}
        >
            <Icon size={iconSize} color={hasCriticalFaultCodes ? 'danger' : undefined} name="alert-circle" />
        </ActiveFaultsTooltip>
    );
}
