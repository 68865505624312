import { DateTime } from 'luxon';

import { useDeviceModels } from 'app/core/DeviceModels';

import { SubmitReservationRequestInput, useUserSubmitReservationRequestMutation } from 'generated/graphql';

interface ReservationRequest {
    /**
     * The device model to request
     */
    deviceModelID?: string;

    /**
     * The user making the request
     */
    userID: string;

    /**
     * The account associated with the request
     */
    accountID: string;

    /**
     * The name for the reservatoin
     */
    reservationName?: string | null;

    /**
     * The number of units to include in the reservation request
     */
    unitCount: number;

    /**
     * What day the units should be delievered
     */
    startDate: DateTime;

    /**
     * What hour are the units needed by
     */
    startHour: number;

    /**
     * What timezone to use as reference for start time
     */
    startTimeZone: string;

    /**
     * What day the units should be picked up
     */
    endDate: DateTime;

    /**
     * What hour are the units need to be picked up by
     */
    endHour: number;

    /**
     * What timezone to use as reference for end time
     */
    endTimeZone: string;

    /**
     * The associated delivery locations for each of the units
     */
    locations: {
        /**
         * Account location id for the pickup
         */
        pickupID: string;

        /**
         * Account location id for the dropoff
         */
        dropoffID: string;

        /**
         * Whether the pickup and dropoff locations should be kept in sync
         */
        isLinked: boolean;
    }[];

    /**
     * General reservation notes for the request
     */
    notes?: string | null | undefined;
}

function makeDateTime({ date, hour, timeZone }: { date: string | DateTime; hour: number; timeZone: string }): string {
    const _date = typeof date === 'string' ? DateTime.fromISO(date) : date;

    // Keep local time makes sure we submit the time in the target timezone without any conversions
    return _date.set({ hour }).setZone(timeZone, { keepLocalTime: true }).toISO() ?? '';
}

/**
 * Given the reservation request form data, format it for use with the submitReservationRequest mutation
 */
function toSubmitReservationRequestInput(value: ReservationRequest): SubmitReservationRequestInput {
    const start = makeDateTime({ date: value.startDate, hour: value.startHour, timeZone: value.startTimeZone });
    const end = makeDateTime({ date: value.endDate, hour: value.endHour, timeZone: value.endTimeZone });

    return {
        name: value?.reservationName,
        requesterAccountID: value?.accountID,
        userID: value?.userID,
        additionalNotes: value?.notes,

        reservationItems: (value?.locations ?? []).map((l, index) => {
            return {
                name: `Unit ${index + 1}`,
                deviceModelID: value.deviceModelID ?? '',
                quantity: 1,

                start,
                deliveryDate: start,
                deliveryLocationID: l.dropoffID,

                end,
                pickupDate: end,
                pickupLocationID: l.pickupID,
            };
        }),
    };
}

/**
 * Responsible for providing API to submit a reservation request
 */
export default function useRequestReservation({
    shouldRefetchActiveQueries = false,
}: { shouldRefetchActiveQueries?: boolean } = {}) {
    const [requestReservation] = useUserSubmitReservationRequestMutation();

    /**
     * Note(derek): at some point we'll have to support model selection but for now we'll
     * pull the default model which should be the mp-75
     */
    const { defaultDeviceModel } = useDeviceModels();

    return (value: ReservationRequest): Promise<{ id: string }> =>
        requestReservation({
            /**
             * TODO(derek): this isn't exactly what we want but better than specifying the exact query as we don't really know
             * The purpose of the refetch is to ensure reservations are updated when we're on a reservation related page. For
             * other cases, normal navigation should refetch queries.
             */
            refetchQueries: shouldRefetchActiveQueries ? 'active' : undefined,
            variables: {
                input: toSubmitReservationRequestInput({ ...value, deviceModelID: defaultDeviceModel?.id ?? '' }),
            },
        }).then(result => ({ id: result?.data?.submitReservationRequest?.id ?? '' }));
}
