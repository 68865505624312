import { useCallback, useRef, useState } from 'react';
/**
 * Note(Derek): Authors of react-router-dom do not recommend using `useBlocker` but there are no
 * equivalent options provided. Generally it works fairly well but we'll likely need to
 * document caveats as we continue to use it or modify our designs to persist changes locally
 * which is the recommended path forward.
 */
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { useLayer } from 'app/core/layers';

import { useI18n } from 'i18n';

import { FormStateChange, saveFunction } from '../types';
import UnsavedChangesTracker from './UnsavedChangesTracker';

interface Result {
    /**
     * Whether to remind the user of their unsaved changes
     */
    remindOfUnsavedChanges: boolean;

    /**
     * Create an onFormStateChange handler to stash form changes
     */
    stashChangesFor: <T>(formID: string, save: saveFunction<T>) => (event: FormStateChange<T>) => void;

    /**
     * Method to state on the current page
     */
    stayOnPage: () => void;

    /**
     * Method to attempt a save of all unsaved edits and then proceed with navigation
     */
    saveAndProceed: () => Promise<void>;

    /**
     * Method to continue with the navigation and abandon all unsaved changes
     */
    proceedWithoutSaving: () => void;
}

/**
 * Responsible for managing state and actions related to reminding users of unsaved changes
 */
export default function useUnsavedChangesBlocker(): Result {
    const { t } = useI18n();
    const { toast } = useLayer();

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    const blocker = useBlocker(hasUnsavedChanges);
    const { current: unsavedChangeTracker } = useRef(new UnsavedChangesTracker());

    function stashChangesFor<T>(formID: string, save: saveFunction<T>): (event: FormStateChange<T>) => void {
        return formState => {
            unsavedChangeTracker.updateFormStateFor(formID, { formState, save });

            setHasUnsavedChanges(unsavedChangeTracker.hasUnsavedChanges);
        };
    }

    const stayOnPage = useCallback(() => {
        blocker?.reset?.();
    }, [blocker]);

    const proceedWithoutSaving = useCallback(() => {
        blocker?.proceed?.();
    }, [blocker]);

    const saveAndProceed = useCallback(async () => {
        if (unsavedChangeTracker.hasValidationErrors) {
            toast.add({
                severity: 'error',
                message: t('admin_user_page.unsaved_changes_invalid_fields_warning'),
            });
            blocker?.reset?.();

            return;
        }

        await unsavedChangeTracker.saveAll();

        setHasUnsavedChanges(false);
        blocker?.proceed?.();
    }, [unsavedChangeTracker, blocker, toast, setHasUnsavedChanges, t]);

    return {
        remindOfUnsavedChanges: blocker.state === 'blocked',
        stashChangesFor,
        stayOnPage,
        proceedWithoutSaving,
        saveAndProceed,
    };
}
