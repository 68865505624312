// For some reason importing from `app/components` or `app/components/primatives`
// breaks the build for the app and storyboook
import Icon from 'app/components/primitives/Icon';

import CSSDimension from 'design-system/CSSDimension';

const makeIcon = iconName => props => {
    return <Icon {...props} name={iconName} />;
};

const HEADER_HEIGHT = CSSDimension.fromPixels(44).as('rem');

/**
 * Responsible for defining DataGrid theme overrides
 */
const MuiDataGrid = {
    defaultProps: {
        autoHeight: true,
        /**
         * The components prop is deprecated in favor of slots in @mui/x-data-grid@v6
         */
        components: {
            ColumnFilteredIcon: makeIcon('filter'),
            ColumnMenuIcon: makeIcon('ellipsis-vertical'),
            ColumnSortedAscendingIcon: makeIcon('arrow-down'),
            ColumnSortedDescendingIcon: makeIcon('arrow-up'),
        },
    },

    /**
     * For some reason SCSS like selector keys are not applying their
     * defined style rules.
     */
    styleOverrides: {
        root: {
            border: 'none',
        },

        columnHeaders: ({ theme }) => ({
            minHeight: `${HEADER_HEIGHT} !important`,
            maxHeight: `${HEADER_HEIGHT} !important`,
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            backgroundColor: theme.palette.background.secondaryDark.main,
            border: 'none',
        }),

        columnHeader: ({ theme }) => ({
            ...theme.typography.bodyMedium,
            height: `${HEADER_HEIGHT} !important`,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),

            '&:focus, &:focus-within': {
                outline: 'none',
            },
        }),

        overlayWrapperInner: {
            position: 'absolute',
        },

        columnSeparator: {
            display: 'none',
        },

        cell: ({ theme }) => {
            return {
                ...theme.typography.body,
                borderColor: theme.palette.border.light,
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),

                '&:focus, &:focus-within': {
                    outline: 'none',
                },
            };
        },

        menuIcon: {
            width: 'auto',
            visibility: 'visible',
        },
    },
};

export default { MuiDataGrid };
