import { LoaderFunction, redirect } from 'react-router-dom';

import getPathByName from 'app/core/Navigation/getPathByName';

import { AccountRoleName, UserRoleName } from '../Session/Session';
import { ISession } from '../Session/types';
import { RouteName } from './getPathByName';

export function generateRequireAuthLoader({
    session,
    redirectToSignIn,
}: {
    session: ISession;
    redirectToSignIn: () => Promise<Response>;
}): LoaderFunction {
    return async ({ request }) => {
        const { isAuthenticated } = await session.syncInBackground();

        if (!isAuthenticated) {
            return redirectToSignIn();
        }

        const url = new URL(request.url);

        if (url.pathname === '/' || url.pathname === '') return redirect(getPathByName('HOME'));

        return true;
    };
}

export function generateRequireAnonLoader(session: ISession): LoaderFunction {
    return async () => {
        const { isAuthenticated } = await session.syncInBackground();

        if (isAuthenticated) {
            return redirect(getPathByName('HOME'));
        }

        return true;
    };
}

export function generateRequireAdminRoleLoader(session: ISession): LoaderFunction {
    return async () => {
        const { isAdmin } = await session.syncInBackground();

        if (!isAdmin) {
            return redirect(getPathByName('HOME'));
        }

        return true;
    };
}

export function generateRequireOwnerRoleLoader(session: ISession): LoaderFunction {
    return async () => {
        await session.syncInBackground();

        if (!session.hasAccountRoleAccess(AccountRoleName.Owner)) {
            return redirect(getPathByName('HOME'));
        }

        return true;
    };
}

export function generateRequireRenterAccessLoader(session: ISession): LoaderFunction {
    return async () => {
        await session.syncInBackground();

        if (!session.hasAccountRoleAccess(AccountRoleName.Renter)) {
            return redirect(getPathByName('HOME'));
        }

        return true;
    };
}

export function generateRequireMoxionFleetManagerAccessLoader(session: ISession): LoaderFunction {
    return async () => {
        await session.syncInBackground();

        if (!session.hasAnyUserRoleOf([UserRoleName.MoxionAdmin, UserRoleName.MoxionFleetManager])) {
            return redirect(getPathByName('HOME'));
        }

        return true;
    };
}

export function generateHomeLoader({
    session,
    getHomePathName,
    redirectToSignIn,
}: {
    session: ISession;
    redirectToSignIn: () => Promise<Response>;
    getHomePathName: () => RouteName;
}): LoaderFunction {
    return async () => {
        const { isAuthenticated, primaryAccountRoles } = await session.syncInBackground();

        if (!isAuthenticated) {
            return redirectToSignIn();
        }

        if (primaryAccountRoles.length === 0) {
            throw new Error('Missing account roles');
        }

        return redirect(getPathByName(getHomePathName()));
    };
}
