import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

import { Button, Icon, SectionHeader, ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import UsersTable from '../UsersTable';
import { USERS_TABLE_ROWS_PER_PAGE_OPTIONS } from '../UsersTable/UsersTable';
import useUsers from './useUsers';

export function UserIndexPage(): ReactElement {
    const { t } = useI18n();

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: USERS_TABLE_ROWS_PER_PAGE_OPTIONS[0],
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

    const {
        loading,
        error,
        refetch: refetchUsers,
        fetchNextPage: fetchNextUserPage,
        fetchPrevPage: fetchPrevUserPage,
        data: users,
        totalCount: totalUserCount,
    } = useUsers({ sortModel, filterModel, limit: paginationModel.pageSize });

    return (
        <>
            {error && (
                <ZeroState
                    title={t('admin_user_index_page.zero_state.fetch_error.title')}
                    message={t('admin_user_index_page.zero_state.fetch_error.message')}
                    cta={<Button onClick={() => refetchUsers()}>{t('cta.retry')}</Button>}
                />
            )}

            {!error && (
                <>
                    <SectionHeader
                        title={t('admin_user_index_page.title')}
                        cta={
                            <Box mb={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
                                <Button to={getPathByName('INVITE_USER')} icon={<Icon name="add-circle" />}>
                                    {t('admin_user_index_page.cta.invite')}
                                </Button>
                            </Box>
                        }
                    />

                    <Paper variant="outlined" sx={{ overflow: 'auto' }}>
                        <UsersTable
                            loading={loading}
                            users={users}
                            sortModel={sortModel}
                            onSortModelChange={model => {
                                // cannot jump to a given page, so reset to first page
                                setPaginationModel({ ...paginationModel, page: 0 });
                                setSortModel(model);
                            }}
                            filterModel={filterModel}
                            onFilterModelChange={model => {
                                // cannot jump to a given page, so reset to first page
                                setPaginationModel({ ...paginationModel, page: 0 });
                                setFilterModel(model);
                            }}
                            paginationModel={paginationModel}
                            onPaginationModelChange={newPaginationModel => {
                                if (newPaginationModel.page > paginationModel.page) {
                                    fetchNextUserPage();
                                } else {
                                    fetchPrevUserPage();
                                }

                                setPaginationModel(newPaginationModel);
                            }}
                            totalRowCount={totalUserCount}
                        />
                    </Paper>
                </>
            )}

            <Outlet />
        </>
    );
}
