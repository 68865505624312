import { useUserAccountInviteUpsertMutation } from 'generated/graphql';

export { AccountInviteFormContainer as default } from './container';

export const useSendAccountInviteTo = () => {
    const [upsertUserAccountInvite] = useUserAccountInviteUpsertMutation();

    return ({ email, accountID, roles }: { email: string; accountID: string; roles?: string[] }) => {
        return upsertUserAccountInvite({ variables: { email, accountID, roles } });
    };
};
