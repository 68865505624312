import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import Stack from '@mui/material/Stack';

import { Button, MultiAccountSelector, MultiServiceAreaSelector } from 'app/components';

import { useI18n } from 'i18n';

import ExternalIDSelector from '../../ExternalIDSelector';
import MultiMPUSelector from '../../MPUSelector';
import SerialNumberSelector from '../../SerialNumberSelector';
import { mpuFilterType } from '../constants';
import type { EditMPUFilterProps, FilterField, MPUFilterFormProps, MPUFormValues } from '../types';
import useFilters from '../useFilters';

const getSelectedOptionIds = (options: FilterField[] = []) => options?.map(({ id }) => id) || [];

export const EditMPUFilter = ({
    ownerAccountIDs,
    filter,
    onSubmit,
    onCancel,
    onDelete = () => {},
}: EditMPUFilterProps) => {
    const { fields = {} } = filter;
    const formik = useFormik({
        initialValues: {
            name: Array.isArray(fields['name']) ? fields['name'] : fields['name'] ? [fields['name']] : [],
            serviceAreaName: Array.isArray(fields['serviceAreaName']) ? fields['serviceAreaName'] : [],
            ownerName: Array.isArray(fields['ownerName']) ? fields['ownerName'] : [],
            serialNumber: Array.isArray(fields['serialNumber']) ? fields['serialNumber'] : [],
            externalID: Array.isArray(fields['externalID']) ? fields['externalID'] : [],
        },
        onSubmit,
    });
    const { t } = useI18n();

    const filterByProperty =
        Boolean(fields['serviceAreaName']?.length || fields['ownerName']?.length) ||
        fields['serialNumber']?.length ||
        fields['externalID']?.length;
    const filterByName = Boolean(fields['name']?.length);

    return (
        <Stack component="form" spacing={5} onSubmit={formik.handleSubmit}>
            {filterByProperty && (
                <Stack spacing={4} sx={{ flexGrow: 1 }}>
                    <Stack direction="row" spacing={2}>
                        <MultiServiceAreaSelector
                            name="serviceAreaName"
                            value={getSelectedOptionIds(formik.values.serviceAreaName)}
                            fieldName={t('filters.fields.service_area')}
                            onChange={({ selectedOptions }) => {
                                formik.setFieldValue('serviceAreaName', selectedOptions);
                            }}
                            placeholder={t('filters.input_placeholder')}
                            accountID="1234"
                        />
                        <MultiAccountSelector
                            name="ownerName"
                            value={getSelectedOptionIds(formik.values.ownerName)}
                            fieldName={t('filters.fields.owner_name')}
                            placeholder={t('filters.input_placeholder')}
                            onChange={({ selectedOptions }) => {
                                formik.setFieldValue('ownerName', selectedOptions);
                            }}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <SerialNumberSelector
                            name="serialNumber"
                            ownerAccountIDs={ownerAccountIDs}
                            value={getSelectedOptionIds(formik.values.serialNumber)}
                            fieldName={t('filters.fields.serial_number')}
                            placeholder={t('filters.input_placeholder')}
                            onChange={({ selectedOptions }) => {
                                formik.setFieldValue('serialNumber', selectedOptions);
                            }}
                        />
                        <ExternalIDSelector
                            name="externalID"
                            ownerAccountIDs={ownerAccountIDs}
                            value={getSelectedOptionIds(formik.values.externalID)}
                            fieldName={t('filters.fields.external_id')}
                            placeholder={t('filters.input_placeholder')}
                            onChange={({ selectedOptions }) => {
                                formik.setFieldValue('externalID', selectedOptions);
                            }}
                        />
                    </Stack>
                </Stack>
            )}

            {filterByName && (
                <MultiMPUSelector
                    ownerAccountIDs={ownerAccountIDs}
                    name="name"
                    fieldName={t('filters.fields.mpu')}
                    placeholder={t('filters.input_placeholder')}
                    onChange={({ selectedOptions }) => {
                        formik.setFieldValue('name', selectedOptions);
                    }}
                    value={getSelectedOptionIds(formik.values.name)}
                />
            )}

            <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button onClick={() => onDelete()} ctaType="tertiary" color="danger">
                    {t('filters.delete')}
                </Button>
                {formik.dirty && (
                    <Button onClick={() => onCancel()} ctaType="secondary">
                        {t('filters.cancel')}
                    </Button>
                )}

                <Button type="submit" ctaType="primary">
                    {t('filters.save')}
                </Button>
            </Stack>
        </Stack>
    );
};

const MPUFilter = ({ ownerAccountIDs, formId, onSubmit, initialValues, onChange }: MPUFilterFormProps) => {
    const { filter } = useFilters(mpuFilterType);
    const { fields = {} } = filter;
    const formik = useFormik<MPUFormValues>({
        initialValues: {
            name: fields['name'] ?? [],
            serviceAreaName: fields['serviceAreaName'] ?? [],
            ownerName: fields['ownerName'] ?? [],
            serialNumber: fields['serialNumber'] ?? [],
            externalID: fields['externalID'] ?? [],

            ...initialValues,
        },
        onSubmit,
    });
    const { t } = useI18n();

    const {
        values: { serviceAreaName = [], ownerName = [], name = [], serialNumber = [], externalID = [] },
    } = formik;

    const filterByName = Boolean(name.length);
    // TODO: @DEAN, maybe abstract this to be everything but name?
    const filterByProperty = Boolean(
        serviceAreaName.length || ownerName.length || serialNumber.length || externalID.length,
    );

    useEffect(() => {
        onChange && onChange(formik.values);
    }, [formik?.values, onChange]);

    return (
        <Stack direction="row">
            <Stack
                component="form"
                spacing={5}
                paddingInlineEnd={5}
                onSubmit={formik.handleSubmit}
                id={formId}
                sx={{ borderRight: '1px solid #E2DEDB', width: '50%' }}
            >
                <MultiServiceAreaSelector
                    fieldName="Service Area"
                    name="serviceAreaName"
                    onChange={({ selectedOptions }) => {
                        formik.setFieldValue('serviceAreaName', selectedOptions);
                    }}
                    value={getSelectedOptionIds(formik.values.serviceAreaName)}
                    placeholder={t('filters.input_placeholder')}
                    accountID="1234"
                    disabled={filterByName}
                />
                <MultiAccountSelector
                    fieldName="Owner Name"
                    name="ownerName"
                    placeholder={t('filters.input_placeholder')}
                    onChange={({ selectedOptions }) => {
                        formik.setFieldValue('ownerName', selectedOptions);
                    }}
                    required={false}
                    value={getSelectedOptionIds(formik.values.ownerName)}
                    disabled={filterByName}
                />
                <SerialNumberSelector
                    ownerAccountIDs={ownerAccountIDs}
                    fieldName="Serial Number"
                    name="serialNumber"
                    placeholder={t('filters.input_placeholder')}
                    onChange={({ selectedOptions }) => {
                        formik.setFieldValue('serialNumber', selectedOptions);
                    }}
                    value={getSelectedOptionIds(formik.values.serialNumber)}
                    disabled={filterByName}
                />
                <ExternalIDSelector
                    ownerAccountIDs={ownerAccountIDs}
                    fieldName="External ID"
                    name="externalID"
                    placeholder={t('filters.input_placeholder')}
                    onChange={({ selectedOptions }) => {
                        formik.setFieldValue('externalID', selectedOptions);
                    }}
                    value={getSelectedOptionIds(formik.values.externalID)}
                    disabled={filterByName}
                />
            </Stack>
            <Stack direction="column" spacing={5} sx={{ width: '50%' }} paddingInlineStart={5}>
                <MultiMPUSelector
                    ownerAccountIDs={ownerAccountIDs}
                    fieldName="MPU"
                    name="name"
                    placeholder={t('filters.input_placeholder')}
                    onChange={({ selectedOptions }) => {
                        formik.setFieldValue('name', selectedOptions);
                    }}
                    value={getSelectedOptionIds(formik.values.name)}
                    disabled={filterByProperty}
                />
            </Stack>
        </Stack>
    );
};

export default MPUFilter;
