import { cloneElement, ComponentProps, forwardRef, isValidElement, ReactElement, ReactNode, Ref, useId } from 'react';

import Box from '@mui/material/Box';
import ClickAway from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';

import { PermissiveChildrenProp } from 'app/core/types';

import { useI18n } from 'i18n';

import Icon from '../Icon';
import Button from '../interactive/Button';
import Cluster from '../layout/Cluster';
import Text from '../Text';

type ButtonProps = ComponentProps<typeof Button>;

interface Props {
    /**
     * The title explaining and setting context for the dialog
     */
    title: ReactNode;

    /**
     * Additional context to clarify the intent of the dialog, not included by the title
     */
    subtitle?: ReactNode;

    /**
     * The dialog body, is the main content for the dialog
     */
    children: PermissiveChildrenProp;

    /**
     * Content rendered in the footer of the dialog.
     * Overrides the `primaryCTA` and `tertiaryCTA` props.
     */
    footerContent?: ReactNode;

    /**
     * The CTA / button taking on the primary action associated with the dialog
     */
    primaryCTA?: ReactElement<Pick<ButtonProps, 'ctaType' | 'color'>>;

    /**
     * The CTA / button taking on the secondary action associated with the dialog
     */
    secondaryCTA?: ReactElement<Pick<ButtonProps, 'ctaType' | 'color'>>;

    /**
     * The CTA / button taking on the tertiary action associated with the dialog
     */
    tertiaryCTA?: ReactElement<Pick<ButtonProps, 'ctaType' | 'color'>> | null;

    /**
     * The auxiliaryCTA to surface an additional action
     */
    auxiliaryCTA?: ReactElement | null;

    /**
     * Event handler to be called when user requests the dialog to close
     */
    onClose?: () => void;

    /**
     * Whether to allow the dialog to close when clicked outside of the dialog body
     */
    enableClickAway?: boolean;
}

const noop = () => {};

/**
 * Presentation for dialogs
 * @link {Figma | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=551%3A8498&mode=dev}
 */
export default forwardRef(function Dialog(
    {
        title,
        subtitle,
        children,
        footerContent,
        primaryCTA,
        secondaryCTA,
        tertiaryCTA,
        auxiliaryCTA,
        onClose = noop,
        enableClickAway = false,
    }: Props,
    ref: Ref<HTMLElement>,
): ReactElement {
    const { t } = useI18n();
    const dialogTitleID = useId();

    function renderTertiaryCTA(): ReactNode {
        if (tertiaryCTA === null) return null;

        if (isValidElement(tertiaryCTA)) return cloneElement(tertiaryCTA, { ctaType: 'tertiary', color: 'primary' });

        return (
            <Button ctaType="tertiary" onClick={onClose}>
                {t('cta.cancel')}
            </Button>
        );
    }

    function renderFooterContent(): ReactNode {
        if (footerContent)
            return (
                <Box component="footer" py={4} px={5} display="flex" justifyContent="flex-end">
                    {footerContent}
                </Box>
            );

        return (
            <Box component="footer" py={4} px={5} display="flex" justifyContent="space-between">
                {auxiliaryCTA ?? <Box />}

                <Cluster gap={2} sx={{ justifyContent: 'flex-end' }}>
                    {renderTertiaryCTA()}

                    {isValidElement(secondaryCTA) &&
                        cloneElement(secondaryCTA, { ctaType: 'secondary', color: 'primary' })}

                    {isValidElement(primaryCTA) && cloneElement(primaryCTA, { ctaType: 'primary', color: 'primary' })}
                </Cluster>
            </Box>
        );
    }

    return (
        <ClickAway onClickAway={enableClickAway ? onClose : noop}>
            <Box
                role="dialog"
                aria-labelledby={dialogTitleID}
                ref={ref}
                component={Paper}
                elevation={4}
                sx={{
                    width: { xs: '100%', md: 640 },
                    // Note(Derek): The minheight at medium breakpoints and above is a little awkward looking for dialogs with little content
                    // like simple confirm dialogs with just a message. Confirm with Jessica.
                    minHeight: { xs: '100vh', md: /* 500 */ 'auto' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: { xs: 0, md: `8px` },
                }}
            >
                <Box>
                    <Box
                        component="header"
                        sx={{
                            py: 4,
                            pl: 5,
                            pr: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box id={dialogTitleID}>
                            <Text variant="h4">{title}</Text>
                            {!!subtitle && <Text as="div">{subtitle}</Text>}
                        </Box>

                        <Button ctaType="tertiary" icon={<Icon name="close" />} onClick={onClose} />
                    </Box>

                    <Box p={5} height="100%" sx={{ overflowX: 'hidden' }}>
                        <Text>{children}</Text>
                    </Box>
                </Box>

                {renderFooterContent()}
            </Box>
        </ClickAway>
    );
});
