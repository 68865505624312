import { MouseEventHandler, ReactElement, useCallback, useEffect, useId, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';

import { useI18n } from 'i18n';

const fileFormats = ['json', 'csv'] as const;
type FileFormat = (typeof fileFormats)[number];

function assertFileFormat(value: unknown): asserts value is FileFormat {
    if (!fileFormats.includes(value as FileFormat)) {
        throw new Error('Invalid file format.');
    }
}

interface Props {
    label: string;
    chartNames?: string;
    onDownload?: (event: { value: FileFormat }) => void;
    disabled?: boolean;
}

export default function DownloadMenu({ label, chartNames, disabled = false, onDownload }: Props): ReactElement {
    const { t } = useI18n();

    const id = useId();
    const [isDownloadFormatMenuOpen, setIsDownloadFormatMenuOpen] = useState(false);
    const downloadCTARef = useRef<HTMLButtonElement>(null);

    const closeMenu = () => setIsDownloadFormatMenuOpen(false);

    const selectFileFormat: MouseEventHandler<HTMLButtonElement> = useCallback(
        event => {
            const value = (event.target as HTMLButtonElement).value;

            assertFileFormat(value);

            onDownload?.({ value });
            closeMenu();
        },
        [onDownload],
    );

    useEffect(() => {
        window.addEventListener('scroll', closeMenu);

        return () => {
            window.removeEventListener('scroll', closeMenu);
        };
    }, []);

    return (
        <Box display="inline-flex" ref={downloadCTARef}>
            <Button
                ctaType="secondary"
                aria-label={t('cta.download_chart_data_sr', { title: chartNames })}
                aria-controls={id}
                aria-haspopup="true"
                aria-expanded={isDownloadFormatMenuOpen ? 'true' : undefined}
                icon={<Icon name="download" />}
                onClick={() => {
                    setIsDownloadFormatMenuOpen(x => !x);
                }}
                disabled={disabled}
            >
                {label}
            </Button>

            <Menu
                id={id}
                open={isDownloadFormatMenuOpen}
                onClose={closeMenu}
                anchorEl={downloadCTARef.current}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                sx={{ m: 1 }}
                disableScrollLock
            >
                <MenuItem component={MuiButton} value="json" onClick={selectFileFormat} fullWidth>
                    {t('download_format_json')}
                </MenuItem>
                <MenuItem component={MuiButton} value="csv" onClick={selectFileFormat} fullWidth>
                    {t('download_format_csv')}
                </MenuItem>
            </Menu>
        </Box>
    );
}
