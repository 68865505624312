import { DateTime } from 'luxon';

import { DateTimeSpan } from 'app/core/types';

import { GroupByInterval } from 'generated/graphql';

/**
 * Resolves a sensible groupBy param value for a given interval to ensure the number of datapoints is
 * reasonable to render
 */
export default function resolveGroupBy({ start, end }: DateTimeSpan<DateTime>): GroupByInterval {
    const dayCount = end.diff(start).as('day');

    if (dayCount > 90) return GroupByInterval.Day;
    if (dayCount > 7) return GroupByInterval.Hour;

    return GroupByInterval.Minute;
}
