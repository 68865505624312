import { ComponentProps, ElementType, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import { MUIMarginProps } from 'styles/theme/types';

type AcceptableGap = 0 | 1 | 2 | 3 | 4 | 5;
interface Props extends Pick<ComponentProps<typeof Box>, 'sx'>, MUIMarginProps {
    as?: ElementType;

    /**
     * Sets both row and column gap to the same value
     */
    gap?: AcceptableGap;

    /**
     * Sets the row gap
     */
    rowGap?: AcceptableGap;

    /**
     * Sets the column gap
     */
    columnGap?: AcceptableGap;

    /**
     * The elements to layout in a cluster
     */
    children: ReactNode | ReactNode[];
}

/**
 * Responsible for ensuring a collection of inline elements are equally spaced even when
 * forced to wrap
 */
export default function Cluster({
    as = 'div',
    children,
    gap = 2,
    rowGap,
    columnGap,
    sx,
    ...marginProps
}: Props): ReactElement {
    return (
        <Box
            {...marginProps}
            component={as}
            display="flex"
            flexWrap="wrap"
            gap={gap}
            rowGap={rowGap}
            columnGap={columnGap}
            sx={sx}
        >
            {children}
        </Box>
    );
}
