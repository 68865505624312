import { ReactElement } from 'react';
import { DateTime, Duration } from 'luxon';

import TimeSeriesChart, { Style } from 'app/components/compounds/TimeSeriesChart';
import { IntervalKey } from 'app/components/primitives/interactive/IntervalSelector';
import { MetricChartType } from 'app/components/widgets/telemetry/types';
import { useChartData } from 'app/core/data/deviceTelemetry';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import resolveMetricNamesFromType from '../resolveMetricNamesFromType';

export type { IntervalKey };

export const metricChartDisplayOrder = [
    MetricChartType.LineLoads,
    MetricChartType.LineVoltages,
    MetricChartType.LineCurrents,
    MetricChartType.Power,
    MetricChartType.Soc,
    MetricChartType.AvailableEnergy,
    MetricChartType.EnergyUsage,
    MetricChartType.PackTemp,
] as const;

export const DEFAULT_METRIC_CHART = metricChartDisplayOrder[0];

interface Props {
    /**
     * The identifier for a reservationItem to get telemetry by (do not also pass mpuID)
     */
    unitID?: string;

    /**
     * The identifier for a device instance to get telemetry by (do not also pass unitID)
     */
    mpuID?: string;

    /**
     * The chart type, for referencing relevant translations or other type dependent data
     */
    type: MetricChartType;

    /**
     * The start of the interval to fetch data for
     */
    start?: ISO8601 | DateTime;

    /**
     * The end of the interval to fetch data for
     */
    end?: ISO8601 | DateTime;

    /**
     * The Download CTA for downloading the chart data
     */
    downloadCTA?: ReactElement;

    /**
     * The Interval Selector to update the displayed interval
     */
    intervalSelector?: ReactElement;
}

/**
 * Responsible for a network connected time series chart for a given set of metrics associated with a specific unitID
 */
export default function MetricChart({
    type,
    unitID = '',
    mpuID = '',
    start,
    end,
    downloadCTA,
    intervalSelector,
}: Props): ReactElement {
    const { t } = useI18n();

    const { loading, error, series } = useChartData({
        mpuID,
        unitID,
        metricNames: resolveMetricNamesFromType(type),
        start: start,
        end: end,
        enablePolling: true,
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });

    const errorMessage = !!error ? t('time_series_chart.error_fetching_data') : null;

    return (
        <TimeSeriesChart
            series={series}
            unit={series[0]?.units}
            style={Style.Line}
            loading={loading}
            errorMessage={errorMessage}
            downloadCTA={downloadCTA}
            intervalSelect={intervalSelector}
        />
    );
}
