import { ScheduleTimeCell } from 'app/components/compounds/Schedule/ScheduleTimeCell';
import { withProps } from 'app/components/withProps';

import { dimensions } from './constants';

export const ReservationScheduleHeadingCell = withProps(ScheduleTimeCell, props => ({
    ...props,
    sx: {
        paddingBottom: dimensions.HEADING_BOTTOM_PADDING.as('px'),
    },
}));
