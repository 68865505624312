import { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { ApolloCache, ApolloClient, ApolloProvider, useApolloClient } from '@apollo/client';
import { To, useNavigate } from 'react-router-dom';

/**
 * Creates a new cache-only Apollo client using the given cache
 */
function makeCacheOnlyClient(cache: ApolloCache<unknown>) {
    return new ApolloClient({
        cache,
        defaultOptions: {
            watchQuery: {
                initialFetchPolicy: 'cache-only',
                fetchPolicy: 'cache-only',
                nextFetchPolicy: 'cache-only',
            },
        },
    });
}

/**
 * Disables network requests for all GraphQL queries, for all children in the component tree.
 * This is accomplished by providing a new cache-only client to the Apollo context,
 * but reuses the same cache as the client higher up in the component tree.
 * The new client allows for components to render cached data, but not fetch
 * data using the network.
 */
function ApolloCacheOnlyProvider({ children }: { children: ReactNode }): ReactElement {
    const client = useApolloClient();
    const cacheOnlyClient = useMemo(() => {
        return makeCacheOnlyClient(client.cache);
    }, [client]);

    return <ApolloProvider client={cacheOnlyClient}>{children}</ApolloProvider>;
}

/**
 * Creates a component that redirects to another route.
 *
 * This utility should only be used for child routes, because React Router
 * has a big limitation that it can't call loaders for child routes. As a result,
 * the React Router's `redirect` utility can't be used for child routes.
 *
 * See: https://github.com/remix-run/react-router/issues/9362#issuecomment-1261060825
 *
 * @param useTo A React hook that returns a destination accepted by `navigate`.
 * @param placeholder JSX that's rendered while the route is being redirected.
 * This will be rendered from `<Outlet />`.
 * The `placeholder` JSX will be short lived, and unmounted when the route is redirected.
 * Components rendered by `placeholder` are provided with a cache-only Apollo client.
 *
 * @example
 *
 * {
 *     element: makeChildRedirect(
 *         () => getPathByName('MY_ROUTE'),
 *         <MyComponent />,
 *     )
 * }
 *
 * @example
 *
 * {
 *     element: makeChildRedirect(() => {
 *         const params = useParams();
 *
 *         return `/cars/${params.manufacturer}`;
 *     }),
 * }
 *
 * @remarks
 *
 * This utility will also be useful for adding a delay before for a redirect, if we ever have the need.
 */
export default function makeChildRedirect(useTo: () => To, placeholder: ReactElement | null = null): ReactNode {
    function ChildRedirect(): ReactElement | null {
        const navigate = useNavigate();
        const to = useTo();

        useEffect(() => {
            navigate(to, { replace: true });
        }, [navigate, to]);

        return <ApolloCacheOnlyProvider>{placeholder}</ApolloCacheOnlyProvider>;
    }

    return <ChildRedirect />;
}
