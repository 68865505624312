import { useEffect, useRef, useState } from 'react';
import { DateTime, Duration } from 'luxon';

interface Input {
    /**
     * Whether to enable polling for updated unit status
     */
    enablePolling?: boolean;

    /**
     * How frequently to check for updated status
     */
    pollInterval?: number;
}

const POLL_INTERVAL = Duration.fromObject({ minutes: 5 }).toMillis();

/**
 * Responsible for initializing now and / or periodically emitting a new now value
 * This is useful for telemetry queries which cannot make use of apolloClient queries
 * pollInterval
 */
export default function useNowPoller({ enablePolling, pollInterval = POLL_INTERVAL }: Input): DateTime {
    const [now, setNow] = useState<DateTime>(DateTime.now());
    const intervalID = useRef<number | undefined>();

    useEffect(() => {
        if (!enablePolling) {
            setNow(DateTime.now());
            clearInterval(intervalID.current);
            return;
        }

        setNow(DateTime.now());

        intervalID.current = window.setInterval(() => {
            setNow(DateTime.now());
        }, pollInterval);

        return () => {
            clearInterval(intervalID.current);
        };
    }, [enablePolling, pollInterval]);

    return now;
}
