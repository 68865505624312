import { useCallback } from 'react';

import { ScheduleEventVariant } from 'app/components/compounds/Schedule';
import { useToastErrorHandler } from 'app/core/error';

import { useI18n } from 'i18n';

import { DowntimeDialogSubmitHandler } from './DowntimePeriodDialog';
import { useUpdateDowntimePeriodMutation } from './operations';
import { formValuesToDowntimePeriod } from './transformers';
import { ScheduleChangeEvent } from './types';
import { useDowntimePeriodDialog } from './useDowntimePeriodDialog';

interface EditDowntimePeriodDialogProps {
    /**
     * A callback invoked after the downtime period has been edited.
     */
    onScheduleChange?: (event: ScheduleChangeEvent) => void;
}

interface OpenHandlerProps {
    /**
     * The ID of the MPU associated with the downtime period to be edited.
     */
    mpuID: string;
    /**
     * The ID of the schedule being edited.
     */
    targetScheduleID: string;
}

/**
 * Handlers for opening and closing a dialog for editing a downtime period.
 */
interface EditDowntimePeriodDialogHandlers {
    open: (props: OpenHandlerProps) => void;
    close: () => void;
}

/**
 * @returns A function to open and close a dialog for editing a downtime period.
 */
export function useEditDowntimePeriodDialog({
    onScheduleChange,
}: EditDowntimePeriodDialogProps): EditDowntimePeriodDialogHandlers {
    const [updateDowntimePeriod] = useUpdateDowntimePeriodMutation();
    const handleError = useToastErrorHandler();
    const { t } = useI18n();

    const handleSubmit = useCallback<DowntimeDialogSubmitHandler>(
        async ({ values, dialog, mpuID, targetScheduleID }) => {
            try {
                if (targetScheduleID === undefined) {
                    // A schedule ID is required to update a downtime period.
                    // This should never happen while adhering to the `EditDowntimePeriodDialogHandlers` types above.
                    throw new Error('The `scheduleID` is required.');
                }

                await updateDowntimePeriod({
                    id: targetScheduleID,
                    ...formValuesToDowntimePeriod(values),
                });

                onScheduleChange?.({ value: { mpuID } });
                dialog.close();
            } catch (error) {
                handleError(error, t('downtime_period_dialog.error.update_failed'));
            }
        },
        [handleError, onScheduleChange, updateDowntimePeriod, t],
    );

    return useDowntimePeriodDialog({
        onScheduleChange,
        onSubmit: handleSubmit,
        previewVariant: ScheduleEventVariant.Ghost,
        saveText: t('downtime_period_dialog.cta.save'),
        title: t('downtime_period_dialog.title.edit'),
    });
}
