import { ComponentType, ReactElement, useEffect, useRef, useState } from 'react';

import { useFocusTrap } from './focus-trap';
import { IComponentConnector } from './types';

/**
 * Component which enables imperative remote updating of a component.
 */
export default function LayerItem({
    itemConnector,
    Component,
    enableFocusTrap = false,
}: {
    enableFocusTrap?: boolean;
    itemConnector: Pick<IComponentConnector, 'on' | 'props'>;
    Component: ComponentType<any>;
}): ReactElement {
    const [props, setProps] = useState<any>(itemConnector.props);

    useEffect(() => {
        setProps(itemConnector.props);

        return itemConnector.on('update', ({ data }) => setProps(data?.props));
    }, [itemConnector]);

    const { isOpen, onClose, enableClickAway, ...passThroughProps } = props;
    const boundaryElRef = useRef();

    useFocusTrap({
        shouldTrap: isOpen,
        enabled: enableFocusTrap,
        withinElement: boundaryElRef.current,
        onEscape: onClose,
        onClickAway: enableClickAway ? onClose : undefined,
    });

    return <Component ref={boundaryElRef} isOpen={isOpen} onClose={onClose} {...passThroughProps} />;
}
