import CSSDimension from 'design-system/CSSDimension';

import { Assignment } from 'generated/graphql';

/**
 * Health values for MPU tiles
 */
export enum MPUTileHealth {
    /** MPU with at least one critical fault */
    CriticalFault = 'CriticalFault',
    /** MPU not connected */
    Disconnected = 'Disconnected',
    /** MPU without faults */
    NoFault = 'NoFault',
    /** MPU with at least one non-critical fault, and no critical faults */
    NonCriticalFault = 'NonCriticalFault',
}

export const MPU_TILE_WIDTH = CSSDimension.fromPixels(264);

/**
 * Assignment values for MPU tiles
 */
export enum MPUTileAssignment {
    /**
     * An assignment value that indicates the MPU is available.
     *
     * This assignment should be used when the retrieval of schedules was successful,
     * but the MPU is not currently assigned to a schedule.
     */
    Available = 'Available',
    Charging = Assignment.Charging,
    Downtime = Assignment.Maintenance,
    InTransit = Assignment.Transit,
    OnRent = Assignment.Discharging,
}

export function isMPUTileAssignment(assignment: string): assignment is MPUTileAssignment {
    return Object.values(MPUTileAssignment).includes(assignment as MPUTileAssignment);
}
