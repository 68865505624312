import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

import Text from 'app/components/primitives/Text';

interface Props extends BoxProps {
    /**
     * Text describing what is being displayed
     */
    label: string;
    /**
     * The value to display
     */
    value: string;
}

/**
 * Calls attention to a single piece of information
 */
export default function SpotlightBasic({ label, value, ...props }: Props): ReactElement {
    const theme = useTheme();

    return (
        <Box
            {...props}
            px={5}
            py={6}
            sx={{
                borderRadius: `${theme.shape.borderRadius}px`,
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: theme.customShadows.subtle,
            }}
        >
            <Text as="p" gutterBottom>
                {label}
            </Text>

            <Text variant="h4" as="p">
                {value}
            </Text>
        </Box>
    );
}
