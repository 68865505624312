import { ComponentType, ReactElement, useMemo } from 'react';

/**
 * Creates a slots object for a picker component.
 *
 * @param icon The icon to render in the picker button.
 *
 * If the `icon` isn't memoized, the picker will re-render every time the parent component re-renders.
 */
export function usePickerSlots(icon?: ReactElement) {
    return useMemo(() => {
        // We can't set a slot property to `undefined`.
        // It must be either set to a component, or the property must be omitted.
        const slots: Record<string, ComponentType> = {};

        if (icon) {
            // Create a function component that renders the given icon element.
            slots.openPickerIcon = (): ReactElement => icon;
        }

        return slots;
    }, [icon]);
}
