import { useCallback, useMemo, useRef } from 'react';
import { DateTime } from 'luxon';

import { DateValidationError } from '@mui/x-date-pickers';

import {
    dateTimeToPossibleISO8601Date,
    DEFAULT_TIME_ZONE,
    INVALID_DATE_TIME_VALUE,
    PossibleISO8601,
} from 'app/core/date-time';
import { IANATimeZone } from 'app/core/types';

import { makeDateTimeChangeEvent, nullifyTime } from './transformers';
import { DateChangeEvent } from './types';

interface DateFieldValueProps {
    name?: string;
    onChange?: (event: DateChangeEvent) => void;
    timeZone?: IANATimeZone;
    value: PossibleISO8601 | undefined;
}

export function useDateFieldValue(props: DateFieldValueProps) {
    const { name, onChange, timeZone = DEFAULT_TIME_ZONE, value } = props;
    const latestDateTimeRef = useRef<DateTime | null>(null);

    const handleChange = useCallback(
        (dateTime: DateTime | null, { validationError }: { validationError?: DateValidationError | null }) => {
            latestDateTimeRef.current = dateTime;

            const value = dateTimeToPossibleISO8601Date(dateTime);

            onChange?.(
                makeDateTimeChangeEvent({
                    name,
                    timeZone,
                    validationError,
                    value,
                }),
            );
        },
        [onChange, name, timeZone],
    );

    const dateTime = useMemo(() => {
        if (value === INVALID_DATE_TIME_VALUE) {
            return latestDateTimeRef.current ?? null;
        }

        return value ? nullifyTime(value, timeZone) : null;
    }, [value, timeZone]);

    return {
        dateTime,
        handleChange,
    };
}
