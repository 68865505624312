import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import Box from '@mui/material/Box';

import Text from 'app/components/primitives/Text';

interface Props extends TooltipProps<ValueType, NameType> {
    unit?: string;
    tickFormatter?: (number) => string;
    valueFormatter?: (number) => string;
}

/**
 * Tooltip to be used with time series based charts.
 */
const TimeSeriesTooltip = ({
    active,
    payload,
    label,
    unit,
    tickFormatter = x => x,
    valueFormatter = x => x,
}: Props) => {
    if (!active || !payload || payload?.length === 0) {
        return null;
    }

    return (
        <Box
            tabIndex={-1}
            sx={theme => ({
                borderRadius: 2,
                backgroundColor: theme.palette.background.paper,

                padding: 2,
                minWidth: '150px',
                maxWidth: '310px',

                outline: 'none',
                overflow: 'hidden',

                boxShadow: theme.shadows[5],
            })}
        >
            <Text variant="bodyMedium" as="p" gutterBottom>
                {tickFormatter(label)}
            </Text>

            <div>
                {payload.map(series => (
                    <div key={series.name}>
                        {payload.length > 1 && (
                            <Text as="span" color="secondary">
                                {series.name}
                                <span style={{ color: series.color }}> — </span>
                            </Text>
                        )}
                        <Text as="span">
                            {valueFormatter(series.value)} {unit}
                        </Text>
                    </div>
                ))}
            </div>
        </Box>
    );
};

export default TimeSeriesTooltip;
