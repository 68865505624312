export enum DomainValidationHint {
    TOO_FEW_CHARACTERS = 'TOO_FEW_CHARACTERS',
    TOO_MANY_CHARACTERS = 'TOO_MANY_CHARACTERS',
    HAS_INVALID_CHARACTERS = 'HAS_INVALID_CHARACTERS',
    CANNOT_START_WITH_HYPHEN = 'CANNOT_START_WITH_HYPHEN',
    CANNOT_END_WITH_HYPHEN = 'CANNOT_END_WITH_HYPHEN',
    MISSING_TLD = 'MISSING_TLD',
    TLD_TOO_FEW_CHARACTERS = 'TLD_TOO_FEW_CHARACTERS',
    TLD_TOO_MANY_CHARACTERS = 'TLD_TOO_MANY_CHARACTERS',
}

type DomainValidationHints = `${DomainValidationHint}`[];

export const DOMAIN_MIN_CHARACTERS = 4;
export const DOMAIN_MAX_CHARACTERS = 255;
// Technically a domain can be 1 character long but these are reserved
export const DOMAIN_SEGMENT_MIN_CHARACTERS = 2;
export const DOMAIN_SEGMENT_MAX_CHARACTERS = 63;

/**
 * Responsible for validating a string to check if it's a valid domain pattern.
 * This function will not check that the domain exists, just that it conforms to the rules
 * for defining a domain
 */
export default function validateDomain(_candidate?: string | null): {
    errors: DomainValidationHints;
    isValid: boolean;
} {
    let validationErrors: DomainValidationHints = [];
    const candidate = _candidate ?? '';

    if (!candidate || candidate.length < DOMAIN_MIN_CHARACTERS) {
        validationErrors.push('TOO_FEW_CHARACTERS');
    }

    if (candidate.length > DOMAIN_MAX_CHARACTERS) {
        validationErrors.push('TOO_MANY_CHARACTERS');
    }

    if (/[^-a-z0-9.]+/i.test(candidate)) {
        validationErrors.push('HAS_INVALID_CHARACTERS');
    }

    if (/^-/.test(candidate)) {
        validationErrors.push('CANNOT_START_WITH_HYPHEN');
    }

    if (/-$/.test(candidate)) {
        validationErrors.push('CANNOT_END_WITH_HYPHEN');
    }

    const domainSegments = candidate.split('.');
    const tld = domainSegments[domainSegments.length - 1];

    if (domainSegments.length < 2 || !tld) {
        validationErrors.push('MISSING_TLD');
    } else {
        if (tld.length < DOMAIN_SEGMENT_MIN_CHARACTERS) {
            validationErrors.push('TLD_TOO_FEW_CHARACTERS');
        }

        if (tld.length > DOMAIN_SEGMENT_MAX_CHARACTERS) {
            validationErrors.push('TLD_TOO_MANY_CHARACTERS');
        }
    }

    return {
        errors: validationErrors,
        isValid: validationErrors.length === 0,
    };
}
