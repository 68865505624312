import { DateTime } from 'luxon';

import { ISO8601 } from 'app/core/types';

import { disableCellAvailability } from './availability';
import { DEFAULT_DAYS_DISPLAYED, DEFAULT_FUTURE_LENGTH, DEFAULT_HISTORY_LENGTH } from './constants';
import { ScheduleConfig, ScheduleEvent, ScheduleOptions } from './types';

function getInitialDate(input?: ISO8601): ISO8601 {
    const initialTime = input ? DateTime.fromISO(input) : DateTime.fromJSDate(new Date());

    // Ensure the initial date is set to midnight for consistent rendering
    return initialTime.startOf('day').toISO() ?? '';
}

function removeUndefinedOptions<E extends ScheduleEvent>(options: ScheduleOptions<E>): ScheduleOptions<E> {
    return Object.fromEntries(Object.entries(options).filter(([_key, value]) => value !== undefined));
}

/**
 * Creates configuration from the given options.
 */
export function makeScheduleConfig<E extends ScheduleEvent>(options: ScheduleOptions<E> = {}): ScheduleConfig<E> {
    const { initialDate, ...otherOptions } = options;

    return {
        dayMarkers: [],
        daysDisplayed: DEFAULT_DAYS_DISPLAYED,
        defaultExpandedRows: [],
        events: [],
        futureLength: DEFAULT_FUTURE_LENGTH,
        getCellAvailability: disableCellAvailability,
        historyLength: DEFAULT_HISTORY_LENGTH,
        initialDate: getInitialDate(initialDate),
        timeRanges: [],
        ...removeUndefinedOptions<E>(otherOptions),
    };
}
