import { FocusTrapAPI } from '../types';
import getFocusableChildren from './getFocusableChildren';

function isFocused(element) {
    return document.activeElement === element;
}

function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !('nodeType' in e)) {
        throw new Error(`Node expected`);
    }
}

interface FocusTrapState {
    id: number;
    boundaryElement: HTMLElement | null;
    returnElement: HTMLElement | null;
    onEscape?: () => void;
    onClickAway?: () => void;
}

const nullFocusTrapState = {
    id: -1,
    boundaryElement: null,
    returnElement: null,
};

/**
 * Responsible for managing multiple focus traps in a document.
 */
export default function initFocusTrap(): FocusTrapAPI {
    let nextID = 0;

    let traps: FocusTrapState[] = [];

    function getActiveTrap(): FocusTrapState {
        return traps.slice(-1)[0] ?? nullFocusTrapState;
    }

    function isTrap(withinElementOrID?: HTMLElement | number | null, x?: FocusTrapState): boolean {
        return x?.boundaryElement === withinElementOrID || x?.id === withinElementOrID;
    }

    function getExistingTrap(withinElementOrID?: HTMLElement | number | null): FocusTrapState | undefined {
        return traps.find(x => isTrap(withinElementOrID, x));
    }

    /**
     * Will trap focus within the given element. The element which receives focus on trap will be the first child of the boundary element or
     * the first child with the attribute `data-focus-start`. Trap can be called multiple times to re-activate a blurred trap.
     * @returns {number} trapID - the trap id is used to release the trap. This is important as traps are often released when an element is
     *   unmounted and the ref may no longer be valid.
     */
    function trap({
        withinElement,
        onEscape,
        onClickAway,
    }: {
        withinElement?: HTMLElement | null;
        onEscape?: () => void;
        onClickAway?: () => void;
    }): number {
        let trapID: number = -1;
        const existingTrap = getExistingTrap(withinElement);

        if (!withinElement || !document.body.contains(withinElement)) return -1;

        if (!existingTrap) {
            trapID = nextID;

            nextID += 1;

            traps.push({
                id: trapID,
                boundaryElement: withinElement,
                returnElement: document.activeElement as HTMLElement,
                onEscape,
                onClickAway,
            });
        } else {
            traps = traps.filter(x => x.boundaryElement !== withinElement).concat(existingTrap);

            trapID = existingTrap.id;
        }

        const { startChild, firstChild, children } = getFocusableChildren(withinElement);

        if (children.length === 0) {
            withinElement?.focus();
        } else if (startChild) {
            startChild?.focus();
        } else {
            firstChild?.focus();
        }

        return trapID;
    }

    function release(withinElementOrID?: HTMLElement | number | null): Promise<void> {
        // Ensure returning focus happens after clicks are processed
        // and next trap is activated after click as well
        return new Promise(resolve => {
            setTimeout(() => {
                const trapToRelease = traps.find(x => isTrap(withinElementOrID, x));

                if (!trapToRelease) return resolve();

                traps = traps.filter(x => !isTrap(withinElementOrID, x));

                trapToRelease?.returnElement?.focus();
                resolve();
            });
        });
    }

    async function releaseAll(): Promise<void> {
        const firstTrap = traps[0];

        await release(firstTrap?.id);

        traps = [];
    }

    /**
     * This ensures focus is trapped on keyboard events and handles escape for releasing the active trap.
     */
    function handleKeyDown(event: KeyboardEvent) {
        const activeTrap = getActiveTrap();

        if (!document.body.contains(activeTrap.boundaryElement)) return;

        const { key, shiftKey } = event;
        const { firstChild, children, lastChild } = getFocusableChildren(activeTrap.boundaryElement);

        if (/^escape$/i.test(key)) {
            release(activeTrap.id);
            activeTrap?.onEscape?.();
            event.stopPropagation();
        }

        if (!/^tab$/i.test(key)) return;

        if (children.length === 0) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        if (!shiftKey && isFocused(lastChild)) {
            event.preventDefault();
            event.stopPropagation();
            firstChild?.focus();
            return;
        }

        if (shiftKey && isFocused(firstChild)) {
            event.preventDefault();
            event.stopPropagation();
            lastChild?.focus();
            return;
        }
    }

    /**
     * This ensures focus is trapped on mouse events.
     */
    function handleClick(event: MouseEvent) {
        const { boundaryElement, onClickAway } = getActiveTrap();
        const { firstChild } = getFocusableChildren(boundaryElement);

        assertIsNode(event.target);

        if (
            !!event.target &&
            document.body.contains(boundaryElement) &&
            document.body.contains(event.target) &&
            !boundaryElement?.contains(event.target)
        ) {
            onClickAway?.();
            firstChild?.focus();
        }
    }

    return {
        init: () => {
            window?.addEventListener('keydown', handleKeyDown);
            window?.addEventListener('click', handleClick);
        },

        trap,

        release,

        releaseAll,

        destroy: async () => {
            window?.removeEventListener('keydown', handleKeyDown);
            window?.removeEventListener('click', handleClick);

            await releaseAll();
        },
    };
}
