import { ReactElement } from 'react';

import { AsideCellProps, ContentProps } from 'app/components/compounds/MultiGrid';
import { ScheduleToolbarWrapper } from 'app/components/compounds/Schedule';
import { ScheduleTimeCell } from 'app/components/compounds/Schedule/ScheduleTimeCell';
import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import Text from 'app/components/primitives/Text';
import { withProps } from 'app/components/withProps';

import { useI18n } from 'i18n';

import { Availability, MPU_HEADING_BOTTOM_PADDING, RowIndex } from './constants';

const availabilityByRowIndex: Partial<Record<number, Availability>> = {
    [RowIndex.Reserved]: Availability.Reserved,
    [RowIndex.Available]: Availability.Available,
    [RowIndex.Downtime]: Availability.Downtime,
};

const i18nKeyByAvailability: Record<Availability, string> = {
    [Availability.Reserved]: 'mpu_schedule.reserved',
    [Availability.Available]: 'mpu_schedule.available',
    [Availability.Downtime]: 'mpu_schedule.downtime',
};

export function MpuAsideCell({ index, style }: AsideCellProps): ReactElement {
    const { t } = useI18n();
    const availability = availabilityByRowIndex[index];

    return (
        <Text as="div" style={style}>
            {availability ? t(i18nKeyByAvailability[availability]) : ''}
        </Text>
    );
}

export const MpuHeadingCell = withProps(ScheduleTimeCell, props => ({
    ...props,
    sx: {
        paddingBottom: MPU_HEADING_BOTTOM_PADDING.as('px'),
    },
}));

interface MpuToolbarRightProps extends ContentProps {
    onAddDowntime?: () => void;
    readOnly?: boolean;
}

export function MpuToolbarRight({ onAddDowntime, readOnly }: MpuToolbarRightProps) {
    const { t } = useI18n();

    return (
        <ScheduleToolbarWrapper variant="right">
            {!readOnly && (
                <Button ctaType="secondary" icon={<Icon name="add-circle" />} onClick={onAddDowntime}>
                    {t('mpu_schedule.cta.add_downtime_period')}
                </Button>
            )}
        </ScheduleToolbarWrapper>
    );
}
