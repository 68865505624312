import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Box from '@mui/material/Box';

import { AccountSelector, Button, DeviceModelSelector, Input, ServiceAreaSelector } from 'app/components';
import { UnitUpdateInput } from 'app/core/data/unit/types';

import { useI18n } from 'i18n';

type EditMPUDetailsFormFields =
    | 'name'
    | 'ownerID'
    | 'modelID'
    | 'serviceAreaID'
    | 'assetID'
    | 'serialNumber'
    | 'externalID';

interface Props {
    /**
     * The unique ID to assign to the form element, used to associate form controls outside of the form
     */
    id?: string;

    /**
     * Affects the layout of the form
     */
    layout?: 'standard' | 'compact';

    /**
     * The account to pull service areas for
     */
    accountID?: string;

    /**
     * Whether the entire form should be rendered as readOnly / static
     */
    readOnly?: boolean;

    /**
     * Which fields to exclude from the form
     */
    excludeFields?: EditMPUDetailsFormFields[];

    /**
     * Which fields to mark as readOnly
     */
    readOnlyFields?: EditMPUDetailsFormFields[];

    /**
     * Whether or not the form submit button should be hidden, To be used when the
     * submit button exists outside of the form (i.e. within dialogs)
     */
    hideSaveCTA?: boolean;

    /**
     *
     */
    unit?: UnitUpdateInput;

    /**
     * Event handler to be called back when the submit CTA should be disabled based on
     * form state
     */
    onFormStateChange?: (event: { isSaveCTADisabled: boolean }) => void;

    /**
     * Event handler to be called back when the user intends to save the form
     */
    onSave?: (values: UnitUpdateInput) => Promise<void>;
}

const noop = () => {};
const asyncNoop = () => Promise.resolve();

/**
 * Responsible for making edits to an existing MPU metadata
 */
export default function EditMPUDetailsForm({
    id,
    layout = 'standard',
    accountID,
    readOnly,
    hideSaveCTA = false,
    readOnlyFields = [],
    excludeFields = [],
    unit,
    onFormStateChange = noop,
    onSave = asyncNoop,
}: Props): ReactElement {
    const { t } = useI18n();

    const form = useFormik<UnitUpdateInput>({
        initialValues: {
            id: unit?.id ?? '',
            name: unit?.name ?? '',
            ownerID: unit?.ownerID,
            modelID: unit?.modelID ?? '',

            // TODO(derek): Hide asset id and serviceArea from Account Fleet Managers (aka Owners i.e. Sunbelt)
            assetID: unit?.assetID ?? '',
            serviceAreaID: unit?.serviceAreaID ?? '',

            serialNumber: unit?.serialNumber,

            // TODO(derek): All fields for Account Fleet manager should be readOnly except externalID
            externalID: unit?.externalID ?? '',
        },
        enableReinitialize: true,
        validateOnMount: false,
        validateOnChange: true,
        // TODO: find a way to restrict the schema keys to DeviceInstanceInput
        validationSchema: object().shape({
            name: string().required(t('admin_unit_page.validation.unit_name_required')),
            ownerID: string().optional(),
            modelID: string().required(t('admin_unit_page.validation.unit_model_required')),
            serviceAreaID: string().optional(),
            assetID: string().required(t('admin_unit_page.validation.unit_asset_id_required')),
            serialNumber: string().required(t('admin_unit_page.validation.serial_number_required')),
            externalID: string().optional(),
        }),
        onSubmit: async values => {
            // TODO(will): handle errors
            await onSave(values);
        },
    });

    const {
        isValid,
        dirty,
        values: { name, ownerID, modelID, serviceAreaID, assetID, serialNumber, externalID },
        errors: _errors,
    } = form;
    const isSaveCTADisabled = !dirty || !isValid;

    // Supress any errors when in readOnly mode
    const errors = readOnly ? {} : _errors;

    useEffect(() => {
        onFormStateChange({ isSaveCTADisabled });
    }, [onFormStateChange, isSaveCTADisabled]);

    function isFieldReadOnly(fieldName) {
        return readOnly || readOnlyFields?.includes(fieldName);
    }

    function isFieldIncluded(fieldName) {
        return !excludeFields?.includes(fieldName);
    }

    function getColumnStyles() {
        if (layout === 'compact') {
            return { columnCount: 2, columnWidth: '280px', gap: 3 };
        }

        return {
            columnWidth: '500px',
            gap: 8,
        };
    }

    return (
        <form id={id} onSubmit={form.handleSubmit}>
            <Box sx={{ ...getColumnStyles(), maxWidth: 'lg' }}>
                {isFieldIncluded('name') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <Input
                            fullWidth
                            required
                            readOnly={isFieldReadOnly('name')}
                            name="name"
                            value={name}
                            error={!!errors.name}
                            fieldName={t('unit_page.label.device_name')}
                            caption={errors.name ?? ''}
                            onChange={form.handleChange}
                        />
                    </Box>
                )}

                {isFieldIncluded('ownerID') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <AccountSelector
                            name="ownerID"
                            required={false}
                            readOnly={isFieldReadOnly('ownerID')}
                            value={ownerID ?? null}
                            fieldName={t('unit_page.label.owner')}
                            caption={errors.name ?? ''}
                            onChange={x => {
                                form.setFieldValue('ownerID', x.value?.id);
                                form.setFieldValue('serviceAreaID', 'unassigned');
                            }}
                        />
                    </Box>
                )}

                {isFieldIncluded('serviceAreaID') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <ServiceAreaSelector
                            required={false}
                            fullWidth
                            /**
                             * Only users who own the unit can set the service area
                             */
                            disabled={accountID !== ownerID}
                            accountID={accountID}
                            readOnly={isFieldReadOnly('serviceAreaID')}
                            fieldName={t('unit_page.label.service_area')}
                            name="serviceAreaID"
                            value={serviceAreaID}
                            onChange={x => {
                                form.setFieldValue('serviceAreaID', x.value?.id);
                            }}
                        />
                    </Box>
                )}

                {isFieldIncluded('modelID') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <DeviceModelSelector
                            required
                            fullWidth
                            readOnly={isFieldReadOnly('modelID')}
                            fieldName={t('unit_page.label.device_model')}
                            caption={errors.modelID ?? ''}
                            error={!!errors.modelID}
                            name="modelID"
                            value={modelID}
                            onChange={x => {
                                form.setFieldValue('modelID', x.value?.id);
                            }}
                        />
                    </Box>
                )}

                {isFieldIncluded('assetID') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <Input
                            fullWidth
                            required
                            readOnly={isFieldReadOnly('assetID')}
                            name="assetID"
                            value={assetID}
                            error={!!errors.assetID}
                            fieldName={t('unit_page.label.asset_id')}
                            caption={errors.assetID ?? ''}
                            onChange={form.handleChange}
                        />
                    </Box>
                )}

                {isFieldIncluded('serialNumber') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <Input
                            fullWidth
                            required
                            name="serialNumber"
                            readOnly={isFieldReadOnly('serialNumber')}
                            value={serialNumber}
                            error={!!errors.serialNumber}
                            fieldName={t('unit_page.label.serial_number')}
                            caption={errors.serialNumber ?? ''}
                            onChange={form.handleChange}
                        />
                    </Box>
                )}

                {isFieldIncluded('externalID') && (
                    <Box mb={5} sx={{ breakInside: 'avoid' }}>
                        <Input
                            fullWidth
                            name="externalID"
                            readOnly={isFieldReadOnly('externalID')}
                            value={externalID}
                            error={!!errors.externalID}
                            fieldName={t('unit_page.label.external_id')}
                            caption={errors.name ?? ''}
                            onChange={form.handleChange}
                        />
                    </Box>
                )}

                {/*
                 * This element is here to balance the columns layout to make sure
                 * the fields stay in their respective columns as captions come in and out
                 * as the form is validated. It is not ideal as it relies on screen level breakpoints
                 * while columns react to space provided by their parent. The math in the use cases we have
                 * work out fairly well however.
                 */}
                <Box pb={`${(excludeFields.length + 1) * 4.5}rem`} sx={{ display: { xs: 'none', lg: 'block' } }} />
            </Box>

            {!hideSaveCTA && (
                <Box display="flex" justifyContent="flex-end" mt={5}>
                    <Button type="submit" disabled={isSaveCTADisabled}>
                        {t('admin_unit_page.cta.save_changes')}
                    </Button>
                </Box>
            )}
        </form>
    );
}
