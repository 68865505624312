import { ComponentProps, useCallback, useId, useState } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';

import { Button, ModalDialog, ReservationRequestForm } from 'app/components';
import { useSession } from 'app/core/Session';

import { useI18n } from 'i18n';

interface Props {
    id?: string;
    isOpen?: boolean;
    onClose: () => void;
    onCreateReservation: (
        event: Parameters<ComponentProps<typeof ReservationRequestForm>['onSubmit']>[0],
    ) => Promise<void>;
}

export default function CreateReservationDialog({
    id,
    isOpen = false,
    onClose = () => {},
    onCreateReservation = x => Promise.resolve(),
}: Props): JSX.Element {
    const { t } = useI18n();

    const dialogTitleID = useId();
    const formID = useId();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const { id: userID, primaryAccountID } = useSession().getSession();

    const handleValidityChange = useCallback(
        ({ isValid }) => {
            setIsFormValid(isValid);
        },
        [setIsFormValid],
    );

    return (
        <ModalDialog
            id={id}
            open={isOpen}
            onClose={onClose}
            aria-labelledby={dialogTitleID}
            title={<Box id={dialogTitleID}>{t('admin_reservation_index_page.create_reservation_dialog.title')}</Box>}
            primaryCTA={
                <Button form={formID} type="submit" disabled={!isFormValid}>
                    {t('cta.submit')}
                </Button>
            }
        >
            <ReservationRequestForm
                id={formID}
                userID={userID}
                accountID={primaryAccountID}
                /**
                 * Fleet managers can make reservations that occur in the past, however we limit
                 * to 2023, the year in which production of the MP-75 began. Any thing before this
                 * is likely a mistake.
                 */
                minDate={DateTime.fromMillis(0).set({ year: 2023 }).startOf('year')}
                onValidityChange={handleValidityChange}
                onSubmit={async ({ value }) => {
                    await onCreateReservation({ value });

                    onClose();
                }}
                enableAccountSelection
                hideCta
            />
        </ModalDialog>
    );
}
