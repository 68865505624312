import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Stack from '@mui/material/Stack';

import { AccountSelector, Button, DeviceModelSelector, Input } from 'app/components';
import { isAssetAlreadyExistsError, isSerialNumberConflictError, useToastErrorHandler } from 'app/core/error';

import { useI18n } from 'i18n';

interface AddUnitFormInputs {
    name: string;
    assetID: string;
    deviceModelID: string;
    serialNumber: string;
    externalID: string;
    ownerAccountID?: string;
}

interface Props {
    /**
     * Unique identifier for the form, used as ID attribute.
     */
    id: string;

    /**
     * Whether a submit button will be used outside the form, useful if
     * the form is to be used in a dialog.
     */
    hideSubmitCta: boolean;

    /**
     * Event handler to be called back when the user submits the form.
     * Will only be called for valid forms.
     */
    onSubmit: (values: AddUnitFormInputs) => Promise<void>;

    /**
     * Notifies upon change in forms validity
     */
    onValidityChange?: (event: { isValid: boolean }) => void;
}

const noop = () => {};

/**
 * Responsible for collecting the information necessary to provision a unit in Moxie OS.
 */
export default function AddMPUForm({
    id,
    hideSubmitCta = false,
    onSubmit,
    onValidityChange = noop,
}: Props): ReactElement {
    const { t } = useI18n();

    const _t = (key: string): string => t(`admin_unit_index_page.add_mpu_dialog.form.${key}`);

    const handleError = useToastErrorHandler();

    const formik = useFormik<AddUnitFormInputs>({
        enableReinitialize: false,
        initialValues: {
            name: '',
            assetID: '',
            deviceModelID: '',
            ownerAccountID: '',
            serialNumber: '',
            externalID: '',
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: object().shape({
            deviceModelID: string().required(_t('mpu_model_required_message')),
            name: string().required(_t('name_required_message')),
            externalID: string().optional(),
            serialNumber: string().required(_t('serial_number_required_message')),
            assetID: string().required(_t('asset_id_help_message')),
            ownerAccountID: string().optional(),
        }),
        async onSubmit(values) {
            try {
                await onSubmit(values);
            } catch (error: unknown) {
                if (isSerialNumberConflictError(error)) {
                    formik.setFieldError('serialNumber', _t('serial_number_non_unique_message'));
                    return;
                }
                if (isAssetAlreadyExistsError(error)) {
                    formik.setFieldError('assetID', _t('asset_id_non_unique_message'));
                    return;
                }

                handleError(error, t('admin_unit_index_page.add_mpu_dialog.form.unknown_failure'));
            }
        },
    });

    const {
        isValid,
        values: { name, assetID, deviceModelID, ownerAccountID, serialNumber, externalID },
    } = formik;

    useEffect(() => {
        onValidityChange({ isValid });
    }, [onValidityChange, isValid]);

    function hasError(field: keyof AddUnitFormInputs): boolean {
        return !!formik.touched[field] && Boolean(formik.errors[field]);
    }

    function resolveCaption(field: keyof AddUnitFormInputs, defaultCaption: string = ''): string {
        return (formik.touched[field] && formik.errors[field]) || defaultCaption;
    }

    return (
        <form id={id} onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
                <DeviceModelSelector
                    required
                    fieldName={_t('mpu_model_label')}
                    name="deviceModelID"
                    value={deviceModelID}
                    onChange={evt => {
                        formik.setFieldValue('deviceModelID', evt?.value?.id);
                    }}
                />

                <Input
                    required
                    fieldName={_t('name_label')}
                    name="name"
                    value={name}
                    maxLength={80}
                    error={hasError('name')}
                    caption={resolveCaption('name')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <Input
                    required
                    fieldName={_t('asset_id_label')}
                    name="assetID"
                    maxLength={24}
                    value={assetID}
                    error={hasError('assetID')}
                    caption={resolveCaption('assetID', _t('asset_id_help_message'))}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <Input
                    required
                    fieldName={_t('serial_number_label')}
                    name="serialNumber"
                    maxLength={16}
                    value={serialNumber}
                    error={hasError('serialNumber')}
                    caption={resolveCaption('serialNumber')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <Input
                    fieldName={_t('external_id_label')}
                    name="externalID"
                    maxLength={36}
                    value={externalID}
                    error={hasError('externalID')}
                    caption={resolveCaption('externalID', _t('external_id_help_message'))}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <AccountSelector
                    fieldName={_t('owner_label')}
                    caption={_t('owner_help_message')}
                    name="ownerAccountID"
                    required={false}
                    value={ownerAccountID || null}
                    onChange={x => {
                        formik.setFieldValue('ownerAccountID', x.value?.id);
                    }}
                />
            </Stack>

            {!hideSubmitCta && (
                <Button type="submit" disabled={!isValid}>
                    {_t('submit_cta_label')}
                </Button>
            )}
        </form>
    );
}
