import { createContext, ReactElement, useContext } from 'react';

import { ISession } from 'app/core/types';

import { nullSession } from './Session';

const SessionContext = createContext<ISession>(nullSession);

interface Props {
    session: ISession;
    children: ReactElement;
}

export function SessionProvider({ session, children }: Props) {
    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

export function useSession() {
    return useContext(SessionContext);
}
