import { ApolloError } from '@apollo/client';

import { knownApolloErrorMessages, KnownServiceErrorKind } from './constants';

/**
 * This function is a stop-gap to help match a known service error.
 *
 * TODO(Morris): Remove this. We shouldn't be sniffing postgresql error codes to match service errors.
 *
 * @deprecated
 */
function isUniqueViolationError(error: ApolloError) {
    // see: https://www.postgresql.org/docs/current/errcodes-appendix.html
    const PG_UNIQUE_VIOLATION_CODE = '23505';

    return !!error?.graphQLErrors?.find(
        graphqlError => (graphqlError.extensions?.exception as any)?.code === PG_UNIQUE_VIOLATION_CODE,
    );
}
/**
 * This function is a stop-gap to help match a known service error.
 *
 * TODO(Morris): Remove this. We shouldn't be sniffing SQL column names to match service errors.
 *
 * @deprecated
 */
function isErrorRelevantToColumn(error: ApolloError, columnName: string) {
    return !!error?.graphQLErrors?.find(graphqlError =>
        String((graphqlError.extensions?.exception as any)?.detail).startsWith(`Key (${columnName})`),
    );
}

function getGraphqlPath(error: ApolloError): string {
    const pieces = error.graphQLErrors.at(0)?.path ?? [];

    return pieces.join('.');
}

/**
 * An object of functions used to match errors to `KnownServiceErrorKind`
 */
const apolloErrorMatchersByKind: Record<KnownServiceErrorKind, (error: ApolloError) => boolean> = {
    AssetAlreadyExists: error => {
        const hasRelevantGraphqlPath = ['updateDeviceInstance', 'createDeviceInstance'].includes(getGraphqlPath(error));

        return hasRelevantGraphqlPath && isErrorRelevantToColumn(error, 'assetid') && isUniqueViolationError(error);
    },
    ScheduleConflict: error => {
        return error.message === knownApolloErrorMessages.ScheduleConflict;
    },
    ScheduleDeleteBug: error => {
        return error.message === knownApolloErrorMessages.ScheduleDeleteBug;
    },
    SerialNumberConflict: error => {
        return error.message === knownApolloErrorMessages.SerialNumberConflict;
    },
};

/**
 * If the given error is an `ApolloError` that matches a known service error, then a `KnownServiceErrorKind` is returned.
 */
export function findKnownServiceErrorKind(error: unknown): KnownServiceErrorKind | undefined {
    if (!(error instanceof ApolloError)) return undefined;

    const kinds = Object.values(KnownServiceErrorKind);

    return kinds.find(kind => {
        const isKindOfKnownApolloError = apolloErrorMatchersByKind[kind];

        return isKindOfKnownApolloError(error);
    });
}
