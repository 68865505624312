import { ChangeEvent } from 'react';

import MenuItem from '@mui/material/MenuItem';

import Input from 'app/components/primitives/interactive/Input';

import { ReservationStatus, useListReservationsAllowedStatusTransitionsQuery } from 'generated/graphql';

import { useI18n } from 'i18n';

interface ReservationStatusSelectorContainerProps {
    reservationID: string;
    name: string;
    value: ReservationStatus;
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export default function ReservationStatusSelectorContainer({
    reservationID,
    name,
    value,
    onChange,
}: ReservationStatusSelectorContainerProps) {
    const { t } = useI18n();

    const { data } = useListReservationsAllowedStatusTransitionsQuery({
        skip: !reservationID,
        variables: { reservationId: reservationID },
        /**
         * The transitions are static now and we no longer need to continuously fetch
         * when the status changes.
         */
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only',
    });

    const statusTransitions = data?.reservationsAllowedStatusTransitions?.allowedTransitions ?? [];

    const hasTransitions = statusTransitions.length > 1;

    return (
        <Input
            select
            name={name}
            fieldName={t('admin_reservation_page.reservation_status_selector.label')}
            fullWidth
            disabled={!hasTransitions}
            onChange={onChange}
            value={!hasTransitions ? '' : value}
        >
            {statusTransitions.map(transition => (
                <MenuItem value={transition} key={transition}>
                    {transition}
                </MenuItem>
            ))}
        </Input>
    );
}
