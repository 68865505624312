import { MouseEventHandler, ReactElement } from 'react';

import { ContentProps } from '../MultiGrid';
import { ScheduleInitialDateButton } from './ScheduleInitialDateButton';
import { ScheduleToolbarWrapper } from './ScheduleToolbarWrapper';

interface ScheduleTodayButtonToolbarProps extends ContentProps<any> {
    /**
     * Determines if the button should display the text for today or returning to start.
     */
    isToday?: boolean;
    /**
     * Callback handler called when the Today button is clicked.
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Presentational component for adding a search box and filter menu to the `Schedule` component.
 *
 * @example
 *
 * <Schedule
 *     slots={{
 *         toolbarLeft: ScheduleInitialDateButtonToolbar,
 *     }}
 *     slotProps={{
 *         toolbarLeft: {
 *             isToday: true,
 *             onClick: () => console.log('Today button clicked'),
 *         },
 *     }}
 * />
 */
export function ScheduleInitialDateButtonToolbar({ isToday, onClick }: ScheduleTodayButtonToolbarProps): ReactElement {
    return (
        <ScheduleToolbarWrapper variant="left">
            <ScheduleInitialDateButton isToday={isToday} onClick={onClick} />
        </ScheduleToolbarWrapper>
    );
}
