import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';

import useTheme, { Theme } from 'styles/theme';

import MPUTileContent, { MPUTileHealth } from '../MPUTileContent';

type MPUTileProps = ComponentProps<typeof MPUTileContent>;

const backgroundByHealth: Record<MPUTileHealth, (theme: Theme) => string> = {
    [MPUTileHealth.CriticalFault]: theme => theme.palette.background.danger.main,
    [MPUTileHealth.Disconnected]: theme => theme.palette.background.disabled.main,
    [MPUTileHealth.NoFault]: theme => theme.palette.background.primary.main,
    [MPUTileHealth.NonCriticalFault]: theme => theme.palette.background.primary.main,
};

export function MPUTile({ health = MPUTileHealth.NoFault, loading, ...contentProps }: MPUTileProps): ReactElement {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: backgroundByHealth[health](theme),
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: loading ? theme.palette.border.light : theme.palette.border.dark,
                borderRadius: `${theme.shape.borderRadius}px`,
            }}
        >
            <MPUTileContent health={health} loading={loading} {...contentProps} />
        </Box>
    );
}
