import { DateTime } from 'luxon';

import { extractUnitStatusesFromTelemetry } from 'app/core/data/deviceTelemetry';
import getPathByName, { RouteName } from 'app/core/Navigation/getPathByName';

import { Assignment, MpuIndexDeviceInstanceFragment, MpuIndexDeviceLocationFragment } from 'generated/graphql';

import { TranslateFunction } from 'i18n';

import { FaultCodesBySeverity, MPU } from './types';

type ServerDeviceInstance = MpuIndexDeviceInstanceFragment & {
    latestDeviceLocation: Omit<MpuIndexDeviceLocationFragment, 'deviceID'>;
};

type ServerDeviceInstanceSchedule = NonNullable<ServerDeviceInstance['schedulesIntersecting']>[0];

interface Result {
    mpus: MPU[];

    /**
     * The total unit count
     */
    totalCount: number;

    /**
     * The unit count with known locations
     */
    shownUnitCount: number;
}

/**
 * Responsible for resolving the currently active assignment.
 *
 * TODO(derek): Currently the backend returns all schedule assignments which over time can get quite long and
 *    will be full of lesser and lesser relevant schedules (past assignments) causing this to get slower and slower.
 *    We need a solution in the backend to ideally return this field for us OR give us a way to use a date range to restrict the results
 */
function getCurrentAssignment(
    schedules: Pick<ServerDeviceInstanceSchedule, 'start' | 'end' | 'assignment'>[],
): Assignment | undefined {
    const currentSlot = schedules.find(
        slot => DateTime.fromISO(slot.start) <= DateTime.now() && DateTime.fromISO(slot.end) >= DateTime.now(),
    );

    return currentSlot?.assignment;
}

function toActiveFaultCodes(activeFaults?: ServerDeviceInstance['activeFaults']): FaultCodesBySeverity {
    const result = (activeFaults ?? []).reduce((acc, cur) => {
        const severity = (cur.faultSeverity ?? 'info').toLowerCase();
        const currentList = acc[severity] ?? [];

        return {
            ...acc,
            [severity]: currentList.concat(cur.faultName),
        };
    }, {});

    const sortedKeys = Object.keys(result).sort(a => {
        return /CRITICAL/i.test(a) ? -1 : 1;
    });

    return sortedKeys.reduce((acc, cur) => {
        return acc.set(cur, result[cur]);
    }, new Map());
}

/**
 * Responsible for flattening, simplifying and normalizing server data for device instances AKA MPUs
 * for display
 */
export default function toMPUData(
    deviceInstances: Partial<ServerDeviceInstance>[],
    { t }: { t: TranslateFunction; detailPath?: RouteName },
): Result {
    const unfilteredMPUs = (deviceInstances ?? [])?.map((mpu): MPU => {
        const assignment = getCurrentAssignment(mpu?.schedulesIntersecting ?? []);
        const activeFaultCodes = toActiveFaultCodes(mpu?.activeFaults);

        return {
            id: mpu?.id ?? '',
            serialNumber: mpu?.serialNumber ?? undefined,
            externalID: mpu?.externalID ?? undefined,
            href: getPathByName('MPU_DETAIL', { params: { mpuID: mpu?.id ?? '' } }),
            name: mpu?.name ?? '',
            modelName: mpu?.deviceModel?.name ?? undefined,
            ownerName: mpu?.owner?.name ?? undefined,
            particleDeviceID: mpu?.assetID ?? undefined,

            currentLocation:
                mpu?.latestDeviceLocation?.lat && mpu?.latestDeviceLocation?.lon
                    ? { lat: mpu?.latestDeviceLocation?.lat, lon: mpu?.latestDeviceLocation?.lon }
                    : null,
            locationTimestamp: mpu?.latestDeviceLocation?.time ?? undefined,

            currentAssignment: assignment
                ? t(`mpu_assignment.${assignment.toLowerCase()}`)
                : t('mpu_assignment.unknown'),
            currentAssignmentValue: assignment,

            serviceAreaName: mpu?.serviceArea?.name ?? t('service_area_unassigned'),

            activeFaultCodes,
            criticalFaultCodes: activeFaultCodes.get('critical'),
            nonCriticalFaultCodes: activeFaultCodes.get('info'),

            ...extractUnitStatusesFromTelemetry<'stateOfCharge' | 'timeToEmpty' | 'mode' | 'cellSignalBars'>(
                mpu?.latestTelemetry?.telemetryPoints ?? [],
                {
                    t,
                },
            ),
        };
    });

    const mpus = unfilteredMPUs.filter(di => di?.currentLocation);
    const unitCount = unfilteredMPUs.length;
    const shownUnitCount = mpus.length;

    return {
        mpus: unfilteredMPUs,
        totalCount: unitCount,
        shownUnitCount,
    };
}
