import { useCallback, useMemo } from 'react';
import { i18n, TOptions } from 'i18next';
import { Settings as LuxonSettings } from 'luxon';
import { useTranslation } from 'react-i18next';

import * as formatters from './formatters';
import { DATA_GRID_NAMESPACE } from './makeI18n';

type Formatters = typeof formatters;

/**
 * An object of formatters provided with an `i18n` instance.
 */
export type FormatObject = {
    [K in keyof Formatters]: (
        value?: Parameters<Formatters[K]>[1],
        options?: Parameters<Formatters[K]>[2],
    ) => ReturnType<Formatters[K]>;
};

/**
 * Create a new object of formatters with each provided with
 * the given `i18n` instance.
 */
function makeFormatObject(i18n: i18n): FormatObject {
    return Object.fromEntries(
        Object.entries(formatters).map(([key, formatter]) => [
            key,
            // Ignore the `any`s below. Strict typing is enabled by the `FormatObject` type.
            (value: any, options: any) => formatter(i18n, value, options),
        ]),
    ) as any;
}

/**
 * This hook wraps react-i18next to hide as many details as possible while also giving
 * us the ability to modify and extend the underlying functionality.
 */
export default function useI18n(namespaces: string[] = []) {
    const { t, i18n } = useTranslation(namespaces, { useSuspense: false });

    const { language } = i18n;

    // ensure that luxon's locale is in sync with i18n
    LuxonSettings.defaultLocale = language;

    const format = useMemo(() => {
        return makeFormatObject(i18n);
    }, [i18n]);

    const _t = useCallback(
        (key: string, values?: any, options?: TOptions) => {
            const areNamespacesLoaded = namespaces.concat('translation').reduce((acc, cur) => {
                return acc && i18n.hasLoadedNamespace(cur);
            }, true);

            if (!areNamespacesLoaded) {
                return '';
            }

            return t(key, values, options);
        },
        [t, i18n, namespaces],
    );

    return {
        /**
         * This is property must not be used lightly. Generally we'd like to abstract
         * away the language from the rest of the app. This is here for cases where
         * third parties need to know what language the app is in
         */
        language,

        /**
         * Decorated translate function to ensure all namespaces are loaded before using.
         * Using suspense requires the parent component to be wrapped in React.Suspense
         * which is a little awkward.
         */
        t: _t,

        /**
         * Provide localized app specific formats for Dates, DateTimes, numbers etc...
         */
        format,

        getDataGridLocaleText: () => i18n.getResourceBundle(language, DATA_GRID_NAMESPACE),
    };
}

/**
 * An application specific version of i18next's `TFunction` type.
 * @see import('i18next').TFunction
 */
export type TranslateFunction = ReturnType<typeof useI18n>['t'];
