import { DateTime, Interval } from 'luxon';

import { ISO8601 } from 'app/core/types';

import { GetHistoricalFaultsQueryResult, useGetHistoricalFaultsQuery } from 'generated/graphql';

import { TranslateFunction } from 'i18n';

import { FaultGroup, HistoricalFault } from './types';

type ServerFault = NonNullable<
    NonNullable<GetHistoricalFaultsQueryResult['data']>['getDeviceInstance']['historicalFaults']
>[0];

interface Input {
    /**
     * The unique identifier for an MPU
     */
    mpuID?: string;

    /**
     * The start of the range to review faults for
     */
    start?: ISO8601 | null;

    /**
     * The end of the range to review faults for
     */
    end?: ISO8601 | null;

    /**
     * Which faults to include, if not passed all faults which have occurred during the specified interval will be returned
     */
    faultNames?: string[];

    /**
     * Translation Function for the app
     */
    t: TranslateFunction;
}

interface Output {
    /**
     * Whether the historical fault data is being fetched
     */
    loading: boolean;

    /**
     * All faults found for a given time interval sorted by severity
     */
    data: FaultGroup[];
}

/**
 * Frontend transformation to prep the returned server data for display
 */
export function toFault(serverFault: ServerFault, { t }): HistoricalFault {
    return {
        name: serverFault?.faultName,
        displayName: serverFault?.faultName ? t(`device_fault.${serverFault?.faultName?.toLowerCase()}.name`) : '',
        severity: serverFault?.faultSeverity,
        firstSeen: serverFault?.firstSeen,
        resolved: serverFault?.resolved,
        duration: serverFault?.resolved
            ? Interval.fromDateTimes(
                  DateTime.fromISO(serverFault.firstSeen),
                  DateTime.fromISO(serverFault.resolved),
              ).length('seconds')
            : null,
    };
}

type FaultSortFields = Pick<FaultGroup, 'severity'>;

function toSortScore({ severity }: FaultSortFields) {
    if (severity === 'CRITICAL') return 100;

    return 1;
}

function bySeverity(a: FaultSortFields, b: FaultSortFields) {
    return toSortScore(b) - toSortScore(a);
}

function sortBySeverityThenDisplayName(faults: FaultGroup[]): FaultGroup[] {
    return faults.sort((a, b) => a.displayName.localeCompare(b.displayName)).sort(bySeverity);
}

function groupFaultsByName(faults: HistoricalFault[]): {
    [name: string]: FaultGroup;
} {
    return faults.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.name]: {
                name: cur.name,
                displayName: cur.displayName,
                severity: cur.severity,
                faults: (acc[cur.name]?.faults ?? []).concat(cur),
            },
        };
    }, {});
}

export function toFaultGroup(historicalFaults: ServerFault[] | undefined | null, { t }: { t: TranslateFunction }) {
    const faults = (historicalFaults ?? []).map(x => toFault(x, { t }));
    const faultsByName = groupFaultsByName(faults);
    const result = Object.keys(faultsByName).map(name => faultsByName[name]);

    return sortBySeverityThenDisplayName(result);
}

/**
 * Responsible for fetching and prepping historical fault data for display
 */
export default function useHistoricalFaults({ mpuID, start, end, faultNames, t }: Input): Output {
    const { loading, data } = useGetHistoricalFaultsQuery({
        skip: !mpuID,
        variables: {
            id: mpuID ?? '',
            start: start ?? '',
            end: end ?? '',
            faultNames,
        },
    });

    return {
        loading,
        data: toFaultGroup(data?.getDeviceInstance?.historicalFaults, { t }),
    };
}
