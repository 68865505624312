import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { LoadingIndicator } from 'app/components';

interface Props {
    /**
     * Whether the panel content is loading
     */
    loading: boolean;

    /**
     * Whether we should show the zero state, zero state will not be shown if content is still loading
     */
    shouldShowZeroState: boolean;

    /**
     * The panel content
     */
    children: ReactElement;

    /**
     * The zero state representation likely ZeroState
     */
    zeroState: ReactElement;
}

/**
 * Responsible for common layout and render state management for renter reservation tabbed panels
 */
export default function PanelWithZeroState({ loading, shouldShowZeroState, children, zeroState }: Props): ReactElement {
    const minHeight = 600;

    return (
        <Box minHeight={minHeight}>
            {loading || shouldShowZeroState ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {loading ? <LoadingIndicator /> : zeroState}
                    </Box>
                </Box>
            ) : (
                children
            )}
        </Box>
    );
}
