import { Box, Paper } from '@mui/material';

interface LayoutProps {
    title: JSX.Element;
    subtitle: JSX.Element;
    errorMessage?: JSX.Element;
    mapHeading: JSX.Element;
    map: JSX.Element;
    tableHeading: JSX.Element;
    table: JSX.Element;
}

export function Layout({ title, subtitle, errorMessage, mapHeading, map, tableHeading, table }: LayoutProps) {
    return (
        <Box>
            <Box mx={6} mb={5}>
                {title}

                {subtitle}
            </Box>

            {!!errorMessage && <Box mb={4}>{errorMessage}</Box>}

            <Paper sx={{ px: 6, py: 5 }} elevation={0}>
                <Box mb={6}>
                    <Box mb={3}>{mapHeading}</Box>

                    {map}
                </Box>

                <Box mb={3}>{tableHeading}</Box>

                <Box sx={{ overflowX: 'auto', borderRadius: 0 }}>{table}</Box>
            </Paper>
        </Box>
    );
}
