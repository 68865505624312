import React from 'react';

import MenuItem from '@mui/material/MenuItem';

import { Input } from 'app/components';

import { useI18n } from 'i18n';

interface DocumentTypeSelectorProps {
    value: string;
    onChange: (value: string) => void;
}

const DocumentTypeSelector: React.FC<DocumentTypeSelectorProps> = ({ value, onChange }) => {
    const { t } = useI18n();

    const types = [
        {
            id: 'bill-of-lading-internal',
            name: t('documents.bill_of_lading_internal'),
        },
        {
            id: 'bill-of-lading-external',
            name: t('documents.bill_of_lading_external'),
            disabled: true,
        },
    ];

    return (
        <Input
            type="select"
            value={value}
            onChange={e => onChange(e.target.value)}
            select
            fieldName={t('documents.select_document_type')}
        >
            {types.map(type => (
                <MenuItem value={type.id} key={type.id} disabled={type.disabled}>
                    {type.name}
                </MenuItem>
            ))}
        </Input>
    );
};

export default DocumentTypeSelector;
