import { motion } from 'framer-motion';

import Layer from '../layout/Layer';
import { LoadingIndicator } from '../LoadingIndicator';

const MotionLayer = motion(Layer);

/**
 * Fills the first position ancester adding a loading shimmer and indicator
 */
export default function LoadingOverlay({ loading }) {
    if (!loading) return <></>;

    return (
        <>
            <MotionLayer
                fill="anchor"
                sx={{ backgroundColor: 'background.primary.main' }}
                animate={{ opacity: [0.3, 0.8] }}
                transition={{
                    repeat: Infinity,
                    repeatType: 'reverse',
                    duration: 2,
                }}
            ></MotionLayer>
            <Layer fill="anchor" display="flex" justifyContent="center" alignItems="center">
                <LoadingIndicator />
            </Layer>
        </>
    );
}
