import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import { GridColDef, GridRenderCellParams, GridRowsProp, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';

import {
    Button,
    DataGrid,
    DataGridCellContent,
    DataGridFillCell,
    Icon,
    Layer,
    Link,
    LoadingIndicator,
    Text,
    ZeroState,
} from 'app/components';
import { Alert } from 'app/core/Alert/types';

import { useI18n } from 'i18n';

interface Props {
    loading?: boolean;

    alerts: Alert[];
    excludeColumns?: string[];

    // Sort
    sortModel?: GridSortModel;
    onSortModelChange?: (sortModel: GridSortModel) => void;
}

function LoadingOverlay() {
    return (
        <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
            <LoadingIndicator />
        </Layer>
    );
}
function NoRowsOverlay() {
    const { t } = useI18n();
    const _t = (key: string, params = {}) => t(`alerts_page.${key}`, params);

    return (
        <Box m={6}>
            <ZeroState title={_t('table.no_rows_title')} message={_t('table.no_rows_message')} />
        </Box>
    );
}

export default function AlertsTable({
    loading,
    alerts,
    excludeColumns = [],

    sortModel,

    onSortModelChange,
}: Props): ReactElement {
    const { t, format } = useI18n();
    const _t = (key: string, params = {}) => t(`alerts_page.${key}`, params);

    const rows: GridRowsProp<Alert> = alerts.slice();
    const allColumns: GridColDef<Alert>[] = [
        {
            field: 'severity',
            headerName: _t('table.header.alert'),

            sortable: false,
            hideable: false,
            filterable: false,
            disableColumnMenu: true,

            flex: 6,
            minWidth: 380,
            renderCell(params: GridRenderCellParams<GridValidRowModel, string>) {
                const alert = params.row;

                return (
                    <DataGridFillCell>
                        <Box display="flex" width="100%" height="100%" alignItems="center" gap={3}>
                            <Box
                                sx={{
                                    minWidth: 56,
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor:
                                        alert.severity === 'CRITICAL'
                                            ? 'background.danger.main'
                                            : 'background.warningDark.main',
                                    color: alert.severity === 'CRITICAL' ? 'text.danger' : null,
                                }}
                            >
                                <Icon name="warning" />
                            </Box>

                            <Text sx={{ whiteSpace: 'wrap' }}>{alert.name}</Text>
                        </Box>
                    </DataGridFillCell>
                );
            },
        },
        {
            field: 'reservation',
            headerName: _t('table.header.reservation'),
            flex: 3,

            minWidth: 200,

            hideable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

            renderCell(params: GridRenderCellParams<GridValidRowModel, number>) {
                const alert = params.row;
                const reservationHREF = alert?.reservation.href;

                return (
                    <DataGridCellContent
                        line1={<Link to={reservationHREF}>{alert.reservation.shortID}</Link>}
                        line2={alert.reservation.status}
                    />
                );
            },
        },
        {
            field: 'timestamp',
            headerName: _t('table.header.timestamp'),

            minWidth: 200,

            hideable: false,
            filterable: false,
            disableColumnMenu: true,

            renderCell(params: GridRenderCellParams<GridValidRowModel, number>) {
                const alert = params.row;

                return (
                    <DataGridCellContent line1={format.date(alert.timestamp)} line2={format.time(alert.timestamp)} />
                );
            },
        },
        {
            field: 'company',
            headerName: _t('table.header.company'),
            flex: 3,
            minWidth: 200,

            hideable: false,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,

            renderCell(params: GridRenderCellParams<GridValidRowModel, number>) {
                const alert = params.row;
                const accountHREF = alert?.reservation.requesterAccountHREF;
                const userHREF = alert?.reservation.userRequestedHREF;

                return (
                    <DataGridCellContent
                        line1={
                            alert.reservation.requesterAccountName ? (
                                <Link to={accountHREF}>
                                    <Text variant="inherit" maxLines={1} title={alert.reservation.requesterAccountName}>
                                        {alert.reservation.requesterAccountName}
                                    </Text>
                                </Link>
                            ) : null
                        }
                        line2={
                            alert.reservation.userRequestedName ? (
                                <Link to={userHREF}>{alert.reservation.userRequestedName}</Link>
                            ) : null
                        }
                    />
                );
            },
        },
        {
            field: 'serviceAreaName',
            headerName: _t('table.header.service_area'),
            flex: 3,
            minWidth: 200,

            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,

            valueGetter(params: GridRenderCellParams<GridValidRowModel, boolean>) {
                const alert = params.row;

                return alert.reservation.serviceAreaName ?? t('service_area_unassigned');
            },
        },
        {
            field: 'action',
            headerName: '',
            flex: 3,
            minWidth: 200,

            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,

            renderCell(params: GridRenderCellParams<GridValidRowModel, number>) {
                const alert = params.row;

                return (
                    <Button size="sm" ctaType="secondary" to={alert.action?.to}>
                        {alert.action?.label}
                    </Button>
                );
            },
        },
    ];

    const columns = allColumns.filter(col => !excludeColumns.includes(col.field));

    // render the grid
    return (
        <Box sx={{ overflowX: 'auto' }}>
            <DataGrid
                // rendering
                loading={loading}
                density="comfortable"
                autoHeight
                rowHeight={49.5}
                // data
                sortingMode="client"
                filterMode="client"
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick={true}
                isRowSelectable={() => false}
                isCellEditable={() => false}
                onSortModelChange={onSortModelChange}
                sortModel={sortModel}
                components={{
                    LoadingOverlay,
                    NoRowsOverlay,
                }}
                hideFooter
            />
        </Box>
    );
}
