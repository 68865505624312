import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { Account, AccountPaginationFilters, SortDirection } from 'generated/graphql';

const ALLOWED_STRING_OPERATORS = ['contains', 'isAnyOf'];

export default class DataGridToGqlAdaptor {
    private static gridSortToGqlSortMap: { [key: string]: SortDirection } = {
        asc: SortDirection.Asc,
        desc: SortDirection.Desc,
    };
    private static gridFieldNameToGqlFieldMap: { [key: string]: keyof Account } = {
        name: 'name',
        industry: 'industry',
    };

    public static getGqlSortBy(sortModel?: GridSortModel): { field: keyof Account; direction: SortDirection } {
        if (!sortModel || sortModel.length === 0 || !sortModel[0].sort) {
            return { field: 'name', direction: SortDirection.Asc };
        }

        // NOTE: cannot handle sorting by multiple fields at this point
        return {
            field: this.gridFieldNameToGqlFieldMap[sortModel[0].field],
            direction: this.gridSortToGqlSortMap[sortModel[0].sort],
        };
    }

    public static getGqlFilter(filterModel?: GridFilterModel): AccountPaginationFilters {
        if (!filterModel) {
            return {};
        }

        let names: string[] | undefined;
        const nameFilterItem = filterModel.items.find(item => item.field === 'name');

        if (nameFilterItem && ALLOWED_STRING_OPERATORS.includes(nameFilterItem.operator) && nameFilterItem.value) {
            names = [].concat(nameFilterItem.value);
        }

        let industries: string[] | undefined;
        const industryFilterItem = filterModel.items.find(item => item.field === 'industry');

        if (
            industryFilterItem &&
            ALLOWED_STRING_OPERATORS.includes(industryFilterItem.operator) &&
            industryFilterItem.value
        ) {
            industries = [].concat(industryFilterItem.value);
        }

        return { names, industries };
    }
}
