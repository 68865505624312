import { DateTime } from 'luxon';

import { MetricName } from 'app/core/data';
import { useSession } from 'app/core/Session';

import { useI18n } from 'i18n';

import { ReservationStatus, useListActiveReservationItemsQuery } from '../../../generated/graphql';

export interface ReservationItem {
    /**
     * The reservation item id
     */
    id: string;

    /**
     * Whether a unit has been assigned to fulfill the specific item in the reservation
     */
    hasAssignment: boolean;

    /**
     * The moxion provided name uniquely identifying a specific unit in the fleet
     */
    officialName: string | undefined;

    /**
     * The user provided name for a unit in a reservation. This name should persist for the entire
     * reservation regardless of unit swaps. If the name is not provided then the name will be
     * numerically assigned.
     */
    name: string;

    /**
     * The parent reservation's id
     */
    reservationID?: string;

    /**
     * The parent reservation's name
     */
    reservationName?: string;

    /**
     * ISO 8601 date time string representing the reservation items start time
     */
    start: string;

    /**
     * ISO 8601 date time string representing the reservation items end time
     */
    end: string;

    /**
     * The formatted delivery address for the item
     */
    deliveryLocation?: string;

    /**
     * The formatted location for the unit associated with the reservation item currently
     */
    currentLocation?: {
        lat: number;
        lon: number;
    } | null;

    /**
     * The assigned units state of charge in percent (%)
     */
    stateOfCharge?: number | undefined;

    /**
     * The assigned units projected time to empty for the batteries in seconds (s)
     */
    timeToEmpty?: number | undefined;

    /**
     * The status taken from the reservation
     */
    status: ReservationStatus;
}

function getReservationCount(reservationItems: ReservationItem[]): number {
    return new Set(reservationItems.map(ri => ri.reservationID).filter(Boolean)).size;
}

export function useRenterDashboardData() {
    const session = useSession();
    const { primaryAccountID } = session.getSession();
    const { t } = useI18n();

    const now = DateTime.now().startOf('minute');

    const { loading, error, data } = useListActiveReservationItemsQuery({
        errorPolicy: 'all',
        variables: {
            requesterAccountID: primaryAccountID,
            end: now.toISO() ?? '',
        },
    });

    const collectionArray: ReservationItem[] = [];
    const augmentedReservationItems: ReservationItem[] = (data?.listReservations ?? []).reduce((items, reservation) => {
        const itemsToAdd: ReservationItem[] = reservation.reservationItems.map((ri, index) => {
            const telemetryPoints = ri.assignedDevice?.latestTelemetry.telemetryPoints;
            const reservationName =
                reservation.name?.trim() ||
                t('renter_dashboard.reservation_fallback_title', {
                    id: reservation.id.substring(0, 5),
                });

            const augmentedReservationItem: ReservationItem = {
                reservationID: reservation?.id,
                reservationName,

                id: ri?.id,
                hasAssignment: !!ri?.assignedDevice,
                name: ri?.alias ?? `Unit ${index + 1}`,
                officialName: ri?.assignedDevice?.name,
                start: ri?.start,
                end: ri?.end,
                currentLocation: ri?.assignedDevice?.latestDeviceLocation,
                deliveryLocation: ri?.deliveryLocation?.formattedAddress ?? undefined,

                stateOfCharge: telemetryPoints?.find(({ metric }) => metric === MetricName.StateOfCharge)?.value,
                timeToEmpty: telemetryPoints?.find(({ metric }) => metric === MetricName.TimeToEmpty)?.value,

                status: reservation.status,
            };

            return augmentedReservationItem;
        });

        return items.concat(itemsToAdd);
    }, collectionArray);

    return {
        loading,
        hasError: !!data && !!error,

        reservationCount: getReservationCount(augmentedReservationItems),
        reservationItems: augmentedReservationItems,
    };
}
