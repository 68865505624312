import { ComponentProps, useMemo } from 'react';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useSession } from 'app/core/Session';
import { Experience } from 'app/core/types';

import { useI18n } from 'i18n';

import AppChromeLayout from './AppChromeLayout';

type ButtonProps = ComponentProps<typeof Button>;

type NavProps = Pick<ComponentProps<typeof AppChromeLayout>, 'links' | 'renderPrimaryCTA' | 'onSignOut'>;

/**
 * Responsible for building the primary nav based on user roles
 */
export default function useConfigureNav({
    targetExperience,
    onSignOut,
}: {
    targetExperience: Experience;
    onSignOut: () => void;
}): NavProps {
    const { t } = useI18n();
    const session = useSession();

    return useMemo(() => {
        const { isAdmin } = session.getSession();

        const config: NavProps = {
            links: [],
            onSignOut,
        };

        if (isAdmin && targetExperience === 'Owner') {
            config.links = [
                {
                    to: getPathByName('RENTAL_MGMT_RESERVATIONS'),
                    icon: <Icon name="reservation" />,
                    label: t('primary_navigation.reservations'),
                },
                {
                    to: getPathByName('RENTAL_MGMT_TRANSITIONS'),
                    icon: <Icon name="repeat" />,
                    label: t('primary_navigation.transitions'),
                },
                {
                    to: getPathByName('MPUS_INDEX'),
                    icon: <Icon name="equipment" />,
                    label: t('primary_navigation.units'),
                },
                {
                    to: getPathByName('ACCOUNT_MGMT_INDEX'),
                    icon: <Icon name="people" />,
                    label: t('primary_navigation.accounts_and_users'),
                },
            ];
        } else if (!isAdmin && targetExperience === 'Owner') {
            config.links = [
                {
                    to: getPathByName('MPUS_INDEX'),
                    icon: <Icon name="equipment" />,
                    label: t('primary_navigation.units'),
                },
            ];
        } else {
            config.links = [
                {
                    to: getPathByName('RENTER_DASHBOARD'),
                    icon: <Icon name="dashboard" />,
                    label: t('primary_navigation.dashboard'),
                },
                {
                    to: getPathByName('CUSTOMER_RESERVATIONS'),
                    icon: <Icon name="reservation" />,
                    label: t('primary_navigation.reservations'),
                },
            ];

            config.renderPrimaryCTA = (props: Pick<ButtonProps, 'ctaType' | 'color' | 'fullWidth'>) => (
                <Button {...props} to={getPathByName('RENTER_RESERVATION_REQUEST_FORM')}>
                    {t('cta.reserve')}
                </Button>
            );
        }

        return config;
    }, [onSignOut, session, targetExperience, t]);
}
