import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { useI18n } from 'i18n';

interface TabData {
    key: string;
    name: string;
}

export type TabOption = 'current' | 'upcoming' | 'past' | 'cancelled' | 'declined';

export const isValidTabOption = (value: string | null): boolean => {
    return /^(current|upcoming|past|cancelled|declined)$/.test(value ?? '');
};

export const toTabOption = (value: string | null, fallback: TabOption = 'current'): TabOption => {
    return isValidTabOption(value) ? (value as TabOption) : fallback;
};

interface Props {
    /**
     * System Style overrides, only use for layout adjustments. All other style overrides should be made
     * at the system / theme level
     */
    sx?: BoxProps['sx'];

    /**
     * The currently active tab
     */
    activeTab?: TabOption;

    /**
     * Event handler for updating the activeTab based on the user selection
     */
    onChange: (event: { value: TabOption }) => void;
}

export default function ReservationTabs({ activeTab, onChange, sx }: Props) {
    const { t } = useI18n();

    /**
     * NOTE: ReservationTabs can ensure the exact number of tables is passed in to match tabs,
     * but only if the type is explicit about the length
     */
    const tabs: [TabData, TabData, TabData, TabData, TabData] = [
        { key: 'current', name: t('reservation_table.tabs.current') },
        { key: 'upcoming', name: t('reservation_table.tabs.upcoming') },
        { key: 'past', name: t('reservation_table.tabs.past') },
        { key: 'cancelled', name: t('reservation_table.tabs.cancelled') },
        { key: 'declined', name: t('reservation_table.tabs.declined') },
    ];

    return (
        <Box sx={{ ...sx }}>
            <Tabs
                onChange={(_event, value) => {
                    onChange({ value });
                }}
                value={activeTab}
            >
                {tabs.map((tab, i) => (
                    <Tab label={tab.name} value={tab.key} key={tab.key} />
                ))}
            </Tabs>

            <Divider sx={{ mt: '-1px' }} />
        </Box>
    );
}
