import { ScheduleCellLayer } from 'app/components/compounds/Schedule';

import { Assignment } from 'generated/graphql';

import { Theme } from 'styles/theme';

import {
    createDeviceInstanceTransformer,
    deviceInstanceScheduleToMpuScheduleEvent,
    makeMpuScheduleAvailabilityLayer,
} from '../MpuSchedule';
import { DeviceInstanceWithSchedules, MpuScheduleEvent } from '../MpuSchedule/types';
import { EXPANDED_BAR_OFFSET_MULTIPLICAND, EXPANDED_ROW_TOP_SPACING, Lane } from './constants';

const laneByAssignment: Record<Assignment, Lane> = {
    [Assignment.Charging]: Lane.Unavailable,
    [Assignment.Discharging]: Lane.Unavailable,
    [Assignment.Maintenance]: Lane.Downtime,
    [Assignment.Transit]: Lane.Unavailable,
};

function assignmentToLane(assignment?: Assignment | null): Lane {
    return assignment ? laneByAssignment[assignment] : Lane.Unavailable;
}

function calculateExpandedOffset(lane: Lane): number {
    return EXPANDED_ROW_TOP_SPACING.asValue('px') + EXPANDED_BAR_OFFSET_MULTIPLICAND.asValue('px') * lane;
}

export function deviceInstancesToMpuIndexScheduleEvents(
    theme: Theme,
    deviceInstances: DeviceInstanceWithSchedules[],
): MpuScheduleEvent[] {
    return deviceInstances.reduce<MpuScheduleEvent[]>((events, deviceInstance, deviceInstanceIndex) => {
        const deviceInstanceToScheduleEvents = createDeviceInstanceTransformer({
            deviceInstanceIndex,
            transform({ theme, schedule, deviceInstanceIndex }): MpuScheduleEvent {
                const event = deviceInstanceScheduleToMpuScheduleEvent({ deviceInstance, theme, schedule });
                const lane = assignmentToLane(schedule.assignment);

                return {
                    ...event,
                    expandedOffset: calculateExpandedOffset(lane),
                    rowIndex: deviceInstanceIndex,
                };
            },
        });

        return [...events, ...deviceInstanceToScheduleEvents(theme, deviceInstance)];
    }, []);
}

/**
 * Extends the given availability layer with properties presentation
 */
export function makeMpuIndexScheduleAvailabilityLayer(theme: Theme, layer: ScheduleCellLayer): ScheduleCellLayer {
    return {
        ...makeMpuScheduleAvailabilityLayer(theme, layer),
        expandedOffset: calculateExpandedOffset(Lane.Available),
    };
}
