import { useEffect, useMemo, useState } from 'react';

import {
    GetAccountForProfileQueryResult,
    useGetAccountForProfileQuery,
    useUpdateAccountMutation,
} from 'generated/graphql';

import { AccountUpdateEvent } from './AccountSettings';

export type AccountInfo = NonNullable<GetAccountForProfileQueryResult['data']>['getAccount'];

/**
 * React hook for managing account settings state and server interactions.
 */
const useAccountSettings = ({
    accountId,
    onAccountUpdate,
}: {
    accountId: string;
    onAccountUpdate: (event: AccountUpdateEvent) => void;
}) => {
    const { data, loading, error, refetch } = useGetAccountForProfileQuery({
        skip: !accountId ?? accountId === '',
        variables: { id: accountId },
    });

    const [setAccount] = useUpdateAccountMutation();
    const updateAccount = async updatedFields => {
        if (!accountId) {
            throw new Error('upsertAccount: accountId must be provided');
        }

        const res = await setAccount({
            variables: {
                accountInput: {
                    id: accountId,
                    ...updatedFields,
                },
            },
        });

        onAccountUpdate({
            account: res.data?.updateAccount ?? {},
        });

        return res;
    };

    const accountInfo: AccountInfo = useMemo(() => data?.getAccount ?? { id: '', restrictedUserDomains: [] }, [data]);

    const [companyName, setCompanyName] = useState(accountInfo.name);
    const [companyPhone, setCompanyPhone] = useState(accountInfo.contactPhone);
    const [billingAddressLocation, setBillingAddressLocation] = useState(accountInfo.billingAddressLocation);
    const [restrictedUserDomains, setRestrictedUserDomains] = useState<string[]>(accountInfo.restrictedUserDomains);

    useEffect(() => {
        setCompanyName(accountInfo.name);
        setCompanyPhone(accountInfo.contactPhone);
        setBillingAddressLocation(accountInfo.billingAddressLocation);
        setRestrictedUserDomains(accountInfo.restrictedUserDomains);
    }, [loading, accountInfo]);

    return {
        error,
        loading,

        updateAccount,
        refetchAccount: refetch,

        savedCompanyName: accountInfo.name,
        savedCompanyPhone: accountInfo.contactPhone,
        savedRestrictedUserDomains: accountInfo.restrictedUserDomains,

        companyName,
        companyPhone,
        billingAddressLocation,
        restrictedUserDomains,

        setCompanyName,
        setCompanyPhone,
        setRestrictedUserDomains,
    };
};

export default useAccountSettings;
