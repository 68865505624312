import { DateTime } from 'luxon';

import { Theme } from '@mui/material/styles';

import { ScheduleCellLayer, ScheduleEvent, ScheduleEventVariant } from 'app/components/compounds/Schedule';
import { ISO8601 } from 'app/core/types';

import {
    createDeviceInstanceTransformer,
    deviceInstanceScheduleToMpuScheduleEvent,
    DeviceInstanceWithSchedules,
    hasMpuScheduleEventType,
    iconNameByEventType,
    isMpuScheduleEvent,
    makeMpuScheduleAvailabilityLayer,
    MpuScheduleEvent,
    MpuScheduleEventType,
} from '../MpuSchedule';
import { EXPANDED_BAR_OFFSET_MULTIPLICAND, EXPANDED_ROW_TOP_SPACING, Lane } from './constants';

const expandedBarOffsetMultiplierByLane: Record<Lane, number> = {
    [Lane.Allocation]: 2,
    [Lane.Available]: 1,
    [Lane.Unavailable]: 0,
};

function calculateExpandedOffset(lane: Lane): number {
    return (
        EXPANDED_ROW_TOP_SPACING.asValue('px') +
        EXPANDED_BAR_OFFSET_MULTIPLICAND.asValue('px') * expandedBarOffsetMultiplierByLane[lane]
    );
}

export function reservationItemToUnitAssignmentScheduleEvents({
    end,
    rowCount,
    start,
    theme,
}: {
    end: ISO8601;
    rowCount: number;
    start: ISO8601;
    theme: Theme;
}): MpuScheduleEvent[] {
    const events: MpuScheduleEvent[] = [];
    const expandedOffset = calculateExpandedOffset(Lane.Allocation);

    for (let rowIndex = 0; rowIndex <= rowCount; rowIndex++) {
        // Swap event must be added to the collection before the allocation event,
        // so that it's rendered underneath the allocation event.
        events.push({
            id: `allocation-swap-${rowIndex}`,
            color: theme.palette.background.contrast.faded!,
            end: DateTime.fromISO(end).plus({ hours: 24 }).toISO() ?? '',
            expandedOffset,
            iconName: iconNameByEventType[MpuScheduleEventType.Swap],
            rowIndex,
            schedules: [],
            start: DateTime.fromISO(end).toISO() ?? '',
            type: MpuScheduleEventType.Swap,
            variant: ScheduleEventVariant.Solid,
        });

        events.push({
            id: `allocation-${rowIndex}`,
            color: theme.palette.grey[700]!,
            end,
            expandedOffset,
            hasTail: true,
            rowIndex,
            schedules: [],
            secondaryColor: theme.palette.background.disabled.main,
            start: start,
            type: MpuScheduleEventType.Allocation,
            variant: ScheduleEventVariant.Ghost,
        });
    }

    return events;
}

export function isEventFromAllocation(
    event: ScheduleEvent,
): event is MpuScheduleEvent<MpuScheduleEventType.Allocation | MpuScheduleEventType.Swap> {
    return (
        isMpuScheduleEvent(event) &&
        (hasMpuScheduleEventType(MpuScheduleEventType.Allocation, event) ||
            hasMpuScheduleEventType(MpuScheduleEventType.Swap, event))
    );
}

export function deviceInstancesToUnitAssignmentScheduleEvents({
    deviceInstances,
    theme,
}: {
    deviceInstances: DeviceInstanceWithSchedules[];
    theme: Theme;
}): MpuScheduleEvent[] {
    return deviceInstances.reduce<MpuScheduleEvent[]>((events, deviceInstance, deviceInstanceIndex) => {
        const deviceInstanceToScheduleEvents = createDeviceInstanceTransformer({
            deviceInstanceIndex,
            transform({ theme, schedule, deviceInstanceIndex }): MpuScheduleEvent {
                return {
                    ...deviceInstanceScheduleToMpuScheduleEvent({ deviceInstance, schedule, theme }),
                    expandedOffset: calculateExpandedOffset(Lane.Unavailable),
                    rowIndex: deviceInstanceIndex,
                };
            },
        });

        return [...events, ...deviceInstanceToScheduleEvents(theme, deviceInstance)];
    }, []);
}

/**
 * Extends the given availability layer with properties presentation
 */
export function makeUnitAssignmentScheduleAvailabilityLayer(theme: Theme, layer: ScheduleCellLayer): ScheduleCellLayer {
    return {
        ...makeMpuScheduleAvailabilityLayer(theme, layer),
        expandedOffset: calculateExpandedOffset(Lane.Available),
    };
}
