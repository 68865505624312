import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const textStyles: Style = {
    fontSize: 10,
    lineHeight: 1.25,
    fontFamily: 'Helvetica',
};

const boldTextStyles: Style = {
    ...textStyles,
    fontFamily: 'Helvetica-Bold',
};

export const BaseText = ({ children, style = {} }) => {
    return <Text style={{ ...textStyles, ...style }}>{children}</Text>;
};

export const BoldText = ({ children, style = {} }) => {
    return <Text style={{ ...boldTextStyles, ...style }}>{children}</Text>;
};
