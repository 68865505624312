import { ReactElement, useCallback, useId, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import ClickAway from '@mui/material/ClickAwayListener';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';

import { getSupportEmail, getSupportPhoneNumber } from 'environment';

import { useI18n } from 'i18n';

/**
 * Responsible for exposing contact methods for a user who desires support
 */
export default function ContactMenu(): ReactElement {
    const { t } = useI18n();
    const id = useId();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const containerRef = useRef();

    const supportEmail = getSupportEmail();
    const supportPhoneNumber = getSupportPhoneNumber();

    const handleClickAway = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const toggleOpen = useCallback(() => {
        setIsOpen(x => !x);
    }, [setIsOpen]);

    return (
        <Box ref={containerRef}>
            <ClickAway onClickAway={handleClickAway}>
                <Button
                    fullWidth
                    aria-controls={id}
                    aria-haspopup="true"
                    aria-expanded={isOpen ? 'true' : undefined}
                    ctaType="secondary"
                    icon={<Icon name="send" />}
                    onClick={toggleOpen}
                >
                    {t('cta.contact_us')}
                </Button>
            </ClickAway>

            <Menu
                id={id}
                open={isOpen}
                anchorEl={containerRef.current}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                sx={{ mt: -1 }}
                disableScrollLock
                disablePortal
            >
                {/*
                    Explicitly render email and phone number in case users want to dial the phone number
                    or type the email directly into their preferred email client
                 */}
                <MenuItem component={Link} href={`mailto:${supportEmail}`} target="_blank">
                    {t('cta.email_x', { email: supportEmail })}
                </MenuItem>
                <MenuItem component={Link} href={`tel:${supportPhoneNumber}`} target="_blank">
                    {t('cta.call_x', { phoneNumber: supportPhoneNumber })}
                </MenuItem>
            </Menu>
        </Box>
    );
}
