import { useState } from 'react';

import { useLayer } from 'app/core/layers';

import { useI18n } from 'i18n';

import type { CSVData, DataDownload, DownloadFileArgs } from './types';

export const createDirectDownload = (mimeType: string, data: string): string =>
    `data:${mimeType};charset=utf-8,${encodeURIComponent(data)}`;

export const downloadFile = (fileName: string, dataUri: string): void => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = dataUri;
    a.click();
};

export const serializeJSON = (data: any): string => {
    try {
        return JSON.stringify(data, null, '\t');
    } catch (e) {
        // console.error(e);
        return 'error';
    }
};

export const serializeCSV = ({ columns, rows }: CSVData): string => {
    const labelRow = columns.join(',');
    const dataRows = rows
        .map(
            row => columns.map(key => `${row[key] ?? ''}`).join(','), // join with commas
        )
        .join('\n');

    return `${labelRow}\n${dataRows}`;
};

export const createJSONDataDownload = (name: string, data: any): DataDownload => ({
    name: `${name}.json`,
    toDataURI: () => createDirectDownload(`application/json`, serializeJSON(data)),
    toString: () => serializeJSON(data),
    download: () => downloadFile(`${name}.json`, createDirectDownload(`application/json`, serializeJSON(data))),
});

export const createCSVDataDownload = (name: string, data: CSVData): DataDownload => ({
    name: `${name}.csv`,
    toDataURI: () => createDirectDownload(`text/csv`, serializeCSV(data)),
    toString: () => serializeCSV(data),
    download: () => downloadFile(`${name}.csv`, createDirectDownload(`text/csv`, serializeCSV(data))),
});

export const getFileDownloader = type => {
    const downloaders = {
        csv: createCSVDataDownload,
        json: createJSONDataDownload,
        default: createJSONDataDownload,
    };

    return downloaders[type] || downloaders['default'];
};

export const useDownloadFile = ({ queryFn, fileName, type }: DownloadFileArgs) => {
    const downloader = getFileDownloader(type);
    const { toast } = useLayer();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useI18n();

    const fetchData = async () => {
        if (typeof queryFn !== 'function') return;

        const data = await queryFn();

        return data;
    };

    const download = async () => {
        setIsLoading(true);

        try {
            const data = await fetchData();

            if (!data) return;

            downloader(fileName, data).download();
        } catch (error) {
            toast.add({ severity: 'error', message: t('downloads.error') });
        } finally {
            setIsLoading(false);
        }
    };

    return {
        download,
        isLoading,
    };
};
