import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { Layer, LoadingIndicator, PageHeader, ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { Account } from './useAccount';

interface AccountPageProps {
    account?: Account;
    isLoading: boolean;
    error?: boolean;

    detailsSection: ReactElement;
    domainsSection: ReactElement;
    rolesSection: ReactElement;
    teamMembersSection: ReactElement;
}

export function AccountPage({
    account,
    isLoading,
    error,

    detailsSection,
    domainsSection,
    rolesSection,
    teamMembersSection,
}: AccountPageProps): JSX.Element {
    const { t } = useI18n();

    return (
        <Layer anchor>
            <PageHeader title={account?.name} />

            {isLoading && (
                <Layer height="100vh" display="flex" alignItems="center" relAnchorY="top" y="top">
                    <LoadingIndicator />
                </Layer>
            )}

            {error && !account && (
                <ZeroState
                    title={t('admin_account_page.account_not_found_title')}
                    message={t('admin_account_page.account_not_found_message')}
                    cta={
                        <Button
                            color="secondary"
                            variant="contained"
                            component={Link}
                            to={getPathByName('ACCOUNT_MGMT_ACCOUNTS')}
                        >
                            {t('admin_account_page.see_all_accounts_cta')}
                        </Button>
                    }
                />
            )}

            {!error && !isLoading && account && (
                <>
                    <Stack spacing={6} p={4}>
                        {detailsSection}

                        {domainsSection}

                        {rolesSection}

                        {teamMembersSection}
                    </Stack>
                </>
            )}
        </Layer>
    );
}
