import { FocusEventHandler, MouseEventHandler, ReactNode, useCallback, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import FieldGroup from 'app/components/compounds/FieldGroup/FieldGroup';
import { IANATimeZone, ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import Icon from '../../Icon';
import { DateChangeEvent, DateTimeRangePickerEvent, useDateTimeRangePicker } from '../date-time-inputs';
import DateField from '../DateField';
import { DateRangePopover } from '../DateRangePopover/DateRangePopover';
import Input from '../Input';

export type DateRangeChangeEvent = {
    value: {
        endDate: ISO8601 | null;
        endTimeZone: IANATimeZone;
        startDate: ISO8601 | null;
        startTimeZone: IANATimeZone;
    };
};

interface DateRangePickerProps {
    /**
     * The caption to display below the input.
     */
    caption?: ReactNode;
    /**
     * Determines if the input is disabled.
     */
    disabled?: boolean;
    /**
     * Determines if a date in the past can be selected.
     */
    disablePast?: boolean;
    /**
     * The end date value.
     */
    endDate: ISO8601 | null;
    /**
     * The name of the end date input.
     */
    endName?: string;
    /**
     * The end time zone value.
     */
    endTimeZone?: IANATimeZone;
    /**
     * Determines if the input is in an error state.
     */
    error?: boolean;
    /**
     * The text to display above the input.
     */
    fieldName?: ReactNode;
    /**
     * Determines if the input should take up the full width of its container.
     */
    fullWidth?: boolean;
    /**
     * The maximum date that can be selected.
     */
    maxDate?: ISO8601 | null;
    /**
     * The minimum date that can be selected.
     */
    minDate?: ISO8601 | null;
    /**
     * An event handler that is called when the field group loses focus.
     */
    onBlur?: FocusEventHandler;
    /**
     * An event handler that is called when the value changes.
     */
    onChange?: (event: DateRangeChangeEvent) => void;
    /**
     * An event handler that is called when the field group gains focus.
     */
    onFocus?: FocusEventHandler;
    /**
     * An event handler that is called when an input element loses focus.
     */
    onInputBlur?: FocusEventHandler;
    /**
     * An error message displayed inside the date range popover.
     */
    popoverErrorMessage?: ReactNode;
    /**
     * Determines if the input is required.
     */
    required?: boolean;
    /**
     * Determines if the input is read only.
     */
    readOnly?: boolean;
    /**
     * The start date value.
     */
    startDate: ISO8601 | null;
    /**
     * The name of the start date input.
     */
    startName?: string;
    /**
     * The start time zone value.
     */
    startTimeZone?: IANATimeZone;
}

function CalendarToggleButton({ active, onClick }: { active?: boolean; onClick?: MouseEventHandler }) {
    return (
        <IconButton size="small" onClick={onClick}>
            <Icon name={active ? 'chevron-up' : 'chevron-down'} />
        </IconButton>
    );
}

export default function DateRangePicker({
    caption,
    disabled,
    disablePast,
    endDate,
    endName,
    endTimeZone,
    error,
    fieldName,
    fullWidth,
    maxDate,
    minDate,
    onBlur,
    onChange,
    onFocus,
    onInputBlur,
    popoverErrorMessage,
    readOnly,
    required,
    startDate,
    startName,
    startTimeZone,
}: DateRangePickerProps) {
    const { format } = useI18n();
    const [candidate, setCandidate] = useState<ISO8601 | null>(null);

    const startDateInputRef = useRef<HTMLInputElement | null>(null);
    const startTimeInputRef = useRef<HTMLInputElement | null>(null);
    const endDateInputRef = useRef<HTMLInputElement | null>(null);
    const endTimeInputRef = useRef<HTMLInputElement | null>(null);
    const popperAnchorRef = useRef<HTMLElement | null>(null);

    const handleDateTimeRangePickerChange = useCallback(
        (event: DateTimeRangePickerEvent) => {
            setCandidate(event.value.candidate);

            onChange?.({
                value: {
                    endDate: event.value.endDate,
                    endTimeZone: event.value.endTimeZone,
                    startDate: event.value.startDate,
                    startTimeZone: event.value.startTimeZone,
                },
            });
        },
        [onChange],
    );

    const {
        endError,
        endTimestamp,
        errorMessage: internalErrorMessage,
        errors,
        handleClickAway,
        handleDateRangeChange,
        handleEndChange,
        handleEndError,
        handleEndToggleButtonClick,
        handlePopperAnchorFocus,
        handlePopperAnchorKeyDown,
        handleStartChange,
        handleStartError,
        isEndFocused,
        isOpen,
        isStartFocused,
        maxEndDate,
        maxStartDate,
        minEndDate,
        minStartDate,
        onStartToggleButtonClick,
        rangeError,
        selectedValue,
        selectionTarget,
        selectionTargetMaxDate,
        selectionTargetMinDate,
        startError,
        startTimestamp,
    } = useDateTimeRangePicker({
        candidate,
        endDate,
        endDateInputRef,
        endTime: null,
        endTimeInputRef,
        endTimeZone,
        maxDateTime: maxDate,
        minDateTime: minDate,
        onChange: handleDateTimeRangePickerChange,
        skipTimeFocus: true,
        startDate,
        startDateInputRef,
        startTime: null,
        startTimeInputRef,
        startTimeZone,
    });

    const handleStartDateChange = useCallback(
        ({ value, timeZone }: DateChangeEvent) => {
            handleStartChange({
                value: {
                    date: value,
                    time: null,
                    timeZone: timeZone ?? undefined,
                },
            });
        },
        [handleStartChange],
    );

    const handleEndDateChange = useCallback(
        ({ value, timeZone }: DateChangeEvent) => {
            handleEndChange({
                value: {
                    date: value,
                    time: null,
                    timeZone: timeZone ?? undefined,
                },
            });
        },
        [handleEndChange],
    );

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box
                ref={popperAnchorRef}
                onFocus={handlePopperAnchorFocus}
                onKeyDown={handlePopperAnchorKeyDown}
                sx={{
                    display: 'inline-flex',
                    width: fullWidth ? '100%' : null,
                }}
            >
                <FieldGroup
                    caption={caption}
                    disabled={disabled}
                    error={error || !!errors.length}
                    fieldName={fieldName}
                    fullWidth={fullWidth}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    readOnly={readOnly}
                    readOnlyContent={
                        <Input
                            readOnly
                            value={format.dateRange({
                                start: startTimestamp,
                                end: endTimestamp,
                            })}
                        />
                    }
                    required={required}
                >
                    <FieldGroup.Segment>
                        <DateField
                            disabled={disabled}
                            disablePast={disablePast}
                            endAdornment={
                                <CalendarToggleButton active={isStartFocused} onClick={onStartToggleButtonClick} />
                            }
                            error={error || !!startError || !!rangeError}
                            focused={isStartFocused}
                            fullWidth={fullWidth}
                            hideCaption
                            inputRef={startDateInputRef}
                            maxDate={maxStartDate}
                            minDate={minStartDate}
                            name={startName}
                            onBlur={onInputBlur}
                            onChange={handleStartDateChange}
                            onError={handleStartError}
                            timeZone={startTimeZone}
                            value={startDate}
                        />
                    </FieldGroup.Segment>
                    <FieldGroup.Segment>
                        <DateField
                            disabled={disabled}
                            disablePast={disablePast}
                            endAdornment={
                                <CalendarToggleButton active={isEndFocused} onClick={handleEndToggleButtonClick} />
                            }
                            error={error || !!endError || !!rangeError}
                            focused={isEndFocused}
                            fullWidth={fullWidth}
                            hideCaption
                            inputRef={endDateInputRef}
                            maxDate={maxEndDate}
                            minDate={minEndDate}
                            name={endName}
                            onBlur={onInputBlur}
                            onChange={handleEndDateChange}
                            onError={handleEndError}
                            timeZone={endTimeZone}
                            value={endDate}
                        />
                    </FieldGroup.Segment>
                </FieldGroup>

                <DateRangePopover
                    anchor={popperAnchorRef.current}
                    candidate={candidate}
                    disablePast={disablePast}
                    end={endDate}
                    endTimeZone={endTimeZone}
                    errorMessage={popoverErrorMessage || internalErrorMessage}
                    hasEndError={!!endError}
                    hasStartError={!!startError}
                    maxDate={selectionTargetMaxDate}
                    minDate={selectionTargetMinDate}
                    onChange={handleDateRangeChange}
                    open={isOpen}
                    selectionTarget={selectionTarget}
                    start={startDate}
                    startTimeZone={startTimeZone}
                    value={selectedValue}
                />
            </Box>
        </ClickAwayListener>
    );
}
