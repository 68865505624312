import { ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';

import { Button, Dialog } from 'app/components';

import { useI18n } from 'i18n';

import UpdateUnitCountForm, { UpdateUnitCountFormFields } from './UpdateUnitCountForm';

interface Props extends Omit<UpdateUnitCountFormFields, 'onSubmit'> {
    close: () => void;
    onUpdateUnitCount: (values: UpdateUnitCountFormFields) => void;
}

/**
 * Responsible for updating the number of units (i.e. reservation items) attached to a reservation
 */
export default function UpdateUnitCountDialog({
    close,
    unitCount,
    onUpdateUnitCount = () => undefined,
}: Props): ReactElement {
    const { t } = useI18n();
    const formID = useId();

    const [shouldDisableCTA, setShouldDisableCTA] = useState<boolean>(false);

    return (
        <Dialog
            onClose={close}
            title={<Box>{t('admin_reservation_page.update_unit_count_dialog.update_unit_count_title')}</Box>}
            primaryCTA={
                <Button form={formID} type="submit" disabled={shouldDisableCTA}>
                    {t('admin_reservation_page.cta.add_units')}
                </Button>
            }
        >
            <UpdateUnitCountForm
                id={formID}
                hideSubmitCTA
                unitCount={unitCount}
                onFormStateChange={({ shouldDisableCTA }) => setShouldDisableCTA(shouldDisableCTA)}
                onSubmit={async values => {
                    await onUpdateUnitCount(values);

                    close();
                }}
            />
        </Dialog>
    );
}
