import { DateTime } from 'luxon';

import { ScheduleEvent, ScheduleEventVariant } from 'app/components/compounds/Schedule';
import { byStartingTimestamp } from 'app/core/date-time';
import { DateTimeSpan, ISO8601 } from 'app/core/types';

import {
    Assignment,
    CreateScheduleInput,
    ReservationScheduleDeviceInstanceScheduleFragment,
    ReservationScheduleReservationItemFragment,
} from 'generated/graphql';

import { Theme } from 'styles/theme';

import { dimensions } from './constants';
import { Allocation, AllocationPatch, PendingAllocation, PersistedAllocation, PreparedAllocation, Unit } from './types';
import { isValidPreparedAllocation, MIN_SCHEDULE_DURATION } from './utils';

function deviceInstanceScheduleToAllocation(
    unitID: Unit['id'],
    deviceInstanceSchedule: ReservationScheduleDeviceInstanceScheduleFragment,
): PersistedAllocation {
    return {
        end: deviceInstanceSchedule.end,
        id: deviceInstanceSchedule.id,
        isPersisted: true,
        mpuName: deviceInstanceSchedule.deviceInstance.name,
        mpuID: deviceInstanceSchedule.deviceInstance.id,
        start: deviceInstanceSchedule.start,
        unitID,
    };
}

function makeAllocations(
    reservationItem: ReservationScheduleReservationItemFragment,
    pendingAllocations: readonly PendingAllocation[],
): Allocation[] {
    const unitID = reservationItem.id;
    const completedAllocations = reservationItem.deviceInstanceSchedule
        .filter(({ assignment }) => assignment === Assignment.Discharging)
        .map(deviceInstanceSchedule => deviceInstanceScheduleToAllocation(unitID, deviceInstanceSchedule));
    const unitPendingAllocations = pendingAllocations.filter(pendingAllocation => pendingAllocation.unitID === unitID);

    return [...completedAllocations, ...unitPendingAllocations].sort(byStartingTimestamp);
}

export function makeUnits(
    reservationItems: readonly ReservationScheduleReservationItemFragment[],
    pendingAllocations: readonly PendingAllocation[],
): Unit[] {
    return reservationItems.reduce<Unit[]>((units, reservationItem) => {
        const unit: Unit = {
            alias: reservationItem.alias || undefined,
            allocations: makeAllocations(reservationItem, pendingAllocations),
            end: reservationItem.end,
            id: reservationItem.id,
            start: reservationItem.start,
        };

        return [...units, unit];
    }, []);
}

function makeFulfilledUnitEvents(theme: Theme, unit: Unit, unitIndex: number): ScheduleEvent[] {
    return [
        {
            color: theme.palette.background.contrast.light,
            end: unit.end,
            id: unit.id,
            expandedOffset: 0,
            rowIndex: unitIndex,
            start: unit.start,
            variant: ScheduleEventVariant.Solid,
        },
    ];
}

function makeUnfulfilledUnitEvents(theme: Theme, unit: Unit, unitIndex: number): ScheduleEvent[] {
    return unit.allocations.map<ScheduleEvent>((allocation, index) => {
        const isFirstAllocation = index === 0;
        const isLastAllocation = index === unit.allocations.length - 1;

        return {
            id: allocation.id,
            color: allocation.isPersisted
                ? theme.palette.background.contrast.light
                : theme.palette.background.dangerDark.main,
            end: allocation.end,
            expandedOffset: 0,
            rowIndex: unitIndex,
            start: allocation.start,
            variant: ScheduleEventVariant.Solid,
            isStartCentered: !isFirstAllocation,
            isEndCentered: !isLastAllocation,
        };
    });
}

/**
 * Returns the unallocated time of a unit in time spans
 */
export function getUnallocatedTimeSpans({ allocations, end, start }: Unit): DateTimeSpan[] {
    const unallocatedTimeSpans: DateTimeSpan[] = [];
    const firstAllocation = allocations.at(0);

    if (!firstAllocation) {
        return [{ end, start }];
    }

    const lastAllocation = allocations[allocations.length - 1];
    const hasGapAtStart =
        DateTime.fromISO(firstAllocation.start) >= DateTime.fromISO(start).plus(MIN_SCHEDULE_DURATION);
    const hasGapAtEnd = DateTime.fromISO(lastAllocation.end) <= DateTime.fromISO(end).minus(MIN_SCHEDULE_DURATION);

    if (hasGapAtStart) {
        unallocatedTimeSpans.push({
            end: firstAllocation.start,
            start,
        });
    }
    if (hasGapAtEnd) {
        unallocatedTimeSpans.push({
            end,
            start: lastAllocation.end,
        });
    }

    for (let i = 0; i < allocations.length; i++) {
        const allocation = allocations.at(i);
        const nextAllocation = allocations.at(i + 1);

        if (!allocation || !nextAllocation) break;

        const hasGapInMiddle =
            DateTime.fromISO(nextAllocation.start).diff(DateTime.fromISO(allocation.end)) >= MIN_SCHEDULE_DURATION;

        if (hasGapInMiddle) {
            unallocatedTimeSpans.push({
                end: nextAllocation.start,
                start: allocation.end,
            });
        }
    }

    return unallocatedTimeSpans;
}

function isUnitFulfilled(unit: Unit): boolean {
    const unitDuration = DateTime.fromISO(unit.end).diff(DateTime.fromISO(unit.start)).toMillis();
    const unallocatedDuration = unit.allocations.reduce((result, allocation) => {
        if (!allocation.isPersisted) {
            return result;
        }

        const allocationDuration = DateTime.fromISO(allocation.end).diff(DateTime.fromISO(allocation.start)).toMillis();

        return result - allocationDuration;
    }, unitDuration);

    // Margin of error 1 millisecond
    return unallocatedDuration <= 1;
}

function unitToReservationScheduleEvents(theme: Theme, unit: Unit, unitIndex: number): ScheduleEvent[] {
    if (isUnitFulfilled(unit)) {
        return makeFulfilledUnitEvents(theme, unit, unitIndex);
    }

    return makeUnfulfilledUnitEvents(theme, unit, unitIndex);
}

function allocationsToReservationScheduleEvents(
    theme: Theme,
    allocations: Allocation[],
    unitIndex: number,
): ScheduleEvent[] {
    return allocations.reduce<ScheduleEvent[]>((events, allocation, index) => {
        const isFirstAllocation = index === 0;
        const isLastAllocation = index === allocations.length - 1;
        const expandedOffset =
            dimensions.EXPANDED_OFFSET_BASE.asValue('px') + dimensions.ALLOCATION_HEIGHT.asValue('px') * index;
        const event: ScheduleEvent = {
            end: allocation.end,
            id: allocation.id,
            color: allocation.isPersisted
                ? theme.palette.background.contrast.light
                : theme.palette.background.dangerDark.main,
            rowIndex: unitIndex,
            expandedOffset,
            start: allocation.start,
            variant: allocation.isPersisted ? ScheduleEventVariant.Solid : ScheduleEventVariant.Ghost,
            isStartCentered: !isFirstAllocation,
            isEndCentered: !isLastAllocation,
        };

        return [...events, event];
    }, []);
}

export function unitsToReservationScheduleEvents(theme: Theme, units: readonly Unit[]): ScheduleEvent[] {
    return units.reduce<ScheduleEvent[]>((events, unit, unitIndex) => {
        return [
            ...events,
            ...unitToReservationScheduleEvents(theme, unit, unitIndex),
            ...allocationsToReservationScheduleEvents(theme, unit.allocations, unitIndex),
        ];
    }, []);
}

/**
 * Shrinks the allocation by returning an earlier end timestamp.
 * The resulting duration of the allocation will be half of the original duration.
 *
 * Accuracy isn't really important here, this functionality is only used to make room
 * for adding a new allocation.
 * The user can adjust the new allocation to their liking after it has been added.
 */
export function getShrunkAllocationEnd(allocation: Allocation): ISO8601 {
    const allocationStart = DateTime.fromISO(allocation.start);
    const allocationEnd = DateTime.fromISO(allocation.end);
    const allocationDuration = allocationEnd.diff(allocationStart);

    return (
        allocationStart
            .plus({
                milliseconds: Math.round(allocationDuration.toMillis() / 2),
            })
            .set({
                // Use the allocation start hour to ensure a valid business hour is selected.
                hour: allocationStart.hour,
                minute: 0,
                second: 0,
                millisecond: 0,
            })
            .toISO() ?? ''
    );
}

enum AllocationTimelinePoint {
    DischargingStart = 'dischargingStart',
    DischargingEnd = 'dischargingEnd',
}

/**
 * Creates a map of timestamps representing the schedule for an allocation from the given time frame.
 * Timestamps are both named and ordered.
 *
 * @remarks
 *
 *
 */
export function makeAllocationTimeline(timeSpan: DateTimeSpan): Map<`${AllocationTimelinePoint}`, ISO8601> {
    const dischargingStart = DateTime.fromISO(timeSpan.start);
    const dischargingEnd = DateTime.fromISO(timeSpan.end);

    return new Map([
        [AllocationTimelinePoint.DischargingStart, dischargingStart.toISO() ?? ''],
        [AllocationTimelinePoint.DischargingEnd, dischargingEnd.toISO() ?? ''],
    ]);
}

/**
 * Creates an array of timestamps representing the schedule for an allocation from the given time frame.
 */
export function makeAllocationTimelineRecord(timeSpan: DateTimeSpan) {
    return Object.fromEntries(makeAllocationTimeline(timeSpan).entries()) as Record<
        `${AllocationTimelinePoint}`,
        ISO8601
    >;
}

/**
 * Creates an object of timestamps representing the schedule for an allocation from the given time frame.
 */
export function makeAllocationTimelineArray(timeSpan: DateTimeSpan): ISO8601[] {
    return Array.from(makeAllocationTimeline(timeSpan).values());
}

/**
 * Creates an array of inputs for creating device instance schedules for the given allocation.
 *
 * @remarks
 *
 * This function will evolve significantly as we add more features to support more flexible schedules.
 */
export function allocationToDeviceInstanceSchedules(
    allocation: PreparedAllocation | PersistedAllocation,
): CreateScheduleInput[] {
    const deviceInstance = { id: allocation.mpuID };
    const reservationItem = { id: allocation.unitID };
    const allocationTimeline = makeAllocationTimelineRecord(allocation);

    return [
        {
            assignment: Assignment.Discharging,
            deviceInstance,
            end: allocationTimeline[AllocationTimelinePoint.DischargingEnd],
            reservationItem,
            start: allocationTimeline[AllocationTimelinePoint.DischargingStart],
        },
    ];
}

/**
 * Create a prepared allocation from the given non-persisted allocation and allocation patch.
 */
export function nonPersistedToPreparedAllocation(
    allocation: PendingAllocation | PreparedAllocation,
    allocationUpdate: AllocationPatch,
): PreparedAllocation | undefined {
    const updatedAllocation = { ...allocation, ...allocationUpdate };

    return isValidPreparedAllocation(updatedAllocation) ? updatedAllocation : undefined;
}

/**
 * Create a prepared allocation from the given persisted allocation and allocation patch.
 */
export function persistedToPreparedAllocation(
    { id, ...persistedAllocation }: PersistedAllocation,
    allocationUpdate: AllocationPatch,
): PreparedAllocation {
    return {
        ...persistedAllocation,
        ...allocationUpdate,
        isPersisted: false,
    };
}
