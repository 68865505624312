import { useMemo, useRef } from 'react';

import { useLayer } from 'app/core/layers';
import type { LayerItemFacade } from 'app/core/layers/types';

import { DowntimePeriodDialog, DowntimePeriodDialogParams } from './DowntimePeriodDialog';
import { ScheduleChangeEvent } from './types';
import { useRemoveDowntimePeriodConfirm } from './useRemoveDowntimePeriodConfirm';

interface UseDowntimeDialogProps
    extends Pick<DowntimePeriodDialogParams, 'onSubmit' | 'previewColor' | 'previewVariant' | 'saveText' | 'title'> {
    /**
     * A callback invoked after the downtime period has been added.
     */
    onScheduleChange?: (event: ScheduleChangeEvent) => void;
}

interface OpenHandlerProps {
    /**
     * The ID of the MPU associated with the downtime period.
     */
    mpuID: string;
    /**
     * The ID of the schedule being edited.
     */
    targetScheduleID?: string;
}

/**
 * Handlers for opening and closing a dialog for adding or editing a downtime period.
 */
interface DowntimePeriodDialogHandlers {
    open: (props: OpenHandlerProps) => void;
    close: () => void;
}

/**
 * @returns an object with methods to open and close a dialog for adding or editing a downtime period.
 */
export function useDowntimePeriodDialog(props: UseDowntimeDialogProps): DowntimePeriodDialogHandlers {
    const { dialog } = useLayer();
    const openDialog = useRef<LayerItemFacade | undefined>();
    const confirmRemove = useRemoveDowntimePeriodConfirm();
    const { onScheduleChange, onSubmit, previewColor, previewVariant, saveText, title } = props;

    return useMemo<DowntimePeriodDialogHandlers>(() => {
        function open({ mpuID, targetScheduleID }: OpenHandlerProps) {
            openDialog.current = dialog.add(layerItem => {
                function handleRemove() {
                    if (!targetScheduleID) return;

                    confirmRemove({
                        targetScheduleID,
                        onScheduleChange: () => {
                            onScheduleChange?.({ value: { mpuID } });
                            layerItem.close();
                        },
                    });
                }

                const downtimePeriodDialog = new DowntimePeriodDialog({
                    layerItem,
                    mpuID,
                    onRemove: handleRemove,
                    onSubmit,
                    previewColor,
                    previewVariant,
                    saveText,
                    targetScheduleID,
                    title,
                });

                return downtimePeriodDialog.renderProps();
            });
        }

        function close() {
            openDialog.current?.close();
        }

        return { open, close };
    }, [confirmRemove, dialog, onScheduleChange, onSubmit, previewColor, previewVariant, saveText, title]);
}
