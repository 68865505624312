import { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Box from '@mui/material/Box';

import { AccountRoleSelector, Button } from 'app/components';

import { ModifyAccountRolesInput } from 'generated/graphql';

import { useI18n } from 'i18n';

import { FormStateChange } from '../../types';

interface EditAccountRolesFormProps {
    /**
     * The unique ID for the account
     */
    accountID: string;

    /**
     * The unique ID for the form
     */
    formID: string;

    /**
     * Whether the form is readOnly or not
     */
    readOnly?: boolean;

    /**
     * Whether to hide the submit button if being provided elsewhere outside the form
     */
    hideSubmitCTA?: boolean;

    /**
     * Whether to reset the form when going from edit mode to readOnly
     */
    enableResetOnModeChange?: boolean;

    /**
     * Event handler called when the form state changes
     */
    onFormStateChange?: (event: FormStateChange<Omit<ModifyAccountRolesInput, 'accountID'>>) => void;

    /**
     * Event handler called when the user initiates the form submission
     */
    onSubmit: (input: { value: Omit<ModifyAccountRolesInput, 'accountID'> }) => Promise<void>;

    accountRoleNames?: string[];
}

export default function EditAccountRolesForm({
    formID,
    accountRoleNames = [],

    readOnly = false,
    hideSubmitCTA = false,
    enableResetOnModeChange = true,

    onFormStateChange,
    onSubmit,
}: EditAccountRolesFormProps) {
    const { t } = useI18n();
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            accountRoleNames,
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: object().shape({
            accountRoleNames: array()
                .min(1, t('admin_account_page.roles.validation.at_least_one_role_is_required'))
                .of(string())
                .required(),
        }),
        onSubmit: async roleNames => {
            await onSubmit({ value: roleNames });
            form.resetForm();
        },
    });

    const { isValid, dirty, values } = form;
    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({
            shouldDisableCTA,
            isDirty: dirty,
            isValid,
            values,
        });
    }, [shouldDisableCTA, dirty, onFormStateChange, isValid, values]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resetForm = useRef(form.resetForm.bind(form));

    useEffect(() => {
        if (enableResetOnModeChange && readOnly) {
            resetForm.current();
        }
    }, [readOnly, resetForm, enableResetOnModeChange]);

    return (
        <form id={formID} onSubmit={form.handleSubmit}>
            <AccountRoleSelector
                name="accountRoleNames"
                fieldName={t('admin_account_page.roles.label.account_roles')}
                value={values.accountRoleNames}
                onChange={({ name, value }) => name && form.setFieldValue(name, value)}
                variant="compact"
                readOnly={readOnly}
                required
            />

            {!hideSubmitCTA && (
                <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_account_page.cta.save_changes')}
                    </Button>
                </Box>
            )}
        </form>
    );
}
