import { Dispatch, ReactElement, ReactNode, useCallback, useMemo, useReducer, useRef } from 'react';
import { ContextSelector, createContext, useContextSelector } from '@fluentui/react-context-selector';
import { AnyAction } from 'typescript-fsa';

import { EventEmitter } from 'app/core';

/**
 * React's `useReducer` updates state asynchronously.
 * The version of `dispatch` will return a promise that
 * resolves on the next microtask to facilitate waiting
 * for state to update.
 */
export type DispatchAsync = (action: AnyAction) => Promise<void>;

/**
 * Creates an object for listening to dispatches of actions.
 */
function useActionEventEmitter() {
    const ACTION_DISPATCHED = 'actionDispatched';
    const actionEmitter = useRef(new EventEmitter());

    return useMemo(() => {
        type ActionListener = (action: AnyAction) => void;
        type Unsubscribe = () => void;

        return {
            emit(action: AnyAction) {
                // Ensure that the operation is async for consistency.
                queueMicrotask(() => {
                    actionEmitter.current?.emit(ACTION_DISPATCHED, action);
                });
            },
            on(listener: ActionListener): Unsubscribe {
                const removeListener = actionEmitter.current?.on(ACTION_DISPATCHED, event =>
                    listener(event.data as AnyAction),
                );

                return () => removeListener?.();
            },
        };
    }, []);
}

interface ReducerContextPayload<State, Data> {
    /**
     * For holding arbitrary component data in the context.
     */
    data: Data;
    /**
     * React's dispatch that returns a promise for the next microtask.
     */
    dispatch: DispatchAsync;
    /**
     * A function to retrieve the latest state from the reducer.
     * The function itself will not update, and can be safely past
     * through component props without triggering updates.
     */
    getState: () => State;
    /**
     * Add a listener that is called for every action that's dispatched.
     */
    onActionDispatch: ReturnType<typeof useActionEventEmitter>['on'];
    /**
     * The latest state from the reducer.
     */
    state: State;
}

type ReducerContextAccessor<State, Data> = () => ReducerContextPayload<State, Data>;

function createReducerContextComponents<State, Data>(defaultValue: ReducerContextAccessor<State, Data>) {
    return createContext(defaultValue);
}

function useStateAccessor<State>(state: State) {
    const stateRef = useRef(state);
    // Ensure that `stateRef` is always up-to-date with the latest state object
    stateRef.current = state;
    // `getState` should never change. It can safely be passed around without triggering updates
    return useCallback(() => stateRef.current, []);
}

function logDispatch<State = unknown>({
    action,
    nextState,
    prevState,
}: {
    action: AnyAction;
    nextState: State;
    prevState: State;
}): void {
    /* eslint-disable no-console */
    console.groupCollapsed(
        `%caction %c${action.type} %c@ ${new Date().toLocaleString()}`,
        'color: gray;',
        'color: inherit;',
        'color: gray;',
    );
    console.info('%cprev state', 'color: gray;', prevState);
    console.info('%caction', 'color: lightskyblue;', action);
    console.info('%cnext state', 'color: lightgreen;', nextState);
    console.groupEnd();
    /* eslint-enable no-console */
}

/**
 * The context value will change whenever `state` changes,
 * i.e. it shouldn't be passed around through props, because it'll
 * trigger unnecessary component updates.
 */
function useReducerContextAccessor<State, Data>({
    data,
    debug,
    dispatch,
    state,
}: {
    data: Data;
    debug?: boolean;
    dispatch: Dispatch<AnyAction>;
    state: State;
}): ReducerContextAccessor<State, Data> {
    const actionEventEmitter = useActionEventEmitter();
    const getState = useStateAccessor(state);

    const dispatchAsync = useCallback(
        async (action: AnyAction): Promise<void> => {
            const prevState = getState();

            dispatch(action);
            actionEventEmitter?.emit(action);

            // Wait for the next microtask, because `useReducer` is asynchronous and updates state on render.
            await Promise.resolve();

            if (debug) logDispatch({ action, nextState: getState(), prevState });
        },
        [actionEventEmitter, debug, dispatch, getState],
    );

    const payload = useMemo((): ReducerContextPayload<State, Data> => {
        return {
            data,
            dispatch: dispatchAsync,
            getState,
            onActionDispatch: actionEventEmitter.on,
            state,
        };
    }, [data, dispatchAsync, getState, actionEventEmitter, state]);

    return useCallback<ReducerContextAccessor<State, Data>>(() => payload, [payload]);
}

interface UseContextValueParams<State, Data, DefaultStateOptions> {
    createDefaultState: (options: DefaultStateOptions) => State;
    data: Data;
    debug?: boolean;
    options: DefaultStateOptions;
    reducer: (state: State, action: AnyAction) => State;
}

export interface ReducerContext<State, Data> {
    /**
     * The context provider for the reducer state.
     */
    Provider(props: { children: ReactNode; value: ReducerContextAccessor<State, Data> }): ReactElement;
    /**
     * A hook to select constructs from the context using a selector function.
     */
    useContext<T = ReducerContextPayload<State, Data>>(
        selector?: ContextSelector<ReducerContextPayload<State, Data>, T>,
    ): T;
    /**
     * A hook to create the context value that given to the `Provider`
     */
    useContextValue<DefaultStateOptions = {}>(
        params: UseContextValueParams<State, Data, DefaultStateOptions>,
    ): ReducerContextAccessor<State, Data>;
    /**
     * A utility hook for easy access to the `dispatch` function.
     */
    useDispatch(): DispatchAsync;
    /**
     * A hook to select data from the reducer state using a selector function.
     */
    useReducerState<T>(selector: (state: State) => T): T;
}

export type ExtractReducerContextAccessor<T extends ReducerContext<any, any>> = ReturnType<T['useContextValue']>;
export type ExtractReducerContext<T extends ReducerContext<any, any>> = ReturnType<ExtractReducerContextAccessor<T>>;

/**
 * Combines React's `useReducer` hook with React context to facilitate management
 * of complex state within a deeply nested component.
 */
export function createReducerContext<State, Data>(): ReducerContext<State, Data> {
    const defaultContextValue: ReducerContextAccessor<State, Data> = () => {
        throw new Error('Operation requires the use of a provider component.');
    };

    const ReducerContext = createReducerContextComponents(defaultContextValue);

    function useContextValue<DefaultStateOptions = {}>({
        createDefaultState,
        data,
        debug,
        options,
        reducer,
    }: UseContextValueParams<State, Data, DefaultStateOptions>) {
        const [state, dispatch] = useReducer(reducer, options, createDefaultState);

        return useReducerContextAccessor({
            data,
            debug,
            dispatch,
            state,
        });
    }

    function Provider({
        children,
        value,
    }: {
        children: ReactNode;
        value: ReducerContextAccessor<State, Data>;
    }): ReactElement {
        return <ReducerContext.Provider value={value}>{children}</ReducerContext.Provider>;
    }

    function useContext<T = ReducerContextPayload<State, Data>>(
        selector?: ContextSelector<ReducerContextPayload<State, Data>, T>,
    ): T {
        return useContextSelector(ReducerContext, getContext => {
            if (selector) {
                return selector(getContext());
            }

            return getContext() as T;
        });
    }

    function useDispatch(): DispatchAsync {
        return useContext(({ dispatch }) => dispatch);
    }

    function useReducerState<T>(selector: (state: State) => T): T {
        return useContext(payload => selector(payload.state));
    }

    return {
        Provider,
        useContext,
        useContextValue,
        useDispatch,
        useReducerState,
    };
}
