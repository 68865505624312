import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import { DateTimeSpan } from 'app/core/types';

import { useGetReservationScheduleReservationQuery } from 'generated/graphql';

import { useMpuIndexScheduleDeviceInstanceRequest } from '../MpuIndexSchedule/hooks';
import { makeAllocationTimelineArray } from './transformers';
import { AllocationSchedule, PersistedAllocation } from './types';

/**
 * Fetch all reservation items for a given reservation.
 */
export function useReservationItems(reservationID: string) {
    const { data, error, loading, refetch } = useGetReservationScheduleReservationQuery({
        variables: { reservationID },
    });

    return {
        data: data?.getReservation.reservationItems,
        error,
        loading,
        refetch,
    };
}

export function useReservationDateTimeSpan(timeSpans: readonly DateTimeSpan[]) {
    return useMemo((): DateTimeSpan => {
        const rangeDateTimes = timeSpans.reduce<{ end?: DateTime; start?: DateTime }>(
            (range, unit) => {
                const unitEnd = DateTime.fromISO(unit.end);
                const unitStart = DateTime.fromISO(unit.start);

                if (!range.start || unitStart < range.start) {
                    range.start = unitStart;
                }
                if (!range.end || unitEnd > range.end) {
                    range.end = unitEnd;
                }

                return range;
            },
            { end: undefined, start: undefined },
        );

        const endDateTime = rangeDateTimes.end || DateTime.now().minus({ week: 1 });
        const startDateTime = rangeDateTimes.start || DateTime.now().plus({ week: 1 });

        return {
            end: endDateTime.toISO() ?? '',
            start: startDateTime.toISO() ?? '',
        };
    }, [timeSpans]);
}

export function useGetAllocationSchedules() {
    const getDeviceInstance = useMpuIndexScheduleDeviceInstanceRequest();

    return useCallback(
        async (allocation: PersistedAllocation): Promise<AllocationSchedule[]> => {
            const allocationTimeline = makeAllocationTimelineArray(allocation);
            const start = allocationTimeline[0];
            const end = allocationTimeline[allocationTimeline.length - 1];
            const { schedules } = await getDeviceInstance({
                id: allocation.mpuID,
                start,
                end,
            });

            return schedules || [];
        },
        [getDeviceInstance],
    );
}
