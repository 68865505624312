import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

interface Props {
    /**
     * Controls presentation communicating the type of feedback
     */
    type?: 'error' | 'info' | 'success';
    /**
     * Controls whether to show the toast message
     */
    isOpen: boolean;
    /**
     * The message to show the user
     */
    message: string | ReactElement;
    /**
     * Event handler to be called when the toast should close. The component is controlled so isOpen will need to be
     * updated accordingly
     */
    onClose: () => void;
    /**
     * Snackbar styling
     */
    sx?: SnackbarProps['sx'];
}

/**
 * Component to provide basic feedback to a user.
 * @deprecated use useLayer from 'app/core/layers' instead
 */
export default function Toast({ type = 'info', isOpen, message, onClose, sx }: Props) {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={onClose}
            sx={sx}
        >
            <Alert severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
