import { useMemo, useState } from 'react';

import { useLayer } from 'app/core/layers';

import {
    AccountLocationInput,
    AccountLocationUpdateInput,
    useCreateAccountLocationForProfileMutation,
    useDeleteAccountLocationForProfileMutation,
    useUpdateAccountLocationForProfileMutation,
    useUpdateAccountMutation,
} from 'generated/graphql';

import { useI18n } from 'i18n';

import { AccountInfo } from '../../../pages/ProfilePage/AccountSettings/useAccountSettings';
import { SelectedLocation } from '../../compounds/EditAddressModal/types';
import { AccountLocations, AccountLocationsProps } from './presentation';

interface BaseProps
    extends Omit<
        AccountLocationsProps,
        | 'onEdit'
        | 'onSave'
        | 'onDelete'
        | 'onCloseEditModal'
        | 'errorMessage'
        | 'isEditModalOpen'
        | 'onLocationSelected'
    > {
    accountID: string;
    canEdit?: boolean;
    canAdd?: boolean;
    onSaveLocation?: () => void;
    onSaveNewLocation?: () => void;
    onDeleteLocation?: () => void;
}

interface CanDeleteProps extends BaseProps {
    canDelete?: boolean;
    isBillingAddress?: never;
}

// if this component is used for a billing address, it should accept 0-1 locations,
// and it should not be possible to delete any location
interface BillingAddressProps extends Omit<BaseProps, 'accountLocations'> {
    accountLocations: [NonNullable<AccountInfo['billingAddressLocation']>] | [];
    canDelete?: never;
    isBillingAddress?: boolean;
}

type AccountLocationsContainerProps = CanDeleteProps | BillingAddressProps;

export function AccountLocationsContainer({
    accountID,
    canEdit = false,
    canDelete = false,
    canAdd = false,
    isBillingAddress = false,
    onSaveLocation,
    onSaveNewLocation,
    onDeleteLocation,
    ...rest
}: AccountLocationsContainerProps) {
    const { t } = useI18n();
    const { toast } = useLayer();

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [updatingID, setUpdatingID] = useState<string>();

    const [updateAccountLocation] = useUpdateAccountLocationForProfileMutation();
    const [deleteAccountLocation] = useDeleteAccountLocationForProfileMutation();
    const [createAccountLocation] = useCreateAccountLocationForProfileMutation();
    const [updateAccount] = useUpdateAccountMutation();

    const onEdit = useMemo(() => {
        if (!canEdit) return undefined;

        return id => {
            setUpdatingID(id);
            setIsEditModalOpen(true);
        };
    }, [canEdit]);

    const onAdd = useMemo(() => {
        if (!canAdd) return undefined;

        return () => setIsEditModalOpen(true);
    }, [canAdd]);

    const onSave = useMemo(() => {
        if (!canEdit) return undefined;

        return async (
            accountLocationID: string,
            partialInput: Omit<AccountLocationUpdateInput, 'accountID' | 'id'>,
        ) => {
            const accountLocationUpdateInput: AccountLocationUpdateInput = {
                ...partialInput,
                accountID,
                id: accountLocationID,
            };
            try {
                await updateAccountLocation({
                    variables: { accountLocationUpdateInput },
                });
            } catch (error) {
                toast.add({ severity: 'error', message: t('account_locations.update_location_error_message') });

                throw error;
            }

            if (onSaveLocation) onSaveLocation();
            return true;
        };
    }, [accountID, canEdit, onSaveLocation, updateAccountLocation, t, toast]);

    const onDelete = useMemo(() => {
        if (!canDelete) return undefined;

        return async (accountLocationID: string) => {
            try {
                await deleteAccountLocation({ variables: { accountID, accountLocationID } });
            } catch (error) {
                // TODO: trigger toast feedback
                return;
            }

            if (onDeleteLocation) onDeleteLocation();
        };
    }, [accountID, canDelete, deleteAccountLocation, onDeleteLocation]);

    const onSaveNew = useMemo(() => {
        if (!canAdd) return undefined;

        return async (accountLocationInputRaw: Omit<AccountLocationInput, 'accountId'>) => {
            const accountLocationInput = { ...accountLocationInputRaw, accountId: accountID };
            try {
                const { data } = await createAccountLocation({
                    variables: { accountLocationInput },
                });

                if (isBillingAddress && data?.createAccountLocation) {
                    await updateAccount({
                        variables: {
                            accountInput: {
                                id: accountID,
                                billingAddressLocationID: data?.createAccountLocation.id,
                            },
                        },
                    });
                }
            } catch (error) {
                toast.add({ severity: 'error', message: t('account_locations.add_location_error_message') });

                throw error;
            }

            if (onSaveNewLocation) onSaveNewLocation();
            return true;
        };
    }, [accountID, canAdd, createAccountLocation, isBillingAddress, onSaveNewLocation, updateAccount, t, toast]);

    const onCloseEditModal = () => {
        setIsEditModalOpen(false);
        setUpdatingID(undefined);
    };

    const onLocationSelected = useMemo(() => {
        if (!(canEdit || canAdd)) return undefined;

        return async (location: SelectedLocation) => {
            if (updatingID && onSave) {
                await onSave(updatingID, location);
                onCloseEditModal();
            } else if (onSaveNew) {
                await onSaveNew(location);
                onCloseEditModal();
            }
        };
    }, [canAdd, canEdit, onSave, onSaveNew, updatingID]);

    return (
        <AccountLocations
            {...rest}
            onEdit={onEdit}
            onAdd={onAdd}
            isEditModalOpen={isEditModalOpen}
            onCloseEditModal={onCloseEditModal}
            updatingID={updatingID}
            onSave={onSave}
            onDelete={onDelete}
            onSaveNew={onSaveNew}
            onLocationSelected={onLocationSelected}
        />
    );
}
