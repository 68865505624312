import { ComponentProps, forwardRef, ReactElement, Ref, useRef } from 'react';

import { useI18n } from 'i18n';

import useCombineRefs from '../../../useCombineRefs';
import Input from '../Input';
import useInputFilter from '../useInputFilter';

interface Props extends ComponentProps<typeof Input> {
    namespace?: string;
    value?: string | null | undefined;
}

const ns = (namespace: string | undefined, value: string) => (namespace ? `${namespace} ${value}` : value);

/**
 * PhoneField is a controlled input that handles phone number input. It wraps MUI TextField and
 * accepts the same props. Some props have been set as they relate to phone numbers.
 * See storybook for examples.
 *
 * For now our handling of phone numbers is very light touch. We don't make any assumptions about formats or
 * validate inputted values. For now users can type in anything phone number related including formatting characters.
 */
const PhoneField = forwardRef((props: Props, ref: Ref<HTMLInputElement>): ReactElement => {
    const internalRef = useRef<HTMLInputElement | null>(null);
    const combinedRefs = useCombineRefs<HTMLInputElement | null>(internalRef, ref);
    const { t } = useI18n();

    useInputFilter({
        inputRef: internalRef,
        filter: value => {
            return /^[\d\s\-+().]*$/.test(value);
        },
        errorMessage: t('phone_field.unrecognized_character_warning'),
    });

    return (
        <Input
            {...props}
            name={props.name ?? 'telephone'}
            type="tel"
            ref={combinedRefs}
            value={props?.value ?? ''}
            autoComplete={ns(props.namespace, props.name ?? 'telephone')}
            minLength={7}
            maxLength={24}
            inputProps={{ pattern: '[\\+\\(\\)\\.\\-\\s\\d]{7,24}' }}
            onChange={props.onChange}
        />
    );
});

export default PhoneField;
