import { ReactElement, ReactNode } from 'react';
import type * as CSS from 'csstype';

import Stack from '@mui/material/Stack';

enum ScheduleToolbarVariant {
    Left = 'left',
    Right = 'right',
}

const justifyContentByVariant: Record<ScheduleToolbarVariant, CSS.DataType.ContentPosition> = {
    [ScheduleToolbarVariant.Left]: 'start',
    [ScheduleToolbarVariant.Right]: 'end',
};

/**
 * Presentational component for adding a toolbar to the `Schedule` component.
 */
export function ScheduleToolbarWrapper({
    children,
    variant = ScheduleToolbarVariant.Left,
}: {
    children: ReactNode;
    variant?: `${ScheduleToolbarVariant}`;
}): ReactElement {
    return (
        <Stack direction="row" spacing={4} justifyContent={justifyContentByVariant[variant]}>
            {children}
        </Stack>
    );
}
