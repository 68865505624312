import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import { APIKey, ServiceAccount } from '../types';

interface ServiceAccountAPIKeysProps {
    serviceAccount: Omit<ServiceAccount, 'apiKeys'> & {
        apiKeys?: APIKey[];
    };
}

export function ServiceAccountAPIKeysTable({ serviceAccount }: ServiceAccountAPIKeysProps) {
    const { t } = useI18n();

    const apiKeys = serviceAccount.apiKeys ?? [];

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width={`30%`}>
                        <Text variant="detailMedium">{t('api_keys.key_id')}</Text>
                    </TableCell>

                    <TableCell width={`35%`}>
                        <Text variant="detailMedium">{t('api_keys.issued_at')}</Text>
                    </TableCell>

                    <TableCell width={`35%`}>
                        <Text variant="detailMedium">{t('api_keys.not_after')}</Text>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {apiKeys.map((apiKey, idx) => (
                    <TableRow
                        key={apiKey.id}
                        sx={{
                            ':last-child > *': { borderBottom: 'none' },
                        }}
                    >
                        <TableCell>{apiKey.id}</TableCell>
                        <TableCell>{apiKey.issuedAt}</TableCell>
                        <TableCell>{apiKey.notAfter}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
