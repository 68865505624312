import { ComponentProps } from 'react';

import Box from '@mui/material/Box';
import MuiTooltip from '@mui/material/Tooltip';

import { normalizeSxProp } from 'app/components/normalizeSxProp';
import { Text } from 'app/components/primitives';

import useTheme from 'styles/theme';

type MuiTooltipProps = ComponentProps<typeof MuiTooltip>;

interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
    content: MuiTooltipProps['title'];
}

/**
 * MUI Tooltip with Moxion theming and defaults.
 *
 * @see {@link https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=161-4711&mode=dev | Figma design reference}
 * @see {@link https://mui.com/material-ui/api/tooltip/ | Tooltip API}
 */
export function Tooltip(props: TooltipProps) {
    const theme = useTheme();
    const { children, content, slotProps, ...otherProps } = props;
    const nextProps: Omit<MuiTooltipProps, 'children' | 'title'> = {
        arrow: true,
        placement: 'top',
        slotProps: {
            ...slotProps,
            arrow: {
                ...slotProps?.arrow,
                sx: [
                    {
                        color: theme.palette.background.contrast.main,
                    },
                    ...normalizeSxProp(slotProps?.arrow?.sx),
                ],
            },
            tooltip: {
                ...slotProps?.tooltip,
                sx: [
                    {
                        bgcolor: theme.palette.background.contrast.main,
                        p: 3,
                    },
                    ...normalizeSxProp(slotProps?.tooltip?.sx),
                ],
            },
        },
        ...otherProps,
    };

    return (
        <MuiTooltip
            {...nextProps}
            title={
                <Box
                    component={Text}
                    variant="detail"
                    sx={{ pr: 2, mr: -2, maxHeight: 300, overflow: 'auto', display: 'flex', flexDirection: 'column' }}
                >
                    {content}
                </Box>
            }
        >
            {children}
        </MuiTooltip>
    );
}
