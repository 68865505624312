import { ScheduleTimeCell } from 'app/components/compounds/Schedule/ScheduleTimeCell';
import { withProps } from 'app/components/withProps';

import { HEADING_BOTTOM_PADDING } from './constants';

export const UnitAssignmentHeadingCell = withProps(ScheduleTimeCell, props => ({
    ...props,
    sx: {
        paddingBottom: HEADING_BOTTOM_PADDING.as('px'),
    },
}));
