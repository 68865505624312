import { ComponentProps, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import FieldCaption from '../../FieldCaption';
import FieldLabel from '../../FieldLabel';
import Text from '../../Text';

interface Props extends ComponentProps<typeof Box> {
    /**
     * The label / name for the field value, describing what the value represents
     */
    fieldName: ReactNode;

    /**
     * The value
     */
    value?: ReactNode;

    /**
     * Whether to fill the parent width
     */
    fullWidth?: boolean;

    /**
     * The caption content
     */
    caption?: ReactNode;
}

/**
 * This is the layout for readOnly Inputs where the readOnly content should wrap. It can be used instead of Input when
 * the content will be always readOnly.
 */
export default function FieldValue({ fieldName, caption, value, fullWidth, ...props }: Props): ReactElement {
    return (
        <Box {...props} display="inline-flex" flexDirection="column" width={fullWidth ? '100%' : 240}>
            {!!fieldName && <FieldLabel fieldName={fieldName} gutterBottom />}

            <Text my={3} color="secondary" preserveHeight={1} tabIndex={0}>
                {value}
            </Text>

            {!!caption && <FieldCaption caption={caption} />}
        </Box>
    );
}
