import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Button, Input, PhoneField } from 'app/components';

import { useI18n } from 'i18n';

import { FormStateChange } from '../../types';
import { User } from '../types';

interface EditUserDetailsFormFields {
    userID: User['id'];

    name: User['name'];

    email: User['email'];

    phoneNumber: User['phoneNumber'];
}

type SubmittedFields = Pick<User, 'id' | 'name' | 'email' | 'phoneNumber'>;

interface EditUserDetailsFormProps extends Partial<EditUserDetailsFormFields> {
    /**
     * The unique id identifying the form in the document
     */
    id?: string;

    /**
     * Whether the form is readOnly or not
     */
    readOnly?: boolean;

    /**
     * Whether to hide the submit button if being provided elsewhere outside the form
     */
    hideSubmitCTA?: boolean;

    /**
     * Whether to reset the form when going from edit mode to readOnly
     */
    enableResetOnModeChange?: boolean;

    /**
     * Event handler called when the form state changes
     */
    onFormStateChange?: (event: FormStateChange<SubmittedFields>) => void;

    /**
     * Event handler called when the user initiates the form submission
     */
    onSubmit: (userChanges: { value: SubmittedFields }) => Promise<void>;
}

/**
 * Responsible for rendering a form for editing basic user details
 */
export default function EditUserDetailsForm({
    id,
    userID,
    name,
    email,
    phoneNumber,
    readOnly = false,
    hideSubmitCTA = false,
    enableResetOnModeChange = true,

    onFormStateChange,
    onSubmit,
}: EditUserDetailsFormProps): ReactElement {
    const { t } = useI18n();

    const form = useFormik<EditUserDetailsFormFields>({
        initialValues: {
            userID: userID ?? '',
            name: name ?? '',
            email: email ?? '',
            phoneNumber: phoneNumber ?? '',
        },
        enableReinitialize: true,

        validateOnMount: false,
        validateOnChange: true,
        validateOnBlur: true,
        // TODO: find a way to restrict the schema keys to UserInput
        validationSchema: object().shape({
            name: string().required(t('admin_user_page.name_is_required')),
            email: string()
                .email(t('admin_user_page.email_is_invalid'))
                .required(t('admin_user_page.email_is_required')),
        }),
        onSubmit: async values => {
            const { userID, ...rest } = values;

            await onSubmit({ value: { id: userID, ...rest } });
        },
    });

    const { isValid, dirty, values, errors } = form;
    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        const { userID, ...rest } = values;

        onFormStateChange?.({
            shouldDisableCTA,
            isDirty: dirty,
            isValid,
            values: { id: userID, ...rest },
        });
    }, [shouldDisableCTA, dirty, isValid, values, onFormStateChange]);

    const resetForm = form.resetForm.bind(form);

    useEffect(() => {
        if (enableResetOnModeChange && readOnly) {
            resetForm();
        }
    }, [readOnly, resetForm, enableResetOnModeChange]);

    return (
        <Box id={id} component="form" maxWidth="lg" onSubmit={form.handleSubmit}>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={4}>
                    <Input
                        required
                        readOnly={readOnly}
                        name="name"
                        fieldName={t('admin_user_page.label.name')}
                        value={values.name}
                        fullWidth
                        maxLength={160}
                        error={!!errors.name}
                        caption={errors.name}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Input
                        required
                        readOnly={readOnly}
                        name="email"
                        fieldName={t('admin_user_page.label.email_address')}
                        value={values.email}
                        fullWidth
                        maxLength={320}
                        error={!!errors.email}
                        caption={errors.email}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Input
                        disabled={!readOnly}
                        readOnly={readOnly}
                        fullWidth
                        name="userID"
                        fieldName={t('admin_user_page.label.id')}
                        value={values.userID}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <PhoneField
                        readOnly={readOnly}
                        fullWidth
                        name="phoneNumber"
                        fieldName={t('admin_user_page.label.phone_number')}
                        value={values.phoneNumber}
                        onChange={form.handleChange}
                    />
                </Grid>
            </Grid>

            {!hideSubmitCTA && (
                <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_user_page.cta.save_changes')}
                    </Button>
                </Box>
            )}
        </Box>
    );
}
