import { ReactElement, ReactNode } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Text from 'app/components/primitives/Text';

interface Props extends BoxProps {
    /**
     * layout slot for the label identifying the line
     */
    label?: ReactNode;

    /**
     * layout slot for the line voltage
     */
    voltage?: string;

    /**
     * layout slot for the line current
     */
    current?: string;

    /**
     * layout slot for the line power meter
     */
    progress?: ReactElement;
}

export default function LineRow({ label = '', progress, voltage = '', current = '', ...props }: Props): ReactElement {
    return (
        <Box {...props}>
            <Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 4 }}>
                <Box>
                    <Text as="p" color="secondary">
                        {label}
                    </Text>
                </Box>

                <Box sx={{ width: '100%' }}>{progress}</Box>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 1 }}>
                    <Box minWidth="7ch" textAlign="right">
                        <Text as="p" variant="bodyMedium">
                            {current}
                        </Text>
                    </Box>

                    <Box minWidth="7ch" textAlign="right">
                        <Text as="p" variant="bodyMedium">
                            {voltage}
                        </Text>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}
