/**
 * Given a metricId returns the base unit. As metrics are not transmitted with their units this is necessary.
 * The units are goverened by what's in our telemetry schema but there are cases where changes in firmware break
 * this contract. Be sure to confirm any unit changes with both the backend and firmware teams and update the telemetry
 * schema to match.
 *
 * @link {telemetry schema | https://moxionpower.atlassian.net/wiki/spaces/SWE/pages/247300106/Telemetry+Schemas}
 */
export default function getUnitForMetric(metricID: string): string {
    if (/^pcs_total_load/.test(metricID)) return 'kW';

    if (/^(load_|p_)/.test(metricID)) return 'W';

    if (/^(e_|energy\/)/.test(metricID)) return 'Wh';

    if (/^i_/.test(metricID)) return 'A';

    if (/^v_/.test(metricID)) return 'V';

    if (/^(soc|cell_)/.test(metricID)) return '%';

    if (/^s_time/.test(metricID)) return 's';

    if (/^(temp_ess_max|ess_temp_max)$/.test(metricID)) return '°C';

    return '';
}
