import React, { useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

import { Button, Input, Text } from 'app/components';

import { useI18n } from 'i18n';

interface Row {
    [key: string]: string;
}

interface Column {
    id: string;
    label: string;
}

interface OnChange {
    (updatedData: Row[]): void;
}

interface LineItemTableProps {
    rows: Row[];
    columns: Column[];
    onChange: OnChange;
    name?: string;
}

/**
 * LineItemTable is a component that displays a table of line items.
 * Each line item is represented by a row in the table, and each property of a line item is represented by a column.
 * The component allows for editing the values of the line items directly in the table.
 * When a value is edited, the `onChange` prop is called with the updated data.
 * A new row can be added to the table by clicking the "Add Row" button.
 */
const LineItemTable: React.FC<LineItemTableProps> = ({ rows, columns, onChange, name }) => {
    const [data, setData] = useState(rows);
    const { t } = useI18n();

    const onCellEditCommit = ({ rowIndex, column, value }) => {
        if (rowIndex < 0 || rowIndex >= data.length) {
            // console.error(`Invalid rowIndex: ${rowIndex}`);
            return;
        }

        const updatedRow = { ...data[rowIndex], [column]: value };
        const updatedData = [...data];
        updatedData[rowIndex] = updatedRow;

        setData(updatedData);
        onChange(updatedData);
    };

    const addRow = () => {
        const newRow = columns.reduce((acc, column) => {
            if (!column.id) {
                // console.error(`Invalid column id: ${column.id}`);
                return acc;
            }

            return { ...acc, [column.id]: '' };
        }, {});

        setData([...data, newRow]);
    };

    return (
        <TableContainer component={Paper}>
            <Text variant="h3" p={4}>
                {t('documents.components.line_items')}
            </Text>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {columns.map((column, columnIndex) => (
                                <TableCell key={columnIndex} component="td" scope="row">
                                    <Input
                                        value={row[column.id]}
                                        onChange={event => {
                                            const { value } = event.target;
                                            onCellEditCommit({ rowIndex, column: column.id, value });
                                        }}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Button onClick={() => addRow()} size="sm" m={4}>
                {t('documents.components.add_row')}
            </Button>
        </TableContainer>
    );
};

export default LineItemTable;
