import { ReactElement } from 'react';

import { AsideCellProps } from 'app/components/compounds/MultiGrid';
import { CollapsibleAsideCell } from 'app/components/compounds/Schedule';
import { Button } from 'app/components/primitives';
// TODO(Derek): refactor this out of component by passing in "to" / "href" prop to components
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { MpuInfoTooltipContent } from './MpuInfoTooltipContent';
import { MpuIndexItemData } from './types';

export function MpuIndexAsideCell({ index, style, data }: AsideCellProps<MpuIndexItemData>): ReactElement {
    const { t } = useI18n();
    const deviceInstance = data.deviceInstances.at(index);
    // TODO(Morris): Make externally configurable
    const ctaPath = getPathByName('MPU_DETAIL', { params: { mpuID: deviceInstance?.id } });

    return (
        <CollapsibleAsideCell
            cta={
                <Button ctaType="secondary" size="sm" to={ctaPath}>
                    {t('mpu_index_schedule.view_mpu')}
                </Button>
            }
            index={index}
            heading={deviceInstance?.name || t('unknown')}
            tooltip={<MpuInfoTooltipContent deviceInstance={deviceInstance} />}
            style={style}
        >
            <CollapsibleAsideCell.Label>{t('mpu_index_schedule.bar.unavailable')}</CollapsibleAsideCell.Label>
            <CollapsibleAsideCell.Label>{t('mpu_index_schedule.bar.available')}</CollapsibleAsideCell.Label>
            <CollapsibleAsideCell.Label>{t('mpu_index_schedule.bar.downtime')}</CollapsibleAsideCell.Label>
        </CollapsibleAsideCell>
    );
}
