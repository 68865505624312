import { ReactNode } from 'react';

import Stack from '@mui/material/Stack';

import { MPU_TILE_WIDTH } from './constants';

/**
 * Component that renders the structure of the MPU tile content.
 * This component is used to render both a skeleton and the actual content.
 */
export function MPUTileStructure({
    assignment,
    externalID,
    firmwareVersion,
    indicators,
    modelName,
    mpuName,
    ownerName,
    serialNumber,
    serviceArea,
    stateOfCharge,
    timeToEmpty,
}: {
    assignment: ReactNode;
    externalID: ReactNode;
    firmwareVersion: ReactNode;
    indicators: ReactNode;
    modelName: ReactNode;
    mpuName: ReactNode;
    ownerName: ReactNode;
    serialNumber: ReactNode;
    serviceArea: ReactNode;
    stateOfCharge: ReactNode;
    timeToEmpty: ReactNode;
}) {
    return (
        <Stack
            textAlign="left"
            direction="column"
            justifyContent="space-between"
            px={4}
            pt={2}
            pb={3}
            sx={{
                height: '192px',
                minWidth: MPU_TILE_WIDTH.as('px'),
            }}
        >
            <Stack direction="column" spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Stack direction="row" spacing={2}>
                        {indicators}
                    </Stack>
                    {assignment}
                </Stack>
                <Stack height={64} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Stack direction="column" alignItems="flex-start">
                        {mpuName}
                        {timeToEmpty}
                    </Stack>
                    {stateOfCharge}
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1}>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    {ownerName}
                    {serviceArea}
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    {modelName}
                    {firmwareVersion}
                    {serialNumber}
                </Stack>
                {externalID && <Stack direction="row">{externalID}</Stack>}
            </Stack>
        </Stack>
    );
}
