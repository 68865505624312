import { Account, useListNetsuiteCustomersQuery } from 'generated/graphql';

interface NetsuiteCustomer {
    id: string;
    name: string;
    associatedAccount?: Pick<Account, 'id' | 'name'>;
}

interface Response {
    loading: boolean;
    netsuiteCustomersByID: { [key: string]: NetsuiteCustomer };
}

export default function useNetsuiteCustomers(): Response {
    const { loading, data } = useListNetsuiteCustomersQuery();

    const netsuiteCustomers: NetsuiteCustomer[] = (data?.listNetSuiteCustomers ?? []).map(nsCustomer => ({
        id: nsCustomer.id,
        name: nsCustomer.name,
        associatedAccount: nsCustomer.account ?? undefined,
    }));

    const netsuiteCustomersByID =
        netsuiteCustomers.reduce((acc, cur: NetsuiteCustomer) => {
            if (acc[cur.id]) {
                // eslint-disable-next-line no-console
                console.warn('useNetsuiteCustomers() - duplicate IDs found: ', acc[cur.id], cur);
            }

            return { ...acc, [cur.id]: cur };
        }, {}) ?? {};

    return { loading, netsuiteCustomersByID };
}
