import Box from '@mui/material/Box';

import { Icon, Text } from 'app/components';

import { useI18n } from 'i18n';

import { ColumnLayout } from '../ColumnLayout';

export function HeaderRow() {
    const { t } = useI18n();

    return (
        <Box
            display="flex"
            flexDirection="row"
            mb={4}
            p={4}
            borderRadius={1}
            sx={theme => ({
                backgroundColor: theme.palette.background.secondaryDark.main,
            })}
        >
            <ColumnLayout
                columnValues={[
                    <Text variant="detailMedium">{t('reservation_table.header.name')}</Text>,
                    <Text variant="detailMedium">{t('reservation_table.header.number_of_units')}</Text>,
                    <Text variant="detailMedium">{t('reservation_table.header.date_range')}</Text>,
                    <Text variant="detailMedium">{t('reservation_table.header.delivery')}</Text>,
                ]}
            />
            {/* this is just to maintain alignment */}
            <Icon spacer />
        </Box>
    );
}
