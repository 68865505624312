import { useCallback, useState } from 'react';
import { Duration } from 'luxon';

import { GridSortItem } from '@mui/x-data-grid';

import { useActiveFaultSummary } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import {
    ActiveFaultsPageQuery,
    ActiveFaultsPageQueryVariables,
    FaultSeverity,
    SortDirection,
    useActiveFaultsPageQuery,
} from 'generated/graphql';

import { Fault } from './types';

/**
 * Map client fields to sortable server fields
 */
const FIELDS = {
    name: 'faultName',
    firstSeen: 'firstSeen',
    lastUpdate: 'lastUpdate',
};

type ServerFault = ActiveFaultsPageQuery['listActiveFaults']['edges'][0]['node'];
type ServerSortModel = ActiveFaultsPageQueryVariables['sortBy'];

function toSingleWordTitleCase(s) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
}

function toServerSortDirection(clientSort: GridSortItem['sort']): SortDirection {
    return SortDirection[toSingleWordTitleCase(clientSort ?? 'DESC')];
}

/**
 * Adapts the client sort model (used by MUI DataGrid) to the sort model expected
 * by the GQL query
 */
function toServerSortModel(sortModel: GridSortItem): ServerSortModel {
    const field = FIELDS[sortModel?.field];

    if (!field) return;

    return {
        field,
        direction: toServerSortDirection(sortModel?.sort),
    };
}

interface NextPageRequest {
    first: number;
    after?: string;
}

interface PreviousPageRequest {
    last: number;
    before: string;
}

/**
 * Transform / flatten the GQL model for display
 */
function toFault(serverFaultNode: { node: ServerFault }): Fault {
    const fault = serverFaultNode?.node;

    return {
        id: fault?.id,
        name: fault?.faultName,
        severity: fault?.faultSeverity,
        mpuName: fault?.device?.name,
        mpuModelName: fault?.device?.deviceModel?.name ?? '',
        mpuDetailHref: getPathByName('MPU_DETAIL', { params: { mpuID: fault?.device?.id } }),
        firstSeen: fault?.firstSeen,
        lastUpdate: fault?.lastUpdate,
        ownerName: fault?.device?.owner?.name ?? '',
        serviceAreaName: fault?.device?.serviceArea?.name,
    };
}

type Filter = 'all' | 'critical';

interface Result {
    /**
     * Whether the faults are being retrieved from the server
     */
    loading: boolean;

    /**
     * The high level counts for faults for feedback related to availablefilters
     * A count of -1 means the count is unkonwn likely because it's still being fetched
     */
    counts: {
        total: number;
        critical: number;
    };

    /**
     * The page for faults data
     */
    faults: Fault[];

    /**
     * The current sort model being applied
     */
    sortModel: GridSortItem[];

    /**
     * Callback to be called when the sort model is updated in some way
     */
    onSortModelChange: (model: GridSortItem[]) => void;

    /**
     * The currently active filter. We only allow one active filter at this time.
     */
    activeFilter: Filter;

    /**
     * Applies the "all" filter deselecting all other filters
     */
    showAll: () => void;

    /**
     * Applies the "critical" filter deselecting all other filters
     */
    showCritical: () => void;

    /**
     * The current page where 0 is the first page
     */
    currentPage: number;

    /**
     * The number of results for current page
     */
    resultCount: number;

    /**
     * The size of the page to request. The returned size may be less
     */
    pageSize: number;

    /**
     * The allowed page sizes to request
     * Note: pageSize must be included in the array here if using with MuiDataGrid else controls do not render
     */
    pageSizeOptions: number[];

    /**
     * Event handler to be called when the user requests a new page
     */
    onPageChange: (newPage: number) => void;

    /**
     * Event handler to be called when the user selects a new page size
     */
    onPageSizeChange: (newPageSize: number) => void;
}

const pageSizeOptions = [15, 30, 45];

/**
 * Responsible for providing the faults data, with pagination, sort and filter controls
 */
export default function useFaults({ ownerAccountID }: { ownerAccountID?: string } = {}): Result {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0]);
    const [pageRequest, setPageRequest] = useState<Partial<NextPageRequest | PreviousPageRequest>>({ first: pageSize });

    const [activeFilter, setActiveFilter] = useState<Filter>('all');
    const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: 'firstSeen', sort: 'desc' }]);
    const [resultCount, setResultCount] = useState<number>(0);

    const { totalFaults, counts, refetch } = useActiveFaultSummary({ accountID: ownerAccountID });

    const criticalFaultCount = counts.find(x => x.severity === 'CRITICAL')?.count ?? -1;

    const { loading, data } = useActiveFaultsPageQuery({
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
        variables: {
            ownerAccountID,
            ...pageRequest,
            severities: activeFilter === 'critical' ? [FaultSeverity.Critical] : undefined,
            sortBy: toServerSortModel(sortModel[0]),
        },
        onCompleted: data => {
            setResultCount(data?.listActiveFaults?.pageInfo?.totalEdges ?? 0);
            refetch();
        },
    });

    const edges = data?.listActiveFaults?.edges ?? [];
    const headCursor = edges[0]?.cursor;
    const tailCursor = edges[edges.length - 1]?.cursor;

    const faults = edges.map(toFault);

    const requestFirstPage = useCallback(
        ({ pageSize: _pageSize } = { pageSize }) => {
            setCurrentPage(0);
            setPageRequest({
                first: _pageSize,
            });
        },
        [pageSize],
    );

    const showAll = useCallback(() => {
        setActiveFilter('all');
        requestFirstPage();
    }, [requestFirstPage]);

    const showCritical = useCallback(() => {
        setActiveFilter('critical');
        requestFirstPage();
    }, [requestFirstPage]);

    const onPageChange = useCallback(
        (newPage: number) => {
            if (newPage > currentPage) {
                setPageRequest({
                    first: pageSize,
                    after: tailCursor,
                });
            } else {
                setPageRequest({
                    last: pageSize,
                    before: headCursor,
                });
            }

            setCurrentPage(newPage);
        },
        [currentPage, pageSize, tailCursor, headCursor],
    );

    const onPageSizeChange = useCallback(
        (newPageSize: number) => {
            setPageSize(newPageSize);
            requestFirstPage({ pageSize: newPageSize });
        },
        [requestFirstPage],
    );

    const onSortModelChange = useCallback(
        (newSortModel: GridSortItem[]) => {
            requestFirstPage();
            setSortModel(newSortModel);
        },
        [requestFirstPage],
    );

    return {
        loading,

        counts: {
            total: totalFaults ?? -1,
            critical: criticalFaultCount,
        },

        faults,

        // Sort
        sortModel,
        onSortModelChange,

        // Filters
        activeFilter,

        showAll,
        showCritical,

        // Pagination
        currentPage,
        resultCount,
        pageSize,
        pageSizeOptions,

        onPageChange,
        onPageSizeChange,
    };
}
