import { ComponentProps } from 'react';

import Box from '@mui/material/Box';

import { Tag } from 'app/components/primitives';
import { MPUDetailSectionID } from 'app/core/Navigation/constants';
import getPathByName from 'app/core/Navigation/getPathByName';

import { TranslateFunction, useI18n } from 'i18n';

import { Tooltip } from '../Tooltip';
import { MPUTileAssignment } from './constants';

type TagColor = ComponentProps<typeof Tag>['color'];

const contentByStatus = {
    [MPUTileAssignment.Available]: {
        color: 'normal',
        label: t => t('mpu_tile.status.available.label'),
        tooltip: t => t('mpu_tile.status.available.tooltip'),
    },
    [MPUTileAssignment.Charging]: {
        color: 'success',
        label: t => t('mpu_tile.status.charging.label'),
        tooltip: t => t('mpu_tile.status.charging.tooltip'),
    },
    [MPUTileAssignment.Downtime]: {
        color: 'danger',
        label: t => t('mpu_tile.status.downtime.label'),
        tooltip: t => t('mpu_tile.status.downtime.tooltip'),
    },
    [MPUTileAssignment.InTransit]: {
        color: 'info',
        label: t => t('mpu_tile.status.in_transit.label'),
        tooltip: t => t('mpu_tile.status.in_transit.tooltip'),
    },
    [MPUTileAssignment.OnRent]: {
        color: 'warning',
        label: t => t('mpu_tile.status.on_rent.label'),
        tooltip: t => t('mpu_tile.status.on_rent.tooltip'),
    },
} as const satisfies Record<
    MPUTileAssignment,
    {
        color: TagColor;
        label: (t: TranslateFunction) => string;
        tooltip: (t: TranslateFunction) => string;
    }
>;

export function MPUTileAssignmentTag({
    assignment,
    mpuID,
}: {
    assignment: `${MPUTileAssignment}` | undefined;
    mpuID: string | undefined;
}) {
    const { t } = useI18n();

    if (!assignment) {
        return (
            <Tooltip content={t('mpu_tile.status.unknown.tooltip')}>
                <Box>
                    <Tag color="normal" label="?" outlined />
                </Box>
            </Tooltip>
        );
    }

    const href = mpuID
        ? getPathByName('MPU_DETAIL', { params: { mpuID }, hash: MPUDetailSectionID.Schedule })
        : undefined;

    const { color, label, tooltip } = contentByStatus[assignment];

    return (
        <Tooltip content={tooltip(t)}>
            <Box>
                <Tag color={color} label={label(t)} outlined to={href} />
            </Box>
        </Tooltip>
    );
}
