import { useId, useState } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GridColDef, GridRenderCellParams, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';

import { Button, DataGrid, DataGridCellContent, Layer, LoadingIndicator, Text } from 'app/components';

import { ReservationItemUpdateInput } from 'generated/graphql';

import { useI18n } from 'i18n';

import EditAddressesModal from '../EditAddressesModal';
import { ReservationDetail } from '../useReservation';

export interface ReservationItemDetails {
    id: string;
    name: string;
    deliveryLocation: ReservationDetail['items'][0]['deliveryLocation'];
    pickupLocation: ReservationDetail['items'][0]['pickupLocation'];
}

interface UnitAddressesProps {
    items: ReservationItemDetails[];
    isLoading: boolean;
    onUpdate: (values: ReservationItemUpdateInput) => void;
    accountID: string;
    sx?: BoxProps['sx'];
}

export default function UnitAddresses({ items, isLoading, onUpdate, accountID, sx }: UnitAddressesProps) {
    const { t, format } = useI18n();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [activeItem, setActiveItem] = useState<ReservationItemDetails>(items[0]);
    const editModalDialogId = useId();

    const rows: GridRowsProp<ReservationItemDetails> = items.slice();
    const columns: GridColDef<ReservationItemDetails>[] = [
        {
            field: 'name',
            headerName: t('admin_reservation_page.addresses_table.header.name'),
            flex: 1,
            hideSortIcons: true,
            valueGetter: ctx => ctx.row.name,
            renderCell(params: GridRenderCellParams<GridValidRowModel, string>) {
                return <DataGridCellContent line1={params.value} />;
            },
        },
        {
            field: 'deliveryLocation',
            headerName: t('admin_reservation_page.addresses_table.header.delivery_location'),
            flex: 3,
            hideSortIcons: true,
            valueGetter: ctx => ctx.row.deliveryLocation,
            renderCell(params: GridRenderCellParams<ReservationDetail['items'][0]['deliveryLocation']>) {
                return (
                    <DataGridCellContent
                        line1={params.value?.formattedAddress}
                        line2={
                            params.value?.lat &&
                            params.value?.lon &&
                            format.latLong({ lat: params.value?.lat, lon: params.value?.lon })
                        }
                    />
                );
            },
        },
        {
            field: 'pickupLocation',
            headerName: t('admin_reservation_page.addresses_table.header.pickup_location'),
            flex: 3,
            hideSortIcons: true,
            valueGetter: ctx => ctx.row.pickupLocation,
            renderCell(params: GridRenderCellParams<ReservationDetail['items'][0]['pickupLocation']>) {
                return (
                    <DataGridCellContent
                        line1={params.value?.formattedAddress}
                        line2={
                            params.value?.lat &&
                            params.value?.lon &&
                            format.latLong({ lat: params.value?.lat, lon: params.value?.lon })
                        }
                    />
                );
            },
        },
        {
            field: 'actions',
            headerName: t('admin_reservation_page.addresses_table.header.actions'),
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            renderHeader(params) {
                return (
                    <Text variant="bodyMedium" mr={2}>
                        {params.colDef.headerName}
                    </Text>
                );
            },
            valueGetter: ctx => ctx.row.id,
            renderCell(params: GridRenderCellParams<GridValidRowModel, string>) {
                return (
                    <Button
                        ctaType="secondary"
                        onClick={() => {
                            const newActiveItem = items.find(item => item.id === params.value);
                            if (newActiveItem) {
                                setActiveItem(newActiveItem);
                                setIsEditModalOpen(true);
                            }
                        }}
                    >
                        {t('admin_reservation_page.addresses_table.action.edit')}
                    </Button>
                );
            },
        },
    ];

    return (
        <Box sx={sx}>
            <Box mb={4}>
                <Text variant="h3" as="h2">
                    {t('admin_reservation_page.heading.addresses')}
                </Text>
            </Box>

            <Paper elevation={0} sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'border.light' }}>
                <DataGrid
                    loading={isLoading}
                    density="comfortable"
                    autoHeight
                    // data
                    rows={rows}
                    columns={columns}
                    hideFooterPagination
                    hideFooter
                    disableRowSelectionOnClick={true}
                    isRowSelectable={() => false}
                    isCellEditable={() => false}
                    pageSizeOptions={[]}
                    components={{
                        LoadingOverlay: () => (
                            <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
                                <LoadingIndicator />
                            </Layer>
                        ),
                        NoRowsOverlay: () => (
                            <Box
                                display="flex"
                                width="100%"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                textAlign="center"
                                m={6}
                            >
                                <Text variant="h3" as="div" gutterBottom>
                                    {t('admin_reservation_page.addresses_table.zero_state.title')}
                                </Text>

                                <Text as="p" gutterBottom>
                                    {t('admin_reservation_page.addresses_table.zero_state.message')}
                                </Text>
                            </Box>
                        ),
                    }}
                />
            </Paper>

            <EditAddressesModal
                id={editModalDialogId}
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSave={onUpdate}
                reservationItemID={activeItem.id}
                unitName={activeItem.name}
                deliveryLocation={activeItem.deliveryLocation}
                pickupLocation={activeItem.pickupLocation}
                accountID={accountID}
            />
        </Box>
    );
}
