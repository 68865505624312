import React from 'react';
import * as luxon from 'luxon';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';

import { useI18n } from 'i18n';

export const localZone = () => new luxon.SystemZone();
export const utc = () => luxon.FixedOffsetZone.utcInstance;

const TIMEZONE_OPTIONS = ['Local Time', 'Pacific', 'Mountain', 'Central', 'Eastern', 'UTC'] as const;
export type TimezoneOption = (typeof TIMEZONE_OPTIONS)[number];

function isTimezoneOption(name: unknown): name is TimezoneOption {
    return TIMEZONE_OPTIONS.find(timezoneOption => timezoneOption === name) !== undefined;
}

export const makeZone = (timezoneName: TimezoneOption): luxon.Zone => {
    const zones = {
        'Local Time': localZone(),
        Pacific: luxon.IANAZone.create('America/Los_Angeles'),
        Mountain: luxon.IANAZone.create('America/Denver'),
        Central: luxon.IANAZone.create('America/Chicago'),
        Eastern: luxon.IANAZone.create('America/New_York'),
        UTC: utc(),
    };
    return zones[timezoneName];
};

interface Props {
    name: string;
    value: TimezoneOption;
    onChange?: (changedValue: { value: TimezoneOption }) => void;
    nowFunc?: () => luxon.DateTime;
}
export default function TimezonePicker({ name, value, onChange = tz => {}, nowFunc = luxon.DateTime.utc }: Props) {
    const { format } = useI18n();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const timezoneName = event.target.value;
        if (!isTimezoneOption(timezoneName)) {
            throw new Error(`unexpected timezone: "${timezoneName}"`);
        }
        onChange({ value: timezoneName });
    };

    const renderValue = (zoneName: unknown): React.ReactNode => {
        if (!isTimezoneOption(zoneName)) {
            throw new Error(`unexpected timezone: "${zoneName}"`);
        }
        const zone = makeZone(zoneName);

        return (
            <Box display="flex" justifyContent={'space-between'}>
                <div>{zoneName}</div>
                <Chip label={`UTC${format.timezoneOffset(zone)}`} size="small" />
            </Box>
        );
    };

    return (
        <TextField
            id="timezone-picker"
            name={name}
            value={value}
            label="Timezone"
            select
            SelectProps={{
                renderValue,
            }}
            onChange={handleChange}
            variant="standard"
            fullWidth
        >
            {TIMEZONE_OPTIONS.map(n => (
                <MenuItem key={n} value={n}>
                    {n}
                </MenuItem>
            ))}
        </TextField>
    );
}
