interface LatLon {
    lat: number;
    lon: number;
}

const fitBoundsOptions = {
    padding: {
        top: 50,
        bottom: 50,
        left: 50,
        right: 50,
    },
};

// Approximately 13.8 miles (1deg ~ 69miles)
const MAP_DISTANCE_DEGREES = 0.2;
const SAN_FRANCISCO_LAT_LON = {
    lon: -122.4,
    lat: 37.8,
};

const compare = (prop: 'lat' | 'lon') => (a: LatLon, b: LatLon) => {
    const value1 = a?.[prop] ?? 0;
    const value2 = b?.[prop] ?? 0;

    if (value1 === value2) return 0;
    if (value1 < value2) return -1;

    return 1;
};

const makeBounds = (latLon: LatLon): LatLon[] => {
    const { lat, lon } = latLon;

    return [
        { lon: lon + MAP_DISTANCE_DEGREES / 2, lat: lat + MAP_DISTANCE_DEGREES / 2 },
        { lon: lon - MAP_DISTANCE_DEGREES / 2, lat: lat - MAP_DISTANCE_DEGREES / 2 },
    ];
};

/**
 * Given a collection of locations founds the outer most bounds to display all locations on a map
 */
export default function useBounds(locations: LatLon[]) {
    if (locations.length <= 1) {
        return {
            bounds: makeBounds(locations[0] ?? SAN_FRANCISCO_LAT_LON),
            fitBoundsOptions,
        };
    }

    const sortedByLon = locations.sort(compare('lon'));
    const sortedByLat = locations.sort(compare('lat'));

    const lat1 = sortedByLat[0].lat;
    const lon1 = sortedByLon[0].lon;

    const lat2 = sortedByLat[sortedByLat.length - 1].lat;
    const lon2 = sortedByLon[sortedByLon.length - 1].lon;

    return {
        bounds: [
            { lon: lon1, lat: lat1 },
            { lon: lon2, lat: lat2 },
        ],
        fitBoundsOptions,
    };
}
