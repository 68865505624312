import { useUpdateUserMutation } from 'generated/graphql';

import { User } from './types';

/**
 * Responsible for initiating the network interaction to update user details (excluding roles)
 */
export default function useUpdateUserDetails() {
    const [updateUserMutation] = useUpdateUserMutation();

    return async ({ current, updates }: { current: User; updates: User }) => {
        const { id, name, email, accountID, phoneNumber } = updates;

        if (
            name === current.name &&
            email === current.email &&
            accountID === current.accountID &&
            phoneNumber === current.phoneNumber
        ) {
            return Promise.resolve(current);
        }

        await updateUserMutation({
            variables: {
                userInput: {
                    id,
                    name,
                    email,
                    phoneNumber,
                    accountIds: accountID ? [accountID] : undefined,
                },
            },
        });
    };
}
