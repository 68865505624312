import { useCallback } from 'react';

import { useToastErrorHandler } from 'app/core/error';
import { useConfirm } from 'app/core/layers';
import { LayerItemFacade } from 'app/core/layers/types';

import { useI18n } from 'i18n';

import { useDeleteDowntimePeriodMutation, useGetDowntimePeriodQuery } from './operations';
import { ScheduleChangeEvent } from './types';

interface RemoveDowntimeConfirmProps {
    /**
     * A callback to execute after the downtime period has been added.
     */
    onScheduleChange?: (event: ScheduleChangeEvent) => void;
    /**
     * The ID of the downtime period schedule to delete.
     */
    targetScheduleID: string;
}

/**
 * @returns A function to confirm the removal of a downtime period.
 */
export function useRemoveDowntimePeriodConfirm() {
    const { t } = useI18n();
    const [deleteDowntimePeriod] = useDeleteDowntimePeriodMutation();
    const confirm = useConfirm();
    const handleError = useToastErrorHandler();
    const [getDowntimePeriod] = useGetDowntimePeriodQuery();

    return useCallback(
        ({ onScheduleChange, targetScheduleID }: RemoveDowntimeConfirmProps) => {
            async function handleConfirm(item: Pick<LayerItemFacade, 'close'>) {
                try {
                    const result = await getDowntimePeriod(targetScheduleID);
                    const { mpuID } = result.data;

                    await deleteDowntimePeriod(targetScheduleID);

                    onScheduleChange?.({ value: { mpuID } });
                    item.close();
                } catch (error) {
                    handleError(error, t('downtime_period_dialog.error.remove_failed'));
                }
            }

            confirm({
                title: t('downtime_period_dialog.title.remove'),
                message: t('downtime_period_dialog.message.remove'),
                onConfirm: handleConfirm,
            });
        },
        [confirm, deleteDowntimePeriod, getDowntimePeriod, handleError, t],
    );
}
