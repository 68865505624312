import { ReactElement, useState } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Text from 'app/components/primitives/Text';
import { MetricChartType } from 'app/components/widgets/telemetry/types';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import MetricChart, { DEFAULT_METRIC_CHART, metricChartDisplayOrder } from '../MetricChart';
import MetricChartDownloader from '../MetricChartDownloader';

interface Props {
    /**
     * The identifier for a reservationItem to get telemetry by (do not also pass mpuID)
     */
    unitID?: string;

    /**
     * The identifier for a device instance to get telemetry by (do not also pass unitID)
     */
    mpuID?: string;

    /**
     * The start of the interval to fetch data for
     */
    start?: ISO8601 | DateTime;

    /**
     * The end of the interval to fetch data for
     */
    end?: ISO8601 | DateTime;
}

/**
 * Shows historical trends for metrics via time series chart.
 */
export default function HistoricalSummary({ unitID, mpuID, start, end }: Props): ReactElement {
    const { t } = useI18n();

    const [activeChart, setActiveChart] = useState<MetricChartType>(DEFAULT_METRIC_CHART);

    return (
        <Box>
            <Tabs
                value={activeChart}
                onChange={(_, newActiveFilter) => {
                    setActiveChart(newActiveFilter);
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label={t('chart.screen_reader.tab_description')}
            >
                {metricChartDisplayOrder
                    // TODO(Derek): add energy usage chart when the query supports an interval (start, end)
                    .filter(chartType => chartType !== MetricChartType.EnergyUsage)
                    .map(item => (
                        <Tab
                            key={item}
                            value={item}
                            label={
                                <Text variant="inherit" textTransform="none">
                                    {t(`chart.${item}.title`)}
                                </Text>
                            }
                        />
                    ))}
            </Tabs>

            <Divider sx={{ marginTop: '-1px' }} />

            <Box>
                <MetricChart
                    unitID={unitID}
                    mpuID={mpuID}
                    type={activeChart}
                    start={start}
                    end={end}
                    downloadCTA={
                        <MetricChartDownloader
                            unitID={unitID}
                            mpuID={mpuID}
                            includeCharts={[activeChart]}
                            start={start}
                            end={end}
                        />
                    }
                />
            </Box>
        </Box>
    );
}
