import { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { Duration } from 'luxon';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';

import { MetricName } from 'app/core/data';
import { isSOCLow, useUnitStatus } from 'app/core/data/deviceTelemetry';

import { useI18n } from 'i18n';

import useTheme from 'styles/theme';
import { MUIMarginProps } from 'styles/theme/types';

import Layer from '../../../primitives/layout/Layer';
import { LoadingIndicator } from '../../../primitives/LoadingIndicator';
import { Progress } from '../../../primitives/Progress';
import ChargingStatus from './ChargingStatus';
import InlineErrorMessage from './InlineErrorMessage';
import Layout from './Layout';
import LineLoads from './LineLoads';
import Metric from './Metric';
import NetPower from './NetPower';
import PowerIn from './PowerIn';
import PowerOut from './PowerOut';
import RunMode from './RunMode';
import TGUConnection from './TGUConnection';
import VoltageSelection from './VoltageSelection';

const MotionLayer = motion(Layer);

interface Props extends MUIMarginProps {
    reservationItemID?: string | undefined;
    deviceInstanceID?: string | undefined;
    /**
     * Determines whether the view should render targeting users that are fleet managers.
     *
     * TODO(Morris): Once user roles are established, remove this prop,
     * and derive this from user data from the backend.
     */
    isUserWithFleetManagerRole?: boolean;
}

/**
 * Container component responsible for connecting data and presentation
 */
export default function UnitStatus({
    reservationItemID,
    deviceInstanceID,
    isUserWithFleetManagerRole,
    ...props
}: Props): ReactElement {
    const { t, format } = useI18n();
    const theme = useTheme();
    const {
        error,
        loading,
        data: unitStatus,
    } = useUnitStatus({
        reservationItemID,
        deviceInstanceID,
        metricNames: [
            // System status
            MetricName.SwitchMode,
            MetricName.PcsState,
            MetricName.CellSignalStrength,
            MetricName.CellSignalQuality,
            MetricName.ChargingStatus,
            MetricName.VoltageSelection,

            // available energy
            MetricName.PackEnergyAvailable,
            MetricName.StateOfCharge,
            MetricName.TimeToEmpty,
            MetricName.NetPower,

            // power out
            MetricName.Line1LoadVoltage,
            MetricName.Line2LoadVoltage,
            MetricName.Line3LoadVoltage,
            MetricName.LineNLoadVoltage,

            MetricName.Line1LoadCurrent,
            MetricName.Line2LoadCurrent,
            MetricName.Line3LoadCurrent,
            MetricName.NeutralCurrent,

            MetricName.TotalLoadPower,

            // power in
            MetricName.TimeToFull,
            MetricName.ChargeInputPower,
        ],
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });
    const errorMessage = !!error ? t('unit_status.data_retrieval_error') : null;

    return (
        <Layer anchor {...props}>
            <Layout
                runMode={<RunMode value={unitStatus.mode} />}
                voltageSelection={<VoltageSelection value={unitStatus.voltageSelection} />}
                tguConnection={<TGUConnection value={unitStatus.cellSignalBars} />}
                chargingStatus={<ChargingStatus value={unitStatus.chargingStatus} />}
                stateOfCharge={
                    <Metric variant="card" size="lg" center label={t('unit_status.state_of_charge_heading')}>
                        <Progress
                            variant="charge"
                            value={unitStatus.stateOfCharge}
                            max={100}
                            error={isSOCLow(unitStatus.stateOfCharge)}
                        />
                    </Metric>
                }
                stateOfEnergy={
                    <Metric
                        variant="card"
                        size="lg"
                        center
                        label={t('unit_status.state_of_energy_heading')}
                        {...format.energy(unitStatus.packEnergyAvailable)}
                    />
                }
                powerIn={<PowerIn timeToFull={unitStatus.timeToFull} chargeInputPower={unitStatus.chargeInputPower} />}
                powerOut={<PowerOut totalLoadPower={unitStatus.totalLoadPower} timeToEmpty={unitStatus.timeToEmpty} />}
                netPower={<NetPower value={unitStatus.netPower} />}
                lineLoads={
                    <Metric variant="card" label="Line Monitoring">
                        <LineLoads
                            line1LoadVoltage={unitStatus.line1LoadVoltage}
                            line2LoadVoltage={unitStatus.line2LoadVoltage}
                            line3LoadVoltage={unitStatus.line3LoadVoltage}
                            lineNLoadVoltage={unitStatus.lineNLoadVoltage}
                            line1LoadCurrent={unitStatus.line1LoadCurrent}
                            line2LoadCurrent={unitStatus.line2LoadCurrent}
                            line3LoadCurrent={unitStatus.line3LoadCurrent}
                            lineNLoadCurrent={unitStatus.lineNLoadCurrent}
                        />
                    </Metric>
                }
            />

            {loading && (
                <>
                    <MotionLayer
                        fill="anchor"
                        sx={{ backgroundColor: 'background.primary.main' }}
                        animate={{ opacity: [0.3, 0.8] }}
                        transition={{
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 2,
                        }}
                    ></MotionLayer>
                    <Layer fill="anchor" display="flex" justifyContent="center" alignItems="center">
                        <LoadingIndicator />
                    </Layer>
                </>
            )}

            {!!errorMessage && (
                <Layer
                    fill="anchor"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={theme => ({ backgroundColor: alpha(theme.palette.background.primary.main, 0.8) })}
                >
                    <Box
                        sx={{
                            backgroundColor: 'background.primary.main',
                            borderColor: 'border.light',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: `${theme.shape.borderRadius}px`,
                            p: 5,
                        }}
                    >
                        <InlineErrorMessage text={errorMessage} />
                    </Box>
                </Layer>
            )}
        </Layer>
    );
}
