import { ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';

import { ModalDialog } from 'app/components/primitives/Dialog';
import Button from 'app/components/primitives/interactive/Button';

import { AccountLocation } from 'generated/graphql';

import { useI18n } from 'i18n';

import AddOrEditAccountLocationForm from './AddOrEditAccountLocationForm';
import { SelectedLocation } from './types';

interface Props {
    /**
     * The unique id, identifying the modal. For associating to controlling CTAs for screen readers
     */
    id?: string;

    /**
     * Whether the dialogis open / visible to user
     */
    isOpen?: boolean;

    /**
     * Event handler to be called when user initiates closing the dialog
     */
    onClose?: () => void;

    /**
     * Event handler to be called when user initiates a save of the entered location details
     */
    onSave: (selectedLocation: SelectedLocation) => Promise<void>;

    /**
     * Optional initial values for the location form
     */
    accountLocationToEdit?: Partial<AccountLocation>;
}

/**
 * Responsible for presenting the form for adding or editing an existing in a modal dialog to be presented over the page
 */
export default function EditAddressModal({
    id,
    isOpen = false,
    onClose,
    onSave,
    accountLocationToEdit,
}: Props): ReactElement {
    const { t } = useI18n();

    const dialogTitleID = useId();
    const formID = useId();
    const [isSubmitCTADisabled, setIsSubmitCTADisabled] = useState<boolean>(false);
    const title = !!accountLocationToEdit ? t('edit_address.edit_title') : t('edit_address.add_title');

    return (
        <ModalDialog
            id={id}
            open={isOpen}
            onClose={onClose}
            aria-labelledby={dialogTitleID}
            title={<Box id={dialogTitleID}>{title}</Box>}
            primaryCTA={
                <Button form={formID} type="submit" disabled={isSubmitCTADisabled}>
                    {t('edit_address.cta.save_address')}
                </Button>
            }
        >
            <AddOrEditAccountLocationForm
                id={formID}
                hideSubmitCTA
                contactName={accountLocationToEdit?.contactName ?? undefined}
                contactPhone={accountLocationToEdit?.contactPhone ?? undefined}
                placeID={accountLocationToEdit?.placeId ?? undefined}
                notes={accountLocationToEdit?.notes ?? undefined}
                onSubmit={async ({
                    value: { contactName, contactPhone, notes, placeProvider, placeID, formattedAddress, lat, lon },
                }) => {
                    await onSave({
                        contactName,
                        contactPhone,
                        formattedAddress,
                        notes,
                        placeProvider,
                        placeId: placeID,
                        lat,
                        lon,
                    });
                    onClose?.();
                }}
                onFormStateChange={({ isSubmitCTADisabled }) => setIsSubmitCTADisabled(isSubmitCTADisabled)}
            />
        </ModalDialog>
    );
}
