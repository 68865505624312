import { forwardRef, FunctionComponent } from 'react';

/**
 * A higher-order component that facilitates composability, by allowing for easy creation of new components.
 */
export function withProps<TProps, TRef = unknown>(
    Component: FunctionComponent<TProps>,
    useNextProps: (props: TProps) => TProps,
) {
    const NextComponent = forwardRef<TRef, TProps>((props, ref) => <Component ref={ref} {...useNextProps(props)} />);

    const displayName = Component.displayName ?? Component.name ?? 'Component';

    NextComponent.displayName = `withProps(${displayName})`;

    return NextComponent;
}
