import { DateTime } from 'luxon';

import { ScheduleEvent } from 'app/components/compounds/Schedule';
import { combineDateAndTime } from 'app/core/date-time';
import { ISO8601 } from 'app/core/types';

import {
    Assignment,
    CreateScheduleInput,
    DeviceInstance,
    DeviceInstanceSchedule,
    DowntimePeriodFragment,
} from 'generated/graphql';

import { Theme } from 'styles/theme';

import type { MpuScheduleEvent } from '../MpuSchedule';
import { makeMpuScheduleEvent } from '../MpuSchedule/transformers';
import { DowntimePeriodFormValues } from './DowntimePeriodForm';

export interface DowntimePeriod {
    end: ISO8601;
    id: DeviceInstanceSchedule['id'];
    internalNotes?: string;
    mpuID: DeviceInstance['id'];
    start: ISO8601;
}

/**
 * Convert downtime period form values into a downtime period object.
 *
 * TODO(Morris): Handle potential invalid date times.
 */
export function formValuesToDowntimePeriod(values: DowntimePeriodFormValues): Omit<DowntimePeriod, 'id' | 'mpuID'> {
    return {
        end: combineDateAndTime(values.endDate, values.endTime, undefined).toISO() ?? '',
        internalNotes: values.internalNotes || undefined,
        start: combineDateAndTime(values.startDate, values.startTime, undefined).toISO() ?? '',
    };
}

/**
 * Convert a downtime period into a mutation input for persistance.
 */
export function downtimePeriodToCreateScheduleInputs({
    end,
    internalNotes,
    mpuID,
    start,
}: Omit<DowntimePeriod, 'id'>): CreateScheduleInput[] {
    return [
        {
            assignment: Assignment.Maintenance,
            deviceInstance: { id: mpuID },
            end,
            internalNotes,
            start,
        },
    ];
}

/**
 * Flattens a device instance schedule into a downtime period object.
 */
export function scheduleToDowntimePeriod(deviceInstanceSchedule: DowntimePeriodFragment): DowntimePeriod {
    return {
        end: deviceInstanceSchedule.end,
        id: deviceInstanceSchedule.id,
        internalNotes: deviceInstanceSchedule.internalNotes ?? undefined,
        mpuID: deviceInstanceSchedule.deviceInstance.id,
        start: deviceInstanceSchedule.start,
    };
}

/**
 * Convert a device instance schedule into downtime period form values.
 *
 * This transformer is only deterministic when the start property is provided,
 * otherwise it will default the start value to the current time.
 */
export function scheduleToFormValues(values: Partial<DowntimePeriodFragment> = {}): DowntimePeriodFormValues {
    const start = values.start
        ? DateTime.fromISO(values.start)
        : DateTime.now().set({ millisecond: 0, minute: 0, second: 0 });
    const end = values.end ? DateTime.fromISO(values.end) : start.plus({ hours: 8 });

    return {
        endDate: end.toISODate() ?? '',
        endTime: end.toISOTime({ includeOffset: false }) ?? '',
        internalNotes: values.internalNotes ?? '',
        startDate: start.toISODate() ?? '',
        startTime: start.toISOTime({ includeOffset: false }) ?? '',
    };
}

/**
 * Convert downtime period form values into a schedule event preview.
 */
export function formValuesToDowntimePreview({
    color,
    theme,
    values,
    variant,
}: {
    color?: ScheduleEvent['color'];
    theme: Theme;
    values: DowntimePeriodFormValues;
    variant?: ScheduleEvent['variant'];
}): MpuScheduleEvent {
    const downtimePeriod = formValuesToDowntimePeriod(values);
    const scheduleEvent = makeMpuScheduleEvent({
        assignment: Assignment.Maintenance,
        end: downtimePeriod.end,
        start: downtimePeriod.start,
        theme,
    });

    return {
        ...scheduleEvent,
        color: color ?? scheduleEvent.color,
        variant: variant ?? scheduleEvent.variant,
    };
}
