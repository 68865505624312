import { ComponentProps, useState } from 'react';
import { t } from 'i18next';

import Paper from '@mui/material/Paper';
import { GridSortModel } from '@mui/x-data-grid';

import { Button, Cluster, Icon, Input, SectionHeader } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import UsersTable from '../../UsersTable';

interface TeamMembersProps
    extends Omit<ComponentProps<typeof UsersTable>, 'users' | 'sortModel' | 'onSortModelChange'> {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    teamMembers: ComponentProps<typeof UsersTable>['users'];
    accountID: string;
}

export default function TeamMembers({ teamMembers, accountID, searchTerm, setSearchTerm, ...props }: TeamMembersProps) {
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'active', sort: 'desc' }]);

    return (
        <>
            <SectionHeader
                title={t('admin_account_page.team_members.title')}
                cta={
                    <Cluster>
                        <Input
                            startAdornment={<Icon name="search" />}
                            value={searchTerm}
                            placeholder={t('admin_account_page.team_members.search_input_placeholder')}
                            onChange={({ target: { value } }) => setSearchTerm(value)}
                        />

                        <Button
                            icon={<Icon name="add-circle" />}
                            to={getPathByName('INVITE_USER_TO_ACCOUNT', { params: { accountID } })}
                        >
                            {t('admin_account_page.team_members.cta.invite')}
                        </Button>
                    </Cluster>
                }
                mb={4}
            />

            <Paper sx={{ overflowX: 'auto' }} variant="outlined">
                <UsersTable
                    {...props}
                    users={teamMembers}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    sortingMode="client"
                    filterMode="client"
                    paginationMode="client"
                    hideAccount
                    sx={{ minHeight: undefined }}
                />
            </Paper>
        </>
    );
}
