import { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import { Button, Icon, Layer, SectionHeader } from 'app/components';
import { AutoSizer } from 'app/components/primitives/AutoSizer';
import { ReservationSchedule } from 'app/components/widgets';
import { Allocation, ReservationScheduleController } from 'app/components/widgets/ReservationSchedule/types';
import { UnitAssignmentScheduleContainer } from 'app/components/widgets/UnitAssignmentSchedule/UnitAssignmentScheduleContainer';
import { DeviceInstance } from 'app/core/data';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

interface ReservationScheduleSwitcherProps {
    ownerAccountID: string;
    reservationID: string;
}

type BoxSize = { height: number; width: number };

/**
 * A presentational component responsible for rendering the layout for
 * switching between the two schedule components.
 */
function ScheduleSwitcherWrapper({
    renderReservationSchedule,
    renderUnitAssignmentSchedule,
    showUnitAssignment,
    reservationID,
}: {
    renderReservationSchedule: (size: BoxSize) => ReactElement;
    renderUnitAssignmentSchedule: (size: BoxSize) => ReactNode;
    showUnitAssignment: boolean;
    reservationID: string;
}) {
    const { t } = useI18n();

    return (
        <Card variant="outlined" sx={{ p: 5 }}>
            <Stack spacing={3}>
                <SectionHeader
                    title={t('admin_reservation_page.heading.reservation_schedule')}
                    headingLevel="H3"
                    as="h2"
                    cta={
                        <Button
                            ctaType="secondary"
                            icon={<Icon name="add" />}
                            to={getPathByName('RENTAL_MGMT_UPDATE_UNIT_COUNT', {
                                params: { reservationID },
                            })}
                        >
                            {t('admin_reservation_page.cta.add_units')}
                        </Button>
                    }
                />
                <Box sx={{ height: { md: 'calc(100vh - 280px )', lg: 'calc(100vh - 220px)' }, minHeight: '600px' }}>
                    <AutoSizer>
                        {size => (
                            <Layer anchor>
                                {renderReservationSchedule(size)}
                                {showUnitAssignment && (
                                    <Layer fill="anchor">
                                        <Box
                                            height={size.height}
                                            sx={theme => ({ backgroundColor: theme.palette.background.paper })}
                                            width={size.width}
                                        >
                                            {renderUnitAssignmentSchedule(size)}
                                        </Box>
                                    </Layer>
                                )}
                            </Layer>
                        )}
                    </AutoSizer>
                </Box>
            </Stack>
        </Card>
    );
}

/**
 * A component responsible for providing the logic for
 * switching between the two schedule components.
 */
export function ReservationScheduleSwitcher({ ownerAccountID, reservationID }: ReservationScheduleSwitcherProps) {
    const [targetAllocation, setTargetAllocation] = useState<Allocation | undefined>();
    const reservationScheduleRef = useRef<ReservationScheduleController | undefined>();
    const handleDeviceAssignment = useCallback(
        (deviceInstance: DeviceInstance): void => {
            if (!targetAllocation) return;

            reservationScheduleRef.current?.assignDeviceInstance(targetAllocation.id, deviceInstance);
            setTargetAllocation(undefined);
        },
        [targetAllocation],
    );
    const handleCancel = useCallback(() => setTargetAllocation(undefined), []);

    return (
        <ScheduleSwitcherWrapper
            reservationID={reservationID}
            renderReservationSchedule={({ height, width }) => (
                <ReservationSchedule
                    controllerRef={reservationScheduleRef}
                    height={height}
                    onAssignAllocationRequest={setTargetAllocation}
                    reservationID={reservationID}
                    width={width}
                />
            )}
            renderUnitAssignmentSchedule={({ height, width }) =>
                targetAllocation && (
                    <UnitAssignmentScheduleContainer
                        end={targetAllocation.end}
                        height={height}
                        onCancel={handleCancel}
                        onDeviceAssignment={handleDeviceAssignment}
                        ownerAccountID={ownerAccountID}
                        start={targetAllocation.start}
                        width={width}
                    />
                )
            }
            showUnitAssignment={!!targetAllocation}
        />
    );
}
