import { Duration } from 'luxon';

import { extractUnitStatusesFromTelemetry } from 'app/core/data';

import {
    GetMpuBasicDetailsQueryResult,
    useGetMpuBasicDetailsQuery,
    useGetMpuSpotlightStatsQuery,
} from 'generated/graphql';

import { TranslateFunction, useI18n } from 'i18n';

import { DeviceInstance, ServerDeviceInstance } from './types';

interface Input {
    /**
     * The deviceInstanceID to pull data for
     */
    id: string;
}

interface Result extends Pick<GetMpuBasicDetailsQueryResult, 'loading' | 'error'> {
    /**
     * The deviceInstanceID to pull data for
     */
    unit: DeviceInstance;

    refetch: () => Promise<void>;
}

/**
 * Transform a given server-side `ServerDeviceInstance` model into the client-side `DeviceInstance` model.
 */
export function toDeviceInstance(
    serverModel: Partial<ServerDeviceInstance> & { id: string },
    { t }: { t: TranslateFunction },
): DeviceInstance {
    const { lat, lon, time } = serverModel?.latestDeviceLocation ?? {};

    return {
        id: serverModel.id,
        serialNumber: serverModel?.serialNumber ?? undefined,
        externalID: serverModel?.externalID ?? undefined,
        name: serverModel?.name ?? '',
        assetID: serverModel?.assetID ?? '',
        ownerID: serverModel?.owner?.id ?? undefined,
        ownerCompanyName: serverModel?.owner?.name ?? undefined,
        modelID: serverModel?.deviceModel?.id ?? '',
        serviceArea: serverModel?.serviceArea?.name,
        serviceAreaID: serverModel?.serviceArea?.id ?? 'unassigned',
        modelName: serverModel?.deviceModel?.name ?? '',
        currentLocation: lat && lon ? { lat, lon } : null,
        locationTimestamp: time ?? '',
        latestDataTimestamp: serverModel?.latestDataTimestamp,
        ...extractUnitStatusesFromTelemetry<'stateOfCharge' | 'timeToEmpty'>(
            serverModel.latestTelemetry?.telemetryPoints ?? [],
            {
                t,
            },
        ),
    };
}

/**
 * Responsible for retrieving unit details from GQL server and periodically syncing.
 */
export default function useGetUnitDetails({ id }: Input): Result {
    const { t } = useI18n();

    const {
        data: basicDetails,
        loading,
        error,
        refetch: refetchBasicDetails,
    } = useGetMpuBasicDetailsQuery({
        skip: !id,
        variables: {
            id,
        },
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });

    const { data: mpuSpotlight, refetch: refetchSpotlight } = useGetMpuSpotlightStatsQuery({
        skip: !id,
        variables: {
            id,
        },
    });

    const mpu = {
        id,
        ...basicDetails?.getDeviceInstance,
        ...mpuSpotlight?.getDeviceInstance,
    };

    const deviceInstance = toDeviceInstance(mpu, { t });

    return {
        loading,
        error,
        refetch: async () => {
            await Promise.all([refetchBasicDetails(), refetchSpotlight()]);
        },
        unit: deviceInstance,
    };
}
