import { addMethod, string } from 'yup';

export { default as Session } from './Session';

export * from './SessionProvider';

export { AccountRoleName } from './Session';

// Add password verification to yup
const passwordVerificationRegex =
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[A-Z])(?=.*[@#$^+=!~\[\{\}\]\\\|<,\.>\/\?!%&\*\(\)\-\_`':;"])(?!.*[^a-zA-Z0-9@#$^+=!~\[\{\}\]\\\|<,\.>\/\?!%&\*\(\)\-\_`':;"])(.{8,255})$/;

addMethod(string, 'password', function (errorMessage) {
    return this.test(`password-rules`, errorMessage, function (value) {
        const { path, createError } = this;

        return passwordVerificationRegex.test(value ?? '') || createError({ path, message: errorMessage });
    });
});

declare module 'yup' {
    interface StringSchema {
        password(errorMessage?: string): StringSchema;
    }
}
