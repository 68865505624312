import { useCallback } from 'react';

import { useLayer } from '../layers';
import { handleUnknownError } from './handleUnknownError';

/**
 * Handles the given unknown error, and displays an error toast with the given message.
 */
export function useToastErrorHandler() {
    const { toast } = useLayer();

    return useCallback(
        (error: unknown, message: string) => {
            handleUnknownError(error);
            toast.add({
                severity: 'error',
                message,
            });
        },
        [toast],
    );
}
