import { ComponentProps, ReactElement } from 'react';

import { Tooltip } from '../Tooltip';
import ActiveFaults from './ActiveFaults';

interface Props extends Omit<ComponentProps<typeof Tooltip>, 'content'>, ComponentProps<typeof ActiveFaults> {}

/**
 * Responsible for presenting the active faults in a tooltip
 */
export default function ActiveFaultsTooltip({
    criticalFaultCodes,
    nonCriticalFaultCodes,
    faultsHREF,
    ...props
}: Props): ReactElement {
    const hasCriticalFaults = (criticalFaultCodes?.length ?? 0) > 0;
    const hasNonCriticalFaults = (nonCriticalFaultCodes?.length ?? 0) > 0;

    if (!hasCriticalFaults && !hasNonCriticalFaults) return <></>;

    return (
        <Tooltip
            {...props}
            content={
                <ActiveFaults
                    criticalFaultCodes={criticalFaultCodes}
                    nonCriticalFaultCodes={nonCriticalFaultCodes}
                    faultsHREF={faultsHREF}
                />
            }
        />
    );
}
