import { areDatesEqual, isWeekEnd, isWeekStart } from './date-helpers';

export default function getRangeExtents({ day, start, end, candidate, selectionTarget }) {
    const isRangeReversed = start > end;
    const _end = isRangeReversed ? start : end;
    const _start = isRangeReversed ? end : start;
    const isEndDay = areDatesEqual(day, _end);
    const isStartDay = areDatesEqual(day, _start);
    const isCandidateDay = areDatesEqual(day, candidate);

    const halfGapPx = 2;
    const leftOffset = (isStartDay && selectionTarget !== 'start') || isWeekStart(day) ? 0 : halfGapPx;
    const rightOffset =
        (isEndDay && candidate < _end) || (isCandidateDay && candidate >= _end) || isWeekEnd(day) ? 0 : halfGapPx;

    return {
        top: `0px`,
        bottom: `0px`,
        left: `-${leftOffset}px`,
        right: `-${rightOffset}px`,
    };
}
