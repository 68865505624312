import { DateTime } from 'luxon';
import * as yup from 'yup';

import { TranslateFunction } from 'i18n';

import { combineDateAndTime } from './combineDateAndTime';
import { PossibleISO8601 } from './types';

enum ValidationFormFieldProperty {
    EndDate = 'endDate',
    EndTime = 'endTime',
    StartDate = 'startDate',
    StartTime = 'startTime',
}

type ValidationFormFieldNames = Record<`${ValidationFormFieldProperty}`, string>;

const DEFAULT_FIELD_NAMES: ValidationFormFieldNames = {
    [ValidationFormFieldProperty.EndDate]: ValidationFormFieldProperty.EndDate,
    [ValidationFormFieldProperty.EndTime]: ValidationFormFieldProperty.EndTime,
    [ValidationFormFieldProperty.StartDate]: ValidationFormFieldProperty.StartDate,
    [ValidationFormFieldProperty.StartTime]: ValidationFormFieldProperty.StartTime,
};

export function isValidDateTimeSpan({
    endDate,
    endTime,
    startDate,
    startTime,
}: {
    endDate: PossibleISO8601 | undefined;
    endTime: PossibleISO8601 | undefined;
    startDate: PossibleISO8601 | undefined;
    startTime: PossibleISO8601 | undefined;
}): boolean {
    const start = combineDateAndTime(startDate, startTime, undefined);
    const end = combineDateAndTime(endDate, endTime, undefined);

    return start.isValid && end.isValid && start.toMillis() < end.toMillis();
}

export function isValidDateRange({
    endDate,
    startDate,
}: {
    endDate: PossibleISO8601 | undefined;
    startDate: PossibleISO8601 | undefined;
}): boolean {
    if (!endDate || !startDate) return false;

    const startDateTime = DateTime.fromISO(startDate);
    const endDateTime = DateTime.fromISO(endDate);

    return startDateTime.isValid && endDateTime.isValid && startDateTime.toMillis() <= endDateTime.toMillis();
}

export function makeMinEndDateTest(
    t: TranslateFunction,
    fieldNames: Pick<ValidationFormFieldNames, 'endDate' | 'startDate'> = DEFAULT_FIELD_NAMES,
): yup.TestConfig<PossibleISO8601 | undefined> {
    return {
        name: 'min_end_date',
        message: t('date_time_input_validation.min_end_date'),
        test: (value, context): boolean => {
            return isValidDateRange({
                endDate: value,
                startDate: context.parent[fieldNames.startDate],
            });
        },
    };
}

export function makeMinEndTimeTest(
    t: TranslateFunction,
    fieldNames = DEFAULT_FIELD_NAMES,
): yup.TestConfig<PossibleISO8601 | undefined> {
    return {
        name: 'min_end_time',
        message: t('date_time_input_validation.min_end_time'),
        test: (value, context): boolean => {
            return isValidDateTimeSpan({
                endDate: context.parent[fieldNames.endDate],
                endTime: value,
                startDate: context.parent[fieldNames.startDate],
                startTime: context.parent[fieldNames.startTime],
            });
        },
    };
}

export function makeMaxStartDateTest(
    t: TranslateFunction,
    fieldNames: Pick<ValidationFormFieldNames, 'endDate' | 'startDate'> = DEFAULT_FIELD_NAMES,
): yup.TestConfig<PossibleISO8601 | undefined> {
    return {
        name: 'max_start_date',
        message: t('date_time_input_validation.max_start_date'),
        test: (value, context): boolean => {
            return isValidDateRange({
                endDate: context.parent[fieldNames.endDate],
                startDate: value,
            });
        },
    };
}

export function makeMaxStartTimeTest(
    t: TranslateFunction,
    fieldNames = DEFAULT_FIELD_NAMES,
): yup.TestConfig<PossibleISO8601 | undefined> {
    return {
        name: 'max_start_time',
        message: t('date_time_input_validation.max_start_time'),
        test: (value, context): boolean => {
            return isValidDateTimeSpan({
                endDate: context.parent[fieldNames.endDate],
                endTime: context.parent[fieldNames.endTime],
                startDate: context.parent[fieldNames.startDate],
                startTime: value,
            });
        },
    };
}
