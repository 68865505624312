import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';
import {
    getGridSingleSelectOperators,
    getGridStringOperators,
    GridCellParams,
    GridColDef,
    GridFilterModel,
    GridFilterOperator,
    GridMenuParams,
    GridRenderCellParams,
    GridRowsProp,
    GridSortModel,
} from '@mui/x-data-grid';

import { DataGrid, DataGridCellContent, Layer, Link, LoadingIndicator, ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { UserStatus } from 'generated/graphql';

import { useI18n } from 'i18n';

import ResendInviteButton from '../ResendInviteButton';
import { User } from '../types';
import useAccountFilterOptions from './useAccountFilterOptions';
import useRoleFilterOptions from './useRoleFilterOptions';

export const USERS_TABLE_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

const STRING_OPERATOR = {
    contains: getGridStringOperators().find(operator => operator.value === 'contains') as GridFilterOperator<
        User,
        string | number | null,
        any
    >,
    isAnyOf: getGridStringOperators().find(operator => operator.value === 'isAnyOf') as GridFilterOperator<
        User,
        string | number | null,
        any
    >,
};

interface Props
    extends Pick<
        ComponentProps<typeof DataGrid>,
        | 'sortModel'
        | 'onSortModelChange'
        | 'sortingMode'
        | 'filterModel'
        | 'onFilterModelChange'
        | 'filterMode'
        | 'paginationModel'
        | 'onPaginationModelChange'
        | 'paginationMode'
        | 'sx'
    > {
    users: User[];
    loading: boolean;
    sortModel: GridSortModel;
    filterModel: GridFilterModel;
    hideStatus?: boolean;
    hideAccount?: boolean;
    totalRowCount: number;
}

function LoadingOverlay() {
    return (
        <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
            <LoadingIndicator />
        </Layer>
    );
}
function NoRowsOverlay() {
    const { t } = useI18n();
    const _t = (key: string, params = {}) => t(`admin_user_index_page.${key}`, params);

    return (
        <Box m={6}>
            <ZeroState title={_t('table.no_rows_title')} message={_t('table.no_rows_message')} />
        </Box>
    );
}

const ROLE_FILTER_OPERATOR: GridFilterOperator<User> = {
    ...getGridSingleSelectOperators().filter(operator => operator.value === 'is')[0],
    getApplyFilterFn(filterItem, column) {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null;
        }
        return (params: GridCellParams<User, string[]>): boolean => {
            return (params.value ?? []).includes(filterItem.value);
        };
    },
};

export default function UsersTable({
    users,
    loading,
    sortModel,
    onSortModelChange,
    sortingMode = 'server',
    filterModel,
    onFilterModelChange,
    filterMode = 'server',
    hideAccount,
    onPaginationModelChange,
    paginationModel = { page: 0, pageSize: 25 },
    totalRowCount,
    paginationMode = 'server',
    sx = {},
}: Props): ReactElement {
    const { t } = useI18n();
    const _t = (key: string, params = {}) => t(`admin_user_index_page.${key}`, params);

    const { accountValueOptions, fetchAccountValueOptions } = useAccountFilterOptions();
    const { roleValueOptions, fetchRoleValueOptions } = useRoleFilterOptions();

    const rows: GridRowsProp<User> = users.slice();
    const columns: GridColDef<User>[] = [
        {
            field: 'name',
            headerName: _t('table.header.name'),
            hideable: false,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            flex: 1,
            minWidth: 300,
            renderCell(params: GridRenderCellParams<User, string>) {
                const user = params.row;

                if (params.row.status === UserStatus.Invited) {
                    return <DataGridCellContent line1={user.name} />;
                }

                return (
                    <Link to={getPathByName('ACCOUNT_MGMT_USER', { params: { userID: user.id } })}>
                        <DataGridCellContent line1={user.name} />
                    </Link>
                );
            },
        },
        {
            field: 'accountName',
            headerName: _t('table.header.account_name'),
            flex: 1,
            minWidth: 300,
            hideable: false,
            sortable: false,
            type: 'singleSelect',
            filterOperators: getGridSingleSelectOperators().filter(operator => operator.value === 'is'),
            valueOptions: accountValueOptions,
            valueGetter: ctx => ctx.row.accountID,
            renderCell(params: GridRenderCellParams<User, number>) {
                const user = params.row;

                return (
                    <Link to={getPathByName('ACCOUNT_MGMT_ACCOUNT', { params: { accountID: user.accountID } })}>
                        <DataGridCellContent line1={user.accountName} />
                    </Link>
                );
            },
        },
        {
            field: 'email',
            headerName: _t('table.header.email'),
            flex: 1,
            minWidth: 300,
            hideable: false,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            renderCell(params: GridRenderCellParams<User, number>) {
                return <DataGridCellContent line1={params.row.email} />;
            },
        },
        {
            field: 'roles',
            headerName: _t('table.header.roles'),
            flex: 1,
            minWidth: 300,
            hideable: false,
            sortable: false,
            type: 'singleSelect',
            filterOperators: [ROLE_FILTER_OPERATOR],
            valueOptions: roleValueOptions,
            valueGetter: ctx => ctx.row.roles,
            renderCell(params: GridRenderCellParams<User, number>) {
                const user = params.row;

                return (
                    <DataGridCellContent
                        line1={_t('shortened_roles', {
                            primaryRole: user.roles[0],
                            remainingCount: user.roles.length - 1,
                        })}
                    />
                );
            },
        },
        {
            field: 'status',
            headerName: _t('table.header.status'),
            flex: 0.5,
            minWidth: 160,
            filterOperators: getGridSingleSelectOperators().filter(operator => operator.value === 'is'),
            valueOptions: [
                { value: UserStatus.Active, label: _t('status.ACTIVE') },
                { value: UserStatus.Deactivated, label: _t('status.DEACTIVATED') },
                { value: UserStatus.Invited, label: _t('status.INVITED') },
            ],
            type: 'singleSelect',
            valueGetter(params: GridRenderCellParams<User, UserStatus>) {
                return params.row.status;
            },
            // the asc order: active -> invite pending -> deactivated
            sortComparator(status1, status2) {
                if (status1 === UserStatus.Active && status2 !== UserStatus.Active) return 1;

                if (status1 === UserStatus.Deactivated && status2 !== UserStatus.Deactivated) return -1;

                if (status1 === UserStatus.Deactivated && status2 === UserStatus.Invited) return -1;

                if (status1 === UserStatus.Invited && status2 === UserStatus.Deactivated) return 1;

                if (status1 === UserStatus.Invited && status2 === UserStatus.Active) return -1;

                return 0;
            },
            renderCell(params: GridRenderCellParams<User, boolean>) {
                const teamMember = params.row;

                if (teamMember.status === UserStatus.Invited) {
                    return <ResendInviteButton accountID={teamMember.accountID} email={teamMember.email} />;
                }

                if (teamMember.status === UserStatus.Active) {
                    return <DataGridCellContent line1={_t('status.ACTIVE')} />;
                }

                return <DataGridCellContent line1={_t('status.DEACTIVATED')} />;
            },
        },
    ];

    /**
     * returns true if opened menu is for column with given field;
     * returns null if header element not found (as failsafe)
     */
    function isMenuForColumn(columnField: string, params: GridMenuParams) {
        const column = columns.find(column => column.field === columnField);

        if (!column) {
            return false;
        }

        // find the column header element
        const el = params.target?.closest('.MuiDataGrid-columnHeader')?.querySelector('.MuiDataGrid-columnHeaderTitle');

        if (!el) {
            // no element found, so return null to distinguish from false
            return null;
        }

        return el?.textContent === column.headerName;
    }

    // render the grid
    return (
        <DataGrid
            loading={loading}
            density="comfortable"
            autoHeight
            // rendering
            // pagination
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={USERS_TABLE_ROWS_PER_PAGE_OPTIONS}
            pagination
            paginationMode={paginationMode}
            // data
            rows={rows}
            rowCount={totalRowCount}
            columns={columns}
            sx={{ minHeight: '80vh', ...sx }}
            disableRowSelectionOnClick={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            sortingMode={sortingMode}
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            filterMode={filterMode}
            onMenuOpen={params => {
                const isMenuForAccountNameColumn = isMenuForColumn('accountName', params);
                if (isMenuForAccountNameColumn || isMenuForAccountNameColumn === null) {
                    fetchAccountValueOptions();
                }

                const isMenuForRolesColumn = isMenuForColumn('roles', params);
                if (isMenuForRolesColumn || isMenuForRolesColumn === null) {
                    fetchRoleValueOptions();
                }
            }}
            components={{
                LoadingOverlay,
                NoRowsOverlay,
            }}
            initialState={{ columns: { columnVisibilityModel: { accountName: !hideAccount } } }}
        />
    );
}
