import { AuthState, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoaderFunction, redirect } from 'react-router-dom';

import { RedirectToSignInFunc } from 'app/core/Navigation';

import { getIsSignupEnabled } from 'environment';

import {
    ValidateInviteKeyDocument,
    ValidateInviteKeyQuery,
    ValidateInviteKeyQueryVariables,
    ValidateInviteKeyResult,
} from 'generated/graphql';

import { GQLClient } from '../network/types';

export function generateRequireOktaAuthLoader(oktaAuth: OktaAuth, authState?: AuthState): LoaderFunction {
    return () => {
        if (authState?.isAuthenticated === false) {
            const originalUri = toRelativeUrl(globalThis.location.href, globalThis.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }

        if (!authState?.isAuthenticated) return redirect('/');

        return true;
    };
}

function makeValidateInviteKey({ client }: { client: Pick<GQLClient, 'query'> }) {
    return async function validateInviteKey({ inviteKey }: { inviteKey: string }): Promise<ValidateInviteKeyResult> {
        const { data } = await client.query<ValidateInviteKeyQuery, ValidateInviteKeyQueryVariables>({
            query: ValidateInviteKeyDocument,
            variables: {
                inviteKey,
            },
            errorPolicy: 'all',
        });

        return data?.validateInviteKey;
    };
}

export function generateRequireInviteLoader({
    client,
    redirectToSignIn,
}: {
    client: GQLClient;
    redirectToSignIn: RedirectToSignInFunc;
}): LoaderFunction {
    const isSignupEnabled = getIsSignupEnabled();
    const validateInviteKey = makeValidateInviteKey({ client });

    return async () => {
        const searchParams = new URLSearchParams(globalThis.location.search);
        const inviteKey = searchParams.get('inviteKey') ?? '';
        const email = searchParams.get('email') ?? '';

        if (!inviteKey && isSignupEnabled) {
            return true;
        }

        const { exists, isClaimed, isExpired } = await validateInviteKey({ inviteKey });

        if (!exists) {
            return redirectToSignIn({ saveCurrentLocation: false, search: { invite: 'dne' } });
        }

        if (isExpired) {
            return redirectToSignIn({ saveCurrentLocation: false, search: { invite: 'expired' } });
        }

        if (isClaimed) {
            return redirectToSignIn({ saveCurrentLocation: false, search: { email, invite: 'claimed' } });
        }

        if (!(email && inviteKey)) {
            return redirectToSignIn({ saveCurrentLocation: false });
        }

        return true;
    };
}

export function chainLoaders(...loaders: LoaderFunction[]): LoaderFunction {
    return async args => {
        let response: ReturnType<LoaderFunction> = true;

        for (const loader of loaders) {
            response = await loader(args);
            if (response !== true) break;
        }

        return response;
    };
}
