import { ComponentProps, MouseEventHandler, useCallback } from 'react';

import { Box } from '@mui/material';

import { Button, Dialog, Text } from 'app/components/primitives';

import { Assignment } from 'generated/graphql';

import { TranslateFunction, useI18n } from 'i18n';

import { AllocationSchedule } from './types';

const translationByAssignment: Record<Assignment | 'unknown', (t: TranslateFunction) => string> = {
    [Assignment.Charging]: t => t('mpu_assignment.charging'),
    [Assignment.Discharging]: t => t('mpu_assignment.discharging'),
    [Assignment.Maintenance]: t => t('mpu_assignment.maintenance'),
    [Assignment.Transit]: t => t('mpu_assignment.transit'),
    unknown: t => t('mpu_assignment.unknown'),
};

function AllocationDeleteDialogBody({ allocationSchedules }: { allocationSchedules: AllocationSchedule[] }) {
    const { t, format } = useI18n();

    function formatAllocationSchedule(allocationSchedule: AllocationSchedule) {
        const assignment = translationByAssignment[allocationSchedule.assignment || 'unknown'](t);
        const dateTimes = format.dateRangeSentence(allocationSchedule);

        return t('reservation_schedule.allocation_delete_confirmation.schedule', { assignment, dateTimes });
    }

    return (
        <Box>
            <Text as="p">{t('reservation_schedule.allocation_delete_confirmation.message')}</Text>
            <br />
            <Box component="section">
                <Text component="p">{t('reservation_schedule.allocation_delete_confirmation.schedules_heading')}</Text>
                <Box component="ul">
                    {allocationSchedules.map(schedule => (
                        <Box component="li" key={schedule.id}>
                            {formatAllocationSchedule(schedule)}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

/**
 * Returns a function that can be used to create a dialog for confirming the deletion of allocation schedules.
 */
export function useConfirmAllocationDeleteDialog() {
    const { t } = useI18n();

    return useCallback(
        ({
            allocationSchedules,
            onConfirmClick: onConfirm,
            onDenyClick: onDeny,
        }: {
            allocationSchedules: AllocationSchedule[];
            onConfirmClick: MouseEventHandler<HTMLButtonElement>;
            onDenyClick: MouseEventHandler<HTMLButtonElement>;
        }): ComponentProps<typeof Dialog> => {
            return {
                title: t('reservation_schedule.allocation_delete_confirmation.title'),
                children: <AllocationDeleteDialogBody allocationSchedules={allocationSchedules} />,
                primaryCTA: (
                    <Button onClick={onConfirm}>
                        {t('reservation_schedule.allocation_delete_confirmation.confirm')}
                    </Button>
                ),
                tertiaryCTA: (
                    <Button onClick={onDeny}>{t('reservation_schedule.allocation_delete_confirmation.deny')}</Button>
                ),
            };
        },
        [t],
    );
}
