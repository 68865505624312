import { ComponentProps, ReactElement, useId, useState } from 'react';

import { Button, EditableSection, SectionHeader } from 'app/components';

import { useI18n } from 'i18n';

import { User } from '../types';
import EditUserDetailsForm from './EditUserDetailsForm';

interface Props {
    /**
     * The user's unique identifier (display only)
     */
    userID: User['id'];

    /**
     * The user's name
     */
    name: User['name'];

    /**
     * The user's email
     */
    email: User['email'];

    /**
     * The user's phone number
     */
    phoneNumber: User['phoneNumber'];

    /**
     * Event handler to be called when the form state changes
     */
    onFormStateChange?: ComponentProps<typeof EditUserDetailsForm>['onFormStateChange'];

    /**
     * Event handler to be called when the user initiates a form submission
     */
    onSubmit: ComponentProps<typeof EditUserDetailsForm>['onSubmit'];
}

export default function BasicInfoSection({
    userID,
    name,
    email,
    phoneNumber,
    onFormStateChange,
    onSubmit,
}: Props): ReactElement {
    const { t } = useI18n();

    const editInfoFormID = useId();
    const [disableEditInfoCTA, setDisableEditInfoCTA] = useState(false);

    return (
        <EditableSection
            header={<SectionHeader title={t('admin_user_page.basic_info_section_title')} />}
            saveCTA={<Button type="submit" form={editInfoFormID} disabled={disableEditInfoCTA} />}
        >
            {({ isEditing }) => (
                <EditUserDetailsForm
                    id={editInfoFormID}
                    hideSubmitCTA
                    readOnly={!isEditing}
                    userID={userID}
                    name={name}
                    email={email}
                    phoneNumber={phoneNumber}
                    onFormStateChange={event => {
                        const { shouldDisableCTA } = event;

                        setDisableEditInfoCTA(shouldDisableCTA);
                        onFormStateChange?.(event);
                    }}
                    onSubmit={onSubmit}
                />
            )}
        </EditableSection>
    );
}
