import { Text } from 'app/components';
import StandaloneAccountServiceAccounts from 'app/components/widgets/AccountServiceAccounts/StandaloneAccountServiceAccounts';

import { useI18n } from 'i18n';

interface APISettingsProps {
    accountID: string;
}

/**
 * The container component for API (service account, etc.)
 **/
function APISettings({ accountID }: APISettingsProps): JSX.Element {
    const { t } = useI18n();
    return (
        <>
            <Text variant="h2" mb={2}>
                {t('account_settings.heading.service_accounts')}
            </Text>

            <section>
                <StandaloneAccountServiceAccounts sx={{ mb: 7 }} accountID={accountID} />
            </section>
        </>
    );
}

export default APISettings;
