import { ComponentPropsWithoutRef, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Cluster, FieldValue, Layer, LoadingOverlay, Text } from 'app/components';

import { useI18n } from 'i18n';

import useMPUBuildDetails from './useMPUBuildDetails';

interface Props extends ComponentPropsWithoutRef<typeof Box> {
    /**
     * The unique identifier for a specific MPU
     */
    mpuID?: string;
}

/**
 * Responsible for surfacing MPU Build related information including firmware versions and lifetime statistics
 */
export default function MPUBuildDetailsSection({ mpuID, ...props }: Props): ReactElement {
    const { t, format } = useI18n();

    const { loading, buildDetails } = useMPUBuildDetails({ mpuID });

    return (
        <Layer anchor {...props}>
            <Stack spacing={3}>
                <FieldValue
                    fieldName={t('admin_unit_page.build_detail.approximate_build_date_label')}
                    value={format.date(buildDetails.buildDate)}
                />

                <Box>
                    <Text variant="h3" gutterBottom>
                        {t('admin_unit_page.build_detail.firmware_versions_heading')}
                    </Text>

                    <Cluster gap={5}>
                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.bms_version_label')}
                            value={buildDetails?.bmsVersion}
                        />
                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.ecu_version_label')}
                            value={buildDetails?.ecuVersion}
                        />
                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.evcc_version_label')}
                            value={buildDetails?.evccVersion}
                        />
                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.pmc_version_label')}
                            value={buildDetails?.pmcVersion}
                        />
                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.tgu_version_label')}
                            value={buildDetails?.tguVersion}
                        />
                    </Cluster>
                </Box>

                <Box>
                    <Text variant="h3" gutterBottom>
                        {t('admin_unit_page.build_detail.lifetime_heading')}
                    </Text>

                    <Cluster gap={5}>
                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.total_pcs_activity_label')}
                            value={format.durationFull(buildDetails?.lifetimePCSActivity.value).toString()}
                        />

                        <FieldValue
                            fieldName={t('admin_unit_page.build_detail.total_discharge_label')}
                            value={format.metricValue(buildDetails?.lifetimeDischarge).toString()}
                        />
                    </Cluster>
                </Box>
            </Stack>

            <LoadingOverlay loading={loading} />
        </Layer>
    );
}
