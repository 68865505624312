import { DateTime } from 'luxon';

import { useCancelReservationMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

import { useDialogContext } from './useDialogContext';

interface Output {
    isCancellable: (input: { date: DateTime }) => boolean;
    run: (input: { id: string; start: DateTime }) => void;
}

function isCancellable({ date }: { date: DateTime }): boolean {
    return date.diffNow().as('hours') >= 24;
}

/**
 * Responsible for returning a confirmation gated method for cancelling a reservation
 */
export default function useCancelReservation(): Output {
    const { t } = useI18n();
    const { showDialog } = useDialogContext();
    const [cancelReservation] = useCancelReservationMutation();

    function cancelReservationAfterConfirming({ id, start }) {
        if (!isCancellable({ date: start })) return;

        showDialog(
            t('reservation_table.dialog.cancel_action.title'),
            t('reservation_table.dialog.cancel_action.message'),
            async () => {
                if (!isCancellable({ date: start })) {
                    throw new Error('Reservation cannot be cancelled after 24 hours before start');
                }

                await cancelReservation({ variables: { id } });
            },
        );
    }

    return {
        isCancellable,
        run: cancelReservationAfterConfirming,
    };
}
