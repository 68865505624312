import { MutableRefObject, RefCallback, useCallback } from 'react';

export type CallbackOrMutableRef<T> = RefCallback<T> | MutableRefObject<T>;

/**
 * A convenience utility to normalize React Refs into a callback.
 */
export function normalizeRef<T = HTMLElement>(ref?: CallbackOrMutableRef<T> | null): RefCallback<T> {
    return (node: T): void => {
        if (ref != null && typeof ref === 'object') {
            ref.current = node;
        } else if (typeof ref === 'function') {
            ref(node);
        }
    };
}

/**
 * Combine refs
 */
export default function useCombineRefs<T = HTMLElement>(
    ...refs: (CallbackOrMutableRef<T> | null | undefined)[]
): RefCallback<T> {
    return useCallback(
        (node: T): void => {
            refs.forEach(ref => {
                normalizeRef(ref)(node);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...refs],
    );
}
