import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';

import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

interface CreateAPIKeyModalProps {
    serviceAccountID: string;
    onAddAPIKeyConfirmed: (serviceAccountID: string) => void;
    onClose: (event: unknown, reason: string) => void;
    isOpen: boolean;
}

export function CreateAPIKeyModal({ serviceAccountID, onAddAPIKeyConfirmed, onClose, isOpen }: CreateAPIKeyModalProps) {
    const { t } = useI18n();
    const close = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose(event, reason);
        }
    };

    return (
        <Dialog id={''} open={isOpen} onClose={close} aria-labelledby="add-unit-dialog-title">
            <DialogTitle id="add-unit-dialog-title">{t('service_accounts.create_api_key_dialog.title')}</DialogTitle>

            <Divider />

            <DialogContent
                sx={{
                    width: {
                        md: 500,
                    },
                }}
            >
                <Text variant="body">{t('service_accounts.create_api_key_dialog.message')}</Text>
            </DialogContent>

            <DialogActions sx={{ px: 5, pb: 4 }}>
                <Button onClick={event => close(event, 'cancel')}>{t('cta.cancel')}</Button>

                <Button type="submit" variant="contained" onClick={event => onAddAPIKeyConfirmed(serviceAccountID)}>
                    {t('service_accounts.create_service_account_dialog.create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
