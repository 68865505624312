import { ReactElement, useMemo } from 'react';

import MPUTileGrid from 'app/components/compounds/MPUTileGrid';

import { mpuToMPUTileProps } from './mpuToMPUTileProps';
import { useInfiniteScrollMPUs } from './useInfiniteScrollMPUs';

type MPUsTileViewProps = Parameters<typeof useInfiniteScrollMPUs>[0] & {
    pageSize: number;
};

/**
 * TODO(Morris): Calculate `skeletonTilesCount` based on the number of tiles currently visible in the viewport.
 * TODO(Morris): Add `onPageSizeChange` prop.
 */
export function MPUsTileView({ hasNextPage, loading, pageSize, ...otherProps }: MPUsTileViewProps): ReactElement {
    const { mpus, scrollCursorElement } = useInfiniteScrollMPUs({ ...otherProps, hasNextPage, loading });
    const tiles = useMemo(() => mpus.map(mpuToMPUTileProps), [mpus]);

    // TODO(Morris): Calculate `skeletonTilesCount` based on the number of tiles currently visible in the viewport.
    const skeletonTilesCount = Math.round(pageSize / 2);

    return (
        <MPUTileGrid
            hasMoreItems={loading || hasNextPage}
            scrollCursorElement={scrollCursorElement}
            skeletonTilesCount={skeletonTilesCount}
            tiles={tiles}
        />
    );
}
