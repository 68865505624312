import { FaultSeverity } from 'generated/graphql';

export const FAULT_SEVERITIES = [FaultSeverity.Critical, FaultSeverity.Info] as const;

// Dimensions
export const CHART_BODY_MAX_HEIGHT = '60vh' as const;
export const CHART_ROW_HEIGHT = 44 as const;
export const CHART_X_AXIS_HEIGHT = 60 as const;
export const CHART_Y_AXIS_WIDTH = 200 as const;
export const CHART_MIN_BAR_WIDTH = 4 as const;

export const getBarStyle = ({ theme }) => ({
    CRITICAL: {
        fill: theme?.palette?.background?.dangerDark?.main,
    },
    INFO: {
        fill: theme?.palette?.background?.secondaryDark?.main,
    },
});
