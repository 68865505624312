import { useState } from 'react';
import { DateTime, Duration } from 'luxon';

import { toAlert } from 'app/core/Alert';
import { useNowPoller } from 'app/core/data';

import {
    Assignment,
    FilterMode,
    ListReservationItemsByReservationStatusQuery,
    ListReservationItemsByReservationStatusQueryResult,
    useGetReservationStatusCountsQuery,
    useListReservationItemsByReservationStatusQuery,
} from 'generated/graphql';

import { FormatObject, TranslateFunction, useI18n } from 'i18n';

import { isAllocationRequired } from 'utils/reservation';

import { Reservation } from '../types';

const FIVE_MINUTES_MS = Duration.fromObject({ minutes: 5 }).toMillis();

type ServerReservationItem = ListReservationItemsByReservationStatusQuery['listReservationItemsByReservationStatus'][0];

interface Input {
    filterMode: FilterMode;
    fulfillerAccountID: string;
}

interface Result extends Pick<ListReservationItemsByReservationStatusQueryResult, 'loading' | 'error'> {
    countsByStatus: Partial<{
        requested: number;
        confirmed: number;
        started: number;
    }>;
    lastUpdateTimestamp: string | null;
    data: Reservation[];
    refetch: () => Promise<void>;
}

/**
 * Squishes reservation items back into a simplified object representing relevant reservation metadata for display
 */
export function compressReservationItems(
    reservationItems: ServerReservationItem[] = [],
    { t, format }: { t: TranslateFunction; format: FormatObject },
): Reservation[] {
    const orderedMappedReservations = (reservationItems ?? []).reduce<Map<string, Reservation>>(
        (reservations: Map<string, Reservation>, reservationItem) => {
            const reservation = reservationItem?.reservation;
            const reservationID = reservation?.id;
            const res: Reservation = reservations.get(reservationItem.reservation.id) ?? {
                id: reservationID,
                serviceType: reservation.type,
                shortID: reservation.id.substring(0, 5),
                name: reservation?.name?.trim(),
                requestDate: reservation?.createdAt,
                requesterName: reservation?.userRequested?.name ?? '',
                requesterOrgName: reservation?.account?.name || '',
                start: reservationItem?.start,
                end: reservationItem?.end,
                status: reservation?.status,
                unitQuantity: 0,
                units: [],
                isFullyAllocated: true,
                serviceAreaName: reservation?.serviceArea?.name ?? t?.('service_area_unassigned'),
                alerts: reservation.activeAlerts.map(x => toAlert(x, { t, format })),
            };

            res.unitQuantity += 1;

            if (reservationItem.deviceInstanceSchedule && reservationItem.deviceInstanceSchedule.length) {
                res.units.push({
                    name:
                        reservationItem.alias ||
                        t?.('admin_reservation_index_page.units_tooltip_label', { index: res.unitQuantity }),
                    assignedMPUs: reservationItem.deviceInstanceSchedule
                        .filter(schedule => schedule.assignment === Assignment.Discharging)
                        .map(schedule => ({
                            id: schedule.deviceInstance.id,
                            name: schedule.deviceInstance.name,
                            isCurrent: reservationItem.assignedDevice?.id === schedule.deviceInstance.id,
                        })),
                    isAllocationRequired: isAllocationRequired(reservationItem),
                });
            } else {
                res.units.push({
                    name: t?.('admin_reservation_index_page.units_tooltip_label', { index: res.unitQuantity }),
                    assignedMPUs: [{ name: t?.('admin_reservation_index_page.units_tooltip_unassigned') }],
                    isAllocationRequired: isAllocationRequired(reservationItem),
                });
            }

            if (res.isFullyAllocated && res.units.some(unit => unit.isAllocationRequired)) {
                res.isFullyAllocated = false;
            }

            reservations.set(reservationID, res);

            return reservations;
        },
        new Map(),
    );

    return Array.from(orderedMappedReservations).map(x => x[1]);
}

/**
 * Responsible for providing reservation data synced and updated from the GQL server
 */
export default function useReservations({ filterMode, fulfillerAccountID }: Input): Result {
    const { t, format } = useI18n();
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<string | null>(null);

    const now = useNowPoller({ enablePolling: true, pollInterval: FIVE_MINUTES_MS });

    const { data: counts, refetch: refetchReservationCounts } = useGetReservationStatusCountsQuery({
        variables: { fulfillerAccountID },
        pollInterval: FIVE_MINUTES_MS,
    });

    // reservation items query + filter state
    const {
        data,
        loading,
        refetch: refetchReservations,
    } = useListReservationItemsByReservationStatusQuery({
        variables: {
            filterMode,
            time: now.toISO() ?? '',
        },
        errorPolicy: 'all',
        onCompleted: () => {
            setLastUpdateTimestamp(DateTime.now().toISO());
        },
    });

    const countsByStatus = counts?.countReservationsByStatus || {};

    return {
        loading,
        // TODO(derek): handle errors
        error: undefined,

        countsByStatus,
        lastUpdateTimestamp,
        data: compressReservationItems(data?.listReservationItemsByReservationStatus ?? [], { t, format }),

        refetch: () => Promise.all([refetchReservations(), refetchReservationCounts()]).then(x => undefined),
    };
}
