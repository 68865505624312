import React, { useId } from 'react';

import { Button, Icon, ModalDialog } from 'app/components';

import { useI18n } from 'i18n';

interface DocumentFormModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    formId: string;
    children: React.ReactNode;
}

const DocumentFormModal: React.FC<DocumentFormModalProps> = ({ children, isOpen, onClose, title, formId }) => {
    const documentFormModalId = useId();
    const { t } = useI18n();

    return (
        <ModalDialog
            id={documentFormModalId}
            open={isOpen}
            onClose={onClose}
            title={title}
            primaryCTA={
                <Button ctaType="primary" type="submit" form={formId} icon={<Icon name="document" />}>
                    {t('documents.generate_document')}
                </Button>
            }
        >
            {children}
        </ModalDialog>
    );
};

export default DocumentFormModal;
