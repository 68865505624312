import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import { SystemStyleObject } from '@mui/system';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import { Theme } from 'styles/theme';

import { InnerWrapperProps } from '../MultiGrid';
import { EXPANSION_TRANSITION_DURATION, EXPANSION_TRANSITION_EASE } from './constants';

interface GridColumnsProps extends InnerWrapperProps {
    /**
     * Determines the width of the column lines.
     */
    columnWidth: number;
}

/**
 * Renders grid columns using a linear-gradient background.
 */
export function ScheduleGridColumns({ columnWidth, sx, ...otherProps }: GridColumnsProps): ReactElement {
    function renderStyle(theme: Theme): SystemStyleObject<Theme> {
        const lineColor = theme.palette.grey[300];
        const gradientSteps = [
            `transparent 0px`,
            `transparent ${columnWidth - 1}px`,
            `${lineColor} ${columnWidth - 1}px`,
            `${lineColor} ${columnWidth}px`,
        ];

        return {
            backgroundImage: `linear-gradient(90deg, ${gradientSteps.join()})`,
            backgroundRepeat: 'repeat',
            backgroundSize: `${columnWidth}px 1px`,
            minHeight: '100%',
            transitionDuration: EXPANSION_TRANSITION_DURATION,
            transitionProperty: 'height',
            transitionTimingFunction: EXPANSION_TRANSITION_EASE,
        };
    }

    return <Box sx={[renderStyle, ...normalizeSxProp(sx)]} {...otherProps} />;
}
