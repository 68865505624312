import { ComponentProps, ReactElement, useCallback } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Dialog from './Dialog';
import usePageScrollLock from './usePageScrollLock';

type Props = Omit<ComponentProps<typeof Modal>, 'title' | 'children'> & ComponentProps<typeof Dialog>;

/**
 * Standalone dialog component responsible for layering and focus handling to be used until
 * we have a replacement for MUIs portal based dialogs / modals
 */
export default function ModalDialog({
    title,
    children,
    primaryCTA,
    tertiaryCTA,
    footerContent,
    open,
    ...props
}: Props): ReactElement {
    const { onClose } = props;

    const close = useCallback(
        (_, reason) => {
            if (reason !== 'backdropClick' && typeof onClose === 'function') {
                onClose();
            }
        },
        [onClose],
    );

    usePageScrollLock({ enabled: open });

    return (
        <Modal
            {...props}
            open={open}
            onClose={close}
            /**
             * MUI scroll lock does not preserve the scroll position. Disabling for now until it is fixed
             * and relying on `usePageScrollLock` for now.
             */
            disableScrollLock={true}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    maxHeight: '100%',
                    width: { xs: '100%', md: 'auto' },
                }}
            >
                <Dialog
                    title={title}
                    primaryCTA={primaryCTA}
                    tertiaryCTA={tertiaryCTA}
                    footerContent={footerContent}
                    onClose={onClose}
                >
                    {children}
                </Dialog>
            </Box>
        </Modal>
    );
}
