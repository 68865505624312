import * as luxon from 'luxon';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

// NOTE(will): add more variants here e.g. last 24 hours, last 15 minutes, etc.
const INTERVAL_OPTIONS = ['Yesterday', 'Today', 'Tomorrow'] as const;
export type IntervalOption = (typeof INTERVAL_OPTIONS)[number];

function isIntervalOption(name: unknown): name is IntervalOption {
    return INTERVAL_OPTIONS.find(intervalOption => intervalOption === name) !== undefined;
}

export const makeInterval = (intervalName: IntervalOption, tz: luxon.Zone): luxon.Interval => {
    const intervals: Record<IntervalOption, luxon.Interval> = {
        Yesterday: luxon.Interval.fromDateTimes(
            luxon.DateTime.local({ zone: tz }).minus({ days: 1 }).startOf('day'),
            luxon.DateTime.local({ zone: tz }).minus({ days: 1 }).endOf('day'),
        ),
        Today: luxon.Interval.fromDateTimes(
            luxon.DateTime.local({ zone: tz }).startOf('day'),
            luxon.DateTime.local({ zone: tz }).endOf('day'),
        ),
        Tomorrow: luxon.Interval.fromDateTimes(
            luxon.DateTime.local({ zone: tz }).plus({ days: 1 }).startOf('day'),
            luxon.DateTime.local({ zone: tz }).plus({ days: 1 }).endOf('day'),
        ),
    };
    return intervals[intervalName];
};

export interface Props {
    name: string;
    value: IntervalOption;
    onChange?: (changedValue: { value: IntervalOption }) => void;
}

// DateTimeRangePicker is a component that allows very limited selection of a timezone localized interval.
//
// Longer term, we'd like this component to support:
// - more selection of pre-defined relative times e.g. last 30 minutes, last 1 month, etc.
export default function DateTimeRangePicker({ name, value, onChange = interval => {} }: Props): JSX.Element {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const intervalName = event.target.value;
        if (!isIntervalOption(intervalName)) {
            throw new Error(`unexpected timezone: "${intervalName}"`);
        }
        onChange({ value: intervalName });
    };

    return (
        <TextField
            id="date-time-range-picker"
            name={name}
            value={value}
            label="Interval"
            select
            onChange={handleChange}
            fullWidth
            variant="standard"
        >
            {INTERVAL_OPTIONS.map(i => (
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>
            ))}
        </TextField>
    );
}
