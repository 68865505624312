import { useAddUserRolesMutation, useRemoveUserRolesMutation } from 'generated/graphql';

interface Input {
    id: string;
    currentRoles: string[];
    newRoles: string[];
}

type Output = { roles: string[] };

/**
 * Hook for consolidating the adding and removing of user roles. Returns an updateRoles() function for that purpose
 */
export default function useUpdateRoles(): (input: Input) => Promise<Output> {
    const [addUserRoles] = useAddUserRolesMutation();
    const [removeUserRoles] = useRemoveUserRolesMutation();

    /**
     * Evaluates diff between current roles and new roles, then calls the addUserRoles and removeUserRoles mutations as appropriate
     */
    return async function updateRoles({ id, currentRoles, newRoles }: Input): Promise<Output> {
        const rolesToAdd = newRoles.filter(role => role && !currentRoles.includes(role)) as string[];
        const rolesToRemove = currentRoles.filter(role => role && !newRoles.includes(role)) as string[];

        if (rolesToAdd.length) {
            await addUserRoles({ variables: { input: { userID: id, roles: rolesToAdd } } });
        }

        if (rolesToRemove.length) {
            await removeUserRoles({ variables: { input: { userID: id, roles: rolesToRemove } } });
        }

        return { roles: newRoles };
    };
}
