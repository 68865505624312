import { useCallback, useState } from 'react';

import { SxProps } from '@mui/material';

import { useCreateApiKeyForServiceAccountMutation, useCreateServiceAccountMutation } from 'generated/graphql';

import ServiceAccounts from './ServiceAccounts';
import useAccountServiceAccounts from './useAccountServiceAccounts';

interface Props {
    sx?: SxProps;
    accountID: string;
}

export default function StandaloneAccountServiceAccounts({ accountID, sx }: Props) {
    const { serviceAccounts, refetch } = useAccountServiceAccounts({ accountID });
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    // The table passes back which service account the new API Key is requested for. Empty string means none requested.
    const [createAPIKeyServiceAccountID, setCreateAPIKeyServiceAccountID] = useState('');
    const [createdAPIKeyToken, setCreatedAPIKeyToken] = useState('');

    const [createServiceAccount] = useCreateServiceAccountMutation();
    const [createAPIKeyForServiceAccount] = useCreateApiKeyForServiceAccountMutation();

    const onAddServiceAccount = useCallback(() => setIsCreateModalOpen(true), []);

    const onAddAPIKey = useCallback((serviceAccountID: string) => {
        setCreateAPIKeyServiceAccountID(serviceAccountID);
    }, []);

    const onClose = useCallback(() => {
        setIsCreateModalOpen(false);
        setCreateAPIKeyServiceAccountID('');
    }, []);

    const onAddAPIKeyConfirmed = useCallback(
        async (serviceAccountID: string) => {
            const createAPIKeyResult = await createAPIKeyForServiceAccount({ variables: { serviceAccountID } });
            refetch();
            setCreatedAPIKeyToken(createAPIKeyResult.data?.createAPIKeyForServiceAccount.token ?? '');
            onClose();
        },
        [refetch, onClose, createAPIKeyForServiceAccount],
    );

    const onAPIKeyCreatedDialogClosed = useCallback(() => {
        setCreatedAPIKeyToken('');
    }, []);

    const onCreateServiceAccount = useCallback(
        async (sa: { name: string }) => {
            try {
                await createServiceAccount({
                    variables: { accountID, serviceAccountName: sa.name },
                });
                refetch();
            } catch (error) {
                // todo toast?
            }
        },
        [accountID, createServiceAccount, refetch],
    );

    return (
        <>
            <ServiceAccounts
                sx={sx}
                serviceAccounts={serviceAccounts}
                onAddServiceAccount={onAddServiceAccount}
                onCreateServiceAccount={onCreateServiceAccount}
                isCreateServiceAccountModalOpen={isCreateModalOpen}
                onAddAPIKey={onAddAPIKey}
                onAddAPIKeyConfirmed={onAddAPIKeyConfirmed}
                createAPIKeyServiceAccountID={createAPIKeyServiceAccountID}
                createdAPIKeyToken={createdAPIKeyToken}
                onAPIKeyCreatedDialogClosed={onAPIKeyCreatedDialogClosed}
                onClose={onClose}
            />
        </>
    );
}
