import { AccountSelectorQuery, useAccountSelectorQuery } from 'generated/graphql';

type Account = AccountSelectorQuery['listAccounts']['edges'][0]['node'];

/**
 * Responsible for fetching accounts for selecting purposes
 */
export default function useAccounts(): {
    loading: boolean;

    accounts: Account[];

    accountsByID: { [id: string]: Account };
} {
    const { loading, data } = useAccountSelectorQuery();

    const connection = data?.listAccounts;
    const accounts = (connection?.edges ?? []).map(edge => edge.node);
    const accountsByID: { [id: string]: Account } =
        accounts?.reduce((acc, cur: Account) => ({ ...acc, [cur.id]: cur }), {}) ?? {};

    return {
        loading,
        accounts,
        accountsByID,
    };
}
