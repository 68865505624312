import { ReactElement, useMemo } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';

import { SxPropMixin } from 'app/components/normalizeSxProp';
import { Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { HeadingCellProps } from '../MultiGrid';
import { useCellDetails } from './hooks';
import { ScheduleCellPresenter } from './ScheduleCellPresenter';
import { useScheduleContext } from './ScheduleContext';

function useDateFromColumnIndex(columnIndex: number) {
    const config = useScheduleContext(payload => payload.data.config);
    const { format } = useI18n();
    const { historyLength, initialDate } = config;

    return useMemo(() => {
        const dateOfColumn = DateTime.fromISO(initialDate).plus({ days: columnIndex - historyLength });

        return format.dateDayOfMonth(dateOfColumn);
    }, [columnIndex, format, historyLength, initialDate]);
}

/**
 * Renders the dates of each cell in the horizontal heading list.
 */
export function ScheduleTimeCell({ index, style, sx }: HeadingCellProps & SxPropMixin): ReactElement {
    const date = useDateFromColumnIndex(index);
    const { markers, ranges } = useCellDetails({ columnIndex: index, rowIndex: 0 });

    return (
        <ScheduleCellPresenter markers={markers} isHeading ranges={ranges} style={style} sx={sx}>
            <Box alignItems="center" display="flex" height="100%" justifyContent="center">
                <Text variant="detail">{date}</Text>
            </Box>
        </ScheduleCellPresenter>
    );
}
