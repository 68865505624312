import { ComponentProps, ReactElement, useMemo } from 'react';

import { MultiGridRenderStats } from 'app/components/compounds/MultiGrid';
import {
    makeCellAvailabilityForOneRow,
    Schedule,
    ScheduleFilterToolbar,
    ScheduleInitialDateButtonToolbar,
} from 'app/components/compounds/Schedule';
import { useScheduleEventRowIndexes } from 'app/components/compounds/Schedule/hooks';

import useTheme from 'styles/theme';

import {
    DeviceInstanceWithSchedules,
    MpuScheduleEvent,
    ScheduleNavigationEvent,
    useMpuTooltipContentRenderer,
} from '../MpuSchedule';
import { EXPANDED_ROW_HEIGHT, HEADING_HEIGHT, ROW_HEIGHT } from './constants';
import { MpuIndexAsideCell } from './MpuIndexAsideCell';
import { MpuIndexHeadingCell } from './presenters';
import { deviceInstancesToMpuIndexScheduleEvents, makeMpuIndexScheduleAvailabilityLayer } from './transformers';
import { MpuIndexItemData } from './types';

interface MpuIndexSchedulePresenterProps {
    /**
     * The number of days visible at a time in the body grid.
     * Defaults to `14`.
     */
    daysDisplayed?: number;
    /**
     * A collection of device instances with schedules.
     */
    deviceInstances: DeviceInstanceWithSchedules[];
    /**
     * The number of days available to be viewed forward in the schedule.
     */
    futureLength?: number;
    /**
     * Total height of the component in pixels.
     */
    height: number;
    /**
     * The number of days available to be viewed backwards in the schedule.
     */
    historyLength?: number;
    /**
     * Determines the starting point of the initially displayed time frame.
     * Determines the initial time frame in combination with the `daysDisplayed` option.
     */
    initialDate: string;
    /**
     * When `true`, the component renders a loading overlay.
     */
    loading?: boolean;
    /**
     * A function called whenever the body grid renders a different set of cells.
     */
    onItemsRendered?: (renderStats: MultiGridRenderStats) => void;
    /**
     * Callback handler called when the search input value changes.
     */
    onSearchChange?: (searchValue: string) => void;
    /**
     * A callback invoked when the user navigates to a schedule event.
     */
    onScheduleNavigation?: (event: ScheduleNavigationEvent) => void;
    /**
     * Total width of the component in pixels.
     */
    width: number;
}

/**
 * A widget that wraps the `Schedule` component to display a schedule for a collection of MPUs.
 */
export function MpuIndexSchedulePresenter({
    daysDisplayed,
    deviceInstances,
    futureLength = 90,
    height,
    historyLength = 90,
    initialDate,
    loading,
    onItemsRendered,
    onSearchChange,
    onScheduleNavigation,
    width,
}: MpuIndexSchedulePresenterProps): ReactElement {
    const theme = useTheme();
    const itemData = useMemo((): MpuIndexItemData => ({ deviceInstances: deviceInstances }), [deviceInstances]);
    const events = useMemo(
        () => deviceInstancesToMpuIndexScheduleEvents(theme, deviceInstances),
        [deviceInstances, theme],
    );
    const getCellAvailability = useMemo(() => {
        return makeCellAvailabilityForOneRow({
            makeLayer: layer => makeMpuIndexScheduleAvailabilityLayer(theme, layer),
        });
    }, [theme]);

    // Expand all rows by default
    const defaultExpandedRows = useScheduleEventRowIndexes(events);

    const dayMarkers = useMemo<ComponentProps<typeof Schedule>['dayMarkers']>(
        () => [
            {
                color: theme.palette.brand.accent!,
                timestamp: initialDate,
            },
        ],
        [initialDate, theme],
    );

    const renderTooltipContent = useMpuTooltipContentRenderer({ onScheduleNavigation });
    type ToolbarLeftProps = Partial<ComponentProps<typeof ScheduleInitialDateButtonToolbar>>;
    type ToolbarRightProps = Partial<ComponentProps<typeof ScheduleFilterToolbar>>;

    return (
        <Schedule<MpuIndexItemData, MpuScheduleEvent>
            asideGridSize={3}
            collapsedRowHeight={ROW_HEIGHT.asValue('px')}
            dayMarkers={dayMarkers}
            daysDisplayed={daysDisplayed}
            defaultExpandedRows={defaultExpandedRows}
            events={events}
            expandedRowHeight={EXPANDED_ROW_HEIGHT.asValue('px')}
            futureLength={futureLength}
            getCellAvailability={getCellAvailability}
            headingHeight={HEADING_HEIGHT.asValue('px')}
            height={height}
            historyLength={historyLength}
            initialDate={initialDate}
            itemData={itemData}
            loading={loading}
            onItemsRendered={onItemsRendered}
            renderTooltipContent={renderTooltipContent}
            rowCount={deviceInstances.length}
            slots={{
                asideCell: MpuIndexAsideCell,
                headingCell: MpuIndexHeadingCell,
                toolbarLeft: ScheduleInitialDateButtonToolbar,
                toolbarRight: ScheduleFilterToolbar,
            }}
            slotProps={{
                toolbarLeft: {
                    isToday: true,
                } satisfies ToolbarLeftProps,
                toolbarRight: {
                    onSearchChange,
                } satisfies ToolbarRightProps,
            }}
            width={width}
        />
    );
}
