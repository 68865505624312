import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import { ActiveAlertsSummary, Button, Icon, PageHeader, SectionHeader } from 'app/components';
import { useLayer } from 'app/core/layers';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useRequestReservation } from 'app/core/Reservation';

import { RelativeTime, useI18n } from 'i18n';

import CreateReservationDialog from './CreateReservationDialog';

export { default as ReservationsKanbanPage } from './ReservationsKanbanPage';
export { default as ReservationsTablePage } from './ReservationsTablePage';

export function AdminReservationsPage() {
    const { toast } = useLayer();

    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<string | null>(null);
    const [lastRequestedReservationID, setLastRequestedReservationID] = useState<string | null>(null);
    const [isCreateReservationDialogOpen, setIsCreateReservationDialogOpen] = useState<boolean>(false);

    const requestReservation = useRequestReservation();

    const { t } = useI18n();

    const onLastUpdateTimestampChange = useCallback(
        ({ value }) => {
            setLastUpdateTimestamp(value);
        },
        [setLastUpdateTimestamp],
    );

    return (
        <>
            <Helmet>
                <title>{t('admin_reservation_index_page.meta.title')}</title>
                <meta name="description" content={t('admin_reservation_index_page.meta.description')} />
            </Helmet>

            <Box minHeight="100%">
                <PageHeader
                    title={t('admin_reservation_index_page.title')}
                    subtitle={t('admin_reservation_index_page.latest_update_time', {
                        dateTime: <RelativeTime key="last-update-time" time={lastUpdateTimestamp} />,
                    })}
                />

                <Box maxWidth="lg" m="auto">
                    <ActiveAlertsSummary mb={5} viewAllHref={getPathByName('ALERTS')} />
                </Box>

                <SectionHeader
                    headingLevel="H3"
                    title="All Reservations"
                    mb={4}
                    cta={
                        <Button
                            icon={<Icon name="add-circle" />}
                            onClick={() => {
                                setIsCreateReservationDialogOpen(true);
                            }}
                        >
                            {t('admin_reservation_index_page.cta.create_reservation')}
                        </Button>
                    }
                />

                <Outlet
                    context={{
                        lastRequestedReservationID,
                        onLastUpdateTimestampChange,
                    }}
                />
            </Box>

            <CreateReservationDialog
                isOpen={isCreateReservationDialogOpen}
                onClose={() => {
                    setIsCreateReservationDialogOpen(false);
                }}
                onCreateReservation={async ({ value }) => {
                    try {
                        const { id: reservationID } = await requestReservation(value);

                        toast.add({
                            severity: 'success',
                            title: t('admin_reservation_index_page.reservation_request_success.title'),
                            message: t('admin_reservation_index_page.reservation_request_success.message', {
                                reservationID,
                            }),
                        });

                        setLastRequestedReservationID(reservationID);
                    } catch (error) {
                        toast.add({
                            severity: 'error',
                            title: t('admin_reservation_index_page.reservation_request_error.title'),
                            message: t('admin_reservation_index_page.reservation_request_error.message'),
                        });
                    }
                }}
            />
        </>
    );
}
