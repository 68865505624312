import { ApolloError } from '@apollo/client';

import { findKnownServiceErrorKind } from './findKnownServiceErrorKind';
import { KnownServiceError } from './KnownServiceError';
import { isScheduleDeleteBugError } from './type-guards';

/**
 * Enhances the given error value into a `KnownServiceError`,
 * if the error value is a well-known `ApolloError`.
 *
 * @example
 *
 * try {
 *     await myMutationFn();
 * } catch (_error) {
 *     const error = enhanceError(_error);
 *
 *     if (error instanceof KnownServiceError) {
 *         alert(error.kind);
 *         return;
 *     }
 *
 *     throw error;
 * }
 *
 */
export function enhanceError<T>(error: T): KnownServiceError | T {
    if (!(error instanceof ApolloError)) return error;

    const kind = findKnownServiceErrorKind(error);

    if (!kind) return error;

    return new KnownServiceError(kind, error);
}

/**
 * Wraps the given promise to enhance the thrown error value into a `KnownServiceError`,
 * if the error value is a well-known `ApolloError`.
 *
 * This function should be used with mutations to more easily detect known service errors
 * to provide feedback to end-users.
 *
 * @example
 *
 * await enhanceAsyncError(myMutationFn());
 */
export async function enhanceAsyncError<T>(promise: Promise<T>): Promise<T> {
    try {
        return await promise;
    } catch (error) {
        throw enhanceError(error);
    }
}

/**
 * @throws {ApolloError} Re-throws unknown `ApolloError`s.
 */
export async function silenceKnownDeleteError(result: Promise<unknown>): Promise<void> {
    try {
        await enhanceAsyncError(result);
    } catch (error) {
        if (isScheduleDeleteBugError(error)) {
            // Do nothing
            return;
        }

        throw error;
    }
}
