import { useCallback } from 'react';

import { ScheduleChangeEvent, useEditDowntimePeriodDialog } from '../DowntimePeriodDialog';
import { MpuScheduleEventType } from './constants';
import { ScheduleNavigationEvent } from './tooltip-content';

interface ScheduleNavigationHandlerProps {
    /**
     * A callback invoked after an MPU's schedule has been changed.
     */
    onScheduleChange?: (event: ScheduleChangeEvent) => void;
    readOnly?: boolean;
}

export function useScheduleNavigationHandler({ onScheduleChange, readOnly }: ScheduleNavigationHandlerProps) {
    const editDowntimeDialog = useEditDowntimePeriodDialog({
        onScheduleChange,
    });

    const handleDowntimeScheduleNavigation = useCallback(
        (event: ScheduleNavigationEvent) => {
            // Prevent the link from triggering any navigation.
            event.preventDefault();

            if (readOnly) return;

            // We must close the tooltip before opening the dialog,
            // otherwise the tooltip will remain open.
            event.closeTooltip?.();

            const mpuID = event.deviceInstanceSchedule.deviceInstance.id;
            const targetScheduleID = event.deviceInstanceSchedule.id;

            editDowntimeDialog.open({
                mpuID,
                targetScheduleID,
            });
        },
        [editDowntimeDialog, readOnly],
    );

    const handleScheduleNavigation = useCallback(
        (event: ScheduleNavigationEvent) => {
            const { scheduleEvent } = event;

            if (scheduleEvent.type === MpuScheduleEventType.Downtime) {
                handleDowntimeScheduleNavigation(event);
                return;
            }

            // TODO: Handle other schedule types.
        },
        [handleDowntimeScheduleNavigation],
    );

    return handleScheduleNavigation;
}
