import { forwardRef, ReactElement } from 'react';
import styled from 'styled-components/macro';

import { Box, BoxProps } from '@mui/material';

import { PermissiveChildrenProp } from 'app/core/types';

interface FillProps extends BoxProps {
    target: 'screen' | 'anchor';
}

export interface LayerProps extends BoxProps {
    anchor?: boolean;
    fill?: 'screen' | 'anchor';
    children?: PermissiveChildrenProp;

    relAnchorX?: 'left' | 'center' | 'right';
    relAnchorY?: 'top' | 'center' | 'bottom';
    x?: 'left' | 'center' | 'right';
    y?: 'top' | 'center' | 'bottom';
    isOnOval?: boolean;
    isOval?: boolean;
}

interface PositionedInAnchorProps extends BoxProps {
    $relAnchorX: 'left' | 'center' | 'right';
    $relAnchorY: 'top' | 'center' | 'bottom';
    $x: 'left' | 'center' | 'right';
    $y: 'top' | 'center' | 'bottom';
    $isOnOval: boolean;
    $isOval: boolean;
}

const resolveRelAnchorY = (props: PositionedInAnchorProps) => {
    const { $relAnchorX, $relAnchorY, $isOnOval } = props;

    if ($isOnOval) {
        if ($relAnchorX === 'left' && $relAnchorY === 'top') return '14.5%';
        if ($relAnchorX === 'left' && $relAnchorY === 'bottom') return '85.5%';
        if ($relAnchorX === 'right' && $relAnchorY === 'top') return '14.5%';
        if ($relAnchorX === 'right' && $relAnchorY === 'bottom') return '85.5%';
    }

    if ($relAnchorY === 'top') return '0;';
    if ($relAnchorY === 'center') return '50%;';
    if ($relAnchorY === 'bottom') return '100%;';

    return '';
};

const resolveRelAnchorX = (props: PositionedInAnchorProps) => {
    const { $relAnchorX, $relAnchorY, $isOnOval } = props;

    if ($isOnOval) {
        if ($relAnchorX === 'left' && $relAnchorY === 'top') return '14.5%';
        if ($relAnchorX === 'left' && $relAnchorY === 'bottom') return '14.5%';
        if ($relAnchorX === 'right' && $relAnchorY === 'top') return '85.5%';
        if ($relAnchorX === 'right' && $relAnchorY === 'bottom') return '85.5%';
    }

    if ($relAnchorX === 'left') return '0%';
    if ($relAnchorX === 'center') return '50%;';
    if ($relAnchorX === 'right') return '100%';

    return '';
};

const resolveTranslateX = (props: PositionedInAnchorProps) => {
    const { $x, $y, $isOval } = props;

    if ($isOval) {
        if ($x === 'left' && $y === 'top') return '-14.5%';
        if ($x === 'left' && $y === 'bottom') return '-14.5%';
        if ($x === 'right' && $y === 'top') return '-85.5%';
        if ($x === 'right' && $y === 'bottom') return '-85.5%';
    }

    if ($x === 'left') {
        return '0%';
    } else if ($x === 'center') {
        return '-50%';
    } else if ($x === 'right') {
        return '-100%';
    }

    return null;
};

const resolveTranslateY = (props: PositionedInAnchorProps) => {
    const { $x, $y, $isOval } = props;

    if ($isOval) {
        if ($x === 'left' && $y === 'top') return '-14.5%';
        if ($x === 'left' && $y === 'bottom') return '-85.5%';
        if ($x === 'right' && $y === 'top') return '-14.5%';
        if ($x === 'right' && $y === 'bottom') return '-85.5%';
    }

    if ($y === 'top') {
        return '0%';
    } else if ($y === 'center') {
        return '-50%';
    } else if ($y === 'bottom') {
        return '-100%';
    }

    return null;
};

const resolvePosition = (props: PositionedInAnchorProps) => {
    const translateX = resolveTranslateX(props);
    const translateY = resolveTranslateY(props);

    if (!translateX && !translateY) return '';

    const tx = translateX ? `translateX(${translateX})` : '';
    const ty = translateY ? `translateY(${translateY})` : '';

    return `transform: ${tx} ${ty}`;
};

const Anchor = styled(Box)`
    position: relative;
`;

const targets = {
    screen: 'fixed',
    anchor: 'absolute',
};

const Fill = styled(Box)<FillProps>`
    position: ${({ target }: FillProps) => targets[target] || 'fixed'};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const PositionedInAnchor = styled(Box)<PositionedInAnchorProps>`
    position: absolute;

    top: ${resolveRelAnchorY};
    left: ${resolveRelAnchorX};

    ${resolvePosition};
`;

/**
 * Layer is a component used to statically position one element over another. A layer item will be positioned
 * relative to the first Layer identified as an anchor.
 *
 * @example
 * <Layer anchor>
 *    <Layer>I'm positioned relative to the anchor</Layer>
 * </Layer>
 *
 * Nested layer items within other layer items will be positioned relative to the next layer item
 * @example
 * <Layer>
 *      I'm positioned relative to the first positioned parent element
 *
 *      <Layer>I'm positioned relative to the first parent layer</Layer>
 * </Layer>
 *
 * Layers can be placed over the entire screen to create a frame for placing additional UI, such as panels, bottom sheets, modals, etc...
 *
 * @example
 * <Layer fill="screen">
 *  I'll fill the entire screen
 * </Layer>
 *
 * Layers can also fill just their anchored parents
 *
 * @example
 * <Layer anchor>
 *  <Layer fill="anchor">
 *      I'll fill my anchor
 *  </Layer>
 * </Layer>
 */
const Layer = forwardRef(
    (
        {
            anchor = false,
            fill,
            children,
            relAnchorX = 'center',
            relAnchorY = 'center',
            x = 'center',
            y = 'center',
            isOnOval = false,
            isOval = false,
            ...props
        }: LayerProps,
        ref,
    ): ReactElement => {
        if (anchor)
            return (
                <Anchor ref={ref} {...props}>
                    {children}
                </Anchor>
            );

        if (fill) {
            return (
                <Fill ref={ref} target={fill} {...props}>
                    {children}
                </Fill>
            );
        }

        return (
            <PositionedInAnchor
                ref={ref}
                $relAnchorX={relAnchorX}
                $relAnchorY={relAnchorY}
                $x={x}
                $y={y}
                $isOnOval={isOnOval}
                $isOval={isOval}
                {...props}
            >
                {children}
            </PositionedInAnchor>
        );
    },
);

export default Layer;
