import { TIME_CELL_HEIGHT } from 'app/components/compounds/Schedule/constants';

import CSSDimension from 'design-system/CSSDimension';

export enum Lane {
    Unavailable,
    Available,
    Downtime,
}

export const ROW_HEIGHT = CSSDimension.fromPixels(128);
export const HEADING_BOTTOM_PADDING = CSSDimension.fromPixels(16);
export const HEADING_HEIGHT = CSSDimension.fromPixels(
    HEADING_BOTTOM_PADDING.asValue('px') + TIME_CELL_HEIGHT.asValue('px'),
);
export const EXPANDED_ROW_TOP_SPACING = CSSDimension.fromPixels(48);
/** The offset to be multiplied to obtain a bar's expanded offset. */
export const EXPANDED_BAR_OFFSET_MULTIPLICAND = CSSDimension.fromPixels(32);
const HIGHEST_LANE = Lane.Downtime;
/** The height of all expanded bars. */
export const EXPANDED_BARS_HEIGHT = CSSDimension.fromPixels(
    EXPANDED_BAR_OFFSET_MULTIPLICAND.asValue('px') * (HIGHEST_LANE + 1),
);
const EXPANDED_ROW_BOTTOM_SPACING = CSSDimension.fromPixels(96);
export const EXPANDED_ROW_HEIGHT = CSSDimension.fromPixels(
    EXPANDED_ROW_TOP_SPACING.asValue('px') +
        EXPANDED_BARS_HEIGHT.asValue('px') +
        EXPANDED_ROW_BOTTOM_SPACING.asValue('px'),
);
