import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import { Text, UnitsMap, ZeroState } from 'app/components';
import { ReservationItemTable } from 'app/pages/RenterAccountDashboard/ReservationItemTable';

import { useI18n } from 'i18n';

import { Layout } from './layout';
import { ReservationItem } from './useRenterDashboardData';

export interface RenterAccountDashboardProps {
    userName?: string;
    reservationItems: ReservationItem[];
    reservationCount: number;
    hasError?: boolean;
    onViewDashboard: (event: { id: string }) => void;
}

export function RenterAccountDashboard({
    userName,
    reservationItems,
    reservationCount,
    hasError,
    onViewDashboard,
}: RenterAccountDashboardProps) {
    const { t } = useI18n();

    return (
        <Layout
            title={
                <Text variant="h1">
                    {t('renter_dashboard.title', {
                        name: userName,
                    })}
                </Text>
            }
            subtitle={
                <Text color="secondary">
                    {reservationCount > 0
                        ? t('renter_dashboard.tagline.main', {
                              unitCount: reservationItems.length,
                              reservationCount,
                          })
                        : t('renter_dashboard.tagline.no_reservations')}
                </Text>
            }
            errorMessage={hasError ? <Alert severity="error">{t('renter_dashboard.error_message')}</Alert> : undefined}
            mapHeading={<Text variant="h2">{t('renter_dashboard.map_heading')}</Text>}
            map={<UnitsMap units={reservationItems} />}
            tableHeading={<Text variant="h2">{t('renter_dashboard.table_heading')}</Text>}
            table={
                reservationCount > 0 ? (
                    <ReservationItemTable
                        reservationItems={reservationItems}
                        onRowClick={data => {
                            onViewDashboard({ id: data?.row?.reservationID });
                        }}
                    />
                ) : (
                    <Box maxWidth={1000} my={4} mx="auto">
                        <ZeroState message={t('renter_dashboard.no_active_rentals_message')} />
                    </Box>
                )
            }
        />
    );
}
