import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';

import { MultiGridController } from 'app/components/compounds/MultiGrid';
import { ExtractReducerContextAccessor } from 'app/components/createReducerContext';
import { DeviceInstance } from 'app/core/data';
import { isScheduleConflictError, useToastErrorHandler } from 'app/core/error';

import { ReservationScheduleReservationItemFragment } from 'generated/graphql';

import { useI18n } from 'i18n';

import {
    getAllocation,
    getLatestAllocationForUnit,
    getRowIndexFromUnitID,
    getUnit,
    getUnitNextAllocation,
    getUnitPrevAllocation,
} from './accessors';
import {
    addAllocation,
    deletePendingAllocation,
    insertPendingAllocation,
    insertPendingAllocationAfter,
    insertPendingAllocationBefore,
    insertPendingAllocationCollection,
    removeAllocation,
    requestAllocationAssignment,
    updateAllocationPeriod,
    updatePendingAllocation,
    updateReservationItems,
} from './actions';
import { DeleteAllocationResult } from './constants';
import { ReservationScheduleContext } from './ReservationScheduleContext';
import {
    getShrunkAllocationEnd,
    nonPersistedToPreparedAllocation,
    persistedToPreparedAllocation,
} from './transformers';
import {
    AllocationPatch,
    AssignAllocationRequestHandler,
    DeleteAllocationHandler,
    PersistAllocationHandler,
    PersistedAllocation,
    PreparedAllocation,
    RefreshReservationItemsHandler,
    ReservationScheduleController,
} from './types';

export function useReservationScheduleSideEffects({
    getContext,
    handleDeleteAllocation = async () => DeleteAllocationResult.Success,
    handlePersistAllocation,
    handleRefreshReservationItems,
    onAssignAllocationRequest,
    reservationItems,
    scheduleControllerRef,
}: {
    getContext: ExtractReducerContextAccessor<typeof ReservationScheduleContext>;
    handleDeleteAllocation: DeleteAllocationHandler | undefined;
    handlePersistAllocation: PersistAllocationHandler | undefined;
    handleRefreshReservationItems: RefreshReservationItemsHandler | undefined;
    onAssignAllocationRequest: AssignAllocationRequestHandler | undefined;
    reservationItems: readonly ReservationScheduleReservationItemFragment[] | undefined;
    scheduleControllerRef: MutableRefObject<MultiGridController<void> | undefined>;
}): ReservationScheduleController {
    const { dispatch } = getContext();
    const { t } = useI18n();
    const handleError = useToastErrorHandler();

    /**
     * Update the row height of the row containing the unit with the given ID.
     */
    const updateRowHeightByUnitID = useCallback(
        (unitID: string): void => {
            const { getState } = getContext();
            const rowIndex = getRowIndexFromUnitID(getState(), unitID);

            if (rowIndex !== undefined) {
                scheduleControllerRef.current?.resetAfterRowIndex(rowIndex);
            }
        },
        [getContext, scheduleControllerRef],
    );

    /**
     * Update the row height of the row containing the allocation with the given ID,
     */
    const updateRowHeightFromAllocationID = useCallback(
        async (allocationID: string): Promise<void> => {
            const { getState } = getContext();
            const allocation = getAllocation(getState(), allocationID);

            if (!allocation) return;

            updateRowHeightByUnitID(allocation.unitID);
        },
        [getContext, updateRowHeightByUnitID],
    );

    /**
     * Attempt to persist the given allocation while handling any errors.
     */
    const attemptPersistAllocation = useCallback(
        async (allocation: PreparedAllocation) => {
            try {
                // The associated pending allocation is automatically removed,
                // from the component state when the reservation items are updated,
                // with the new device instance schedules.
                // This operations occurs in the `reduceUpdateReservationItems` reducer function;
                await handlePersistAllocation?.(allocation);
            } catch (error) {
                const message = isScheduleConflictError(error)
                    ? t('reservation_schedule.errors.schedule_conflict')
                    : t('reservation_schedule.errors.create_allocation_failure');

                handleError(error, message);
            }
        },
        [handleError, handlePersistAllocation, t],
    );

    /**
     * Attempt to delete the given allocation while handling any errors.
     */
    const attemptDeletePersistedAllocation = useCallback(
        async (persistedAllocation: PersistedAllocation): Promise<DeleteAllocationResult> => {
            try {
                // An allocation's schedules are discovered by performing a query
                // using the allocation's time span. If the time span of the
                // updated allocation is different from the original allocation,
                // then the wrong collection of schedules may be discovered.
                return handleDeleteAllocation(persistedAllocation);
            } catch (error) {
                handleError(error, t('reservation_schedule.errors.update_allocation_failure'));

                return DeleteAllocationResult.Error;
            }
        },
        [handleError, handleDeleteAllocation, t],
    );

    /**
     * Attempt to update the given persisted allocation while handling any errors.
     *
     * @param persistedAllocation The allocation to update.
     * @param allocationUpdate The properties to update on the given allocation.
     * @param onBeforePersist A optional procedure called before the allocation persisted again after being deleted.
     *
     * @remarks
     *
     * Allocations are updated by deleting the original allocation and creating a new allocation.
     * This is preferred to updating the original allocation, because it is simpler to implement,
     * and avoids potential scheduling conflicts.
     *
     * This procedure occurs in three steps:
     *
     * 1. Delete the original allocation.
     * 2. Call the optional `onBeforePersist` procedure.
     * 3. Persist the updated allocation as a new allocation.
     *
     * @privateRemarks
     *
     * TODO: This procedure should be replaced with a batch schedule update mutation.
     * {@link https://moxionpower.atlassian.net/browse/SWE-2147 | Related JIRA issue (SWE-2147)}
     */
    const attemptUpdatePersistedAllocation = useCallback(
        async (
            persistedAllocation: PersistedAllocation,
            allocationUpdate: AllocationPatch,
            onBeforePersist?: () => Promise<void>,
        ) => {
            // The original allocation must be used for deletion, to ensure the correct schedules are deleted.
            await attemptDeletePersistedAllocation(persistedAllocation);

            await onBeforePersist?.();

            const preparedAllocation = persistedToPreparedAllocation(persistedAllocation, allocationUpdate);

            await attemptPersistAllocation(preparedAllocation);
        },
        [attemptDeletePersistedAllocation, attemptPersistAllocation],
    );

    /**
     * Attempt to refresh the reservation items while handling any errors.
     */
    const attemptRefreshReservationItems = useCallback(async () => {
        try {
            await handleRefreshReservationItems?.();
        } catch (error) {
            handleError(error, t('reservation_schedule.errors.refresh_reservation_items_failure'));
        }
    }, [handleError, handleRefreshReservationItems, t]);

    /**
     * Attempt to update the given allocation while handling any errors.
     *
     * @param allocationUpdate The properties to update on the given allocation.
     * @param onBeforePersist A optional procedure called before the given allocation changes are persisted.
     * In the case of updating a `PendingAllocation`, the procedure is invoked after the allocation is updated in component state.
     *
     * @privateRemarks
     *
     * TODO: This procedure should be replaced with a batch schedule update mutation.
     * {@link https://moxionpower.atlassian.net/browse/SWE-2147 | Related JIRA issue (SWE-2147)}
     */
    const updateAllocation = useCallback(
        async ({
            allocationUpdate,
            onBeforePersist,
        }: {
            allocationUpdate: AllocationPatch;
            onBeforePersist?: () => Promise<void>;
        }): Promise<void> => {
            const { dispatch, getState } = getContext();
            const allocation = getAllocation(getState(), allocationUpdate.id);

            if (!allocation) return;

            if (allocation.isPersisted) {
                await attemptUpdatePersistedAllocation(allocation, allocationUpdate, onBeforePersist);
                await attemptRefreshReservationItems();
                return;
            }

            const preparedAllocation = nonPersistedToPreparedAllocation(allocation, allocationUpdate);

            if (preparedAllocation) {
                await onBeforePersist?.();
                await attemptPersistAllocation(preparedAllocation);
                await attemptRefreshReservationItems();
                return;
            }
            if (!allocationUpdate.isPersisted) {
                await dispatch(updatePendingAllocation(allocationUpdate));
                await onBeforePersist?.();
                return;
            }

            // This shouldn't happen.
            handleError(new Error('Invalid allocation update.'), t('reservation_schedule.errors.unknown_failure'));
        },
        [
            attemptPersistAllocation,
            attemptRefreshReservationItems,
            attemptUpdatePersistedAllocation,
            getContext,
            handleError,
            t,
        ],
    );

    /**
     * Reduce the duration of given allocation to half it's original duration.
     */
    const shrinkAllocation = useCallback(
        async (allocationID: string): Promise<void> => {
            const { getState } = getContext();
            const allocation = getAllocation(getState(), allocationID);

            if (!allocation) return;

            const allocationUpdate = {
                end: getShrunkAllocationEnd(allocation),
                id: allocationID,
                isPersisted: allocation.isPersisted,
            };

            await updateAllocation({ allocationUpdate });
        },
        [getContext, updateAllocation],
    );

    /**
     * Add an allocation to the given unit.
     */
    const handleAddAllocation = useCallback(
        async (unitID: string): Promise<void> => {
            const { dispatch, getState } = getContext();
            const latestAllocation = getLatestAllocationForUnit(getState(), unitID);

            if (latestAllocation) {
                const unit = getUnit(getState(), unitID);

                if (latestAllocation.end === unit?.end) {
                    await shrinkAllocation(latestAllocation.id);
                }
            }

            await dispatch(insertPendingAllocation(unitID));

            updateRowHeightByUnitID(unitID);
        },
        [getContext, shrinkAllocation, updateRowHeightByUnitID],
    );

    /**
     * Remove the given allocation from the reservation.
     */
    const handleRemoveAllocation = useCallback(
        async (allocationID: string): Promise<void> => {
            const { dispatch, getState } = getContext();
            const allocation = getAllocation(getState(), allocationID);

            if (!allocation) return;

            const { unitID } = allocation;

            if (allocation.isPersisted) {
                try {
                    await handleDeleteAllocation?.(allocation);
                } catch (error) {
                    handleError(error, t('reservation_schedule.errors.delete_allocation_failure'));
                }
                try {
                    await handleRefreshReservationItems?.();
                } catch (error) {
                    handleError(error, t('reservation_schedule.errors.refresh_reservation_items_failure'));
                }
            } else {
                await dispatch(deletePendingAllocation(allocationID));
            }

            updateRowHeightByUnitID(unitID);
        },
        [getContext, handleDeleteAllocation, handleError, handleRefreshReservationItems, updateRowHeightByUnitID, t],
    );

    /**
     * Update the end of the given allocation.
     */
    const handleUpdateAllocationDateTimeSpan = useCallback(
        async ({ end, id, start }: ReturnType<typeof updateAllocationPeriod>['payload']): Promise<void> => {
            const { getState } = getContext();
            const allocation = getAllocation(getState(), id);

            if (!allocation) return;

            const endDateTime = DateTime.fromISO(allocation.end);
            const startDateTime = DateTime.fromISO(allocation.start);
            const nextEndDateTime = DateTime.fromISO(end);
            const nextStartDateTime = DateTime.fromISO(start);
            const startChanged = !startDateTime.equals(nextStartDateTime);
            const endChanged = !endDateTime.equals(nextEndDateTime);
            const nextAllocation = getUnitNextAllocation(getState(), id);
            const prevAllocation = getUnitPrevAllocation(getState(), id);
            const shouldUpdateNextAllocation = nextAllocation && endChanged;
            const shouldUpdatePrevAllocation = prevAllocation && startChanged;

            if (!endChanged && !startChanged) return;

            async function updatePrevAllocation() {
                if (!shouldUpdatePrevAllocation) return;

                const allocationUpdate = {
                    end: start,
                    id: prevAllocation.id,
                    isPersisted: prevAllocation.isPersisted,
                    start: prevAllocation.start,
                };

                await updateAllocation({ allocationUpdate });
            }

            async function updateNextAllocation() {
                if (!shouldUpdateNextAllocation) return;

                const allocationUpdate = {
                    end: nextAllocation.end,
                    id: nextAllocation.id,
                    isPersisted: nextAllocation.isPersisted,
                    start: end,
                };

                await updateAllocation({ allocationUpdate });
            }

            const targetAllocationUpdate = {
                end,
                id,
                isPersisted: allocation.isPersisted,
                start,
            };

            // 1. Delete the target allocation
            // 2. Update the previous allocation's end to the target allocation's start
            // 3. Update the next allocation's start to the target allocation's end
            // 4. Persist the target allocation
            await updateAllocation({
                allocationUpdate: targetAllocationUpdate,
                async onBeforePersist() {
                    await updatePrevAllocation();
                    await updateNextAllocation();
                },
            });
        },
        [getContext, updateAllocation],
    );

    /**
     * Request an assignment for the given allocation.
     */
    const handleRequestAllocationAssignment = useCallback(
        async (allocationID: string): Promise<void> => {
            const { getState } = getContext();
            const allocation = getAllocation(getState(), allocationID);

            if (!allocation) return;

            onAssignAllocationRequest?.(allocation);
        },
        [getContext, onAssignAllocationRequest],
    );

    /**
     * Assign the given MPU to the given allocation.
     */
    const assignDeviceInstance = useCallback(
        async (allocationID: string, mpu: DeviceInstance): Promise<void> => {
            const { getState } = getContext();
            const allocation = getAllocation(getState(), allocationID);

            if (!allocation) return;

            const allocationUpdate = {
                id: allocationID,
                isPersisted: allocation.isPersisted,
                mpuID: mpu.id,
                mpuName: mpu.name,
            };

            await updateAllocation({ allocationUpdate });
        },
        [getContext, updateAllocation],
    );

    // Creates an action listener for handling component side effects.
    useEffect(() => {
        return getContext().onActionDispatch(async action => {
            if (insertPendingAllocation.match(action)) {
                updateRowHeightByUnitID(action.payload);
                return;
            }
            if (insertPendingAllocationAfter.match(action) || insertPendingAllocationBefore.match(action)) {
                updateRowHeightFromAllocationID(action.payload);
                return;
            }
            if (insertPendingAllocationCollection.match(action)) {
                updateRowHeightByUnitID(action.payload.unitID);
                return;
            }
            if (addAllocation.match(action)) {
                handleAddAllocation(action.payload);
                return;
            }
            if (removeAllocation.match(action)) {
                handleRemoveAllocation(action.payload);
                return;
            }
            if (updateAllocationPeriod.match(action)) {
                handleUpdateAllocationDateTimeSpan(action.payload);
                return;
            }
            if (requestAllocationAssignment.match(action)) {
                handleRequestAllocationAssignment(action.payload);
                return;
            }
        });
    }, [
        getContext,
        handleAddAllocation,
        handleRemoveAllocation,
        handleRequestAllocationAssignment,
        handleUpdateAllocationDateTimeSpan,
        updateRowHeightByUnitID,
        updateRowHeightFromAllocationID,
    ]);

    // Update `reservationItems` in state, when the `reservationItems` prop changes.
    useEffect(() => {
        dispatch(updateReservationItems(reservationItems || []));
    }, [dispatch, reservationItems]);

    return useMemo((): ReservationScheduleController => ({ assignDeviceInstance }), [assignDeviceInstance]);
}
