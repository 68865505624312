import { ReactElement, ReactNode } from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';

interface Props {
    /**
     * Whether to force the active state. This should not be necessary in practice and we should rely on the
     * active state being managed by react-router-dom
     */
    active?: boolean;

    /**
     * The imagery to match the link label text
     */
    icon: ReactNode;

    /**
     * The application routes path
     */
    to: string;

    /**
     * The Link label content
     */
    children: ReactNode;

    /**
     * Whether or not to display the link full screen, default is inline
     */
    fullWidth: boolean;

    /**
     * Handler to be called when a nav link is clicked
     */
    onClick: () => void;
}

/**
 * Responsible for fulfilling style requirements for the navigation links in the PrimaryNav
 */
export default function NavLink({ active, icon, ...props }: Props): ReactElement {
    const theme = useTheme();

    return (
        <Button
            {...props}
            className={active ? '.active' : undefined}
            sx={{
                color: 'text.disabled',
                backgroundColor: 'background.contrast.main',
                justifyContent: 'flex-start',
                '&.active': {
                    color: 'background.contrast.contrastText',
                    backgroundColor: 'background.contrast.light',
                },
                '&.active .mx-icon': { fill: `${theme.palette.brand.accent} !important` },
                '&:hover': {
                    backgroundColor: 'background.contrast.dark',
                },
                '&.active:hover': {
                    backgroundColor: 'background.contrast.light',
                },
            }}
            startIcon={icon}
            disableElevation
            variant="contained"
            component={BaseNavLink}
        />
    );
}
