import { ComponentProps, ReactElement, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Portal from '@mui/material/Portal';

import SectionHeader from 'app/components/primitives/layout/SectionHeader';

interface PageHeaderProps extends Pick<ComponentProps<typeof SectionHeader>, 'title' | 'subtitle' | 'cta'> {
    gutterBottom?: boolean;
}

export default function PageHeader({ title, subtitle, cta, gutterBottom = true }: PageHeaderProps): ReactElement {
    const [portalHost, setPortalHost] = useState(document.getElementById('page-header'));

    useEffect(() => {
        setPortalHost(document.getElementById('page-header'));
    }, []);

    return (
        <>
            <Portal container={portalHost}>
                <SectionHeader title={title} subtitle={subtitle} headingLevel="H2" as="h1" cta={cta} />
            </Portal>

            <Box display={{ xs: 'flex', lg: 'none' }} mb={gutterBottom ? 5 : undefined}>
                <SectionHeader title={title} subtitle={subtitle} headingLevel="H1" cta={cta} />
            </Box>
        </>
    );
}
