import { ReactElement } from 'react';

import Paper from '@mui/material/Paper';

import { Button, Link, PageHeader, ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { User } from './types';

interface UserPageProps {
    isLoading: boolean;
    error: boolean;
    user?: User;

    userInfoSection?: ReactElement;
    rolesSection?: ReactElement;

    onDeactivateUser: () => void;
    onActivateUser: () => void;
}

/**
 * Responsible for the User Detail page layout
 */
export function UserPage({
    isLoading,
    error,
    user,

    userInfoSection,
    rolesSection,

    onDeactivateUser,
    onActivateUser,
}: UserPageProps): ReactElement {
    const { t } = useI18n();

    return (
        <>
            <PageHeader
                title={user?.name}
                subtitle={
                    <Link to={getPathByName('ACCOUNT_MGMT_ACCOUNT', { params: { accountID: user?.accountID ?? '' } })}>
                        {user?.accountName}
                    </Link>
                }
            />

            {!isLoading && !!error && (
                <ZeroState
                    title={t('admin_user_page.admin_user_page_title')}
                    message={t('admin_user_page.fetch_error')}
                    cta={<Button to="/admin/dashboard/users">{t('admin_user_page.see_all_users_cta')}</Button>}
                />
            )}

            {user && !error && (
                <>
                    <Paper variant="outlined" sx={{ p: 4, mb: 5 }}>
                        {userInfoSection}
                    </Paper>

                    <Paper variant="outlined" sx={{ p: 4, mb: 5 }}>
                        {rolesSection}
                    </Paper>

                    {user.active && (
                        <Button ctaType="secondary" color="danger" onClick={onDeactivateUser}>
                            {t('admin_user_page.cta.deactivate_user')}
                        </Button>
                    )}

                    {user.active === false && (
                        <Button ctaType="secondary" color="primary" onClick={onActivateUser}>
                            {t('admin_user_page.cta.activate_user')}
                        </Button>
                    )}
                </>
            )}
        </>
    );
}
