import { createReducerContext } from 'app/components/createReducerContext';

import { createDefaultReservationScheduleState, reservationScheduleReducer } from './state';
import { ReservationScheduleOptions, ReservationScheduleState } from './types';

export const ReservationScheduleContext = createReducerContext<ReservationScheduleState, void>();

/**
 * Initializes the Schedule component.
 *
 * Should only be called once in the `Schedule` component.
 *
 * @returns The context value object that should be given to `ScheduleProvider`.
 */
export function useReservationScheduleInit(options: ReservationScheduleOptions) {
    return ReservationScheduleContext.useContextValue({
        createDefaultState: createDefaultReservationScheduleState,
        data: undefined,
        // TODO(Morris): Disable debug mode.
        // TODO(Morris): Implement app wide debug flag to enable this.
        debug: true,
        options,
        reducer: reservationScheduleReducer,
    });
}

export const ReservationScheduleProvider = ReservationScheduleContext.Provider;
export const useReservationScheduleDispatch = ReservationScheduleContext.useDispatch;
export const useReservationScheduleState = ReservationScheduleContext.useReducerState;
