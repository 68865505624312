import { DateTime } from 'luxon';

import { resolveDateTime } from 'app/core/date-time';
// types
import { ISO8601, Series } from 'app/core/types';

import { GetReservationItemTelemetryQueryHookResult, Telemetry } from 'generated/graphql';

import { useI18n } from 'i18n';

import { MetricName } from './constants';
import toSeries from './toSeries';
import useEnergyUsage, { useEnergyUsageLazy } from './useEnergyUsage';
import useTelemetryData, { useTelemetryDataLazy } from './useTelemetryData';

interface Input {
    /**
     * The device instance id to pull telemetry for
     */
    mpuID?: string | undefined;

    /**
     * The unique identifier for a reservation item
     */
    unitID: string;

    /**
     * The metrics to plot on the same axis
     */
    metricNames: MetricName[];

    /**
     * Fetch datapoints from start date
     */
    start?: DateTime | ISO8601 | null;

    /**
     * The end of the interval to fetch datapoints for
     */
    end?: DateTime | ISO8601 | null;

    /**
     * Whether or not to poll to display semi real time chart data, if an end date time is passed in
     * this will disable polling
     */
    enablePolling?: boolean;

    /**
     * The interval to poll at (if enabled)
     */
    pollInterval?: number;

    /**
     * Whether to skip the operation or not
     */
    skip?: boolean;
}

interface Result extends Pick<GetReservationItemTelemetryQueryHookResult, 'error' | 'loading'> {
    series: Series[];
}

function bucketMetricNames(metricNames) {
    const seed: { energyUsageMetrics: string[]; rawMetrics: string[] } = { energyUsageMetrics: [], rawMetrics: [] };

    return metricNames.reduce((acc, cur) => {
        if (/^energy\//.test(cur)) {
            return {
                ...acc,
                energyUsageMetrics: (acc?.energyUsageMetrics ?? []).concat(cur),
            };
        }

        return {
            ...acc,
            rawMetrics: (acc?.rawMetrics ?? []).concat(cur),
        };
    }, seed);
}

/**
 * Responsible for fetching data and formatting it for display in TimeSeriesChart
 */
export default function useChartData({
    mpuID,
    unitID,
    metricNames,
    start,
    end,
    enablePolling = false,
    pollInterval = 60000,
    skip = false,
}: Input): Result {
    const { t } = useI18n();

    const sortedMetrics = bucketMetricNames(metricNames);
    const shouldSkipRawMetrics = sortedMetrics.rawMetrics.length === 0;
    const shouldSkipEnergyUsageMetrics = sortedMetrics.energyUsageMetrics.length === 0;

    const {
        loading: metricsLoading,
        error: metricsError,
        data: metricsData,
    } = useTelemetryData({
        unitID,
        mpuID,
        metricNames,
        enablePolling,
        pollInterval,
        start: resolveDateTime(start),
        end: resolveDateTime(end),
        skip: skip || shouldSkipRawMetrics,
    });

    const {
        loading: energyUsageLoading,
        error: energyUsageError,
        data: energyUsageData,
    } = useEnergyUsage({
        mpuID,
        unitID,
        start: start,
        end: end,
        enablePolling,
        pollInterval,
        skip: skip || shouldSkipEnergyUsageMetrics,
    });

    return {
        loading:
            (shouldSkipEnergyUsageMetrics ? true : energyUsageLoading) &&
            (shouldSkipRawMetrics ? true : metricsLoading),
        error: metricsError || energyUsageError,
        series: toSeries((metricsData ?? []).concat(energyUsageData ?? []), { t }),
    };
}

/**
 * Responsible for retrieving metrics on demand
 */
export function useChartDataLazy() {
    const { t } = useI18n();
    const fetchTelemetryData = useTelemetryDataLazy();
    const fetchEnergyUsageData = useEnergyUsageLazy();

    return async function ({
        mpuID,
        unitID,
        metricNames,
        start,
        end,
    }: {
        mpuID?: string;
        unitID?: string;
        metricNames: MetricName[];
        start?: DateTime | ISO8601;
        end?: DateTime | ISO8601;
    }) {
        const sortedMetrics = bucketMetricNames(metricNames);
        const shouldSkipRawMetrics = sortedMetrics.rawMetrics.length === 0;
        const shouldSkipEnergyUsageMetrics = sortedMetrics.energyUsageMetrics.length === 0;
        const requests: Promise<any>[] = [];

        if (!shouldSkipEnergyUsageMetrics) {
            requests.push(fetchEnergyUsageData({ mpuID, unitID, start, end }));
        }

        if (!shouldSkipRawMetrics) {
            requests.push(fetchTelemetryData({ mpuID, unitID, metricNames: sortedMetrics.rawMetrics, start, end }));
        }

        return Promise.all(requests).then(results => {
            const seed: Telemetry[] = [];
            const combinedData = results.reduce((acc, cur) => {
                return acc.concat(cur?.data ?? []);
            }, seed);

            return { data: toSeries(combinedData, { t }) };
        });
    };
}
