import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Layer, Text } from 'app/components/primitives';

import { CHART_X_AXIS_HEIGHT } from '../config';

interface Props {
    /**
     * The width of the parent / anchor
     */
    width?: number;

    /**
     * The current position of the reference line
     */
    x?: number | null;

    /**
     * Whether the reference line is locked
     */
    isLocked?: boolean;

    /**
     * The time marker UI
     */
    timeMarker: ReactElement;

    /**
     * Whether the info panel should be visible or not
     */
    showInfoPanel?: boolean;

    /**
     * The info panel with active faults content
     */
    infoPanel: ReactElement;
}

const MAX_PANEL_WIDTH = 300;
const ROUGH_TIME_MARKER_WIDTH = 140;
const REF_LINE_WIDTH = 2;

/**
 * Responsible for the layout aspects of the time scrubber
 */
export default function Layout({
    width = 0,
    x = 0,
    isLocked,
    timeMarker,
    showInfoPanel,
    infoPanel,
}: Props): ReactElement {
    if (x == null) return <></>;

    const spaceLeft = width - x;
    const shouldNudgeTimeMarker = spaceLeft >= ROUGH_TIME_MARKER_WIDTH / 2;
    const shouldFlip = spaceLeft > MAX_PANEL_WIDTH;

    return (
        <Box
            component={Text}
            sx={theme => ({
                pointerEvents: 'none',
                position: 'absolute',
                top: '100%',

                // We transform the container such that it does not cause an overflow
                // on the parent when we want to enable horizontal scroll to accomodate
                // smaller screens
                transform: `translate(${shouldFlip ? '0' : '-100%'}, -100%)`,

                left: x,
                width: { xs: 200, md: MAX_PANEL_WIDTH },
                height: `calc(100% - ${CHART_X_AXIS_HEIGHT}px)`,
                mt: `-${CHART_X_AXIS_HEIGHT}px`,

                // Reference line styles
                borderColor: theme.palette.brand.accent,
                borderStyle: 'none',
                borderWidth: REF_LINE_WIDTH,
                borderLeftStyle: shouldFlip ? 'solid' : 'none',
                borderRightStyle: shouldFlip ? 'none' : 'solid',
            })}
        >
            {showInfoPanel && (
                <Layer relAnchorX="left" relAnchorY="bottom" x="left" y="bottom" width="100%" height="100%">
                    <Stack
                        m={2}
                        sx={theme => ({
                            backgroundColor: theme.palette.lighten[80],
                            borderRadius: `${theme.shape.borderRadius}px`,
                            p: 3,
                            overflow: 'auto',
                            maxHeight: '100%',
                            position: 'absolute',
                            pointerEvents: isLocked ? 'auto' : 'none',
                            bottom: 0,
                            right: shouldFlip ? null : 0,
                        })}
                        spacing={2}
                    >
                        {infoPanel}
                    </Stack>
                </Layer>
            )}

            <Layer
                relAnchorY="bottom"
                relAnchorX={shouldFlip ? 'left' : 'right'}
                y="top"
                x={shouldNudgeTimeMarker ? 'center' : 'right'}
                ml={shouldNudgeTimeMarker ? undefined : 3}
            >
                {timeMarker}
            </Layer>
        </Box>
    );
}
