import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { formatURLState } from 'app/core/Navigation/getPathByName';

import { AlertType } from 'generated/graphql';

import { useI18n } from 'i18n';

import { Link } from '../../primitives/Link';
import Text from '../../primitives/Text';
import { FlattenedAlertForPreview, Reservation } from './types';

interface Props extends FlattenedAlertForPreview {}

const ReservationLink = ({ href, name, shortID }: Reservation): ReactElement => {
    return (
        <Link color="inherit" to={href}>
            {shortID}
            {name ? ` (${name})` : ''}
        </Link>
    );
};

const Title = ({ text, href }: { text: string; href: string }): ReactElement => (
    <Link color="inherit" to={href}>
        <Text variant="inherit" fontWeight="bold">
            {text}
        </Text>
    </Link>
);

const ReservationRow = ({ href, name, shortID }: Reservation): ReactElement => {
    const { t } = useI18n();

    return (
        <Box>
            {t('active_alerts_preview.reservation')} <ReservationLink href={href} name={name} shortID={shortID} />
        </Box>
    );
};

const MPUOffline = ({
    lastUploadTime,
    mpuName,
    mpuHREF,
    reservationName,
    reservationHREF,
    reservationShortID,
}: Pick<
    Props,
    'lastUploadTime' | 'mpuName' | 'mpuHREF' | 'reservationName' | 'reservationHREF' | 'reservationShortID'
>) => {
    const { t, format } = useI18n();

    return (
        <Box>
            <Title href={mpuHREF ?? ''} text={t('active_alerts_preview.mpu_offline_title')} />

            <ReservationRow href={reservationHREF} name={reservationName} shortID={reservationShortID} />

            <Box>
                {t('active_alerts_preview.mpu')}{' '}
                <Link color="inherit" to={mpuHREF}>
                    {mpuName}
                </Link>
            </Box>

            <Box>
                {t('active_alerts_preview.last_seen')} {format.dateTime(lastUploadTime)}
            </Box>
        </Box>
    );
};

const MPUCriticalFault = ({
    faultName,
    mpuName,
    mpuHREF,
    reservationName,
    reservationHREF,
    reservationShortID,
}: Pick<
    Props,
    'faultName' | 'mpuName' | 'mpuHREF' | 'reservationName' | 'reservationHREF' | 'reservationShortID'
>): ReactElement => {
    const { t } = useI18n();

    return (
        <Box>
            <Title href={mpuHREF ?? ''} text={t('active_alerts_preview.mpu_critical_fault_title')} />

            <ReservationRow href={reservationHREF} name={reservationName} shortID={reservationShortID} />

            <Box>
                {t('active_alerts_preview.mpu')}{' '}
                <Link color="inherit" to={mpuHREF}>
                    {mpuName}
                </Link>
            </Box>

            <Box>
                {t('active_alerts_preview.fault')} {faultName}
            </Box>
        </Box>
    );
};

const MPULowSOC = ({
    socThreshold,
    mpuName,
    mpuHREF,
    reservationName,
    reservationHREF,
    reservationShortID,
}: Pick<
    Props,
    'socThreshold' | 'mpuName' | 'mpuHREF' | 'reservationName' | 'reservationHREF' | 'reservationShortID'
>): ReactElement => {
    const { t } = useI18n();

    return (
        <Box>
            <Title
                href={formatURLState({ path: reservationHREF, hash: 'schedule' })}
                text={t('active_alerts_preview.mpu_low_soc_title', { threshold: `${socThreshold}%`, mpuName })}
            />

            <ReservationRow href={reservationHREF} name={reservationName} shortID={reservationShortID} />

            <Box>
                {t('active_alerts_preview.mpu')}{' '}
                <Link color="inherit" to={mpuHREF}>
                    {mpuName}
                </Link>
            </Box>
        </Box>
    );
};

const ReservationAllocation = ({
    reservationName,
    reservationHREF,
    reservationShortID,
}: Pick<Props, 'reservationName' | 'reservationHREF' | 'reservationShortID'>): ReactElement => {
    const { t } = useI18n();
    const reservationScheduleHREF = formatURLState({ path: reservationHREF, hash: 'schedule' });

    return (
        <Box>
            <Title href={reservationScheduleHREF} text={t('active_alerts_preview.reservation_item_allocation_title')} />

            <Box>
                {t('active_alerts_preview.allocation_guidance', {
                    reservation: (
                        <ReservationLink
                            key="res-link"
                            href={reservationScheduleHREF}
                            shortID={reservationShortID}
                            name={reservationName}
                        />
                    ),
                })}
            </Box>
        </Box>
    );
};

const ReservationStatusMismatch = ({
    reservationHREF,
    expectedStatus,
}: Pick<Props, 'reservationHREF' | 'expectedStatus'>): ReactElement => {
    const { t } = useI18n();

    return (
        <Text
            as="div"
            variant="inherit"
            renderLink={({ text }) => (
                <Link color="inherit" to={reservationHREF}>
                    {text}
                </Link>
            )}
        >
            {t(`active_alerts_preview.expected_status_${expectedStatus?.toLowerCase()}_note`)}
        </Text>
    );
};

/**
 * Responsible for formatting an Alert for display in a tooltip
 */
export default function AlertTooltipContent({
    type,
    socThreshold,
    expectedStatus,
    lastUploadTime,
    faultName,
    mpuName,
    mpuHREF,
    reservationName,
    reservationHREF,
    reservationShortID,
}: Props): ReactElement {
    switch (type) {
        case AlertType.MpuOffline:
            return (
                <MPUOffline
                    lastUploadTime={lastUploadTime}
                    mpuName={mpuName}
                    mpuHREF={mpuHREF}
                    reservationName={reservationName}
                    reservationHREF={reservationHREF}
                    reservationShortID={reservationShortID}
                />
            );

        case AlertType.MpuLowSoc:
            return (
                <MPULowSOC
                    socThreshold={socThreshold}
                    mpuName={mpuName}
                    mpuHREF={mpuHREF}
                    reservationName={reservationName}
                    reservationHREF={reservationHREF}
                    reservationShortID={reservationShortID}
                />
            );

        case AlertType.MpuCriticalFault:
            return (
                <MPUCriticalFault
                    faultName={faultName}
                    mpuName={mpuName}
                    mpuHREF={mpuHREF}
                    reservationName={reservationName}
                    reservationHREF={reservationHREF}
                    reservationShortID={reservationShortID}
                />
            );

        case AlertType.ReservationAllocation:
        /** Falls Through */
        case AlertType.ReservationItemAllocation:
            return (
                <ReservationAllocation
                    reservationName={reservationName}
                    reservationHREF={reservationHREF}
                    reservationShortID={reservationShortID}
                />
            );

        case AlertType.ReservationStatusMismatch:
            return <ReservationStatusMismatch expectedStatus={expectedStatus} reservationHREF={reservationHREF} />;

        default:
            return <></>;
    }
}
