import { ComponentProps, ReactElement } from 'react';

import MenuItem from '@mui/material/MenuItem';

import { useI18n } from 'i18n';

import Input from '../../primitives/interactive/Input';
import useServiceAreas from './useServiceAreas';

type ServiceArea = {
    name: string;
    id: string;
};

type InputProps = Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'onChange'>;

interface Props extends InputProps {
    /**
     * The unique ID, referencing which account to grab service areas for
     * accountID is required but is marked as optional incase the accountID is not known at mount time
     */
    accountID?: string;

    /**
     * Value represents a selected serviceAreaID for a given account or "unassigned" if no service area is set
     */
    value?: string;

    /**
     * Event handler called when the value changes
     * A ServiceArea with an id of "unassigned" is a special value to indicate a service area should not be set
     */
    onChange?: (x: { name?: string; value: ServiceArea | undefined | null }) => void;
}

/**
 * Responsible for surfacing all service areas for a given account to select from
 */
export default function ServiceAreaSelector({
    accountID,
    name,
    value,
    onChange,
    required = true,
    readOnly,
    disabled,
    ...props
}: Props): ReactElement {
    const { t } = useI18n();

    const { loading, serviceAreas, serviceAreaByID } = useServiceAreas({ accountID, t });

    return (
        <Input
            {...props}
            readOnly={readOnly}
            required={required}
            disabled={!readOnly && (!!disabled || !accountID || loading)}
            select={!readOnly}
            name={name}
            value={readOnly ? serviceAreaByID[value ?? '']?.name : value ?? ''}
            onChange={x => {
                const serviceAreaID = x.target.value;

                if (!readOnly && onChange) {
                    onChange({ name, value: serviceAreaByID[serviceAreaID] });
                }
            }}
        >
            {serviceAreas?.map(serviceArea => (
                <MenuItem value={serviceArea.id || ''} key={serviceArea.id}>
                    {serviceArea.name}
                </MenuItem>
            ))}
        </Input>
    );
}
