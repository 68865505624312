import { ComponentProps, ReactElement } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import { useI18n } from 'i18n';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {}

// TODO(Derek): pull AlertSeverity from GQL enum
const ALERT_SEVERITIES = ['CRITICAL', 'MAJOR'];

/**
 * Provides ability to select multiple alert severities, likely for filtering
 */
export default function MultiAlertSeveritySelector({ value, onChange, ...props }: Props): ReactElement {
    const { t } = useI18n();

    return (
        <MultiSelect
            {...props}
            disabled={!props?.readOnly && !!props?.disabled}
            value={value}
            options={ALERT_SEVERITIES.map(x => ({ id: x, label: t(`alerts.severity.${x?.toLowerCase()}.name`) }))}
            onChange={onChange}
        />
    );
}
