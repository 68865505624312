import BillOfLadingInternal from './BillOfLadingInternal';

/*
This will eventually handle logic around which form to render based on the document type
but since we only have one document type for now, we'll just render the BillOfLadingInternal form
*/

const queryKey = 'form';

export const createUrlWithSerializedFormParam = (path, values) => {
    // Serialize to query param string
    const base64encodedFormData = btoa(JSON.stringify(values));
    const queryParams = new URLSearchParams();
    queryParams.set(queryKey, base64encodedFormData);

    return `${path}?${queryParams.toString()}`;
};

export const getFormDataFromSearchParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const base64encodedFormData = queryParams.get(queryKey);

    if (!base64encodedFormData) {
        return null;
    }

    try {
        const jsonString = atob(base64encodedFormData);
        return JSON.parse(jsonString);
    } catch (error) {
        // console.error('Error decoding and parsing form data:', error);
        return null;
    }
};

interface DocumentFormProps {
    onSubmit: (values: any) => void;
    type: string;
    formId?: string;
}

const DocumentForm: React.FC<DocumentFormProps> = ({ onSubmit, type, ...otherProps }) => {
    switch (type) {
        case 'bill-of-lading-internal':
            return <BillOfLadingInternal onSubmit={onSubmit} {...otherProps} />;
        // case 'bill-of-lading-external':
        //     return <BillOfLadingExternal onSubmit={onSubmit} />;
        // case 'invoice':
        //     return <Invoice onSubmit={onSubmit} />;
        // case 'packing-list':
        //     return <PackingList onSubmit={onSubmit} />;
        // case 'shipping-label':
        //     return <ShippingLabel onSubmit={onSubmit} />;
        default:
            return <BillOfLadingInternal onSubmit={onSubmit} {...otherProps} />;
    }
};

export default DocumentForm;
