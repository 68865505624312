import { Children, cloneElement, isValidElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import { normalizeSxProp, SxPropMixin } from 'app/components/normalizeSxProp';
import {
    centerGroupedInputStyle,
    leftGroupedInputStyle,
    rightGroupedInputStyle,
} from 'app/components/primitives/style-helpers';

enum FieldGroupSegmentVariant {
    Left = 'left',
    Right = 'right',
    Center = 'center',
}

interface FieldGroupSegmentTypeBProps extends SxPropMixin {
    children?: ReactNode;
    fullWidth?: boolean;
    variant?: `${FieldGroupSegmentVariant}`;
    disableGroupInputStyle?: boolean;
}

const layeringFixStyle = {
    ':hover': { zIndex: 1 },
    ':focus-within,.Mui-focused,.Mui-error': { zIndex: 1 },
} as const;

const groupedInputStyleByVariant = {
    [FieldGroupSegmentVariant.Left]: leftGroupedInputStyle,
    [FieldGroupSegmentVariant.Center]: centerGroupedInputStyle,
    [FieldGroupSegmentVariant.Right]: rightGroupedInputStyle,
} as const satisfies Record<FieldGroupSegmentVariant, Record<string, any>>;

/**
 * @privateRemarks
 *
 * The markup for this component was pulled out of the `BusinessDateTimeField` component,
 * and made reusable for other compound components.
 */
export function FieldGroupSegment({ children, fullWidth, sx, variant }: FieldGroupSegmentTypeBProps) {
    function renderChildren() {
        const childrenArr = Children.toArray(children);
        const [field] = childrenArr;

        if (childrenArr.length > 1 || !isValidElement(field)) {
            return children;
        }

        const groupedInputStyle = variant ? groupedInputStyleByVariant[variant] : {};
        const nextProps: SxPropMixin = {
            sx: [groupedInputStyle, ...normalizeSxProp(field.props.sx)],
        };

        return cloneElement(field, nextProps);
    }

    const shouldCollapseLeftBorder =
        variant === FieldGroupSegmentVariant.Right || variant === FieldGroupSegmentVariant.Center;
    const shouldFixHoverLayering =
        variant === FieldGroupSegmentVariant.Left || variant === FieldGroupSegmentVariant.Center;

    const wrapperSx = [
        {
            display: 'inline-flex',
            width: fullWidth ? '100%' : null,
            ml: shouldCollapseLeftBorder ? '-1px' : undefined,
            ...(shouldFixHoverLayering ? layeringFixStyle : {}),
        },
        ...normalizeSxProp(sx),
    ];

    return <Box sx={wrapperSx}>{renderChildren()}</Box>;
}

FieldGroupSegment.Variant = FieldGroupSegmentVariant;
