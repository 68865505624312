import { CSSProperties } from 'react';

import { responsiveFontSizes } from '@mui/material/styles';

import { FONT_FAMILY, FONT_STYLES, FONT_WEIGHT } from 'design-system/typography';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body: CSSProperties;
        bodyMedium: CSSProperties;

        detail: CSSProperties;
        detailMedium: CSSProperties;

        data: CSSProperties;
        dataSmall: CSSProperties;
        dataLabel: CSSProperties;

        // disabled variants
        // These variants will still exist on MUI's theme
        // object and there doesn't appear to be a way to remove
        // them. The variants found below should not be used.

        // h6: false;
        // body1: false;
        // body2: false;
        // caption: false;
        // subtitle1: false;
        // subtitle2: false;
        // overline: false;
        // button: false;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body: true;
        bodyMedium: true;

        detail: true;
        detailMedium: true;

        data: true;
        dataSmall: true;
        dataLabel: true;

        // disabled variants
        h6: false;
        body1: false;
        body2: false;
        caption: false;
        subtitle1: false;
        subtitle2: false;
        overline: false;
        button: false;
    }
}

/**
 * I'm not sure why typescript constrains the values to string only when numbers are allowed
 * https://microsoft.github.io/PowerBI-JavaScript/interfaces/_node_modules_typedoc_node_modules_typescript_lib_lib_dom_d_.cssstyledeclaration.html
 */
interface CorrectedCSSStyleDeclaration extends Omit<Partial<CSSStyleDeclaration>, 'lineHeight' | 'fontWeight'> {
    lineHeight?: number | string;
    fontWeight?: number | string;
}

function addFontFamily(fontStyle: CorrectedCSSStyleDeclaration): CorrectedCSSStyleDeclaration {
    return {
        ...fontStyle,
        fontFamily: FONT_FAMILY.join(','),
    };
}

export const makeTypographyForMUI = () => {
    return {
        fontFamily: FONT_FAMILY.join(','),

        // Font Weights

        fontWeightRegular: FONT_WEIGHT.REGULAR,
        fontWeightMedium: FONT_WEIGHT.MEDIUM,
        fontWeightBold: FONT_WEIGHT.BOLD,

        // disabled font weights
        fontWeightLight: undefined,

        // Type Scale
        // The fontSizes defined below apply at the largest breakpoint
        // The MUI utility responsiveFontSizes will produce the
        // responsive adjustments for each variant

        h1: FONT_STYLES.h1,
        h2: FONT_STYLES.h2,
        h3: FONT_STYLES.h3,
        h4: FONT_STYLES.h4,
        h5: FONT_STYLES.h5,

        // Any custom type variants (those outside the default MUI theme) will not have fontFamily defined
        // which is a little surprising
        body: addFontFamily(FONT_STYLES.body),
        bodyMedium: addFontFamily(FONT_STYLES.bodyMedium),

        detail: addFontFamily(FONT_STYLES.detail),
        detailMedium: addFontFamily(FONT_STYLES.detailMedium),

        data: addFontFamily(FONT_STYLES.data),
        dataSmall: addFontFamily(FONT_STYLES.dataSmall),
        dataLabel: addFontFamily(FONT_STYLES.dataLabel),

        // Keeping the following styles around as I'm not sure if MUI relies on them internally
        body1: FONT_STYLES.body,
        body2: FONT_STYLES.detail,

        // disabled MUI default font styles
        // Despite what the docs say setting these to undefined causes an exception
        // h6: undefined,
        // caption: undefined,
        // subtitle1: undefined,
        // subtitle2: undefined,
        // overline: undefined,
        // button: undefined
    };
};

export { responsiveFontSizes };
