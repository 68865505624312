import { ComponentProps, useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import { alpha } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import { MultiGridRenderStats } from 'app/components/compounds/MultiGrid';
import {
    filterEventForOneRow,
    makeCellAvailabilityForOneRow,
    Schedule,
    ScheduleFilterToolbar,
    ScheduleTimeRange,
    useScheduleDaysFromTimeSpan,
} from 'app/components/compounds/Schedule';
import { ISO8601 } from 'app/core/types';

import { DeviceInstanceWithSchedules, MpuScheduleEvent, useMpuTooltipContentRenderer } from '../MpuSchedule';
import { EXPANDED_ROW_HEIGHT, HEADING_HEIGHT, ROW_HEIGHT } from './constants';
import {
    deviceInstancesToUnitAssignmentScheduleEvents,
    isEventFromAllocation,
    makeUnitAssignmentScheduleAvailabilityLayer,
    reservationItemToUnitAssignmentScheduleEvents,
} from './transformers';
import { UnitAssignmentItemData } from './types';
import { UnitAssignmentAsideCell } from './UnitAssignmentAsideCell';
import { UnitAssignmentHeadingCell } from './UnitAssignmentHeadingCell';
import { UnitAssignmentToolbarLeft } from './UnitAssignmentToolbarLeft';

interface UnitAssignmentScheduleProps {
    /**
     * Device instances to render to potential assignment.
     */
    deviceInstances: DeviceInstanceWithSchedules[];
    /**
     * The end of the range of time to be allocated.
     */
    end: ISO8601;
    /**
     * Total height of the component in pixels.
     */
    height: number;
    /**
     * When `true`, the component renders a loading overlay.
     */
    loading?: boolean;
    /**
     * A callback to be invoked when the assignment is cancelled.
     */
    onCancel?: () => void;
    /**
     * A function called with a device instance is to be assigned to an allocation.
     */
    onDeviceAssignment?: (deviceInstance: DeviceInstanceWithSchedules) => void;
    /**
     * A function called whenever the body grid renders a different set of cells.
     */
    onItemsRendered?: (renderStats: MultiGridRenderStats) => void;
    /**
     * Callback handler called when the search input value changes.
     */
    onSearchChange?: (searchValue: string) => void;
    /**
     * The start of the range of time to be allocated.
     */
    start: ISO8601;
    /**
     * Total width of the component in pixels.
     */
    width: number;
}

/**
 * A widget that wraps the `Schedule` component to display a schedule
 * for the assignment of MPUs to an allocation for a unit.
 */
export function UnitAssignmentSchedulePresenter({
    deviceInstances,
    end,
    height,
    loading,
    onCancel,
    onDeviceAssignment,
    onSearchChange,
    onItemsRendered,
    start,
    width,
}: UnitAssignmentScheduleProps) {
    const theme = useTheme();
    const onAssignButtonClick = useCallback(
        (rowIndex: number) => {
            const deviceInstance = deviceInstances.at(rowIndex);

            if (!deviceInstance) {
                // This shouldn't happen
                throw new Error('Invalid row index.');
            }

            onDeviceAssignment?.(deviceInstance);
        },
        [deviceInstances, onDeviceAssignment],
    );
    const itemData = useMemo(
        (): UnitAssignmentItemData => ({
            deviceInstances,
            onAssignButtonClick,
        }),
        [deviceInstances, onAssignButtonClick],
    );
    const rowCount = deviceInstances.length;
    const events = useMemo(
        () => [
            ...reservationItemToUnitAssignmentScheduleEvents({
                end,
                start,
                rowCount,
                theme,
            }),
            ...deviceInstancesToUnitAssignmentScheduleEvents({
                deviceInstances,
                theme,
            }),
        ],
        [deviceInstances, end, rowCount, start, theme],
    );

    const getCellAvailability = useMemo(() => {
        return makeCellAvailabilityForOneRow({
            filterEvent: params => filterEventForOneRow(params) && !isEventFromAllocation(params.event),
            makeLayer: layer => makeUnitAssignmentScheduleAvailabilityLayer(theme, layer),
        });
    }, [theme]);

    const swapTimeEnd = DateTime.fromISO(end).plus({ hours: 24 }).toISO() ?? '';
    const { daysDisplayed, futureLength, initialDate } = useScheduleDaysFromTimeSpan({
        maxDaysDisplayed: 20,
        minDaysDisplayed: 7,
        paddingDays: 2,
        end: swapTimeEnd,
        start,
    });

    const timeRanges = useMemo(
        (): ScheduleTimeRange[] => [
            {
                color: alpha(theme.palette.background.secondaryDark.main, 0.5)!,
                end: swapTimeEnd,
                start,
            },
        ],
        [start, swapTimeEnd, theme],
    );

    const renderTooltipContent = useMpuTooltipContentRenderer();

    type ToolbarLeftProps = Partial<ComponentProps<typeof UnitAssignmentToolbarLeft>>;
    type ToolbarRightProps = Partial<ComponentProps<typeof ScheduleFilterToolbar>>;

    return (
        <Schedule<UnitAssignmentItemData, MpuScheduleEvent>
            asideGridSize={3}
            collapsedRowHeight={ROW_HEIGHT.asValue('px')}
            daysDisplayed={daysDisplayed}
            events={events}
            expandedRowHeight={EXPANDED_ROW_HEIGHT.asValue('px')}
            futureLength={futureLength}
            getCellAvailability={getCellAvailability}
            headingHeight={HEADING_HEIGHT.asValue('px')}
            height={height}
            historyLength={0}
            initialDate={initialDate}
            itemData={itemData}
            loading={loading}
            onItemsRendered={onItemsRendered}
            renderTooltipContent={renderTooltipContent}
            rowCount={rowCount}
            slots={{
                asideCell: UnitAssignmentAsideCell,
                headingCell: UnitAssignmentHeadingCell,
                toolbarLeft: UnitAssignmentToolbarLeft,
                toolbarRight: ScheduleFilterToolbar,
            }}
            slotProps={{
                toolbarLeft: {
                    onCancel,
                } satisfies ToolbarLeftProps,
                toolbarRight: {
                    onSearchChange,
                } satisfies ToolbarRightProps,
            }}
            timeRanges={timeRanges}
            width={width}
        />
    );
}
