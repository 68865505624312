import { ReactElement } from 'react';
import styled from 'styled-components';

import useTheme from '@mui/material/styles/useTheme';

/**
 * Simple MUI themed textarea. Used to collect multline text.
 * Note: for some reason the TextareaAutosize component is not themed
 * nor makes it easy to theme. It appears to be a simple wrapper around <textarea />
 * @deprecated Use Input instead with prop "multiline", "minRows", "maxRows" and "rows"
 */
export default function Textarea({ fullWidth, ...props }: any): ReactElement {
    const theme = useTheme();

    return <StyledTextarea $theme={theme} $fullWidth={fullWidth} {...props} />;
}

const resolveWidth = ({ $fullWidth }: any) => ($fullWidth ? '100%' : null);

const StyledTextarea = styled.textarea.attrs<{ $theme: any; $fullWidth: boolean }>(props => ({
    $theme: props.$theme,
    $fullWidth: props.$fullWidth,
}))<{ $theme: any }>`
    font-family: ${({ $theme }) => $theme?.typography?.body?.fontFamily};
    font-size: ${({ $theme }) => $theme?.typography?.body?.fontSize};
    font-weight: ${({ $theme }) => $theme?.typography?.body?.fontWeight};
    letter-spacing: ${({ $theme }) => $theme?.typography?.body?.letterSpacing};
    line-height: ${({ $theme }) => $theme?.typography?.body?.lineHeight};

    background-color: transparent;

    width: ${resolveWidth};

    border-radius: ${({ $theme }) => `${$theme?.shape?.borderRadius}px`};
    // TODO(derek): add to themes palette. For some reason this color isn't in the palette but is the resultant color on
    // the TextField controls underline (steady state)
    border-color: ${({ $theme }) => $theme?.palette?.divider};
    box-shadow: ${({ $theme }) => $theme?.customShadows?.subtle};
    padding: ${({ $theme }) => $theme?.spacing(1)};
    min-height: 100px;
    max-height: 200px;

    &::placeholder {
        color: ${({ $theme }) => $theme?.palette?.text.secondary};
    }

    &:hover {
        // TODO(derek): add to themes palette. For some reason this color isn't in the palette but is the resultant color on
        // the TextField controls underline (hover state)
        outline: 1px solid rgba(0, 0, 0, 0.87);
        outline-offset: -1px;
    }

    &:focus-visible {
        outline: 2px solid ${({ $theme }) => $theme?.palette?.primary?.main};
        outline-offset: -2px;
        box-shadow: ${({ $theme }) => $theme?.customShadows?.focusHighlight};
    }
`;
