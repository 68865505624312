import { GenericObject, IEventEmitter } from './types';

export interface EventEmitterEvent<T = GenericObject> {
    type: string;
    data?: T;
}

export type EventEmitterCallback<T = any> = (event: EventEmitterEvent<T>) => void;

/**
 * Primitive for enabling event based collaboration
 */
export default class EventEmitter implements IEventEmitter {
    private callbacks: Record<string, ((event: EventEmitterEvent) => void)[]>;

    constructor() {
        this.callbacks = {};
    }

    /**
     * Notifies event listeners of a specific event with optional contextual data
     */
    emit(eventName: string, payload?: GenericObject): void {
        const callbacks = this.callbacks[eventName];

        if (callbacks) {
            callbacks.forEach(cb => {
                cb({
                    type: eventName,
                    data: payload,
                });
            });
        }
    }

    /**
     * Remove event all event listeners for a type or all at once
     */
    off(eventName?: string): void {
        if (!eventName) {
            this.callbacks = {};
        } else {
            this.callbacks[eventName] = [];
        }
    }

    /**
     * Registers an event listener to be called back when the event is raised via emit.
     * @returns {Function} function to remove / unregister listener
     */
    on(eventName: string, callback: EventEmitterCallback): () => void {
        const callbacks = this.callbacks[eventName] ?? [];

        this.callbacks[eventName] = callbacks.concat(callback);

        return () => {
            this.callbacks[eventName] = this.callbacks[eventName]?.filter(cb => cb !== callback);
        };
    }
}
