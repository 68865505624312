import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiTabs from '@mui/material/Tabs';

export default function Tabs(props: ComponentProps<typeof MuiTabs>): ReactElement {
    return (
        <Box>
            <MuiTabs {...props} />

            <Divider sx={{ mt: '-2px', borderBottomWidth: '2px' }} />
        </Box>
    );
}
