import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { number, object, string } from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { ServiceAreaSelector } from 'app/components';
import { BusinessDateTimeRangePicker, Input, Link, Text } from 'app/components/primitives';
import getPathByName from 'app/core/Navigation/getPathByName';

import { ReservationStatus, ReservationType } from 'generated/graphql';

import { useI18n } from 'i18n';

import ReservationServiceTypeSelector from '../ReservationServiceTypeSelector';

const SPACING = 5;

export interface UpdateReservationInput {
    reservationID: string;
    name: string;
    serviceType: ReservationType;
    startDate: DateTime;
    startHour: number;
    startTimeZone: string;
    endDate: DateTime;
    endHour: number;
    endTimeZone: string;
    status: ReservationStatus;
    userNotes: string | null;
    fulfillerNotes: string | undefined;
    serviceAreaID: string | undefined;
}

interface EditReservationFormProps {
    reservationID: string;
    serviceType: ReservationType;
    name?: string;
    unitCount: number;
    serviceAreaID?: string;
    status: ReservationStatus;
    userNotes: string;
    fulfillerNotes?: string;
    startDate: DateTime;
    endDate: DateTime;
    accountID: string;
    shouldDisableDurationEditing: boolean;
    onFormStateChange?: (event: { shouldDisableCTA: boolean }) => void;
    onSubmit: (input: UpdateReservationInput) => void;
    formID: string;
}

export default function EditReservationForm({
    reservationID,
    serviceType,
    name = '',
    startDate,
    endDate,
    status,
    userNotes,
    fulfillerNotes = '',
    unitCount,
    serviceAreaID,
    accountID,
    shouldDisableDurationEditing = false,
    onFormStateChange,
    onSubmit,
    formID,
}: EditReservationFormProps) {
    const [candidateDate, setCandidateDate] = useState<DateTime | null | undefined>(startDate ?? null);
    const { t } = useI18n();

    const form = useFormik<UpdateReservationInput>({
        enableReinitialize: true,

        initialValues: {
            reservationID,
            serviceType,
            name,
            startDate,
            startHour: startDate.get('hour'),
            startTimeZone: startDate.zoneName ?? '',
            endDate,
            endHour: endDate.get('hour'),
            endTimeZone: endDate.zoneName ?? '',
            status,
            userNotes,
            fulfillerNotes,
            serviceAreaID,
        },

        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: object().shape({
            name: string().optional(),
            serviceType: string().optional(),
            startDate: object().required(),
            startHour: number().required(),
            startTimeZone: string().required(),
            endDate: object().required(),
            endHour: number().required(),
            endTimeZone: string().required(),
            status: string().required(),
            fulfillerNotes: string().nullable(),
            serviceAreaName: string().nullable(),
        }),

        onSubmit,
    });

    const { isValid, dirty, values } = form;

    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({ shouldDisableCTA });
    }, [onFormStateChange, shouldDisableCTA]);

    return (
        <Stack component="form" spacing={SPACING} onReset={form.handleReset} onSubmit={form.handleSubmit} id={formID}>
            <ReservationServiceTypeSelector
                fieldName={t('admin_reservation_page.label.service_type')}
                name="serviceType"
                value={values.serviceType}
                onChange={form.handleChange}
                fullWidth
            />

            <Input
                fieldName={t('admin_reservation_page.label.name')}
                name="name"
                value={values.name}
                onChange={form.handleChange}
                fullWidth
                required
            />

            <Input fieldName={t('admin_reservation_page.label.id')} value={values.reservationID} fullWidth disabled />

            <Grid container>
                <Grid item xs={8}>
                    <Input
                        readOnly
                        fieldName={t('admin_reservation_page.label.unit_count')}
                        name="unitCount"
                        value={unitCount}
                        onBlur={form.handleBlur}
                    />

                    <Box mt={4}>
                        <Text
                            renderLink={({ text }) => (
                                <Link
                                    to={getPathByName('RENTAL_MGMT_UPDATE_UNIT_COUNT', {
                                        params: { reservationID },
                                    })}
                                >
                                    {text}
                                </Link>
                            )}
                        >
                            {t('admin_reservation_page.caption.unit_count')}
                        </Text>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <ServiceAreaSelector
                        accountID={accountID}
                        value={values.serviceAreaID}
                        fieldName={t('admin_reservation_page.label.service_area')}
                        onChange={e => {
                            form.setFieldValue('serviceAreaID', e.value?.id);
                        }}
                        required={false}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <BusinessDateTimeRangePicker
                disabled={shouldDisableDurationEditing}
                name="rentalDuration"
                startFieldName={t('admin_reservation_page.label.start_date')}
                endFieldName={t('admin_reservation_page.label.end_date')}
                candidate={candidateDate}
                start={values.startDate}
                startHour={values.startHour}
                startTimeZone={values.startTimeZone}
                end={values.endDate}
                endHour={values.endHour}
                endTimeZone={values.endTimeZone}
                minDate={null}
                disablePast={false}
                onChange={({ value }) => {
                    form.setFieldValue('startDate', value.start);
                    form.setFieldValue('startHour', value.startHour);
                    form.setFieldValue('startTimeZone', value.startTimeZone);

                    form.setFieldValue('endDate', value.end);
                    form.setFieldValue('endHour', value.endHour);
                    form.setFieldValue('endTimeZone', value.endTimeZone);

                    setCandidateDate(value.candidate);
                }}
            />

            <Input
                fieldName={t('admin_reservation_page.label.notes')}
                value={values.userNotes}
                fullWidth
                multiline
                disabled
            />

            <Input
                fieldName={t('admin_reservation_page.label.fulfiller_notes')}
                name="fulfillerNotes"
                value={values.fulfillerNotes}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
            />
        </Stack>
    );
}
