import { ReactElement } from 'react';

import { Tab, Tabs } from 'app/components';

import { FilterMode } from 'generated/graphql';

import { useI18n } from 'i18n';

import LabelWithCount from './LabelWithCount';

interface Props {
    requestedCount?: number;
    confirmedCount?: number;
    startedCount?: number;

    filterMode: FilterMode;

    onFilter: (filterMode: FilterMode) => void;
}

export function ReservationTabs({
    requestedCount,
    confirmedCount,
    startedCount,

    onFilter,
    filterMode,
}: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Tabs
            value={filterMode}
            onChange={(_, newFilterMode) => {
                onFilter(newFilterMode);
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label={t('admin_reservation_index_page.screen_reader.tabs')}
        >
            <Tab
                value={FilterMode.Requested}
                label={
                    <LabelWithCount label={t('admin_reservation_index_page.tab.requested')} count={requestedCount} />
                }
            />
            <Tab
                value={FilterMode.Confirmed}
                label={
                    <LabelWithCount label={t('admin_reservation_index_page.tab.confirmed')} count={confirmedCount} />
                }
            />
            <Tab
                value={FilterMode.Active}
                label={<LabelWithCount label={t('admin_reservation_index_page.tab.active')} count={startedCount} />}
            />
            <Tab value={FilterMode.Past} label={t('admin_reservation_index_page.tab.past')} />
            <Tab value={FilterMode.All} label={t('admin_reservation_index_page.tab.all')} />
        </Tabs>
    );
}
