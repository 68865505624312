import Box from '@mui/material/Box';

import { normalizeSxProp } from 'app/components/normalizeSxProp';
import { withProps } from 'app/components/withProps';

export const BoxWithoutScrollbars = withProps(Box, ({ sx, ...otherProps }) => ({
    ...otherProps,
    sx: [
        {
            // Edge
            msOverflowStyle: 'none',
            // Firefox
            scrollbarWidth: 'none',
            // Webkit / Blink
            '::-webkit-scrollbar': {
                width: '0',
            },
        },
        ...normalizeSxProp(sx),
    ],
}));
