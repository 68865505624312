import { useEffect } from 'react';

export default function useViewportUnits() {
    useEffect(() => {
        let svh: number | null = null;
        let lvh: number | null = null;

        function updateViewportUnits() {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            const currentVh = window.innerHeight * 0.01;

            svh = Math.min(currentVh, svh || currentVh);
            lvh = Math.max(currentVh, lvh || currentVh);

            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${lvh}px`);
            document.documentElement.style.setProperty('--dvh', `${currentVh}px`);
            document.documentElement.style.setProperty('--svh', `${svh}px`);
        }

        updateViewportUnits();

        // 100vh bug for phones
        window.addEventListener('resize', updateViewportUnits);

        return () => {
            window.removeEventListener('resize', updateViewportUnits);
        };
    }, []);
}
