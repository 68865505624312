import COLOR from './palette';

/**
 * The application color palette. This palette contains all colors used in the app.
 * @link {Figma | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=41%3A6097&t=E7qqiRgx475A6oIO-1}
 */
const LIGHT_THEME_PALETTE = {
    TEXT: {
        PRIMARY: COLOR.PRIMARY_DARKEST,
        SECONDARY: COLOR.GREY_DARKER,
        DISABLED: COLOR.GREY,
        CONTRAST: COLOR.WHITE,
        INFO: COLOR.SECONDARY,
        SUCCESS: COLOR.SUCCESS_DARKER,
        WARNING: COLOR.WARNING_DARKEST,
        DANGER: COLOR.DANGER_DARKER,
    },
    BACKGROUND: {
        // light
        PRIMARY: COLOR.WHITE,

        SECONDARY: COLOR.GREY_LIGHTEST,
        SECONDARY_DARK: COLOR.GREY_LIGHTER,

        DISABLED: COLOR.PRIMARY_LIGHTEST,

        INFO: COLOR.SECONDARY_LIGHTEST,
        INFO_DARK: COLOR.SECONDARY,

        SUCCESS: COLOR.TERTIARY_LIGHTEST,
        SUCCESS_DARK: COLOR.TERTIARY_DARKER,

        WARNING: COLOR.WARNING_LIGHTEST,
        WARNING_DARK: COLOR.WARNING_LIGHTER,

        DANGER: COLOR.DANGER_LIGHTEST,
        DANGER_DARK: COLOR.DANGER_DARKER,

        CONTRAST: COLOR.PRIMARY_DARKEST,
    },
    BORDER: {
        LIGHT: COLOR.GREY_LIGHTER,
        DARK: COLOR.GREY,
        INFO: COLOR.SECONDARY,
        SUCCESS: COLOR.SUCCESS,
        WARNING: COLOR.WARNING,
        DANGER: COLOR.DANGER_DARKER,
    },
    BRAND: {
        ACCENT: COLOR.TERTIARY,
    },
} as const;

export default LIGHT_THEME_PALETTE;
