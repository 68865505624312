import { ReactElement, useId, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useSendAccountInviteTo } from 'app/components';
import { useLayer } from 'app/core/layers';
import { AccountRoleName } from 'app/core/Session/Session';

import { useI18n } from 'i18n';

import InviteUserDialog from './InviteUserDialog';
import { useAccountRoles } from './useAccountRoles';

/**
 * This function determines whether the target account can be selected,
 * and whether roles can be selected for that account.
 */
function evaluateTargetAccountSelection(
    accountID: string | undefined,
    targetAccountRoleNames: string[],
): { canSelectAccount: boolean; canSelectRoles: boolean } {
    let canSelectRoles = true;

    if (targetAccountRoleNames.length > 1) {
        // We only have owners and renters right now, so this check is unnecessary,
        // but the logic will need refinement once we add more roles
        canSelectRoles = targetAccountRoleNames.includes(AccountRoleName.Owner);
    } else if (targetAccountRoleNames.length > 0) {
        // i.e. the account has only one role, so check whether it's a renter
        canSelectRoles = !targetAccountRoleNames.includes(AccountRoleName.Renter);
    }

    return {
        canSelectAccount: !accountID,
        canSelectRoles,
    };
}

/**
 * Responsible for surfacing the dialog as a page which has a associated route / url
 */
export default function InviteUserPage(): ReactElement {
    const params = useParams();

    const [targetAccountID, setTargetAccountID] = useState(params.accountID);

    const { t } = useI18n();
    const { toast } = useLayer();
    const dialogID = useId();

    const { onInviteSent } = useOutletContext<{ onInviteSent?: () => void }>() ?? {};
    const navigate = useNavigate();

    const sendInvite = useSendAccountInviteTo();
    const { accountRoleNames: targetAccountRoleNames } = useAccountRoles({ targetAccountID });

    const { canSelectAccount, canSelectRoles } = evaluateTargetAccountSelection(
        params.accountID,
        targetAccountRoleNames,
    );

    return (
        <InviteUserDialog
            id={dialogID}
            accountID={params.accountID}
            allowAccountSelection={canSelectAccount}
            allowRoleSelection={canSelectRoles}
            open={true}
            onClose={() => {
                navigate(-1);
            }}
            onFormStateChange={({ values }) => setTargetAccountID(values.accountID)}
            onInviteUser={async ({ value }) => {
                try {
                    await sendInvite(value);
                } catch (error) {
                    let message = t('invite_user_page.invite_user_failure.default_message');
                    if (error instanceof ApolloError) {
                        for (const err of error.graphQLErrors) {
                            switch (err.extensions.reason) {
                                case 'AccountNotFound':
                                    message = t('invite_user_page.invite_user_failure.account_not_found_message');
                                    break;
                                case 'EmailAlreadyTaken':
                                    message = t('invite_user_page.invite_user_failure.email_already_taken_message');
                                    break;
                                case 'DomainNotAllowed':
                                    message = t('invite_user_page.invite_user_failure.domain_not_allowed_message');
                                    break;
                                case 'RequestingUserNotFound':
                                    message = t(
                                        'invite_user_page.invite_user_failure.requesting_user_not_found_message',
                                    );
                                    break;
                                case 'UnacceptedInviteAlreadyExists':
                                    message = t(
                                        'invite_user_page.invite_user_failure.unaccepted_invite_already_exists_message',
                                    );
                                    break;
                                default:
                                    // TODO(logan): can we log to DD or similar?
                                    break;
                            }
                        }
                    }
                    toast.add({
                        severity: 'error',
                        message,
                    });
                    throw error;
                }

                onInviteSent?.();
                toast.add({
                    severity: 'success',
                    title: t('invite_user_page.invite_user_success_title'),
                    message: t('invite_user_page.invite_user_success_message', { email: value.email }),
                });
            }}
        />
    );
}
