import { Grid } from '@mui/material';

import Text from 'app/components/primitives/Text';

import { ServiceAccount } from '../types';

interface ServiceAccountDetailsRowProps extends Pick<ServiceAccount, 'id' | 'name' | 'createdAt'> {}

export function ServiceAccountDetailsRow({ id, name, createdAt }: ServiceAccountDetailsRowProps) {
    return (
        <Grid container display="flex" alignItems="center" minHeight={24}>
            <Grid item xs={3}>
                <Text variant="detail" sx={{ fontWeight: { xs: 'bold', md: 'normal' } }}>
                    {name}
                </Text>
            </Grid>

            <Grid item xs={1} lg={3}>
                <Text variant="detail">{id}</Text>
            </Grid>

            <Grid item xs={2} lg={3}>
                <Text variant="detail">{createdAt}</Text>
            </Grid>
        </Grid>
    );
}
