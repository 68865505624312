import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

import Link from '@mui/material/Link';

import { Text } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useSubmitResetPasswordTokenMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

function useResetPasswordMutation() {
    const [submitPasswordResetToken] = useSubmitResetPasswordTokenMutation();

    return async ({ email, password, token }) => {
        const result = await submitPasswordResetToken({
            variables: {
                email,
                newPassword: btoa(password),
                resetToken: token,
            },
        });

        return result.data?.submitResetPasswordToken;
    };
}

export default function useResetPassword() {
    const { t } = useI18n();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const resetPassword = useResetPasswordMutation();
    const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('');

    const email = searchParams.get('email') ?? '';
    const token = searchParams.get('token') ?? '';

    useEffect(() => {
        if (!email || !token) {
            setErrorMessage(
                <Text
                    renderLink={({ text }) => (
                        <Link
                            component={RouterLink}
                            color="error"
                            to={getPathByName('SIGN_IN', { search: { email: email ?? '' } })}
                        >
                            {text}
                        </Link>
                    )}
                >
                    {t('reset_password_page.malformed_reset_password_link')}
                </Text>,
            );
        }
    }, [email, token, t]);

    return {
        run: async ({ values: { password } }) => {
            try {
                await resetPassword({
                    email: searchParams.get('email'),
                    token: searchParams.get('token'),
                    password,
                });
            } catch (error) {
                // TODO(derek): differentiate errors, need API support
                setErrorMessage(t('reset_password_page.reset_password_failed_message'));

                throw error;
            }

            navigate(getPathByName('SIGN_IN'));
        },
        email,
        token,
        errorMessage,
    };
}
