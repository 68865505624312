import { ComponentProps, ReactElement, useMemo } from 'react';
import type * as CSS from 'csstype';

import Box from '@mui/material/Box';
import { SystemStyleObject } from '@mui/system';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import { Theme } from 'styles/theme';

import { EXPANSION_TRANSITION_DURATION, EXPANSION_TRANSITION_EASE } from './constants';
import { ScheduleColumnMarker, ScheduleColumnRange } from './types';

interface ScheduleCellPresenterProps extends ComponentProps<typeof Box> {
    /** Determines whether the cell is rendered as a heading. */
    isHeading?: boolean;
    /** Column markers the cell should render. */
    markers?: ScheduleColumnMarker[];
    /** Column ranges the cell should render. */
    ranges?: ScheduleColumnRange[];
}

/**
 * The small ball at the top of the column marker
 */
function TopOfCellMarker({ markerColor }: { markerColor: CSS.DataType.Color }): ReactElement {
    return (
        <Box
            sx={{
                background: markerColor,
                borderRadius: '999px',
                height: '8px',
                position: 'absolute',
                transform: 'translateX(-4px)',
                width: '8px',
            }}
        />
    );
}

/**
 * An extendable component for rendering cells in the `Schedule` component.
 */
export function ScheduleCellPresenter({
    children,
    isHeading,
    markers,
    ranges,
    sx,
    ...otherProps
}: ScheduleCellPresenterProps) {
    const background = useMemo(() => ranges?.map(({ color }) => color).join(', '), [ranges]);
    // TODO(Morris): Support multiple day markers per cell.
    const markerColor = markers?.at(0)?.color;

    function renderCellStyles(): SystemStyleObject<Theme> {
        return {
            background,
            boxShadow: markerColor && `inset 1px 0px 0px 0px ${markerColor}, -1px 0px 0px 0px ${markerColor}`,
            transitionDuration: EXPANSION_TRANSITION_DURATION,
            transitionProperty: 'top, height',
            transitionTimingFunction: EXPANSION_TRANSITION_EASE,
        };
    }

    return (
        <Box sx={[renderCellStyles, ...normalizeSxProp(sx)]} {...otherProps}>
            {isHeading && markerColor && <TopOfCellMarker markerColor={markerColor} />}
            {children}
        </Box>
    );
}
