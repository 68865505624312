import { ComponentProps, ReactElement, useEffect } from 'react';

import MenuItem from '@mui/material/MenuItem';

import Input from 'app/components/primitives/interactive/Input';
import { DeviceModel, useDeviceModels } from 'app/core/DeviceModels';

type Props = Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'onChange' | 'value'>;

interface DeviceModelSelectorContainerProps extends Props {
    /**
     * Value representing the selected modelID for an MPU, The value is optional to allow
     * initially unknown / unselected values.
     */
    value?: string;

    /**
     * Event handler called when the value changes.
     */
    onChange: (x: { name?: string; value: DeviceModel | undefined | null }) => void;
}

/**
 * Responsible for surfacing all active production models to select from
 */
export default function DeviceModelSelector({
    name,
    value,
    onChange,
    required = true,
    disabled,
    readOnly,
    ...props
}: DeviceModelSelectorContainerProps): ReactElement {
    const { loading, defaultDeviceModel, deviceModels, deviceModelsById } = useDeviceModels();

    // We only want this to be run once we know the list of device models but should prefer
    // the value passed in
    useEffect(() => {
        if (loading || value) return;

        onChange({ name, value: defaultDeviceModel });
        // eslint-disable-next-line
    }, [loading]);

    return (
        <Input
            {...props}
            readOnly={readOnly}
            required={required}
            disabled={!readOnly ? !!disabled || loading || (deviceModels?.length ?? 0) < 2 : false}
            select={!readOnly}
            name={name}
            value={readOnly ? deviceModelsById[value ?? '']?.name : value ?? ''}
            onChange={x => {
                const deviceModelId = x.target.value;

                onChange({ name, value: deviceModelsById[deviceModelId] });
            }}
        >
            {deviceModels?.map(deviceModel => (
                <MenuItem value={deviceModel.id || ''} key={deviceModel.id}>
                    {deviceModel.name}
                </MenuItem>
            ))}
        </Input>
    );
}
