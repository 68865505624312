import { ComponentProps, ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';

import { Button, ModalDialog } from 'app/components';

import { useI18n } from 'i18n';

import InviteUserForm from './InviteUserForm';

interface InviteUserInputs {
    accountID: string;
    email: string;
}

interface Props extends Omit<ComponentProps<typeof InviteUserForm>, 'id' | 'onSubmit'> {
    id: string;

    open: boolean;
    onClose: () => void;

    onInviteUser: (event: { value: InviteUserInputs }) => Promise<void>;
}

/**
 * Responsible for presenting the invite user form in a dialog
 */
export default function InviteUserDialog({
    id,
    open = false,
    accountID,
    allowAccountSelection,
    allowRoleSelection,
    onClose,
    onInviteUser,
    onFormStateChange,
}: Props): ReactElement {
    const { t } = useI18n();
    const dialogTitleID = useId();
    const formID = useId();

    const [shouldDisableCTA, setShouldDisableCTA] = useState<boolean | undefined>(false);

    return (
        <ModalDialog
            id={id}
            open={open}
            onClose={onClose}
            title={<Box id={dialogTitleID}>{t('invite_user_page.title')}</Box>}
            primaryCTA={
                <Button form={formID} type="submit" disabled={shouldDisableCTA}>
                    {t('invite_user_page.cta.invite')}
                </Button>
            }
        >
            <InviteUserForm
                id={formID}
                accountID={accountID}
                hideSubmitCTA
                allowAccountSelection={allowAccountSelection}
                allowRoleSelection={allowRoleSelection}
                onFormStateChange={event => {
                    onFormStateChange?.(event);

                    const { shouldDisableCTA } = event;
                    setShouldDisableCTA(shouldDisableCTA);
                }}
                onSubmit={async ({ value }) => {
                    await onInviteUser?.({ value });

                    onClose?.();
                }}
            />
        </ModalDialog>
    );
}
