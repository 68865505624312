import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export default props => (
    <Svg viewBox="0 0 150 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        {/* @ts-ignore */}
        <G clipPath="url(#clip0)">
            <Path
                fill="#9ea3a1"
                d="M49.7869 16.9558C49.7869 18.1109 48.8305 19.0474 47.6506 19.0474H41.2604H34.8705C33.6906 19.0474 32.7341 18.1109 32.7341 16.9558V7.57669C32.7341 6.41881 33.6928 5.48017 34.8755 5.48017H41.2604H47.6456C48.8282 5.48017 49.7869 6.41881 49.7869 7.57669V16.9558ZM52.518 0.378784L27.4133 0.390909C26.1352 0.390909 24.9595 1.11092 24.3481 2.26788L17.6616 14.9236C17.3014 15.6052 16.3522 15.6052 15.9921 14.9236L9.30545 2.26788C8.69417 1.11092 7.51841 0.390909 6.24035 0.390909H0.891288C0.399087 0.390909 0.000100288 0.80221 0.000100288 1.30971L0.00155372 22.792C-0.0289658 23.34 0.394033 23.8017 0.926568 23.8017H5.0431C5.79035 23.8017 6.39715 23.1791 6.40052 22.4088L6.44234 12.9217C6.44428 12.4677 7.02338 12.3025 7.25167 12.6907L13.2537 22.8985C13.5831 23.4592 14.1728 23.8017 14.8087 23.8017H18.8449C19.4808 23.8017 20.0705 23.4592 20.3999 22.8985L26.2252 12.6342C26.3749 12.3704 26.6937 12.3538 26.8846 12.5184V20.1057C26.8846 22.0915 28.5289 23.7012 30.5572 23.7012H52.5264C54.5547 23.7012 56.1988 22.0915 56.1988 20.1057V3.98254C56.1988 1.99224 54.5509 0.378784 52.518 0.378784Z"
            />

            <Path
                d="M117.649 17.103C117.649 18.2685 116.684 19.2133 115.494 19.2133H109.047H102.6C101.41 19.2133 100.445 18.2685 100.445 17.103V7.64035C100.445 6.47212 101.412 5.52519 102.605 5.52519H109.047H115.489C116.682 5.52519 117.649 6.47212 117.649 7.64035V17.103ZM143.211 0.462183H140.553C138.701 0.462183 137.199 2.00992 137.199 3.91915V12.6677C137.199 13.2416 136.528 13.531 136.131 13.1279L125.198 2.00531C124.195 0.985608 122.84 0.419098 121.432 0.430921L98.2571 0.378418C96.2056 0.378418 94.543 2.0062 94.543 4.01433V20.281C94.543 22.2844 96.2023 23.9085 98.2482 23.9085L122.414 23.953C123.537 23.953 124.448 23.0142 124.448 21.8562V11.0954C124.448 10.8786 124.701 10.7685 124.852 10.9198L137.321 23.4532C137.64 23.7736 138.068 23.953 138.513 23.953H141.949C143.019 23.953 143.886 23.0593 143.886 21.9567V1.15744C143.886 0.773391 143.583 0.462183 143.211 0.462183Z"
                fill="#6FFCD1"
            />
            <Path
                fill="#9ea3a1"
                d="M90.9205 10.8738H86.3529C85.8512 10.8738 85.4446 11.2931 85.4446 11.8102V19.4521C85.4446 19.8948 84.9273 20.119 84.6205 19.8092L67.1201 2.13954C66.2217 1.23277 65.0149 0.724976 63.7577 0.724976H59.0068C58.1727 0.724976 57.7601 1.76862 58.3554 2.37019C58.623 2.64012 58.8772 2.89722 59.0777 3.09922C60.6251 4.66177 65.1993 9.28142 67.3134 11.4154C67.8002 11.9067 67.7996 12.71 67.3128 13.2014L58.4092 22.1957C57.7728 22.8382 58.2142 23.9527 59.1048 23.9527C59.1048 23.9527 62.9795 23.9517 64.7668 23.9514C65.3334 23.9512 65.8772 23.7222 66.2821 23.3134L71.9839 17.5564C72.3761 17.1602 73.0041 17.1602 73.3963 17.5564L78.7597 22.9729L79.0804 23.2966C79.4969 23.7172 80.0568 23.9527 80.64 23.9527H86.2516C86.2665 23.9527 86.2804 23.95 86.2948 23.9494C86.3158 23.951 86.3374 23.9527 86.359 23.9527H90.9238C91.4317 23.9527 91.8427 23.5288 91.8427 23.0056V11.8246C91.8427 11.2995 91.43 10.8738 90.9205 10.8738Z"
            />
            <Path
                fill="#6FFCD1"
                d="M90.9243 0.724976H80.0329C79.8036 0.724976 79.5832 0.817661 79.4193 0.983084L76.3194 4.11502C75.9716 4.4665 75.9716 5.04394 76.3199 5.39492L79.3279 8.42564C79.8003 8.90187 80.5552 8.90147 81.0271 8.42484L84.6974 4.7192C84.976 4.43825 85.4451 4.64144 85.4451 5.04313V7.0173C85.4451 7.56878 85.8788 8.01587 86.4138 8.01587H90.8994C91.4206 8.01587 91.8432 7.58032 91.8432 7.04307V1.67213C91.8432 1.14901 91.4316 0.724976 90.9243 0.724976Z"
            />
        </G>
        <Defs>
            <ClipPath id="clip0">
                <Rect width="149.538" height="24" fill="white" />
            </ClipPath>
        </Defs>
    </Svg>
);
