import { ReactElement } from 'react';

import { CellSignalBar } from 'app/core/data';

import { TranslateFunction, useI18n } from 'i18n';

import Metric from './Metric';
import SignalStrength from './SignalStrength';

interface Props {
    /**
     * The representative number of bars for the TGU connection signal strength
     */
    value?: CellSignalBar;
}

function resolveRenderedValue(value: CellSignalBar | undefined, { t }: { t: TranslateFunction }) {
    if (value == null) return t('unknown');

    if (value !== 0) return t('unit_status.tgu_connected');

    return t('unit_status.tgu_not_connected');
}

/**
 * Responsible for representing the TGU connections signal strength
 */
export default function TGUConnection({ value }: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Metric
            variant="card"
            label={t('unit_status.tgu_connection_label')}
            icon={value == null ? null : <SignalStrength numberOfBars={value} />}
            value={resolveRenderedValue(value, { t })}
        />
    );
}
