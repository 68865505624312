import { ReactElement, ReactNode, useCallback } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Icon } from 'app/components/primitives';

import { Tooltip } from '../Tooltip';

/**
 * A compound component that renders a heading and content for the `CopyTooltip`.
 *
 * @example
 *
 * <CopyTooltip
 *     content={
 *         <CopyTooltip.TextContent
 *             heading="Greeting"
 *             description={<Box>Hello, world!</Box>}
 *         />
 *     }
 *     textToCopy="Hello, world!"
 * >
 *     <Text>Hello, world!</Text>
 * </CopyTooltip>
 */
function TextContent({ heading, description }: { heading: ReactNode; description: ReactNode }): ReactElement {
    return (
        <Stack direction="column" spacing={1}>
            <Box>{heading}</Box>
            <Box>{description}</Box>
        </Stack>
    );
}

interface CopyTooltipProps {
    children: ReactElement;
    content: ReactNode;
    textToCopy: string;
}

/**
 * A compound component that renders a tooltip with a copy button.
 *
 * @example
 *
 * <CopyTooltip
 *     content={<Box>Hello, world!</Box>}
 *     textToCopy="Hello, world!"
 * >
 *     <Text>Hello, world!</Text>
 * </CopyTooltip>
 */
export function CopyTooltip({ children, content, textToCopy }: CopyTooltipProps): ReactElement {
    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(textToCopy);
    }, [textToCopy]);

    return (
        <Tooltip
            content={
                <Stack direction="row" spacing={2}>
                    <Box>{content}</Box>
                    <Box display="flex" alignItems="center">
                        <IconButton onClick={handleCopy} sx={{ p: 1 }}>
                            <Icon color="contrast" name="copy" size="sm" />
                        </IconButton>
                    </Box>
                </Stack>
            }
        >
            {children}
        </Tooltip>
    );
}

CopyTooltip.TextContent = TextContent;
