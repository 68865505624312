export enum KnownServiceErrorKind {
    /**
     * When an MPU has a duplicate Asset ID.
     */
    AssetAlreadyExists = 'AssetAlreadyExists',
    /**
     * When an overlapping schedule would be created.
     */
    ScheduleConflict = 'ScheduleConflict',
    /**
     * An error due to a known harmless bug in the delete mutation.
     *
     * TODO(Morris): Remove after bug is fixed on the backend.
     */
    ScheduleDeleteBug = 'ScheduleDeleteBug',
    /**
     * When an MPU has a duplicate Serial Number.
     */
    SerialNumberConflict = 'SerialNumberConflict',
}

/**
 * Known Apollo error messages used to match errors
 */
// prettier-ignore
export const knownApolloErrorMessages = {
    ScheduleConflict: 'conflicting key value violates exclusion constraint "device_instance_schedule_exclusive_interval"',
    ScheduleDeleteBug: 'Cannot return null for non-nullable field DeviceInstanceSchedule.id.',
    SerialNumberConflict: "duplicate key value violates unique constraint \"IDX_device_instance_serial_number\"",
} as const satisfies Partial<Record<KnownServiceErrorKind, string>>
