import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { Button, Cluster, Icon, IconAndText, Tag, Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { MUIMarginProps } from 'styles/theme/types';

import useActiveFaultSummary from './useActiveFaultSummary';

interface Props extends MUIMarginProps {
    /**
     * If passed will scope faults owned by the specific account, else all faults are returned if authorized.
     */
    accountID?: string;

    /**
     * The location of the faults page
     */
    viewAllHref?: string;
}

function resolveTagPropsBySeverity(severity): {
    color: 'normal' | 'danger';
    outlined: boolean;
} {
    if (/INFO/i.test(severity)) {
        return {
            color: 'normal',
            outlined: true,
        };
    }

    return {
        color: 'danger',
        outlined: false,
    };
}

/**
 * Displays a fault summary for all MPUs in a given fleet
 */
export default function ActiveFaultSummary({ accountID, viewAllHref, ...props }: Props): ReactElement {
    const { t } = useI18n();

    const { totalFaults, counts } = useActiveFaultSummary({ accountID });

    return (
        <Box
            component={Paper}
            variant="outlined"
            p={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            {...props}
        >
            <Cluster
                gap={0}
                sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box my={2} ml={1}>
                    <IconAndText
                        icon={<Icon color="danger" name="alert-circle" />}
                        text={
                            <Text variant="h4">
                                {t('active_fault_summary.total_faults', { count: totalFaults ?? '-' })}
                            </Text>
                        }
                    />
                </Box>

                <Cluster gap={5}>
                    {counts.map(x => (
                        <IconAndText
                            key={x.severity}
                            icon={<Tag {...resolveTagPropsBySeverity(x.severity)} label={x.count} />}
                            text={<Text>{t(`active_fault_summary.severity.${x.severity.toLowerCase()}`)}</Text>}
                        />
                    ))}
                </Cluster>

                {/* Counter balance to achieve proper responsive layout */}
                <Box />
            </Cluster>

            <Button ctaType="secondary" to={viewAllHref}>
                {t('active_fault_summary.view_all_cta')}
            </Button>
        </Box>
    );
}
