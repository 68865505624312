import { i18n } from 'i18next';
import { DateTime, Duration, DurationLikeObject, Zone } from 'luxon';

import { PossibleDate } from '../types';

const DEGREE_SYMBOL_UNICODE = '\u00B0';
const NBSP_UNICODE = '\u00A0';

interface BasicValueWithUnit {
    value?: string | number | null | undefined;
    unit: string;
}

interface ValueWithUnit extends BasicValueWithUnit {
    toString: () => string;
}

/**
 * Given a time representation return a luxon DateTime instance
 */
function resolveDateTime(value?: PossibleDate): DateTime {
    // returns an invalid datetime if date is undefined
    if (!value) return DateTime.fromISO('');

    if (value instanceof DateTime) return value;
    if (typeof value === 'string') return DateTime.fromISO(value);
    if (value instanceof Date) return DateTime.fromJSDate(value);

    throw new Error(`resolveDateTime passed unknown date format`);
}

/**
 * DATE AND DATETIME FORMATS
 */

/**
 * Date time format for time series charts
 */
export const timeAxis = ({ language }: Pick<i18n, 'language'>, value: PossibleDate): string => {
    return resolveDateTime(value).setLocale(language).toLocaleString({
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    });
};

/**
 * Date time format for time series charts, tooltips where more space is available to
 * show the year
 */
export const timeTooltip = ({ language }: Pick<i18n, 'language'>, value: PossibleDate): string => {
    return resolveDateTime(value).setLocale(language).toLocaleString({
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        hour12: false,
    });
};

/**
 * Basic time format (i.e. 12:00PM PST)
 */
export const time = ({ language }: Pick<i18n, 'language'>, value: PossibleDate): string => {
    return resolveDateTime(value).setLocale(language).toLocaleString({
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
    });
};

/**
 * Most basic date format, Month / Day / Year with locale specific separators and ordering
 */
export const date = ({ language, t }: Pick<i18n, 'language' | 't'>, value?: PossibleDate): string => {
    const resolvedDate = resolveDateTime(value).setLocale(language);

    if (!resolvedDate.isValid) return t('unknown');

    return resolvedDate.toLocaleString();
};

/**
 * Format the given date with locale specific separators & ordering, and a two digit year.
 */
export const dateCompact = ({ language }: Pick<i18n, 'language'>, value?: PossibleDate): string =>
    resolveDateTime(value).setLocale(language).toLocaleString({
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
    });

/**
 * Format the given date into a two digit day of month.
 */
export const dateDayOfMonth = ({ language }: Pick<i18n, 'language'>, value?: PossibleDate): string =>
    resolveDateTime(value).setLocale(language).toLocaleString({
        day: '2-digit',
    });

export const dateFull = ({ language }: Pick<i18n, 'language'>, value?: PossibleDate): string =>
    resolveDateTime(value).setLocale(language).toLocaleString(DateTime.DATE_FULL);

export const dateDayAndTime = ({ language }: Pick<i18n, 'language'>, value?: PossibleDate): string =>
    resolveDateTime(value).setLocale(language).toLocaleString({ hour: 'numeric', month: 'short', day: 'numeric' });

/**
 * Gets the underlying date format to be used with MUI DatePicker for input masking
 */
export const dateFormat = ({ language }: Pick<i18n, 'language'>): string => {
    return DateTime.now()
        .setLocale(language)
        .toLocaleParts()
        .map(({ type, value }) => {
            if (type === 'day') return 'dd';
            if (type === 'month') return 'MM';
            if (type === 'year') return 'yyyy';
            if (type === 'literal') return value;

            return '';
        })
        .join('');
};

/**
 * @example
 *
 * <DatePicker format={format.dateFormatCompact()} />
 */
export const dateFormatCompact = ({ language }: Pick<i18n, 'language'>): string => {
    return DateTime.now()
        .setLocale(language)
        .toLocaleParts()
        .map(({ type, value }) => {
            if (type === 'day') return 'd';
            if (type === 'month') return 'M';
            if (type === 'year') return 'yy';
            if (type === 'literal') return value;

            return '';
        })
        .join('');
};

/**
 * Presentation of a date range (i.e 1/1/2023) where the month day year position are localized
 */
export const dateRange = (
    { language, t }: Pick<i18n, 'language' | 't'>,
    value: { start?: PossibleDate; end?: PossibleDate },
): string => {
    const start = date({ language, t }, value?.start);
    const end = date({ language, t }, value?.end);

    return `${start} – ${end}`;
};

/**
 * Presentation of a date range with month name (i.e. January 1, 2023 12 PM PDT) with time (hour) and timeZone
 */
export const dateRangeFull = (
    { language }: Pick<i18n, 'language'>,
    value: { start?: PossibleDate; end?: PossibleDate },
): string => {
    const start = resolveDateTime(value?.start);
    const end = resolveDateTime(value?.end);
    const formattedStart = dateFull({ language }, start);
    const formattedEnd = dateFull({ language }, end);

    const startTime = start
        .setLocale(language)
        .toLocaleString({ hour: 'numeric', timeZone: start.zoneName ?? '', timeZoneName: 'short' });
    const endTime = end
        .setLocale(language)
        .toLocaleString({ hour: 'numeric', timeZone: end.zoneName ?? '', timeZoneName: 'short' });

    return `${formattedStart} ${startTime} – ${formattedEnd} ${endTime}`;
};

/**
 * Presentation of a date range with time, short month name, and no year (i.e. Apr 18, 12 PM)
 */
export const dateRangeDayAndTime = (
    { language }: Pick<i18n, 'language'>,
    value: { start?: PossibleDate; end?: PossibleDate },
): string => {
    const start = dateDayAndTime({ language }, value?.start);
    const end = dateDayAndTime({ language }, value?.end);

    return `${start} – ${end}`;
};

/**
 * Presentation of a date range using `time` and `dateCompact` in a sentence fragment.
 *
 * @example
 *
 * "6/17/23 at 8:42 AM PDT to 6/23/23 at 8:42 AM PDT"
 */
export const dateRangeSentence = (
    { language, t }: i18n,
    { end, start }: { end?: PossibleDate; start?: PossibleDate } = {},
): string => {
    if (!end || !start) {
        return t('unknown');
    }

    return t('date_range_sentence', {
        endDate: dateCompact({ language }, end),
        endTime: time({ language }, end),
        startDate: dateCompact({ language }, start),
        startTime: time({ language }, start),
    });
};

interface TimeLeftOptions {
    now?: () => DateTime;
}

/**
 * Formats a date in relative time for all times in the future. This will peg dates in the past to "0 days" in the desired language.
 *
 * NOTE(will): the device may return very, very high values for ETTE if it is not under load; we may
 * want to account for that in 'timeLeft' i.e. if 'emptyAt' is years in the future, we might want to say
 * e.g. 'more than one month'.
 *
 * @param i18n the i18n instance with the users desired language settings
 * @param date the date to format
 * @param options additional formatting options
 * @returns the formatted string
 */
export const timeLeft = (
    { language }: Pick<i18n, 'language'>,
    date?: PossibleDate,
    { now = DateTime.now }: TimeLeftOptions = {},
): string | null => {
    const resolvedDate = resolveDateTime(date);

    if (!resolvedDate.isValid) return '-';

    const hoursFromNow = resolvedDate.diff(now(), 'hours').as('hours');
    const isEmptyInFuture = hoursFromNow > 0;
    const zeroDays = now().setLocale(language).toRelative({ unit: 'days', base: now() });

    return isEmptyInFuture ? resolvedDate.setLocale(language).toRelative({ base: now() }) : zeroDays;
};

/*
 * Time relative to now
 */
export const relativeTime = ({ language, t }: Pick<i18n, 'language' | 't'>, value: PossibleDate): string => {
    const dt = resolveDateTime(value);
    if (!(dt && dt.isValid)) {
        return '-';
    }

    // NOTE(derek): Updating every second is a bit distracting, we can make this configurable
    // if we find we need the granularity of showing seconds
    if ((DateTime.now().toMillis() - dt.toMillis()) / 1000 < 60) {
        return t('a_moment_ago');
    }

    return dt.setLocale(language).toRelative() || '-';
};

/**
 * For our date picker components, returns the first letter of the day's name (i.e. W for Wednesday)
 */
export const shortWeekday = (_: i18n, value: PossibleDate): string => {
    return resolveDateTime(value).toFormat('EEEEE');
};

/**
 * Short date showing only month and day
 */
export const shortDate = ({ language }: Pick<i18n, 'language'>, value: PossibleDate): string => {
    return resolveDateTime(value).setLocale(language).toLocaleString({
        month: 'numeric',
        day: 'numeric',
    });
};

/*
 * Long date time format showing month, day, year, hour, minute and timezone
 */
export const longDateTime = ({ language }: Pick<i18n, 'language'>, value: PossibleDate): string => {
    const dt = resolveDateTime(value);
    if (!(dt && dt.isValid)) {
        return '-';
    }
    return dt.setLocale(language).toLocaleString(DateTime.DATETIME_FULL);
};

/*
 * Date time format showing month, day, year, hour, minute and timezone (i.e. "12/12/31, 2:12 PM PST")
 */
export const dateTime = ({ language }: Pick<i18n, 'language'>, value: PossibleDate): string => {
    const dt = resolveDateTime(value);
    if (!(dt && dt.isValid)) {
        return '-';
    }
    return dt.setLocale(language).toLocaleString({
        ...DateTime.DATETIME_SHORT,
        timeZoneName: 'short',
    });
};

/**
 * Given the hour in a day render the locale friendly presentation.
 */
export const hourWithTimeZone = (
    { language }: Pick<i18n, 'language'>,
    hour: number,
    { timeZone = 'local' } = {},
): string => {
    return DateTime.local({ zone: timeZone })
        .set({ hour })
        .setLocale(language)
        .toLocaleString({ hour: 'numeric', timeZone, timeZoneName: 'short' });
};

// renders the timezone offset relative to UTC at a given time e.g. -07:00, +00:00, etc.
export const timezoneOffset = (
    { language }: Pick<i18n, 'language'>,
    zone: Zone,
    nowFunc: () => luxon.DateTime = DateTime.now,
): string => {
    return zone.formatOffset(nowFunc().toUnixInteger(), 'short');
};

/**
 * NUMBER FORMATS
 */

/**
 * Most basic decimal representation, always rending 1 decimal place.
 */
export const decimal = (
    { languages }: Pick<i18n, 'languages'>,
    value: number,
    options?: Pick<Intl.NumberFormatOptions, 'maximumFractionDigits'>,
): string => {
    return new Intl.NumberFormat(languages as string[], {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        ...options,
    }).format(value);
};

/**
 * Most basic number formatting. Will ensure numbers use the expected separator (i.e. 1,000 comma or 1.000 period)
 * based on locale
 */
export const number = ({ languages }: Pick<i18n, 'languages'>, value: number): string => {
    return new Intl.NumberFormat(languages as string[]).format(value);
};

/**
 * Formats a decimal latitude and longitude for display
 */
export const latLong = (i18nInstance: Pick<i18n, 'languages' | 't'>, value?: { lat?: number; lon?: number } | null) => {
    const { lat, lon } = value ?? {};
    const isValidLatLon =
        typeof lat === 'number' && lat >= -90 && lat <= 90 && typeof lon === 'number' && lon >= -180 && lon <= 180;

    if (!isValidLatLon) return i18nInstance.t('unknown');

    const latValue = decimal(i18nInstance, Math.abs(lat), { maximumFractionDigits: 4 });
    const latDirection = lat < 0 ? 'S' : 'N';

    const lonValue = decimal(i18nInstance, Math.abs(lon), { maximumFractionDigits: 4 });
    const lonDirection = lon < 0 ? 'W' : 'E';

    return `${latValue}${DEGREE_SYMBOL_UNICODE}${NBSP_UNICODE}${latDirection}, ${lonValue}${DEGREE_SYMBOL_UNICODE}${NBSP_UNICODE}${lonDirection}`;
};

/**
 * Adds toString method for simple object representing a value with a unit
 * @param hasSpace determines whether to add space between value and unit
 */
function makeValueWithUnit({ value, unit }: BasicValueWithUnit, hasSpace = true): ValueWithUnit {
    return {
        value,
        unit,
        toString() {
            return `${value}${hasSpace ? NBSP_UNICODE : ''}${unit}`;
        },
    };
}

function getMaxUnitOfDuration(duration: Duration): keyof DurationLikeObject {
    const scaledDuration = duration.rescale();
    let maxUnit: keyof DurationLikeObject = 'seconds';

    if (scaledDuration.years) {
        maxUnit = 'years';
    } else if (scaledDuration.months) {
        maxUnit = 'months';
    } else if (scaledDuration.weeks) {
        maxUnit = 'weeks';
    } else if (scaledDuration.days) {
        maxUnit = 'days';
    } else if (scaledDuration.hours) {
        maxUnit = 'hours';
    } else if (scaledDuration.minutes) {
        maxUnit = 'minutes';
    }

    return maxUnit;
}

export const durationShort = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined | Duration,
): ValueWithUnit => {
    if (value === undefined) return makeValueWithUnit({ value: '', unit: '' });
    if (value === null) return makeValueWithUnit({ value: t('unknown'), unit: '' });

    const duration = typeof value === 'number' ? Duration.fromObject({ seconds: value }) : value;

    const maxUnit = getMaxUnitOfDuration(duration);
    const displayValue = duration.as(maxUnit);

    return makeValueWithUnit({
        value: decimal({ languages }, displayValue),
        unit: t(maxUnit, { value: Math.floor(displayValue) }),
    });
};

export const durationFull = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined | Duration,
) => {
    if (value === undefined) return '';
    if (value === null) return t('unknown');

    const duration = (typeof value === 'number' ? Duration.fromObject({ seconds: value }) : value).rescale();
    const seed: ValueWithUnit[] = [];

    const values = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'].reduce((acc, cur) => {
        const segmentValue = duration[cur];

        if (!segmentValue) return acc;

        return acc.concat(
            makeValueWithUnit({
                value: number({ languages }, segmentValue),
                unit: t(cur, { value: Math.floor(segmentValue) }),
            }),
        );
    }, seed);

    return {
        values,
        toString: () => values.join(' '),
    };
};

export const timeLeftShort = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined,
): ValueWithUnit => {
    if (typeof value !== 'number') return makeValueWithUnit({ value: t('unknown'), unit: '' });

    if (value === -1) return makeValueWithUnit({ value: 'N/A', unit: '' });

    return durationShort({ languages, t }, value);
};

/**
 * Formats the estimated time to empty in seconds to display as the largest time unit and value
 */
export const ette = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined,
): ValueWithUnit => {
    return timeLeftShort({ languages, t }, value);
};

/**
 * Formats a given number as a percentage
 *
 * @param value A number between 0 and 100
 * @returns A string representing the percentage
 */
export const percentage = (
    { t, languages }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined,
): ValueWithUnit => {
    if (typeof value !== 'number') {
        return makeValueWithUnit({ value: t('unknown'), unit: '' });
    }
    if (value === 100) {
        // A value of `100` is a special case,
        // because it should render as "100%"; not "100.0%".
        return makeValueWithUnit({ value: '100', unit: '%' }, false);
    }
    return makeValueWithUnit(
        {
            value: decimal({ languages }, value),
            unit: '%',
        },
        false,
    );
};

/**
 * Formats the state of charge to display as a percentage
 *
 * @param value A number between 0 and 100
 * @returns A string representing the percentage
 * @deprecated Use `percentage` instead
 */
export const soc = percentage;

/**
 * Formats the given power in kW for display
 */
export const power = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined,
): ValueWithUnit => {
    if (typeof value !== 'number') return makeValueWithUnit({ value: t('unknown'), unit: '' });

    return makeValueWithUnit({ value: decimal({ languages }, value, { maximumFractionDigits: 3 }), unit: 'kW' });
};

/**
 * Formats the given energy in kWh for display
 */
export const energy = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined,
): ValueWithUnit => {
    if (typeof value !== 'number') return makeValueWithUnit({ value: t('unknown'), unit: '' });

    return makeValueWithUnit({ value: decimal({ languages }, value, { maximumFractionDigits: 3 }), unit: 'kWh' });
};

/**
 * Formats net power metric for display
 */
export const netPower = (
    { languages, t }: Pick<i18n, 'languages' | 't'>,
    value?: number | null | undefined,
): ValueWithUnit => {
    return power({ languages, t }, value);
};

type MetricPrefix = '' | 'k' | 'M' | 'G';

const metricPrefixes: MetricPrefix[] = ['', 'k', 'M', 'G'];

function findLargestPrefix(value: number): MetricPrefix {
    let exponent = 0;
    let testValue = value / Math.pow(1000, exponent);

    while (testValue > 999 && exponent <= 3) {
        exponent += 1;
        testValue = value / Math.pow(1000, exponent);
    }

    return metricPrefixes[exponent];
}

function getMetricMultiplier(prefix: MetricPrefix): number {
    const exponent = metricPrefixes.findIndex(x => x === prefix);

    return 1 / Math.pow(1000, exponent);
}

/**
 * Returns the largest prefixed unit given a value.
 * Note: the unit must be the base unit. This will not convert a
 * prefixed unit to another prefixed unit at this time.
 */
export const metricValue = (
    { languages }: Pick<i18n, 'languages'>,
    { value, unit, targetPrefix }: { value?: number | null | undefined; unit: string; targetPrefix?: MetricPrefix },
) => {
    if (value == null)
        return makeValueWithUnit({
            value: '',
            unit: '',
        });

    const prefix = targetPrefix ?? findLargestPrefix(value);

    return makeValueWithUnit({
        value: decimal({ languages }, value * getMetricMultiplier(prefix), { maximumFractionDigits: 3 }),
        unit: `${prefix}${unit}`,
    });
};
