import { ComponentProps, ReactElement, useEffect, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';

// import Select from '@mui/material/Select';
import Input from 'app/components/primitives/interactive/Input';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import { createIntervalFromKey, getOptions, IntervalKey, IntervalType } from './createInterval';

interface Props
    extends Omit<ComponentProps<typeof Input>, 'value' | 'onChange' | 'variant' | 'open' | 'onClose' | 'children'> {
    /**
     * Type defines where the interval is being used, mainly to interpret how to display the label
     * for all time.
     */
    type?: IntervalType;

    /**
     * The date time interval key / id
     */
    value?: IntervalKey | '';

    /**
     * Min date to scope the interval to. Any dates before the min will be masked / left off
     */
    minDate?: ISO8601;

    /**
     * Max date to scope the interval to. Any dates after the max will be masked / left off
     */
    maxDate?: ISO8601;

    /**
     * The event handler to be notified when the value changes
     */
    onChange: (event: { value: IntervalKey }) => void;
}

function resolveLabelKey({ type, value }: { type: IntervalType; value: string }): string {
    if (value !== 'all') return `interval_selector.${value}.label`;

    if (type === IntervalType.ReservationItem) {
        return `interval_selector.${value}.label`;
    }

    return `interval_selector.${value}.label_generic`;
}

/**
 * Responsible for emitting known date time intervals based on user selection
 */
export default function IntervalSelector({
    type = IntervalType.ReservationItem,
    minDate,
    maxDate,
    value,
    onChange,
    ...props
}: Props): ReactElement {
    const { t } = useI18n();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const closeMenu = () => {
            setIsOpen(false);
        };

        window.addEventListener('scroll', closeMenu);

        return () => {
            window.removeEventListener('scroll', closeMenu);
        };
    }, []);

    const options = getOptions(type).filter(key => {
        return createIntervalFromKey(key, { minDate, maxDate });
    });

    return (
        <Input
            {...props}
            select
            disabled={props.disabled || options.length <= 1}
            value={value}
            onChange={({ target: { value } }) => {
                const _value = value as IntervalKey;

                onChange({ value: _value });
            }}
            SelectProps={{
                open: isOpen,
                onOpen: () => setIsOpen(true),
                onClose: () => setIsOpen(false),
                MenuProps: { disableScrollLock: true, sx: { mt: 1 } },
            }}
        >
            {options.map(key => (
                <MenuItem key={key} value={key}>
                    {t(resolveLabelKey({ type, value: key }))}
                </MenuItem>
            ))}
        </Input>
    );
}
