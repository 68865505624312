import { DateTime } from 'luxon';
import { CartesianGrid, Legend, ReferenceArea, Tooltip, XAxis, YAxis } from 'recharts';

import TimeSeriesTooltip from './TimeSeriesTooltip';

// TODO(derek): centralize date and number formats for localization
const makeFormatDateTime =
    (ext?: Intl.DateTimeFormatOptions) =>
    (ts: number): string => {
        return DateTime.fromMillis(ts).toLocaleString({
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            ...ext,
        });
    };

const formatNumber = (value: number, { unit = '' } = {}): string => {
    let maximumFractionDigits = 1;

    // TODO(derek): quick fix need a more robust solution for value formatting power (kW) and energy (kWh)
    if (/^k/i.test(unit)) {
        maximumFractionDigits = 3;
    }

    return Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits }).format(value);
};

const tickFormatter = makeFormatDateTime();
const tooltipTickFormatter = makeFormatDateTime({ year: 'numeric' });

interface renderAxisParams {
    minConstraint: number;
    maxConstraint: number;
    scale?: 'linear' | 'auto';
    tickFormatter: (v: number) => string;
}

const renderTimeAxis = ({ minConstraint, maxConstraint, tickFormatter }: renderAxisParams): JSX.Element => (
    <XAxis
        dataKey="timestamp"
        scale="time"
        type="number"
        tickFormatter={ts => {
            // Recharts default interval includes "preserveEnd" which will always show the last timestamp in the dataset
            // even when we are zoomed / constrained to a window of the data. This replaces all ticks that are greater than
            // the constraint with the constraint itself. This should only happen once as the last tick is the problematic one.
            const isConstrained = typeof maxConstraint === 'number' && ts > maxConstraint;

            return tickFormatter(isConstrained ? maxConstraint : ts);
        }}
        // zoom
        allowDataOverflow
        domain={[minConstraint, maxConstraint]}
        // spacing and appearance
        axisLine={false}
        padding={{ right: 40 }}
        minTickGap={60}
    />
);

const renderYAxis = ({
    minConstraint,
    maxConstraint,
    tickFormatter,
    scale = 'auto',
}: renderAxisParams): JSX.Element => (
    <YAxis
        scale={scale}
        allowDecimals={true}
        tickFormatter={tickFormatter}
        // zoom
        domain={[minConstraint, maxConstraint]}
        allowDataOverflow
        // alignment and appearance
        width={70} // About 7 characters
        padding={{ top: 8, bottom: 8 }}
        mirror={false}
        axisLine={false}
    />
);

/**
 * This render function produces a consistent time series chart. The reason it is a function and not a
 * React component is because of the way Rechart expects specific components to be direct children of the
 * root chart components (i.e. BarChart or LineChart). It will render a chart at the specified zoom level
 * given the provided props.
 * @param param0
 * @returns
 */
const renderTimeSeriesChart = ({
    graphedData,
    // zoom
    zoomSelectionStart,
    zoomSelectionEnd,
    zoomMinXConstraint,
    zoomMaxXConstraint,
    zoomMinYConstraint,
    zoomMaxYConstraint,
    // formatting and display
    unit,
}) => (
    <>
        <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
                <stop offset="0" stopColor="#6ffccf" />
                <stop offset="1" stopColor="#30cace" />
            </linearGradient>
        </defs>

        <CartesianGrid strokeDasharray="1 2" />

        <Tooltip
            animationDuration={200}
            content={tooltipProps => (
                <TimeSeriesTooltip
                    {...tooltipProps}
                    unit={unit}
                    tickFormatter={tooltipTickFormatter}
                    valueFormatter={value => formatNumber(value, { unit })}
                />
            )}
        />

        {graphedData}

        {zoomSelectionStart && zoomSelectionEnd ? (
            <ReferenceArea x1={zoomSelectionStart} x2={zoomSelectionEnd} strokeOpacity={0.3} />
        ) : null}

        {renderTimeAxis({ minConstraint: zoomMinXConstraint, maxConstraint: zoomMaxXConstraint, tickFormatter })}
        {renderYAxis({
            minConstraint: zoomMinYConstraint,
            maxConstraint: zoomMaxYConstraint,
            scale: 'linear',
            tickFormatter: value => formatNumber(value, { unit }),
        })}

        {graphedData.length > 1 && <Legend wrapperStyle={{ position: 'relative' }} />}
    </>
);

export default renderTimeSeriesChart;
