import { useCallback, useState } from 'react';
import { ApolloQueryResult, FetchResult, useApolloClient } from '@apollo/client';

import { enhanceAsyncError, silenceKnownDeleteError } from 'app/core/error';
import { ISO8601 } from 'app/core/types';

import {
    DeviceInstanceSchedule,
    GetDowntimePeriodDocument,
    GetDowntimePeriodQuery,
    GetDowntimePeriodQueryVariables,
    GetMpuScheduleDeviceInstanceDocument,
    GetMpuScheduleDeviceInstanceQuery,
    GetMpuScheduleDeviceInstanceQueryVariables,
    useDeleteReservationScheduleDeviceInstanceScheduleMutation as useDeleteSchedule,
    usePersistReservationScheduleDeviceInstanceSchedulesMutation as usePersistSchedules,
} from 'generated/graphql';

import { DowntimePeriod, downtimePeriodToCreateScheduleInputs, scheduleToDowntimePeriod } from './transformers';

/**
 * @return A function to create a new downtime period.
 */
export function useCreateDowntimePeriodMutation() {
    const [persistSchedules, mutationResult] = usePersistSchedules();
    const deleteSchedulesInTimeSpan = useDeleteSchedulesInTimeSpanMutation();

    const createDowntimePeriod = useCallback(
        async (downtimePeriod: Omit<DowntimePeriod, 'id'>): Promise<FetchResult<NonNullable<DowntimePeriod['id']>>> => {
            await deleteSchedulesInTimeSpan({
                end: downtimePeriod.end,
                mpuID: downtimePeriod.mpuID,
                start: downtimePeriod.start,
            });

            const { data, ...result } = await enhanceAsyncError(
                persistSchedules({
                    variables: {
                        input: {
                            schedules: downtimePeriodToCreateScheduleInputs(downtimePeriod),
                        },
                    },
                }),
            );

            const createdScheduleID = data?.createSchedules.at(0)?.id;

            if (!createdScheduleID) {
                throw new Error("Couldn't create schedule.");
            }

            return {
                data: createdScheduleID,
                ...result,
            };
        },
        [deleteSchedulesInTimeSpan, persistSchedules],
    );

    return [createDowntimePeriod, mutationResult] as const;
}

/**
 * @return A function to delete a new downtime period.
 */
export function useDeleteDowntimePeriodMutation() {
    const [deleteSchedule, deleteMutationResult] = useDeleteSchedule();

    const deleteDowntimePeriodPeriod = useCallback(
        // Return's type is `Promise<void>` due to the silencing of the known error.
        // TODO: Remove `silenceKnownDeleteError` and return a `FetchResult`.
        async (id: DowntimePeriod['id']): Promise<void> => {
            await silenceKnownDeleteError(
                deleteSchedule({
                    variables: {
                        deviceScheduleID: id,
                    },
                }),
            );
        },
        [deleteSchedule],
    );

    return [deleteDowntimePeriodPeriod, deleteMutationResult] as const;
}

/**
 * @return A function to get a downtime period.
 *
 * @remarks
 *
 * Neither `useQuery` nor `useLazyQuery` are used here, because we want to invoke query
 * and handle the result on demand inside the callback.
 */
export function useGetDowntimePeriodQuery() {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);

    const getDowntimePeriod = useCallback(
        async (id: DeviceInstanceSchedule['id']): Promise<ApolloQueryResult<DowntimePeriod>> => {
            setLoading(true);

            const { data, ...result } = await client.query<GetDowntimePeriodQuery, GetDowntimePeriodQueryVariables>({
                query: GetDowntimePeriodDocument,
                variables: { id },
            });

            setLoading(false);

            if (result.error) {
                throw result.error;
            }

            return {
                data: scheduleToDowntimePeriod(data.deviceInstanceSchedule),
                ...result,
            };
        },
        [client],
    );

    return [getDowntimePeriod, { loading }] as const;
}

/**
 * @return A function to update a downtime period.
 */
export function useUpdateDowntimePeriodMutation() {
    const [deleteDowntimePeriodPeriod, deleteMutationResult] = useDeleteDowntimePeriodMutation();
    const [createDowntimePeriod, createMutationResult] = useCreateDowntimePeriodMutation();
    const [getDowntimePeriod, getQueryResult] = useGetDowntimePeriodQuery();
    const deleteSchedulesInTimeSpan = useDeleteSchedulesInTimeSpanMutation();

    const updateDowntimePeriod = useCallback(
        async ({ end, id, internalNotes, start }: Omit<DowntimePeriod, 'mpuID'>) => {
            const result = await getDowntimePeriod(id);
            const { mpuID } = result.data;

            await deleteSchedulesInTimeSpan({ end, mpuID, start });
            await deleteDowntimePeriodPeriod(id);

            const createResult = await createDowntimePeriod({ end, internalNotes, mpuID, start });

            return {
                createdScheduleID: createResult.data,
                mpuID,
                removedScheduleID: id,
            };
        },
        [createDowntimePeriod, deleteDowntimePeriodPeriod, deleteSchedulesInTimeSpan, getDowntimePeriod],
    );

    const loading = createMutationResult.loading || deleteMutationResult.loading || getQueryResult.loading;

    return [updateDowntimePeriod, { loading }] as const;
}

/**
 * @returns A function to delete all schedules in a given time span.
 */
export function useDeleteSchedulesInTimeSpanMutation() {
    const [deleteSchedule] = useDeleteSchedule();
    const client = useApolloClient();

    return useCallback(
        async ({ end, mpuID, start }: { end: ISO8601; mpuID: string; start: ISO8601 }) => {
            const { data, ...result } = await client.query<
                GetMpuScheduleDeviceInstanceQuery,
                GetMpuScheduleDeviceInstanceQueryVariables
            >({
                query: GetMpuScheduleDeviceInstanceDocument,
                variables: {
                    deviceInstanceID: mpuID,
                    end,
                    start,
                },
            });

            if (result.error) {
                throw result.error;
            }

            const schedules = data.getDeviceInstance.schedules ?? [];

            await Promise.all(
                schedules.map(schedule =>
                    silenceKnownDeleteError(
                        deleteSchedule({
                            variables: {
                                deviceScheduleID: schedule.id,
                            },
                        }),
                    ),
                ),
            );
        },
        [client, deleteSchedule],
    );
}
