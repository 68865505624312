import { ComponentProps, ReactElement } from 'react';

import MenuItem from '@mui/material/MenuItem';

import { Input } from 'app/components';

import { ReservationType } from 'generated/graphql';

import { useI18n } from 'i18n';

type Props = Omit<ComponentProps<typeof Input>, 'select'>;

export default function ReservationServiceTypeSelector(props: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Input {...props} select>
            {Object.values(ReservationType).map(value => (
                <MenuItem key={value} value={value}>
                    {t(`service_type.${value.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Input>
    );
}
