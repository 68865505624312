import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ZeroState from 'app/components/compounds/ZeroState';
import { LoadingIndicator } from 'app/components/primitives/LoadingIndicator';

interface Props {
    /**
     * Whether the data to be rendered in the table is still in flight
     */
    isLoading: boolean;
    /**
     * Whether to render the zero state UI or not
     */
    isEmpty: boolean;
    /**
     * What to render if we're the data set is empty
     */
    fallback: JSX.Element;
    /**
     * Content to render if not loading or if the data set is empty
     */
    children: JSX.Element | JSX.Element[] | null | undefined;
}

const SpanRow = ({ children }) => (
    <TableRow>
        {/* we use colSpan 999 to ensure the cell fully spans the row */}
        <TableCell colSpan={999} align="center">
            {children}
        </TableCell>
    </TableRow>
);

/**
 * TableBody provides standard treatment for loading and zero states. Use instead of
 * MUI TableBody when data in table is populated dynamically via a network call.
 */
export default function DynamicTableBody({
    isLoading,
    isEmpty,
    fallback = <ZeroState title="No data" message="There are no rows." />,
    children,
    ...rest
}: Props): JSX.Element {
    return (
        <TableBody {...rest}>
            {isLoading && (
                <SpanRow>
                    <Box m={10}>
                        <LoadingIndicator />
                    </Box>
                </SpanRow>
            )}

            {!isLoading && isEmpty && (
                <SpanRow>
                    <Box m={6}>{fallback}</Box>
                </SpanRow>
            )}

            {!isLoading && !isEmpty && children}
        </TableBody>
    );
}
