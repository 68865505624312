import { ComponentProps, Fragment, ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Duration } from 'luxon';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Alert from 'app/components/primitives/Alert';
import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import useActiveFaultsForDevice from './useActiveDeviceFaults';

const AnimatedBox = motion(Box);

const PREVIEW_COUNT = 3;

/**
 * Extends Stack to allow layout properties (i.e. "m", "mb", etc or sx props)
 * Avoid setting any non spacing related stylings
 */
interface Props extends Omit<ComponentProps<typeof Stack>, 'direction | children | spacing | useFlexGap'> {
    /**
     * The device instance ID
     */
    mpuID?: string;

    /**
     * How frequently in milliseconds to retry fetching active faults
     * @default
     */
    pollInterval?: number;

    /**
     * Event handler to notify consumers of the total fault count
     */
    onFaultCountChange?: (event: { value: number }) => void;

    /**
     * Notify when the loading state changes, because the alerts can affect page height and hence scrolling it may
     * be helpful in some circumstances to know when the element is loaded before scrolling or for any other purpose.
     */
    onLoadingChange?: (event: { value: boolean }) => void;
}

/**
 * Responsible for listing all active faults associated with a device with the provided ID
 */
export default function ActiveDeviceFaults({
    mpuID,
    pollInterval = Duration.fromObject({ minutes: 5 }).toMillis(),
    onLoadingChange,
    onFaultCountChange,
    ...propsForStack
}: Props): ReactElement {
    const { t, format } = useI18n();

    const { isLoading, data: activeFaults } = useActiveFaultsForDevice({ id: mpuID, pollInterval });
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const overflowCount = activeFaults.length - PREVIEW_COUNT;
    const isOverflown = overflowCount > 0;

    let activeFaultsToDisplay = activeFaults;

    if (isOverflown) {
        activeFaultsToDisplay = isExpanded ? activeFaults : activeFaults.slice(0, PREVIEW_COUNT);
    }

    const faultCount = activeFaults.length;

    useEffect(() => {
        onFaultCountChange?.({
            value: faultCount,
        });
    }, [faultCount, onFaultCountChange]);

    useEffect(() => {
        onLoadingChange?.({
            value: isLoading,
        });
    }, [isLoading, onLoadingChange]);

    return (
        <Stack {...propsForStack} spacing={4}>
            <AnimatePresence initial={false}>
                {activeFaultsToDisplay.map(({ code, name, description, severity, firstSeen, lastUpdate }) => (
                    <AnimatedBox
                        key={code}
                        layout
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0, margin: 0 }}
                    >
                        <Alert
                            iconName="alert-circle"
                            severity={severity === 'CRITICAL' ? 'error' : 'normal'}
                            title={t('active_device_faults.fault_title', {
                                faultName: (
                                    <Fragment key="faultName">
                                        {name} <Text variant="body">({code})</Text>
                                    </Fragment>
                                ),
                            })}
                            message={
                                <Box>
                                    <Text variant="inherit">{description}</Text>
                                    <Text variant="inherit">
                                        {t('active_device_faults.first_seen_and_last_updated', {
                                            firstSeen: format.dateTime(firstSeen),
                                            lastUpdate: format.dateTime(lastUpdate),
                                        })}
                                    </Text>
                                </Box>
                            }
                        />
                    </AnimatedBox>
                ))}
            </AnimatePresence>

            {isOverflown && (
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        ctaType="tertiary"
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        <Stack direction="row" spacing={2}>
                            <span>
                                {isExpanded
                                    ? t('active_device_faults.view_less_faults')
                                    : t('active_device_faults.view_more_faults', { count: overflowCount })}
                            </span>
                            <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
                        </Stack>
                    </Button>
                </Box>
            )}
        </Stack>
    );
}
