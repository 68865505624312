import { DateTime } from 'luxon';

import {
    GetReservationItemTelemetryQueryHookResult,
    TelemetryPoint,
    useGetDeviceLatestTelemetryQuery,
    useGetReservationItemLatestTelemetryQuery,
} from 'generated/graphql';

import { MetricName } from './constants';

interface Input {
    /**
     * The reservation item's id to pull telemetry for
     */
    reservationItemID?: string | undefined;

    /**
     * The device instance id to pull telemetry for
     */
    deviceInstanceID?: string | undefined;

    /**
     * Which telemetry to pull
     */
    metricNames: MetricName[];

    /**
     * How frequently to check for updated status
     */
    pollInterval?: number;

    /**
     * When to pull telemetry from
     */
    start?: DateTime | null;
}

interface LatestTelemetryOutput extends Pick<GetReservationItemTelemetryQueryHookResult, 'loading' | 'error'> {
    data: TelemetryPoint[];
}

/**
 * Responsible for fetching latest telemetry for a device either by deviceInstanceID or as part of a reservation via
 * reservationItemID. Only one ID must be provided.
 *
 * Note that generally a Renter should supply a reservationItem and an owner a DeviceInstance
 */
export default function useLatestTelemetryData({
    metricNames,
    reservationItemID,
    deviceInstanceID,
    pollInterval,
}: Input): LatestTelemetryOutput {
    const {
        loading: reservationTelemetryLoading,
        error: reservationTelemetryError,
        data: reservationTelemetryData,
    } = useGetReservationItemLatestTelemetryQuery({
        skip: !!deviceInstanceID || !reservationItemID,
        errorPolicy: 'all',
        variables: {
            id: reservationItemID ?? '',
            metricNames,
        },
        pollInterval,
    });

    const {
        loading: deviceTelemetryLoading,
        error: deviceTelemetryError,
        data: deviceTelemetryData,
    } = useGetDeviceLatestTelemetryQuery({
        skip: !!reservationItemID || !deviceInstanceID,
        errorPolicy: 'all',
        variables: {
            id: deviceInstanceID ?? '',
            metricNames,
        },
        pollInterval,
    });

    const data =
        reservationTelemetryData?.reservationLatestTelemetry.telemetryPoints ||
        deviceTelemetryData?.getDeviceInstance.latestTelemetry.telemetryPoints;
    const error = reservationTelemetryError || deviceTelemetryError;

    return {
        loading: reservationTelemetryLoading || deviceTelemetryLoading,
        error: !!data && !!error ? undefined : error,
        data: data ?? [],
    };
}
