import { Duration } from 'luxon';

import { ISO8601 } from 'app/core/types';

import {
    ActiveAlertsSummaryQueryResult,
    AlertSeverity,
    AlertType,
    useActiveAlertsSummaryQuery,
} from 'generated/graphql';

interface Alert {
    reservationID?: string;
    type: AlertType;
    triggerTime: ISO8601;
    severity: AlertSeverity;
}

export default function useActiveAlertSummary(): {
    /**
     * Returns -1 if the total is unknown (i.e. loading)
     */
    totalAlerts: number;

    /**
     * The alert counts sorted by criticality. Most critical first.
     */
    counts: { severity: string; count: number }[];

    /**
     * Force refetch for summary counts
     */
    refetch: ActiveAlertsSummaryQueryResult['refetch'];
} {
    const { loading, data, refetch } = useActiveAlertsSummaryQuery({
        pollInterval: Duration.fromObject({ minutes: 5 }).toMillis(),
    });

    const alerts: Alert[] =
        data?.listActiveAlerts.map(alert => ({ ...alert, reservationID: alert.reservation?.id })) ?? [];

    // set up the count according to severity
    let countMap: { [severity: string]: number } = { [AlertSeverity.Critical]: 0 };
    for (const alert of alerts) {
        if (!countMap[alert.severity]) {
            countMap[alert.severity] = 0;
        }
    }

    // format the count as an array of objects
    const counts: { severity: string; count: number }[] = Object.entries(
        alerts.reduce((acc, alert) => {
            acc[alert.severity]++;

            return acc;
        }, countMap),
    ).map(([severity, count]) => ({ severity, count }));

    const totalAlerts = loading
        ? -1
        : counts.reduce((acc, cur) => {
              return acc + cur.count;
          }, 0);

    return {
        counts,
        totalAlerts,
        refetch,
    };
}
