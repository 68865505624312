import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { Cluster, Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

import useTheme from 'styles/theme';

import { FAULT_SEVERITIES, getBarStyle } from './config';

/**
 * This renders the legend for how to make sense of the bar styles. This is done outside of recharts
 * so we don't have to worry about the restrictions on recharts layout especially with the enabled
 * vertical scrolling.
 */
export default function Legend(): ReactElement {
    const { t } = useI18n();
    const theme = useTheme();

    return (
        <Cluster>
            {FAULT_SEVERITIES.map(x => (
                <Box key={`legend-entry-${x}`} display="flex" alignContent="center" gap={2}>
                    <Box sx={{ backgroundColor: getBarStyle({ theme })[x]?.fill, width: 24, height: 20 }} />

                    <Text variant="detail">{t(`fault_severity.${x?.toLowerCase()}`)}</Text>
                </Box>
            ))}
        </Cluster>
    );
}
