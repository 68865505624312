import {
    cloneElement,
    ComponentProps,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useId,
    useRef,
    useState,
} from 'react';

import Box from '@mui/material/Box';

import Button from 'app/components/primitives/interactive/Button';
import TransitionContentHeight from 'app/components/primitives/layout/TransitionContentHeight';

import { useI18n } from 'i18n';

import { MUIMarginProps } from 'styles/theme/types';

interface Props extends MUIMarginProps {
    /**
     * Whether to disable the auto focus behavior when edit mode is engaged
     */
    disableAutoFocus?: boolean;

    /**
     * The sections title, must accept a cta
     */
    header: ReactElement<{ mb: number; cta: ReactElement }>;

    /**
     * The save CTA to initiate persisting the changes made to the editable content / form
     */
    saveCTA: ReactElement<Pick<ComponentProps<typeof Button>, 'onClick' | 'children' | 'ctaType' | 'color'>>;

    /**
     * The editable content, generally a form or series of input fields
     */
    children: (x: { isEditing: boolean }) => ReactNode | ReactNode[];

    /**
     * Event handler called when the edit mode is dismissed
     */
    onCancel?: () => void;
}

/**
 * Editable section handles the layout and edit modes for a given form or section with editable content. This component
 * collaborates with input elements (passed as children).
 */
const EditableSection = ({ children, onCancel, header, saveCTA, disableAutoFocus, ...props }: Props): ReactElement => {
    const { t } = useI18n();

    const contentSectionID = useId();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [shouldReturnFocus, setShouldReturnFocus] = useState(false);
    const editToggleRef = useRef<HTMLButtonElement>(null);

    const save: MouseEventHandler<HTMLButtonElement> = useCallback(
        event => {
            setShouldReturnFocus(true);
            setIsEditing(false);
            saveCTA.props.onClick?.(event);
        },
        [saveCTA.props],
    );

    const cancel = useCallback(() => {
        setShouldReturnFocus(true);
        setIsEditing(false);
        onCancel?.();
    }, [setIsEditing, onCancel]);

    /**
     * Handle focus behaviors
     */
    useEffect(() => {
        if (isEditing && !disableAutoFocus) {
            const focusableElements =
                document
                    .getElementById(contentSectionID)
                    ?.querySelectorAll<HTMLElement>(
                        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
                    ) ?? [];

            focusableElements[0]?.focus();
        }

        if (!isEditing && shouldReturnFocus) {
            editToggleRef?.current?.focus();
        }
    }, [isEditing, contentSectionID, disableAutoFocus, shouldReturnFocus]);

    return (
        <Box
            {...props}
            onKeyDown={({ key }) => {
                if (key === 'Escape') {
                    cancel();
                }
            }}
        >
            <TransitionContentHeight>
                {cloneElement(header, {
                    mb: 4,
                    cta: (
                        <Box
                            sx={{
                                visibility: isEditing ? 'hidden' : 'visible',
                                // the button wraps underneath the header below md, so the height should only
                                // be preserved at md and wider
                                height: isEditing ? { xs: 0, md: 'auto' } : 'auto',
                            }}
                        >
                            <Button ref={editToggleRef} ctaType="secondary" onClick={() => setIsEditing(true)}>
                                {t('cta.edit')}
                            </Button>
                        </Box>
                    ),
                })}

                <Box id={contentSectionID}>{children?.({ isEditing })}</Box>

                <Box
                    mt={6}
                    sx={{
                        display: isEditing ? 'flex' : 'none',
                        justifyContent: 'flex-end',
                        gap: 2,
                    }}
                >
                    <Button ctaType="tertiary" onClick={cancel}>
                        {t('cta.cancel')}
                    </Button>

                    {cloneElement(saveCTA, {
                        ctaType: 'primary',
                        color: 'primary',
                        children: saveCTA.props.children ?? t('cta.save'),
                        onClick: save,
                    })}
                </Box>
            </TransitionContentHeight>
        </Box>
    );
};

export default EditableSection;
