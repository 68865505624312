import { ComponentProps, ElementType, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Text from 'app/components/primitives/Text';

type TextVariant = ComponentProps<typeof Text>['variant'];

interface Props extends Omit<ComponentProps<typeof Box>, 'title' | 'children'> {
    headingLevel?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
    as?: ElementType | null;
    title: ReactNode;
    subtitle?: ReactNode;
    cta?: ReactElement | null;
}

export default function SectionHeader({
    headingLevel = 'H2',
    as,
    title,
    subtitle,
    cta,
    ...props
}: Props): ReactElement {
    return (
        <Box component="header" width="100%" {...props}>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                justifyContent="space-between"
            >
                <Box>
                    <Text
                        variant={headingLevel?.toLowerCase() as TextVariant}
                        as={as}
                        alignSelf="center"
                        preserveHeight={1}
                        textTransform="capitalize"
                    >
                        {title}
                    </Text>

                    {!!subtitle && (
                        <Text
                            as="div"
                            display="flex"
                            justifyContent="flex-start"
                            mt={1}
                            color="secondary"
                            preserveHeight={1}
                        >
                            {subtitle}
                        </Text>
                    )}
                </Box>

                {!!cta && (
                    <Box mt={{ xs: 4, md: 0 }} ml={{ xs: 0, md: 4 }}>
                        {cta}
                    </Box>
                )}
            </Stack>
        </Box>
    );
}
