import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useExperienceSwitcher } from 'app/core/Navigation/ExperienceSwitcher';
import getPathByName from 'app/core/Navigation/getPathByName';

/**
 * Resposible for integrating experience switcher with components which allow experience selection
 */
export default function useExperience({ userID }) {
    const navigate = useNavigate();
    const experienceSwitcher = useExperienceSwitcher();

    const [nextTargetExperience, setNextTargetExperience] = useState(experienceSwitcher.getNextExperience());
    const [targetExperience, setTargetExperience] = useState(experienceSwitcher.getExperience());

    const forgetExperience = useCallback(() => {
        experienceSwitcher.forgetExperience({ userID });
    }, [userID, experienceSwitcher]);

    const switchExperience = useCallback(
        ({ value }) => {
            experienceSwitcher.switchExperience(value);
        },
        [experienceSwitcher],
    );

    useEffect(() => {
        return experienceSwitcher.onSwitchExperience(({ data }) => {
            setNextTargetExperience(experienceSwitcher.getNextExperience());
            setTargetExperience(data?.targetExperience ?? null);

            navigate(getPathByName(experienceSwitcher.getHomePathName()));
        });
    }, [experienceSwitcher, navigate]);

    return {
        targetExperience,
        nextTargetExperience,
        switchExperience,
        forgetExperience,
    };
}
