import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { debounce } from '@mui/material';

import { formatURLState } from './getPathByName';

// Note-1(Derek): What's going on here??
// Turns out that document.body is not always the scrolling element. There is a new API for getting the scrollingElement
// however this doesn't seem properly supported, especially in quirks mode.There is a polyfill for it but that doesn't appear
// to work consistently either :(. Since either body or html / window could be the scrolling element calling scrollTo on both
// is quite stable. The duplication hurts a bit but works quite well.
//
// Aditional Context:
// I noticed that owners/units/:unitID page wasn't working with the code within which was scrolling the document.body and listening on document.body
// but worked if I switched everything over to window. The opposite was true for the admin/dashboard/units/:unitID page. The main difference was
// that the admin/dashboard/units/:unitID page has MUI Modals / Dialogs which when commented out caused both pages to rely on the same scrolling element.
// Further investigation is needed to understand why.
//
// - MDN for document.scrollingElement https://developer.mozilla.org/en-US/docs/Web/API/document/scrollingElement
// - The gist with the polyfill code can be seen at https://gist.github.com/dperini/ac3d921d6a08f10fd10e.

interface Inputs {
    /**
     * Whether the page is loading
     */
    isLoading?: boolean;

    /**
     * How long to try searching for a given element by hash / id before giving up
     */
    timeout?: number;

    /**
     * Expected section IDs to be scrolled to, if the hash does not match one of these sections scroll will not ensue
     */
    sectionIDs?: string[];
}

const POLL_INTERVAL = 100;

// Get the scroll progress for the page.
// See Note-1 above for extra context on the weirdness around page scrolling.
function getScrollProgress() {
    return window.scrollY || window.document.body.scrollTop;
}

/**
 * This hook will handle scrolling to a given section (if exists, or will exist within the timeout) based on the hash in the
 * current locations URL.
 *
 * This hook should only be called once per Page, ideally at the root component for the Page.
 */
export default function useScrollToPageSection({ isLoading = false, timeout = 1000, sectionIDs = [] }: Inputs) {
    const location = useLocation();
    const navigate = useNavigate();
    const totalTimeElapsed = useRef<number>(0);
    const isScrolling = useRef<boolean>(false);
    const shouldIgnoreScroll = useRef<boolean>(false);
    const { hash } = location;

    function getActiveSection() {
        const scrollProgress = getScrollProgress();

        return sectionIDs
            .map(x => document.getElementById(x))
            .filter(x => !!x)
            .sort((a, b) => {
                const aVal = Math.abs((a?.offsetTop ?? 0) - scrollProgress);
                const bVal = Math.abs((b?.offsetTop ?? 0) - scrollProgress);

                return aVal - bVal;
            })[0];
    }

    // React to changes in the URL's hash except when caused by the scroll below
    useEffect(() => {
        const elementID = hash?.slice(1);

        if (location.state?.reason === 'scroll') {
            location.state.reason = null;

            return;
        }

        if (isScrolling.current || !sectionIDs.includes(elementID) || totalTimeElapsed.current > timeout || isLoading) {
            return;
        }

        // This timeout appears necessary to ensure we apply this logic after the default browser behavior
        // The default is only noticed when a new hash is entered into the location bar
        setTimeout(() => {
            function attemptToScroll() {
                const targetElement = document.getElementById(elementID);

                if (targetElement) {
                    // See Note-1 above
                    window.scrollTo({
                        top: targetElement.offsetTop,
                        left: 0,
                        behavior: 'smooth',
                    });
                    window.document.body.scrollTo({
                        top: targetElement.offsetTop,
                        left: 0,
                        behavior: 'smooth',
                    });

                    // We are ignoring scroll events until after the scrollTo animation completes as the
                    // scrollTo scrolling will generate scroll events. The timing of this is a bit hairy
                    // but half a second seems fine.
                    shouldIgnoreScroll.current = true;

                    setTimeout(() => {
                        shouldIgnoreScroll.current = false;
                    }, 500);
                } else {
                    setTimeout(attemptToScroll, POLL_INTERVAL);
                    totalTimeElapsed.current += POLL_INTERVAL;
                }
            }

            attemptToScroll();
        });

        // We don't care about reacting to sectionIDs or the refs that we're manipulating within
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, hash]);

    // Stash the hash in the URL as the page is scrolled. This makes it so in page links to sections of the page will work when the section is
    // scrolled away from.
    useEffect(() => {
        const stashHash = debounce(function () {
            if (shouldIgnoreScroll.current) return;

            const nearestElement = getActiveSection();

            navigate(formatURLState({ search: location.search, hash: nearestElement?.id }), {
                replace: true,
                state: { reason: 'scroll' },
            });
        }, 100);

        // See Note-1 above
        window.addEventListener('scroll', stashHash);
        window.document.body.addEventListener('scroll', stashHash);

        return () => {
            window.removeEventListener('scroll', stashHash);
            window.document.body.removeEventListener('scroll', stashHash);
        };

        // Only run on mount, we don't care about the deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
