import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

import Icon from 'app/components/primitives/Icon';
import Text from 'app/components/primitives/Text';

interface Props extends BoxProps {
    text: string;
}

export default function InlineErrorMessage({ text }: Props): ReactElement {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Icon name="alert-circle" size="lg" color="error.main" />

            <Text as="p" color="danger">
                {text}
            </Text>
        </Box>
    );
}
