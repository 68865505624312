import { ReactElement } from 'react';

import { Text } from 'app/components/primitives';
import { CellSignalBar, RunMode } from 'app/core/data';
import { MPUDetailSectionID } from 'app/core/Navigation/constants';
import getPathByName from 'app/core/Navigation/getPathByName';

import ActiveFaultsPreview from '../ActiveFaultsPreview';
import { MPUTileAssignment, MPUTileHealth } from './constants';
import { MPUTileAssignmentTag } from './MPUTileAssignmentTag';
import { MPUTileCellSignalIcon } from './MPUTileCellSignalIcon';
import { MPUTileExternalID } from './MPUTileExternalID';
import { MPUTileMpuName } from './MPUTileMpuName';
import { MPUTileRunModeIcon } from './MPUTileRunModeIcon';
import { MPUTileSerialNumber } from './MPUTileSerialNumber';
import { MPUTileSkeleton } from './MPUTileSkeleton';
import { MPUTileStateOfCharge } from './MPuTileStateOfCharge';
import { MPUTileStructure } from './MPUTileStructure';
import { MPUTileTimeToEmpty } from './MPUTileTimeToEmpty';

interface MPUTileContentProps {
    /**
     * The status of the MPU.
     */
    assignment?: `${MPUTileAssignment}`;
    /**
     * The connection strength of the MPU.
     */
    cellSignalBars?: CellSignalBar;
    /**
     * The fault codes deemed critical
     */
    criticalFaultCodes?: string[];
    /**
     * The external ID of the MPU.
     *
     * @example
     *
     * "10873305"
     */
    externalID?: string;
    /**
     * The firmware version of the MPU.
     *
     * @example
     *
     * "23.6.1"
     */
    firmwareVersion?: string;
    /**
     * A value representing the overall health of the MPU.
     */
    health?: `${MPUTileHealth}`;
    /**
     * Determines whether the component should render skeleton content.
     * When `true`, all content is replaced by skeleton.
     */
    loading?: boolean;
    /**
     * The threshold at which the MPU is considered low on charge.
     * A number between 0 and 100.
     *
     * @default 20
     */
    lowStateOfChargeThreshold?: number;
    /**
     * The threshold at which the MPU is considered low on time to empty in seconds.
     *
     * @default 172800 // 48 hours
     */
    lowTimeToEmptyThreshold?: number;
    /**
     * The mode of the MPU.
     */
    mode?: `${RunMode}`;
    /**
     * The model name of the MPU.
     *
     * @example
     *
     * "MPU-75"
     */
    modelName?: string;
    /**
     * The MPU's internal ID.
     */
    mpuID?: string;
    /**
     * The name of the MPU.
     *
     * @example
     *
     * "c-0025"
     */
    mpuName?: string;
    /**
     * The fault codes deemed non-critical
     */
    nonCriticalFaultCodes?: string[];
    /**
     * The owner of the MPU.
     *
     * @example
     *
     * "Moxion Power"
     */
    ownerName?: string;
    /**
     * The serial number of the MPU.
     *
     * @example
     *
     * "ABF0000000007845023"
     */
    serialNumber?: string;
    /**
     * The service area of the MPU.
     *
     * @example
     *
     * "Richmond, CA"
     */
    serviceAreaName?: string;
    /**
     * A number between 0 and 100 representing the state of charge of the MPU.
     */
    stateOfCharge?: number;
    /**
     * The time to empty of the MPU in seconds.
     */
    timeToEmpty?: number;
}

export function MPUTileContent(props: MPUTileContentProps): ReactElement {
    const {
        assignment,
        cellSignalBars,
        criticalFaultCodes,
        externalID,
        firmwareVersion,
        health,
        loading,
        lowStateOfChargeThreshold,
        lowTimeToEmptyThreshold,
        mode,
        modelName,
        mpuID,
        mpuName,
        nonCriticalFaultCodes,
        ownerName,
        serialNumber,
        serviceAreaName,
        stateOfCharge,
        timeToEmpty,
    } = props;

    if (loading) {
        return <MPUTileSkeleton />;
    }

    const faultsHREF = mpuID
        ? getPathByName('MPU_DETAIL', { hash: MPUDetailSectionID.HistoricalFaults, params: { mpuID } })
        : mpuID;

    return (
        <MPUTileStructure
            assignment={<MPUTileAssignmentTag mpuID={mpuID} assignment={assignment} />}
            externalID={<MPUTileExternalID externalID={externalID} />}
            firmwareVersion={
                <Text
                    color="secondary"
                    maxLines={1}
                    minWidth="8ch"
                    textAlign={serialNumber ? 'left' : 'right'}
                    variant="detail"
                >
                    {firmwareVersion}
                </Text>
            }
            indicators={
                <>
                    <ActiveFaultsPreview
                        criticalFaultCodes={criticalFaultCodes}
                        faultsHREF={faultsHREF}
                        nonCriticalFaultCodes={nonCriticalFaultCodes}
                    />
                    <MPUTileRunModeIcon mode={mode} />
                    <MPUTileCellSignalIcon cellSignalBars={cellSignalBars} />
                </>
            }
            modelName={
                <Text color="secondary" maxLines={1} minWidth="7ch" variant="detail">
                    {modelName}
                </Text>
            }
            mpuName={<MPUTileMpuName mpuID={mpuID} mpuName={mpuName} />}
            ownerName={
                <Text color="secondary" maxLines={1} variant="detail">
                    {ownerName}
                </Text>
            }
            serialNumber={<MPUTileSerialNumber serialNumber={serialNumber} />}
            serviceArea={
                <Text color="secondary" maxLines={1} variant="detail">
                    {serviceAreaName}
                </Text>
            }
            stateOfCharge={
                <MPUTileStateOfCharge
                    health={health}
                    lowStateOfChargeThreshold={lowStateOfChargeThreshold}
                    mpuID={mpuID}
                    stateOfCharge={stateOfCharge}
                />
            }
            timeToEmpty={
                <MPUTileTimeToEmpty lowTimeToEmptyThreshold={lowTimeToEmptyThreshold} timeToEmpty={timeToEmpty} />
            }
        />
    );
}
