import { ComponentProps, FocusEvent, ReactElement } from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import Input, { useInputForAutocomplete } from '../../primitives/interactive/Input';
import useAccounts from './useAccounts';

interface Props extends Omit<ComponentProps<typeof Input>, 'value' | 'onChange' | 'select' | 'children'> {
    /**
     * The value is the current selected accountID or null if not set
     */
    value: string | null;

    onBlur?: (event: FocusEvent) => void;

    /**
     * Event handler to be called when the value changes
     */
    onChange?: (event: { value: { id?: string; name?: string | null } | null }) => void;
}

/**
 * Responsible for rending a form control with allows users to select from a list of known accounts
 */
export default function AccountSelector({
    id,
    fullWidth = false,
    fieldName,
    caption,
    name,
    value,
    error,
    disabled,
    readOnly,
    required = true,
    onBlur,
    onChange,
}: Props): ReactElement {
    const renderInput = useInputForAutocomplete({
        fieldName,
        caption,
        name,
        required,
        readOnly,
        disabled,
        error,
    });

    const { loading, accountsByID } = useAccounts();
    const options: string[] = Object.keys(accountsByID);

    return (
        <Autocomplete
            loading={loading}
            id={id}
            disabled={disabled}
            readOnly={readOnly}
            fullWidth={fullWidth}
            autoHighlight
            value={value || null}
            disableClearable={required || readOnly}
            forcePopupIcon={!readOnly}
            isOptionEqualToValue={(option, currentAccountID) => {
                return option === currentAccountID;
            }}
            options={options}
            getOptionLabel={option => {
                return accountsByID[option]?.name ?? '';
            }}
            renderInput={renderInput}
            renderOption={(props, option) => (
                <li {...props} key={option}>
                    {accountsByID[option].name}
                </li>
            )}
            onChange={(event, newValue) => {
                onChange?.({ value: accountsByID[newValue ?? ''] ?? null });
            }}
            onBlur={onBlur}
        />
    );
}
