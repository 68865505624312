import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Grid from '@mui/material/Grid';

import {
    MultiAccountSelector,
    MultiAlertSeveritySelector,
    MultiReservationSelector,
    MultiServiceAreaSelector,
} from 'app/components';

import { FilterCriteria, FormStateChange } from './types';

interface Props extends Partial<FilterCriteria> {
    /**
     * The ID attribute to be applied to the form element. Enables remote form submission via the `form` attribute
     * when set
     */
    id?: string;

    /**
     * The accountID to returned scoped serviceAreas for
     */
    accountID?: string;

    /**
     * Event handler to be called on form submission
     */
    onSubmit?: (event: { value: FilterCriteria }) => Promise<void>;

    /**
     * Event handler to be called on form state changes
     */
    onFormStateChange?: (event: FormStateChange<FilterCriteria>) => void;
}

export default function FilterAlertForm({
    id,

    // Data dependencies
    accountID,

    // Form fields
    alertSeverities = [],
    reservationIDs = [],
    rentalAccountIDs = [],
    serviceAreaIDs = [],

    // Event handlers
    onSubmit,
    onFormStateChange,
}: Props): ReactElement {
    const filter = object().shape({
        id: string(),
        label: string(),
    });

    const formik = useFormik<FilterCriteria>({
        enableReinitialize: true,

        initialValues: {
            alertSeverities,
            reservationIDs,
            rentalAccountIDs,
            serviceAreaIDs,
        },

        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        validationSchema: object().shape({
            alertSeverities: array().of(filter).optional(),
            reservationIDs: array().of(filter).optional(),
            rentalAccountIDs: array().of(filter).optional(),
            serviceAreaIDs: array().of(filter).optional(),
        }),

        async onSubmit(value) {
            await onSubmit?.({ value });
        },
    });

    const { isValid, dirty, values } = formik;

    useEffect(() => {
        onFormStateChange?.({
            shouldDisableCTA: !isValid || !dirty,
            isDirty: dirty,
            isValid,
            values,
            filterCriterionCount: Object.keys(values)?.reduce((acc, cur) => {
                return acc + values[cur].length;
            }, 0),
        });
    }, [dirty, onFormStateChange, isValid, values]);

    return (
        <Grid
            component="form"
            id={id}
            container
            spacing={4}
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
        >
            <Grid item xs={12} md={6}>
                <MultiAlertSeveritySelector
                    name="alertSeverities"
                    value={values.alertSeverities.map(x => x.id)}
                    fieldName="Alert Severity"
                    placeholder="Select..."
                    onChange={({ name, selectedOptions }) => {
                        formik.setFieldValue(name ?? '', selectedOptions, true);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <MultiReservationSelector
                    name="reservationIDs"
                    value={values.reservationIDs.map(x => x.id)}
                    fieldName="Reservations"
                    placeholder="Select..."
                    onChange={({ name, selectedOptions }) => {
                        formik.setFieldValue(name ?? '', selectedOptions, true);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <MultiAccountSelector
                    name="rentalAccountIDs"
                    value={values.rentalAccountIDs.map(x => x.id)}
                    fieldName="Renter"
                    placeholder="Select..."
                    onChange={({ name, selectedOptions }) => {
                        formik.setFieldValue(name ?? '', selectedOptions, true);
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <MultiServiceAreaSelector
                    name="serviceAreaIDs"
                    value={values.serviceAreaIDs.map(x => x.id)}
                    accountID={accountID}
                    fieldName="Service Area"
                    placeholder="Select..."
                    onChange={({ name, selectedOptions }) => {
                        formik.setFieldValue(name ?? '', selectedOptions, true);
                    }}
                />
            </Grid>
        </Grid>
    );
}
