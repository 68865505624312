import Box from '@mui/material/Box';

import Button from 'app/components/primitives/interactive/Button';

import { useI18n } from 'i18n';

/**
 * The footer content for the downtime period dialog.
 */
export function DowntimePeriodFooterContent({
    enableRemove,
    onCancel,
    onRemove,
    onSave,
    saveDisabled,
    saveText,
}: {
    enableRemove?: boolean;
    onCancel?: () => void;
    onRemove?: () => void;
    onSave?: () => void;
    saveDisabled?: boolean;
    saveText: string;
}) {
    const { t } = useI18n();

    return (
        <>
            {enableRemove && (
                <>
                    <Button ctaType="secondary" color="danger" onClick={onRemove}>
                        {t('downtime_period_dialog.cta.remove')}
                    </Button>
                    <Box flexGrow={1} />
                </>
            )}
            <Button ctaType="tertiary" color="primary" onClick={onCancel}>
                {t('cta.cancel')}
            </Button>
            <Button ctaType="primary" color="primary" disabled={saveDisabled} onClick={onSave}>
                {saveText}
            </Button>
        </>
    );
}
