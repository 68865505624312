import { ReactElement } from 'react';

import Icon from 'app/components/primitives/Icon';

import { TranslateFunction, useI18n } from 'i18n';

import Metric from './Metric';

interface Props {
    /**
     * The MPU power / run state selection
     */
    value?: string;
}

function resolveLabel(mode: string | undefined, { t }: { t: TranslateFunction }): string {
    if (mode == null) return t('unknown');

    switch (mode) {
        case 'unknown':
            return t('unknown');
        default:
            return t(`mode_selection_state.${mode}`);
    }
}

function resolveIcon(mode?: string): ReactElement | null {
    if (mode == null) return null;

    switch (mode) {
        case 'unknown':
            return null;
        case 'off':
            return <Icon name="power" color="disabled" />;
        case 'run':
            return <Icon name="run" />;
        case 'on_standby':
        /* falls through */
        default:
            return <Icon name="power" />;
    }
}

/**
 * Responsible for representing the MPUs run state selection
 */
export default function RunMode({ value }: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Metric
            variant="card"
            label={t('unit_status.run_mode_label')}
            icon={resolveIcon(value)}
            value={resolveLabel(value, { t })}
        />
    );
}
