import { useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';

import { RenterReservationRequestModal, Text } from 'app/components';
import { useLayer } from 'app/core/layers';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useRequestReservation } from 'app/core/Reservation';
import { useSession } from 'app/core/Session';

import { useI18n } from 'i18n';

export default function RenterReservationRequestDialog() {
    const { t } = useI18n();
    const navigate = useNavigate();
    const { toast } = useLayer();
    const { primaryAccountID: accountID, id: userID } = useSession().getSession();

    const requestReservation = useRequestReservation({ shouldRefetchActiveQueries: true });

    const submitReservationRequest = async ({ value }) => {
        try {
            await requestReservation(value);

            toast.add({
                severity: 'success',
                message: (
                    <Text
                        renderLink={({ text }) => (
                            <Link
                                component={RouterLink}
                                to={getPathByName('CUSTOMER_RESERVATIONS', { search: { tab: 'upcoming' } })}
                            >
                                {text}
                            </Link>
                        )}
                    >
                        {t('renter_reservation_form.request_success_message')}
                    </Text>
                ),
            });
        } catch (error) {
            toast.add({
                severity: 'error',
                message: <Text richText>{t('renter_reservation_form.request_error_message')}</Text>,
            });

            throw error;
        }
    };

    const closeRequestReservationForm = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <RenterReservationRequestModal
            userID={userID}
            accountID={accountID}
            open
            onClose={closeRequestReservationForm}
            onRequestReservation={submitReservationRequest}
        />
    );
}
