import React, { useId } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import Checkbox from 'app/components/primitives/interactive/Checkbox';
import { useInputForAutocomplete } from 'app/components/primitives/interactive/Input';

import { useI18n } from 'i18n';

interface MPU {
    id: string;
    name: string;
}

interface MPUSelectorProps {
    mpus: MPU[];
    value: MPU;
    onChange: (value: MPU) => void;
    name?: string;
}

const MPUSelector: React.FC<MPUSelectorProps> = ({ mpus, value, onChange, name, ...otherProps }) => {
    const id = useId();
    const idAttribute = `mpu-selector-${id}`;
    const { t } = useI18n();

    const renderInput = useInputForAutocomplete({
        name,
        fieldName: name || t('documents.components.mpu'),
    });

    return (
        <Autocomplete
            options={mpus}
            id={idAttribute}
            freeSolo
            autoSelect
            value={value?.name}
            renderInput={renderInput}
            renderOption={(props, option, { selected }) => (
                <Box component="li" key={option.id} {...props}>
                    <Checkbox as="div" checked={selected} label={option.name} />
                </Box>
            )}
            onChange={(e, newValue) => {
                const isOption = newValue && typeof newValue === 'object';
                const isString = typeof newValue === 'string';

                // Clearing input
                if (newValue === null) {
                    onChange({ id: '', name: '' });
                    return;
                }

                // Setting from Options
                if (isOption) {
                    const selectedMPU = mpus.find(mpu => mpu.id === newValue.id);

                    if (selectedMPU) {
                        onChange(selectedMPU);
                    }

                    return;
                }

                // Custom Input
                if (isString) {
                    onChange({ id: '', name: newValue });
                }
            }}
            {...otherProps}
        />
    );
};

export default MPUSelector;
