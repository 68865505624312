import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

import batteryMaskUrl from 'app/assets/images/battery-mask.png';

import CSSDimension from 'design-system/CSSDimension';

import { useI18n } from 'i18n';

import Layer from '../layout/Layer';
import Text from '../Text';

interface Props extends BoxProps {
    /**
     * The value
     */
    value?: number | null | undefined;

    /**
     * The total to display the value as a propotion of (defaults to 100)
     */

    max?: number | null | undefined;

    /**
     * Element id to associate with the progress. This will be read aloud to screen readers with the value.
     * The progress should be directly labelled with ariaLabel if this is left empty.
     */
    ariaLabelledby?: string;

    /**
     * Text to associate with the progress. This will be read aloud to screen readers with the value
     * The progress should be labelled with ariaLabelledby if this is left empty.
     */
    ariaLabel?: string;

    /**
     * The presentational variant for the Progress component. "standard" is a simple bar and "charge" renders progress as a battery
     */
    variant?: 'charge' | 'standard';

    /**
     * Whether the progress is in an error state
     */
    error?: boolean;
}

/**
 * Responsible for representing progress in a line
 */
export default function Progress({
    variant = 'standard',
    value,
    max = 100,
    ariaLabel,
    ariaLabelledby,
    error,
    ...props
}: Props): ReactElement {
    const { format } = useI18n();
    const theme = useTheme();

    const transitionDuration = 400;
    const _max = max ?? 0;

    const fraction = Math.min(value ?? 0, _max) / _max;

    const chargeProgressStyleOverrides =
        variant === 'charge'
            ? {
                  maskImage: `url(${batteryMaskUrl})`,
                  maskSize: '100% 100%',

                  height: CSSDimension.fromPixels(60).as('rem'),
                  width: 226,
                  maxWidth: '100%',
              }
            : null;
    const borderRadius = variant === 'charge' ? null : '999px';

    return (
        <Box {...props}>
            <Layer
                anchor
                width="100%"
                height={8}
                role="progressbar"
                aria-valuemax={_max}
                aria-valuenow={value ?? 0}
                aria-labelledby={ariaLabelledby}
                aria-label={ariaLabel}
                sx={chargeProgressStyleOverrides}
            >
                <Box
                    width="100%"
                    sx={{
                        height: '100%',
                        backgroundColor: 'background.secondaryDark.main',
                        borderRadius: borderRadius,
                    }}
                />

                <Layer
                    relAnchorX="left"
                    x="left"
                    width="100%"
                    height="100%"
                    sx={{ overflow: 'hidden', borderRadius: borderRadius }}
                >
                    <Box
                        width="100%"
                        sx={{
                            height: '100%',
                            backgroundColor: error
                                ? theme.palette.background.dangerDark.main
                                : theme.palette.brand.accent,
                            borderRadius: borderRadius,
                            transition: `transform ${transitionDuration}ms ease`,
                            transform: `translateX(${-(1 - fraction) * 100}%)`,
                            transformOrigin: 'top right',
                        }}
                    />
                </Layer>

                {variant === 'charge' && (
                    <Layer>
                        <Text variant="dataSmall">{format.decimal(fraction * 100)}%</Text>
                    </Layer>
                )}
            </Layer>
        </Box>
    );
}
