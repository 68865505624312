import { ReactElement, useId } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';

import { Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { FAULT_SEVERITIES } from '../config';

interface Props {
    /**
     * The severity being displayed
     */
    severity?: (typeof FAULT_SEVERITIES)[number];

    /**
     * All of the active faults for a given severity
     */
    faults?: any;
}

const BULLET_UNICODE = '\u25CF';

/**
 * Responsible for displaying the list of active faults for a given severity
 */
export default function ActiveFaults({ severity, faults }: Props): ReactElement {
    const { t, format } = useI18n();
    const listLabelId = useId();

    return (
        <Box key={`severity-label-${severity}`}>
            <Text id={listLabelId} as="div" variant="bodyMedium" mb={1}>
                {t(`fault_severity.${severity?.toLowerCase()}`)} ({faults?.length})
            </Text>

            <List disablePadding aria-labelledby={listLabelId}>
                {faults.map(x => (
                    <ListItem key={`active-fault-at-time-${x.name}`} disablePadding>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Text variant="detail">{BULLET_UNICODE}</Text>
                            <Stack>
                                <Text as="div" variant="detail">
                                    {t(`device_fault.${x.name.toLowerCase()}.name`)}
                                </Text>

                                <Text as="div" variant="detail" color="secondary">
                                    {x.resolved
                                        ? format.dateRangeSentence({
                                              start: x.firstSeen,
                                              end: x.resolved,
                                          })
                                        : format.dateTime(x.firstSeen)}

                                    {typeof x.duration === 'number' &&
                                        ` (${format.durationShort(x.duration).toString()})`}
                                </Text>
                            </Stack>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}
