import { ForwardedRef, forwardRef, ReactElement } from 'react';
import get from 'lodash/get';
import { Paths } from 'types';

import { Theme, Typography, TypographyProps } from '@mui/material';

import { normalizeSxProp } from 'app/components/normalizeSxProp';

import TextWithMarkup from './TextWithMarkup';
import TranslationWithLinks from './TranslationWithLinks';

export interface TextProps extends TypographyProps {
    /**
     * Used to override the default element that is rendered
     */
    as?: React.ElementType | null;
    component?: React.ElementType;

    /**
     * Attribute to associate label text to a form input, do not
     * forget to set the "as" prop to "label"
     */
    htmlFor?: string;

    /**
     * The maximum number of lines to show. Any text content that exceeds the space
     * will be truncated
     */
    maxLines?: number;

    /**
     * The number of lines to preserve height for (default: 0)
     */
    preserveHeight?: number;

    /**
     * Whether or not to cloak the content. Will preserve space of any children and hide them. Useful when using
     * Text as a spacer to align content.
     */
    cloak?: boolean;

    /**
     * Whether the text to be displayed contains html tags
     */
    richText?: boolean;

    /**
     * Text color to apply
     */
    color?: keyof Theme['palette']['text'] | 'inherit' | Paths<Theme['palette']>;

    /**
     * Render function to be called on all encountered links
     */
    renderLink?: (linkMeta: { id: string; text: string }) => ReactElement;
}

function makeTruncationStyles(maxLines) {
    if (!maxLines) return {};

    return {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: `${maxLines}`,
        WebkitBoxOrient: 'vertical',
        lineBreak: 'anywhere',
        whiteSpace: 'initial',
    };
}

function isVariantInline(variant) {
    return !/^h/i.test(variant);
}

const Text = forwardRef((props: TextProps, ref: ForwardedRef<HTMLElement>): ReactElement => {
    const {
        as,
        component,
        variant = 'body',
        fontWeight,
        fontStyle,
        maxLines,
        richText,
        renderLink,
        children,
        preserveHeight = 0,
        cloak = false,
        display,
        color = 'inherit',
        sx,
        ...propsForTypography
    } = props;
    let resolvedFontWeight = fontWeight;
    let resolvedFontStyle = fontStyle;

    const elementType = as || component;
    const componentProp = elementType ? { component: elementType } : {};

    const hasTextContent = typeof children === 'string';
    const hasRenderLink = typeof renderLink === 'function' && hasTextContent;
    const hasRichText = !hasRenderLink && !!richText && hasTextContent;
    const hasBasicContent = !hasRenderLink && !hasRichText;
    const isMaxLinesSet = typeof maxLines === 'number';

    return (
        <Typography
            aria-hidden={cloak}
            sx={normalizeSxProp(sx).concat([
                theme => ({
                    color: theme.palette.text[color] || get(theme.palette, color),
                    display: (preserveHeight ?? 0) > 0 && isVariantInline(variant) ? 'inline-flex' : display,
                    ...makeTruncationStyles(maxLines),
                    visibility: cloak ? 'hidden' : null,
                    width: cloak ? 'fit-content' : null,
                    minHeight:
                        (preserveHeight ?? 0) > 0 ? `${theme.typography[variant].lineHeight * preserveHeight}em` : null,
                    maxHeight: isMaxLinesSet ? `${theme.typography[variant].lineHeight * maxLines}em` : null,
                }),
            ])}
            {...propsForTypography}
            {...componentProp}
            variant={variant}
            fontWeight={resolvedFontWeight}
            fontStyle={resolvedFontStyle}
            ref={ref}
        >
            {hasRenderLink && <TranslationWithLinks text={children} renderLink={renderLink} />}

            {hasRichText && <TextWithMarkup text={children} />}

            {hasBasicContent && children}
        </Typography>
    );
});

export default Text;
