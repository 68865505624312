import SignalStrength from 'app/components/widgets/telemetry/UnitStatus/SignalStrength';
import { CellSignalBar } from 'app/core/data';

import { useI18n } from 'i18n';

import { Tooltip } from '../Tooltip';

export function MPUTileCellSignalIcon({ cellSignalBars }: { cellSignalBars: CellSignalBar | undefined }) {
    const { t } = useI18n();

    if (cellSignalBars === undefined) {
        return null;
    }

    const tooltipContent =
        cellSignalBars === CellSignalBar.Disconnected
            ? t('mpu_tile.cell_signal.not_connected')
            : t('mpu_tile.cell_signal.connected');

    return (
        <Tooltip content={tooltipContent}>
            <SignalStrength numberOfBars={cellSignalBars} />
        </Tooltip>
    );
}
