import { ComponentProps } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import { useListMpuExternalIDsForSelectorQuery } from 'generated/graphql';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {
    ownerAccountIDs: string[];
}

const MultiMPUSelector = ({ ownerAccountIDs, ...props }: Props) => {
    const { data } = useListMpuExternalIDsForSelectorQuery({ variables: { ownerAccountIDs } });

    const options =
        data?.listDeviceInstances?.edges
            .filter(({ node }) => typeof node.externalID === 'string' && node.externalID.length > 0)
            .map(({ node }) => ({ label: node.externalID, id: node.externalID })) || [];

    // @ts-ignore
    return <MultiSelect {...props} label="Serial Number" placeholder="Select..." options={options} />;
};

export default MultiMPUSelector;
