import { Cluster, Icon } from 'app/components';
import IconAndText from 'app/components/primitives/layout/IconAndText';

interface ReservationSubtitleProps {
    accountName?: string | null;
    userName?: string | null;
    contactPhone?: string | null;
    email?: string | null;
}

export default function ReservationSubtitle({ accountName, userName, contactPhone, email }: ReservationSubtitleProps) {
    return (
        <Cluster gap={4} rowGap={2}>
            {accountName && <IconAndText icon={<Icon name="business" size="sm" />} text={accountName} />}
            {userName && <IconAndText icon={<Icon name="person" size="sm" />} text={userName} />}
            {contactPhone && <IconAndText icon={<Icon name="call" size="sm" />} text={contactPhone} />}
            {email && <IconAndText icon={<Icon name="mail" size="sm" />} text={email} />}
        </Cluster>
    );
}
