import { ComponentProps } from 'react';

import { useUnitAssignmentDeviceInstances } from './hooks';
import { UnitAssignmentSchedulePresenter } from './UnitAssignmentSchedulePresenter';

interface UnitAssignmentScheduleContainerProps
    extends Omit<ComponentProps<typeof UnitAssignmentSchedulePresenter>, 'deviceInstances'> {
    /**
     * A callback to be invoked when the assignment is cancelled.
     */
    onCancel?: () => void;
    /**
     * The account owner of the available units
     */
    ownerAccountID: string;
}

export function UnitAssignmentScheduleContainer({
    end,
    onCancel,
    ownerAccountID,
    start,
    ...otherProps
}: UnitAssignmentScheduleContainerProps) {
    const { deviceInstances, handleItemsRendered, handleSearchChange, loading } = useUnitAssignmentDeviceInstances({
        end,
        ownerAccountID,
        start,
    });

    return (
        <UnitAssignmentSchedulePresenter
            deviceInstances={deviceInstances}
            end={end}
            loading={loading}
            onCancel={onCancel}
            onItemsRendered={handleItemsRendered}
            onSearchChange={handleSearchChange}
            start={start}
            {...otherProps}
        />
    );
}
