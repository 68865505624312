import { ComponentProps, forwardRef, ReactElement, Ref } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ButtonBase from '@mui/material/ButtonBase';
import MuiLink from '@mui/material/Link';

type LinkProps = ComponentProps<typeof MuiLink> & ComponentProps<typeof RouterLink>;

interface Props extends Omit<LinkProps, 'to' | 'color'> {
    to?: LinkProps['to'];

    color?: 'inherit' | 'primary' | 'danger';
}

function resolveColor(color) {
    if (color === 'danger') return 'error';

    return color;
}

const ButtonLink = forwardRef(function ButtonLink(props, ref: Ref<HTMLButtonElement>) {
    return (
        <ButtonBase
            ref={ref}
            sx={{ textAlign: 'inherit' }}
            disableRipple
            disableTouchRipple
            focusRipple={false}
            {...props}
        ></ButtonBase>
    );
});

/**
 * A convenience component that composes the MUI and React Router components together.
 */
export const Link = forwardRef(function Link(
    { children, color, to, type = 'button', ...otherProps }: Props,
    ref: Ref<any>,
): ReactElement {
    const component = !!to ? RouterLink : ButtonLink;

    return (
        <MuiLink ref={ref} {...otherProps} type={type} to={to ?? ''} color={resolveColor(color)} component={component}>
            {children}
        </MuiLink>
    );
});
