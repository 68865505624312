import { ComponentProps, ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';

import { Button, ModalDialog } from 'app/components';
import { UnitUpdateInput } from 'app/core/data/unit/types';

import { useI18n } from 'i18n';

import UpdateUnitForm from './EditMPUDetailsForm';

interface Props {
    /**
     * Unique identifier for the dialog for associating the
     * controlling button or other interactive UI
     */
    id?: string;

    /**
     * Unique identifier representing the account for
     */
    accountID?: string;

    /**
     * Whether the dialog should be open or not
     */
    open: boolean;

    /**
     *
     */
    unit: UnitUpdateInput;

    /**
     * Event handler to be callback when tthe dialog shoudl be closed
     */
    onClose: () => void;

    /**
     * Event handler called when user submits the form
     */
    onChange: NonNullable<ComponentProps<typeof UpdateUnitForm>['onSave']>;
}

/**
 * Displays the MPU provisioning form in a dialog
 */
export default function EditMPUDetailsDialog({
    id,
    accountID,
    unit,
    open = false,
    onClose,
    onChange,
}: Props): ReactElement {
    const { t } = useI18n();

    const _t = (key: string): string => t(`admin_unit_page.${key}`);

    const dialogTitleID = useId();
    const formID = useId();
    const [isSaveCTADisabled, setIsSaveCTADisabled] = useState<boolean>(false);

    return (
        <ModalDialog
            id={id}
            open={open}
            onClose={onClose}
            aria-labelledby={dialogTitleID}
            title={<Box id={dialogTitleID}>{_t('edit_mpu_details_dialog_title')}</Box>}
            primaryCTA={
                <Button form={formID} type="submit" disabled={isSaveCTADisabled}>
                    {_t('cta.save_changes')}
                </Button>
            }
        >
            <UpdateUnitForm
                id={formID}
                hideSaveCTA
                layout="compact"
                unit={unit}
                accountID={accountID}
                onSave={async values => {
                    await onChange(values);
                    onClose();
                }}
                onFormStateChange={({ isSaveCTADisabled }) => setIsSaveCTADisabled(isSaveCTADisabled)}
            />
        </ModalDialog>
    );
}
