import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import escapeRegExp from 'lodash/escapeRegExp';

import { debounce } from '@mui/material';

import { MultiGridRenderStats } from 'app/components/compounds/MultiGrid';
import { useToastErrorHandler } from 'app/core/error';
import { DateTimeSpan, ISO8601 } from 'app/core/types';

import {
    GetMpuIndexScheduleDeviceInstanceDocument,
    GetMpuIndexScheduleDeviceInstanceQuery,
    GetMpuIndexScheduleDeviceInstanceQueryVariables,
    MpuIndexScheduleDeviceInstanceFragment,
    useGetMpuIndexScheduleDeviceInstanceListQuery,
} from 'generated/graphql';

import { useI18n } from 'i18n';

import { deviceInstanceFragmentToDeviceInstanceWithSchedules, DeviceInstanceWithSchedules } from '../MpuSchedule';

/**
 * Returns a function to retrieve one MPU with schedules on demand.
 * Errors are handled downstream.
 */
export function useMpuIndexScheduleDeviceInstanceRequest() {
    const { t } = useI18n();
    const client = useApolloClient();

    return useCallback(
        async (variables: GetMpuIndexScheduleDeviceInstanceQueryVariables): Promise<DeviceInstanceWithSchedules> => {
            const result = await client.query<
                GetMpuIndexScheduleDeviceInstanceQuery,
                GetMpuIndexScheduleDeviceInstanceQueryVariables
            >({
                query: GetMpuIndexScheduleDeviceInstanceDocument,
                variables,
            });

            return deviceInstanceFragmentToDeviceInstanceWithSchedules(result.data.getDeviceInstance, { t });
        },
        [client, t],
    );
}

/**
 * Retrieves a list of all MPUs with the minimum properties to accomplish an initial render
 */
function useDeviceInstanceList() {
    const { data, error, loading, refetch } = useGetMpuIndexScheduleDeviceInstanceListQuery({
        variables: { filters: { ownerAccountIDs: [] } },
    });

    const list = useMemo((): MpuIndexScheduleDeviceInstanceFragment[] => {
        return data?.listDeviceInstances.edges.map(({ node }) => node) || [];
    }, [data]);

    return {
        data: list,
        error,
        loading,
        refetch,
    };
}

/**
 * Lazily retrieves device instances with schedules.
 *
 * _This hook:_
 *
 * 1. Retrieves a list of all MPUs with the minimum properties to accomplish an initial render
 * 2. Retrieves additional MPU details on demand
 */
function useDeviceInstancesLazy({ end, start }: DateTimeSpan) {
    const { t } = useI18n();
    const { data: deviceInstanceList, error, loading, refetch: refetchMpuList } = useDeviceInstanceList();
    const getDeviceInstance = useMpuIndexScheduleDeviceInstanceRequest();

    /**
     * An object to capture the loaded device instances to be reassembled later.
     *
     * TODO(Morris): Explore writing a local-only data query for this instead of writing it to component state.
     */
    const [deviceInstanceDictionary, setDeviceInstanceDictionary] = useState<
        Record<DeviceInstanceWithSchedules['id'], DeviceInstanceWithSchedules | undefined>
    >({});

    /**
     * An object to keep track of which device instances have been loaded.
     * It doesn't cache any data, but simply avoids unnecessarily invoking Apollo.
     *
     * TODO(Morris): Explore relying only Apollo's `cache-first` policy instead of checking for invocations.
     */
    const loadedDeviceInstancesRef = useRef<Record<DeviceInstanceWithSchedules['id'], true>>({});

    // Reset the loaded data if the request parameters or list is re-fetched.
    useEffect(() => {
        setDeviceInstanceDictionary({});
        loadedDeviceInstancesRef.current = {};
    }, [deviceInstanceList, end, start]);

    /**
     * Lazy load a single device instance and schedules.
     * Errors are handled downstream.
     */
    const loadDeviceInstance = useCallback(
        async (id: string, refetch = false) => {
            const hasLoaded = loadedDeviceInstancesRef.current[id];

            if (hasLoaded && !refetch) return;

            loadedDeviceInstancesRef.current[id] = true;

            const deviceInstance = await getDeviceInstance({
                end,
                id,
                start,
            });

            setDeviceInstanceDictionary(dict => ({
                ...dict,
                [deviceInstance.id]: deviceInstance,
            }));
        },
        [end, getDeviceInstance, start],
    );

    /**
     * Load the schedules for the given MPU only, caching the result.
     */
    const loadMpuSchedules = useCallback((mpuID: string) => loadDeviceInstance(mpuID), [loadDeviceInstance]);

    /**
     * Reload the schedules for the given MPU only.
     */
    const reloadMpuSchedules = useCallback((mpuID: string) => loadDeviceInstance(mpuID, true), [loadDeviceInstance]);

    /**
     * The original device instance list reassembled with lazy loaded data.
     */
    const deviceInstances = useMemo((): DeviceInstanceWithSchedules[] => {
        if (!deviceInstanceList) return [];

        return deviceInstanceList.map((deviceInstancePartial): DeviceInstanceWithSchedules => {
            const deviceInstance = deviceInstanceDictionary[deviceInstancePartial.id];

            if (deviceInstance) return deviceInstance;

            return deviceInstanceFragmentToDeviceInstanceWithSchedules(
                {
                    ...deviceInstancePartial,
                    latestTelemetry: {
                        telemetryPoints: [],
                    },
                },
                { t },
            );
        }, []);
    }, [deviceInstanceDictionary, deviceInstanceList, t]);

    return {
        data: deviceInstances,
        error,
        loading,
        loadMpuSchedules,
        refetchMpuList,
        reloadMpuSchedules,
    };
}

/**
 * Creates an array of integers from a given range of number.
 * Results are inclusive.
 */
function getIntegerRange(start: number, end: number): number[] {
    return new Array(end - start + 1).fill(undefined).map((_value, index) => index + start);
}

/**
 * Retrieves device instances with schedules based on the state of the schedule component.
 * It's responsible for handling communication between `MpuIndexSchedule` and `useDeviceInstancesLazy`.
 *
 * _This hook:_
 *
 * 1. Retrieves a list of all MPUs with the minimum properties to accomplish a first render
 * 2. Sorts the list using the given `sort` function
 * 3. Retrieves MPU details and schedules based on the rows that are rendered in the view
 */
export function useMpuIndexScheduleDeviceInstances({
    end,
    sort,
    start,
}: {
    end: ISO8601;
    sort?: (list: DeviceInstanceWithSchedules[]) => DeviceInstanceWithSchedules[];
    start: ISO8601;
}) {
    const { t } = useI18n();
    const { data, loading, loadMpuSchedules, refetchMpuList, reloadMpuSchedules } = useDeviceInstancesLazy({
        end,
        start,
    });
    const [searchValue, setSearchValue] = useState('');
    const handleError = useToastErrorHandler();

    const deviceInstances = useMemo((): DeviceInstanceWithSchedules[] => {
        const sortedData = sort?.(data) ?? data;
        const searchPattern = new RegExp(escapeRegExp(searchValue), 'ig');

        return sortedData.filter(({ name }) => searchPattern.test(name));
    }, [data, searchValue, sort]);

    const handleItemsRendered = useMemo(
        () =>
            // Add debounce to wait for scrolling to stop before requesting data.
            debounce(async ({ overscanRowStartIndex, overscanRowStopIndex }: MultiGridRenderStats) => {
                const renderedRowIndexes = getIntegerRange(overscanRowStartIndex, overscanRowStopIndex);

                try {
                    // Simply attempt to make all of the requests at once,
                    // and catch them all at once.
                    // The browser will throttle requests without us doing anything.
                    //
                    // If the end-user is viewing the schedule with a large height,
                    // like a vertically rotated monitor, then they'll send more requests,
                    // than someone on a laptop.
                    await Promise.all(
                        renderedRowIndexes.map(async rowIndex => {
                            const deviceInstance = deviceInstances.at(rowIndex);

                            if (!deviceInstance) return;

                            await loadMpuSchedules(deviceInstance.id);
                        }),
                    );
                } catch (error) {
                    handleError(error, t('mpu_index_schedule.error.load_mpu_failure'));
                }
            }, 300),
        [deviceInstances, handleError, loadMpuSchedules, t],
    );

    return {
        deviceInstances,
        handleItemsRendered,
        handleSearchChange: setSearchValue,
        loading,
        refetchMpuList,
        reloadMpuSchedules,
    };
}
