import {
    cloneElement,
    ComponentProps,
    ElementType,
    forwardRef,
    isValidElement,
    MouseEvent,
    ReactElement,
    ReactNode,
} from 'react';

import { darken, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import CSSDimension from 'design-system/CSSDimension';

import { MUIMarginProps } from 'styles/theme/types';

import Icon from '../Icon';
import { Link } from '../Link';
import Text from '../Text';

interface Props extends MUIMarginProps {
    /**
     * Screen reader label
     */
    'aria-label'?: string;

    /**
     * Text content for the Tag
     */
    label: ReactNode;

    /**
     * The url to navigate to if clicked
     */
    to?: string;

    /**
     * Optional icon to apply to the front of the Tag
     */
    startIcon?: ReactElement<Pick<ComponentProps<typeof Icon>, 'size' | 'color'>>;

    /**
     * Optional icon to apply to the end of the Tag
     */
    endIcon?: ReactElement<Pick<ComponentProps<typeof Icon>, 'size' | 'color'>>;

    /**
     * The name / key describing the value / selected state
     */
    name?: string;

    /**
     * The selected state of the Tag (when usedas a ToggleButton)
     */
    selected?: boolean;

    /**
     * The supported variations in color
     */
    color?: 'normal' | 'info' | 'success' | 'warning' | 'danger';

    /**
     * Whether the tag should be outlined
     */
    outlined?: boolean;

    /**
     * Whether the tag should appear disabled and not be clickable
     */
    disabled?: boolean;

    /**
     * Event handler to be called when the user clicks on the Tag
     */
    onClick?: (event: MouseEvent) => void;

    /**
     * Event handler to be called when the toggle should change
     */
    onChange?: (event: { name?: string; value: boolean }) => void;
}

/**
 * Implements the Tag component from our Design System, similar to MUI's Chip component or Bootstraps Badge component. Used to label content
 * or as a toggle button or even a link
 * @link {Figma Designs for Tag | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=7-3874&mode=dev}
 */
export default forwardRef(function Tag(
    {
        label,
        name,
        to,
        selected,
        color = 'info',
        outlined = false,
        disabled = false,
        startIcon,
        endIcon,
        onChange,
        onClick,
        ...marginProps
    }: Props,
    ref,
): ReactElement {
    const isToggle = typeof selected === 'boolean' && typeof onClick === 'function';
    const _selected = isToggle ? selected : true;
    const X: ElementType = to || onClick || isToggle ? Link : Box;

    function resolveBackgroundColor(palette: Theme['palette']) {
        if (disabled) return palette.background.disabled.main;

        if (isToggle && selected && color === 'normal') return palette.action.selected;

        if ((!_selected && outlined) || color === 'normal') return palette.background.primary.main;

        if (!_selected && !outlined) return palette.background.disabled.main;

        if (_selected && outlined) return palette.background[color].main;

        return palette.background[`${color}Dark`].main;
    }

    function resolveBorderColor(palette: Theme['palette']) {
        if (outlined && (!_selected || disabled || color === 'normal')) return palette.border.light;

        if (outlined) return palette.border[color];

        return 'rgba(0,0,0,0)';
    }

    function resolveHoverColor(palette: Theme['palette']) {
        if (disabled || !onClick) return null;

        if (!_selected || color === 'normal') return palette.action.hover;
        if (_selected && outlined) return palette.background[color].dark;

        return palette.background[`${color}Dark`].dark;
    }

    function resolveFocusColor(palette: Theme['palette']) {
        if (disabled || !onClick) return null;

        return darken(resolveBackgroundColor(palette) ?? '', 0.1);
    }

    function resolveTextColor():
        | 'disabled'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'danger'
        | 'background.infoDark.contrastText'
        | 'background.successDark.contrastText'
        | 'background.warningDark.contrastText'
        | 'background.dangerDark.contrastText' {
        if (disabled) return 'disabled';

        if (!_selected || color === 'normal') return 'secondary';
        if (_selected && outlined) return color;

        return `background.${color}Dark.contrastText`;
    }

    return (
        <Box {...marginProps} sx={{ display: 'inline-flex' }}>
            <X
                ref={ref}
                to={to}
                underline="none"
                disabled={disabled}
                aria-pressed={selected}
                onClick={(event: MouseEvent): void => {
                    onClick?.(event);
                    onChange?.({ name, value: !selected });
                }}
                sx={(theme: Theme) => ({
                    display: 'inline-flex',
                    boxSizing: 'border-box',
                    minWidth: CSSDimension.fromPixels(30).as('rem'),

                    // Prevents buttons to be stretched in a flexbox (align-items: stretch) along with the above wrapping box
                    alignSelf: 'flex-start',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: resolveBackgroundColor(theme.palette),
                    borderColor: resolveBorderColor(theme.palette),
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: theme.shape.pillBorderRadius,
                    py: 1,
                    px: 2,
                    gap: 1,
                    transition: 'background-color 400ms ease',

                    ':hover': { backgroundColor: resolveHoverColor(theme.palette) },
                    ':focus': {
                        backgroundColor: resolveFocusColor(theme.palette),
                        outlineOffset: -1,
                        outlineWidth: 2,
                        outlineStyle: 'solid',
                        // MUI doesn't resolve palette colors for outlines, so we're passing back the resolved CSS color value :/
                        outlineColor: resolveBorderColor(theme.palette),
                    },
                })}
            >
                {isValidElement(startIcon) &&
                    cloneElement(startIcon, {
                        size: 'sm',
                        color: resolveTextColor(),
                    })}

                <Text variant={!_selected ? 'detail' : 'detailMedium'} color={resolveTextColor()} maxLines={1}>
                    {label}
                </Text>

                {isValidElement(endIcon) &&
                    cloneElement(endIcon, {
                        size: 'sm',
                        color: resolveTextColor(),
                    })}
            </X>
        </Box>
    );
});
