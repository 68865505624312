import { ComponentProps, ReactElement, useCallback, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import googleLogo from 'app/assets/images/google_on_white_hdpi.png';
import HighlightMatches from 'app/components/primitives/HighlightMatches';
import Icon from 'app/components/primitives/Icon';
import Text from 'app/components/primitives/Text';

import { gmapsApiKey } from 'environment';

import { useI18n } from 'i18n';

import Input, { useInputForAutocomplete } from '../../primitives/interactive/Input';

/**
 * Related fields from our AccountLocation model as sourced from google places
 */
interface Location {
    /**
     * The full formatted address including all parts as a single string
     */
    formattedAddress: string;

    /**
     * The latitudinal coordinate for the location
     */
    lat?: number;

    /**
     * The longitudinal coordinate for the location
     */
    lon?: number;

    /**
     * The location / place service provider
     */
    placeProvider: 'GooglePlaces';

    /**
     * The unique id for a given place
     * Note: observationally, two places with nearly identical addresses can result in
     *    two different placeIds. I encountered this when comparing placeIDs returned from
     *    getDetails and getPlacePredictions
     */
    placeID?: string;
}

interface Props extends Omit<ComponentProps<typeof Input>, 'onChange' | 'value' | 'select' | 'children'> {
    /**
     * Whether to fill the parent element or not
     */
    fullWidth?: boolean;

    /**
     * Unique identifier for the input field
     */
    id?: string;

    /**
     * The key to associate the user entered value with
     */
    name: string;

    /**
     * The selected placeID
     */
    value: string | undefined;

    /**
     * The event handler to be called when the selected place changes
     */
    onChange: (event: { value: Location }) => void;
}

type PlacesService = google.maps.places.PlacesService;
type PlaceDetail = Pick<google.maps.places.PlaceResult, 'geometry' | 'place_id' | 'formatted_address'>;

const nullPlace = {
    placeID: undefined,
    formattedAddress: '',
    lat: undefined,
    lon: undefined,
    placeProvider: 'GooglePlaces',
} as const;

/**
 * Responsible for adding attribution to the places search results per googles terms of use
 * @link {places attribution policy | https://developers.google.com/maps/documentation/places/web-service/policies#logo}
 */
const OptionsContainerWithAttribution: ComponentProps<typeof Autocomplete>['PaperComponent'] = ({
    children,
    ...props
}): ReactElement => {
    const { t } = useI18n();

    return (
        <Paper {...props}>
            {children}

            <Box display="flex" alignItems="baseline" pb={2} px={4} gap={1} justifyContent="flex-end">
                <Text variant="detail">{t('address_input.attribution_slogan')}</Text>
                <Box sx={{ height: 18, '> img': { height: '100%', aspectRatio: '119 / 36' } }}>
                    <img src={googleLogo} alt={t('address_input.google_logo_description')} />
                </Box>
            </Box>
        </Paper>
    );
};

/**
 * Transforms a google Place (detail) to our Location model
 */
function toLocation(googlePlaceDetails: PlaceDetail | null): Location {
    return {
        formattedAddress: googlePlaceDetails?.formatted_address ?? '',
        lat: googlePlaceDetails?.geometry?.location?.lat(),
        lon: googlePlaceDetails?.geometry?.location?.lng(),
        placeProvider: 'GooglePlaces',
        placeID: googlePlaceDetails?.place_id,
    };
}

/**
 * Responsible for providing a method for typing and selecting an address powered by google places API
 */
export default function AddressInput({
    id,
    disabled,
    readOnly,
    valid,
    error,
    fieldName,
    caption,
    fullWidth = false,
    required,
    name,
    value,
    onChange,
}: Props): ReactElement {
    const { t, language } = useI18n();

    const [selectedPlace, setSelectedPlace] = useState<Location | null>(null);

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: gmapsApiKey(),
        options: {
            language,
            types: ['address'],

            componentRestrictions: { country: 'us' },
            input: '',
        },
    });
    const renderInput = useInputForAutocomplete({
        fullWidth,
        name,
        startAdornment: <Icon name="location" />,
        endAdornment: null,
        disabled,
        readOnly,
        valid,
        error,
        fieldName,
        caption,
        required,

        onChange: evt => {
            getPlacePredictions({ input: evt.target.value });
        },
    });

    /**
     * Google Service SDK is lazy loaded inside of react-google-autocomplete and may not be immediately available
     */
    const selectedPlaceID = selectedPlace?.placeID;
    const [isGoogleSDKAvailable, setIsGoogleSDKAvailable] = useState<boolean>(!!placesService);

    const getDetails = useCallback(
        (options: Parameters<PlacesService['getDetails']>[0], callback: Parameters<PlacesService['getDetails']>[1]) => {
            placesService?.getDetails(options, callback);
        },
        [placesService],
    );
    const getPlaceDetails = useCallback(
        function (placeID: string): Promise<Location> {
            return new Promise((resolve, reject) => {
                getDetails(
                    {
                        placeId: placeID,
                        fields: ['geometry.location', 'place_id', 'formatted_address'],
                    },
                    (placeDetails, serviceStatus) => {
                        if (!/^ok$/i.test(serviceStatus)) {
                            return reject(
                                new Error(
                                    `Encountered an error will retrieving the details for place ${placeID} (${serviceStatus})`,
                                ),
                            );
                        }

                        resolve(toLocation(placeDetails));
                    },
                );
            });
        },
        [getDetails],
    );

    // usePlacesService does not rerender when the google SDK is available after load so we have to check
    // if it's available ourself
    useEffect(() => {
        if (isGoogleSDKAvailable) return;

        const intervalID = setInterval(() => {
            if (window.google) {
                setIsGoogleSDKAvailable(true);
                clearInterval(intervalID);
            }
        }, 250);

        return () => {
            clearInterval(intervalID);
        };
    }, [isGoogleSDKAvailable]);

    /**
     * Handle initial values and external changes to the value / placeID
     */
    useEffect(() => {
        if (isGoogleSDKAvailable && !!value && value !== selectedPlaceID) {
            getPlaceDetails(value).then(place => {
                setSelectedPlace(place);
                onChange({ value: place });
            });
        }
    }, [value, selectedPlaceID, setSelectedPlace, getPlaceDetails, isGoogleSDKAvailable, onChange]);

    const currentSelectedPlace = selectedPlace?.placeID
        ? {
              [selectedPlace?.placeID]: {
                  place_id: selectedPlace?.placeID,
                  description: selectedPlace?.formattedAddress,
                  structured_formatting: {
                      main_text: selectedPlace?.formattedAddress,
                      secondary_text: '',
                  },
              },
          }
        : {};

    const placesByID = placePredictions.reduce(
        (acc, cur) => ({
            ...acc,
            [cur.place_id]: cur,
        }),
        currentSelectedPlace,
    );

    const _options = (value ? [value] : []).concat(placePredictions.map(x => x.place_id).filter(x => x !== value));

    return (
        <Autocomplete
            open={readOnly ? false : undefined}
            loading={isPlacePredictionsLoading}
            id={id}
            disabled={disabled}
            fullWidth={fullWidth}
            readOnly={readOnly}
            value={value || null}
            options={_options}
            isOptionEqualToValue={(option, currentPlaceID) => {
                return option === currentPlaceID;
            }}
            forcePopupIcon={false}
            disablePortal
            loadingText={t('address_input.loading_results')}
            noOptionsText={t('address_input.no_results')}
            PaperComponent={OptionsContainerWithAttribution}
            renderInput={renderInput}
            getOptionLabel={option => {
                return placesByID[option]?.description ?? '';
            }}
            renderOption={(props, option, { inputValue }) => {
                const _option = placesByID[option];

                if (!_option) return null;

                return (
                    <MenuItem key={_option.place_id} {...props}>
                        <Box>
                            <Text as="div">
                                <HighlightMatches text={_option.structured_formatting.main_text} input={inputValue} />
                            </Text>
                            <Text as="div" variant="detail">
                                <HighlightMatches
                                    text={_option.structured_formatting.secondary_text}
                                    input={inputValue}
                                />
                            </Text>
                        </Box>
                    </MenuItem>
                );
            }}
            onChange={async (event, newValue) => {
                if (newValue === null) {
                    setSelectedPlace(null);

                    onChange({
                        value: nullPlace,
                    });

                    return;
                }

                const placeDetails = await getPlaceDetails(newValue);
                const hacked = { ...placeDetails, placeID: newValue };

                setSelectedPlace(hacked);
                onChange({ value: hacked });
            }}
        />
    );
}
