import { TIME_CELL_HEIGHT } from 'app/components/compounds/Schedule/constants';

import CSSDimension from 'design-system/CSSDimension';

export const MPU_HEADING_BOTTOM_PADDING = CSSDimension.fromPixels(32);
export const MPU_HEADING_HEIGHT = CSSDimension.fromPixels(
    MPU_HEADING_BOTTOM_PADDING.asValue('px') + TIME_CELL_HEIGHT.asValue('px'),
);

export enum RowIndex {
    Reserved,
    Available,
    Downtime,
}

export enum Availability {
    Available = 'Available',
    Downtime = 'Downtime',
    Reserved = 'Reserved',
}

/**
 * The event type for a MPU Schedule component
 */
export enum MpuScheduleEventType {
    /**
     * An unpersisted "Discharging" schedule.
     */
    Allocation = 'Allocation',
    /**
     * Time when no schedules exist.
     */
    Available = 'Available',
    /**
     * A "Charging" device instance schedule.
     */
    Charging = 'Charging',
    /**
     * A "Maintenance" device instance schedule.
     */
    Downtime = 'Downtime',
    /**
     * An event of reserved time.
     * This represents either a "Discharging" schedule, or the time range for an Allocation.
     */
    Reserved = 'Reserved',
    /**
     * A facade schedule representing time after an allocation for charging and transit.
     * This event type should only be used to facilitate the assignment of MPUs to a Unit.
     * The duration of an event with this event type should be 24 hours.
     */
    Swap = 'Swap',
    /**
     * A "Transit" device instance schedule.
     */
    Transit = 'Transit',
}
