import { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Stack from '@mui/material/Stack';

import { Button, Input, PhoneField } from 'app/components/primitives/interactive';
import AddressInput from 'app/components/widgets/AddressInput';

import { useI18n } from 'i18n';

interface Location {
    placeID?: string;
    placeProvider?: string;
    formattedAddress?: string;
    lat?: number;
    lon?: number;
}

interface FormFields extends Location {
    contactName?: string;
    contactPhone?: string;

    notes?: string;
}

interface Props {
    /**
     * The unique ID to assign to the form element, used to associate form controls outside of the form
     */
    id?: string;

    /**
     * Whether or not the form submit button should be hidden, To be used when the
     * submit button exists outside of the form (i.e. within dialogs)
     */
    hideSubmitCTA?: boolean;

    /**
     * The initial value for the forms contactName
     */
    contactName?: string;

    /**
     * The initial value for the forms contactPhone
     */
    contactPhone?: string;

    /**
     * The initial value for the forms placeID
     */
    placeID?: string;

    /**
     * The initial value for the forms notes
     */
    notes?: string;

    /**
     * event handler to share form state determining if an external submit CTA should be disabled
     */
    onFormStateChange?: (event: { isSubmitCTADisabled: boolean }) => void;

    /**
     * event handler to be called when the form is submitted with valid values
     */
    onSubmit: (formState: { value: FormFields }) => Promise<void>;
}

/**
 * Responsible for collecting information related to an account location or editing an existing account location
 */
export default function AddOrEditAccountLocationForm({
    id,
    hideSubmitCTA = false,
    onFormStateChange,
    contactName = '',
    contactPhone = '',
    placeID = '',
    notes = '',
    onSubmit,
}: Props): ReactElement {
    const { t } = useI18n();

    const [placeMeta, setPlaceMeta] = useState<Location>();

    const formik = useFormik<FormFields>({
        enableReinitialize: true,

        initialValues: {
            contactName,
            contactPhone,
            placeID,
            notes,
        },

        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        validationSchema: object().shape({
            contactName: string().optional(),
            contactPhone: string().optional(),
            placeID: string().required(),
            notes: string().optional(),
        }),

        async onSubmit(value) {
            await onSubmit({ value: { ...value, ...placeMeta } });
        },
    });

    const { isValid, dirty } = formik;

    const isSubmitCTADisabled = !dirty || !isValid;

    useEffect(() => {
        onFormStateChange?.({ isSubmitCTADisabled });
    }, [onFormStateChange, isSubmitCTADisabled]);

    return (
        <Stack id={id} component="form" spacing={3} onSubmit={formik.handleSubmit}>
            <Input
                fieldName={t('edit_address.label.contact_name')}
                fullWidth
                name="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />

            <PhoneField
                fieldName={t('edit_address.label.contact_phone')}
                fullWidth
                name="contactPhone"
                value={formik.values.contactPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />

            <AddressInput
                fullWidth
                required
                fieldName={t('edit_address.label.address')}
                name="placeID"
                value={formik.values.placeID ?? undefined}
                onBlur={formik.handleBlur}
                onChange={({ value }) => {
                    formik.setFieldValue('placeID', value?.placeID, true);

                    // We track place meta separate to be able to use formiks dirty flag
                    // when editing the form
                    setPlaceMeta(value);
                }}
            />

            <Input
                fieldName={t('edit_address.label.additional_details')}
                fullWidth
                multiline
                rows={4}
                name="notes"
                value={formik.values.notes}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
            />

            {!hideSubmitCTA && (
                <Button type="submit" disabled={!isValid || !dirty}>
                    {t('edit_address.cta.save_address')}
                </Button>
            )}
        </Stack>
    );
}
