import * as React from 'react';

import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

// For some reason importing from `app/components` or `app/components/primitives`
// breaks the build for the app and storyboook
import Icon from 'app/components/primitives/Icon';

import useViewportUnits from '../useViewportUnits';
import { makeColorPaletteForMUI } from './color';
import Checkbox from './components/Checkbox';
import DataGrid from './components/DataGrid';
import Tabs from './components/Tabs';
import TextField from './components/TextField';
import spacing from './spacing';
import { makeTypographyForMUI, responsiveFontSizes } from './typography';

const ExpandMoreRoundedIcon = props => {
    return <Icon {...props} name="chevron-down" />;
};

declare module '@mui/material/styles' {
    interface Theme {
        customShadows: {
            subtle: React.CSSProperties['boxShadow'];
            focusHighlight: React.CSSProperties['boxShadow'];
            errorHighlight: React.CSSProperties['boxShadow'];
            successHighlight: React.CSSProperties['boxShadow'];
        };

        shape: {
            borderRadius: string | number;
            pillBorderRadius: string | number;
        };
    }

    type Shadows = React.CSSProperties['boxShadow'][];

    interface BreakpointOverrides {
        xxl: true;
    }

    interface ThemeOptions {
        breakpoints: {
            values: {
                xs: number;
                sm: number;
                md: number;
                lg: number;
                xl: number;
                xxl: number;
            };
        };

        shape: {
            borderRadius: string | number;
            pillBorderRadius: string | number;
        };
    }
}

const _muiMoxionTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1400,
            xl: 2500,
            xxl: 3600,
        },
    },

    palette: makeColorPaletteForMUI(),
    typography: makeTypographyForMUI(),

    shape: {
        borderRadius: 8,
        pillBorderRadius: '999px',
    },

    spacing,

    components: {
        MuiPaper: {
            defaultProps: {
                // For most of our cases elevation would be better to default to 0 but because
                // paper is used broadly withing MUI we cannot do this. For example, DataGrid popovers will
                // cease to have a box-shadow making them hard to read as their own bit of UI.
                // elevation: 0,
            },
            styleOverrides: {
                rounded: ({ theme }) => ({
                    borderRadius: theme.shape.borderRadius,
                }),
            },
        },

        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: theme.shape.borderRadius,
                    minHeight: 48,

                    paddingLeft: theme.spacing(6),
                    paddingRight: theme.spacing(6),

                    textTransform: 'capitalize',
                }),
            },
            defaultProps: {
                disableElevation: true,
            },
        },

        MuiToggleButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: theme.shape.borderRadius,

                    '&.active': {
                        backgroundColor: theme.palette.action.selected,
                    },
                }),
            },
        },

        ...Checkbox,

        MuiAutocomplete: {
            styleOverrides: {
                option: ({ theme }) => ({
                    padding: `${theme.spacing(3, 3, 3, 3)} !important`,
                }),
            },

            defaultProps: {
                popupIcon: <Icon name="chevron-down" />,
                slotProps: {
                    // push menu down a bit to separate it from the input field
                    paper: {
                        sx: { my: 2 },
                    },
                },
            },
        },

        MuiSelect: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: ({ theme }) => ({
                        '.MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',

                            borderRadius: theme.shape.borderRadius,

                            paddingLeft: theme.spacing(6),
                            paddingRight: `${theme.spacing(7)} !important`,

                            paddingTop: '0px',
                            paddingBottom: '0px',

                            minHeight: '48px !important',
                        },

                        '.MuiOutlinedInput-notchedOutline': {
                            borderRadius: theme.shape.borderRadius,
                        },

                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            boxShadow: theme.customShadows.focusHighlight,
                        },
                    }),
                },
            ],
            defaultProps: {
                IconComponent: ExpandMoreRoundedIcon,

                MenuProps: {
                    sx: { my: 2 },
                },
            },
        },

        MuiMenu: {
            styleOverrides: {
                root: ({ theme }) => ({
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),

                    '& .MuiMenu-list': {
                        padding: 0,
                    },
                }),
            },
        },

        ...TextField,

        MuiTableHead: {
            variants: [
                {
                    // @ts-expect-error https://github.com/mui/material-ui/issues/33510
                    props: { variant: 'contained' },
                    style: ({ theme }) => ({
                        '& .MuiTableCell-root': {
                            backgroundColor: theme.palette.background.secondaryDark.main,
                            borderColor: 'transparent',
                        },
                        '& .MuiTableCell-root:first-child': {
                            borderTopLeftRadius: theme.shape.borderRadius,
                            borderBottomLeftRadius: theme.shape.borderRadius,
                        },
                        '& .MuiTableCell-root:last-child': {
                            borderTopRightRadius: theme.shape.borderRadius,
                            borderBottomRightRadius: theme.shape.borderRadius,
                        },
                    }),
                },
            ],
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    wordBreak: 'normal',
                    textDecoration: 'none',
                },
            },
        },
        MuiTableFooter: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root': {
                        border: 'none',
                    },
                },
            },
        },

        ...DataGrid,

        MuiAccordion: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: ({ theme }) => {
                    return {
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: 'transparent',

                        '&.Mui-expanded + .MuiAccordion-root::before': {
                            display: 'none',
                        },

                        '&::before': {
                            marginLeft: theme.spacing(3),
                            marginRight: theme.spacing(3),
                        },

                        '&.Mui-expanded': {
                            borderRadius: theme.shape.borderRadius,
                            boxShadow: theme.customShadows.subtle,
                        },

                        '&.Mui-expanded > .MuiCollapse-root': {
                            borderWidth: 1,
                            borderColor: theme.palette.grey[300],
                            borderStyle: 'solid',
                            borderTopStyle: 'none',
                            borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
                        },
                    };
                },
            },
        },

        MuiAccordionSummary: {
            variants: [
                {
                    // @ts-expect-error
                    props: { variant: 'contained' },
                    style: ({ theme }) => ({
                        '&.Mui-expanded': {
                            backgroundColor: theme.palette.background.secondaryDark.main,
                        },
                    }),
                },
            ],

            styleOverrides: {
                root: ({ theme }) => ({
                    '&.Mui-expanded': {
                        minHeight: 52,
                        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                        border: `1px solid ${theme.palette.grey[400]}`,
                    },
                }),

                content: ({ theme }) => ({
                    '&.Mui-expanded': {
                        marginTop: theme.spacing(3),
                        marginBottom: theme.spacing(3),
                    },
                }),
            },

            defaultProps: {
                expandIcon: <Icon name="chevron-down" />,
            },
        },

        ...Tabs,
    },
});

function makeFocusHighlight({ shadowColor }) {
    return `0 0 0px 4px ${shadowColor}`;
}

/**
 * Shadow overrides
 * https://github.com/mui/material-ui/issues/8289
 */

// Additional shadows to complement the Mui shadows 0 - 24.
_muiMoxionTheme.customShadows = {
    subtle: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    focusHighlight: makeFocusHighlight({ shadowColor: _muiMoxionTheme.palette.focusAccent }),
    errorHighlight: makeFocusHighlight({ shadowColor: _muiMoxionTheme.palette.errorAccent }),
    successHighlight: makeFocusHighlight({ shadowColor: _muiMoxionTheme.palette.successAccent }),
};

export const muiMoxionTheme = responsiveFontSizes(_muiMoxionTheme);

// @ts-ignore
window.theme = muiMoxionTheme;

export const ThemeProvider = (props: { children: React.ReactChild }) => {
    useViewportUnits();

    return <MUIThemeProvider theme={muiMoxionTheme}>{React.Children.only(props.children)}</MUIThemeProvider>;
};
