import { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default ({ children, i18n }) => {
    const [isInitialized, setIsInitialized] = useState(i18n.isInitialized);

    useEffect(() => {
        const onInitialized = () => {
            setIsInitialized(true);
        };

        i18n.on('initialized', onInitialized);

        return () => i18n.off('initialized', onInitialized);
    });

    if (!isInitialized) return null;

    return (
        <I18nextProvider i18n={i18n}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>{children}</LocalizationProvider>
        </I18nextProvider>
    );
};
