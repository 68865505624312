import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { FieldLabel, Link, Text } from 'app/components/primitives';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

export function ReservationScheduleAllocationMPU({
    mpuID,
    mpuName,
}: {
    mpuID: string | undefined;
    mpuName: string | undefined;
}): ReactElement {
    const { t } = useI18n();
    const mpuURL = mpuID ? getPathByName('MPU_DETAIL', { params: { mpuID } }) : undefined;
    const textStyles = {
        display: 'block',
        height: '1.5rem',
        paddingBottom: 3,
        paddingTop: 3,
    } as const;

    return (
        <Box>
            <FieldLabel fieldName={t('reservation_schedule.labels.mpuName')} />
            {mpuURL ? (
                <Link to={mpuURL} sx={textStyles}>
                    {mpuName}
                </Link>
            ) : (
                <Text sx={textStyles}>{t('reservation_schedule.unassigned')}</Text>
            )}
        </Box>
    );
}
