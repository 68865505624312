import { ReactElement, useEffect, useState } from 'react';
import { Duration } from 'luxon';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
    Cluster,
    HistoricalSummary,
    Icon,
    IconAndText,
    IntervalKey,
    IntervalSelector,
    MetricChartDownloader,
    PageHeader,
    SectionHeader,
    SpotlightBasic,
    Text,
    UnitsMap,
    UnitStatus,
    useDiscreteInterval,
    ZeroState,
} from 'app/components';

import { RelativeTime, useI18n } from 'i18n';

import useReservation from './useReservation';

const POLL_INTERVAL = Duration.fromObject({ minutes: 5 }).toMillis();

export default function ReservationMonitoringPage(): ReactElement {
    const [activeReservationItemIndex, setActiveReservationItemIndex] = useState<number>(0);
    const { t, format } = useI18n();
    const { reservationID } = useParams<{ reservationID: string }>();

    const {
        loading,
        error,
        data: reservation,
    } = useReservation(reservationID, {
        pollInterval: POLL_INTERVAL,
    });

    const activeReservationItem = reservation.items[activeReservationItemIndex] ?? {};

    const [intervalKey, setIntervalKey] = useState<IntervalKey>('24h');
    const { type, interval } = useDiscreteInterval({
        unitID: activeReservationItem.id,
        minDate: activeReservationItem?.start,
        maxDate: activeReservationItem?.end,
        intervalKey: intervalKey,
        enablePolling: true,
        pollInterval: POLL_INTERVAL,
    });

    const { hasEnded } = reservation;

    useEffect(() => {
        if (hasEnded) {
            setIntervalKey('all');
        }
    }, [hasEnded]);

    // TODO(derek): We need more granular error codes from the server so we can present
    //  more informative messaging
    if (!loading && !!error) {
        return (
            <div>
                <ZeroState
                    title={t('reservation_monitoring_page.reservation_not_found_title')}
                    message={t('reservation_monitoring_page.reservation_not_found_message')}
                />
            </div>
        );
    }

    return (
        <div>
            <PageHeader
                title={reservation.name}
                subtitle={
                    !reservation?.hasEnded ? (
                        <IconAndText
                            icon={<Icon name="location" size="sm" />}
                            text={
                                <Text variant="inherit" richText>
                                    {t('reservation_monitoring_page.units_in_reservation_subtitle', {
                                        count: reservation.items.length,
                                    })}
                                </Text>
                            }
                        />
                    ) : (
                        t('reservation_monitoring_page.reservation_ended')
                    )
                }
            />

            {reservation?.isActive && (
                <Box mb={6}>
                    <UnitsMap units={reservation.items} socAlertBoundary={20} />
                </Box>
            )}

            <Paper variant="outlined" sx={{ p: { xs: 3, md: 5 }, overflow: 'hidden' }}>
                <Box sx={{ mx: { xs: -3, md: -5 }, mt: { xs: -3, md: -5 }, mb: 4 }}>
                    <Tabs
                        value={activeReservationItemIndex}
                        onChange={(_, newActiveFilter) => {
                            setActiveReservationItemIndex(newActiveFilter);
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label={t('reservation_monitoring_page.screen_reader.tab_description')}
                    >
                        {reservation.items.map((item, index) => (
                            <Tab
                                key={item.id}
                                value={index}
                                label={`${item.name} ${
                                    reservation?.isActive
                                        ? `(${
                                              item.hasAssignment ? item.officialName : t('unit_allocation.unassigned')
                                          })`
                                        : ''
                                }`}
                            />
                        ))}
                    </Tabs>

                    <Divider sx={{ marginTop: '-1px' }} />
                </Box>

                <Box>
                    <Grid container spacing={4} mb={6}>
                        {reservation?.isActive && (
                            <Grid item xs={12} sm={6} lg={4} xl={2}>
                                <SpotlightBasic
                                    height="100%"
                                    label="Current Location"
                                    value={format.latLong(activeReservationItem?.currentLocation)}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={6} lg={4} xl={2}>
                            <SpotlightBasic
                                height="100%"
                                label="Rental Duration"
                                value={format.dateRangeFull(activeReservationItem)}
                            />
                        </Grid>
                    </Grid>

                    {reservation?.isActive && (
                        <>
                            <Box mb={5}>
                                <Text variant="h2">
                                    {t('reservation_monitoring_page.unit_status_section_heading', {
                                        name: activeReservationItem?.name,
                                    })}
                                </Text>
                                <Text variant="body" color="secondary">
                                    {t('reservation_monitoring_page.unit_status_last_updated', {
                                        dateTime: (
                                            <RelativeTime
                                                key={`${activeReservationItem.id}:latest-data-timestamp`}
                                                time={activeReservationItem.latestDataTimestamp}
                                            />
                                        ),
                                    })}
                                </Text>
                            </Box>

                            <UnitStatus mb={7} reservationItemID={activeReservationItem?.id} />
                        </>
                    )}

                    <SectionHeader
                        mb={4}
                        title={t('reservation_monitoring_page.historical_telemetry_section_heading', {
                            name: activeReservationItem?.name,
                        })}
                        cta={
                            <Cluster>
                                <MetricChartDownloader
                                    label={t('cta.download_all')}
                                    includeCharts={['all']}
                                    unitID={activeReservationItem?.id}
                                    start={interval?.start ?? ''}
                                    end={interval?.end ?? ''}
                                />

                                <IntervalSelector
                                    disabled={reservation.hasEnded}
                                    minDate={activeReservationItem?.start}
                                    maxDate={activeReservationItem?.end}
                                    type={type}
                                    value={intervalKey}
                                    onChange={({ value }) => {
                                        setIntervalKey(value);
                                    }}
                                />
                            </Cluster>
                        }
                    />

                    <HistoricalSummary
                        unitID={activeReservationItem?.id}
                        start={interval?.start ?? ''}
                        end={interval?.end ?? ''}
                    />
                </Box>
            </Paper>
        </div>
    );
}
