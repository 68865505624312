import { ScheduleEvent } from 'app/components/compounds/Schedule';

import { MpuScheduleEventType } from './constants';
import { MpuScheduleEvent } from './types';

export function isMpuScheduleEvent(event: ScheduleEvent): event is MpuScheduleEvent {
    return Object.values(MpuScheduleEventType).includes((event as MpuScheduleEvent).type);
}

export function hasMpuScheduleEventType<E extends MpuScheduleEventType>(
    eventType: E,
    event: MpuScheduleEvent,
): event is MpuScheduleEvent<E> {
    return event.type === eventType;
}
