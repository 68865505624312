import { ReactElement } from 'react';

import Icon from 'app/components/primitives/Icon';

import { TranslateFunction, useI18n } from 'i18n';

import Metric from './Metric';

interface Props {
    /**
     * The voltage selection switch state
     */
    value?: string;
}

function resolveVoltageLabel(voltageSelection: string | undefined, { t }: { t: TranslateFunction }): string {
    if (!voltageSelection) return t('unknown');

    switch (voltageSelection) {
        case 'invalid':
            return t('voltage_selection_state.invalid');
        case 'unknown':
            return t('unknown');
        default:
            return voltageSelection;
    }
}

function resolveIcon(voltageSelection?: string): ReactElement | null {
    if (!voltageSelection) return null;

    switch (voltageSelection) {
        case 'invalid':
            return <Icon name="close" color="danger" />;
        case 'unknown':
            return null;
        default:
            return <Icon name="lightning" />;
    }
}

function resolveUnit(voltageSelection?: string): string {
    if (/^(invalid|unknown)$/.test(voltageSelection ?? 'unknown')) return '';

    return 'volts';
}

/**
 * Responsible for representing the MPUs voltage selection switch state
 */
export default function VoltageSelection({ value }: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Metric
            variant="card"
            label={t('unit_status.voltage_selection_label')}
            icon={resolveIcon(value)}
            value={resolveVoltageLabel(value, { t })}
            unit={resolveUnit(value)}
        />
    );
}
