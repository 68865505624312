import { ReactElement } from 'react';

import Grid from '@mui/material/Grid';

interface ColumnLayoutProps {
    columnValues: [ReactElement, ReactElement, ReactElement, ReactElement];
}

export function ColumnLayout({ columnValues }: ColumnLayoutProps) {
    return (
        <Grid container display="flex" alignItems="center" minHeight={24}>
            <Grid item xs={4}>
                {columnValues[0]}
            </Grid>

            <Grid item xs={1} lg={2}>
                {columnValues[1]}
            </Grid>

            <Grid item xs={4} lg={3}>
                {columnValues[2]}
            </Grid>

            <Grid item xs={2}>
                {columnValues[3]}
            </Grid>
        </Grid>
    );
}
