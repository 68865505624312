import { cloneElement, isValidElement, ReactElement } from 'react';
import { Bar, BarChart, Line, LineChart, ResponsiveContainer } from 'recharts';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Icon from 'app/components/primitives/Icon';
import Button from 'app/components/primitives/interactive/Button';
import { LoadingIndicator } from 'app/components/primitives/LoadingIndicator';
import Text from 'app/components/primitives/Text';
import { mergeSeries } from 'app/core/data';

import { useI18n } from 'i18n';

import renderTimeSeriesChart from './renderTimeSeriesChart';
import { Series } from './types';
import useChartZoom from './useChartZoom';

export enum Style {
    Line = 0,
    Bar = 1,
}

export type { Series } from './types';

export interface Props {
    /**
     * Chart data
     */
    series: Series[];

    /**
     * The base unit for the data in the series
     */
    unit: string;

    /**
     * The type of chart to render (i.e. Line or Bar)
     */
    style?: Style;

    /**
     * Whether or not the chart data is still being fetched
     */
    loading?: boolean;

    /**
     * Slot for an interval select component or similar
     */
    intervalSelect?: ReactElement;

    /**
     * Download UI slot
     */
    downloadCTA?: ReactElement<{ series: Series[] }>;

    /**
     * Error message to display if the chart data could not be fetched
     */
    errorMessage?: string | null | undefined;
}

// TODO(derek): get line chart colors from Emily
function pickLineColor(seriesIndex) {
    switch (seriesIndex) {
        case 0:
            return '#3fd8a8';
        case 1:
            return '#30cace';
        case 2:
            return '#0b0a0c';
        case 3:
            return 'orange';
        case 4:
            return 'blue';
        default:
            return '#3fd8a8';
    }
}

function addStyles(series) {
    return series
        .sort((a, b) => {
            if (a.seriesName > b.seriesName) return 1;
            if (a.seriesName < b.seriesName) return -1;

            return 0;
        })
        .map((x, index) => ({
            ...x,
            style: {
                stroke: {
                    color: pickLineColor(index),
                },
            },
        }));
}

const resolveColor = (s: Series): string => s?.style?.stroke?.color ?? 'url(#colorUv)';
const CHARTS = {
    [Style.Line]: {
        Root: LineChart,
        // FIXME(derek): not sure why this is needed for Line and not for Bar
        SeriesDisplay: Line as React.ElementType,
        makeSeriesProps: (series: Series) => ({
            dot: false,
            strokeWidth: 2,
            stroke: resolveColor(series),
        }),
    },
    [Style.Bar]: {
        Root: BarChart,
        SeriesDisplay: Bar,
        makeSeriesProps: (series: Series) => ({
            dot: false,
            strokeWidth: 2,
            fill: resolveColor(series),
        }),
    },
};

export default function TimeSeriesChart({
    series,
    unit,
    style: _style,
    loading,
    intervalSelect,
    downloadCTA,
    errorMessage,
}: Props) {
    const { t } = useI18n();

    const merged = mergeSeries(series);
    const style = _style ?? Style.Line;
    const zoom = useChartZoom({ series });
    const Chart = CHARTS[style];

    if (!Chart) {
        throw new Error('unknown style');
    }

    const hasData =
        series.reduce((acc, cur) => {
            return acc + cur.data.filter(x => x.value != null).length;
        }, 0) > 0;

    return (
        <Box
            sx={{
                position: 'relative',
                minHeight: '466px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                userSelect: 'none',
            }}
        >
            <Stack sx={{ width: '100%' }}>
                <Box display="flex" flexWrap="wrap" sx={{ gap: 2, alignSelf: 'flex-end', px: 4, pt: 4 }}>
                    {!!downloadCTA && (
                        <Box display="flex" alignItems="center">
                            {isValidElement(downloadCTA) ? cloneElement(downloadCTA, { series }) : null}
                        </Box>
                    )}

                    <Box display="flex" alignItems="center">
                        <Button
                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                            ctaType="secondary"
                            icon={<Icon name="return-up-back" />}
                            disabled={!zoom.isActive}
                            onClick={zoom.reset}
                        >
                            {t('time_series_chart.reset_zoom_cta')}
                        </Button>
                    </Box>

                    {!!intervalSelect && (
                        <Box display="flex" alignItems="center">
                            {intervalSelect}
                        </Box>
                    )}
                </Box>

                <Box height={400} display="flex" justifyContent="center" alignItems="center">
                    {loading && <LoadingIndicator />}

                    {!loading && !!errorMessage && (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Icon name="alert-circle" color="danger" size="lg" />
                            <Text as="p" color="danger">
                                {errorMessage}
                            </Text>
                        </Box>
                    )}

                    {!loading && !errorMessage && !hasData && <div>{t('time_series_chart.no_data')}</div>}

                    {!loading && !errorMessage && hasData && (
                        <ResponsiveContainer width="100%" height="100%">
                            <Chart.Root
                                data={merged}
                                margin={{ top: 20, right: 20, left: 10, bottom: series.length > 1 ? 36 : 20 }}
                                onMouseDown={zoom.start}
                                onMouseMove={zoom.updateSelection}
                                onMouseUp={zoom.apply}
                            >
                                {renderTimeSeriesChart({
                                    graphedData: addStyles(series).map(s => {
                                        return (
                                            <Chart.SeriesDisplay
                                                key={s.seriesName}
                                                dataKey={s.seriesName}
                                                {...Chart.makeSeriesProps(s)}
                                            />
                                        );
                                    }),

                                    zoomSelectionStart: zoom.selectionStart,
                                    zoomSelectionEnd: zoom.selectionEnd,
                                    zoomMinXConstraint: zoom.minXConstraint,
                                    zoomMaxXConstraint: zoom.maxXConstraint,
                                    zoomMinYConstraint: zoom.minYConstraint,
                                    zoomMaxYConstraint: zoom.maxYConstraint,

                                    unit,
                                })}
                            </Chart.Root>
                        </ResponsiveContainer>
                    )}
                </Box>
            </Stack>
        </Box>
    );
}
