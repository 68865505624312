import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { ListUsersQueryResult, useListUsersQuery } from 'generated/graphql';

import { User } from '../types';
import DataGridToGQLAdaptor from './DataGridToGQLAdaptor';
import { ServerUser } from './types';

interface Input {
    limit?: number; // the number of results to return
    cursor?: string; // the pagination cursor to start from a.k.a. 'after' in relay parlance
    searchTerm?: string;
    sortModel?: GridSortModel;
    filterModel?: GridFilterModel;
}

interface Output extends Pick<ListUsersQueryResult, 'loading' | 'error' | 'refetch'> {
    data: User[];
    totalCount: number;
    fetchNextPage: () => void;
    fetchPrevPage: () => void;
}

/**
 * Simplify and prep the returned model from the GQL server for display
 */
export function toUser(serverUser: ServerUser): User {
    return {
        id: serverUser.id,
        name: serverUser.name ?? '',
        accountID: serverUser.accounts[0]?.id ?? '',
        accountName: serverUser.accounts[0]?.name ?? '',
        email: serverUser.email,
        roles: serverUser.roles.map(role => role.name),
        status: serverUser.status,
    };
}

/**
 * Responsible for fetching and processing user data to be display on the table
 */
export default function useUsers({ limit = 1_000_000, cursor, sortModel, filterModel }: Input): Output {
    const { data, loading, error, refetch } = useListUsersQuery({
        // NOTE: new fields may need to be added to listUsersFieldPolicy in src/app/core/network/makeApolloClient.ts to preserve data integrity
        variables: {
            first: limit,
            after: cursor,
            sortBy: DataGridToGQLAdaptor.getGQLSortBy(sortModel),
            filters: DataGridToGQLAdaptor.getGQLFilter(filterModel),
        },
    });

    const users = (data?.listUsers.edges ?? []).map(edge => edge.node).map(toUser);

    function fetchNextPage() {
        if (data?.listUsers.pageInfo.hasNextPage) {
            const nextCursor = data.listUsers.pageInfo.endCursor;
            refetch({ last: undefined, before: undefined, first: limit, after: nextCursor });
        }
    }

    function fetchPrevPage() {
        if (data?.listUsers.pageInfo.hasPreviousPage) {
            const prevCursor = data.listUsers.pageInfo.startCursor;
            refetch({ last: limit, before: prevCursor, first: undefined, after: undefined });
        }
    }

    return {
        loading,
        error,
        data: users,
        refetch,
        fetchNextPage,
        fetchPrevPage,
        totalCount: data?.listUsers.pageInfo.totalEdges ?? 0,
    };
}
