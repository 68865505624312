import { ComponentProps } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import { useListMpuSerialNumbersForSelectorQuery } from 'generated/graphql';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {
    ownerAccountIDs: string[];
}

const MultiMPUSelector = ({ ownerAccountIDs, ...props }: Props) => {
    const { data } = useListMpuSerialNumbersForSelectorQuery({ variables: { ownerAccountIDs } });

    const options =
        data?.listDeviceInstances?.edges
            ?.filter(({ node }) => Boolean(node.serialNumber))
            .map(({ node }) => ({ label: node.serialNumber, id: node.serialNumber })) || [];

    // @ts-ignore
    return <MultiSelect {...props} label="Serial Number" placeholder="Select..." options={options} />;
};

export default MultiMPUSelector;
