import { DateTime } from 'luxon';

import { useListActiveDeviceFaultsQuery } from 'generated/graphql';

import { TranslateFunction, useI18n } from 'i18n';

import { ActiveFault, ServerActiveFault } from './types';

interface Input {
    /**
     * The device to fetch active faults for
     */
    id?: string;

    /**
     * How frequently (in milliseconds) to check server for updates to active faults
     */
    pollInterval?: number;
}

interface Result {
    isLoading: boolean;

    data: ActiveFault[];
}

export function bySeverityThenFirstSeen(a: ActiveFault, b: ActiveFault): number {
    if (a.severity === b.severity) {
        return DateTime.fromISO(a.firstSeen).toMillis() - DateTime.fromISO(b.firstSeen).toMillis();
    }

    if (a.severity === 'CRITICAL' && b.severity === 'INFO') {
        return -1;
    }

    return 1;
}

export function toActiveFault(serverFaults: ServerActiveFault[], { t }: { t: TranslateFunction }): ActiveFault[] {
    const getFaultMeta = (key: string) => t(`device_fault.${key}`.toLowerCase());

    return serverFaults.map(({ id, faultName, faultSeverity, firstSeen, lastUpdate }) => ({
        id,
        code: faultName,
        name: getFaultMeta(`${faultName}.name`),
        description: getFaultMeta(`${faultName}.description`),
        severity: faultSeverity,
        firstSeen,
        lastUpdate,
    }));
}

/**
 * Responsible for fetching active faults and formatting faults for display
 */
export default function useActiveDeviceFaults({ id, pollInterval }: Input): Result {
    const { t } = useI18n();

    const { loading, data } = useListActiveDeviceFaultsQuery({
        skip: !id,
        variables: {
            deviceIDs: !!id ? [id] : [],
        },
        pollInterval,
    });

    return {
        isLoading: loading,
        data: toActiveFault(data?.listActiveFaults.edges.map(e => e.node) ?? [], { t }).sort(bySeverityThenFirstSeen),
    };
}
