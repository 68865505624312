import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { EntryWayLayout, Text } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { getIsSignupEnabled, getSupportEmail } from 'environment';

import { useI18n } from 'i18n';

import SignInForm from './SignInForm';
import useInviteWarning from './useInviteWarning';
import useResetPassword from './useResetPassword';
import useSignIn from './useSignIn';

export default function SignInPage() {
    const { t } = useI18n();

    const [searchParams] = useSearchParams();
    const resetPassword = useResetPassword();
    const signIn = useSignIn();
    const isSignupEnabled = getIsSignupEnabled();

    useInviteWarning();

    return (
        <>
            <Helmet>
                <title>{t('signin_page.meta_title')}</title>
                <meta name="description" content={t('signin_page.meta_description')} />
            </Helmet>

            <EntryWayLayout title={t('signin_page.page_title')} message={t('signin_page.form_prompt')}>
                <SignInForm
                    email={searchParams.get('email')}
                    infoMessage={resetPassword.infoMessage}
                    errorMessage={signIn.errorMessage}
                    onForgotPassword={resetPassword.run}
                    onSubmit={signIn.run}
                />

                <Box display="flex" justifyContent="center">
                    <Text
                        variant="detail"
                        as="p"
                        color="secondary"
                        renderLink={({ text }) => (
                            <Link
                                component={RouterLink}
                                to={isSignupEnabled ? getPathByName('SIGN_UP') : `mailto:${getSupportEmail()}`}
                            >
                                {text}
                            </Link>
                        )}
                    >
                        {isSignupEnabled ? t('signin_page.signup_link_label') : t('signin_page.contact_link_label')}
                    </Text>
                </Box>
            </EntryWayLayout>
        </>
    );
}
