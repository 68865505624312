import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';

import { ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

export function NotFoundPage() {
    const { t } = useI18n();

    return (
        <>
            <Helmet>
                <title>{t('404_page.meta_title')}</title>
                <meta name="description" content={t('404_page.meta_description')} />
            </Helmet>

            <ZeroState
                title={t('404_page.page_title')}
                message={t('404_page.404_message')}
                cta={
                    <Button variant="contained" component={RouterLink} to={getPathByName('HOME')}>
                        {t('404_page.return_home_cta')}
                    </Button>
                }
            />
        </>
    );
}
