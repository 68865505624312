import { ComponentProps, ReactElement } from 'react';

import { Stack } from '@mui/material';

import { AlertSeverity } from 'generated/graphql';

import { MUIMarginProps } from 'styles/theme/types';

import Icon, { IconColor } from '../../primitives/Icon';
import { Tooltip } from '../Tooltip';
import AlertTooltipContent from './AlertTooltipContent';
import { AlertForPreview } from './types';

interface Props extends Omit<ComponentProps<typeof Tooltip>, 'children' | 'content'>, MUIMarginProps {
    iconSize?: ComponentProps<typeof Icon>['size'];
    alerts: AlertForPreview[];
}

function hasCriticalAlert(alerts: Pick<AlertForPreview, 'severity'>[]): boolean {
    return !!alerts.find(alert => alert.severity === AlertSeverity.Critical);
}

function resolveIconColor(alerts: Pick<AlertForPreview, 'severity'>[]): IconColor {
    return hasCriticalAlert(alerts) ? 'danger' : 'warning';
}

export default function ActiveAlertsPreview({
    iconSize,
    alerts,
    m,
    mx,
    my,
    ml,
    mr,
    mt,
    mb,
    ...tooltipProps
}: Props): ReactElement {
    if (!alerts?.length) return <></>;

    return (
        <Tooltip
            {...tooltipProps}
            content={
                <Stack spacing={3}>
                    {alerts.map(alert => (
                        <AlertTooltipContent
                            type={alert.type}
                            socThreshold={alert.socThreshold}
                            expectedStatus={alert.expectedStatus}
                            lastUploadTime={alert?.mpu?.lastUploadTime}
                            faultName={alert?.faultName}
                            mpuName={alert?.mpu?.name}
                            mpuHREF={alert?.mpu?.href}
                            reservationName={alert?.reservation?.name}
                            reservationHREF={alert?.reservation?.href}
                            reservationShortID={alert?.reservation.shortID}
                        />
                    ))}
                </Stack>
            }
        >
            <Icon
                size={iconSize}
                name="warning"
                color={resolveIconColor(alerts)}
                m={m}
                mx={mx}
                my={my}
                ml={ml}
                mr={mr}
                mt={mt}
                mb={mb}
            />
        </Tooltip>
    );
}
