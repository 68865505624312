import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { Button, Cluster, Icon, SectionHeader, ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import AccountsTable, { ACCOUNTS_TABLE_ROWS_PER_PAGE_OPTIONS } from './AccountsTable';
import useAccounts from './useAccounts';

export { default as CreateAccountPage } from './CreateAccountPage';

export function AccountIndexPage(): ReactElement {
    const { t } = useI18n();

    const [pageSize, setPageSize] = useState(ACCOUNTS_TABLE_ROWS_PER_PAGE_OPTIONS[0]);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

    const {
        loading,
        error,
        data: accounts,
        refetch: refetchAccounts,
        fetchNextPage: fetchNextAccountPage,
        fetchPrevPage: fetchPrevAccountPage,
        totalCount: totalAccountCount,
    } = useAccounts({ limit: pageSize, sortModel, filterModel });

    return (
        <>
            <SectionHeader
                title={t('admin_account_index_page.title')}
                cta={
                    <Cluster>
                        <Button icon={<Icon name="add-circle" />} to={getPathByName('CREATE_ACCOUNT')}>
                            {t('admin_account_index_page.cta.create_account')}
                        </Button>
                    </Cluster>
                }
                mb={4}
            />

            {error && (
                <ZeroState
                    title={t('admin_account_index_page.zero_state.fetch_error.title')}
                    message={t('admin_account_index_page.zero_state.fetch_error.message')}
                    cta={<Button onClick={() => refetchAccounts()}>{t('cta.retry')}</Button>}
                />
            )}

            {!error && (
                <Paper variant="outlined" sx={{ overflow: 'auto' }}>
                    <AccountsTable
                        loading={loading}
                        accounts={accounts}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        filterModel={filterModel}
                        onFilterModelChange={model => {
                            // cannot jump to a given page, so reset to first page
                            setPageIndex(0);
                            setFilterModel(model);
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                        pageIndex={pageIndex}
                        onPageChange={newPageIndex => {
                            if (newPageIndex > pageIndex) {
                                fetchNextAccountPage();
                            } else {
                                fetchPrevAccountPage();
                            }

                            setPageIndex(newPageIndex);
                        }}
                        totalRowCount={totalAccountCount}
                    />
                </Paper>
            )}

            {/* For children routes including CreateAccountDialog */}
            <Outlet
                context={{
                    onAccountCreated: refetchAccounts,
                }}
            />
        </>
    );
}
