import { ComponentProps, memo, MouseEventHandler, ReactElement, useCallback, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Layer } from 'app/components/primitives';
import useCombineRefs, { CallbackOrMutableRef } from 'app/components/useCombineRefs';

import {
    ContentProps,
    InnerWrapperProps,
    MultiGrid,
    MultiGridController,
    MultiGridRenderStats,
    MultiGridScrollPosition,
    MultiGridSlotProps,
    MultiGridSlots,
} from '../MultiGrid';
import {
    clickBar,
    collapseRow,
    endBarHover,
    expandRow,
    nextDateRange,
    prevDateRange,
    requestBarHoverEnd,
    scrollToDate,
    startBarHover,
    toggleRowExpansion,
} from './actions';
import {
    DATE_RANGE_HEIGHT,
    DEFAULT_COLLAPSED_ROW_HEIGHT,
    DEFAULT_EXPANDED_ROW_HEIGHT,
    TIME_CELL_HEIGHT,
    VERTICAL_SPACING,
} from './constants';
import { useDateRangeDates, useRowHeightAccessor } from './hooks';
import { ScheduleAsideCornerContent, ScheduleAsideOuter } from './ScheduleAside';
import { ScheduleProvider, useScheduleInit } from './ScheduleContext';
import { ScheduleDateRange } from './ScheduleDateRange';
import { ScheduleEventCell } from './ScheduleEventCell';
import { ScheduleGridColumns } from './ScheduleGridColumns';
import { ScheduleLoadingOverlay } from './ScheduleLoading';
import { ScheduleTimeCell } from './ScheduleTimeCell';
import { ScheduleEvent, ScheduleOptions, TooltipContentRenderer } from './types';

/**
 * Component slots for the `Schedule` component
 *
 * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slots-prop | MUI Slots}
 */
interface ScheduleSlots<ItemData = unknown> extends Exclude<MultiGridSlots<ItemData>, 'bodyCell'> {
    toolbarLeft?: (props: ContentProps<ItemData>) => ReactElement | null;
    toolbarRight?: (props: ContentProps<ItemData>) => ReactElement | null;
}

/**
 * Component props for slots in the `Schedule` component
 *
 * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slotprops-prop | MUI Slot Props}
 */
interface ScheduleSlotProps<ItemData = unknown> extends MultiGridSlotProps<ItemData> {
    toolbarLeft?: Partial<ContentProps<ItemData>>;
    toolbarRight?: Partial<ContentProps<ItemData>>;
}

interface ScheduleProps<ItemData = unknown, E extends ScheduleEvent = ScheduleEvent> extends ScheduleOptions<E> {
    /**
     * A MUI grid size to determine the width of the aside.
     */
    asideGridSize?: ComponentProps<typeof MultiGrid>['asideGridSize'];
    /**
     * The height of rows when collapsed in pixels.
     */
    collapsedRowHeight?: number;
    /**
     * A reference to a controller object for interacting with the component.
     */
    controllerRef?: CallbackOrMutableRef<MultiGridController<ItemData> | undefined>;
    /**
     * An array of indexes for rows to be expanded by default.
     */
    defaultExpandedRows?: number[];
    /**
     * The height of rows when expanded in pixels.
     */
    expandedRowHeight?: number;
    /**
     * A function called to retrieve the height of a row in pixels.
     * This prop takes priority over `collapsedRowHeight` and `expandedRowHeight`.
     *
     * @param rowIndex The index of the row to get height for
     * @param expandedRowIndexes An array of indexes of rows that are expanded
     * @returns The height of the row in pixels
     */
    getRowHeight?: (rowIndex: number, expandedRowIndexes: number[]) => number;
    /**
     * Height of the heading in pixels.
     */
    headingHeight?: number;
    /**
     * Total height of the component in pixels.
     */
    height: number;
    /**
     * Arbitrary data provided to each cell.
     *
     * @remarks
     *
     * The given value _must_ be memoized to prevent constant re-renders of every cell in the grid.
     */
    itemData: ItemData;
    /**
     * When `true`, the component renders a loading overlay.
     */
    loading?: boolean;
    /**
     * A function called when an event bar is clicked.
     */
    onBarClick?: (payload: ReturnType<typeof clickBar>['payload']) => void;
    /**
     * A function called when an event bar hover state ends.
     */
    onBarHoverEnd?: (payload: ReturnType<typeof endBarHover>['payload']) => void;
    /**
     * A function called when an event bar hover state starts.
     */
    onBarHoverStart?: (payload: ReturnType<typeof startBarHover>['payload']) => void;
    /**
     * A function called whenever the body grid renders a different set of cells.
     */
    onItemsRendered?: (renderStats: MultiGridRenderStats) => void;
    /**
     * A function called to render tooltip content for an event bar.
     */
    renderTooltipContent?: TooltipContentRenderer<E>;
    /**
     * The total number of rows in the grid.
     */
    rowCount: number;
    /**
     * Props to be provided to each respective slot component.
     *
     * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slotprops-prop | MUI `slotProps`}.
     */
    slotProps?: ScheduleSlotProps<ItemData>;
    /**
     * Component slots
     *
     * @see {@link https://mui.com/base-ui/guides/overriding-component-structure/#the-slots-prop | MUI `slots`}.
     */
    slots?: ScheduleSlots<ItemData>;
    /**
     * Total width of the component in pixels.
     */
    width: number;
}

/**
 * A compound component that wraps the `MultiGrid` component to present a schedule view.
 * It’s responsible for translating all time-based functionality into coordinates for `MultiGrid`.
 */
function ScheduleComponent<ItemData = unknown, E extends ScheduleEvent = ScheduleEvent>(
    props: ScheduleProps<ItemData, E>,
): ReactElement {
    const {
        controllerRef: controllerRefProp,
        asideGridSize,
        collapsedRowHeight = DEFAULT_COLLAPSED_ROW_HEIGHT.asValue('px'),
        expandedRowHeight = DEFAULT_EXPANDED_ROW_HEIGHT.asValue('px'),
        getRowHeight,
        headingHeight = TIME_CELL_HEIGHT.asValue('px'),
        height: containerHeight,
        itemData,
        loading,
        onBarClick,
        onBarHoverEnd,
        onBarHoverStart,
        onItemsRendered,
        renderTooltipContent,
        rowCount,
        slotProps,
        slots,
        width: containerWidth,
        ...options
    } = props;
    const { toolbarLeft: ToolbarLeft, toolbarRight: ToolbarRight, ...multiGridSlots } = slots || {};

    const gridHeight = containerHeight - DATE_RANGE_HEIGHT.asValue('px') - VERTICAL_SPACING.asValue('px');
    const contextValue = useScheduleInit({ asideGridSize, containerWidth, gridHeight, headingHeight }, options);
    const { data, state, dispatch, getState, onActionDispatch } = contextValue();
    const { config, dimensions, latestGridRenderStatsRef, renderTooltipContentRef, timeConverter } = data;

    renderTooltipContentRef.current = renderTooltipContent;

    const controllerRef = useRef<MultiGridController<void> | undefined>();
    const combinedControllerRef = useCombineRefs<MultiGridController<void> | undefined>(
        controllerRef,
        controllerRefProp,
    );
    const { expandedRowIndexes } = state;
    const rowHeightAccessor = useRowHeightAccessor({
        collapsedRowHeight,
        currentExpandedRowIndexes: expandedRowIndexes,
        expandedRowHeight,
        getRowHeight,
    });

    const { daysDisplayed, futureLength, historyLength } = config;
    const { bodyCellWidth } = dimensions;
    const initialScrollLeft = historyLength * bodyCellWidth;
    const columnCount = historyLength + daysDisplayed + futureLength;

    /**
     * If `true`, the component is currently being animated.
     * The handling of interactions should take this into account.
     */
    const isAnimatingRef = useRef(false);

    const animateTo = useCallback(
        async (position: MultiGridScrollPosition, duration?: number) => {
            isAnimatingRef.current = true;
            await controllerRef.current?.animateTo(position, duration);
            isAnimatingRef.current = false;
        },
        [controllerRef],
    );

    const [dateRangeDates, updateDateRange] = useDateRangeDates({
        daysDisplayed,
        historyLength,
        timeConverter,
    });

    const handleItemsRendered = useCallback(
        (renderStats: MultiGridRenderStats) => {
            latestGridRenderStatsRef.current = renderStats;
            onItemsRendered?.(renderStats);

            // The date range should be kept in sync with the scroll position of the grid,
            // except when the grid is animating. This behavior is intentional and was
            // directed by the design team.
            //
            // The date range dates are updated separately in response to button presses,
            // using action side effects.
            if (!isAnimatingRef.current) {
                updateDateRange(renderStats.visibleColumnStartIndex);
            }
        },
        [latestGridRenderStatsRef, onItemsRendered, updateDateRange],
    );

    // MultiGrid side effects
    useEffect(() => {
        return onActionDispatch(action => {
            const visibleColumnStartIndex = latestGridRenderStatsRef.current?.visibleColumnStartIndex ?? historyLength;

            if (prevDateRange.match(action)) {
                const nextStartColumnIndex = Math.max(visibleColumnStartIndex - daysDisplayed, 0);

                animateTo({ scrollLeft: nextStartColumnIndex * bodyCellWidth });
                updateDateRange(nextStartColumnIndex);
                return;
            }
            if (nextDateRange.match(action)) {
                /** The starting column index of the latest time frame that can be displayed. */
                const latestStartColumnIndex = historyLength + futureLength - 1;
                const nextStartColumnIndex = Math.min(visibleColumnStartIndex + daysDisplayed, latestStartColumnIndex);

                animateTo({ scrollLeft: nextStartColumnIndex * bodyCellWidth });
                updateDateRange(nextStartColumnIndex);
                return;
            }
            if (scrollToDate.match(action)) {
                const nextStartColumnIndex = timeConverter.startTimestampToColumnIndex(action.payload);

                animateTo({ scrollLeft: nextStartColumnIndex * bodyCellWidth });
                updateDateRange(nextStartColumnIndex);
                return;
            }
            if (collapseRow.match(action) || expandRow.match(action) || toggleRowExpansion.match(action)) {
                controllerRef.current?.resetAfterRowIndex(action.payload.rowIndex);
                return;
            }
        });
    }, [
        animateTo,
        controllerRef,
        bodyCellWidth,
        daysDisplayed,
        futureLength,
        getState,
        historyLength,
        latestGridRenderStatsRef,
        onActionDispatch,
        timeConverter,
        updateDateRange,
    ]);

    const isRecalculatingCellPositions = useRef(false);

    // Responsiveness side effects
    useEffect(() => {
        if (isRecalculatingCellPositions.current) return;

        isRecalculatingCellPositions.current = true;

        requestAnimationFrame(() => {
            isRecalculatingCellPositions.current = false;
            // Recalculate the horizontal position of every rendered cell
            controllerRef.current?.resetAfterColumnIndex(0);
        });
    }, [controllerRef, containerWidth]);

    // Prop handler side effects
    useEffect(() => {
        return onActionDispatch(action => {
            if (clickBar.match(action)) {
                onBarClick?.(action.payload);
                return;
            }
            if (endBarHover.match(action)) {
                onBarHoverEnd?.(action.payload);
                return;
            }
            if (startBarHover.match(action)) {
                onBarHoverStart?.(action.payload);
                return;
            }
        });
    }, [onActionDispatch, onBarClick, onBarHoverEnd, onBarHoverStart]);

    // Bar hover side effects
    useEffect(() => {
        let pendingHoverEnd: ReturnType<typeof setTimeout> | undefined;

        return onActionDispatch(action => {
            if (startBarHover.match(action)) {
                if (pendingHoverEnd !== undefined) {
                    clearTimeout(pendingHoverEnd);
                    pendingHoverEnd = undefined;
                }
                return;
            }

            const isHovering = !!getState().hoverTargetId;

            if (requestBarHoverEnd.match(action) && isHovering && pendingHoverEnd === undefined) {
                const HOVER_WAIT = 150;

                // Wait to see if the user begins hovering over another bar or tooltip,
                // then end the hover state.
                pendingHoverEnd = setTimeout(() => dispatch(endBarHover()), HOVER_WAIT);
                return;
            }
        });
    }, [dispatch, getState, onActionDispatch]);

    const handlePrevButtonClick = useCallback<MouseEventHandler>(() => dispatch(prevDateRange()), [dispatch]);
    const handleNextButtonClick = useCallback<MouseEventHandler>(() => dispatch(nextDateRange()), [dispatch]);

    /**
     * A dynamically created component to slot into the `Schedule` component.
     */
    const InnerWrapper = useCallback(
        (props: InnerWrapperProps) => <ScheduleGridColumns columnWidth={bodyCellWidth} {...props} />,
        [bodyCellWidth],
    );

    return (
        <ScheduleProvider value={contextValue}>
            <Layer anchor width={containerWidth}>
                <Stack direction="row" justifyContent="space-between" alignItems="start" mb="24px">
                    <Box minWidth="25%">
                        {ToolbarLeft && <ToolbarLeft data={itemData} {...slotProps?.toolbarLeft} />}
                    </Box>
                    <ScheduleDateRange
                        endDate={dateRangeDates.end}
                        onNextButtonClick={handleNextButtonClick}
                        onPrevButtonClick={handlePrevButtonClick}
                        startDate={dateRangeDates.start}
                    />
                    <Box minWidth="30%">
                        {ToolbarRight && <ToolbarRight data={itemData} {...slotProps?.toolbarRight} />}
                    </Box>
                </Stack>
                <MultiGrid<ItemData>
                    asideGridSize={asideGridSize}
                    bodyColumnsDisplayed={daysDisplayed}
                    columnCount={columnCount}
                    getRowHeight={rowHeightAccessor}
                    headingHeight={headingHeight}
                    height={gridHeight}
                    initialScrollLeft={initialScrollLeft}
                    controllerRef={combinedControllerRef}
                    itemData={itemData}
                    onItemsRendered={handleItemsRendered}
                    rowCount={rowCount}
                    slots={{
                        asideOuter: ScheduleAsideOuter,
                        bodyCell: ScheduleEventCell,
                        bodyInner: InnerWrapper,
                        cornerContent: ScheduleAsideCornerContent,
                        headingCell: ScheduleTimeCell,
                        headingInner: InnerWrapper,
                        ...multiGridSlots,
                    }}
                    enableScrollingUpdates
                    width={containerWidth}
                />
                {loading && <ScheduleLoadingOverlay />}
            </Layer>
        </ScheduleProvider>
    );
}

// This is necessary for `React.memo` to be used with components with generic types.
export const Schedule = memo(ScheduleComponent) as typeof ScheduleComponent;
