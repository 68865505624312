import { ComponentProps, ReactElement } from 'react';
import { motion } from 'framer-motion';

import Icon from 'app/components/primitives/Icon';
import Cluster from 'app/components/primitives/layout/Cluster';
import Tag from 'app/components/primitives/Tag';

interface Props extends Omit<ComponentProps<typeof Cluster>, 'children'> {
    name?: string;

    domains?: string[];

    readOnly?: boolean;

    onRemove?: (event: { name?: string; value: string[] }) => void;
}

/**
 * Simple component to visualize all provided domains
 */
export default function Domains({ name, domains, onRemove, readOnly, ...props }: Props): ReactElement {
    return (
        <Cluster {...props}>
            {domains?.map(x => (
                <motion.div key={x} layout="position" layoutId={x}>
                    <Tag
                        outlined
                        label={x}
                        endIcon={readOnly ? undefined : <Icon name="close" />}
                        onClick={
                            readOnly
                                ? undefined
                                : () => {
                                      onRemove?.({
                                          name,
                                          value: (domains ?? []).filter(domain => domain !== x),
                                      });
                                  }
                        }
                    />
                </motion.div>
            ))}
        </Cluster>
    );
}
