import { DateTime, Interval } from 'luxon';

import { AlertType, ReservationStatus } from 'generated/graphql';

import { FormatObject, TranslateFunction } from 'i18n';

import { SOC_THRESHOLD } from '../data';
import getPathByName from '../Navigation/getPathByName';
import makeAlertAction from './makeAlertAction';
import { Alert, MPU, Reservation, ServerAlert } from './types';

function resolveName({
    type,
    mpu,
    t,
    format,
}: {
    type: string;
    mpu: Alert['mpu'];
    t: TranslateFunction;
    format: FormatObject;
}): string {
    let translationParams = {};

    if (type === 'MPU_OFFLINE') {
        translationParams = {
            mpuName: mpu?.name,
            dateTime: format.dateTime(mpu?.lastUploadTime),
        };
    } else if (type === 'MPU_LOW_SOC') {
        translationParams = {
            threshold: `${SOC_THRESHOLD}%`,
            mpuName: mpu?.name,
        };
    }

    return t(`alerts.type.${type?.toLowerCase()}.name`, translationParams);
}

function resolveReservationMeta(reservation): Reservation {
    return {
        id: reservation?.id ?? null,
        name: reservation?.name,
        shortID: reservation?.id?.substring(0, 5),
        href: getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', { params: { reservationID: reservation?.id ?? '' } }),

        status: reservation?.status,

        requesterAccountID: reservation?.account?.id,
        requesterAccountName: reservation?.account?.name,
        requesterAccountHREF: getPathByName('ACCOUNT_MGMT_ACCOUNT', {
            params: { accountID: reservation?.account?.id ?? '' },
        }),

        userRequestedID: reservation?.userRequested?.id,
        userRequestedName: reservation?.userRequested?.name,
        userRequestedHREF: getPathByName('ACCOUNT_MGMT_USER', {
            params: { userID: reservation?.userRequested?.id ?? '' },
        }),

        serviceAreaID: reservation?.serviceArea?.id,
        serviceAreaName: reservation?.serviceArea?.name,
    };
}

function resolveMPUMeta({ mpu, lastUploadTime }): MPU {
    return {
        id: mpu?.id ?? null,
        name: mpu?.name ?? '',
        href: getPathByName('MPU_DETAIL', { params: { mpuID: mpu?.id ?? '' } }),
        lastUploadTime: lastUploadTime,
    };
}

function getReservationInterval({ reservationItems }) {
    // All reservationItems should have the same start and end
    const start = reservationItems[0]?.start;
    const end = reservationItems[0]?.end;

    return Interval.fromISO(`${start}/${end}`);
}

/**
 * Determines if a reservation status should have been updated
 */
function getExpectedStatus({
    reservationInterval,
    now,
}: {
    reservationInterval: Interval;
    now: DateTime;
}): ReservationStatus | null {
    if (reservationInterval.end && now > reservationInterval.end) return ReservationStatus.Completed;

    if (reservationInterval.contains(now)) return ReservationStatus.Started;

    return null;
}

export default function toAlert(
    serverAlert: ServerAlert,
    { t, format }: { t: TranslateFunction; format: FormatObject },
): Alert {
    const { type, severity, triggerTime } = serverAlert;

    const reservation = resolveReservationMeta(serverAlert.reservation);
    const mpu = resolveMPUMeta({
        mpu: serverAlert.device,
        lastUploadTime: serverAlert.lastUploadTime,
    });

    return {
        // The backend does not provide id for Alerts at this time. We synthesize the id
        // given type and triggerTime which should be unique
        id: `${type}-${triggerTime}`,
        type,
        severity,
        name: resolveName({ type, mpu, t, format }),
        timestamp: triggerTime,

        // Conditional fields based on type
        expectedStatus:
            type === AlertType.ReservationStatusMismatch
                ? getExpectedStatus({
                      now: DateTime.now(),
                      reservationInterval: getReservationInterval({
                          reservationItems: serverAlert.reservation?.reservationItems ?? [],
                      }),
                  })
                : null,
        faultName: serverAlert?.fault?.faultName
            ? t(`device_fault.${serverAlert?.fault?.faultName}.name`.toLowerCase())
            : '',
        socThreshold: SOC_THRESHOLD,

        action: makeAlertAction({ type, reservation, mpu, t }),

        // Associated entities
        reservation,
        mpu,
    };
}
