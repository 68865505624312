import { DateTime } from 'luxon';

import { ResolvableDateTime } from '../types';

export type { ResolvableDateTime };

/**
 * Given a time representation return a luxon DateTime instance
 */
export default function resolveDateTime(value?: ResolvableDateTime): DateTime {
    // returns an invalid datetime if date is undefined
    if (!value) return DateTime.fromISO('');

    if (value instanceof DateTime) return value;
    if (typeof value === 'string') return DateTime.fromISO(value);
    if (value instanceof Date) return DateTime.fromJSDate(value);

    throw new Error(`resolveDateTime passed unknown date format`);
}
