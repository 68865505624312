import { ReactElement } from 'react';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { Button, Icon, Layer, LoadingIndicator, PageHeader, Text, ZeroState } from 'app/components';
import ActiveReservationAlerts from 'app/components/widgets/ActiveReservationAlerts/ActiveReservationAlerts';
import Documents from 'app/components/widgets/Documents';
import { useDownloadFile } from 'app/core/download';
import { useLayer } from 'app/core/layers';
import { useScrollToPageSection } from 'app/core/Navigation';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useSession } from 'app/core/Session';

import {
    ReservationInput,
    ReservationItemUpdateInput,
    ReservationStatus,
    ReservationType,
    useReservationTelemetrySummaryLazyQuery,
    useUpdateReservationItemMutation,
    useUpdateReservationMutation,
} from 'generated/graphql';

import { useI18n } from 'i18n';

import ReservationStatusSelector from '../../components/widgets/ReservationStatusSelector';
import ReservationInfo from './ReservationInfo';
import { UpdateReservationInput } from './ReservationInfo/EditReservationForm';
import ReservationScheduleSwitcher from './ReservationScheduleSwitcher';
import ReservationSubtitle from './ReservationSubtitle';
import UnitAddresses from './UnitAddresses';
import useReservation from './useReservation';
import useUpdateReservationStatus from './useUpdateReservationStatus';

const SECTION_IDS = {
    statusSelector: 'status-selector',
    info: 'info',
    addresses: 'addresses',
    schedule: 'schedule',
};

export function AdminReservationPage(): ReactElement {
    const ownerAccountID = useSession().getSession().primaryAccountID;
    const { reservationID = '' } = useParams<{ reservationID: string }>();
    const { t } = useI18n();

    const { toast } = useLayer();

    const [_updateReservation] = useUpdateReservationMutation();
    const [updateReservationItem] = useUpdateReservationItemMutation();

    const { loading, error, reservation, refetch } = useReservation({
        reservationID,
    });

    const [getDataDownload] = useReservationTelemetrySummaryLazyQuery({
        variables: {
            input: {
                reservationID: reservationID,
            },
        },
    });

    function adaptDataToCSV(input) {
        const columnsSet = new Set(['unit']); // Start with 'device_id' as the first column
        const unitDataMap = {};

        // Helper function to flatten and label data keys
        const flattenAndLabelData = (dataArray, labels) => {
            dataArray.forEach(item => {
                const { unit } = item;

                if (!unit) return; // If no device ID, skip this item

                if (!unitDataMap[unit]) {
                    unitDataMap[unit] = { unit }; // Ensure 'device_id' is first
                }

                for (const [key, value] of Object.entries(item)) {
                    if (key === '__typename' || key === 'unit') continue; // Skip these keys
                    const { label = key, unit: metricUnit } = labels[key] || {};
                    const labelWithUnits = metricUnit ? `${label} (${metricUnit})` : label; // Use the label if it exists, otherwise use the key
                    columnsSet.add(labelWithUnits);
                    unitDataMap[unit][labelWithUnits] = value;
                }
            });
        };

        const labels = t('reports');

        // Process each data category with the appropriate prefix
        flattenAndLabelData(input.chargeDischargeTimes, labels);
        flattenAndLabelData(input.chargeDischargeTotals, labels);
        flattenAndLabelData(input.faults, labels);
        flattenAndLabelData(input.maxContinousLoad, labels);

        // Generate rows from the deviceDataMap
        const rows = Object.values(unitDataMap);

        // Ensure columns are ordered with 'device_id' first
        const columns = Array.from(columnsSet);

        return {
            columns,
            rows,
        };
    }

    const { download: downloadReport, isLoading: isDownloadPending } = useDownloadFile({
        fileName: `reservation-report-download-${reservationID}`,
        queryFn: async () => {
            const { data, error } = await getDataDownload({
                variables: {
                    input: { reservationID: reservationID },
                },
            });

            if (error) throw new Error(t('downloads.error'));

            const { chargeDischargeTimes, chargeDischargeTotals, faults, maxContinousLoad } =
                data?.reservationTelemetrySummary ?? {};

            const csvData = adaptDataToCSV({ chargeDischargeTotals, faults, maxContinousLoad, chargeDischargeTimes });

            const hasEnoughData = csvData?.columns?.length > 1;

            if (!hasEnoughData) {
                toast.add({
                    severity: 'error',
                    message: t('downloads.error_not_enough_data'),
                });

                return;
            }

            return csvData;
        },
        type: 'csv',
    });

    const isEnded = reservation.end < DateTime.now();

    const showRelatedDocuments =
        reservation.status === ReservationStatus.Confirmed || reservation.status === ReservationStatus.Started;

    useScrollToPageSection({
        isLoading: loading,
        sectionIDs: Object.values(SECTION_IDS),
    });

    const updateReservationStatus = useUpdateReservationStatus({
        onError: () => {
            toast.add({
                severity: 'error',
                message: t('admin_reservation_page.reservation_status_update_error_message'),
            });
        },
        onCompleted: ({ status: newStatus }) => {
            toast.add({
                severity: 'success',
                message: t('admin_reservation_page.reservation_status_update_success_message', { status: newStatus }),
            });
            refetch();
        },
    });

    const updateReservation = async ({
        type,
        name,
        fulfillerNotes,
        serviceAreaID,
    }: {
        type?: ReservationType;
        name: string | undefined;
        fulfillerNotes: string | undefined;
        serviceAreaID: string | undefined;
    }) => {
        const reservationInput: ReservationInput = {
            id: reservationID,
            name,
            type,
            fulfillerNotes,
            serviceAreaID: serviceAreaID === 'unassigned' ? undefined : serviceAreaID,
        };

        await _updateReservation({
            variables: { reservationInput },
        });
    };

    async function onUpdateReservation(values: Partial<UpdateReservationInput>) {
        let wasSuccessful = true;
        const {
            name,
            serviceType,
            fulfillerNotes,
            startDate,
            startHour,
            startTimeZone,
            endDate,
            endHour,
            endTimeZone,
            serviceAreaID,
        } = values;

        try {
            await updateReservation({ name, type: serviceType, fulfillerNotes, serviceAreaID });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);

            toast.add({
                severity: 'error',
                message: t('admin_reservation_page.reservation_status_update_error_message'),
            });
            wasSuccessful = false;
        }

        if (startDate && endDate) {
            const start = startDate.set({ hour: startHour }).setZone(startTimeZone, { keepLocalTime: true }).toISO();
            const end = endDate.set({ hour: endHour }).setZone(endTimeZone, { keepLocalTime: true }).toISO();

            try {
                await Promise.all(
                    reservation.items.map(ri => {
                        return updateReservationItem({ variables: { input: { id: ri.id, start, end } } });
                    }),
                );
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);

                toast.add({
                    severity: 'error',
                    message: t('admin_reservation_page.reservation_duration_update_error_message'),
                });
                wasSuccessful = false;
            }
        }

        if (wasSuccessful) {
            toast.add({
                severity: 'success',
                message: t('admin_reservation_page.reservation_detail_update_success'),
            });
        }
        refetch();
    }

    async function onUpdateReservationItem(input: ReservationItemUpdateInput) {
        await updateReservationItem({ variables: { input } });
        refetch();
    }

    return (
        <>
            <Helmet>
                <title>{t('admin_reservation_page.meta.title')}</title>
                <meta name="description" content={t('admin_reservation_page.meta.description')} />
            </Helmet>

            <PageHeader
                title={reservation.title}
                subtitle={
                    <ReservationSubtitle
                        accountName={reservation.account?.name}
                        userName={reservation.userRequested?.name}
                        contactPhone={reservation.account?.contactPhone}
                        email={reservation.userRequested?.email}
                    />
                }
                cta={
                    reservation.start <= DateTime.now() ? (
                        <Button
                            icon={<Icon name={isEnded ? 'analytics' : 'pulse'} />}
                            to={getPathByName('RENTER_RESERVATION_MONITORING', {
                                params: { reservationID: reservation.id },
                            })}
                        >
                            {isEnded ? t('admin_reservation_page.cta.review') : t('admin_reservation_page.cta.monitor')}
                        </Button>
                    ) : null
                }
            />

            <Layer minHeight="100%" anchor>
                {loading && (
                    <Layer>
                        <LoadingIndicator />
                    </Layer>
                )}

                {!!error && (
                    <ZeroState
                        title={t('admin_reservation_page.error_title')}
                        message={t('admin_reservation_page.reservation_retrieval_error_message')}
                        cta={
                            <Button ctaType="secondary" onClick={() => refetch()}>
                                {t('cta.retry')}
                            </Button>
                        }
                    />
                )}

                {!loading && !error && (
                    <>
                        <Box maxWidth="lg" m="auto" mb={4}>
                            <ActiveReservationAlerts reservationID={reservationID} />
                        </Box>

                        <Stack spacing={5}>
                            <Grid container id={SECTION_IDS.statusSelector}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <ReservationStatusSelector
                                        name="status"
                                        value={reservation.status}
                                        reservationID={reservationID}
                                        onChange={async evt => {
                                            updateReservationStatus({ id: reservation.id, status: evt.target.value });
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Box id={SECTION_IDS.info}>
                                <ReservationInfo
                                    reservationID={reservationID}
                                    serviceType={reservation.serviceType}
                                    name={reservation.name}
                                    serviceAreaID={reservation.serviceAreaID}
                                    startDate={reservation.start}
                                    endDate={reservation.end}
                                    status={reservation.status}
                                    userNotes={reservation.userNotes}
                                    unitCount={reservation.unitCount}
                                    requestDate={reservation.requestDate}
                                    fulfillerNotes={reservation.fulfillerNotes}
                                    // Note(derek): This is the fulfiller accountID which at this point in time will be the Moxion Fleet Manager
                                    // or more concretely the signed in users account id (which will be the moxion account ID). We will only be able to
                                    // rent Moxion owned units and service areas are account specific
                                    accountID={ownerAccountID}
                                    onUpdate={onUpdateReservation}
                                />
                            </Box>

                            <Box id={SECTION_IDS.addresses}>
                                <UnitAddresses
                                    items={reservation.items}
                                    isLoading={loading}
                                    onUpdate={onUpdateReservationItem}
                                    accountID={reservation.account?.id ?? ''}
                                    sx={{ pt: 4 }}
                                />
                            </Box>

                            <Box id={SECTION_IDS.schedule}>
                                <ReservationScheduleSwitcher
                                    ownerAccountID={ownerAccountID}
                                    reservationID={reservation.id}
                                />
                            </Box>

                            {showRelatedDocuments && <Documents />}

                            {isEnded && (
                                <Box sx={{ pt: 4 }}>
                                    <Paper sx={{ p: 4 }} variant="outlined">
                                        <Stack direction="column" spacing={4}>
                                            <Text variant="h3" as="h2">
                                                {t('downloads.download_report_title')}
                                            </Text>
                                            <div>
                                                <Text>{t('downloads.download_report_description')}</Text>
                                            </div>
                                            <Button
                                                ctaType="secondary"
                                                onClick={downloadReport}
                                                disabled={isDownloadPending}
                                            >
                                                {isDownloadPending
                                                    ? t('downloads.pending')
                                                    : t('downloads.download_report_cta')}
                                            </Button>
                                        </Stack>
                                    </Paper>
                                </Box>
                            )}
                        </Stack>
                    </>
                )}
            </Layer>

            {/* For children routes including UpdateUnitCountDialog */}
            <Outlet />
        </>
    );
}
