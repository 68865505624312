import { useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { RouterProvider as ReactRouterProvider } from 'react-router-dom';

import { GQLClient } from '../network/types';
import { Session } from '../Session';
import { IExperienceSwitcher } from '../types';
import getPathByName from './getPathByName';
import { generateRouter, serializeNextLocation } from './routes';

/**
 * obtains Apollo client and Okta data to pass to loaders, then
 * generates the Remix router to be passed to ReactRouter
 * @returns instance of React Router's RouterProvider
 */
export function RouterProvider({
    client,
    session,
    experienceSwitcher,
}: {
    client: GQLClient;
    session: Session;
    experienceSwitcher: IExperienceSwitcher;
}) {
    const { oktaAuth, authState } = useOktaAuth();

    // This prevents the router from being generated more than once which
    // will cause the `navigate` method used below to not work
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const router = useMemo(() => generateRouter({ client, session, oktaAuth, authState, experienceSwitcher }), []);

    useEffect(() => {
        return session.on('signedOut', ({ data }) => {
            const search: { next?: string } = data?.isUserInitiated
                ? {}
                : { next: serializeNextLocation(window.location) };

            router.navigate(getPathByName('SIGN_IN', { search }));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ReactRouterProvider router={router} />;
}
