import { DateTime } from 'luxon';

import { INVALID_DATE_TIME_VALUE, PossibleISO8601 } from 'app/core/date-time';

function dateTimeToPossibleISOFormat<T extends string | null>(
    dateTime: DateTime | null | undefined,
    format: (dateTime: DateTime) => string | null,
) {
    if (dateTime == null) {
        return null;
    }
    if (!dateTime.isValid) {
        return INVALID_DATE_TIME_VALUE;
    }
    return format(dateTime) as T;
}

export function dateTimeToPossibleISO8601(dateTime: DateTime | null | undefined): PossibleISO8601.Strict {
    return dateTimeToPossibleISOFormat<PossibleISO8601.Strict>(dateTime, dateTime => dateTime.toISO());
}

export function dateTimeToPossibleISO8601Date(dateTime: DateTime | null | undefined): PossibleISO8601.Date {
    return dateTimeToPossibleISOFormat<PossibleISO8601.Date>(dateTime, dateTime => dateTime.toISODate());
}

export function dateTimeToPossibleISO8601Time(dateTime: DateTime | null | undefined): PossibleISO8601.Time {
    return dateTimeToPossibleISOFormat<PossibleISO8601.Time>(dateTime, dateTime =>
        dateTime.toISOTime({ includeOffset: false }),
    );
}

export function dateTimeToPossibleISO8601TimeWithOffset(
    dateTime: DateTime | null | undefined,
): PossibleISO8601.TimeWithOffset {
    return dateTimeToPossibleISOFormat<PossibleISO8601.TimeWithOffset>(dateTime, dateTime => dateTime.toISOTime());
}
