import { ElementType, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import { SxPropMixin } from 'app/components/normalizeSxProp';

import Text from '../Text';

interface Props extends SxPropMixin {
    /**
     * The element type to use
     * @defaultValue `label`
     */
    as?: ElementType;

    /**
     * The label content which contextualizes the associated input field
     */
    fieldName?: ReactNode;

    /**
     * Used to associate an input field, accepting it's ID
     */
    htmlFor?: string;

    /**
     * Whether or not the input field is in an error state
     */
    error?: boolean;

    /**
     * Whether or not the input field is in an disabled state
     */
    disabled?: boolean;

    /**
     * Whether or not the input field is in an readOnly / static state
     */
    readOnly?: boolean;

    /**
     * Whether or not the input field is required
     */
    required?: boolean;

    /**
     * Whether or not to insert the prescribed bottom margin
     * @defaultValue `true`
     */
    gutterBottom?: boolean;
}

/**
 * Responsible for styling and layout of form input labels / legends
 */
export default function FieldLabel({
    as = 'label',
    fieldName,
    error,
    disabled,
    htmlFor,
    readOnly,
    required,
    gutterBottom = true,
    sx,
}: Props): ReactElement {
    const shouldRenderLabel = !!fieldName;

    const resolveLabelColor = () => {
        if (error) return 'danger';

        if (disabled) return 'secondary';

        return 'primary';
    };

    if (!shouldRenderLabel) return <></>;

    return (
        // TODO(derek): replace with spacing token once spacing ramp is updated
        //  need to use 12px to align with other form input fields
        <Box display="flex" mb={gutterBottom ? 3 : undefined} maxWidth="md" sx={sx}>
            <Text as={as} variant="detailMedium" htmlFor={htmlFor} color={resolveLabelColor()}>
                {fieldName}{' '}
                {!readOnly && required && (
                    <Text as="span" variant="inherit" color="danger">
                        *
                    </Text>
                )}
            </Text>
        </Box>
    );
}
