import {
    ComponentProps,
    CSSProperties,
    memo,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';

import Box from '@mui/material/Box';
import { alpha, SystemStyleObject } from '@mui/system';

import { Icon } from 'app/components/primitives';

import { Theme } from 'styles/theme';

import { MultiGridRenderStats } from '../MultiGrid';
import { Tooltip } from '../Tooltip';
import { clickBar, requestBarHoverEnd, startBarHover } from './actions';
import {
    BAR_HEIGHT,
    EXPANSION_TRANSITION_DURATION,
    EXPANSION_TRANSITION_EASE,
    ScheduleEventVariant,
} from './constants';
import { useScheduleContext, useScheduleDispatch, useScheduleState } from './ScheduleContext';
import { ScheduleCellCoordinates, ScheduleCellDetailsAccessor, ScheduleCellLayer } from './types';

interface LayerStats {
    /** The number of columns in the bar. */
    barLength: number;
    /** The column index of the cell closest to the center of the bar. */
    centerIndex: number;
    /** Whether the right neighboring cell will render an event layer. */
    hasEndNeighbor: boolean;
    /** Whether the left neighboring cell will render an event layer. */
    hasStartNeighbor: boolean;
    /** Whether the cell is the closest to the center of the bar. */
    isCenterColumn: boolean;
    /** Whether the cell is the end of an event and is centered. */
    isCenteredEnd: boolean;
    /** Whether the cell is the start of an event and is centered. */
    isCenteredStart: boolean;
    /** Whether the cell is the right-most cell in the bar. */
    isEnd: boolean;
    /** Whether the cell is in a row that's expanded. */
    isExpanded: boolean;
    /** Whether the cell is in a row that is being hovered over. */
    isHovering: boolean;
    /** Whether the cell is the left-most cell in the bar. */
    isStart: boolean;
    /** Whether the cell is the start of the bar and is a tail. */
    isTailStart: boolean;
    /** Whether the cell is the end of the bar, and has an end neighbor that's a tail. */
    isTailDock: boolean;
    /** Whether the cell is the second cell in a bar that is a tail. */
    isTailBase: boolean;
}

interface MakeLayerStatsProps {
    columnIndex: number;
    getCellDetails: ScheduleCellDetailsAccessor;
    hoverTargetId: string | undefined;
    isExpanded: boolean;
    layer: ScheduleCellLayer;
}

async function makeLayerStats({
    columnIndex,
    getCellDetails,
    hoverTargetId,
    isExpanded,
    layer,
}: MakeLayerStatsProps): Promise<LayerStats> {
    const {
        eventEndColumnIndex,
        eventStartColumnIndex,
        eventHasTail,
        isEventEndCentered,
        isEventStartCentered,
        rowIndex,
    } = layer;
    const prevCellColumnIndex = columnIndex - 1;
    const prevCellDetails = await getCellDetails({ columnIndex: prevCellColumnIndex, rowIndex });
    const eventStartCellDetails = await getCellDetails({ columnIndex: eventStartColumnIndex, rowIndex });
    const nextCellDetails = await getCellDetails({ columnIndex: columnIndex + 1, rowIndex });
    const isHovering = hoverTargetId === layer.eventId;
    const isStart = columnIndex === eventStartColumnIndex;
    const isSecondCell = columnIndex === eventStartColumnIndex + 1;
    const isEnd = columnIndex === eventEndColumnIndex - 1;
    const hasStartNeighbor = !isStart || prevCellDetails.layers.length > 0;
    const hasEndNeighbor = !isEnd || nextCellDetails.layers.length > 0;
    const barLength = eventEndColumnIndex - eventStartColumnIndex;
    const centerIndex = Math.floor(barLength / 2) + eventStartColumnIndex;
    const isCenterColumn = columnIndex === centerIndex;
    const isTailDock = !!eventHasTail && isEnd;
    const isWithinTail = !!eventStartCellDetails.layers.find(
        ({ eventEndColumnIndex, eventHasTail }) => eventEndColumnIndex === eventStartColumnIndex + 1 && eventHasTail,
    );
    const isTailStart = isStart && isWithinTail;
    const isTailBase = isSecondCell && isWithinTail;
    const isCenteredEnd = !!(isEnd && isEventEndCentered);
    const isCenteredStart = !!(isStart && isEventStartCentered);

    return {
        barLength,
        centerIndex,
        hasEndNeighbor,
        hasStartNeighbor,
        isCenterColumn,
        isCenteredEnd,
        isCenteredStart,
        isEnd,
        isExpanded,
        isHovering,
        isStart,
        isTailBase,
        isTailDock,
        isTailStart,
    };
}

function useLayerStats({
    columnIndex,
    getCellDetails,
    hoverTargetId,
    layer,
}: Omit<MakeLayerStatsProps, 'isExpanded'>): LayerStats | undefined {
    const [layerStats, setLayerStats] = useState<LayerStats>();
    const isExpanded = useScheduleState(({ expandedRowIndexes }) => expandedRowIndexes.includes(layer.rowIndex));

    useEffect(() => {
        makeLayerStats({
            columnIndex,
            getCellDetails,
            hoverTargetId,
            isExpanded,
            layer,
        }).then(setLayerStats);
    }, [columnIndex, getCellDetails, hoverTargetId, isExpanded, layer]);

    return layerStats;
}

interface ScheduleEventCellLayerProps {
    columnIndex: number;
    isScrolling?: boolean;
    layer: ScheduleCellLayer;
    rowIndex: number;
}

/**
 * Renders each layer of a cell in the body grid.
 */
export const ScheduleEventCellLayer = memo(function ScheduleEventCellLayer(props: ScheduleEventCellLayerProps) {
    const { columnIndex, layer } = props;
    const getCellDetails = useScheduleContext(payload => payload.data.getCellDetails);
    const hoverTargetId = useScheduleState(({ hoverTargetId }) => hoverTargetId);
    const layerStats = useLayerStats({
        columnIndex,
        getCellDetails,
        hoverTargetId,
        layer,
    });

    if (!layerStats) return null;

    return <ScheduleEventCellLayerInner {...props} layerStats={layerStats} />;
});

interface ScheduleEventCellLayerInnerProps extends ScheduleEventCellLayerProps {
    layerStats: LayerStats;
}

/**
 * Renders each layer of a cell in the body grid.
 */
const ScheduleEventCellLayerInner = memo(function ScheduleEventCellLayerInner({
    columnIndex,
    isScrolling,
    layer,
    layerStats,
    rowIndex,
}: ScheduleEventCellLayerInnerProps) {
    const dispatch = useScheduleDispatch();
    const { config, dimensions, getCellDetails, latestGridRenderStatsRef, renderTooltipContentRef } =
        useScheduleContext(payload => payload.data);

    const { bodyCellWidth } = dimensions;
    const {
        barLength,
        centerIndex,
        hasEndNeighbor,
        hasStartNeighbor,
        isCenterColumn,
        isCenteredEnd,
        isCenteredStart,
        isEnd,
        isExpanded,
        isHovering,
        isStart,
        isTailBase,
        isTailDock,
        isTailStart,
    } = layerStats;
    const {
        color: layerColor,
        eventId: layerEventId,
        eventStartColumnIndex: layerEventStartColumnIndex,
        expandedOffset: layerExpandedOffset = 0,
        secondaryColor: layerSecondaryColor,
        variant: layerVariant = ScheduleEventVariant.Solid,
    } = layer;
    const hasBorder = [ScheduleEventVariant.Ghost, ScheduleEventVariant.Outline].includes(layerVariant);

    function renderBorderRadius(): string {
        const RADIUS = '16px';
        const borderRadius = ['0', '0', '0', '0'];

        if (isExpanded) {
            if (isStart) {
                borderRadius[0] = RADIUS;
                borderRadius[3] = RADIUS;
            }
            if (isEnd) {
                borderRadius[1] = RADIUS;
                borderRadius[2] = RADIUS;
            }
        } else {
            if (isStart && !hasStartNeighbor) {
                borderRadius[0] = RADIUS;
                borderRadius[3] = RADIUS;
            }
            if (isEnd && !hasEndNeighbor) {
                borderRadius[1] = RADIUS;
                borderRadius[2] = RADIUS;
            }
        }

        if (isTailDock) {
            borderRadius[1] = RADIUS;
            borderRadius[2] = RADIUS;
        }

        return borderRadius.join(' ');
    }

    function renderBarOutlineBorderStyles(): CSSProperties {
        const WIDTH = '2px';
        const borderWidth = [
            // Top
            WIDTH,
            // Right
            isEnd ? WIDTH : '0',
            // Bottom
            WIDTH,
            // Left
            isStart ? WIDTH : '0',
        ].join(' ');

        return {
            borderWidth,
            borderStyle: 'dashed',
            borderColor: layer.color,
            // Set to add a small gap between cells to fix dashed border style
            margin: '0 1px',
        };
    }

    /**
     * Determines whether the layer matching the given coordinates is the top most
     * layer in the cell.
     */
    const isLayerOnTop = useCallback(
        async function isLayerOnTop(coords: ScheduleCellCoordinates): Promise<boolean> {
            const targetCellDetails = await getCellDetails(coords);
            // When expanded, only layers with the same expanded offset will be placed on top of each other
            const layerStack = isExpanded
                ? targetCellDetails.layers.filter(({ expandedOffset }) => expandedOffset === layer.expandedOffset)
                : targetCellDetails.layers;
            const eventStackIndex = layerStack.findIndex(({ eventId }) => eventId === layer.eventId);
            const lastLayerStackIndex = layerStack.length - 1;

            return eventStackIndex === lastLayerStackIndex;
        },
        [getCellDetails, isExpanded, layer],
    );

    const [latestGridRender, setLatestGridRender] = useState<MultiGridRenderStats | undefined>(
        latestGridRenderStatsRef.current,
    );

    // Update `latestGridRender` whenever scrolling stops
    useEffect(() => {
        if (isScrolling === false) {
            setLatestGridRender(latestGridRenderStatsRef.current);
        }
    }, [isScrolling, latestGridRenderStatsRef]);

    const isCellRendered = useCallback(
        function isCellRendered(coords: ScheduleCellCoordinates): boolean {
            if (!latestGridRender) return false;

            return (
                coords.columnIndex >= latestGridRender.visibleColumnStartIndex &&
                coords.columnIndex <= latestGridRender.visibleColumnStopIndex &&
                coords.rowIndex >= latestGridRender.visibleRowStartIndex &&
                coords.rowIndex <= latestGridRender.visibleRowStopIndex
            );
        },
        [latestGridRender],
    );

    const isCellVisible = useCallback(
        async function isCellVisible(coords: ScheduleCellCoordinates): Promise<boolean> {
            // Explicity check `isCellRendered` first, because calling `isLayerOnTop` is expensive.
            if (!isCellRendered(coords)) return false;

            return isLayerOnTop(coords);
        },
        [isCellRendered, isLayerOnTop],
    );

    const shouldRenderTooltipAnchor = useCallback(
        async function shouldRenderTooltipAnchor(): Promise<boolean> {
            // Don't render anchors on the tail base,
            // because it'll overlap with the previous event bar.
            if (isTailStart) return false;

            // If the center cell is visible
            if (await isCellVisible({ columnIndex: centerIndex, rowIndex })) {
                // Then whether the anchor is rendered is determined by
                // whether the cell is the center column.
                return isCenterColumn;
            }

            const maxOffset = centerIndex - layerEventStartColumnIndex + 1;

            // Otherwise, search for a visible cell to render the anchor
            // starting from the center outwards.
            for (let offset = 1; offset < maxOffset; offset++) {
                const targetColumnIndexes = [centerIndex + offset, centerIndex - offset];

                for (const targetColumnIndex of targetColumnIndexes) {
                    if (await isCellVisible({ columnIndex: targetColumnIndex, rowIndex })) {
                        return columnIndex === targetColumnIndex;
                    }
                }
            }

            return false;
        },
        [centerIndex, columnIndex, isCellVisible, isCenterColumn, isTailStart, layerEventStartColumnIndex, rowIndex],
    );

    const isBarEvenLength = !(barLength % 2);
    const shouldOffsetAnchor =
        isBarEvenLength &&
        isCenterColumn &&
        // Don't offset the anchor if the cell is a tail base,
        // because it'll cause the anchor to be hidden behind
        // the previous bar that has the tail.
        !isTailBase;

    function renderTooltipAnchor(): ReactElement {
        const { iconName } = layer;

        return (
            <Box
                sx={theme => ({
                    alignItems: 'center',
                    color: layerVariant === ScheduleEventVariant.Solid ? theme.palette.common.white : layerColor,
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    opacity: isScrolling ? '0' : '1',
                    // Offset the anchor so that it aligns with the center of the bar.
                    transform: shouldOffsetAnchor ? 'translateX(-50%)' : undefined,
                    transitionDuration: isScrolling ? '200ms' : '600ms',
                    transitionProperty: 'opacity',
                    transitionTimingFunction: 'ease-in-out',
                })}
            >
                {iconName && <Icon name={iconName} size="sm" />}
            </Box>
        );
    }

    const handleLayerClick = useCallback<MouseEventHandler<HTMLElement>>(
        () => dispatch(clickBar({ eventId: layerEventId })),
        [dispatch, layerEventId],
    );

    const handleMouseOver = useCallback<MouseEventHandler<HTMLElement>>(() => {
        // `barHoverStart` should be allowed to be dispatched even when the layer
        // is already in hover state. This allows for the layer's tooltip to restart
        // the hover state when it is hovered over.
        dispatch(startBarHover({ eventId: layerEventId }));
    }, [dispatch, layerEventId]);
    const handleMouseLeave = useCallback<MouseEventHandler<HTMLElement>>(
        () => dispatch(requestBarHoverEnd()),
        [dispatch],
    );

    function renderTooltipContent(): ReactNode {
        const event = config.events.find(({ id }) => id === layer.eventId);

        return renderTooltipContentRef.current?.({ event, layer });
    }

    function renderTooltipProps(): Omit<ComponentProps<typeof Tooltip>, 'children'> {
        const content = renderTooltipContent();
        const hasContent = content != null;
        const tooltipOffset = Math.floor(bodyCellWidth / -2);

        return {
            content,
            disableFocusListener: true,
            disableHoverListener: true,
            disableTouchListener: true,
            open: isHovering && hasContent && isRenderingTooltipAnchor,
            PopperProps: {
                modifiers: [
                    // Offset the tooltip so that it aligns with tooltip anchor.
                    {
                        name: 'offset',
                        options: { offset: shouldOffsetAnchor ? [tooltipOffset, 0] : [0, 0] },
                    },
                ],
            },
            slotProps: {
                arrow: {
                    sx: {
                        // Offset the arrow so that it aligns with tooltip.
                        marginLeft: shouldOffsetAnchor ? `${tooltipOffset}px` : undefined,
                    },
                },
                tooltip: {
                    // Allows the tooltip to close itself when leaving hover state
                    onMouseLeave: handleMouseLeave,
                    // Allows the tooltip to keep itself open during its hover state
                    onMouseOver: handleMouseOver,
                },
            },
        };
    }

    const [isRenderingTooltipAnchor, setIsRenderingTooltipAnchor] = useState(false);

    useEffect(() => {
        shouldRenderTooltipAnchor().then(setIsRenderingTooltipAnchor);
    }, [shouldRenderTooltipAnchor]);

    function renderLayerBackgroundColor() {
        switch (layerVariant) {
            case ScheduleEventVariant.Solid:
                return layerColor;
            case ScheduleEventVariant.Ghost:
                return layerSecondaryColor ?? alpha(layerColor, 0.15);
            default:
                return undefined;
        }
    }

    function renderBoxShadow() {
        const backgroundOverlap = hasBorder ? 2 : 0;

        return `${backgroundOverlap}px 0px ${renderLayerBackgroundColor()}`;
    }

    function renderLayerInnerStyle(): SystemStyleObject<Theme> {
        return {
            backgroundColor: renderLayerBackgroundColor(),
            borderRadius: renderBorderRadius(),
            boxShadow: renderBoxShadow(),
            boxSizing: 'border-box',
            height: BAR_HEIGHT.as('px'),
            transitionDuration: EXPANSION_TRANSITION_DURATION,
            transitionProperty: 'border-radius',
            transitionTimingFunction: EXPANSION_TRANSITION_EASE,
            ...(hasBorder ? renderBarOutlineBorderStyles() : {}),
        };
    }

    function renderLayerStyle(): SystemStyleObject<Theme> {
        return {
            height: BAR_HEIGHT.as('px'),
            left: isCenteredStart ? '50%' : 0,
            position: 'absolute',
            right: isCenteredEnd ? '50%' : 0,
            top: 0,
            transform: `translateY(${isExpanded ? layerExpandedOffset : 0}px)`,
            transitionProperty: 'transform',
            transitionDuration: EXPANSION_TRANSITION_DURATION,
            transitionTimingFunction: EXPANSION_TRANSITION_EASE,
        };
    }

    // Sadly, the `Tooltip` component must always be rendered,
    // even if the tooltip is disabled.
    // Changing the wrapping component causes its children to remount
    // and break transitions.
    return (
        <Tooltip {...renderTooltipProps()}>
            <Box
                onClick={handleLayerClick}
                onMouseLeave={handleMouseLeave}
                onMouseOver={handleMouseOver}
                sx={renderLayerStyle}
            >
                <Box sx={renderLayerInnerStyle}>{isRenderingTooltipAnchor && renderTooltipAnchor()}</Box>
            </Box>
        </Tooltip>
    );
});
