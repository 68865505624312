import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

interface AccountInviteFormProps {
    onSubmit: (props: { email: string }) => Promise<void>;
}

export function AccountInviteForm({ onSubmit }: AccountInviteFormProps) {
    const form = useFormik<{ email: string }>({
        initialValues: {
            email: '',
        },
        onSubmit: async ({ email }) => await onSubmit({ email }),
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: yup.object().shape({
            email: yup.string().email('Email address is not valid'),
        }),
    });

    const { t } = useI18n();

    return (
        <form
            onSubmit={async e => {
                e.preventDefault();
                await form.submitForm();
                form.resetForm();
            }}
        >
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    name="email"
                    placeholder={t('account_invite_form.placeholder.email')}
                    value={form.values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />

                <Button
                    sx={{ wordBreak: 'keep-all' }}
                    variant="contained"
                    disabled={!form.values.email || !form.isValid}
                    type="submit"
                >
                    {t('account_invite_form.cta.invite')}
                </Button>
            </Box>

            <Box sx={{ display: 'flex' }}>
                <Text variant="detail" color="danger" preserveHeight={1}>
                    {form.touched.email && form.errors.email}
                </Text>
            </Box>
        </form>
    );
}
