import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback } from '@okta/okta-react';
import { createBrowserRouter, Outlet, redirect } from 'react-router-dom';

import { AppChrome, PageSetup } from 'app/components/page-layout';
import { IExperienceSwitcher, ISession } from 'app/core/types';
import {
    AccountIndexPage,
    AccountManagementIndexPage,
    AccountPage,
    CreateAccountPage,
    InviteUserPage,
    UserIndexPage,
    UserPage,
} from 'app/pages/AccountManagement';
import { AdminReservationPage } from 'app/pages/AdminReservationPage';
import UpdateUnitCountPage from 'app/pages/AdminReservationPage/UpdateUnitCountPage';
import { AdminReservationsPage, ReservationsKanbanPage, ReservationsTablePage } from 'app/pages/AdminReservationsPage';
import AlertsPage from 'app/pages/AlertsPage';
import CustomerReservationsPage from 'app/pages/CustomerReservationsPage';
import DocumentsPage from 'app/pages/DocumentPage';
import { MPUDetailPage, MPUFaultsPage, MPUIndexPage } from 'app/pages/mpus';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { ProfilePage } from 'app/pages/ProfilePage';
import { RenterAccountDashboard } from 'app/pages/RenterAccountDashboard';
import RenterReservationRequestDialog from 'app/pages/RenterReservationRequestDialog';
import ReservationMonitoringPage from 'app/pages/ReservationMonitoringPage';
import ResetPasswordPage from 'app/pages/ResetPasswordPage';
import SignInPage from 'app/pages/SignInPage';
import SignUpPage from 'app/pages/SignUpPage';
import { TransitionIndexPage } from 'app/pages/TransitionIndexPage';

import { GQLClient } from '../network/types';
import {
    generateHomeLoader,
    generateRequireAdminRoleLoader,
    generateRequireAnonLoader,
    generateRequireAuthLoader,
    generateRequireMoxionFleetManagerAccessLoader,
    generateRequireOwnerRoleLoader,
    generateRequireRenterAccessLoader,
} from './authLoaders';
import getPathByName from './getPathByName';
import { chainLoaders, generateRequireInviteLoader, generateRequireOktaAuthLoader } from './loaders';
import makeChildRedirect from './makeChildRedirect';
import makeRedirectRoute from './makeRedirectRoute';

/**
 * This serializes a location within the app (potentially, it doesn't guarentee the path can be resolved).
 * We remove the origin so we only allow next locations within the app. Else we'd be inviting open redirects
 * which would be bad.
 */
export function serializeNextLocation(location: Location) {
    return location.pathname + location.hash + location.search;
}

/**
 * Redirect to the signIn page while preserving the inbound location
 */
async function redirectToSignIn({
    saveCurrentLocation = true,
    search,
}: { saveCurrentLocation?: boolean; search?: { [key: string]: string } } = {}): Promise<Response> {
    let finalSearch: { [key: string]: string } = search ?? {};

    if (saveCurrentLocation) {
        finalSearch.next = serializeNextLocation(window.location);
    }

    return redirect(
        getPathByName('SIGN_IN', {
            search: finalSearch,
        }),
    );
}
export type RedirectToSignInFunc = typeof redirectToSignIn;

/**
 * @param args client, oktaAuth, and authState required to generate loaders
 * @returns instance of Remix router
 */
export function generateRouter({
    client,
    session,
    experienceSwitcher,
    oktaAuth,
    authState,
}: {
    client: GQLClient;
    session: ISession;
    oktaAuth: OktaAuth;
    authState: AuthState | null;
    experienceSwitcher: IExperienceSwitcher;
}) {
    // these loaders check current session and Okta state to determine whether the user
    // can access a given route
    const requireAuthLoader = generateRequireAuthLoader({
        session,
        redirectToSignIn,
    });
    const requireAnonLoader = generateRequireAnonLoader(session);
    const requireAdminRoleLoader = generateRequireAdminRoleLoader(session);
    const requireMoxionFleetManagerRoleLoader = generateRequireMoxionFleetManagerAccessLoader(session);
    const requireRenterAccessLoader = generateRequireRenterAccessLoader(session);
    const requireOwnerRoleLoader = generateRequireOwnerRoleLoader(session);
    const requireOktaAuthLoader = generateRequireOktaAuthLoader(oktaAuth, authState ?? undefined);
    const requireHomeLoader = generateHomeLoader({
        session,
        redirectToSignIn,
        getHomePathName: experienceSwitcher.getHomePathName.bind(experienceSwitcher),
    });
    const requireInviteLoader = generateRequireInviteLoader({ client, redirectToSignIn });

    return createBrowserRouter([
        {
            path: '/',
            element: (
                <PageSetup>
                    <Outlet />
                </PageSetup>
            ),
            errorElement: (
                <PageSetup>
                    <AppChrome>
                        <NotFoundPage />
                    </AppChrome>
                </PageSetup>
            ),
            children: [
                {
                    path: 'signin',
                    loader: requireAnonLoader,
                    element: <SignInPage />,
                },

                {
                    path: 'signup',
                    loader: chainLoaders(requireInviteLoader, requireAnonLoader),
                    element: <SignUpPage />,
                },

                {
                    path: 'reset-password',
                    loader: requireAnonLoader,
                    element: <ResetPasswordPage />,
                },

                {
                    path: 'home',
                    loader: requireHomeLoader,
                },

                {
                    path: 'documents/:documentType/create',
                    loader: requireAdminRoleLoader,
                    element: <DocumentsPage />,
                },

                {
                    path: '',
                    loader: requireAuthLoader,
                    element: (
                        <AppChrome>
                            <Outlet />
                        </AppChrome>
                    ),

                    children: [
                        {
                            path: 'profile',
                            element: <ProfilePage />,
                        },

                        {
                            loader: requireMoxionFleetManagerRoleLoader,
                            path: 'alerts',
                            element: <AlertsPage />,
                        },

                        // Customer accessible pages
                        {
                            loader: requireRenterAccessLoader,
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <RenterAccountDashboard />,
                                },

                                {
                                    path: 'reservations',
                                    element: <CustomerReservationsPage />,
                                },

                                {
                                    path: 'reservations/:reservationID/monitor',
                                    element: <ReservationMonitoringPage />,
                                },

                                {
                                    path: 'reserve',
                                    Component: RenterReservationRequestDialog,
                                },
                            ],
                        },

                        // Admin Accessible Pages
                        {
                            path: 'rental-management',
                            loader: requireAdminRoleLoader,
                            children: [
                                {
                                    path: '',
                                    element: makeChildRedirect(() => getPathByName('RENTAL_MGMT_RESERVATIONS')),
                                },
                                {
                                    path: 'reservations',
                                    element: <AdminReservationsPage />,
                                    children: [
                                        {
                                            path: '',
                                            element: makeChildRedirect(
                                                () => getPathByName('RENTAL_MGMT_RESERVATIONS_TABLE'),
                                                <ReservationsTablePage />,
                                            ),
                                        },
                                        {
                                            path: 'table',
                                            element: <ReservationsTablePage />,
                                        },
                                        {
                                            path: 'kanban',
                                            element: <ReservationsKanbanPage />,
                                        },
                                    ],
                                },

                                {
                                    path: 'reservations/:reservationID',
                                    element: <AdminReservationPage />,
                                    children: [
                                        {
                                            path: 'update-unit-count',
                                            element: <UpdateUnitCountPage />,
                                        },
                                    ],
                                },

                                {
                                    path: 'transitions',
                                    element: <TransitionIndexPage />,
                                },
                            ],
                        },

                        {
                            path: 'account-management',
                            loader: requireAdminRoleLoader,
                            children: [
                                {
                                    path: '',
                                    element: <AccountManagementIndexPage />,
                                    children: [
                                        {
                                            path: '',
                                            element: makeChildRedirect(
                                                () => getPathByName('ACCOUNT_MGMT_ACCOUNTS'),
                                                <AccountIndexPage />,
                                            ),
                                        },
                                        {
                                            path: 'accounts',
                                            element: <AccountIndexPage />,

                                            children: [
                                                {
                                                    path: 'create',
                                                    element: <CreateAccountPage />,
                                                },
                                            ],
                                        },
                                        {
                                            path: 'users',
                                            element: <UserIndexPage />,
                                            children: [
                                                {
                                                    path: 'invite',
                                                    element: <InviteUserPage />,
                                                },
                                            ],
                                        },
                                    ],
                                },

                                {
                                    path: 'accounts/:accountID',
                                    element: <AccountPage />,
                                    children: [
                                        {
                                            path: 'invite-user',
                                            element: <InviteUserPage />,
                                        },
                                    ],
                                },

                                {
                                    path: 'users/:userID',
                                    element: <UserPage />,
                                },
                            ],
                        },

                        // Owner Accessible Pages
                        {
                            path: '',
                            loader: requireOwnerRoleLoader,
                            children: [
                                {
                                    path: 'mpus',
                                    element: <MPUIndexPage />,
                                },

                                {
                                    path: 'mpus/faults',
                                    element: <MPUFaultsPage />,
                                },

                                {
                                    path: 'mpus/:mpuID',
                                    element: <MPUDetailPage />,
                                },
                            ],
                        },

                        // Redirects: Keeping these routes around for backward compatibility. We may decide to no longer support these in the future
                        // but we'll want to make sure all references (i.e. Emails) are updated prior to removal
                        ...[
                            {
                                path: '/owner/units',
                                target: '/mpus',
                            },
                            {
                                path: '/owner/units/:deviceInstanceID',
                                target: '/mpus/:mpuID',
                                mapParams: {
                                    deviceInstanceID: 'mpuID',
                                },
                            },
                            {
                                path: '/admin/dashboard/units',
                                target: '/mpus',
                            },
                            {
                                path: '/admin/dashboard/units/:deviceInstanceID',
                                target: '/mpus/:mpuID',
                                mapParams: {
                                    deviceInstanceID: 'mpuID',
                                },
                            },

                            {
                                path: '/admin/dashboard/reservations',
                                target: '/rental-management/reservations',
                            },
                            {
                                path: '/admin/dashboard/reservations/:reservationID',
                                target: '/rental-management/reservations/:reservationID',
                            },
                            {
                                path: '/admin/dashboard/transitions',
                                target: '/rental-management/transitions',
                            },
                        ].map(makeRedirectRoute),
                    ],
                },

                // Okta related routes
                {
                    path: 'protected',
                    loader: requireOktaAuthLoader,
                    element: <ProfilePage />,
                },

                {
                    path: 'login/callback',
                    element: <LoginCallback />,
                },
            ],
        },
    ]);
}
