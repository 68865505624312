import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLayer } from 'app/core/layers';

import { TranslateFunction, useI18n } from 'i18n';

function resolveInviteWarning(
    reason: string | null,
    { t }: { t: TranslateFunction },
): { title?: string; message: string } | null {
    switch (reason) {
        case 'dne':
            return {
                title: t('signin_page.invite_dne_title'),
                message: t('signin_page.invite_dne_message'),
            };
        case 'expired':
            return {
                title: t('signin_page.invite_expired_title'),
                message: t('signin_page.invite_expired_message'),
            };
        case 'claimed':
            return {
                title: t('signin_page.invite_claimed_title'),
                message: t('signin_page.invite_claimed_message'),
            };
        default:
            return null;
    }
}

/**
 * Hook to notify the user of an issue with an invite (i.e. invite has already been claimed or if it does
 * not exist)
 */
export default function useInviteWarning() {
    const { t } = useI18n();
    const { toast } = useLayer();

    const [searchParams] = useSearchParams();

    const inviteWarning = resolveInviteWarning(searchParams.get('invite'), { t });

    useEffect(() => {
        if (inviteWarning) {
            toast.add({
                severity: 'warning',
                ...inviteWarning,
            });
        }
        // Only call on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
