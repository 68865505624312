import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { DeviceInstancePaginationFilters, SortDirection } from 'generated/graphql';

const ALLOWED_STRING_OPERATORS = ['contains', 'isAnyOf'];
const ALLOWED_SINGLE_SELECT_OPERATORS = ['is'];

export default class DataGridToGQLAdapter {
    private static gridSortToGqlSortMap: { [key: string]: SortDirection } = {
        asc: SortDirection.Asc,
        desc: SortDirection.Desc,
    };

    private static gridFieldNameToGqlFieldMap: { [key: string]: string } = {
        name: 'name',
        serialNumber: 'serialNumber',
        externalID: 'externalId',
        ownerName: 'owner.name',
        serviceAreaName: 'serviceArea.name',
        particleDeviceID: 'assetid',
    };

    public static getGqlSortBy(sortModel?: GridSortModel): { field: string; direction: SortDirection } {
        if (
            !sortModel ||
            sortModel.length === 0 ||
            !sortModel[0].sort ||
            !this.gridFieldNameToGqlFieldMap[sortModel[0].field]
        ) {
            return { field: 'name', direction: SortDirection.Asc };
        }

        // NOTE: cannot handle sorting by multiple fields at this point
        return {
            field: this.gridFieldNameToGqlFieldMap[sortModel[0].field],
            direction: this.gridSortToGqlSortMap[sortModel[0].sort],
        };
    }

    public static getGqlFilter(
        filterModel: GridFilterModel | undefined,
        defaultFilters: DeviceInstancePaginationFilters = { ownerAccountIDs: [] },
    ): DeviceInstancePaginationFilters {
        if (!filterModel) {
            return defaultFilters;
        }

        let deviceNames = defaultFilters.deviceNames;
        const nameFilterItem = filterModel.items.find(item => item.field === 'name');

        if (nameFilterItem && ALLOWED_STRING_OPERATORS.includes(nameFilterItem.operator) && nameFilterItem.value) {
            deviceNames = [].concat(nameFilterItem.value);
        }

        let serialNumbers = defaultFilters.serialNumbers;
        const serialNumberFilterItem = filterModel.items.find(item => item.field === 'serialNumber');

        if (
            serialNumberFilterItem &&
            ALLOWED_STRING_OPERATORS.includes(serialNumberFilterItem.operator) &&
            serialNumberFilterItem.value
        ) {
            serialNumbers = [].concat(serialNumberFilterItem.value);
        }

        let externalIDs = defaultFilters.externalIDs;
        const externalIDFilterItem = filterModel.items.find(item => item.field === 'externalID');

        if (
            externalIDFilterItem &&
            ALLOWED_STRING_OPERATORS.includes(externalIDFilterItem.operator) &&
            externalIDFilterItem.value
        ) {
            externalIDs = [].concat(externalIDFilterItem.value);
        }

        // IMPORTANT(will): ownerAccountIDs must not be overwritten by the results of the DataGridToGQLAdapter's paginationVariables
        let ownerAccountIDs: string[] = defaultFilters.ownerAccountIDs;
        const ownerNameFilterItem = filterModel.items.find(item => item.field === 'ownerName');

        if (
            ownerNameFilterItem &&
            ALLOWED_SINGLE_SELECT_OPERATORS.includes(ownerNameFilterItem.operator) &&
            ownerNameFilterItem.value
        ) {
            ownerAccountIDs = ownerAccountIDs.concat(ownerNameFilterItem.value);
        }

        let serviceAreaIDs = defaultFilters.serviceAreaIDs;
        const serviceAreaNameFilterItem = filterModel.items.find(item => item.field === 'serviceAreaName');

        if (
            serviceAreaNameFilterItem &&
            ALLOWED_SINGLE_SELECT_OPERATORS.includes(serviceAreaNameFilterItem.operator) &&
            serviceAreaNameFilterItem.value
        ) {
            serviceAreaIDs = [].concat(serviceAreaNameFilterItem.value);
        }

        let particleDeviceIDs = defaultFilters.particleDeviceIDs;
        const particleDeviceIDFilterItem = filterModel.items.find(item => item.field === 'particleDeviceID');

        if (
            particleDeviceIDFilterItem &&
            ALLOWED_STRING_OPERATORS.includes(particleDeviceIDFilterItem.operator) &&
            particleDeviceIDFilterItem.value
        ) {
            particleDeviceIDs = [].concat(particleDeviceIDFilterItem.value);
        }
        return { deviceNames, serialNumbers, externalIDs, ownerAccountIDs, serviceAreaIDs, particleDeviceIDs };
    }
}
