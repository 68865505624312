import { ComponentProps, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

import Text from 'app/components/primitives/Text';

interface Props extends ComponentProps<typeof Box> {
    value?: ReactNode;

    unit?: string;

    size?: 'md' | 'lg';
}

export default function ValueWithUnit({ value = '-', unit, size = 'md', ...boxProps }: Props): ReactElement {
    return (
        <Box {...boxProps} display="inline-flex" alignItems="baseline" gap={2}>
            <Text variant={size === 'lg' ? 'data' : 'dataSmall'}>{value}</Text>
            {!!unit && <Text variant={size === 'lg' ? 'dataSmall' : 'body'}>{unit}</Text>}
        </Box>
    );
}
