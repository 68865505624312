import { ChangeEvent, ElementType, forwardRef, ReactElement, ReactNode, Ref } from 'react';

import MUICheckbox from '@mui/material/Checkbox';

import { MUIMarginProps } from 'styles/theme/types';

import Layer from '../../layout/Layer';
import Text from '../../Text';

interface Props extends MUIMarginProps {
    /**
     * Unique id to assign to the checkbox input
     */
    id?: string;

    /**
     * The name to associated with the checked state and optional value
     */
    name?: string;

    /**
     * Whether the checkbox is checked or not
     */
    checked: boolean;

    /**
     * Whether to visually indicate the checkbox state as "indeterminate"
     */
    indeterminate?: boolean;

    /**
     * Additional value to associate with the checkbox state
     */
    value?: string;

    /**
     * Whether the checkbox selection is required
     */
    required?: boolean;

    /**
     * Whether the checkbox input is disabled or not
     */
    disabled?: boolean;

    /**
     * The element to use as the checkbox parent, the default is label so it will be properly announced for screen readers
     * and extends the tap area. When using the checkbox within a MenuItem "as" should be set to something other than "label" as
     * it conflicts with setting the checked state
     * @defaultValue "label"
     */
    as?: ElementType;

    /**
     * The text to describe the checkbox input
     */
    label?: ReactNode;

    /**
     * Event handler to be called when the checkbox checked state changes
     */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function resolveLabelColor({ checked, disabled }) {
    if (disabled && checked) return 'background.infoDark.light';
    if (disabled) return 'disabled';

    if (!checked) return 'primary';

    return 'info';
}

export default forwardRef(function Checkbox(
    {
        id,
        label,
        as = 'label',
        checked = false,
        disabled = false,
        required = false,
        indeterminate = false,
        value,
        name,
        onChange,
        ...marginProps
    }: Props,
    ref: Ref<HTMLInputElement>,
): ReactElement {
    const isLabel = as === 'label';

    return (
        <Layer
            {...marginProps}
            anchor
            component={as}
            sx={{
                display: 'inline-flex',
                gap: 3,
                cursor: isLabel ? 'pointer' : null,

                '> .hover-indicator': {
                    display: 'none',
                },

                '&:hover > .hover-indicator': isLabel
                    ? {
                          display: 'block',
                      }
                    : null,
            }}
        >
            <Layer
                className="hover-indicator"
                sx={theme => ({
                    width: '100%',
                    height: '100%',
                    boxSizing: 'content-box',
                    backgroundColor: 'action.hover',
                    borderRadius: `${theme.shape.borderRadius}px`,
                    p: 1,
                    pointerEvents: 'none',
                })}
            />

            <MUICheckbox
                id={id}
                disabled={disabled}
                required={required}
                checked={checked}
                indeterminate={indeterminate}
                inputRef={ref}
                inputProps={{ name, value }}
                onChange={onChange}
            />

            {!!label && (
                <Text sx={{ position: 'relative' }} color={resolveLabelColor({ disabled, checked })}>
                    {label}
                </Text>
            )}
        </Layer>
    );
});
