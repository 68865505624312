import { createContext, ReactElement, useContext } from 'react';

import { PermissiveChildrenProp } from '../types';
import DialogLayer from './DialogLayer';
import { FocusTrapProvider, initFocusTrap } from './focus-trap';
import LayerConnector from './LayerConnector';
import ToastContainer from './ToastContainer';
import { ILayerAPI } from './types';

interface Props {
    children: PermissiveChildrenProp;
}

const focusTrapAPI = initFocusTrap();
const toast = new LayerConnector({ options: { autoDismiss: true, dismissAfterMS: 4000, limit: 2 } });
const dialog = new LayerConnector({ options: { autoDismiss: false } });
const layersFacade = { toast, dialog };

const LayerContext = createContext<ILayerAPI>(layersFacade);

/**
 * Responsible for setting up the target layers and exposing the API for controlling access
 * to the layers
 */
export default function LayerProvider({ children }: Props): ReactElement {
    return (
        <FocusTrapProvider focusTrapAPI={focusTrapAPI}>
            <LayerContext.Provider value={layersFacade}>
                {children}

                <ToastContainer layerConnector={toast} />
                <DialogLayer layerConnector={dialog} />
            </LayerContext.Provider>
        </FocusTrapProvider>
    );
}

/**
 * Provides access to the underlying layers APIs to manage the display of global layered UI including toasts
 * and eventually dialogs
 */
export function useLayer(): ILayerAPI {
    return useContext(LayerContext);
}
