import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { Text } from 'app/components/primitives';
import { DeviceInstance } from 'app/core/data';

import { useI18n } from 'i18n';

export function MpuInfoTooltipContent({ deviceInstance }: { deviceInstance?: DeviceInstance }): ReactElement {
    const { format, t } = useI18n();
    const { ownerCompanyName, modelName, serviceArea, stateOfCharge, timeToEmpty } = deviceInstance || {};

    return (
        <Box>
            <Text variant="detail" as="div">
                {t('mpu_index_schedule.tooltip.owner')}: {ownerCompanyName || t('unknown')}
            </Text>
            <Text variant="detail" as="div">
                {t('mpu_index_schedule.tooltip.model')}: {modelName || t('unknown')}
            </Text>
            <Text variant="detail" as="div">
                {t('mpu_index_schedule.tooltip.service_area')}: {serviceArea || t('unknown')}
            </Text>
            <Text variant="detail" as="div">
                {t('mpu_index_schedule.tooltip.soc')}: {format.soc(stateOfCharge).toString()}
            </Text>
            <Text variant="detail" as="div">
                {t('mpu_index_schedule.tooltip.tte')}: {format.ette(timeToEmpty).toString()}
            </Text>
        </Box>
    );
}
