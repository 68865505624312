import { ReactElement, useCallback } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import getDayColor from './getDayColor';
import getDayTextColor from './getDayTextColor';
import getRangeColors from './getRangeColors';
import getRangeExtents from './getRangeExtents';
import getRangeRadii from './getRangeRadii';

type DateSelection = DateTime | null | undefined;

interface DateRange {
    start: DateSelection;
    end: DateSelection;
}

interface DateRangeWithCandidate extends DateRange {
    candidate: DateSelection;
}

export interface DateRangeChangeEvent {
    value: DateRangeWithCandidate;
}

interface Props extends Omit<PickersDayProps<DateTime>, 'onChange'> {
    /**
     * Determines how the day selection effects the selected range
     */
    selectionTarget?: 'auto' | 'start' | 'end';

    /**
     * The start date for the range
     */
    start?: DateTime | null;

    /**
     * The end date for the range
     */
    end?: DateTime | null;

    /**
     * Candidate end or start date (depending on selectionTarget)
     */
    candidate?: DateTime | null;

    /**
     * Whether or not the given start day is invalid
     */
    hasStartError?: boolean;

    /**
     * Whether or not the given end day is invalid
     */
    hasEndError?: boolean;

    /**
     * Event handler for passing back changes to the range selection
     */
    onChange?: (event: DateRangeChangeEvent) => void;
}

const noop = () => {};

/**
 * Collaborates with DateRangeCalendar to display date ranges and selection of date ranges
 */
export default function RangePickerDay({
    // values
    start,
    end,
    candidate,

    selectionTarget = 'auto',
    hasStartError,
    hasEndError,

    // Reused PickersDay props
    disabled,
    today,
    outsideCurrentMonth,
    isFirstVisibleCell = false,
    isLastVisibleCell = false,

    // event handlers
    onChange = (event: DateRangeChangeEvent) => {},

    // make sure to pass through all other props to PickersDay
    ...props
}: Props): ReactElement {
    const theme = useTheme();
    const { day } = props;

    const isToday = today;

    const rangeBackgroundColors = getRangeColors({
        day,
        candidate,
        start,
        end,
        selectionTarget,

        color1: theme.palette.background.info.main,
        color2: theme.palette.action.hover,
    });

    const dayBackgroundColor = getDayColor({
        day,
        start,
        candidate,
        end,

        hasStartError,
        hasEndError,

        color1: theme.palette.primary.main,
        color2: theme.palette.primary.dark,
        color3: theme.palette.background.secondaryDark.main,
        color4: theme.palette.error.main,
    });

    const dayTextColor = getDayTextColor({
        disabled,
        outsideCurrentMonth,
        dayBackgroundColor,
        theme,
    });

    const selectCandidate = useCallback(() => {
        onChange({
            value: {
                start,
                end,
                candidate: day,
            },
        });
    }, [day, start, end, onChange]);

    const selectDay = useCallback(() => {
        function autoSelect(): DateRange {
            const isResetSelection = !!start && day <= start;

            const newStart = isResetSelection || !start ? day : start;
            const newEnd = isResetSelection || !start ? null : day;

            return { start: newStart, end: newEnd };
        }

        let startAndEnd: DateRange = {
            start: null,
            end: null,
        };

        if (selectionTarget === 'auto') {
            startAndEnd = autoSelect();
        } else if (selectionTarget === 'end') {
            startAndEnd = { start, end: day };
        } else {
            startAndEnd = { start: day, end };
        }

        onChange({
            value: { start: startAndEnd.start, end: startAndEnd.end, candidate },
        });
    }, [selectionTarget, day, start, end, candidate, onChange]);

    return (
        <PickersDay
            {...props}
            day={day}
            today={today}
            isFirstVisibleCell={isFirstVisibleCell}
            isLastVisibleCell={isLastVisibleCell}
            outsideCurrentMonth={outsideCurrentMonth}
            disabled={disabled}
            sx={{
                width: '40px',
                height: '40px',
                position: 'relative',
                backgroundColor: 'transparent !important',
                border: 'none',

                '&:not(.Mui-selected)': {
                    border: 'none',
                },
            }}
            onMouseEnter={selectCandidate}
            onFocus={selectCandidate}
            onClick={selectDay}
            onDaySelect={noop}
        >
            {/* Range underlayment */}
            <Box
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    overflow: 'hidden',

                    ...getRangeExtents({ day, start, end, candidate, selectionTarget }),
                    ...getRangeRadii({ day }),
                }}
            >
                <Box
                    sx={{
                        width: '50%',
                        backgroundColor: rangeBackgroundColors[0],
                    }}
                />

                <Box
                    sx={{
                        width: '50%',
                        backgroundColor: rangeBackgroundColors[1],
                    }}
                />
            </Box>

            {/* Day bubble */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    border: isToday ? '1px solid black' : null,

                    backgroundColor: dayBackgroundColor,
                    color: dayTextColor,
                }}
            >
                {props.day.day}
            </Box>
        </PickersDay>
    );
}
