import React from 'react';
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const cellStyles: Style = {
    height: 'auto',
    padding: 4,
    border: '1px solid #EEE',
    minHeight: 20,
};

interface TableCellProps {
    children?: React.ReactNode;
    style?: Style;
}

const TableCell: React.FC<TableCellProps> = ({ children, style = {} }) => {
    return <View style={{ ...cellStyles, ...style }}>{children}</View>;
};

export default TableCell;
