import { ReactElement } from 'react';

import Stack from '@mui/material/Stack';

import ActiveFaultsBySeverity from './ActiveFaultsBySeverity';

interface Props {
    /**
     * The fault codes deemed critical
     */
    criticalFaultCodes?: string[];

    /**
     * The fault codes deemed non-critical
     */
    nonCriticalFaultCodes?: string[];

    /**
     * Where to link to, to lead the user to more information on the faults surfaced in the list. If omitted no
     * linking behavior is present
     */
    faultsHREF?: string;
}

/**
 * Responsible for laying out the groups of faults
 */
export default function ActiveFaults({ criticalFaultCodes, nonCriticalFaultCodes, faultsHREF }: Props): ReactElement {
    return (
        <Stack spacing={3}>
            <ActiveFaultsBySeverity severity="critical" faultCodes={criticalFaultCodes} faultsHREF={faultsHREF} />

            <ActiveFaultsBySeverity severity="info" faultCodes={nonCriticalFaultCodes} faultsHREF={faultsHREF} />
        </Stack>
    );
}
