import { alpha } from '@mui/material';

import { Layer, LoadingIndicator } from 'app/components/primitives';

export function ScheduleLoadingOverlay() {
    return (
        <Layer
            fill="anchor"
            sx={theme => ({
                alignItems: 'center',
                backgroundColor: alpha(theme.palette.background.paper!, 0.8),
                display: 'flex',
                justifyContent: 'center',
            })}
        >
            <LoadingIndicator />
        </Layer>
    );
}
