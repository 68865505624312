import { ComponentProps, ReactElement } from 'react';
import { motion } from 'framer-motion';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import { MUIMarginProps } from 'styles/theme/types';

import Icon from '../../primitives/Icon';
import Checkbox from '../../primitives/interactive/Checkbox';
import Input, { useInputForAutocomplete } from '../../primitives/interactive/Input';
import Cluster from '../../primitives/layout/Cluster';
import Tag from '../../primitives/Tag';

interface Props
    extends MUIMarginProps,
        Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'sx' | 'value' | 'onChange'> {
    /**
     * The selected value
     */
    value?: string[];

    /**
     * The role options to choose from
     */
    options: string[];

    /**
     * Event handler to be called when the value changes
     */
    onChange?: (event: { name?: string; value: string[]; added?: string; removed?: string }) => void;
}
/**
 * Responsible for surfacing the role options in a compact form factor with autocomplete behaviors
 */
export default function RoleSelector({
    fieldName,
    caption,
    name,
    value,
    required,
    readOnly,
    error,
    disabled,
    valid,
    fullWidth = true,
    id,
    options,
    onChange,
    onBlur,
}: Props): ReactElement {
    const renderInput = useInputForAutocomplete({
        id,
        fieldName,
        caption,
        name,
        required,
        readOnly,
        disabled,
        fullWidth,
        valid,
        error,
        onBlur,
    });

    return (
        <Autocomplete
            disabled={disabled}
            openOnFocus
            readOnly={readOnly}
            fullWidth={fullWidth}
            autoHighlight
            value={value ?? []}
            multiple
            disableCloseOnSelect
            disableClearable={required || readOnly}
            disablePortal={false}
            forcePopupIcon={!readOnly}
            sx={{
                '.MuiInputBase-root': {
                    flexWrap: 'wrap',

                    '> input': {
                        maxWidth: 200,
                    },
                },
            }}
            isOptionEqualToValue={(option, currentAccountID) => {
                return option === currentAccountID;
            }}
            options={options}
            renderTags={(value, getTagProps) => {
                return (
                    <Cluster my={2}>
                        {value.map((x, i) => {
                            const props = getTagProps({ index: i });

                            return (
                                <motion.div key={x} layoutId={x} layout="position">
                                    <Tag
                                        outlined
                                        label={x}
                                        onClick={readOnly ? undefined : props.onDelete}
                                        endIcon={readOnly ? undefined : <Icon name="close" />}
                                    />
                                </motion.div>
                            );
                        })}
                    </Cluster>
                );
            }}
            renderOption={(props, option, { selected }) => (
                <Box component="li" key={option} {...props}>
                    <Checkbox as="div" checked={selected} label={option} />
                </Box>
            )}
            renderInput={renderInput}
            onChange={(event, newValue, action, change) => {
                onChange?.({
                    name,
                    value: newValue,
                    added: action === 'selectOption' ? change?.option : undefined,
                    removed: action === 'removeOption' ? change?.option : undefined,
                });
            }}
        />
    );
}
