import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';

import getPathByName from 'app/core/Navigation/getPathByName';

import { useSignUpUserMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

function useSignUpMutation() {
    const client = useApolloClient();
    const [signUp] = useSignUpUserMutation();

    return async ({ email, password, name, inviteKey }) => {
        await client.cache.reset();

        const result = await signUp({
            variables: {
                signUpUserInput: {
                    email,
                    password: btoa(password),
                    name,
                    inviteKey,
                },
            },
        });

        return result.data?.createUser;
    };
}

export default function useSignUp() {
    const { t } = useI18n();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const signUp = useSignUpMutation();
    const [errorMessage, setErrorMessage] = useState<string>('');

    return {
        run: async ({ values: { email, password, firstName, lastName, inviteKey = '' } }) => {
            const nextRoute = searchParams.get('next');

            try {
                await signUp({ email, password, name: `${firstName} ${lastName}`, inviteKey });
            } catch (error) {
                // TODO(derek): differentiate errors, need API support
                setErrorMessage(t('signup_page.signup_failed_message'));

                throw error;
            }

            navigate(nextRoute ?? getPathByName('HOME'));
        },
        errorMessage,
    };
}
