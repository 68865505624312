import createActionFactory from 'typescript-fsa';

import { DateTimeSpan, ISO8601 } from 'app/core/types';

import { ReservationScheduleReservationItemFragment } from 'generated/graphql';

import { Allocation, PendingAllocationPatch, Unit } from './types';

const createAction = createActionFactory('ReservationSchedule');

/**
 * An action creator directly triggered by user interaction to add a new allocation to a unit.
 */
export const addAllocation = createAction<Unit['id']>('addAllocation');
/**
 * An action creator to insert new pending allocations based on the given time spans.
 */
export const insertPendingAllocationCollection = createAction<{ unitID: string; timeSpans: DateTimeSpan[] }>(
    'insertPendingAllocationCollection',
);
/**
 * An action creator to insert a new pending allocation to fill the unallocated time at the end of a unit.
 */
export const insertPendingAllocation = createAction<Unit['id']>('insertPendingAllocation');
/**
 * An action creator to insert a new pending allocation to fill the unallocated time after the given allocation.
 */
export const insertPendingAllocationAfter = createAction<Allocation['id']>('insertPendingAllocationAfter');
/**
 * An action creator to insert a new pending allocation to fill the unallocated time before the given allocation.
 */
export const insertPendingAllocationBefore = createAction<Allocation['id']>('insertPendingAllocationBefore');
/**
 * An action creator directly triggered by user interaction to remove an allocation from a unit.
 */
export const removeAllocation = createAction<Allocation['id']>('removeAllocation');
/**
 * An action creator to delete a pending allocation from a unit.
 */
export const deletePendingAllocation = createAction<Allocation['id']>('deletePendingAllocation');
/**
 * An action creator directly triggered by user interaction to assign an MPU to a given allocation.
 */
export const requestAllocationAssignment = createAction<Allocation['id']>('requestAllocationAssignment');
/**
 * An action creator directly triggered by user interaction to update an allocation's schedules.
 * The resulting change will affect the start and/or end date-times for neighboring allocations.
 */
export const updateAllocationPeriod = createAction<{
    id: Allocation['id'];
    start: ISO8601;
    end: ISO8601;
}>('updateAllocationPeriod');
/**
 * An action creator to update a pending allocation in a unit.
 */
export const updatePendingAllocation = createAction<PendingAllocationPatch>('updatePendingAllocation');
/**
 * An action creator to update reservations items stored in component state.
 */
export const updateReservationItems =
    createAction<readonly ReservationScheduleReservationItemFragment[]>('updateReservationItems');
