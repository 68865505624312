import { ReactElement, useEffect } from 'react';
import { useFormik } from 'formik';
import { TOptions } from 'i18next';
import { number, object } from 'yup';

import Grid from '@mui/material/Grid';

import { Button, NumberField, Text } from 'app/components';

import { useI18n } from 'i18n';

export interface UpdateUnitCountFormFields {
    unitCount: number;
}

interface Props extends UpdateUnitCountFormFields {
    id?: string;

    hideSubmitCTA?: boolean;

    onFormStateChange?: (event: { shouldDisableCTA: boolean }) => void;

    onSubmit: (values: UpdateUnitCountFormFields) => void;
}

/**
 * Responsible for presenting the update unit count form and collecting user input
 */
export default function UpdateUnitCountForm({
    id,
    hideSubmitCTA = false,
    unitCount,
    onFormStateChange,
    onSubmit,
}: Props): ReactElement {
    const { t } = useI18n();
    const _t = (key: string, options?: TOptions) =>
        t(`admin_reservation_page.update_unit_count_dialog.${key}`, options);

    const formik = useFormik<UpdateUnitCountFormFields>({
        enableReinitialize: true,
        initialValues: {
            unitCount,
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: object().shape({
            unitCount: number().required(),
        }),
        onSubmit,
    });

    const { isValid, dirty, errors, touched, values } = formik;

    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({ shouldDisableCTA });
    }, [onFormStateChange, shouldDisableCTA]);

    return (
        <Grid
            id={id}
            container
            component="form"
            spacing={4}
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
        >
            <Grid item xs={12}>
                <NumberField
                    required
                    fieldName={_t('unit_count_field_label')}
                    name="unitCount"
                    min={unitCount}
                    value={values.unitCount}
                    error={touched.unitCount && !!errors.unitCount}
                    caption={touched.unitCount ? errors.unitCount : undefined}
                    onBlur={formik.handleBlur}
                    onChange={({ value }) => formik.setFieldValue('unitCount', value)}
                />
            </Grid>

            <Grid item xs={12}>
                <Text>{_t('notice', { mpuCount: values.unitCount - unitCount })}</Text>
            </Grid>

            {!hideSubmitCTA && (
                <Grid item xs={12}>
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_reservation_page.cta.add_units')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
