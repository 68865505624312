import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLayer } from 'app/core/layers';
import { useSession } from 'app/core/Session';

import { getItem as getLocalStorageItem, setItem as setLocalStorageItem } from 'utils/localStorage';

import { filterFeatureKey } from './constants';
import { FiltersType, FilterType } from './types';
import { getValidFilter } from './validation';

const useFilters = (key: string) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const filtersEncoded = searchParams.get(filterFeatureKey);

    const { toast } = useLayer();
    const session = useSession();

    const [filters, setFilters] = useState<FiltersType>(() => {
        try {
            if (!filtersEncoded) {
                return {};
            }

            const incomingFilters = JSON.parse(atob(filtersEncoded));

            return incomingFilters;
        } catch (error) {
            // 👽
            return {};
        }
    });
    const filter = filters[key] || {};

    const clearFilters = () => {
        searchParams.delete(filterFeatureKey);

        setSearchParams(searchParams);

        toast.add({
            severity: 'success',
            message: 'Filters have been cleared',
        });
    };

    const addFilter = (filter: FilterType) => {
        const newFilters = {
            ...filters,
            [key]: {
                ...filter,
                meta: {
                    ...filter?.meta,
                    updatedAt: new Date().toISOString(),
                },
            },
        };

        // If the filter is empty, remove it
        if (Object.keys(filter.fields).length === 0) {
            delete newFilters[key];
        }

        // If all filters are empty, remove the filter key
        if (Object.keys(newFilters).length === 0) {
            searchParams.delete(filterFeatureKey);
            setSearchParams(searchParams);

            toast.add({
                severity: 'info',
                message: 'Filter has been removed',
            });

            return;
        }

        // Update the search params
        setSearchParams({
            [filterFeatureKey]: btoa(JSON.stringify(newFilters)),
        });

        toast.add({
            severity: 'success',
            message: 'Filter has been applied',
        });
    };

    const removeFilter = () => {
        const newFilters = {
            ...filters,
        };

        delete newFilters[key];

        // If all filters are empty, remove the filter key
        if (Object.keys(newFilters).length === 0) {
            searchParams.delete(filterFeatureKey);
            setSearchParams(searchParams);

            toast.add({
                severity: 'info',
                message: 'Filter has been removed',
            });

            return;
        }

        // Update the search params
        setSearchParams({
            [filterFeatureKey]: btoa(JSON.stringify(newFilters)),
        });

        toast.add({
            severity: 'success',
            message: 'Filter has been removed',
        });
    };

    const getFilter = (type: string): FilterType => filters[type];

    const saveFilter = (filter: FilterType, name: string) => {
        const validFilter = getValidFilter(filter);
        const now = new Date().toISOString();
        const { primaryAccountID = '' } = session?.getSession();
        const id = validFilter?.meta?.id ?? `${name}-${now}`;

        const validatedFilter = {
            ...validFilter,
            meta: {
                ...filter.meta,
                updatedAt: now,
                id,
                accountID: primaryAccountID,
                name,
            },
        };

        const allFiltersMap = getLocalStorageItem(filterFeatureKey) || {};

        if (typeof allFiltersMap === 'object') {
            allFiltersMap[id] = validatedFilter;
            setLocalStorageItem(filterFeatureKey, allFiltersMap);
        }

        return validatedFilter;
    };

    const deleteFilter = (id: string) => {
        const allFiltersMap = getLocalStorageItem(filterFeatureKey) || {};

        if (typeof allFiltersMap === 'object' && allFiltersMap[id]) {
            delete allFiltersMap[id];
            setLocalStorageItem(filterFeatureKey, allFiltersMap);
        }
    };

    const addFilterToURL = (url: string, filter: FilterType) => {
        const location = new URL(url);
        location.search = ''; // Clear the search params
        const newFilters = {
            [key]: {
                ...filter,
                meta: {
                    ...filter.meta,
                    updatedAt: new Date().toISOString(),
                },
            },
        };

        location.searchParams.set(filterFeatureKey, btoa(JSON.stringify(newFilters)));

        return location.toString();
    };

    const { fields = {} } = filter;
    const tags = Object.values(fields).flat();

    const removeFilterValueByID = id => {
        const newFilterParams = Object.entries(fields).reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
                const newValues = value.filter(v => v.id !== id);

                if (newValues.length === 0) {
                    return acc;
                }

                acc[key] = newValues;
            }

            return acc;
        }, {});

        if (Object.keys(newFilterParams).length === 0) {
            removeFilter();
            return;
        }

        addFilter({ fields: newFilterParams });
    };

    useEffect(() => {
        try {
            if (!filtersEncoded) {
                setFilters({});

                return;
            }

            const incomingFilters = JSON.parse(atob(filtersEncoded));

            // If filters are different, update state
            if (JSON.stringify(incomingFilters) !== JSON.stringify(filters)) {
                setFilters(incomingFilters);
            }
        } catch (error) {
            // 👽
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersEncoded, searchParams]);

    return {
        // Core
        filters,
        filter,
        tags,

        // Methods
        clearFilters,
        addFilter,
        removeFilter,
        getFilter,
        saveFilter,
        deleteFilter,

        // Utils
        addFilterToURL,
        removeFilterValueByID,
    };
};

export default useFilters;
