import { ReactElement, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useLayer } from 'app/core/layers';
import { LayerItemFacade } from 'app/core/layers/types';

import { useI18n } from 'i18n';

import useReservation from '../useReservation';
import UpdateUnitCountDialog from './UpdateUnitCountDialog';
import useAddUnitsToReservation from './useAddUnitsToReservation';

export default function UpdateUnitCountPage(): ReactElement {
    const { t } = useI18n();
    const { dialog, toast } = useLayer();
    const dialogRef = useRef<LayerItemFacade | null>(null);

    const { reservationID = '' } = useParams<{ reservationID: string }>();

    const navigate = useNavigate();

    const { reservation } = useReservation({ reservationID });

    const { addUnitsToReservation } = useAddUnitsToReservation(reservation);

    useEffect(() => {
        dialogRef.current = dialog.add(
            ({ close }) => ({
                unitCount: reservation.unitCount,
                close: () => {
                    close();
                    navigate(-1);
                },
                onUpdateUnitCount: async ({ unitCount }) => {
                    const unitsToAddCount = unitCount - reservation.unitCount;

                    try {
                        addUnitsToReservation(unitsToAddCount);

                        toast.add({
                            severity: 'success',
                            message: t('admin_reservation_page.update_unit_count_success'),
                        });
                    } catch (error) {
                        toast.add({
                            severity: 'error',
                            message: t('admin_reservation_page.update_unit_count_error'),
                        });

                        throw error;
                    }
                },
            }),
            { Component: UpdateUnitCountDialog },
        );

        dialogRef.current?.on('close', () => {
            dialogRef.current = null;
        });

        return dialogRef.current.close;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!dialogRef.current) return;

        dialogRef.current.update({
            unitCount: reservation.unitCount,
        });
    }, [reservation]);

    return <Box />;
}
