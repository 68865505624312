import { ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// TODO(derek): remove direct import after legacy Toast component is removed entirely
import { Toast } from 'app/components/primitives/Alert';
import Layer from 'app/components/primitives/layout/Layer';

import LayerItem from '../LayerItem';
import { IOpenableComponentConnector } from '../types';

/**
 * Responsible for fielding requests submitted via a connector and displaying toasts on the screen
 */
export default function ToastContainer({ layerConnector }): ReactElement {
    const [items, setItems] = useState<IOpenableComponentConnector[]>([]);

    useEffect(() => {
        setItems(layerConnector.getAllItems());

        return layerConnector.on('update', ({ data }) => setItems(data.items));
    }, [layerConnector]);

    return (
        <Layer fill="screen" sx={theme => ({ pointerEvents: 'none', zIndex: theme.zIndex.snackbar })}>
            <Layer mt={4} px={3} width={{ xs: '100%', md: 750 }} y="top" relAnchorY="top">
                <Stack spacing={1}>
                    <AnimatePresence initial={false}>
                        {items.map(x => (
                            <motion.div
                                key={x.id}
                                layout
                                initial={{ opacity: 0, y: 50, scale: 0.3 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                            >
                                <Box sx={{ pointerEvents: 'auto' }}>
                                    <LayerItem key={x.id} itemConnector={x} Component={Toast} />
                                </Box>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </Stack>
            </Layer>
        </Layer>
    );
}
