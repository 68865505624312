import { ReactElement, useState } from 'react';
import { ApolloError } from '@apollo/client';

import Toast from 'app/components/compounds/Toast';

import { useUserAccountInviteUpsertMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

import { AccountInviteForm } from './presentation';

interface Props {
    accountID: string;
    onInviteSent?: ({ email }: { email: string }) => Promise<void>;
}

export function AccountInviteFormContainer({ accountID, onInviteSent = () => Promise.resolve() }: Props): ReactElement {
    const { t } = useI18n();

    const [isSuccessToastOpen, setIsSuccessToastOpen] = useState<boolean>(false);
    const [isErrorToastOpen, setIsErrorToastOpen] = useState<boolean>(false);
    const [upsertUserAccountInvite] = useUserAccountInviteUpsertMutation();
    const [inviteErrorText, setInviteErrorText] = useState(t('account_invite_form.error_message'));

    return (
        <>
            <AccountInviteForm
                onSubmit={async ({ email }) => {
                    if (!email) return;

                    try {
                        await upsertUserAccountInvite({ variables: { email, accountID } });
                        await onInviteSent({ email });

                        setIsSuccessToastOpen(true);
                    } catch (error) {
                        if (error instanceof ApolloError) {
                            for (const err of error.graphQLErrors) {
                                switch (err.extensions.reason) {
                                    // note only a subset of types should be handled vs admin invite
                                    case 'EmailAlreadyTaken':
                                        setInviteErrorText(
                                            t('invite_user_page.invite_user_failure.email_already_taken_message'),
                                        );
                                        break;
                                    case 'DomainNotAllowed':
                                        setInviteErrorText(
                                            t('invite_user_page.invite_user_failure.domain_not_allowed_message'),
                                        );
                                        break;
                                    case 'UnacceptedInviteAlreadyExists':
                                        setInviteErrorText(
                                            t(
                                                'invite_user_page.invite_user_failure.unaccepted_invite_already_exists_message',
                                            ),
                                        );
                                        break;
                                    default:
                                        // TODO(logan): can we log to DD or similar?
                                        setInviteErrorText(t('account_invite_form.error_message'));
                                        break;
                                }
                            }
                        }
                        setIsErrorToastOpen(true);
                    }
                }}
            />

            <Toast
                isOpen={isSuccessToastOpen}
                onClose={() => {
                    setIsSuccessToastOpen(false);
                }}
                type="success"
                message={t('account_invite_form.success_message')}
            />

            <Toast
                isOpen={isErrorToastOpen}
                onClose={() => {
                    setIsErrorToastOpen(false);
                }}
                type="error"
                message={inviteErrorText}
            />
        </>
    );
}
