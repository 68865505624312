import { ComponentProps, ReactElement, ReactNode, useId } from 'react';
import type * as CSS from 'csstype';

import { Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import CSSDimension from 'design-system/CSSDimension';

import { useI18n } from 'i18n';

import { Theme } from 'styles/theme';

import Layer from '../layout/Layer';
import { Link } from '../Link';
import Text from '../Text';

interface CircularProgressProps {
    /**
     * The foreground color of the progress indicator.
     *
     * @default CircularProgressColor.Accent
     */
    color?: `${CircularProgressColor}`;
    /**
     * Secondary content displayed beneath the percentage value.
     */
    description?: ReactNode;
    /**
     * Determines whether the progress indicator should be styled
     * to be displayed on a dark background.
     */
    light?: boolean;
    /**
     * The maximum value of the progress indicator.
     * This is used to calculate the percentage.
     *
     * @default 100
     */
    max?: number;
    /**
     * The size of the progress indicator.
     *
     * @default CircularProgressSize.ExtraLarge
     */
    size?: `${CircularProgressSize}`;
    /**
     * When provided, the progress indicator will be rendered as a link
     * pointing to the given destination.
     */
    to?: ComponentProps<typeof Link>['to'];
    /**
     * The value to be converted into a percentage.
     */
    value?: number | null;
}

enum CircularProgressColor {
    Accent = 'accent',
    Danger = 'danger',
    Success = 'success',
}

enum CircularProgressSize {
    ExtraLarge = 'extra-large',
    ExtraSmall = 'extra-small',
}

type TextVariant = ComponentProps<typeof Text>['variant'];

interface SizeStyle {
    imageSize: CSSDimension;
    innerPadding: number;
    primaryTextVariant: TextVariant;
    secondaryTextVariant: TextVariant;
    strokeWidth: number;
}

export const DIMENSION_EXTRA_LARGE = CSSDimension.fromPixels(240);
export const DIMENSION_EXTRA_SMALL = CSSDimension.fromPixels(56);

const stylesBySize = {
    [CircularProgressSize.ExtraLarge]: {
        imageSize: DIMENSION_EXTRA_LARGE,
        innerPadding: 6,
        primaryTextVariant: 'data',
        secondaryTextVariant: 'dataSmall',
        strokeWidth: 16,
    },
    [CircularProgressSize.ExtraSmall]: {
        imageSize: DIMENSION_EXTRA_SMALL,
        innerPadding: 1,
        primaryTextVariant: 'detail',
        secondaryTextVariant: 'detail',
        strokeWidth: 4,
    },
} as const satisfies Record<CircularProgressSize, SizeStyle>;

const foregroundColorByColor: Record<CircularProgressColor, (theme: Theme) => CSS.DataType.Color | undefined> = {
    [CircularProgressColor.Accent]: theme => theme.palette.brand.accent,
    [CircularProgressColor.Danger]: theme => theme.palette.text.danger,
    [CircularProgressColor.Success]: theme => theme.palette.text.success,
};

/**
 * Responsible for representing progress in a square aspect ratio
 *
 * TODO(Morris): Add MUI click animation when rendered as a link.
 */
export default function CircularProgress({
    description,
    max = 100,
    size = CircularProgressSize.ExtraLarge,
    value,
    color = CircularProgressColor.Accent,
    light,
    to,
}: CircularProgressProps): ReactElement {
    const id = useId();
    const { format } = useI18n();
    const theme = useTheme();

    const hasValue = typeof value === 'number';
    const { imageSize, strokeWidth, primaryTextVariant, secondaryTextVariant, innerPadding } = stylesBySize[size];
    const backgroundColor = light ? theme.palette.background.default : theme.palette.grey[200];
    const foregroundColor = foregroundColorByColor[color](theme);

    const canvasSize = 100;
    const halfCanvasSize = canvasSize / 2;
    const actualStrokeWidth = (strokeWidth * canvasSize) / imageSize.asValue('px');
    const transitionDuration = 400;

    const radius = halfCanvasSize - actualStrokeWidth / 2;
    const perimeter = 2 * Math.PI * radius;
    const fraction = Math.min(value ?? 0, max) / max;
    const percentage = fraction * 100;
    const length = perimeter * fraction;
    const formattedPercentage = hasValue ? format.percentage(percentage).toString() : '?';
    const showDescription = !!description && size !== CircularProgressSize.ExtraSmall;

    /**
     * The props passed to the `Layer` component to make the component into a focusable link.
     */
    const linkPropsMixin: ComponentProps<typeof Link> & { component: typeof Link } = {
        component: Link,
        to,
        sx: {
            color: theme.palette.text.primary,
            '&:hover,&:focus': {
                backgroundColor: theme.palette.background.disabled.main,
                borderRadius: '50%',
                boxShadow: `0 0 0 4px ${theme.palette.background.disabled.main}`,
                outline: 'none',
            },
        },
    };

    /**
     * These props are cast to an unknown `Record`, because the `Layer` component
     * doesn't update it's props type based on the `component` prop.
     *
     * TODO(Morris): Either make the types for the `Layer` component's props generic,
     * or make the `Layer` component accept a `to` prop that converts it into a link.
     */
    const linkProps = (to ? linkPropsMixin : {}) as Record<string, unknown>;

    return (
        <Layer display="inline-flex" anchor {...linkProps}>
            <svg
                viewBox={`-${halfCanvasSize} -${halfCanvasSize} ${canvasSize} ${canvasSize}`}
                width={imageSize.as('rem')}
                height={imageSize.as('rem')}
                role="progressbar"
                aria-valuemax={max}
                aria-valuenow={value ?? 0}
                aria-labelledby={id}
            >
                <circle r={radius} strokeWidth={actualStrokeWidth - 0.1} stroke={backgroundColor} fill="transparent" />

                <circle
                    style={{
                        transition: `stroke-dashoffset ${transitionDuration}ms ease`,
                    }}
                    transform="rotate(-90)"
                    r={radius}
                    strokeWidth={actualStrokeWidth}
                    strokeDasharray={perimeter}
                    // The circle fills up counter-clockwise, so we invert the offset.
                    strokeDashoffset={(perimeter - length) * -1}
                    stroke={foregroundColor}
                    fill="transparent"
                    strokeLinecap="round"
                />
            </svg>

            <Layer
                id={id}
                fill="anchor"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={innerPadding}
            >
                <Stack>
                    <Text as="div" variant={primaryTextVariant}>
                        {formattedPercentage}
                    </Text>

                    {showDescription && (
                        <Text
                            as="div"
                            sx={{ color: 'text.secondary', fontWeight: 'light' }}
                            variant={secondaryTextVariant}
                        >
                            {description}
                        </Text>
                    )}
                </Stack>
            </Layer>
        </Layer>
    );
}
