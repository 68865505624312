import type { DateTime } from 'luxon';

import type { INVALID_DATE_TIME_VALUE } from './constants';

/**
 * ISO 8601 date time string
 *
 * @remarks
 *
 * It's recommended to use this type for date-time props and parameters for more flexibility.
 * The stricter types below may be used for higher specificity, and is recommended for return types.
 */
export type ISO8601 = string;

/**
 * @privateRemarks
 *
 * TODO(Morris): Reuse types with `backend/src/time/iso8601.ts`
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace ISO8601 {
    type TYear = `${number}`;
    type TMonth = `${number}`;
    type TDay = `${number}`;
    type THours = `${number}`;
    type TMinutes = `${number}`;
    type TSeconds = `${number}`;
    type TMilliseconds = `${number}`;
    /**
     * @example
     * '-05:00'
     * @example
     * 'Z'
     */
    type TOffset = string;

    /**
     * Represents a string like `2021-01-08`
     */
    export type Date = `${TYear}-${TMonth}-${TDay}`;
    /**
     * Represents a string like `14:42:34.678`
     */
    export type Time = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;
    /**
     * Represents a string like `14:42:34.678-05:00`
     */
    export type TimeWithOffset = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}${TOffset}`;
    /**
     * Represents a string like `2021-01-08T14:42:34.678-05:00` (format: ISO 8601).
     */
    export type Strict = `${Date}T${Time}${TOffset}`;
    /**
     * Represents a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
     */
    export type StrictUTC = `${Date}T${Time}Z`;
}

/**
 * IANA time zone string
 *
 * @see {@link https://github.com/moment/luxon/blob/master/docs/zones.md#specifying-a-zone | Luxon docs}.
 * @example 'America/New_York'
 *
 * @remarks
 *
 * TODO(Morris): Consider using `string/string` instead of `string` to enforce the format
 */
export type IANATimeZone = string;

export type ResolvableDateTime = ISO8601 | Date | DateTime | null;

export interface DateTimeSpan<T = ISO8601> {
    end: T;
    start: T;
}

/**
 * A string containing `"invalid"` representing an invalid date, time, or date-time.
 */
export type InvalidDateTimeString = typeof INVALID_DATE_TIME_VALUE;

/**
 * _Either:_
 *
 * 1. A ISO 8601 date time string representing a valid date time
 * 2. A string containing `"invalid"` representing an invalid date time
 * 3. `null` representing an empty value
 *
 * @example
 * "2021-01-01"
 *
 * @example
 * "23:25:42.000"
 *
 * @example
 * "invalid"
 *
 * @privateRemarks
 *
 * The type widens to `string | null`, because `ISO8601` isn't a template literal.
 */
export type PossibleISO8601 = ISO8601 | InvalidDateTimeString | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace PossibleISO8601 {
    /**
     * @example
     * "2021-01-01"
     *
     * @example
     * "23:25:42.000"
     *
     * @example
     * "invalid"
     */
    export type Strict = ISO8601.Strict | InvalidDateTimeString | null;
    /**
     * @example
     * "2021-01-01"
     *
     * @example
     * "invalid"
     */
    export type Date = ISO8601.Date | InvalidDateTimeString | null;
    /**
     * @example
     * "23:25:42.000"
     *
     * @example
     * "invalid"
     */
    export type Time = ISO8601.Time | InvalidDateTimeString | null;
    /**
     * @example
     * "23:25:42.000-05:00"
     *
     * @example
     * "invalid"
     */
    export type TimeWithOffset = ISO8601.TimeWithOffset | InvalidDateTimeString | null;
}
