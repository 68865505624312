/**
 * Represents the available filter options for Alerts.
 */
const ALERT_FILTERS = ['all', 'critical', 'custom'] as const;

export type AlertFilterValue = (typeof ALERT_FILTERS)[number];

const seed: { [key: AlertFilterValue | string]: AlertFilterValue } = {};

export const AlertFilter = ALERT_FILTERS.reduce((acc, cur) => ({ ...acc, [cur]: cur }), seed);
