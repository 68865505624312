import { DateTime } from 'luxon';

import { ISO8601 } from 'app/core/types';

import { DEFAULT_METRIC_CHART, MetricChartAccordion, metricChartDisplayOrder } from '../MetricChart';

interface Props {
    /**
     * The identifier for a reservationItem to get telemetry by (do not also pass mpuID)
     */
    unitID?: string;

    /**
     * The identifier for a device instance to get telemetry by (do not also pass unitID)
     */
    mpuID?: string;

    /**
     * The start of the interval to fetch data for
     */
    start?: ISO8601 | DateTime;

    /**
     * The end of the interval to fetch data for
     */
    end?: ISO8601 | DateTime;
}

/**
 * Shows historical trends for metrics via time series chart.
 */
export function HistoricalSummaryAccordion({ unitID, mpuID, start, end }: Props) {
    return (
        <>
            {metricChartDisplayOrder.map(type => (
                <MetricChartAccordion
                    defaultExpanded={type === DEFAULT_METRIC_CHART}
                    mpuID={mpuID}
                    key={type}
                    unitID={unitID}
                    type={type}
                    start={start}
                    end={end}
                />
            ))}
        </>
    );
}
