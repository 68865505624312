import { useId, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { Button, Text } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import DocumentTypeSelector from './DocumentSelector';
import DocumentForm, { createUrlWithSerializedFormParam } from './forms';
import DocumentFormModal from './forms/components/Modal';

const defaultDocumentType = 'bill-of-lading-internal';

export default function Documents() {
    const [isDocumentFormModalOpen, setIsDocumentFormModalOpen] = useState(false);
    const [documentType, setDocumentType] = useState(defaultDocumentType);
    const documentFormModalId = useId();
    const { t } = useI18n();

    const onSubmit = (values: Record<string, any>) => {
        const path = getPathByName('DOCUMENTS_CREATE', { params: { documentType } });
        const url = createUrlWithSerializedFormParam(path, values);

        // Navigate to document page with query params in new tab
        window.open(url, '_blank');
    };

    return (
        <Box sx={{ pt: 4 }}>
            <Paper sx={{ p: 4 }} variant="outlined">
                <Box mb={4}>
                    <Text variant="h3" as="h2">
                        {t('documents.create_related_documents')}
                    </Text>
                </Box>
                <Stack direction="row" alignItems="end" spacing={2}>
                    <DocumentTypeSelector value={documentType} onChange={value => setDocumentType(value)} />

                    <Button
                        ctaType="secondary"
                        onClick={() => {
                            setIsDocumentFormModalOpen(true);
                        }}
                    >
                        {t('documents.create_document')}
                    </Button>
                </Stack>
            </Paper>

            <DocumentFormModal
                title={t('documents.bill_of_lading_internal')}
                isOpen={isDocumentFormModalOpen}
                onClose={() => setIsDocumentFormModalOpen(false)}
                formId={documentFormModalId}
            >
                <DocumentForm onSubmit={onSubmit} type={documentType} formId={documentFormModalId} />
            </DocumentFormModal>
        </Box>
    );
}
