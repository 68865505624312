import { useFormik } from 'formik';

import Stack from '@mui/material/Stack';

import { Input } from 'app/components';

import { useI18n } from 'i18n';

const SaveFilterForm = ({ formId, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            filterName: '',
        },
        onSubmit,
    });
    const { t } = useI18n();

    return (
        <Stack component="form" spacing={5} onSubmit={formik.handleSubmit} id={formId}>
            <Input
                fieldName={t('filters.filter_name')}
                name="filterName"
                onChange={formik.handleChange}
                value={formik.values.filterName}
            />
        </Stack>
    );
};

export default SaveFilterForm;
