import { ComponentProps, ReactElement, useCallback } from 'react';

import { useSession } from 'app/core/Session';
import { UserRoleName } from 'app/core/Session/Session';
import { ISession } from 'app/core/types';

import AppChromeLayout, { AppChromeContentLayout } from './AppChromeLayout';
import useConfigureNav from './useConfigureNav';
import useExperience from './useExperience';

/**
 * This function resolves the capabilities / features to expose the any given user
 * basd on their user roles.
 */
function resolveUsersCapabilities(session: ISession): ComponentProps<typeof AppChromeLayout>['userCapabilities'] {
    const canViewAlerts = session.hasAnyUserRoleOf([UserRoleName.MoxionAdmin, UserRoleName.MoxionFleetManager]);

    return {
        canViewAlerts,
    };
}

interface Props {
    /**
     * Controls the content layout, generally this should not be necessary unless
     * you need full control of the page layout for full width sections or other layout
     * concernts.
     */
    contentLayout?: AppChromeContentLayout;

    /**
     * The page content to render
     */
    children?: JSX.Element | JSX.Element[] | null;
}

/**
 * Resposible for rendering the correct nav based on user role
 */
export default function AppChrome({ contentLayout = 'standard', children }: Props): ReactElement {
    const session = useSession();
    const userCapabilities = resolveUsersCapabilities(session);

    const { id } = session.getSession();

    const { targetExperience, nextTargetExperience, switchExperience, forgetExperience } = useExperience({
        userID: id,
    });

    const onSignOut = useCallback(() => {
        forgetExperience();
        session.signOut();
    }, [session, forgetExperience]);

    const navProps = useConfigureNav({ targetExperience, onSignOut });

    return (
        <AppChromeLayout
            {...navProps}
            contentLayout={contentLayout}
            userCapabilities={userCapabilities}
            targetExperience={nextTargetExperience}
            onSwitchExperience={switchExperience}
        >
            {children}
        </AppChromeLayout>
    );
}
