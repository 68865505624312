import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Accordion, AccordionDetails, AccordionSummary, Text } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { ReservationItemTable } from '../ReservationItemTable';
import useCancelReservation from '../useCancelReservation';
import { CustomerReservationsPageReservation } from '../useReservationsPageData';
import { HeaderRow } from './HeaderRow';
import { ReservationDetailsRow } from './ReservationDetailsRow';

interface ReservationAccordionListProps {
    reservations: CustomerReservationsPageReservation[];
}

export function ReservationAccordionList({ reservations }: ReservationAccordionListProps) {
    const { t } = useI18n();
    const { isCancellable, run } = useCancelReservation();

    return (
        <Box sx={{ px: 2 }}>
            <HeaderRow />

            {reservations.map((reservation, index) => {
                const { id, start, additionalNotes, isMonitorable, isReviewable } = reservation;
                const shouldShowCancelCTA = isCancellable({ date: start });
                const hasActions = isMonitorable || shouldShowCancelCTA || isReviewable;

                return (
                    <Accordion key={`accordion-${id}`}>
                        {/* @ts-ignore */}
                        <AccordionSummary
                            variant="contained"
                            id={`panel-${index}-header`}
                            aria-controls={`panel-${index}-content`}
                        >
                            <ReservationDetailsRow {...reservation} />
                        </AccordionSummary>

                        <AccordionDetails id={`panel-${index}-content`} aria-labelledby={`panel-${index}-header`}>
                            <Box sx={{ overflowX: 'auto' }}>
                                <ReservationItemTable reservation={reservation} />
                            </Box>

                            {additionalNotes && (
                                <Box mt={2}>
                                    <Text variant="detail">{additionalNotes}</Text>
                                </Box>
                            )}

                            {hasActions && (
                                <Box mt={3} display="flex" flexWrap="wrap" gap={2}>
                                    {isMonitorable && (
                                        <Button
                                            sx={{ width: { xs: '100%', md: 'auto' } }}
                                            component={Link}
                                            variant="outlined"
                                            to={getPathByName('RENTER_RESERVATION_MONITORING', {
                                                params: { reservationID: id },
                                            })}
                                        >
                                            {t('cta.monitor_units')}
                                        </Button>
                                    )}

                                    {isReviewable && (
                                        <Button
                                            sx={{ width: { xs: '100%', md: 'auto' } }}
                                            component={Link}
                                            variant="outlined"
                                            to={getPathByName('RENTER_RESERVATION_MONITORING', {
                                                params: { reservationID: id },
                                            })}
                                        >
                                            {t('cta.review_usage')}
                                        </Button>
                                    )}

                                    {shouldShowCancelCTA && (
                                        <Button
                                            sx={{ width: { xs: '100%', md: 'auto' } }}
                                            variant="outlined"
                                            onClick={() => {
                                                run({ id: id, start: start });
                                            }}
                                        >
                                            {t('cta.cancel_reservation')}
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
}
