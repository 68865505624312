import { MutableRefObject, useMemo, useRef } from 'react';

import { createReducerContext } from 'app/components/createReducerContext';

import {
    getMultiGridDimensions,
    MaterialGridSizeStrict,
    MultiGridDimensions,
    MultiGridRenderStats,
} from '../MultiGrid';
import { makeCellDetailsAccessor } from './cell-details';
import { makeScheduleConfig } from './config';
import { createDefaultScheduleState, scheduleReducer } from './state';
import { TimeConverter } from './TimeConverter';
import {
    ScheduleCellDetailsAccessor,
    ScheduleConfig,
    ScheduleEvent,
    ScheduleOptions,
    ScheduleState,
    TooltipContentRenderer,
} from './types';

export interface ScheduleData<E extends ScheduleEvent> {
    config: ScheduleConfig;
    dimensions: MultiGridDimensions;
    getCellDetails: ScheduleCellDetailsAccessor;
    latestGridRenderStatsRef: MutableRefObject<MultiGridRenderStats | undefined>;
    renderTooltipContentRef: MutableRefObject<TooltipContentRenderer<E> | undefined>;
    timeConverter: TimeConverter;
}

// Using `any` because the default event type must accept generics.
const ScheduleContext = createReducerContext<ScheduleState, ScheduleData<any>>();

interface ScheduleProgenitorDimensions {
    asideGridSize: MaterialGridSizeStrict | undefined;
    containerWidth: number;
    gridHeight: number;
    headingHeight: number;
}

/**
 * Despite all of the dependencies, `data` will ultimately only
 * change when the `config` object or its properties change.
 */
function useScheduleData<E extends ScheduleEvent>(
    progenitorDimensions: ScheduleProgenitorDimensions,
    options: ScheduleOptions<E>,
): ScheduleData<E> {
    // Set the `useMemo` dependencies to the values of options for a quick way to
    // add all option properties as dependencies, without needing to destructure the object,
    // and potentially having options left out.
    //
    // Doing it this way allows `config` to only update when an option value changes,
    // instead of the `options` object.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const config = useMemo(() => makeScheduleConfig(options), Object.values(options));

    const { daysDisplayed, historyLength, initialDate } = config;
    const timeConverter = useMemo(
        () => new TimeConverter({ historyLength, initialDate }),
        [historyLength, initialDate],
    );
    const getCellDetails = useMemo(() => makeCellDetailsAccessor(config, timeConverter), [config, timeConverter]);

    const latestGridRenderStatsRef = useRef<MultiGridRenderStats>();
    const renderTooltipContentRef = useRef<TooltipContentRenderer>();

    const { asideGridSize, containerWidth, gridHeight, headingHeight } = progenitorDimensions;
    const dimensions = useMemo(
        () =>
            getMultiGridDimensions({
                asideGridSize,
                bodyColumnsDisplayed: daysDisplayed,
                headingHeight,
                height: gridHeight,
                width: containerWidth,
            }),
        [asideGridSize, containerWidth, daysDisplayed, gridHeight, headingHeight],
    );

    return useMemo(
        (): ScheduleData<E> => ({
            config,
            dimensions,
            getCellDetails,
            latestGridRenderStatsRef,
            renderTooltipContentRef,
            timeConverter,
        }),
        [config, dimensions, getCellDetails, renderTooltipContentRef, timeConverter],
    );
}

/**
 * Initializes the Schedule component.
 *
 * Should only be called once in the `Schedule` component.
 *
 * @returns The context value object that should be given to `ScheduleProvider`.
 */
export function useScheduleInit<E extends ScheduleEvent>(
    progenitorDimensions: ScheduleProgenitorDimensions,
    options: ScheduleOptions<E>,
) {
    return ScheduleContext.useContextValue({
        createDefaultState: createDefaultScheduleState,
        data: useScheduleData(progenitorDimensions, options),
        options,
        reducer: scheduleReducer,
    });
}

/**
 * The context provider component the `Schedule` component.
 */
export const ScheduleProvider = ScheduleContext.Provider;
/**
 * Returns an object for interfacing with the `Schedule` component.
 */
export const useScheduleContext = ScheduleContext.useContext;
/**
 * Retrieves state using a given selector.
 */
export const useScheduleState = ScheduleContext.useReducerState;
/**
 * Returns the `dispatch` function for the `Schedule` component.
 */
export const useScheduleDispatch = ScheduleContext.useDispatch;
