import { Button, useSendAccountInviteTo } from 'app/components';
import { useLayer } from 'app/core/layers';

import { useI18n } from 'i18n';

interface ResendButtonProps {
    email: string;
    accountID: string;
}

export default function ResendInviteButton({ email, accountID }: ResendButtonProps) {
    const { toast } = useLayer();
    const { t } = useI18n();

    const sendInvite = useSendAccountInviteTo();

    return (
        <Button
            ctaType="secondary"
            size="sm"
            onClick={async () => {
                try {
                    await sendInvite({ email, accountID });
                    toast.add({
                        severity: 'success',
                        message: t('account_settings.retry_invite_succeeded'),
                    });
                } catch (error) {
                    toast.add({
                        severity: 'error',
                        message: t('account_settings.error.retry_invite_failed'),
                    });
                }
            }}
        >
            {t('cta.resend_invite')}
        </Button>
    );
}
