import { ReactElement } from 'react';

import { useI18n } from 'i18n';

import { MUIMarginProps } from 'styles/theme/types';

import Metric, { MetricGroup } from '../Metric';

interface Props extends MUIMarginProps {
    /**
     * The total power output in kiloWatt (kW)
     */
    totalLoadPower?: number | null | undefined;

    /**
     * The projected number of seconds until the unit's batteries are drained (s)
     */
    timeToEmpty?: number | null;
}

/**
 * Responsible for rendering the power out stats for an MP unit
 */
export default function PowerOut({
    totalLoadPower,
    timeToEmpty,

    ...props
}: Props): ReactElement {
    const { t, format } = useI18n();

    const formattedTimeToEmpty = format.ette(timeToEmpty);

    return (
        <MetricGroup {...props}>
            <Metric label={t('unit_status.power_out_heading')} {...format.power(totalLoadPower)} />

            <Metric label={t('unit_status.time_to_empty_label')} {...formattedTimeToEmpty} />
        </MetricGroup>
    );
}
