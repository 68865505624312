import { DateTime, Interval } from 'luxon';

import { FaultGroup, HistoricalFault } from '../types';

/**
 * Given a point in time and all faults for a given period, gets all faults which were active.
 */
export default function getCoincidentFaultsBySeverity({
    data,
    timestamp = 0,
    minFaultDuration = 0,
}: {
    /**
     * The consumable historical faults data
     */
    data: FaultGroup[];

    /**
     *  MS since epoch
     */
    timestamp?: number;

    /**
     *  duration in seconds representing the minimum bar width displayed
     */
    minFaultDuration?: number;
}): {
    [severity: string]: HistoricalFault[];
} {
    if (!timestamp) return {};

    const seed: { [severity: string]: HistoricalFault[] } = {};

    return data.reduce((acc, cur) => {
        const activeFaultAtTime = cur.faults.find(x => {
            const firstSeen = DateTime.fromISO(x.firstSeen);
            const resolved = x.resolved ? DateTime.fromISO(x.resolved) : DateTime.now();
            const val = Interval.fromDateTimes(firstSeen, resolved).union(
                Interval.fromDateTimes(firstSeen, firstSeen.plus({ seconds: minFaultDuration })),
            );

            return val.contains(DateTime.fromMillis(timestamp));
        });

        if (!activeFaultAtTime || !cur.severity) return acc;

        return {
            ...acc,
            [cur.severity]: (acc[cur.severity] ?? []).concat(activeFaultAtTime),
        };
    }, seed);
}
