import { addMethod, string } from 'yup';

import validateDomain from './validateDomain';

/**
 * Responsible with extending yup's string validation with the rules for
 * domains.
 *
 * @example
 *
 *  const domainSchema = string()
 *               .domain('invalid_domain')
 *               .required('domain_is_required');
 */
export default function addDomainToYup() {
    addMethod(string, 'domain', function (errorMessage) {
        return this.test(`domain-rules`, errorMessage, function (value) {
            const { path, createError } = this;

            const { isValid, errors } = validateDomain(value);

            if (!isValid) return createError({ path, message: errors[0] });

            return true;
        });
    });
}

declare module 'yup' {
    interface StringSchema {
        domain(errorMessage?: string): StringSchema;
    }
}
