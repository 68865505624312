import { ReactElement, useState } from 'react';
import styled from 'styled-components/macro';

import Box from '@mui/material/Box';

import Text from 'app/components/primitives/Text';

import DeviceOperationDetails from './DeviceOperationDetails';
import DeviceSpecDetails from './DeviceSpecDetails';
import Tabs, { TabOption } from './Tabs';
import { ChargeTimeStat, DimensionedQuantity, PhysicalDimensions, RuntimeStat, VoltageMode } from './types';

interface Props {
    /**
     * The units model number (i.e. MP-75)
     */
    modelNumber: string;

    /**
     * The 16:9 image src
     * TODO(derek): get responsive variants from design
     */
    image: string;

    /**
     * The time to charge the batteries from 5% -> 95%
     */
    dcChargeTime: ChargeTimeStat;

    /**
     * The example system running times based with avergate loading
     */
    exampleRuntimes: RuntimeStat[];

    /**
     * Comparaable Diesel Generator KVA rating
     */
    dieselGeneratorComp: DimensionedQuantity;

    /**
     * System frequency (HZ)
     */
    frequency: DimensionedQuantity;

    /**
     * The units physical dimensions
     */
    unitDimensions: PhysicalDimensions;

    /**
     * The unit + trailer physical dimensions
     */
    outsideDimensions: PhysicalDimensions;

    /**
     * The unit + trailers weight
     */
    totalWeight: DimensionedQuantity;

    /**
     * The supported voltage modes and associated system limits
     */
    configurations: VoltageMode[];
}

export default function UnitSpecsAndOperationPanel({
    image,
    modelNumber,
    dcChargeTime,
    exampleRuntimes,
    dieselGeneratorComp,
    frequency,
    unitDimensions,
    outsideDimensions,
    totalWeight,
    configurations,
}: Props): ReactElement {
    const [activeSection, setActiveSection] = useState<TabOption>('operation');

    return (
        <Box>
            <Box mb={7} sx={{ aspectRatio: '16 / 9' }}>
                <ProductImage src={image} alt={modelNumber} />
            </Box>

            <Text variant="h2" as="h1" gutterBottom>
                {modelNumber}
            </Text>

            <Tabs value={activeSection} onChange={({ value }) => setActiveSection(value)} />

            {activeSection === 'operation' && (
                <DeviceOperationDetails
                    id="operation-tabbed-content"
                    role="tabpanel"
                    dcChargeTime={dcChargeTime}
                    exampleRuntimes={exampleRuntimes}
                />
            )}

            {activeSection === 'spec' && (
                <DeviceSpecDetails
                    id="spec-tabbed-content"
                    role="tabpanel"
                    dieselGeneratorComp={dieselGeneratorComp}
                    frequency={frequency}
                    unitDimensions={unitDimensions}
                    outsideDimensions={outsideDimensions}
                    totalWeight={totalWeight}
                    configurations={configurations}
                />
            )}
        </Box>
    );
}

const ProductImage = styled.img`
    width: 100%;
    height: 100%;
`;
