import { ComponentProps, MutableRefObject, useCallback, useState } from 'react';
import { Deferred } from 'ts-deferred';

import Dialog from 'app/components/primitives/Dialog';
import { silenceKnownDeleteError } from 'app/core/error';
import { useLayer } from 'app/core/layers';

import {
    useDeleteReservationScheduleDeviceInstanceScheduleMutation as useDeleteSchedule,
    usePersistReservationScheduleDeviceInstanceSchedulesMutation as usePersistSchedules,
} from 'generated/graphql';

import { DeleteAllocationResult } from './constants';
import { useGetAllocationSchedules, useReservationItems } from './hooks';
import { ReservationSchedulePresenter } from './ReservationSchedulePresenter';
import { allocationToDeviceInstanceSchedules } from './transformers';
import {
    AllocationSchedule,
    AssignAllocationRequestHandler,
    DeleteAllocationHandler,
    PersistAllocationHandler,
    RefreshReservationItemsHandler,
    ReservationScheduleController,
} from './types';
import { useConfirmAllocationDeleteDialog } from './useConfirmAllocationDeleteDialog';
import { isExpectedSchedulesForAllocation } from './utils';

interface ReservationScheduleContainerProps {
    /**
     * A reference to a `ReservationScheduleController`
     */
    controllerRef?: MutableRefObject<ReservationScheduleController | undefined>;
    /**
     * The height of the component in pixels.
     */
    height: number;
    /**
     * A function called when an MPU assignment is requested for an Allocation.
     */
    onAssignAllocationRequest?: AssignAllocationRequestHandler;
    /**
     * The reservation to view and manage allocation for.
     */
    reservationID: string;
    /**
     * The width of the component in pixels.
     */
    width: number;
}

/**
 * @returns {DeleteAllocationHandler} A function that deletes the given allocation.
 */
function useDeleteAllocation() {
    const getAllocationSchedules = useGetAllocationSchedules();
    const [deleteSchedule] = useDeleteSchedule();
    // To avoid UI flashing, we're using our own loading state,
    // instead of the loading state provided by the mutation,
    // which updates upon every mutation request.
    const [loading, setLoading] = useState(false);
    const { dialog } = useLayer();
    const getConfirmDeleteDialogProps = useConfirmAllocationDeleteDialog();

    /**
     * @throws {ApolloError} If any schedule delete request fails.
     */
    const deleteAllocationSchedules = useCallback(
        async (allocationSchedules: AllocationSchedule[]) => {
            await Promise.all(
                allocationSchedules.map(schedule =>
                    silenceKnownDeleteError(
                        deleteSchedule({
                            variables: {
                                deviceScheduleID: schedule.id,
                            },
                        }),
                    ),
                ),
            );
        },
        [deleteSchedule],
    );

    /**
     * In the case where the application encounters an unexpected collection of schedules,
     * when deleting an allocation, we want to prompt the user to confirm the deletion.
     *
     * @returns {Promise<DeleteAllocationResult>} The result of the delete operation.
     * @throws {ApolloError} If any schedule delete request fails.
     */
    const confirmAllocationDeletion = useCallback(
        async (allocationSchedules: AllocationSchedule[]) => {
            const confirmation = new Deferred<DeleteAllocationResult>();

            dialog.add((item): ComponentProps<typeof Dialog> => {
                async function onConfirmClick() {
                    try {
                        await deleteAllocationSchedules(allocationSchedules);
                        confirmation.resolve(DeleteAllocationResult.Success);
                    } catch (error) {
                        confirmation.reject(error);
                    } finally {
                        item.close();
                    }
                }

                function onDenyClick() {
                    confirmation.resolve(DeleteAllocationResult.UserDenied);
                    item.close();
                }

                return getConfirmDeleteDialogProps({
                    allocationSchedules,
                    onConfirmClick,
                    onDenyClick,
                });
            });

            return confirmation.promise;
        },
        [getConfirmDeleteDialogProps, dialog, deleteAllocationSchedules],
    );

    /**
     * @returns {Promise<DeleteAllocationResult>} The result of the delete operation.
     * @throws {ApolloError}
     */
    const deleteAllocation = useCallback<DeleteAllocationHandler>(
        async allocation => {
            setLoading(true);

            try {
                const allocationSchedules = await getAllocationSchedules(allocation);

                // Additional checks are in place to prevent data loss.
                if (!isExpectedSchedulesForAllocation(allocationSchedules)) {
                    return confirmAllocationDeletion(allocationSchedules);
                }

                await deleteAllocationSchedules(allocationSchedules);

                return DeleteAllocationResult.Success;
            } catch (error) {
                throw error;
            } finally {
                setLoading(false);
            }
        },
        [deleteAllocationSchedules, confirmAllocationDeletion, getAllocationSchedules],
    );

    return [deleteAllocation, { loading }] as const;
}

/**
 * @returns {PersistAllocationHandler} A function that persists the given allocation.
 */
function usePersistAllocation() {
    const [persistSchedules, { loading }] = usePersistSchedules();

    /**
     * @throws {ApolloError}
     */
    const persistAllocation = useCallback<PersistAllocationHandler>(
        async allocation => {
            await persistSchedules({
                variables: {
                    input: { schedules: allocationToDeviceInstanceSchedules(allocation) },
                },
            });
        },
        [persistSchedules],
    );

    return [persistAllocation, { loading }] as const;
}

/**
 * The `ReservationSchedule` component is a schedule view that enables end-users
 * to view and manage unit allocations for a reservation.
 */
export function ReservationScheduleContainer({
    controllerRef,
    height,
    onAssignAllocationRequest,
    reservationID,
    width,
}: ReservationScheduleContainerProps) {
    const [handlePersistAllocation, { loading: persistAllocationLoading }] = usePersistAllocation();
    const [handleDeleteAllocation, { loading: deleteAllocationLoading }] = useDeleteAllocation();

    const {
        data: reservationItems,
        loading: reservationItemsLoading,
        refetch: refetchReservationItems,
    } = useReservationItems(reservationID);

    const handleRefreshReservationItems = useCallback<RefreshReservationItemsHandler>(
        () => refetchReservationItems().then(() => {}),
        [refetchReservationItems],
    );

    const loading = deleteAllocationLoading || persistAllocationLoading || reservationItemsLoading;

    return (
        <ReservationSchedulePresenter
            controllerRef={controllerRef}
            handleDeleteAllocation={handleDeleteAllocation}
            handlePersistAllocation={handlePersistAllocation}
            handleRefreshReservationItems={handleRefreshReservationItems}
            height={height}
            loading={loading}
            onAssignAllocationRequest={onAssignAllocationRequest}
            reservationItems={reservationItems || []}
            width={width}
        />
    );
}
