import { ReactElement } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Layer from 'app/components/primitives/layout/Layer';
import { LinearPercentageScale, Progress } from 'app/components/primitives/Progress';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import LineRow from './LineRow';

interface Props extends BoxProps {
    /**
     * The 3 phase voltage per line in Volts (V)
     */
    line1LoadVoltage?: number | null | undefined;
    line2LoadVoltage?: number | null | undefined;
    line3LoadVoltage?: number | null | undefined;
    lineNLoadVoltage?: number | null | undefined;

    /**
     * The 3 phase current per line in Ampere (A)
     */
    line1LoadCurrent?: number | null | undefined;
    line2LoadCurrent?: number | null | undefined;
    line3LoadCurrent?: number | null | undefined;
    lineNLoadCurrent?: number | null | undefined;

    maxLineCurrent?: number | null | undefined;
}

type Line = '1' | '2' | '3' | 'N';

function resolveShortLineName(line?: Line): string {
    if (/^n$/i.test(line ?? '')) return 'N';

    return `L${line}`.toUpperCase();
}

/**
 * Responsive line label to help save space at smaller breakpoints
 */
function LineLabel({ line, spacer }: { line?: Line; spacer?: boolean }): ReactElement {
    const { t } = useI18n();

    return (
        <>
            <Text sx={{ minWidth: '7ch', display: { xs: 'none', md: 'inline-block' } }} preserveHeight={1}>
                {spacer ? '' : t(`unit_status.line${line}_label`)}
            </Text>

            <Text sx={{ minWidth: '2ch', display: { xs: 'inline-block', md: 'none' } }} preserveHeight={1}>
                {spacer ? '' : resolveShortLineName(line)}
            </Text>
        </>
    );
}

/**
 * Responsible for rendering the power out stats for an MP unit
 */
export default function LineLoads({
    line1LoadVoltage,
    line2LoadVoltage,
    line3LoadVoltage,
    lineNLoadVoltage,

    line1LoadCurrent,
    line2LoadCurrent,
    line3LoadCurrent,
    lineNLoadCurrent,

    maxLineCurrent,

    ...props
}: Props): ReactElement {
    const { format } = useI18n();

    function formatValue(value: number | null | undefined, { unit }: { unit: string }): string {
        const result = typeof value === 'number' ? format.decimal(value) : '?';

        return `${result} ${unit}`;
    }

    return (
        <Layer anchor {...props}>
            <Stack spacing={2}>
                <LineRow
                    label={<LineLabel line="1" />}
                    progress={<Progress value={line1LoadCurrent} max={maxLineCurrent} />}
                    current={formatValue(line1LoadCurrent, { unit: 'A' })}
                    voltage={formatValue(line1LoadVoltage, { unit: 'V' })}
                />

                <LineRow
                    label={<LineLabel line="2" />}
                    progress={<Progress value={line2LoadCurrent} max={maxLineCurrent} />}
                    current={formatValue(line2LoadCurrent, { unit: 'A' })}
                    voltage={formatValue(line2LoadVoltage, { unit: 'V' })}
                />

                <LineRow
                    label={<LineLabel line="3" />}
                    progress={<Progress value={line3LoadCurrent} max={maxLineCurrent} />}
                    current={formatValue(line3LoadCurrent, { unit: 'A' })}
                    voltage={formatValue(line3LoadVoltage, { unit: 'V' })}
                />

                <LineRow
                    label={<LineLabel line="N" />}
                    progress={<Progress value={lineNLoadCurrent} max={maxLineCurrent} />}
                    current={formatValue(lineNLoadCurrent, { unit: 'A' })}
                    voltage={formatValue(lineNLoadVoltage, { unit: 'V' })}
                />

                <Box>
                    <LineRow mt={-2} label={<LineLabel spacer />} progress={<LinearPercentageScale />} />
                </Box>
            </Stack>
        </Layer>
    );
}
