import { ComponentProps, useId, useState } from 'react';

import { Paper } from '@mui/material';

import { Button, EditableSection, SectionHeader } from 'app/components';

import { useI18n } from 'i18n';

import EditAccountRolesForm from './EditAccountRolesForm';

interface AccountRolesProps extends Omit<ComponentProps<typeof EditAccountRolesForm>, 'formID'> {}

export default function AccountRoles({ onFormStateChange, ...props }: AccountRolesProps) {
    const [disableEditCta, setDisableEditCta] = useState(false);
    const editFormID = useId();
    const { t } = useI18n();

    return (
        <Paper variant="outlined" sx={{ p: 4 }}>
            <EditableSection
                header={<SectionHeader title={t('admin_account_page.roles.heading')} />}
                saveCTA={<Button type="submit" form={editFormID} disabled={disableEditCta} />}
                disableAutoFocus
            >
                {({ isEditing }) => (
                    <EditAccountRolesForm
                        {...props}
                        formID={editFormID}
                        hideSubmitCTA
                        readOnly={!isEditing}
                        onFormStateChange={event => {
                            const { shouldDisableCTA } = event;

                            setDisableEditCta(shouldDisableCTA);
                            onFormStateChange?.(event);
                        }}
                    />
                )}
            </EditableSection>
        </Paper>
    );
}
