import { DateTime } from 'luxon';

import { resolveDateTime } from 'app/core/date-time';
import { ISO8601 } from 'app/core/types';

import {
    GetReservationItemTelemetryQueryHookResult,
    Telemetry,
    useGetEnergyUsageForMpuLazyQuery,
    useGetEnergyUsageForMpuQuery,
    useGetEnergyUsageForUnitLazyQuery,
    useGetEnergyUsageForUnitQuery,
} from 'generated/graphql';

import resolveGroupBy from './resolveGroupBy';
import useNowPoller from './useNowPoller';

interface Input {
    /**
     * The reservation item's id to pull telemetry for
     */
    unitID?: string | undefined;

    /**
     * The device instance id to pull telemetry for
     */
    mpuID?: string | undefined;

    /**
     * Whether to enable polling for updated unit status
     */
    enablePolling?: boolean;

    /**
     * How frequently to check for updated status
     */
    pollInterval?: number;

    /**
     * When to pull telemetry from
     */
    start?: DateTime | ISO8601 | null;

    /**
     * The end of the interval to pull telemetry from, if not present or end is in the future now will be used
     */
    end?: DateTime | ISO8601 | null;

    /**
     * Whether to skip the operation or not
     */
    skip?: boolean;
}

interface Output extends Pick<GetReservationItemTelemetryQueryHookResult, 'loading' | 'error'> {
    data: Telemetry[];
}

/**
 * Responsible for fetching the energy usage either by MPU or for the Unit in a reservation (which may be multiple MPUs)
 */
export default function useEnergyUsage({
    unitID,
    mpuID,
    enablePolling,
    pollInterval,
    start,
    end,
    skip,
}: Input): Output {
    const now = useNowPoller({ enablePolling, pollInterval });

    const _start = start ? resolveDateTime(start) : now.minus({ hour: 1 });
    const _end = end ? resolveDateTime(end) : now;
    const groupBy = resolveGroupBy({ start: _start, end: _end });

    const {
        loading: reservationTelemetryLoading,
        error: reservationTelemetryError,
        data: reservationTelemetryData,
    } = useGetEnergyUsageForUnitQuery({
        skip: skip || !!mpuID || !unitID,
        errorPolicy: 'all',
        variables: {
            id: unitID ?? '',
            groupBy,
            // TODO(Derek): put back once query supports a passed interval (start, end)
            // start: _start.toISO() ?? '',
            // end: _end.toISO() ?? '',
        },
    });

    const {
        loading: deviceTelemetryLoading,
        error: deviceTelemetryError,
        data: deviceTelemetryData,
    } = useGetEnergyUsageForMpuQuery({
        skip: skip || !!unitID || !mpuID,
        errorPolicy: 'all',
        variables: {
            id: mpuID ?? '',
            groupBy,
            start: _start.toISO() ?? '',
            end: _end.toISO() ?? '',
        },
    });

    const data = reservationTelemetryData?.reservationEnergyUsage || deviceTelemetryData?.deviceEnergyUsage;
    const error = reservationTelemetryError || deviceTelemetryError;

    return {
        loading: reservationTelemetryLoading || deviceTelemetryLoading,
        error: !!data && !!error ? undefined : error,
        data: data?.telemetries ?? [],
    };
}

/**
 * Responsible for fetching the energy usage either by MPU or for the Unit in a reservation
 * (which may be multiple MPUs) on demand
 */
export function useEnergyUsageLazy() {
    const [fetchEnergyUsageForMPU] = useGetEnergyUsageForMpuLazyQuery();
    const [fetchEnergyUsageForUnit] = useGetEnergyUsageForUnitLazyQuery();

    return async function fetchTelemetryData({
        unitID,
        mpuID,
        start,
        end,
    }: {
        unitID?: string;
        mpuID?: string;
        start?: DateTime | ISO8601;
        end?: DateTime | ISO8601;
    }): Promise<{ data: Telemetry[] }> {
        const now = DateTime.now();

        const _start = start ? resolveDateTime(start) : now.minus({ hour: 1 });
        const _end = end ? resolveDateTime(end) : now;
        const groupBy = resolveGroupBy({ start: _start, end: _end });

        if (unitID) {
            const { data } = await fetchEnergyUsageForUnit({
                fetchPolicy: 'cache-first',
                variables: {
                    id: unitID,
                    groupBy,
                    // TODO(Derek): put back once query supports a passed interval (start, end)
                    // start: _start.toISO() ?? '',
                    // end: _end.toISO() ?? '',
                },
            });

            return { data: data?.reservationEnergyUsage?.telemetries ?? [] };
        }

        if (mpuID) {
            const { data } = await fetchEnergyUsageForMPU({
                fetchPolicy: 'cache-first',
                variables: {
                    id: mpuID,
                    groupBy,
                    start: _start.toISO() ?? '',
                    end: _end.toISO() ?? '',
                },
            });

            return { data: data?.deviceEnergyUsage?.telemetries ?? [] };
        }

        return { data: [] };
    };
}
