const BASE_FONT_SIZE_PX = 16;

type CSSDimensionValue = 'px' | 'rem';

export default class CSSDimension {
    private value: number;
    private unit: CSSDimensionValue;

    private BASE_FONT_SIZE_PX: number = BASE_FONT_SIZE_PX;

    static fromPixels(value: number) {
        return new CSSDimension({ value, unit: 'px' });
    }

    static fromRem(value: number) {
        return new CSSDimension({ value, unit: 'rem' });
    }

    constructor({ value, unit }: { value: number; unit: CSSDimensionValue }) {
        this.value = value;
        this.unit = unit;
    }

    private convertValueTo(unit: CSSDimensionValue) {
        let value = this.value;

        if (this.unit === 'px' && unit === 'rem') {
            value = this.value / this.BASE_FONT_SIZE_PX;
        } else if (this.unit === 'rem' && unit === 'px') {
            value = this.value * this.BASE_FONT_SIZE_PX;
        }

        return value;
    }

    asValue(unit: CSSDimensionValue): number {
        return this.convertValueTo(unit);
    }

    as(unit: CSSDimensionValue): string {
        const value = this.convertValueTo(unit);

        return `${value}${unit}`;
    }
}
