import { DateTime } from 'luxon';

export function areDatesEqual(date1?: DateTime, date2?: DateTime): boolean {
    if (!date1 || !date2) return false;

    return date1.startOf('day').equals(date2.startOf('day'));
}

export function isInRange({ start, end, day }: { start?: DateTime; end?: DateTime; day?: DateTime }): boolean {
    if (!start || !end || !day) return false;

    return day > start.endOf('day') && day < end.startOf('day');
}

export function isWeekStart(day: DateTime): boolean {
    return day.weekday === 1;
}

export function isWeekEnd(day: DateTime): boolean {
    return day.weekday === 7;
}
