import { FilterCriteria, FilterCriterion, IFilterStorage } from './types';

const NULL_FILTER_CRITERIA = {
    alertSeverities: [],
    reservationIDs: [],
    rentalAccountIDs: [],
    serviceAreaIDs: [],
};

/**
 * Given the filterCriteria state object, prepares an object to be serialized by react-router-dom
 */
function serializeFilterCriteria(filterCriteria?: FilterCriteria): {
    alertSeverities: string[];
    reservationIDs: string[];
    rentalAccountIDs: string[];
    serviceAreaIDs: string[];
} {
    return Object.keys(filterCriteria ?? {}).reduce((acc, cur) => {
        const result = (filterCriteria?.[cur] ?? []).map(x => `${x.label}:${x.id}`).join(',');

        if (!result) return acc;

        return {
            ...acc,
            [cur]: [result],
        };
    }, NULL_FILTER_CRITERIA);
}

function parseFilter(filter: string): FilterCriterion {
    const [label, id] = filter.split(':');

    return {
        label,
        id,
    };
}

function parseFilterField(filterField?: string | null): FilterCriterion[] {
    return (filterField?.split(',') ?? []).map(parseFilter);
}

/**
 * Given a previously serailized filter criteria will return the expected state object
 */
function deserializeFilterCriteria(filters: URLSearchParams): FilterCriteria {
    return {
        alertSeverities: parseFilterField(filters.get('alertSeverities')),
        reservationIDs: parseFilterField(filters.get('reservationIDs')),
        rentalAccountIDs: parseFilterField(filters.get('rentalAccountIDs')),
        serviceAreaIDs: parseFilterField(filters.get('serviceAreaIDs')),
    };
}

/**
 * Responsible for storing filter state on the URL via querystring
 */
export default function useFilterStorage({ searchParams, setSearchParams }): IFilterStorage {
    function persistFilterStateToURL({
        activeFilter,
        customFilterCriteria,
    }: {
        activeFilter?: string;
        customFilterCriteria?: FilterCriteria;
    }) {
        setSearchParams({
            // Make sure we preserve non filter query params if / when they get added
            ...Object.fromEntries(searchParams.entries()),
            activeFilter: activeFilter ?? '',
            ...serializeFilterCriteria(customFilterCriteria),
        });
    }

    return {
        getActiveFilter: () => searchParams.get('activeFilter') ?? 'all',
        getCustomFilterCriteria: () =>
            searchParams.get('activeFilter') === 'custom'
                ? deserializeFilterCriteria(searchParams)
                : NULL_FILTER_CRITERIA,

        save: persistFilterStateToURL,
    };
}
