import { ReactElement } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { useLayer } from 'app/core/layers';

import { useCreateAccountMutation } from 'generated/graphql';

import { useI18n } from 'i18n';

import CreateAccountDialog from './CreateAccountDialog';

export default function CreateAccountPage(): ReactElement {
    const { t } = useI18n();
    const { toast } = useLayer();

    const navigate = useNavigate();

    const { onAccountCreated } = useOutletContext<{ onAccountCreated?: () => void }>() ?? {};

    const [createAccount] = useCreateAccountMutation();

    return (
        <CreateAccountDialog
            id="create-account-dialog"
            open={true}
            onClose={() => {
                navigate(-1);
            }}
            onCreateAccount={async accountDetails => {
                let createdAccount = { id: '', name: '' };

                try {
                    const { data } = await createAccount({
                        variables: {
                            accountDetails,
                        },
                    });

                    createdAccount = { id: data?.createAccount?.id ?? '', name: accountDetails.name };
                } catch (error) {
                    toast.add({
                        severity: 'error',
                        message: t('admin_account_index_page.create_account_error'),
                    });

                    throw error;
                }

                await onAccountCreated?.();

                return createdAccount;
            }}
        />
    );
}
