import { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { Link } from 'react-router-dom';

import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import {
    DateTimeRangePicker,
    DynamicTableBody,
    IntervalOption,
    makeInterval,
    makeZone,
    Table,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Text,
    TimezoneOption,
    TimezonePicker,
    ZeroState,
} from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { ListScheduleTransitionsQuery, useListScheduleTransitionsQuery } from 'generated/graphql';

import { useI18n } from 'i18n';

interface ListScheduleTransitionsAPI {
    data: Array<ListScheduleTransitionsQuery['listScheduleTransitions'][0] & { key: string }>;
    loading: boolean;
    refetch: () => void;
    error?: ApolloError;
}

function useListScheduleTransitions(interval: Interval, filters?: { accountID?: string }): ListScheduleTransitionsAPI {
    const input = {
        start: interval.start?.toISO() ?? '',
        end: interval.end?.toISO() ?? '',
        accountID: filters?.accountID,
    };

    const {
        data,
        loading,
        error,
        refetch: refetchTransitions,
    } = useListScheduleTransitionsQuery({
        variables: {
            input,
        },
    });

    const refetch = (): void => {
        refetchTransitions({
            input,
        });
    };

    const transitions = data?.listScheduleTransitions ?? [];
    const transitionsWithKey = transitions.map(t => ({
        ...t,
        key: crypto.randomUUID(),
    }));
    return { data: transitionsWithKey, loading, error, refetch };
}

export function TransitionIndexPage(): JSX.Element {
    const { t, format } = useI18n();

    // timezone
    const [timezoneName, setTimezoneName] = useState<TimezoneOption>('Local Time');
    const tz = makeZone(timezoneName);
    const handleTimezoneChange = ({ value }) => {
        setTimezoneName(value);
    };

    // datetime
    const [intervalName, setIntervalName] = useState<IntervalOption>('Today');
    const interval = makeInterval(intervalName, tz);
    const handleIntervalChange = ({ value }) => {
        setIntervalName(value);
    };

    const { data: transitions, loading, error, refetch: refetchTransitions } = useListScheduleTransitions(interval);

    return (
        <>
            <Text variant="h1" gutterBottom>
                {t('admin_transition_index_page.title')}
            </Text>

            {error && (
                <ZeroState
                    title={t('admin_transition_index_page.zero_state.fetch_error.title')}
                    message={t('admin_transition_index_page.zero_state.fetch_error.message')}
                    cta={
                        <Button color="secondary" variant="contained" onClick={() => refetchTransitions()}>
                            {t('cta.retry')}
                        </Button>
                    }
                />
            )}

            {!error && (
                <Grid container spacing={4} justifyContent="flex-end">
                    <Grid item xs={12} md={2}>
                        <TimezonePicker onChange={handleTimezoneChange} name="timezone" value={timezoneName} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <DateTimeRangePicker onChange={handleIntervalChange} name="interval" value={intervalName} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TableContainer component={Paper} sx={{ borderRadius: 5, p: 4 }} elevation={0}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                {/* @ts-expect-error */}
                                <TableHead variant="contained">
                                    <TableRow>
                                        <TableCell>{t('admin_transition_index_page.table.header.type')}</TableCell>
                                        <TableCell>{t('admin_transition_index_page.table.header.time')}</TableCell>
                                        <TableCell>
                                            {t('admin_transition_index_page.table.header.reservation')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <DynamicTableBody
                                    isLoading={loading}
                                    isEmpty={transitions.length === 0}
                                    fallback={
                                        <ZeroState
                                            title={t('admin_transition_index_page.zero_state.empty.title')}
                                            message={t('admin_transition_index_page.zero_state.empty.message')}
                                            cta={
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => refetchTransitions()}
                                                >
                                                    {t('cta.refresh')}
                                                </Button>
                                            }
                                        />
                                    }
                                >
                                    {transitions.map(transition => {
                                        const reservationID: string | undefined =
                                            transition.from?.reservationItem?.reservation.id ??
                                            transition.to?.reservationItem?.reservation.id ??
                                            undefined;

                                        const dateTime = DateTime.fromISO(transition.time);
                                        return (
                                            <TableRow key={transition.key}>
                                                <TableCell> {transition.type} </TableCell>
                                                <TableCell> {format.longDateTime(dateTime)} </TableCell>
                                                <TableCell>
                                                    <Link
                                                        to={getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', {
                                                            params: { reservationID },
                                                        })}
                                                    >
                                                        {reservationID}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </DynamicTableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell>
                                            {t('admin_transition_index_page.table.footer.transition_count')}:{' '}
                                            {transitions.length}{' '}
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
