import { DEFAULT_ASIDE_GRID_SIZE, MUI_MAX_GRID_SIZE } from './constants';
import { MaterialGridSizeStrict } from './types';

export interface MultiGridDimensions {
    /**
     * The sum of `asideGridSize` and `headingGridSize` must equal `12`.
     */
    asideGridSize: MaterialGridSizeStrict;
    /**
     * The height of the aside vertical list.
     */
    asideHeight: number;
    /**
     * The width of the aside vertical list.
     */
    asideWidth: number;
    /**
     * The width of the cells in the body grid.
     */
    bodyCellWidth: number;
    /**
     * An alias of `headingGridSize` for clarity.
     */
    bodyGridSize: MaterialGridSizeStrict;
    /**
     * An alias of `asideHeight` for clarity.
     */
    bodyHeight: number;
    /**
     * An alias of `headingWidth` for clarity.
     */
    bodyWidth: number;
    /**
     * The height of the component's outer container.
     */
    containerHeight: number;
    /**
     * The width of the component's outer container.
     */
    containerWidth: number;
    /**
     * An alias of `bodyCellWidth` for clarity.
     */
    headingCellWidth: number;
    /**
     * The sum of `asideGridSize` and `headingGridSize` must equal `12`.
     * This is derived from `asideGridSize`.
     */
    headingGridSize: MaterialGridSizeStrict;
    /**
     * The height of the heading horizontal list.
     */
    headingHeight: number;
    /**
     * The width of the heading horizontal list.
     */
    headingWidth: number;
}

interface GetMultiGridDimensionsParams {
    asideGridSize?: MaterialGridSizeStrict;
    bodyColumnsDisplayed: number;
    headingHeight: number;
    height: number;
    width: number;
}

export function getMultiGridDimensions({
    asideGridSize = DEFAULT_ASIDE_GRID_SIZE,
    bodyColumnsDisplayed,
    headingHeight,
    height: containerHeight,
    width: containerWidth,
}: GetMultiGridDimensionsParams): MultiGridDimensions {
    const asideHeight = containerHeight - headingHeight;
    const headingGridSize = (MUI_MAX_GRID_SIZE - asideGridSize) as MaterialGridSizeStrict;
    /**
     * The width of the aside list in pixels.
     *
     * The value is rounded up to account for sub-pixel differences in width.
     * This is due to MUI using CSS `calc`, which _doesn't_ round to an pixel integer.
     */
    const asideWidth = Math.ceil(containerWidth * (asideGridSize / MUI_MAX_GRID_SIZE));
    /** The width of the heading in pixels. */
    const headingWidth = containerWidth - asideWidth;
    /**
     * The width of the body grid in pixels.
     *
     * The value is rounded down to ensure the required columns are displayed.
     */
    const bodyCellWidth = Math.floor(headingWidth / bodyColumnsDisplayed);

    return {
        asideGridSize,
        asideHeight,
        asideWidth,
        bodyCellWidth,
        bodyGridSize: headingGridSize,
        bodyHeight: asideHeight,
        bodyWidth: headingWidth,
        containerHeight,
        containerWidth,
        headingCellWidth: bodyCellWidth,
        headingGridSize,
        headingHeight,
        headingWidth,
    };
}
