import { mpuFilterType } from '../constants';
import type { FilterFormProps } from '../types';
import MPUFilter from './MPUFilter';

const FilterForm = ({ ownerAccountIDs, type, ...otherProps }: FilterFormProps) => {
    switch (type) {
        case mpuFilterType:
            // @ts-ignore
            return <MPUFilter ownerAccountIDs={ownerAccountIDs} {...otherProps} />;
        // case reservationFilterType:
        //     return <ReservationFilter type={type} {...otherProps} />;
        // case otherFilterType:
        //     return <OtherFilter {...otherProps} />;
        default:
            return null;
    }
};

export default FilterForm;
