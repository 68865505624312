/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import FontFaceObserver from 'fontfaceobserver';

import { initYupExtensions } from 'app/core/validation';

// Use consistent styling
import 'maplibre-gl/dist/maplibre-gl.css';
import 'sanitize.css/sanitize.css';

import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

import { initAppCore } from 'app/core';
import initDataDog from 'app/core/Monitoring/datadog';
import { ExperienceSwitcher, ExperienceSwitcherProvider, RouterProvider } from 'app/core/Navigation';
import { GQLClientProvider } from 'app/core/network';
import { SessionProvider } from 'app/core/Session';

import { OktaProvider } from 'okta/OktaProvider';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

// Initialize languages
import { I18nProvider, makeI18n } from './i18n';
import reportWebVitals from './reportWebVitals';

initYupExtensions();

const openSansObserver = new FontFaceObserver('Inter', {});

openSansObserver.load().then(() => {
    document.body.classList.remove('fontFallback');
    document.body.classList.add('fontLoaded');
});

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

const { client, session } = initAppCore();
const experienceSwitcher = new ExperienceSwitcher({ session });

// initialize datadog and set up sign in / sign out listeners
initDataDog(session);

root.render(
    <ThemeProvider>
        <HelmetProvider>
            <CookiesProvider>
                <GQLClientProvider client={client}>
                    {/**
                     * Strict mode will run hooks twice to detect issues, in dev mode only, however this causes issues
                     * with the ApolloProvider, causing requests to be cancelled after they have been fetched once, amongst
                     * other reported issues. I'd like to keep using Strict however it alters the behavior
                     * or the app in development which I think outweighs it's benefit. We should revisit
                     * as Apollo Client addresses issues with strict mode.
                     */}
                    <I18nProvider i18n={makeI18n()}>
                        <OktaProvider>
                            <SessionProvider session={session}>
                                <ExperienceSwitcherProvider experienceSwitcher={experienceSwitcher}>
                                    <RouterProvider
                                        client={client}
                                        session={session}
                                        experienceSwitcher={experienceSwitcher}
                                    />
                                </ExperienceSwitcherProvider>
                            </SessionProvider>
                        </OktaProvider>
                    </I18nProvider>
                </GQLClientProvider>
            </CookiesProvider>
        </HelmetProvider>
    </ThemeProvider>,
);

// Hot reloadable translation json files
if (module.hot) {
    module.hot.accept(['./i18n'], () => {
        // No need to render the App again because i18next works with the hooks
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
