import { ComponentProps, ReactElement } from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import Input, { useInputForAutocomplete } from 'app/components/primitives/interactive/Input';

import { useAccountUserSelectorQuery } from 'generated/graphql';

interface Props extends Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'value' | 'onChange' | 'sx'> {
    /**
     * Required for selection but optional to allow account loading or intermediate states where an account is not selected
     */
    accountID?: string;

    /**
     * The selected value is the userID
     */
    value: string;

    /**
     * Event handler to be called when the value changes
     */
    onChange: (x: { value: { id?: string; name?: string | null } | null }) => void;
}

/**
 * Responsible for rending a form control with allows users to select from a list of known accounts
 */
export default function AccountUserSelector({
    id,

    fullWidth = false,
    fieldName,
    caption,
    required,
    valid,
    error,
    name,
    value,
    accountID,
    disabled,
    placeholder,
    onChange = x => {},
    ...props
}: Props): ReactElement {
    const renderInput = useInputForAutocomplete({
        fieldName,
        caption,
        required,
        error,
        valid,
        name,
        placeholder,
        disabled,
        fullWidth,
        ...props,
    });

    const { data } = useAccountUserSelectorQuery({
        skip: !accountID,
        variables: {
            id: accountID ?? '',
        },
    });

    const users = data?.getAccount?.users ?? [];
    const usersByID = users.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    const options = users.map(({ id }) => id);

    return (
        <Autocomplete
            id={id}
            fullWidth={fullWidth}
            autoHighlight
            value={value}
            disabled={!accountID || disabled}
            disableClearable
            isOptionEqualToValue={(option, currentUserID) => {
                return option === currentUserID;
            }}
            options={options}
            getOptionLabel={option => {
                return usersByID[option]?.name ?? '';
            }}
            renderInput={renderInput}
            renderOption={(props, option) => (
                <li {...props} key={option}>
                    {usersByID[option].name}
                </li>
            )}
            onChange={(event, newValue) => {
                onChange({ value: usersByID[newValue ?? ''] ?? null });
            }}
        />
    );
}
