import { MouseEventHandler, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { TransitionContentHeight } from 'app/components/primitives';
import Button from 'app/components/primitives/interactive/Button';
import { TimeChangeEvent } from 'app/components/primitives/interactive/date-time-inputs';
import DateRangePicker, { DateRangeChangeEvent } from 'app/components/primitives/interactive/DateRangePicker';
import Text from 'app/components/primitives/Text';
import { combineDateAndTime, DEFAULT_TIME_ZONE } from 'app/core/date-time';
import { IANATimeZone, ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import {
    getUnit,
    getUnitNextAllocation,
    getUnitPrevAllocation,
    hasGapWithNextAllocation,
    hasGapWithUnitStart,
} from './accessors';
import {
    insertPendingAllocationAfter,
    insertPendingAllocationBefore,
    removeAllocation,
    requestAllocationAssignment,
    updateAllocationPeriod,
} from './actions';
import { AllocationSwapTimeSelector } from './AllocationSwapTimeSelector';
import { getMaxEndDateTime, getMinStartDateTime, makeValidationSchema } from './makeValidationSchema';
import { ReservationScheduleAllocationMPU } from './ReservationScheduleAllocationMPU';
import { ReservationScheduleContext } from './ReservationScheduleContext';
import { Allocation } from './types';

export interface ReservationScheduleAllocationFormValues {
    endDate: ISO8601 | null;
    endTime: ISO8601 | null;
    endTimeZone: IANATimeZone;
    startDate: ISO8601 | null;
    startTime: ISO8601 | null;
    startTimeZone: IANATimeZone;
}

export function ReservationScheduleAllocation({ allocation }: { allocation: Allocation }): ReactElement {
    const { t } = useI18n();
    const { dispatch, getState } = ReservationScheduleContext.useContext();
    const allocationID = allocation.id;
    const unit = getUnit(getState(), allocation.unitID);
    const isFirstAllocation = allocationID === unit?.allocations.at(0)?.id;
    const state = getState();
    const showInsertAllocationBeforeButton = hasGapWithUnitStart(state, allocationID);
    const showInsertAllocationAfterButton = hasGapWithNextAllocation(state, allocationID);
    const isFinishingTheUnit = allocation.end === unit?.end;
    const disableSwapTime = isFinishingTheUnit && !isFirstAllocation;

    const handleAssignButtonClick = useCallback<MouseEventHandler>(() => {
        dispatch(requestAllocationAssignment(allocationID));
    }, [allocationID, dispatch]);

    const handleRemoveButtonClick = useCallback<MouseEventHandler>(() => {
        dispatch(removeAllocation(allocationID));
    }, [allocationID, dispatch]);

    const handleInsertAllocationBeforeButtonClick = useCallback<MouseEventHandler>(() => {
        dispatch(insertPendingAllocationBefore(allocationID));
    }, [allocationID, dispatch]);

    const handleInsertAllocationAfterButtonClick = useCallback<MouseEventHandler>(() => {
        dispatch(insertPendingAllocationAfter(allocationID));
    }, [allocationID, dispatch]);

    const initialValues = useMemo<ReservationScheduleAllocationFormValues>(() => {
        const initialTimeZone = DEFAULT_TIME_ZONE;
        const endTimeZone = initialTimeZone;
        const startTimeZone = initialTimeZone;
        const endDateTime = DateTime.fromISO(allocation.end, { zone: endTimeZone });
        const startDateTime = DateTime.fromISO(allocation.start, { zone: startTimeZone });
        const endTime = endDateTime.toISOTime();
        const startTime = startDateTime.toISOTime();
        const endDate = endDateTime.toISODate();
        const startDate = startDateTime.toISODate();

        return {
            endDate,
            endTime,
            endTimeZone,
            startDate,
            startTime,
            startTimeZone,
        };
    }, [allocation]);

    const nextAllocation = getUnitNextAllocation(getState(), allocationID);
    const prevAllocation = getUnitPrevAllocation(getState(), allocationID);

    const maxEndDateTime = unit
        ? getMaxEndDateTime({
              nextAllocationEnd: nextAllocation?.end,
              unitEnd: unit?.end,
          })
        : undefined;

    const minStartDateTime = unit
        ? getMinStartDateTime({
              prevAllocationStart: prevAllocation?.start,
              unitStart: unit?.start,
          })
        : undefined;

    const validationSchema = useMemo(() => {
        return makeValidationSchema({
            nextAllocation,
            prevAllocation,
            t,
            unit,
        });
    }, [nextAllocation, prevAllocation, t, unit]);

    const onSubmit = useCallback(
        async (values: ReservationScheduleAllocationFormValues) => {
            const { endDate, endTime, endTimeZone, startDate, startTime, startTimeZone } = values;
            const id = allocationID;
            const end = combineDateAndTime(endDate, endTime, endTimeZone).toISO();
            const start = combineDateAndTime(startDate, startTime, startTimeZone).toISO();

            if (end && start) {
                dispatch(updateAllocationPeriod({ id, end, start }));
            }
        },
        [allocationID, dispatch],
    );

    const {
        setFieldValue,
        dirty,
        errors,
        handleBlur,
        isSubmitting,
        isValid,
        isValidating,
        setValues,
        resetForm,
        submitForm,
        values,
    } = useFormik({
        initialValues,
        validateOnMount: true,
        validateOnChange: true,
        validationSchema,
        onSubmit,
    });

    // FYI: This functionality _can't_ be replaced by Formiks' `enableReinitialize` option.
    useEffect(() => {
        resetForm();
        setValues(initialValues);
    }, [initialValues, resetForm, setValues]);

    useEffect(() => {
        if (dirty && isValid && !isValidating && !isSubmitting) {
            submitForm();
        }
    }, [isSubmitting, isValid, isValidating, dirty, submitForm]);

    const handleDateRangeChange = useCallback(
        ({ value }: DateRangeChangeEvent) => {
            setValues({ ...values, ...value }, true);
        },
        [values, setValues],
    );

    const handleSwapTimeChange = useCallback(
        (event: TimeChangeEvent) => {
            setFieldValue('endTime', event.value, true);
        },
        [setFieldValue],
    );

    const isAssigned = !!allocation.mpuName;
    const errorMessage =
        errors.startDate || errors.startTime || errors.endDate || errors.endTime ? (
            <Text component="p" variant="detail" color="danger" pt={2}>
                {errors.endTime || errors.startDate || errors.endDate || errors.startTime}
            </Text>
        ) : null;

    return (
        <Stack spacing={5} overflow="hidden">
            <Stack direction="column">
                <Stack spacing={3} direction="row">
                    <Box maxWidth="352px">
                        <DateRangePicker
                            disablePast={false}
                            endDate={values.endDate}
                            endName="endDate"
                            endTimeZone={values.endTimeZone}
                            error={!!errors.startDate || !!errors.startTime || !!errors.endDate}
                            fieldName={t('reservation_schedule.labels.date')}
                            maxDate={maxEndDateTime?.toISODate() ?? undefined}
                            minDate={minStartDateTime?.toISODate() ?? undefined}
                            onChange={handleDateRangeChange}
                            onInputBlur={handleBlur}
                            required
                            startDate={values.startDate}
                            startName="startDate"
                            startTimeZone={values.startTimeZone}
                        />
                    </Box>
                    <Box maxWidth="128px">
                        {/* TODO(Morris): Add "End Time" info tooltip. */}
                        <AllocationSwapTimeSelector
                            disabled={disableSwapTime}
                            error={!!errors.endTime}
                            fieldName={
                                isFinishingTheUnit
                                    ? t('reservation_schedule.labels.endTime')
                                    : t('reservation_schedule.labels.swapTime')
                            }
                            name="endTime"
                            onBlur={handleBlur}
                            onChange={handleSwapTimeChange}
                            required={!disableSwapTime}
                            value={values.endTime}
                        />
                    </Box>
                    <Box minWidth="112px">
                        <ReservationScheduleAllocationMPU mpuID={allocation.mpuID} mpuName={allocation.mpuName} />
                    </Box>
                </Stack>
                <TransitionContentHeight content={errorMessage} />
            </Stack>

            <Stack spacing={3} direction="row">
                <Button size="sm" onClick={handleAssignButtonClick} ctaType={isAssigned ? 'secondary' : 'primary'}>
                    {isAssigned ? t('reservation_schedule.actions.reassign') : t('reservation_schedule.actions.assign')}
                </Button>
                <Button color="danger" ctaType="secondary" size="sm" onClick={handleRemoveButtonClick}>
                    {t('reservation_schedule.actions.remove')}
                </Button>
                {showInsertAllocationBeforeButton && (
                    <Button
                        color="utility"
                        ctaType="secondary"
                        size="sm"
                        onClick={handleInsertAllocationBeforeButtonClick}
                    >
                        {t('reservation_schedule.actions.allocate_before')}
                    </Button>
                )}
                {showInsertAllocationAfterButton && (
                    <Button
                        color="utility"
                        ctaType="secondary"
                        size="sm"
                        onClick={handleInsertAllocationAfterButtonClick}
                    >
                        {t('reservation_schedule.actions.allocate_after')}
                    </Button>
                )}
            </Stack>
        </Stack>
    );
}
