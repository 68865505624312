import { ApolloError } from '@apollo/client';
import { DateTime } from 'luxon';

import { extractUnitStatusesFromTelemetry, useNowPoller } from 'app/core/data/deviceTelemetry';

import { useGetReservationDetailsForMonitoringQuery } from 'generated/graphql';

import { useI18n } from 'i18n';

interface AsyncReservation {
    /**
     * Whether the reservation data is being retrieved from the server
     */
    loading: boolean;

    /**
     * The errors returned from server
     */
    error?: ApolloError | undefined;

    /**
     * The reservation data or Null reservation object if still loading
     */
    data: {
        /**
         * The reservation name or client side fallback
         */
        name: string;

        /**
         * Whether the reservation has ended or not
         */
        hasEnded: boolean;

        /**
         * Whether the reservation is active based on the start and end dates
         */
        isActive: boolean;

        /**
         * The reservation items associated with the reservation
         */
        items: {
            /**
             * The reservation item id
             */
            id: string;

            /**
             * Whether a unit has been assigned to fulfill the specific item in the reservation
             */
            hasAssignment: boolean;

            /**
             * The moxion provided name uniquely identifying a specific unit in the fleet
             */
            officialName: string | undefined;

            /**
             * The user provided name for a unit in a reservation. This name should persist for the entire
             * reservation regardless of unit swaps. If the name is not provided then the name will be
             * numerically assigned.
             */
            name: string;

            /**
             * The formatted location for the unit associated with the reservation item currently
             */
            currentLocation?: {
                lat: number;
                lon: number;
            } | null;

            latestDataTimestamp?: string | null;

            /**
             * Timestamp of when the reservation item starts.
             */
            start: string;

            /**
             * Timestamp of when the reservation item ends.
             */
            end: string;
        }[];
    };
}

interface Options {
    pollInterval?: number | undefined;
}

/**
 * Data fetching hook to encapsulate frontend model transformations and data fallbacks
 */
export default function useReservation(id: string | undefined, options?: Options): AsyncReservation {
    const { t } = useI18n();
    const now = useNowPoller({ enablePolling: true, pollInterval: options?.pollInterval });

    const { loading, data, error } = useGetReservationDetailsForMonitoringQuery({
        variables: { id: id ?? '', start: now.minus({ hour: 1 }).toISO() ?? '', end: now.toISO() ?? '' },
        skip: !id,
        errorPolicy: 'all',
    });

    const items = (data?.getReservation?.reservationItems ?? [])
        .map((item, index) => {
            const start = item?.start;
            const end = item?.end;
            const assignedDevice = item?.assignedDevice;
            const latestDataTimestamp = assignedDevice?.latestDataTimestamp;

            // TODO(derek): hoist access logic for latestDataTimestamp in the renter case to the backend
            const isLatestDateTimestampInReservationWindow =
                DateTime.fromISO(start) <= DateTime.fromISO(latestDataTimestamp ?? '') &&
                DateTime.fromISO(end) >= DateTime.fromISO(latestDataTimestamp ?? '');

            return {
                hasAssignment: !!assignedDevice,
                id: item?.id,
                officialName: assignedDevice?.name,
                name: item.alias ?? t('reservation_monitoring_page.unit_name_fallback', { index: index + 1 }),
                start,
                end,
                currentLocation: assignedDevice?.latestDeviceLocation,
                latestDataTimestamp: isLatestDateTimestampInReservationWindow ? latestDataTimestamp : null,
                ...extractUnitStatusesFromTelemetry(assignedDevice?.telemetry?.telemetries ?? [], { t }),
            };
        })
        .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
        });

    const hasBegun =
        items.length === 0
            ? false
            : items.reduce((acc, cur) => {
                  return acc && DateTime.fromISO(cur.start) < DateTime.now();
              }, true);

    const hasEnded =
        items.length === 0
            ? false
            : items.reduce((acc, cur) => {
                  return acc && DateTime.fromISO(cur.end) < DateTime.now();
              }, true);

    return {
        loading,
        error: !!data && error ? undefined : error,

        data: {
            name:
                data?.getReservation?.name ??
                t('reservation_monitoring_page.reservation_fallback_title', {
                    id: id?.substring(0, 5),
                }),
            isActive: hasBegun && !hasEnded,
            hasEnded,

            items,
        },
    };
}
