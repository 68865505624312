import * as React from 'react';
import { mergeDeep } from '@apollo/client/utilities';

import { alpha, darken, PaletteColor, PaletteMode } from '@mui/material';

import { GLOBAL_PALETTE as COLOR, LIGHT_THEME_PALETTE } from 'design-system/color';

const { TEXT, BACKGROUND, BORDER, BRAND } = LIGHT_THEME_PALETTE;

type CSSColor = React.CSSProperties['color'];

interface AugmentedPaletteColor extends PaletteColor {
    faded: CSSColor;
}

interface PaletteOverrides {
    // Config / meta
    mode?: PaletteMode;

    // Colors
    primary: PaletteColor;
    secondary: PaletteColor;

    info: PaletteColor;
    success: PaletteColor;
    warning: PaletteColor;
    error: PaletteColor;

    divider: CSSColor;

    // Actions are a mix of values (i.e. opacities) and colors
    // to be applied as a color mask / layer
    action: {
        hover: CSSColor;
        active: CSSColor;
        focus: CSSColor;
        selected: CSSColor;
    };

    // MUI provides values for the following tokens
    // (grey.{50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700})
    // These greys may be used internally inside of MUI components but we should avoid
    // these greys in favor of the design defined ones.
    grey: {
        50: CSSColor;
        300: CSSColor;
        500: CSSColor;
        700: CSSColor;
        800: CSSColor;
        900: CSSColor;
    };

    common: {
        white: CSSColor;
    };

    text: {
        primary: CSSColor;
        secondary: CSSColor;
        disabled: CSSColor;

        contrast: CSSColor;
        info: CSSColor;
        success: CSSColor;
        warning: CSSColor;
        danger: CSSColor;
    };

    background: {
        default: CSSColor;
        paper: CSSColor;

        primary: PaletteColor;
        secondary: PaletteColor;
        secondaryDark: PaletteColor;
        disabled: PaletteColor;
        contrast: AugmentedPaletteColor;
        infoDark: PaletteColor;
        info: PaletteColor;
        success: PaletteColor;
        successDark: AugmentedPaletteColor;
        warning: PaletteColor;
        warningDark: PaletteColor;
        danger: PaletteColor;
        dangerDark: PaletteColor;
    };

    border: {
        light: CSSColor;
        dark: CSSColor;
        info: CSSColor;
        success: CSSColor;
        warning: CSSColor;
        danger: CSSColor;
    };

    brand: {
        accent: CSSColor;
        accentFaded: CSSColor;
    };

    lighten: {
        5: CSSColor;
        10: CSSColor;
        80: CSSColor;
    };

    darken: {
        5: CSSColor;
        10: CSSColor;
        87: CSSColor;
    };

    focusAccent: CSSColor;
    errorAccent: CSSColor;
    successAccent: CSSColor;
}

declare module '@mui/material/styles' {
    interface Theme {
        palette: PaletteOverrides;
    }

    interface PaletteOptions extends Partial<PaletteOverrides> {}
}

/**
 * MUI Required Color tokens
 */
const MUI_REQUIRED_TOKENS = {
    /**
     * MUI required background colors
     */

    background: {
        // Page background
        default: BACKGROUND.SECONDARY,

        // Paper component background color
        paper: BACKGROUND.PRIMARY,
    },

    // Used primarily for interactive elements (i.e. Button, Radio, TextField, Tab, etc)
    // MUI automagically generates `primary.light`, `primary.dark`, and `primary.contrastText`
    // tokens
    primary: {
        main: BACKGROUND.INFO_DARK,
    },

    // This gets us a secondary button color that aligns with MUI, but the secondary color
    // doesn't have any other use at the moment. MUI automagically generates `secondary.light`,
    // `secondary.dark`, and `secondary.contrastText`
    // tokens
    secondary: {
        main: BACKGROUND.INFO,
    },

    /**
     * Alerts, warnings and error feedback
     * MUI automagically generates `{info,success,warning,error}.{light, dark, contrastText} based off of the provided `main` color
     */

    info: {
        main: BACKGROUND.INFO_DARK,
    },

    success: {
        main: BACKGROUND.SUCCESS_DARK,
    },

    warning: {
        main: BACKGROUND.WARNING_DARK,
    },

    // MUI uses this for error text and borders in it's system
    error: {
        main: TEXT.DANGER,
    },

    /**
     * Misc, interactive, includes color overlays, global hover state etc
     */

    action: {
        // Used in MenuItem, Table / DataTable Rows, NumberField, ToggleButton etc
        hover: alpha(BACKGROUND.SECONDARY_DARK, 0.4),

        selected: COLOR.GREY_LIGHTER,
        // Used for Buttons with "text" variant
        // hoverOpacity: 0.04,
    },

    // Used in Divider
    divider: BORDER.LIGHT,

    /**
     * Text colors
     */
    text: {
        // MUI and our design system use the same designations here
        // Referenced internally by MUI
        primary: TEXT.PRIMARY,
        secondary: TEXT.SECONDARY,

        // This is a tricky name, sometimes this color is used for elements that are enabled,
        // but inactive or unselected.
        disabled: TEXT.DISABLED,
    },

    /**
     * General Grey Scale
     * The greys are sorted by color value from lightest (50) to darkest, nearly black (900).
     * Where values are not defined MUI will add it's defaults.
     */
    grey: {
        '50': COLOR.GREY_LIGHTEST,
        '300': COLOR.GREY_LIGHTER,
        '500': COLOR.GREY,
        '700': COLOR.GREY_DARKER,
        '800': COLOR.GREY_DARKEST,
        '900': COLOR.BLACK,
    },
};

/**
 * This exposes our "Color Applications" which represent our theme
 * and the colors we are using in the app. The UI styles that make use
 * of the same color token will change together. If this is not desired,
 * we'll need a new token to decouple the usage.
 *
 * Generally, the tokens below will try to match the color applications design
 * tokens to keep confusion low between application usage and designers / developers
 * expectations when referencing Figma. However, there are deviations to accomodate
 * tokens which aid development.
 */
const MUI_THEME_EXTENSIONS = {
    /**
     * (Nearly) Unadulterated Color Applications Design Tokens
     */

    text: {
        // Text color extensions / deviations from MUI
        contrast: TEXT.CONTRAST,
        info: TEXT.INFO,
        warning: TEXT.WARNING,
        success: TEXT.SUCCESS,
        danger: TEXT.DANGER,
    },

    border: {
        light: BORDER.LIGHT,
        dark: BORDER.DARK,

        info: BORDER.INFO,

        success: BORDER.SUCCESS,

        warning: BORDER.WARNING,

        danger: BORDER.DANGER,
    },

    brand: {
        accent: BRAND.ACCENT,
        accentFaded: alpha(BRAND.ACCENT, 0.2),
    },

    // MUI Specific color overrides

    background: {
        /**
         * Custom Background colors
         *
         * We use primary and secondary here, but they
         * are different from MUI's primary and secondary
         */

        // The most common background in the app
        primary: {
            main: BACKGROUND.PRIMARY,
            contrastText: TEXT.PRIMARY,
        },

        // The page background color
        secondary: {
            main: BACKGROUND.SECONDARY,
            contrastText: TEXT.PRIMARY,
        },

        // Heading color block (Table, DataGrid, Accordion)
        secondaryDark: {
            main: BACKGROUND.SECONDARY_DARK,
            contrastText: TEXT.PRIMARY,
        },

        disabled: {
            main: BACKGROUND.DISABLED,
            contrastText: TEXT.PRIMARY,
        },

        // Currently only used to support the left nav
        contrast: {
            main: BACKGROUND.CONTRAST,
            light: COLOR.PRIMARY_DARKER,
            dark: alpha(COLOR.BLACK, 0.3),
            faded: alpha(BACKGROUND.CONTRAST, 0.25),
            contrastText: TEXT.CONTRAST,
        },

        info: {
            main: BACKGROUND.INFO,
            dark: darken(BACKGROUND.INFO, 0.05),
            contrastText: TEXT.PRIMARY,
        },

        infoDark: {
            main: BACKGROUND.INFO_DARK,
            light: COLOR.SECONDARY_LIGHTER,
            dark: darken(BACKGROUND.INFO_DARK, 0.15),
            contrastText: TEXT.CONTRAST,
        },

        success: {
            main: BACKGROUND.SUCCESS,
            dark: darken(BACKGROUND.SUCCESS, 0.05),
            contrastText: TEXT.PRIMARY,
        },

        successDark: {
            main: BACKGROUND.SUCCESS_DARK,
            dark: darken(BACKGROUND.SUCCESS_DARK, 0.15),
            // contrastText: TEXT.PRIMARY,
            // Design would like to use white text for this background but
            // it fails the contrast ratio for AA
            contrastText: TEXT.CONTRAST,
            faded: alpha(BACKGROUND.SUCCESS_DARK, 0.35),
        },

        warning: {
            main: BACKGROUND.WARNING,
            dark: darken(BACKGROUND.WARNING, 0.05),
            contrastText: TEXT.PRIMARY,
        },

        warningDark: {
            main: BACKGROUND.WARNING_DARK,
            dark: darken(BACKGROUND.WARNING_DARK, 0.15),
            contrastText: TEXT.PRIMARY,
        },

        danger: {
            main: BACKGROUND.DANGER,
            dark: darken(BACKGROUND.DANGER, 0.05),
            contrastText: TEXT.CONTRAST,
        },

        dangerDark: {
            main: BACKGROUND.DANGER_DARK,
            dark: darken(BACKGROUND.DANGER_DARK, 0.15),
            contrastText: TEXT.CONTRAST,
        },
    },

    /**
     * Developer only colors
     *
     * Colors found here represent one off colors or other colors
     * only helpful to developers. Work with designers to ensure
     * colors defined here make it into the design system eventually.
     */

    // Utility color masks: when used on top of other UI elements will darken or lighten
    lighten: {
        '5': 'rgba(255, 255, 255, 0.05)',
        '10': 'rgba(255, 255, 255, 0.1)',
        '80': 'rgba(255, 255, 255, 0.8)',
    },

    darken: {
        '5': 'rgba(0, 0, 0, 0.05)',
        '10': 'rgba(0, 0, 0, 0.1)',
        '87': 'rgba(0, 0, 0, 0.87)',
    },

    // The focus highlight for our primary interactive elements
    focusAccent: BACKGROUND.INFO,

    // The focus highlight (where focus highlight is used) and when in an error state
    errorAccent: BACKGROUND.DANGER,

    // The focus highlight (where focus highlight is used) and when in a success state
    successAccent: BACKGROUND.SUCCESS,
};

export function makeColorPaletteForMUI() {
    return {
        mode: 'light' as PaletteMode,

        // Contrast ratio of 4.5:1 for WCAG 2.1 Level AA compliance (small text)
        contrastThreshold: 4.5,

        // Used in adjustColor (which takes a main color and generates the dark, light and contrastText colors for a PaletteColor)
        // tonalOffset: 0.2,

        ...mergeDeep(MUI_REQUIRED_TOKENS, MUI_THEME_EXTENSIONS),
    };
}
