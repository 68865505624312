import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { EntryWayLayout, Text } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import ResetPasswordForm from './ResetPasswordForm';
import useResetPassword from './useResetPassword';

export default function ResetPasswordPage() {
    const { t } = useI18n();
    const resetPassword = useResetPassword();

    return (
        <>
            <Helmet>
                <title>{t('reset_password_page.meta_title')}</title>
                <meta name="description" content={t('reset_password_page.meta_description')} />
            </Helmet>

            <EntryWayLayout title={t('reset_password_page.page_title')} message={t('reset_password_page.form_prompt')}>
                <ResetPasswordForm
                    email={resetPassword.email}
                    token={resetPassword.token}
                    errorMessage={resetPassword.errorMessage}
                    onSubmit={resetPassword.run}
                    mb={1}
                />

                <Box display="flex" justifyContent="center">
                    <Text
                        variant="detail"
                        as="p"
                        color="secondary"
                        renderLink={({ text }) => (
                            <Link component={RouterLink} to={getPathByName('SIGN_IN')}>
                                {text}
                            </Link>
                        )}
                    >
                        {t('reset_password_page.remembered_password_message')}
                    </Text>
                </Box>
            </EntryWayLayout>
        </>
    );
}
