import { ReactElement } from 'react';

import { Link, Text } from 'app/components/primitives';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

interface MPUTileMpuNameProps {
    mpuName?: string;
    mpuID?: string;
}

/**
 * The threshold at which the MPU name is considered long.
 */
const NAME_LENGTH_THRESHOLD = 10;

export function MPUTileMpuName({ mpuName, mpuID }: MPUTileMpuNameProps): ReactElement {
    const { t } = useI18n();

    const resolvedMpuName = mpuName ?? t('unknown');
    const isLongName = resolvedMpuName.length > NAME_LENGTH_THRESHOLD;
    const maxLines = isLongName ? 2 : 1;
    const variant = isLongName ? 'h4' : 'h3';
    const href = mpuID ? getPathByName('MPU_DETAIL', { params: { mpuID } }) : undefined;

    const text = (
        <Text color="primary" maxLines={maxLines} variant={variant}>
            {resolvedMpuName}
        </Text>
    );

    if (href) {
        return <Link to={href}>{text}</Link>;
    }

    return text;
}
