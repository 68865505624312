import { ComponentProps, ReactElement, useId, useState } from 'react';

import { Button, EditableSection, SectionHeader } from 'app/components';

import { useI18n } from 'i18n';

import { User } from '../types';
import EditUserRolesForm from './EditUserRolesForm';

interface Props {
    /**
     * The users unique identifier
     */
    userID: User['id'];

    /**
     * The unique identifier for the account the users belongs too (i.e. Sunbelt Rentals)
     */
    accountID: User['accountID'];

    /**
     * The user's currently saved roles
     */
    roles: User['roles'];

    /**
     * Event handler to be called when the form state changes
     */
    onFormStateChange?: ComponentProps<typeof EditUserRolesForm>['onFormStateChange'];

    /**
     * Event handler to be called when the user initiates a form submission
     */
    onSubmit: ComponentProps<typeof EditUserRolesForm>['onSubmit'];
}

export default function RolesSection({ userID, accountID, roles, onFormStateChange, onSubmit }: Props): ReactElement {
    const { t } = useI18n();

    const editRolesFormID = useId();
    const [disableEditRolesCTA, setDisableEditRolesCTA] = useState(false);

    return (
        <EditableSection
            header={<SectionHeader title={t('admin_user_page.roles_section_title')} />}
            saveCTA={<Button type="submit" form={editRolesFormID} disabled={disableEditRolesCTA} />}
        >
            {({ isEditing }) => (
                <EditUserRolesForm
                    id={editRolesFormID}
                    hideSubmitCTA
                    readOnly={!isEditing}
                    userID={userID}
                    accountID={accountID}
                    roles={roles}
                    onFormStateChange={event => {
                        const { shouldDisableCTA } = event;

                        setDisableEditRolesCTA(shouldDisableCTA);
                        onFormStateChange?.(event);
                    }}
                    onSubmit={onSubmit}
                />
            )}
        </EditableSection>
    );
}
