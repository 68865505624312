import { ComponentProps, lazy, ReactNode, Suspense } from 'react';

import { ScheduleEvent } from 'app/components/compounds/Schedule';
import type Dialog from 'app/components/primitives/Dialog';
import type { LayerItemFacade } from 'app/core/layers/types';

import { DowntimePeriodFooterContent } from './DowntimePeriodFooterContent';
import { DowntimePeriodFormValues } from './DowntimePeriodForm';
import { ScheduleConflictEvent } from './useDowntimeScheduleConflicts';

/**
 * Using React.lazy to avoid a synchronous circular dependency.
 *
 * To clarify, this circular dependency is entirely intentional.
 * The `DowntimePeriodBody` contains the `DowntimeConflictWarning` component,
 * which can open another `DowntimePeriodDialog` to resolve a conflict.
 */
const DowntimePeriodBody = lazy(() => import('./DowntimePeriodBody'));

type DowntimeDialogSubmitHandlerPayload = {
    /**
     * The dialog being submitted.
     */
    dialog: LayerItemFacade;
    /**
     * The ID of the MPU associated with the downtime period.
     */
    mpuID: string;
    /**
     * Optional: The ID of the schedule being edited.
     */
    targetScheduleID?: string;
    /**
     * The form values submitted in the dialog.
     */
    values: DowntimePeriodFormValues;
};

export type DowntimeDialogSubmitHandler = (payload: DowntimeDialogSubmitHandlerPayload) => Promise<void>;

/**
 * A function that can be used to create a dialog for adding or editing a downtime period.
 */
export interface DowntimePeriodDialogParams {
    /**
     * A controller for the dialog being created.
     */
    layerItem: LayerItemFacade;
    /**
     * The ID of the MPU associated with the downtime period.
     */
    mpuID: string;
    /**
     * A callback invoked when a downtime period is to be removed.
     */
    onRemove?: () => void;
    /**
     * A callback invoked to handle the dialog's form submission.
     */
    onSubmit: DowntimeDialogSubmitHandler;
    /**
     * Optional: The color of the downtime period preview displayed in the schedule.
     */
    previewColor?: ScheduleEvent['color'];
    /**
     * Optional: The variant of the downtime period preview displayed in the schedule.
     */
    previewVariant?: ScheduleEvent['variant'];
    /**
     * The text to display on the save button.
     */
    saveText: string;
    /**
     * Optional: The ID of the schedule being edited.
     */
    targetScheduleID?: string;
    /**
     * The title of the dialog.
     */
    title: ReactNode;
}

/**
 * Generates dialog props for downtime period dialogs.
 *
 * @remarks
 *
 * Dialogs created via the `useLayer` hook are stateless. This class is used
 * maintain the state of a downtime period dialog it can be re-rendered with new props.
 *
 * To clarify, while the overall structure of the class is similar to a React class component,
 * it is not a React component.
 */
export class DowntimePeriodDialog {
    private formRef: {
        current: HTMLFormElement | null;
    } = {
        current: null,
    };

    private isSubmitting = false;

    private latestScheduleConflictEvent: ScheduleConflictEvent | undefined;

    /**
     * Determines whether the user should be able to proceed with the procedure.
     *
     * @see {@link ScheduleConflictEvent}
     */
    private get shouldProceed() {
        return this.latestScheduleConflictEvent?.value.shouldProceed ?? false;
    }

    /**
     * An event handler invoked when the dialog's form values are to be saved.
     */
    private handleSave = () => {
        // The `submitForm` callback should not be referenced directly,
        // because `makeDowntimePeriodDialog` is only called once upon dialog creation.
        this.formRef.current?.requestSubmit();
    };

    /**
     * An event handler invoked when the dialog is cancelled.
     */
    private handleCancel = () => {
        if (this.isSubmitting) return;

        this.params.layerItem.close();
    };

    /**
     * An event handler invoked when the schedule is to be removed.
     */
    private handleRemove = () => {
        this.params.onRemove?.();
    };

    /**
     * An async event handler invoked when the dialog's form is submitted.
     */
    private handleFormSubmit = async (event: { value: DowntimePeriodFormValues }) => {
        if (this.isSubmitting) return;

        this.isSubmitting = true;
        this.updateProps();

        await this.params.onSubmit({
            values: event.value,
            dialog: this.params.layerItem,
            mpuID: this.params.mpuID,
            targetScheduleID: this.params.targetScheduleID,
        });

        this.isSubmitting = false;
        this.updateProps();
    };

    /**
     * An event handler invoked when the schedule conflict state is updated
     */
    private handleScheduleConflict = (event: ScheduleConflictEvent) => {
        this.latestScheduleConflictEvent = event;
        this.updateProps();
    };

    constructor(private params: DowntimePeriodDialogParams) {}

    /**
     * Updates the layer item with updated dialog props
     *
     * Calling this method will update the dialog component's props,
     * which will cause the dialog to re-render.
     */
    private updateProps() {
        this.params.layerItem.update(this.renderProps());
    }

    /**
     * Renders props for use with the `Dialog` component.
     */
    public renderProps(): ComponentProps<typeof Dialog> {
        const {
            formRef,
            handleCancel,
            handleFormSubmit,
            handleRemove,
            handleScheduleConflict,
            handleSave,
            isSubmitting,
            shouldProceed,
        } = this;
        const { mpuID, previewColor, previewVariant, saveText, targetScheduleID, title } = this.params;
        const saveDisabled = isSubmitting || !shouldProceed;

        return {
            title,
            children: (
                <Suspense fallback={null}>
                    <DowntimePeriodBody
                        formRef={formRef}
                        mpuID={mpuID}
                        onScheduleConflict={handleScheduleConflict}
                        onSubmit={handleFormSubmit}
                        previewColor={previewColor}
                        previewVariant={previewVariant}
                        targetScheduleID={targetScheduleID}
                    />
                </Suspense>
            ),
            footerContent: (
                <DowntimePeriodFooterContent
                    enableRemove={!!targetScheduleID}
                    onCancel={handleCancel}
                    onRemove={handleRemove}
                    onSave={handleSave}
                    saveDisabled={saveDisabled}
                    saveText={saveText}
                />
            ),
        };
    }
}
