import { ReactElement, ReactNode } from 'react';

import Stack from '@mui/material/Stack';

import { Text } from 'app/components';

interface Props {
    /**
     * The tabs label
     */
    label: ReactNode;

    /**
     * The associated count for the content associated with the tab
     */
    count?: number;
}

/**
 * Responsible for rendering a label with an optional count
 */
export default function LabelWithCount({ label, count }: Props): ReactElement {
    const renderedCount =
        typeof count === 'number' ? (
            <>
                &nbsp;<Text>({count})</Text>
            </>
        ) : (
            <></>
        );

    return (
        <Stack direction="row">
            {label}
            {renderedCount}
        </Stack>
    );
}
