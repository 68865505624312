import {
    FocusEventHandler,
    KeyboardEventHandler,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { DateTime } from 'luxon';

import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers';

import {
    combineDateAndTime,
    dateTimeToPossibleISO8601Date,
    DEFAULT_TIME_ZONE,
    INVALID_DATE_TIME_VALUE,
    PossibleISO8601,
} from 'app/core/date-time';
import { IANATimeZone, ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import { DateTimeRangeError } from '../BusinessDateTimeRangePicker/constants';
import formatErrorMessage from '../BusinessDateTimeRangePicker/formatErrorMessage';
import { DateTimeRangeValidationError } from '../BusinessDateTimeRangePicker/types';
import { DateRangeChangeEvent } from '../DateRangeCalendar/RangePickerDay';

export interface DateTimeRangePickerValues {
    endDate: PossibleISO8601.Date | null;
    /** This is optional to be able to support date-only components. */
    endTime?: PossibleISO8601.Time | null;
    endTimeZone?: IANATimeZone;
    startDate: PossibleISO8601.Date | null;
    /** This is optional to be able to support date-only components. */
    startTime?: PossibleISO8601.Time | null;
    startTimeZone?: IANATimeZone;
}

export interface DateTimeRangePickerEventValue {
    candidate: ISO8601 | null;
    endDate: PossibleISO8601.Date;
    endTime: PossibleISO8601.Time;
    endTimeZone: IANATimeZone;
    startDate: PossibleISO8601.Date;
    startTime: PossibleISO8601.Time;
    startTimeZone: IANATimeZone;
}

export interface DateTimeRangePickerFieldChangeEvent {
    value: {
        date: PossibleISO8601.Date;
        time: PossibleISO8601.Time;
        timeZone: IANATimeZone;
    };
}

export interface DateTimeRangePickerEvent {
    name?: string;
    value: DateTimeRangePickerEventValue;
}

interface DateTimeRangePickerProps {
    /**
     * Interacted date but not selected
     */
    candidate: ISO8601 | null;
    /**
     * The end of the date range
     */
    endDate: PossibleISO8601;
    /**
     * A reference to the end date input
     */
    endDateInputRef?: MutableRefObject<HTMLElement | null>;
    /**
     * The end of the date range
     */
    endTime?: PossibleISO8601;
    /**
     * A reference to the end time input
     */
    endTimeInputRef?: MutableRefObject<HTMLElement | null>;
    /**
     * The timezone for the starting DateTime
     */
    endTimeZone: IANATimeZone | undefined;
    /**
     * The minimum allowable date to select
     */
    maxDateTime?: ISO8601 | null;
    /**
     * The minimum allowable date to select
     */
    minDateTime?: ISO8601 | null;
    /**
     * The name for the resultant date time range value
     */
    name?: string;
    /**
     * Event handler to be called when any of the hook's state change.
     */
    onChange?: (event: DateTimeRangePickerEvent) => void;
    /**
     * Called when there is a validation error on one of the DateFields
     */
    onError?: () => void;
    /**
     * Determines whether focus is skipped on the time inputs.
     *
     * 1. After the start date is selected:
     *     1. The end date input will be focused after the start date is selected.
     * 2. After the end date is selected:
     *     1. Focus on the end date input will be blurred
     *     2. The popover will be closed
     */
    skipTimeFocus?: boolean;
    /**
     * The start of the date range
     */
    startDate: PossibleISO8601;
    /**
     * A reference to the start date input
     */
    startDateInputRef?: MutableRefObject<HTMLElement | null>;
    /**
     * The start of the date range
     */
    startTime?: PossibleISO8601;
    /**
     * A reference to the start time input
     */
    startTimeInputRef?: MutableRefObject<HTMLElement | null>;
    /**
     * The timezone for the starting DateTime
     */
    startTimeZone: IANATimeZone | undefined;
}

/**
 * @remarks
 *
 * _Derek's note:_
 *
 * > This defines a reference date which does not change such that rerenders are not triggered
 * > and when the month is being navigated within the DateRangeCalendar the month which the user
 * > is viewing remains without rubberbanding back to the current dates month
 */
const today = DateTime.now().toISO();

function hasStartAfterEndError({
    startDate,
    endDate,
    startTime,
    endTime,
    startTimeZone,
    endTimeZone,
}: DateTimeRangePickerValues): boolean {
    const start = combineDateAndTime(startDate, startTime, startTimeZone);
    const end = combineDateAndTime(endDate, endTime, endTimeZone);

    return start.isValid && end.isValid && start.toMillis() >= end.toMillis();
}

enum SelectionTarget {
    Start = 'start',
    End = 'end',
}

/**
 * A hook that provides the behavior for date-time range picker components.
 */
export function useDateTimeRangePicker(props: DateTimeRangePickerProps) {
    const {
        candidate,
        endDateInputRef,
        endTimeInputRef,
        endTimeZone: initEndTimeZone,
        maxDateTime,
        minDateTime,
        name,
        onChange,
        onError,
        skipTimeFocus,
        startDateInputRef,
        startTimeInputRef,
        startTimeZone: initStartTimeZone,
    } = props;

    // External types are looser for a better DX, while the internal props are stricter,
    // for more coverage.
    const endDate = props.endDate as PossibleISO8601.Date;
    const endTime = props.endTime as PossibleISO8601.Time | undefined;
    const startDate = props.startDate as PossibleISO8601.Date;
    const startTime = props.startTime as PossibleISO8601.Time | undefined;

    const { t, format } = useI18n();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [selectionTarget, setSelectionTarget] = useState<`${SelectionTarget}`>(SelectionTarget.Start);

    const isSelectingStart = selectionTarget === SelectionTarget.Start;
    const isSelectingEnd = selectionTarget === SelectionTarget.End;

    const endTimeZone = initEndTimeZone ?? DEFAULT_TIME_ZONE;
    const startTimeZone = initStartTimeZone ?? DEFAULT_TIME_ZONE;

    const startTimestamp = combineDateAndTime(startDate, startTime, startTimeZone).toISO();
    const endTimestamp = combineDateAndTime(endDate, endTime, endTimeZone).toISO();

    const minStartDate = minDateTime ? DateTime.fromISO(minDateTime).toISODate()! : undefined;
    const maxStartDate = endTimestamp ?? undefined;
    const minEndDate = startTimestamp ?? undefined;
    const maxEndDate = maxDateTime ? DateTime.fromISO(maxDateTime).toISODate()! : undefined;

    const [rangeError, setRangeError] = useState<DateTimeRangeValidationError>(null);
    const [startError, setStartError] = useState<DateValidationError | TimeValidationError>(null);
    const [endError, setEndError] = useState<DateValidationError | TimeValidationError>(null);
    const errorMessage = formatErrorMessage({
        t,
        startError,
        rangeError,
        endError,
        minStartDate: format.date(minStartDate),
        minEndDate: format.date(minEndDate),
    });

    const handleStateChange = useCallback(
        function (value: DateTimeRangePickerEventValue) {
            const startTimeHasChanged = value.startTime !== startTime;

            if (!endDate && startTimeHasChanged) {
                endDateInputRef?.current?.focus();
            }

            onChange?.({ name, value });
        },
        [endDate, endDateInputRef, name, onChange, startTime],
    );

    const validateRange = useCallback(
        (dateTimeSpan: DateTimeRangePickerValues) => {
            if (hasStartAfterEndError(dateTimeSpan)) {
                onError?.();
                setRangeError(DateTimeRangeError.StartAfterEnd);
            } else {
                setRangeError(null);
            }
        },
        [onError],
    );
    /**
     * Run same day validation on mount only,
     * we may want to run this when the date range changes externally
     * but that will potentially cause issues with the onChange validations below
     * as this is a controlled component
     */
    useEffect(() => {
        validateRange({
            endDate,
            endTime,
            startDate,
            startTime,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDateRangeChange = useCallback(
        (event: DateRangeChangeEvent) => {
            function rangeDateTimeToDateValue(
                dateTime: DateTime | null | undefined,
                timeZone: IANATimeZone,
            ): PossibleISO8601.Date {
                if (dateTime && !dateTime.isValid) {
                    return INVALID_DATE_TIME_VALUE;
                }

                return dateTimeToPossibleISO8601Date(dateTime?.setZone(timeZone));
            }

            const eventStart = rangeDateTimeToDateValue(event.value.start, startTimeZone);
            const eventEnd = rangeDateTimeToDateValue(event.value.end, endTimeZone);

            const startDateChanged = startDate !== eventStart;
            const endDateChanged = endDate !== eventEnd;
            const dateChanged = startDateChanged || endDateChanged;

            const range: DateTimeRangePickerEventValue = {
                startDate: eventStart,
                endDate: eventEnd,
                startTime: startTime ?? null,
                endTime: endTime ?? null,
                candidate: event.value.candidate?.toISO() ?? null,
                startTimeZone,
                endTimeZone,
            };

            validateRange(range);
            handleStateChange(range);

            if (dateChanged && !skipTimeFocus) {
                setIsOpen(false);
            }

            if (startDateChanged) {
                if (!startTime && !skipTimeFocus) {
                    startTimeInputRef?.current?.focus();
                } else if (startTime || (skipTimeFocus && eventStart)) {
                    endDateInputRef?.current?.focus();
                }
            }

            if (endDateChanged && !endTime) {
                if (skipTimeFocus) {
                    endDateInputRef?.current?.blur();
                    setIsOpen(false);
                } else {
                    endTimeInputRef?.current?.focus();
                }
            }
        },
        [
            skipTimeFocus,
            endDate,
            endDateInputRef,
            endTime,
            endTimeInputRef,
            endTimeZone,
            startDate,
            startTime,
            startTimeInputRef,
            startTimeZone,
            handleStateChange,
            validateRange,
        ],
    );

    const selectedValue = (isSelectingEnd ? endTimestamp ?? startTimestamp : startTimestamp) ?? today;

    const selectionTargetMinDate = isSelectingEnd ? minEndDate : minStartDate;
    const selectionTargetMaxDate = isSelectingEnd ? maxEndDate : maxStartDate;

    const handleEndChange = useCallback(
        (event: DateTimeRangePickerFieldChangeEvent) => {
            const range: DateTimeRangePickerEventValue = {
                candidate,
                endDate: event.value.date,
                endTime: event.value.time,
                endTimeZone: event.value.timeZone,
                startDate,
                startTime: startTime ?? null,
                startTimeZone,
            };

            validateRange(range);
            handleStateChange(range);
        },
        [startDate, startTime, candidate, handleStateChange, startTimeZone, validateRange],
    );

    const handleStartChange = useCallback(
        (event: DateTimeRangePickerFieldChangeEvent) => {
            const range: DateTimeRangePickerEventValue = {
                candidate,
                endDate,
                endTime: endTime ?? null,
                endTimeZone,
                startDate: event.value.date,
                startTime: event.value.time,
                startTimeZone: event.value.timeZone,
            };

            validateRange(range);
            handleStateChange(range);
        },
        [candidate, endDate, endTime, endTimeZone, handleStateChange, validateRange],
    );

    const createErrorHandler = useCallback(
        (errorSetter: (errorCode: DateValidationError | TimeValidationError | null) => void) => {
            return (errorCode: DateValidationError | TimeValidationError | null) => {
                onError?.();

                // MUI validation cleared but setting the error
                // may clobber the same day error validation
                if (errorCode === null && hasStartAfterEndError({ startDate, endDate, startTime, endTime })) {
                    return;
                }

                errorSetter(errorCode);
            };
        },
        [startDate, endDate, startTime, endTime, onError],
    );

    const handleStartError = useMemo(() => createErrorHandler(setStartError), [createErrorHandler]);
    const handleEndError = useMemo(() => createErrorHandler(setEndError), [createErrorHandler]);

    const handleClickAway = useCallback(() => setIsOpen(false), []);

    const handlePopperAnchorKeyDown = useCallback<KeyboardEventHandler>(({ key }) => {
        if (key === 'Escape') {
            setIsOpen(false);
        }
    }, []);

    const handlePopperAnchorFocus = useCallback<FocusEventHandler>(
        ({ target }) => {
            if (target === startTimeInputRef?.current || target === endTimeInputRef?.current) {
                setIsOpen(false);
            }

            if (target === startDateInputRef?.current) {
                setSelectionTarget(SelectionTarget.Start);
                setIsOpen(true);
            }

            if (target === endDateInputRef?.current) {
                setSelectionTarget(SelectionTarget.End);
                setIsOpen(true);
            }
        },
        [endDateInputRef, startDateInputRef, startTimeInputRef, endTimeInputRef],
    );

    const openCalendar = useCallback(() => setIsOpen(true), []);
    const closeCalendar = useCallback(() => setIsOpen(false), []);
    const toggleCalendar = useCallback(() => setIsOpen(value => !value), []);

    const errors = useMemo(
        () => [endError, rangeError, startError].filter(Boolean),
        [endError, rangeError, startError],
    );

    const isEndFocused = isOpen && isSelectingEnd;
    const isStartFocused = isOpen && isSelectingStart;

    const handleEndToggleButtonClick = useCallback(() => {
        if (isEndFocused) {
            closeCalendar();
        } else {
            endDateInputRef?.current?.focus();
        }
    }, [closeCalendar, isEndFocused, endDateInputRef]);

    const onStartToggleButtonClick = useCallback(() => {
        if (isStartFocused) {
            closeCalendar();
        } else {
            startDateInputRef?.current?.focus();
        }
    }, [closeCalendar, isStartFocused, startDateInputRef]);

    return {
        candidate,
        closeCalendar,
        endError,
        endTimestamp,
        endTimeZone,
        errorMessage,
        errors,
        handleClickAway,
        handleDateRangeChange,
        handleEndChange,
        handleEndError,
        handleEndToggleButtonClick,
        handlePopperAnchorFocus,
        handlePopperAnchorKeyDown,
        handleStartChange,
        handleStartError,
        isEndFocused: isOpen && isSelectingEnd,
        isOpen,
        isSelectingEnd,
        isSelectingStart,
        isStartFocused: isOpen && isSelectingStart,
        maxEndDate,
        maxStartDate,
        minEndDate,
        minStartDate,
        onStartToggleButtonClick,
        openCalendar,
        rangeError,
        selectedValue,
        selectionTarget,
        selectionTargetMaxDate,
        selectionTargetMinDate,
        startError,
        startTimestamp,
        startTimeZone,
        toggleCalendar,
    } as const;
}
