import { PropertyPath } from 'lodash';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

import { ReactComponent as MoxionLogo } from 'app/assets/moxion-logo.svg';

interface Props {
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
    color1?: PropertyPath;
    color2?: PropertyPath;
}

const scale = (value, multiplier) => {
    const _value = parseFloat(value);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, unit = 'px'] = /(rem|px|vh|vw|%)$/.exec(value) ?? [];

    return `${multiplier * _value}${unit}`;
};

export default function MoxLogo({ color1, color2, type = 'h1' }: Props): JSX.Element {
    const theme = useTheme();

    return (
        <Box
            display="inline-flex"
            alignItems="center" // @ts-ignore
            sx={{
                '--color-1': get(theme.palette, color1 ?? ''),
                '--color-2': get(theme.palette, color2 ?? ''),
            }}
        >
            <MoxionLogo height={scale(theme.typography[type].fontSize, 0.8)} />
        </Box>
    );
}
