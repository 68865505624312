import { useGetServiceAccountsForSettingsQuery } from 'generated/graphql';

export default function useAccountServiceAccounts({ accountID }) {
    const { loading, error, data, refetch } = useGetServiceAccountsForSettingsQuery({ variables: { accountID } });
    return {
        isLoading: loading,
        error,
        refetch,
        serviceAccounts: data?.listServiceAccounts,
    };
}
