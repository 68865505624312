import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';

import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

interface APIKeyCreatedModalProps {
    token: string;
    onClose: () => void;
    isOpen: boolean;
}

export function APIKeyCreatedModal({ token, onClose, isOpen }: APIKeyCreatedModalProps) {
    const { t } = useI18n();
    const close = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog id={''} open={isOpen} onClose={close} aria-labelledby="add-unit-dialog-title">
            <DialogTitle id="add-unit-dialog-title">{t('service_accounts.api_key_created_dialog.title')}</DialogTitle>

            <Divider />

            <DialogContent
                sx={{
                    width: {
                        md: 500,
                    },
                }}
            >
                <Text variant="body" as="p">
                    {t('service_accounts.api_key_created_dialog.message')}
                </Text>
                <hr />
                <Text variant="body" as="p">
                    {token}
                </Text>
            </DialogContent>

            <DialogActions sx={{ px: 5, pb: 4 }}>
                <Button onClick={event => close(event, 'cancel')}>{t('cta.close')}</Button>
            </DialogActions>
        </Dialog>
    );
}
