import { useCallback, useEffect, useState } from 'react';

import { PossibleDate } from './types';
import useI18n from './useI18n';

/**
 * Format the given time, and update it each interval (defaults to 1 second).
 */
export default function useRelativeTime(time: PossibleDate, updateInterval = 1000) {
    const { format } = useI18n();
    const [relativeTime, setRelativeTime] = useState(() => format.relativeTime(time));

    /**
     * Updates the relative time using the given time.
     * Note: this function is non-deterministic.
     */
    const updateRelativeTime = useCallback(() => {
        setRelativeTime(format.relativeTime(time));
    }, [format, time]);

    useEffect(() => {
        // Updates relative time whenever the given time changes.
        updateRelativeTime();

        // Updates relative time each second.
        const interval = setInterval(() => {
            updateRelativeTime();
        }, updateInterval);

        return () => clearInterval(interval);
    }, [updateRelativeTime, updateInterval]);

    return {
        relativeTime,
    };
}
