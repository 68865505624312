import { FocusEvent, ReactElement, ReactNode, useCallback, useId, useRef } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';

import {
    dateTimeToPossibleISO8601Date,
    DEFAULT_TIME_ZONE,
    hourToISOTime,
    IANATimeZone,
    resolveDateTime,
} from 'app/core/date-time';

import { useI18n } from 'i18n';

import FieldCaption from '../../FieldCaption';
import FieldLabel from '../../FieldLabel';
import BusinessDateTimeField from '../BusinessDateTimeField';
import { BusinessDateTimeFieldChangeEvent } from '../BusinessDateTimeField/BusinessDateTimeField';
import {
    DateTimeRangePickerEvent,
    DateTimeRangePickerFieldChangeEvent,
    useDateTimeRangePicker,
} from '../date-time-inputs/useDateTimeRangePicker';
import { DateRangePopover } from '../DateRangePopover/DateRangePopover';
import Input from '../Input';
import { DateInput, DateRangeChangeEvent, HourSelection } from './types';

interface Props {
    /**
     * The name for the resultant date time range value
     */
    name: string;

    /**
     * The main label for the controls
     */
    fieldName?: ReactNode;

    /**
     * The label for the start date controls
     */
    startFieldName?: string;

    /**
     * The label for the end date controls
     */
    endFieldName?: string;

    /**
     * The start of the date range
     */
    start: DateInput;

    /**
     * The selected hour of the start day
     */
    startHour: HourSelection;

    /**
     * The timezone for the starting DateTime
     */
    startTimeZone?: IANATimeZone;

    /**
     * The end of the date range
     */
    end: DateInput;

    /**
     * The selected hour of the end day
     */
    endHour: HourSelection;

    /**
     * The timezone for the starting DateTime
     */
    endTimeZone?: IANATimeZone;

    /**
     * Interacted date but not selected
     */
    candidate: DateInput;

    /**
     * Whether or not the user can interact with the control
     */
    disabled?: boolean;

    /**
     * Whether or not the control is readOnly / static (cannot be edited but still focusable)
     */
    readOnly?: boolean;

    /**
     * Whether or not the fieldset is required
     */
    required?: boolean;

    /**
     * Whether the controls should fill the parent
     */
    fullWidth?: boolean;

    /**
     * The minimum allowable date to select
     */
    minDate?: DateTime | null;

    /**
     * Whether a date in the past can be selected
     */
    disablePast?: boolean;

    /**
     * Whether there is a validation error for the start date
     */
    hasStartDateError?: boolean;
    /**
     * Whether there is a validation error for the start time
     */
    hasStartTimeError?: boolean;
    /**
     * Whether there is a validation error for the end date
     */
    hasEndDateError?: boolean;
    /**
     * Whether there is a validation error for the end time
     */
    hasEndTimeError?: boolean;

    /**
     * Validation text to show, since most validation is done internal
     * to this component a la MUI DateField this is supplemental and
     * will only be shown if there is not an internal validation error
     */
    caption?: ReactNode;

    /**
     * Called when there is a validation error on one of the DateFields
     */
    onError?: () => void;

    /**
     * Event handler to be called when either the date or the hour changes
     */
    onFocus?: (event: FocusEvent) => void;
    /**
     * Event handler to be called when either the date or the hour changes
     */
    onBlur?: (event: FocusEvent) => void;

    /**
     * Event handler to be called when any of the date time value changes making up the range
     */
    onChange: (event: DateRangeChangeEvent) => void;
}

function useDateTimeFieldChangeHandler(handler: (event: DateTimeRangePickerFieldChangeEvent) => void) {
    return useCallback(
        ({ value: { date, hour, timeZone } }: BusinessDateTimeFieldChangeEvent) => {
            handler({
                value: {
                    date: dateTimeToPossibleISO8601Date(date),
                    time: hour ? hourToISOTime(hour, timeZone) : null,
                    timeZone: timeZone ?? undefined,
                },
            });
        },
        [handler],
    );
}

/**
 * Input component for allowing users to select a business day and hour for pickup or dropoff of units.
 */
export default function BusinessDateTimeRangePicker(props: Props): ReactElement {
    const {
        name,
        fieldName,
        startFieldName,
        endFieldName,
        caption,
        start: startDate,
        startHour,
        startTimeZone = DEFAULT_TIME_ZONE,
        end: endDate,
        endHour,
        endTimeZone = DEFAULT_TIME_ZONE,
        candidate,

        disabled,
        readOnly,
        required,
        minDate,
        disablePast = true,
        fullWidth,

        hasStartDateError,
        hasStartTimeError,
        hasEndDateError,
        hasEndTimeError,

        onError,
        onBlur,
        onFocus,
        onChange,
    } = props;
    const { format } = useI18n();

    const resolvedStartDate = startDate == null ? null : resolveDateTime(startDate);
    const resolvedEndDate = endDate == null ? null : resolveDateTime(endDate);
    const resolvedMinDate = minDate == null ? null : resolveDateTime(minDate);
    const resolvedCandidate = candidate == null ? null : resolveDateTime(candidate);

    const startDateValue = dateTimeToPossibleISO8601Date(resolvedStartDate);
    const endDateValue = dateTimeToPossibleISO8601Date(resolvedEndDate);
    const endTime = endHour != null ? DateTime.fromObject({ hour: endHour }).toISOTime() : null;
    const startTime = startHour != null ? DateTime.fromObject({ hour: startHour }).toISOTime() : null;

    const startDateTime = resolvedStartDate?.set({ hour: startHour ?? undefined }).setZone(startTimeZone);
    const endDateTime = resolvedEndDate?.set({ hour: endHour ?? undefined }).setZone(endTimeZone);

    /**
     * TODO(Morris): Remove conversion to `DateTime` and update consumers
     * to use plain ISO 8601 strings.
     */
    const handleDateTimeRangePickerChange = useCallback(
        ({ value }: DateTimeRangePickerEvent) => {
            const candidate = value.candidate ? DateTime.fromISO(value.candidate) : null;
            const end = value.endDate ? DateTime.fromISO(value.endDate) : null;
            const endHour = value.endTime ? DateTime.fromISO(value.endTime).hour : null;
            const endTimeZone = value.endTimeZone;
            const start = value.startDate ? DateTime.fromISO(value.startDate) : null;
            const startHour = value.startTime ? DateTime.fromISO(value.startTime).hour : null;
            const startTimeZone = value.startTimeZone;

            onChange({
                name,
                value: {
                    candidate,
                    end,
                    endHour,
                    endTimeZone,
                    start,
                    startHour,
                    startTimeZone,
                },
            });
        },
        [onChange, name],
    );

    const startDateInputRef = useRef<HTMLInputElement | null>(null);
    const startTimeInputRef = useRef<HTMLInputElement | null>(null);
    const endDateInputRef = useRef<HTMLInputElement | null>(null);
    const endTimeInputRef = useRef<HTMLInputElement | null>(null);

    const {
        endError,
        errorMessage,
        errors,
        handleClickAway,
        handleDateRangeChange,
        handleEndChange,
        handleEndError,
        handlePopperAnchorFocus,
        handlePopperAnchorKeyDown,
        handleStartChange,
        handleStartError,
        isEndFocused,
        isOpen,
        isStartFocused,
        maxEndDate,
        maxStartDate,
        minEndDate,
        minStartDate,
        rangeError,
        selectedValue,
        selectionTarget,
        selectionTargetMinDate: activeMinDate,
        startError,
    } = useDateTimeRangePicker({
        candidate: resolvedCandidate?.toISO() ?? null,
        endDate: endDateValue,
        endDateInputRef,
        endTime,
        endTimeInputRef,
        endTimeZone,
        minDateTime: resolvedMinDate?.toISO() ?? null,
        name,
        onChange: handleDateTimeRangePickerChange,
        onError,
        startDate: startDateValue,
        startDateInputRef,
        startTime,
        startTimeInputRef,
        startTimeZone,
    });

    const handleDateTimeStartChange = useDateTimeFieldChangeHandler(handleStartChange);
    const handleDateTimeEndChange = useDateTimeFieldChangeHandler(handleEndChange);

    const hasError = hasStartDateError || hasStartTimeError || hasEndDateError || hasEndTimeError || errors.length > 0;

    const popperAnchorRef = useRef<HTMLElement | null>(null);

    const startDateID = useId();
    const captionID = useId();

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Stack
                aria-describedby={captionID}
                component="fieldset"
                onBlur={onBlur}
                onFocus={onFocus}
                sx={{
                    border: 'none',
                    p: 0,
                    mx: 0,
                }}
            >
                <FieldLabel
                    as="legend"
                    fieldName={fieldName}
                    required={required}
                    readOnly={readOnly}
                    disabled={disabled}
                    error={hasError}
                />

                {readOnly ? (
                    <Input
                        readOnly
                        fullWidth={fullWidth}
                        value={format.dateRangeSentence({
                            start: startDateTime,
                            end: endDateTime,
                        })}
                    />
                ) : (
                    <Box
                        ref={popperAnchorRef}
                        display="flex"
                        sx={{ gap: 4, flexWrap: 'wrap' }}
                        onKeyDown={handlePopperAnchorKeyDown}
                        onFocus={handlePopperAnchorFocus}
                    >
                        <BusinessDateTimeField
                            fullWidth={fullWidth}
                            disabled={disabled}
                            focused={isStartFocused}
                            fieldName={startFieldName}
                            id={startDateID}
                            disablePast={disablePast}
                            minDate={minStartDate ? DateTime.fromISO(minStartDate) : undefined}
                            maxDate={maxStartDate ? DateTime.fromISO(maxStartDate) : undefined}
                            dateInputRef={startDateInputRef}
                            timeInputRef={startTimeInputRef}
                            date={resolvedStartDate}
                            hour={startHour}
                            timeZone={startTimeZone}
                            dateError={hasStartDateError || !!startError}
                            timeError={hasStartTimeError || !!rangeError}
                            onError={handleStartError}
                            onChange={handleDateTimeStartChange}
                        />

                        <BusinessDateTimeField
                            fullWidth={fullWidth}
                            disabled={disabled}
                            fieldName={endFieldName}
                            focused={isEndFocused}
                            disablePast={disablePast}
                            minDate={minEndDate ? DateTime.fromISO(minEndDate) : undefined}
                            maxDate={maxEndDate ? DateTime.fromISO(maxEndDate) : undefined}
                            dateInputRef={endDateInputRef}
                            timeInputRef={endTimeInputRef}
                            date={resolvedEndDate}
                            hour={endHour}
                            timeZone={endTimeZone}
                            dateError={hasEndDateError || !!endError}
                            timeError={hasEndTimeError || !!rangeError}
                            onError={handleEndError}
                            onChange={handleDateTimeEndChange}
                        />
                    </Box>
                )}

                <FieldCaption id={captionID} caption={errorMessage || caption} error={hasError} />

                <DateRangePopover
                    anchor={popperAnchorRef.current}
                    candidate={resolvedCandidate?.toISO() ?? null}
                    disablePast={disablePast}
                    end={resolvedEndDate?.toISO() ?? null}
                    errorMessage={errorMessage}
                    hasEndError={!!endError}
                    hasStartError={!!startError}
                    minDate={activeMinDate}
                    onChange={handleDateRangeChange}
                    open={isOpen}
                    startTimeZone={startTimeZone}
                    endTimeZone={endTimeZone}
                    selectionTarget={selectionTarget}
                    start={resolvedStartDate?.toISO() ?? null}
                    value={selectedValue}
                />
            </Stack>
        </ClickAwayListener>
    );
}
