import { ApolloClient } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import { Duration } from 'luxon';

import { IEventEmitter, ISession, SessionData } from 'app/core/types';

import { GetUserSessionDocument, GetUserSessionQuery, LogOutUserDocument } from 'generated/graphql';

import { EventEmitterCallback } from '../EventEmitter';

export const NULL_SESSION_DATA: Readonly<SessionData> = {
    id: 'null',
    email: '',
    name: '',
    accounts: [],
    primaryAccountID: '',
    primaryAccountName: '',
    primaryAccountRoles: [],
    isAuthenticated: false,
    isAdmin: false,
    userRoles: [],
};

export const nullSession: ISession = {
    syncInBackground: () => Promise.resolve(NULL_SESSION_DATA),
    sync: () => Promise.resolve(NULL_SESSION_DATA),
    getSession: () => NULL_SESSION_DATA,
    hasAccountRole: () => false,
    hasAccountRoleAccess: () => false,
    hasUserRole: () => false,
    hasAnyUserRoleOf: () => false,
    signOut: () => Promise.resolve(),
    on: (eventName: string, cb: EventEmitterCallback) => () => {},
};

/**
 * Pulled from: `/backend/src/account_role/account_role.entity.ts`
 * TODO(Morris): Create common package to reuse types with backend.
 */
export enum AccountRoleName {
    Owner = 'owner',
    Renter = 'renter',
}

/**
 * User Roles from the backend. Note these are currently collapsed with permissions in the backend
 * but will shortly be separated.
 *
 * Note: the naming scheme uses forward slashes for namespacing but we can't use those in our enum key. Instead,
 * we use an underscore "_" for "/" and CamelCase for underscores within given namespace
 *
 * TODO(will): pull these from the //backend module once the frontend is in the yarn modnorepo.
 */
export enum UserRoleName {
    MoxionAdmin = 'MoxionAdmin',
    MoxionFleetManager = 'MoxionFleetManager',
    MoxionFieldOperator = 'MoxionFieldOperator',

    // Account-scoped roles. They have 'account' scope i.e. they can only view reservations, units, users, etc. in/owned by their own account.
    AccountAdmin = 'AccountAdmin',
    AccountFleetManager = 'AccountFleetManager',
    AccountFieldOperator = 'AccountFieldOperator',

    ////////////
    // Legacy //
    ////////////

    Account_Manager = 'account/manager',
}

export const sessionEventNames = {
    SIGNED_OUT: 'signedOut',
    SIGNED_IN: 'signedIn',
} as const;

export type SessionEventName = (typeof sessionEventNames)[keyof typeof sessionEventNames];

function initializeDataLayer() {
    globalThis.dataLayer = globalThis.dataLayer || [];
}

/**
 * Responsible for the syncing session state with the backend and sharing that state with
 * the appropriate loaders and pages
 */
export default class Session implements ISession {
    /**
     * GQL client providing network access
     */
    private client: Pick<ApolloClient<unknown>, 'query' | 'mutate' | 'cache' | 'clearStore'>;

    /**
     * Provides session related event handling
     */
    private eventEmitter: IEventEmitter<SessionEventName>;

    /**
     * Represents inflight session queries
     */
    private sessionPromise: Promise<SessionData> | null;

    private signOutPromise: Promise<void> | null;

    /**
     * The current session data
     */
    private session: SessionData;

    /**
     * The interval polling for session changes
     */
    private intervalID: number | null;

    constructor({
        client,
        eventEmitter,
    }: {
        client /* TODO(will): types */;
        eventEmitter: IEventEmitter<SessionEventName>;
    }) {
        this.client = client;
        this.eventEmitter = eventEmitter;
        this.sessionPromise = null;
        this.session = NULL_SESSION_DATA;

        if (!this.client) {
            throw new Error('Session: client not set');
        }

        if (!this.eventEmitter) {
            throw new Error('Session: eventEmitter not set');
        }
    }

    /**
     * Determines if any given operationName matches the operation used
     * to sync session
     */
    static isSessionOperationName(operationName: string): boolean {
        return operationName === 'GetUserSession';
    }

    /**
     * Determines if a given array of GQL errors contains an error
     * indicating the user is currently unauthenticated
     */
    static hasUnauthenticatedError(errors?: GraphQLErrors): boolean {
        return !!(errors ?? []).find((x: GraphQLError): boolean => {
            const statusCode = x?.extensions?.response?.statusCode;

            return /unauthenticated/i.test(x?.extensions?.code) || statusCode === 401 || statusCode === 403;
        });
    }

    private watch(): void {
        if (!!this.intervalID) {
            return;
        }

        this.intervalID = window.setInterval(this.sync.bind(this), Duration.fromObject({ minutes: 1 }).toMillis());
    }

    /**
     * Sync out of band, returning cached session data
     */
    public async syncInBackground(options?: { onSyncComplete?: (x: SessionData) => void }): Promise<SessionData> {
        const { onSyncComplete } = options ?? {};
        let result;

        if (this.session.id !== 'null') {
            result = Promise.resolve(this.session);
        }

        const syncPromise = this.sync().then(onSyncComplete);

        return result || syncPromise;
    }

    /**
     * Syncs the session state with the server
     */
    public async sync(): Promise<SessionData> {
        if (this.signOutPromise) {
            return Promise.resolve(NULL_SESSION_DATA);
        }

        this.sessionPromise =
            this.sessionPromise ??
            this.client
                .query<GetUserSessionQuery>({
                    query: GetUserSessionDocument,
                    fetchPolicy: 'network-only',
                })
                .then(result => {
                    this.sessionPromise = null;

                    const { currentUserSession } = result.data;

                    /**
                     * We support setting multiple accounts on the backend but practically
                     * the system currently only handles the first account / organization
                     */
                    const primaryAccount = currentUserSession.accounts.at(0);

                    // NOTE(will): we only emit a signedIn event if we were previously signed out
                    if (this.session.id === 'null') {
                        // NOTE(will): we're treating setting the session as equivalent to sign in. This may or
                        // may not be exactly what we want, but it matches our 'signedOut' behavior.
                        this.eventEmitter.emit('signedIn', currentUserSession);
                    }

                    this.session = {
                        ...currentUserSession,
                        primaryAccountID: primaryAccount?.id || NULL_SESSION_DATA.primaryAccountID,
                        primaryAccountName: primaryAccount?.name || NULL_SESSION_DATA.primaryAccountName,
                        primaryAccountRoles:
                            (primaryAccount?.accountRoles ?? []).map(x => x.name) ||
                            NULL_SESSION_DATA.primaryAccountRoles,
                        isAuthenticated: true,
                        isAdmin: !!currentUserSession.admin,
                    };

                    this.watch();

                    // set account ID for Google Tag Manager
                    if (primaryAccount) {
                        initializeDataLayer();
                        globalThis.dataLayer.push({
                            userID: currentUserSession.id,
                            accountID: primaryAccount.id,
                            accountName: primaryAccount.name,
                        });
                    }

                    return this.session;
                })
                .catch(() => {
                    this.sessionPromise = null;

                    this.handleSignOut({ isUserInitiated: false });

                    return NULL_SESSION_DATA;
                });

        return this.sessionPromise;
    }

    /**
     * Gets the current view of the user's session
     */
    public getSession(): SessionData {
        return this.session;
    }

    /**
     * Determines whether the current user session has the given role.
     * When `accountID` isn't provided, the session's primary account ID is used.
     */
    public hasAccountRole(
        accountRoleName: AccountRoleName,
        accountID: string = this.session.primaryAccountID,
    ): boolean {
        return !!this.session.accounts.find(account => {
            return account.id === accountID && !!account.accountRoles?.find(({ name }) => name === accountRoleName);
        });
    }

    /**
     * Determines whether the current user session has at least the same level of access as the given role.
     * When `accountID` isn't provided, the session's primary account ID is used.
     */
    public hasAccountRoleAccess(accountRoleName: AccountRoleName, accountID: string = this.session.primaryAccountID) {
        if (this.session.isAdmin) {
            return true;
        }

        return this.hasAccountRole(accountRoleName, accountID);
    }

    public hasUserRole(userRoleName: UserRoleName): boolean {
        return !!this.session.userRoles.find(userRole => userRole === userRoleName);
    }

    public hasAnyUserRoleOf(userRoleNames: UserRoleName[]): boolean {
        const stringRoleNames = userRoleNames.map(name => `${name}`);
        return !!this.session.userRoles.some(userRole => stringRoleNames.includes(userRole));
    }

    private handleSignOut({ isUserInitiated }): void {
        const wasAuthenticated = this.session.isAuthenticated;

        this.session = NULL_SESSION_DATA;

        window.clearInterval(this.intervalID ?? undefined);
        this.intervalID = null;

        // remove account ID for Google Tag Manager
        initializeDataLayer();
        globalThis.dataLayer.push({ userID: null, accountID: null, accountName: null });

        if (wasAuthenticated) {
            this.eventEmitter.emit('signedOut', { isUserInitiated });
        }
    }

    /**
     * Signs out the current user destroying the session and resetting the
     * application data
     */
    public async signOut(): Promise<void> {
        await this.client.clearStore();

        this.signOutPromise = this.client
            .mutate({
                mutation: LogOutUserDocument,
            })
            .then(() => undefined)
            .finally(() => {
                this.signOutPromise = null;
            });

        this.handleSignOut({ isUserInitiated: true });

        return this.signOutPromise;
    }

    /**
     * Registers a callback to be called when an event occurs
     */
    public on(eventName: SessionEventName, callback: EventEmitterCallback): () => void {
        return this.eventEmitter.on(eventName, callback);
    }
}
