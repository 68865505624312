import { DateTime } from 'luxon';

import { IANATimeZone } from 'app/core/types';

import { PossibleISO8601 } from './types';

/**
 * Combines the given date and time strings into a single DateTime object.
 *
 * @param date - An ISO 8601 date string.
 * @param time - An ISO 8601 time string. Defaults to "00:00:00" if not provided.
 * @param timeZone - The time zone to use for the resulting DateTime object.
 */
export function combineDateAndTime(
    date: PossibleISO8601 | undefined,
    time: PossibleISO8601 | undefined,
    timeZone: IANATimeZone | undefined,
): DateTime {
    const normalizedTime = time ?? '00:00:00';

    return DateTime.fromISO(`${date}T${normalizedTime}`, { zone: timeZone });
}
