import { ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Box from '@mui/material/Box';

import Dialog from 'app/components/primitives/Dialog';
import Layer from 'app/components/primitives/layout/Layer';

import LayerItem from '../LayerItem';
import { IOpenableComponentConnector } from '../types';

const AnimatedLayer = motion(Layer);

/**
 * Responsible for fielding requests submitted via a connector and displaying dialogs on the screen
 */
export default function DialogLayer({ layerConnector }): ReactElement {
    const [items, setItems] = useState<IOpenableComponentConnector[]>([]);

    useEffect(() => {
        setItems(layerConnector.getAllItems());

        return layerConnector.on('update', ({ data }) => setItems(data.items));
    }, [layerConnector]);

    return (
        <Layer fill="screen" sx={theme => ({ pointerEvents: 'none', zIndex: theme.zIndex.modal })}>
            <AnimatePresence initial={false}>
                {items.length > 0 && (
                    // Dialog Scrim / Backdrop
                    <AnimatedLayer
                        initial={{ opacity: 0, y: 50, scale: 0.3, borderRadius: 999 }}
                        animate={{ opacity: 1, y: 0, scale: 1, borderRadius: 0 }}
                        exit={{ opacity: 0, y: 50, scale: 0.3, borderRadius: 999 }}
                        sx={theme => ({
                            pointerEvents: 'auto',
                            zIndex: theme.zIndex.modal + items.length - 1,
                            backgroundColor: theme.palette.darken[10],
                            backdropFilter: 'blur(8px)',
                        })}
                        fill="screen"
                    />
                )}

                {items.map((x, i) => (
                    <AnimatedLayer
                        layout
                        key={x.id}
                        initial={{ opacity: 0, y: 50, scale: 0.3 }}
                        animate={{
                            opacity: 1,
                            x: (items.length - i - 1) * 16,
                            y: (items.length - i - 1) * 16,
                            scale: 1 / Math.sqrt(0.05 * (items.length - i - 1) + 1),
                        }}
                        exit={{ opacity: 0, y: 50, scale: 0.75 }}
                        sx={theme => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            overflow: 'auto',
                            zIndex: theme.zIndex.modal + i,
                        })}
                        fill="screen"
                    >
                        <Box
                            sx={{
                                pointerEvents: 'auto',
                                maxHeight: '100%',
                                width: { xs: '100%', md: 'auto' },
                            }}
                        >
                            <LayerItem key={x.id} enableFocusTrap itemConnector={x} Component={x.Component ?? Dialog} />
                        </Box>
                    </AnimatedLayer>
                ))}
            </AnimatePresence>
        </Layer>
    );
}
