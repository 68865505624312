import { TranslateFunction } from 'i18n';

import getPathByName from '../Navigation/getPathByName';
import { AlertAction, MPU, Reservation } from './types';

export default function makeAlertAction({
    type,
    reservation,
    mpu,
    t,
}: {
    type: string;
    reservation: Pick<Reservation, 'id'>;
    mpu: Pick<MPU, 'id'>;
    t: TranslateFunction;
}): AlertAction | null {
    switch (type) {
        case 'RESERVATION_ITEM_ALLOCATION':
            return {
                label: t('alerts.action.view_schedule'),
                to: getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', {
                    params: { reservationID: reservation?.id ?? '' },
                    hash: 'schedule',
                }),
            };
        // Note - RESERVATION_ALLOCATION is a (temporary?) grouping of RESERVATION_ITEM_ALLOCATIONs
        // . and they should not both be returned to the frontend
        case 'RESERVATION_ALLOCATION':
            return {
                label: t('alerts.action.view_schedule'),
                to: getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', {
                    params: { reservationID: reservation?.id ?? '' },
                    hash: 'schedule',
                }),
            };

        case 'RESERVATION_STATUS_MISMATCH':
            return {
                label: t('alerts.action.view_status'),
                to: getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', {
                    params: { reservationID: reservation?.id ?? '' },
                    hash: 'status-selector',
                }),
            };

        case 'MPU_LOW_SOC':
            return {
                label: t('alerts.action.view_schedule'),
                to: getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', {
                    params: { reservationID: reservation?.id ?? '' },
                    hash: 'schedule',
                }),
            };

        case 'MPU_CRITICAL_FAULT':
            return {
                label: t('alerts.action.view_mpu'),
                to: getPathByName('MPU_DETAIL', {
                    params: { mpuID: mpu?.id ?? '' },
                }),
            };

        case 'MPU_OFFLINE':
            return {
                label: t('alerts.action.view_mpu'),
                to: getPathByName('MPU_DETAIL', {
                    params: { mpuID: mpu?.id ?? '' },
                }),
            };

        default:
            return null;
    }
}
