import { Text } from 'app/components';

import { useI18n } from 'i18n';

import { CustomerReservationsPageReservation } from '../../useReservationsPageData';
import { ColumnLayout } from '../ColumnLayout';

interface ReservationDetailsRowProps
    extends Pick<CustomerReservationsPageReservation, 'id' | 'name' | 'start' | 'end' | 'unitCount'> {}

export function ReservationDetailsRow({ id, name, start, end, unitCount }: ReservationDetailsRowProps) {
    const { t, format } = useI18n();

    return (
        <ColumnLayout
            columnValues={[
                <Text variant="detail" sx={{ fontWeight: { xs: 'bold', md: 'normal' } }}>
                    {name}
                </Text>,
                <Text variant="detail">{t('reservation_table.unit_count', { count: unitCount })}</Text>,
                <Text variant="detail">{format.dateRangeDayAndTime({ start, end })}</Text>,
                <Text variant="detail">{t('reservation_table.moxion_transport')}</Text>,
            ]}
        />
    );
}
