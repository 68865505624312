import React from 'react';

import { Input } from 'app/components';

import { useI18n } from 'i18n';

interface SpecialInstructionsInputProps {
    value: string;
    onChange: (value: string) => void;
    name?: string;
}

const SpecialInstructionsInput: React.FC<SpecialInstructionsInputProps> = ({
    value,
    onChange,
    name,
    ...otherProps
}) => {
    const { t } = useI18n();

    return (
        <Input
            value={value}
            onChange={event => {
                onChange(event.target.value);
            }}
            multiline
            minRows={1}
            maxRows={2}
            fieldName={name || t('documents.components.special_instructions')}
            {...otherProps}
        />
    );
};

export default SpecialInstructionsInput;
