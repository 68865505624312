import { Children, useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

import { LoadingIndicator, Text, Toast } from 'app/components';

import {
    ListEventSubscriptionsQuery,
    NotificationChannel,
    useListEventSubscriptionsQuery,
    useUpsertEventSubscriptionMutation,
} from 'generated/graphql';

import { useI18n } from 'i18n';

type Subscription = ListEventSubscriptionsQuery['listEventSubscriptions'][0];

/**
 * Layout component to keep rows consistently spaced
 */
const Row = ({ children }) => {
    const _children = Children.toArray(children);

    return (
        <>
            <Grid item xs={8} md={4} display="flex" alignItems="center">
                {_children[0]}
            </Grid>

            <Grid item xs={2} md={1} display="flex" alignItems="center" justifyContent="center">
                {_children[1]}
            </Grid>

            <Grid item xs={2} md={1} display="flex" alignItems="center" justifyContent="center">
                {_children[2]}
            </Grid>

            <Grid item xs={0} md={6} sx={{ display: { xs: 'none', md: 'flex' } }} />
        </>
    );
};

/**
 * A users notification subscription (a single event) and controls to update the subscription preference
 * TODO: extract logic out of component and move one level up
 */
function EventSubscriptionRow({ userID, sub, onError }: { userID: string; sub: Subscription; onError: () => void }) {
    const { t } = useI18n();
    const [upsertEventSubscription] = useUpsertEventSubscriptionMutation();

    const subscribedChannels = sub.channels ?? [];
    const channelMap = {
        Email: subscribedChannels.includes(NotificationChannel.Email) ?? false,
        Sms: subscribedChannels.includes(NotificationChannel.Sms) ?? false,
    };

    async function handleChange({ target: { name, checked } }) {
        const updatedChannels = {
            ...channelMap,
            [name]: checked,
        };

        const channels = Object.keys(updatedChannels).reduce((acc: NotificationChannel[], cur: string) => {
            return updatedChannels[cur] ? acc.concat(NotificationChannel[cur]) : acc;
        }, []);

        try {
            await upsertEventSubscription({
                variables: {
                    input: {
                        id: sub.id,
                        eventName: sub.eventName,
                        channels,
                        userID,
                    },
                },
            });
        } catch (error) {
            onError();
        }
    }

    return (
        <Row>
            <Text as="p">{t(`event_subscriptions.${sub.eventName.toLowerCase()}`)}</Text>

            <Checkbox name="Sms" onChange={handleChange} checked={channelMap.Sms} />

            <Checkbox name="Email" onChange={handleChange} checked={channelMap.Email} />
        </Row>
    );
}

/**
 * Presents all user notification related settings
 */
export function EventSubscriptions({ userID }: { userID: string }) {
    const { t } = useI18n();

    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

    const { loading, data, error } = useListEventSubscriptionsQuery({
        variables: {
            userID,
        },
    });

    if (loading) {
        return (
            <Box height="50vh" display="flex" justifyContent="center" alignItems="center">
                <LoadingIndicator />
            </Box>
        );
    }

    if (error || !data) {
        return <Text as="p">{t('event_subscriptions.error_retreiving_subscriptions')}</Text>;
    }

    const subs = data.listEventSubscriptions;

    return (
        <>
            <section>
                <Text as="p" mb={5}>
                    {t('event_subscriptions.section_description')}
                </Text>

                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Row>
                        <b>{t('event_subscriptions.event')}</b>
                        <b>{t('event_subscriptions.sms')}</b>
                        <b>{t('event_subscriptions.email')}</b>
                    </Row>

                    {subs.map((sub: Subscription) => (
                        <EventSubscriptionRow
                            key={sub.id}
                            userID={userID}
                            sub={sub}
                            onError={() => setIsToastOpen(true)}
                        />
                    ))}
                </Grid>
            </section>

            <Toast
                type="error"
                message={t('event_subscriptions.error_updating_subscription')}
                isOpen={isToastOpen}
                onClose={() => setIsToastOpen(false)}
            />
        </>
    );
}
