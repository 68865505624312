import { DateTime } from 'luxon';

import { ISO8601 } from 'app/core/types';

import { ColumnIndex, ScheduleConfig } from './types';

type ColumnRounding = 'floor' | 'ceil';

/**
 * A utility class used to improve performance of date calculations
 * by simply caching results in memory.
 */
export class TimeConverter {
    private initialDateTime: DateTime;

    constructor(public config: Pick<ScheduleConfig, 'historyLength' | 'initialDate'>) {
        this.initialDateTime = DateTime.fromISO(config.initialDate);
    }

    private cache = new Map<`${ISO8601}${ColumnRounding}` | ColumnIndex, DateTime | ColumnIndex>();

    /**
     * Converts the given timestamp into a column position.
     */
    private timestampToColumnPosition(timestamp: ISO8601, rounding: ColumnRounding): ColumnIndex {
        const cacheKey = `${timestamp}${rounding}` as const;
        const result = this.cache.get(cacheKey);

        if (typeof result === 'number') return result;

        const columnIndex = Math[rounding](
            DateTime.fromISO(timestamp).diff(this.initialDateTime).plus({ days: this.config.historyLength }).as('days'),
        );

        this.cache.set(cacheKey, columnIndex);

        return columnIndex;
    }

    /**
     * Converts the given starting timestamp into the nearest column index.
     */
    public startTimestampToColumnIndex(timestamp: ISO8601): ColumnIndex {
        return this.timestampToColumnPosition(timestamp, 'floor');
    }

    /**
     * Converts the given ending timestamp into the nearest column index.
     */
    public endTimestampToColumnIndex(timestamp: ISO8601): ColumnIndex {
        return this.timestampToColumnPosition(timestamp, 'ceil');
    }

    /**
     * Converts the given column index into a `Date` instance using the given configuration.
     */
    public columnIndexToDateTime(columnIndex: number): DateTime {
        const result = this.cache.get(columnIndex);

        if (result instanceof DateTime) return result;

        const dateTime = this.initialDateTime.plus({ days: columnIndex - this.config.historyLength }).startOf('day');

        this.cache.set(columnIndex, dateTime);

        return dateTime;
    }
}
