import { useMemo } from 'react';

import { ISO8601 } from 'app/core/types';

import { useGetMpuScheduleDeviceInstanceQuery } from 'generated/graphql';

import { useI18n } from 'i18n';

import { deviceInstanceFragmentToDeviceInstanceWithSchedules } from './transformers';
import { DeviceInstanceWithSchedules } from './types';

export function useMpuScheduleInstance({
    end,
    deviceInstanceID,
    start,
}: {
    end: ISO8601;
    deviceInstanceID: string;
    start: ISO8601;
}) {
    const { t } = useI18n();
    const { data, error, loading, refetch } = useGetMpuScheduleDeviceInstanceQuery({
        variables: {
            end,
            deviceInstanceID,
            start,
        },
    });

    const deviceInstance = useMemo<DeviceInstanceWithSchedules | undefined>(() => {
        const deviceInstance = data?.getDeviceInstance;

        return deviceInstance ? deviceInstanceFragmentToDeviceInstanceWithSchedules(deviceInstance, { t }) : undefined;
    }, [data, t]);

    return {
        data: deviceInstance,
        error,
        loading,
        refetch,
    };
}
