import { ReactElement, ReactNode, useCallback } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { AsideCellProps } from 'app/components/compounds/MultiGrid';
import {
    EXPANSION_TRANSITION_DURATION,
    EXPANSION_TRANSITION_EASE,
    EXPANSION_TRANSITION_TWEEN,
    ExpansionToggleButton,
    useIsScheduleRowExpanded,
    useToggleScheduleRowExpansion,
} from 'app/components/compounds/Schedule';
import { Button, Icon, Text } from 'app/components/primitives';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import useTheme from 'styles/theme';
import spacing from 'styles/theme/spacing';

import { addAllocation } from './actions';
import { ReservationScheduleAllocation } from './ReservationScheduleAllocation';
import { useReservationScheduleDispatch, useReservationScheduleState } from './ReservationScheduleContext';
import { selectUnitCount, selectUnits } from './selectors';

function AllocationsWrapper({ children, isExpanded }: { children: ReactNode; isExpanded: boolean }): ReactElement {
    const barLabelsVariants = {
        collapsed: {
            height: 0,
            marginTop: 0,
            opacity: 0,
        },
        expanded: {
            height: 'auto',
            marginTop: spacing(5),
            opacity: 1,
        },
    } as const satisfies Variants;

    return (
        <AnimatePresence>
            {isExpanded && (
                <motion.div
                    animate={barLabelsVariants.expanded}
                    exit={barLabelsVariants.collapsed}
                    initial={barLabelsVariants.collapsed}
                    transition={EXPANSION_TRANSITION_TWEEN}
                >
                    <Stack
                        spacing={5}
                        sx={{
                            opacity: isExpanded ? 1 : 0,
                            pointerEvents: isExpanded ? undefined : 'none',
                            transform: isExpanded ? undefined : 'translateY(-25%)',
                            transitionProperty: 'opacity, transform',
                            transitionDuration: EXPANSION_TRANSITION_DURATION,
                            transitionTimingFunction: EXPANSION_TRANSITION_EASE,
                        }}
                    >
                        {children}
                    </Stack>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

function UnitStartingTimestamp({ isExpanded, unitStart }: { isExpanded: boolean; unitStart: ISO8601 | undefined }) {
    const { t, format } = useI18n();

    const variants = {
        hide: { height: 0, opacity: 0 },
        show: { height: 'auto', opacity: 1 },
    } as const satisfies Variants;

    return (
        <AnimatePresence>
            {isExpanded && (
                <motion.div
                    animate={variants.show}
                    exit={variants.hide}
                    initial={isExpanded ? variants.show : variants.hide}
                    transition={EXPANSION_TRANSITION_TWEEN}
                >
                    <Text maxLines={1} as="p" color="secondary">
                        {t('reservation_schedule.allocation_start', {
                            date: format.dateCompact(unitStart),
                            time: format.time(unitStart),
                        })}
                    </Text>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export function ReservationScheduleAsideCell({ index, style }: AsideCellProps<void>): ReactElement {
    const { t } = useI18n();
    const theme = useTheme();
    const unit = useReservationScheduleState(state => selectUnits(state).at(index));
    const unitCount = useReservationScheduleState(selectUnitCount);
    const isExpanded = useIsScheduleRowExpanded(index);
    const handleExpansionToggleClick = useToggleScheduleRowExpansion(index);
    const heading = unit?.alias || t('reservation_schedule.unknown_unit_alias', { num: index + 1 });
    const dispatch = useReservationScheduleDispatch();
    const handleAddAllocationButtonClick = useCallback(() => {
        if (unit) dispatch(addAllocation(unit.id));
    }, [dispatch, unit]);
    const isLastUnit = index === unitCount - 1;
    const mpuCount =
        unit?.allocations.reduce((count, allocation) => {
            return allocation.mpuID ? count + 1 : count;
        }, 0) ?? 0;

    return (
        <Box
            style={style}
            sx={{
                transitionProperty: 'top, height',
                transitionDuration: EXPANSION_TRANSITION_DURATION,
                transitionTimingFunction: EXPANSION_TRANSITION_EASE,
            }}
        >
            <Stack pl={3} pb={5} spacing={5}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    py={4}
                    pr={4}
                    sx={{
                        // Gracefully cover overflowing content when form errors push the content down.
                        background: `linear-gradient(0deg, ${theme.palette.background.primary.main} 50%, transparent)`,
                    }}
                >
                    <Stack alignItems="start" spacing={1}>
                        <Text maxLines={1} variant="h4">
                            {heading}
                        </Text>
                        <UnitStartingTimestamp isExpanded={isExpanded} unitStart={unit?.start} />
                    </Stack>
                    <Stack alignItems="start" direction="row" spacing={3}>
                        <Box pt={2}>
                            <Text maxLines={1} variant="detail">
                                {t('reservation_schedule.mpu_count', { count: mpuCount })}
                            </Text>
                        </Box>
                        <ExpansionToggleButton isExpanded={isExpanded} onClick={handleExpansionToggleClick} />
                    </Stack>
                </Stack>
                <AllocationsWrapper isExpanded={isExpanded}>
                    {unit?.allocations.map(
                        (allocation): ReactElement => (
                            <ReservationScheduleAllocation allocation={allocation} key={allocation.id} />
                        ),
                    )}
                    <Box overflow="hidden">
                        <Button
                            ctaType="tertiary"
                            icon={<Icon name="add-circle" />}
                            onClick={handleAddAllocationButtonClick}
                            size="md"
                        >
                            {t('reservation_schedule.actions.add_allocation')}
                        </Button>
                    </Box>
                </AllocationsWrapper>
                {!isLastUnit && <Divider sx={{ mx: 3 }} />}
            </Stack>
        </Box>
    );
}
