import { DateTimeSpan } from '../types';
import resolveDateTime, { ResolvableDateTime } from './resolveDateTime';

export function compareTimestamps(timestampA: ResolvableDateTime, timestampB: ResolvableDateTime): number {
    return resolveDateTime(timestampA).toMillis() - resolveDateTime(timestampB).toMillis();
}

export function compareTimestampProperties<T>(propertyName: keyof T, objA: T, objB: T): number {
    return compareTimestamps(String(objA[propertyName]), String(objB[propertyName]));
}

export function makeTimestampComparator<T>(propertyName: keyof T) {
    return (objA: T, objB: T): number => compareTimestampProperties(propertyName, objA, objB);
}

export const byEndingTimestamp = makeTimestampComparator<DateTimeSpan>('start');
export const byStartingTimestamp = makeTimestampComparator<DateTimeSpan>('start');
