import { ComponentProps, FunctionComponent, ReactElement, ReactNode, useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';

import { withProps } from 'app/components/withProps';
import getPathByName from 'app/core/Navigation/getPathByName';
import { Experience } from 'app/core/types';

import { useCountActiveAlertsQuery } from 'generated/graphql';

import Button from '../../primitives/interactive/Button';
import Text from '../../primitives/Text';
import PrimaryNav, { NavLinkData } from './PrimaryNav/PrimaryNav';
import AlertsItem from './TopNav/NavItems/AlertsItem';
import SettingsDropdown from './TopNav/NavItems/SettingsDropdown';
import TopNav, { TOP_NAV_HEIGHT } from './TopNav/TopNav';

const PRIMARY_NAV_WIDTH = 260;

type ButtonProps = ComponentProps<typeof Button>;

export type AppChromeContentLayout = 'standard' | 'none';

interface Props {
    /**
     * The content layout to apply
     */
    contentLayout: AppChromeContentLayout;

    /**
     * All primary nav links to render
     */
    links: NavLinkData[];

    /**
     * User permissions
     */
    userCapabilities: {
        /**
         * Whether user has permission to view alerts
         */
        canViewAlerts?: boolean;
    };

    /**
     * The experience to switch to
     */
    targetExperience?: Experience;

    /**
     * The primary CTA to show in the nav (optional)
     */
    renderPrimaryCTA?: (props: ButtonProps) => ReactElement;

    /**
     * The page content
     */
    children?: ReactNode;

    /**
     * Event handler to be called when the user initiates switching the experience
     */
    onSwitchExperience: (event: { value: Experience }) => void;

    /**
     * Event handler to be called to signal sign out intent
     */
    onSignOut: () => void;
}

const contentContainerByContentLayout: Record<AppChromeContentLayout, FunctionComponent<{ children: ReactNode }>> = {
    none: withProps(Box, props => ({
        ...props,
        component: 'main' as const,
        id: 'page-content',
        minHeight: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
    })),
    standard: withProps(Container, props => ({
        ...props,
        component: 'main' as const,
        id: 'page-content',
        maxWidth: 'xxl' as const,
        sx: { py: 5, minHeight: `calc(100% - ${TOP_NAV_HEIGHT}px)` },
    })),
};

/**
 * Responsible for all display aspects of our basic page layout
 */
export default function AppChromeLayout({
    children,
    contentLayout = 'standard',
    links,
    userCapabilities,
    targetExperience,
    renderPrimaryCTA,
    onSwitchExperience,
    onSignOut,
}: Props): ReactElement {
    const [isPrimaryNavOpen, setIsPrimaryNavOpen] = useState<boolean>(false);
    const ContentContainer = contentContainerByContentLayout[contentLayout];

    const closePrimaryNav = useCallback(() => setIsPrimaryNavOpen(false), [setIsPrimaryNavOpen]);
    const openPrimaryNav = useCallback(() => setIsPrimaryNavOpen(true), [setIsPrimaryNavOpen]);

    const { data: countActiveAlertsData } = useCountActiveAlertsQuery();

    let alertsItem: ReactElement | undefined;

    const { canViewAlerts } = userCapabilities;

    if (canViewAlerts) {
        alertsItem = (
            <AlertsItem
                viewAllAlertsHref={getPathByName('ALERTS')}
                alertCount={countActiveAlertsData?.countActiveAlerts}
            />
        );
    }

    return (
        <Stack
            component={Text}
            direction="row"
            sx={{ backgroundColor: 'background.default', minHeight: '100%' }}
            variant="body"
        >
            <PrimaryNav
                id="primary-nav"
                width={PRIMARY_NAV_WIDTH}
                isOpen={isPrimaryNavOpen}
                onClose={closePrimaryNav}
                navLinks={links}
                renderPrimaryCTA={renderPrimaryCTA}
            />

            <Stack width={{ xs: '100%', md: `calc(100% - ${PRIMARY_NAV_WIDTH}px)` }} minHeight="100%">
                <TopNav
                    widthAdjustment={PRIMARY_NAV_WIDTH}
                    openMenuCTA={
                        <ToggleButton
                            aria-haspopup="true"
                            aria-controls="primary-nav"
                            aria-expanded={isPrimaryNavOpen ? 'true' : undefined}
                            value=""
                            selected={isPrimaryNavOpen}
                            onClick={openPrimaryNav}
                        />
                    }
                    alertsItem={alertsItem}
                    settings={
                        <SettingsDropdown
                            onSignOut={onSignOut}
                            onSwitchExperience={onSwitchExperience}
                            targetExperience={targetExperience}
                        />
                    }
                />

                <ContentContainer>{children}</ContentContainer>
            </Stack>
        </Stack>
    );
}
