import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { LoadingIndicator, Text } from 'app/components/primitives';
import { withProps } from 'app/components/withProps';

import { useI18n } from 'i18n';

import MPUTile from '../MPUTile';
import { MPU_TILE_WIDTH } from '../MPUTileContent';

const TileWrapper = withProps(Box, props => ({
    display: 'block',
    margin: 2,
    ...props,
}));

const TileGrid = withProps(Box, props => ({
    sx: {
        textAlign: 'center',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${MPU_TILE_WIDTH.as('px')}, 1fr))`,
        gridGap: '8px',
    },
    ...props,
}));

interface MPUTileGridProps {
    /**
     * Determines whether the component should render skeleton content.
     * When `true`, loading tiles are appended to the grid.
     */
    hasMoreItems?: boolean;
    /**
     * An optional element used to detect when the user has scrolled to the bottom of the grid.
     */
    scrollCursorElement?: ReactElement;
    /**
     * The number of skeleton tiles to render in place of the next collection of MPUs to load.
     *
     * @default 10
     */
    skeletonTilesCount?: number;
    /**
     * The MPU tiles to render in the grid.
     */
    tiles: ComponentProps<typeof MPUTile>[];
}

export function MPUTileGrid({
    hasMoreItems,
    scrollCursorElement,
    skeletonTilesCount = 10,
    tiles,
}: MPUTileGridProps): ReactElement {
    const { t } = useI18n();

    function renderMPUTiles() {
        return tiles.map(({ mpuID, ...tileProps }, index) => {
            const isLastTile = tiles.length === index + 1;
            // The cursor element is inserted within the last tile so the parent component
            // may begin loading new items before the user reaches the bottom of the visible grid.
            const cursor = isLastTile ? scrollCursorElement : null;

            return (
                <TileWrapper key={mpuID}>
                    {cursor}
                    <MPUTile mpuID={mpuID} {...tileProps} />
                </TileWrapper>
            );
        });
    }

    function renderSkeletonTiles() {
        return new Array(skeletonTilesCount).fill(null).map((_, index) => (
            <TileWrapper key={`loading-tile-${index}`}>
                <MPUTile loading />
            </TileWrapper>
        ));
    }

    return (
        <Stack direction="column" pb="64px" spacing={4}>
            <TileGrid>
                {renderMPUTiles()}
                {hasMoreItems && renderSkeletonTiles()}
            </TileGrid>
            {hasMoreItems ? (
                <Box textAlign="center">
                    <LoadingIndicator />
                </Box>
            ) : (
                <Text color="secondary" textAlign="center" component="p">
                    —&emsp;{t('mpu_tile.end_of_list')}&emsp;—
                </Text>
            )}
        </Stack>
    );
}
