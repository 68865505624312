import { ComponentProps, forwardRef, KeyboardEvent, ReactElement, Ref, useEffect, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import { normalizeSxProp, SxPropMixin } from 'app/components/normalizeSxProp';
import { DEFAULT_TIME_ZONE } from 'app/core/date-time';
import { IANATimeZone } from 'app/core/types';

import { useI18n } from 'i18n';

import Input, { useInputForAutocomplete } from '../Input';
import BusinessHours from './BusinessHours';

interface HourChangeEvent {
    value: {
        hour: number | null;
        timeZone: IANATimeZone;
    };
}

interface Props extends Omit<ComponentProps<typeof Input>, 'value' | 'onChange' | 'select' | 'children'>, SxPropMixin {
    /**
     * Unique identifier for the input to be able to associate labels and other accessibility related annotations
     */
    id?: string;

    /**
     * The name for the value being collected
     */
    name: string;

    /**
     * The selected hour. For any hour outside of the business hours the value will be unset
     */
    value: number | null | undefined;

    /**
     * Whether or not the value managed by this control is required
     */
    required?: boolean;

    /**
     * Whether there is a validation error
     */
    error?: boolean;

    /**
     * Whether the field is valid (positive side of error)
     */
    valid?: boolean;

    /**
     * Whether or not the control should be disabled
     */
    disabled?: boolean;

    /**
     * Event handler to be called when a key is pressed down
     */
    onKeyDown?: (event: KeyboardEvent) => void;

    /**
     * Event handler to be called when the value is changed
     */
    onChange: (event: HourChangeEvent) => void;
    /**
     * The inclusive closing hour for selecting a time
     */
    closingHour?: number;
    /**
     * The inclusive opening hour for selecting a time
     */
    openingHour?: number;
    /**
     * The time zone to use for the business hours
     */
    timeZone?: IANATimeZone;
}

export default forwardRef(function BusinessHourSelector(
    {
        id,
        name,
        value,
        fieldName,
        caption,
        readOnly = false,
        disabled = false,
        required = true,
        error = false,
        fullWidth = true,
        valid,
        onKeyDown = event => {},
        onChange,
        timeZone = DEFAULT_TIME_ZONE,
        openingHour,
        closingHour,
        sx,
    }: Props,
    ref: Ref<HTMLInputElement>,
): ReactElement {
    const { format } = useI18n();
    const renderInput = useInputForAutocomplete({
        fieldName,
        caption,
        name,
        required,
        readOnly,
        error,
        valid,
        fullWidth,
        ref,
        onKeyDown,
    });

    const businessHours = useMemo(() => {
        return new BusinessHours({ openingHour, closingHour, timeZone });
    }, [timeZone, openingHour, closingHour]);

    function formatHour(option: number): string {
        return format.hourWithTimeZone(option, { timeZone });
    }

    // Let the controlling component know that the desired value is not valid
    useEffect(() => {
        if (!!value && !businessHours.isOpen(value)) {
            onChange({
                value: {
                    hour: null,
                    timeZone,
                },
            });
        }
    }, [businessHours, value, timeZone, onChange]);

    return (
        <Autocomplete
            id={id}
            fullWidth={fullWidth}
            openOnFocus
            open={readOnly ? false : undefined}
            disabled={disabled}
            forcePopupIcon={!readOnly}
            sx={[
                {
                    '& .MuiOutlinedInput-input': {
                        minWidth: '10ch !important',
                        maxWidth: fullWidth ? null : '10ch !important',
                    },
                },
                {
                    // Squeeze space out of dropdown arrow and selection label
                    // to better suit small mobile phones ~320px;
                    '& .MuiOutlinedInput-root': {
                        pr: `26px`,
                    },
                },

                ...normalizeSxProp(sx),
            ]}
            disableClearable={required}
            value={value ?? null}
            options={businessHours.hourSlots}
            getOptionLabel={option => {
                return businessHours.isOpen(option) ? formatHour(option) : '';
            }}
            renderInput={renderInput}
            renderOption={(props, option) => (
                <li {...props} key={option}>
                    {formatHour(option)}
                </li>
            )}
            filterOptions={(x: number[], y) => {
                const matches: number[] = [];
                const nonMatches: number[] = [];

                x.forEach((cur: number) => {
                    const inputValueWithoutWhitespace = y.inputValue.replace(/\s/g, '');
                    const optionLabelWithoutWhitespace = y.getOptionLabel(cur).replace(/\s/g, '');

                    if (new RegExp(inputValueWithoutWhitespace, 'i').test(optionLabelWithoutWhitespace)) {
                        matches.push(cur);
                    } else {
                        nonMatches.push(cur);
                    }
                });

                return matches.length > 0 ? matches : x;
            }}
            onChange={(event, newValue) => {
                onChange({
                    value: {
                        hour: newValue,
                        timeZone,
                    },
                });
            }}
        />
    );
});
