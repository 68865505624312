import { ComponentProps, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import IconAndText from 'app/components/primitives/layout/IconAndText';
import Text from 'app/components/primitives/Text';

import useTheme, { Theme } from 'styles/theme';

import ValueWithUnit from './ValueWithUnit';

const cardStyles = ({ size, theme }: { size: 'md' | 'lg'; theme: Theme }) => ({
    borderColor: 'border.light',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: `${theme.shape.borderRadius}px`,
    p: 5,
    py: size === 'lg' ? 8 : null,
});

interface MetricProps extends ComponentProps<typeof Box> {
    /**
     * Presentational variants
     * - content will return just content
     * - card will return content within a card
     */
    variant?: 'content' | 'card';

    /**
     * The label describing the metric
     */
    label: string;

    /**
     * Optional icon to associate with the metric
     */
    icon?: ReactElement | null;

    /**
     * The metric value to display, it's optional when children are passed
     * to display the value or related values
     */
    value?: string | number | ReactNode;

    /**
     * The unit of the value if exists
     */
    unit?: string;

    /**
     * The card and layout sizes, lg is more spacious
     * @defaultValue 'md'
     */
    size?: 'md' | 'lg';

    /**
     * Whether to center the content or not
     * @defaultValue false
     */
    center?: boolean;

    /**
     * Whether to fill the parent container or not. The default is try to support the main case of extrinsic sizing
     * such that any metric appearing in the same row maintains the same height
     * @defaultValue true
     */
    fillParent?: boolean;
}

/**
 * Responsible for the various layouts of content related to metric data as it appears in the monitoring dashboard
 */
export default function Metric({
    variant = 'content',
    label,
    icon,
    value,
    unit,
    size = 'md',
    center = false,
    fillParent = true,
    children,
    ...props
}: MetricProps) {
    const dimensions = fillParent ? { height: '100%' } : null;
    const theme = useTheme();

    const variantProps =
        variant === 'card'
            ? {
                  sx: {
                      ...dimensions,
                      ...cardStyles({ size, theme }),
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: center ? 'center' : null,
                      justifyContent: center ? 'center' : null,
                  },
              }
            : null;

    return (
        <Box {...props} {...variantProps}>
            <Text variant="dataLabel" as="div" mb={size === 'lg' ? 5 : 4}>
                {label}
            </Text>

            {!!children ? (
                children
            ) : (
                <IconAndText icon={icon} text={<ValueWithUnit size={size} value={value} unit={unit} />} />
            )}
        </Box>
    );
}

interface MetricGroupProps extends ComponentProps<typeof Stack> {
    /**
     * Whether to fill the parent container or not. The default is try to support the main case of extrinsic sizing
     * such that any metric appearing in the same row maintains the same height
     * @defaultValue true
     */
    fillParent?: boolean;
}

/**
 * Responsible for showing multiple metrics together, children should be an array of Metrics but could be anything
 */
export function MetricGroup({ fillParent = true, children, ...props }: MetricGroupProps): ReactElement {
    const dimensions = fillParent ? { height: '100%' } : null;
    const theme = useTheme();

    return (
        <Stack
            {...props}
            spacing={6}
            sx={{
                ...dimensions,
                ...cardStyles({ size: 'md', theme }),
            }}
        >
            {children}
        </Stack>
    );
}
