import { ReactElement, useEffect, useId, useRef, useState } from 'react';

import { Button, Dialog } from 'app/components';
import { useLayer } from 'app/core/layers';
import { useSession } from 'app/core/Session';

import { useI18n } from 'i18n';

import FilterAlertForm from './FilterAlertForm';

function FilterDialog({
    alertSeverities,
    reservationIDs,
    rentalAccountIDs,
    serviceAreaIDs,

    onClearFilters,
    onSubmit,
    onClose,
}): ReactElement {
    const formID = useId();

    const { primaryAccountID } = useSession().getSession();

    const { t } = useI18n();

    const [shouldDisableSubmitCTA, setShouldDisableSubmitCTA] = useState(false);
    const [shouldDisableClearCTA, setShouldDisableClearCTA] = useState(false);

    return (
        <Dialog
            onClose={onClose}
            title={t('Filter Alerts')}
            primaryCTA={
                <Button type="submit" form={formID} onClick={onClose} disabled={shouldDisableSubmitCTA}>
                    {t('cta.ok')}
                </Button>
            }
            auxiliaryCTA={
                <Button
                    form={formID}
                    type="reset"
                    onClick={() => {
                        onClearFilters?.();
                    }}
                    ctaType="secondary"
                    color="danger"
                    disabled={shouldDisableClearCTA}
                >
                    {t('alerts_page.cta.clear_filters')}
                </Button>
            }
        >
            <FilterAlertForm
                id={formID}
                accountID={primaryAccountID}
                alertSeverities={alertSeverities}
                reservationIDs={reservationIDs}
                rentalAccountIDs={rentalAccountIDs}
                serviceAreaIDs={serviceAreaIDs}
                onSubmit={async ({ value }) => {
                    await onSubmit({ value });
                    onClose?.();
                }}
                onFormStateChange={({ shouldDisableCTA, filterCriterionCount }) => {
                    setShouldDisableSubmitCTA(shouldDisableCTA);
                    setShouldDisableClearCTA(filterCriterionCount === 0);
                }}
            />
        </Dialog>
    );
}

export default function useAddOrEditFilter({ filters, onSubmit, onClearFilters }) {
    const dialogRef = useRef<any | null>(null);
    const { dialog } = useLayer();

    useEffect(() => {
        if (!dialogRef?.current) return;

        dialogRef?.current.update({
            alertSeverities: filters?.alertSeverities,
            reservationIDs: filters?.reservationIDs,
            rentalAccountIDs: filters?.rentalAccountIDs,
            serviceAreaIDs: filters?.serviceAreaIDs,
        });
    }, [filters]);

    return () => {
        dialogRef.current = dialog.add(
            {
                alertSeverities: filters?.alertSeverities,
                reservationIDs: filters?.reservationIDs,
                rentalAccountIDs: filters?.rentalAccountIDs,
                serviceAreaIDs: filters?.serviceAreaIDs,
                onClearFilters,
                onSubmit,
            },
            { Component: FilterDialog },
        );

        dialogRef.current?.on('close', () => {
            dialogRef.current = null;
        });

        return dialogRef.current;
    };
}
