import { useFormik } from 'formik';
import { object, string } from 'yup';

import Alert from '@mui/material/Alert';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { Text } from 'app/components';

import { useI18n } from 'i18n';

interface SignUpFormValues {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    inviteKey?: string;
}

interface Props extends Omit<BoxProps, 'onSubmit'> {
    /**
     * Email set via invite
     */
    email?: string;
    /**
     * Invite key set via invite
     */
    inviteKey?: string;
    /**
     * An error message to show to the user, pass falsey value to suppress
     */
    errorMessage?: string | null;
    /**
     * Event handler to signify when a user has completed the form and all form fields are
     * valid.
     */
    onSubmit: (result: { values: SignUpFormValues }) => Promise<void>;
}

export default function SignUpForm({ email, inviteKey, errorMessage, onSubmit, ...props }: Props) {
    const { t } = useI18n();

    const formik = useFormik<SignUpFormValues>({
        enableReinitialize: true,

        initialValues: {
            email: email ?? '',
            password: '',
            firstName: '',
            lastName: '',
            inviteKey,
        },

        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: object().shape({
            email: string()
                .email(t('signin_page.email_invalid_message'))
                .required(t('signin_page.email_required_message')),
            password: string().password().required(t('signin_page.password_required_message')),
            firstName: string().required(t('signup_page.first_name_required_message')),
            lastName: string().required(t('signup_page.last_name_required_message')),
            inviteKey: string().optional(),
        }),

        async onSubmit(values) {
            await onSubmit({ values });
        },
    });

    const hasErrors = Object.keys(formik.errors).length > 0;

    return (
        <Box {...props}>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2} mb={4}>
                    <Stack>
                        <FormLabel error={!!formik.errors.email} required htmlFor="email-input-1" sx={{ mb: 1 }}>
                            {t('signin_page.email_label')}
                        </FormLabel>
                        <TextField
                            fullWidth
                            id="email-input-1"
                            disabled={!!inviteKey}
                            size="small"
                            helperText={formik.errors.email ?? ' '}
                            name="email"
                            autoComplete="username"
                            error={!!formik.errors.email}
                            value={formik.values.email}
                            onChange={event => {
                                if (formik.touched.email && formik.errors.email) {
                                    formik.validateField('email');
                                }

                                formik.handleChange(event);
                            }}
                            onFocus={() => {
                                formik.setFieldTouched('email');
                            }}
                            onBlur={() => {
                                formik.validateField('email');
                            }}
                        />
                    </Stack>

                    <Stack>
                        <FormLabel error={!!formik.errors.password} required htmlFor="password-input-1" sx={{ mb: 1 }}>
                            {t('signin_page.password_label')}
                        </FormLabel>
                        <TextField
                            fullWidth
                            id="password-input-1"
                            name="password"
                            value={formik.values.password}
                            size="small"
                            type="password"
                            autoComplete="new-password"
                            error={!!formik.errors.password}
                            helperText={t('signup_page.password_guidelines')}
                            onChange={event => {
                                if (formik.touched.password && formik.errors.password) {
                                    formik.validateField('password');
                                }

                                formik.handleChange(event);
                            }}
                            onFocus={() => {
                                formik.setFieldTouched('password');
                            }}
                            onBlur={() => {
                                formik.validateField('password');
                            }}
                        />
                    </Stack>

                    <Stack>
                        <FormLabel
                            error={!!formik.errors.firstName}
                            required
                            htmlFor="first-name-input-1"
                            sx={{ mb: 1 }}
                        >
                            {t('signup_page.first_name_label')}
                        </FormLabel>
                        <TextField
                            fullWidth
                            id="first-name-input-1"
                            name="firstName"
                            value={formik.values.firstName}
                            size="small"
                            autoComplete="given-name"
                            error={!!formik.errors.firstName}
                            helperText={formik.errors.firstName ?? ' '}
                            onChange={formik.handleChange}
                            onBlur={() => {
                                formik.validateField('firstName');
                            }}
                        />
                    </Stack>

                    <Stack>
                        <FormLabel error={!!formik.errors.lastName} required htmlFor="last-name-input-1" sx={{ mb: 1 }}>
                            {t('signup_page.last_name_label')}
                        </FormLabel>
                        <TextField
                            fullWidth
                            id="last-name-input-1"
                            name="lastName"
                            value={formik.values.lastName}
                            size="small"
                            autoComplete="family-name"
                            error={!!formik.errors.lastName}
                            helperText={formik.errors.lastName ?? ' '}
                            onChange={formik.handleChange}
                            onBlur={() => {
                                formik.validateField('lastName');
                            }}
                        />
                    </Stack>
                </Stack>

                {errorMessage && (
                    <Alert sx={{ mb: 4 }} severity="error">
                        {errorMessage}
                    </Alert>
                )}

                <Button type="submit" variant="contained" fullWidth sx={{ mb: 7 }} disabled={hasErrors}>
                    {t('cta.create_account')}
                </Button>

                <Text variant="detail" as="p" color="secondary" textAlign="center">
                    {t('signup_page.legal_acknowledgement')}
                </Text>
            </form>
        </Box>
    );
}
