import SPACING, { SPACING_RAMP } from 'design-system/spacing';

/**
 * Our design system scale is not linear and non-numeric but in order to
 * integrate with MUIs spacing system our tokens have been mapped to numerical tokens
 * See our SPACING_RAMP for the mapping
 *
 * MUI Default is {spacingToken} * 8px (i.e. 4 * 8 = 32 (px))
 * and will only accept numerical tokens. Any non-numeric token value
 * returns 0
 *
 * @returns {string} Number of pixels for given spacing token
 */
export default function spacing(numericToken: number): number {
    const tShirtToken = SPACING_RAMP[numericToken];
    const result = SPACING[tShirtToken];

    // Fallback to default MUI spacing outside of our theme
    if (!result) {
        // eslint-disable-next-line
        console.warn(`The spacing token ${numericToken} does not exist please use 0 - 8.`);

        return numericToken * 8;
    }

    return result.as('px');
}
