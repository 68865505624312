import { parseBuildDateFromSerialNumber } from 'app/core/data';

import { useGetMpuBuildDetailsQuery } from 'generated/graphql';

export default function useMPUBuildDetails({ mpuID }) {
    const { loading, data } = useGetMpuBuildDetailsQuery({
        skip: !mpuID,
        variables: {
            id: mpuID ?? '',
        },
    });

    const mpu = data?.getDeviceInstance;

    const lifetime: any = (mpu?.latestTelemetry?.telemetryPoints ?? []).reduce((acc, cur) => {
        return {
            ...acc,
            [cur.metric]: cur.value,
        };
    }, {});

    return {
        loading,
        buildDetails: {
            buildDate: parseBuildDateFromSerialNumber(mpu?.serialNumber ?? ''),
            bmsVersion: mpu?.firmwareVersions?.bmsVersion,
            ecuVersion: mpu?.firmwareVersions?.ecuVersion,
            evccVersion: mpu?.firmwareVersions?.evccVersion,
            pmcVersion: mpu?.firmwareVersions?.pmcVersion,
            tguVersion: mpu?.firmwareVersions?.tguVersion,

            lifetimePCSActivity: {
                value: lifetime?.mp_unit_lifetime_pcs_active_s,
                unit: 'seconds',
            },

            lifetimeDischarge: {
                value: lifetime?.mp_unit_e_lifetime_discharge,
                unit: 'Wh',
            },
        },
    };
}
