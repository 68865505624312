import { ReactElement, useEffect, useId } from 'react';
import { DateTime } from 'luxon';

import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers/DateCalendar';

import RangePickerDay, { DateRangeChangeEvent } from './RangePickerDay';

interface Props extends Omit<DateCalendarProps<DateTime>, 'onChange'> {
    /**
     * Determines how the day selection effects the selected range
     */
    selectionTarget?: 'auto' | 'start' | 'end';

    /**
     * The start date for the range
     */
    start?: DateTime | null;

    /**
     * The end date for the range
     */
    end?: DateTime | null;

    /**
     * Candidate end or start date (depending on selectionTarget)
     */
    candidate?: DateTime | null;

    /**
     * Whether or not the given start day is invalid
     */
    hasStartError?: boolean;

    /**
     * Whether or not the given end day is invalid
     */
    hasEndError?: boolean;

    /**
     * Event handler for passing back changes to the range selection
     */
    onChange?: (event: DateRangeChangeEvent) => void;
}

/**
 * Display and selection of a date range on a 1 month calendar
 */
export default function DateRangeCalendar({
    start,
    end,
    candidate,

    hasStartError = false,
    hasEndError = false,
    selectionTarget = 'auto',
    onChange,
    value,
    ...otherProps
}: Props): ReactElement {
    const calendarRootId = useId();

    // This is here to clear the candidate day when the users mouse leaves the calendar bounds
    // Mui DateCalendar does not expose this element so we directly query it here
    useEffect(() => {
        // Because the Mui DateCalendar cannot hold a ref we have to resort to querying for the element
        const el = document.getElementById(calendarRootId)?.querySelector(`.MuiPickersSlideTransition-root`);

        const handleMouseLeave = () => {
            onChange?.({
                value: {
                    start,
                    end,
                    candidate: null,
                },
            });
        };

        el?.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            el?.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [calendarRootId, start, end, onChange]);

    return (
        <DateCalendar
            {...otherProps}
            id={calendarRootId}
            value={value ?? DateTime.now()}
            views={['day']}
            monthsPerRow={4}
            fixedWeekNumber={6}
            showDaysOutsideCurrentMonth
            sx={{
                '& .MuiPickersCalendarHeader-root': {
                    position: 'relative',
                    padding: 0,
                    marginLeft: '12px',
                    marginRight: '12px',
                },
                '& .MuiPickersCalendarHeader-root > .MuiPickersArrowSwitcher-root': {
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 2,
                },
                '& .MuiPickersCalendarHeader-root > .MuiPickersCalendarHeader-labelContainer': {
                    margin: 'auto',
                },

                '& .MuiPickersSlideTransition-root': {
                    minHeight: '254px',
                },
                '& .MuiDayCalendar-weekDayLabel': {
                    width: '40px',
                    fontWeight: 500,
                },
            }}
            slots={{
                // TODO: find out how to properly extend slots
                // @ts-expect-error
                day: RangePickerDay,
            }}
            slotProps={{
                day: {
                    // Custom props passed to RangePickerDay to make
                    // range selection work
                    // TODO: find out how to properly extend slotProps
                    selectionTarget,
                    candidate,
                    start,
                    end,

                    hasStartError,
                    hasEndError,

                    // @ts-expect-error
                    onChange,
                },
            }}
        />
    );
}
