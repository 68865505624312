import { ReactElement } from 'react';

import Icon from 'app/components/primitives/Icon';
import IconAndText from 'app/components/primitives/layout/IconAndText';

import { useI18n } from 'i18n';

import Metric from './Metric';
import ValueWithUnit from './ValueWithUnit';

interface Props {
    /**
     * The net power for an MPU in kW
     */
    value?: number;
}

function resolvePowerFlowIcon(netPower) {
    if (typeof netPower !== 'number' || netPower === 0) return null;

    if (netPower > 0) return <Icon name="arrow-forward" />;

    return <Icon name="arrow-back" />;
}

/**
 * Responsible for displaying the latest known NetPower value for an MPU
 */
export default function NetPower({ value }: Props): ReactElement {
    const { t, format } = useI18n();

    return (
        <Metric center variant="card" label={t('unit_status.net_power_label')}>
            <IconAndText
                direction="column"
                icon={
                    <>
                        {resolvePowerFlowIcon(value)}
                        <Icon name="equipment" />
                    </>
                }
                text={<ValueWithUnit {...format.netPower(value)} />}
            />
        </Metric>
    );
}
