import { DateTime } from 'luxon';

import { DEFAULT_TIME_ZONE, PossibleISO8601 } from 'app/core/date-time';
import { IANATimeZone, ISO8601 } from 'app/core/types';

import { DateTimeChangeEvent } from './types';

const UNIX_EPOCH = DateTime.fromISO('1970-01-01T00:00:00.000Z');

export function nullifyTime(value: ISO8601, timeZone: IANATimeZone = DEFAULT_TIME_ZONE): DateTime {
    return DateTime.fromISO(value, { zone: timeZone }).startOf('day');
}

export function nullifyDate(value: ISO8601, timeZone: IANATimeZone = DEFAULT_TIME_ZONE) {
    const time = DateTime.fromISO(value, { zone: timeZone });

    return time.set({
        year: UNIX_EPOCH.year,
        month: UNIX_EPOCH.month,
        day: UNIX_EPOCH.day,
    });
}

export function makeDateTimeChangeEvent<
    T extends PossibleISO8601 = PossibleISO8601,
    E extends string | null = string | null,
>({
    name,
    timeZone,
    validationError,
    value,
}: {
    name?: string;
    timeZone: IANATimeZone;
    validationError?: E;
    value: T;
}): DateTimeChangeEvent<T, E> {
    return {
        target: {
            name: name ?? '',
            value: value ?? '',
        },
        timeZone,
        validationError: validationError ?? undefined,
        value,
    };
}
