import React, {
    ComponentProps,
    FocusEvent,
    FocusEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useId,
} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import FieldCaption from 'app/components/primitives/FieldCaption';
import FieldLabel from 'app/components/primitives/FieldLabel';

import { FieldGroupSegment } from './FieldGroupSegment';

interface FieldGroupProps {
    /**
     * Additional helper text or inline error
     */
    caption?: ReactNode;
    /**
     * The field group content
     */
    children?: ReactNode;
    /**
     * Whether or not the user can interact with the control
     */
    disabled?: boolean;
    /**
     * Determines whether the error state is shown
     */
    error?: boolean;
    /**
     * The legend content contextualizing the field inputs
     */
    fieldName?: ReactNode;
    /**
     * Force visual treatment for focus
     */
    focused?: boolean;
    /**
     * Whether to fill the parent elements width or not
     */
    fullWidth?: boolean;
    /**
     * Whether to render the field as readOnly / static
     */
    readOnly?: boolean;
    /**
     * Content to render when the field group is marked as readOnly
     */
    readOnlyContent?: ReactNode;
    /**
     * Whether the field value is required
     */
    required?: boolean;
    /**
     * Event handler to be called when either the date or the hour changes
     */
    onBlur?: (event: FocusEvent) => void;
    /**
     * Event handler to be called when either the date or the hour changes
     */
    onFocus?: (event: FocusEvent) => void;
}

function isFieldGroupSegmentElement(
    node: ReactNode,
): node is ReactElement<ComponentProps<typeof FieldGroupSegment>, typeof FieldGroupSegment> {
    return React.isValidElement(node) && node.type === FieldGroupSegment;
}

/**
 * A FieldGroup is a compound component that groups related fields together in a single field.
 *
 * @example
 *
 * <FieldGroup fieldName="My field">
 *     <FieldGroup.Segment>
 *         <Input />
 *     </FieldGroup.Segment>
 *     <FieldGroup.Segment>
 *         <Input />
 *     </FieldGroup.Segment>
 *     <FieldGroup.Segment>
 *         <Input />
 *     </FieldGroup.Segment>
 * </FieldGroup>
 *
 * @privateRemarks
 *
 * The markup for this component was pulled out of the `BusinessDateTimeField` component,
 * and made reusable for other compound components.
 */
function FieldGroup({
    caption,
    children,
    disabled,
    error,
    fieldName,
    focused,
    fullWidth,
    onBlur,
    onFocus,
    readOnly,
    readOnlyContent,
    required,
}: FieldGroupProps): ReactElement {
    const captionID = useId();

    const handleFocus = useCallback<FocusEventHandler>(
        event => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
                onFocus?.(event);
            }
        },
        [onFocus],
    );

    const handleBlur = useCallback<FocusEventHandler>(
        event => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
                onBlur?.(event);
            }
        },
        [onBlur],
    );

    function renderChildren() {
        const count = React.Children.count(children);
        const lastIndex = count - 1;

        const variantByIndex = {
            0: FieldGroupSegment.Variant.Left,
            [lastIndex]: FieldGroupSegment.Variant.Right,
        };

        return React.Children.map(children, (node, index) => {
            if (!isFieldGroupSegmentElement(node)) {
                return node;
            }

            const variant = variantByIndex[index] ?? FieldGroupSegment.Variant.Center;

            return React.cloneElement(node, { fullWidth, variant });
        });
    }

    return (
        // Extra wrapping box protects usage in Stack / flexbox direction="column"
        <Box
            sx={{
                display: 'inline-flex',
                width: fullWidth ? '100%' : null,
            }}
        >
            <Stack
                component="fieldset"
                sx={{
                    display: 'inline-flex',
                    width: fullWidth ? '100%' : null,
                    mx: 0,
                    p: 0,
                    border: 'none',
                }}
            >
                <FieldLabel
                    as="legend"
                    fieldName={fieldName}
                    required={required}
                    error={error}
                    disabled={disabled}
                    readOnly={readOnly}
                    // TODO(Morris): Resolve alignment issue without overriding styles
                    // Alignment fix, the original value is 12px
                    sx={{ mb: '10px' }}
                />

                {readOnly ? (
                    readOnlyContent
                ) : (
                    <Box
                        aria-describedby={captionID}
                        display="inline-flex"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        sx={theme => {
                            const focusHighlight = theme.customShadows[error ? 'errorHighlight' : 'focusHighlight'];

                            return {
                                borderRadius: `${theme.shape.borderRadius}px`,
                                boxShadow: focused ? focusHighlight : null,
                                width: fullWidth ? '100%' : null,
                                '&:focus-within': {
                                    boxShadow: focusHighlight,
                                },
                            };
                        }}
                    >
                        {renderChildren()}
                    </Box>
                )}

                <FieldCaption id={captionID} caption={caption} error={error} />
            </Stack>
        </Box>
    );
}

FieldGroup.Segment = FieldGroupSegment;

export default FieldGroup;
