import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Icon, IconAndText, Text } from 'app/components';

import { useI18n } from 'i18n';

export default function AlertSeverityLevelLegend(): ReactElement {
    const { t } = useI18n();

    return (
        <Box>
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <IconAndText
                        color="danger"
                        icon={<Icon name="warning" />}
                        text={t('alerts.severity.critical.name')}
                    />
                    <Text>{t('alerts.severity.critical.description')}</Text>
                </Stack>

                <Stack spacing={2}>
                    <IconAndText
                        color="warning"
                        icon={<Icon name="warning" />}
                        text={t('alerts.severity.major.name')}
                    />
                    <Text>{t('alerts.severity.major.description')}</Text>
                </Stack>
            </Stack>
        </Box>
    );
}
