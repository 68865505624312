import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { LoadingIndicator } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';
import { useSession } from 'app/core/Session';

import { RenterAccountDashboard } from './presentation';
import { useRenterDashboardData } from './useRenterDashboardData';

export function RenterAccountDashboardContainer() {
    const navigate = useNavigate();
    const session = useSession();
    const { name } = session.getSession();

    const { reservationItems, reservationCount, loading, hasError } = useRenterDashboardData();

    if (loading) {
        return (
            <Box height="50vh" display="flex" justifyContent="center" alignItems="center">
                <LoadingIndicator />
            </Box>
        );
    }

    return (
        <RenterAccountDashboard
            userName={name}
            reservationItems={reservationItems}
            reservationCount={reservationCount}
            hasError={hasError}
            onViewDashboard={({ id }) => {
                navigate(getPathByName('RENTER_RESERVATION_MONITORING', { params: { reservationID: id } }));
            }}
        />
    );
}
