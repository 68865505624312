import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';

import { Text, ZeroState } from 'app/components';
import { useSession } from 'app/core/Session';

import { useI18n } from 'i18n';

import PanelWithZeroState from './PanelWithZeroState';
import { ReservationAccordionList } from './ReservationAccordionList';
import ReservationTabs from './ReservationTabs';
import { useDialogContext } from './useDialogContext';
import { useReservationsPageData } from './useReservationsPageData';
import useSyncTabToUrl from './useSyncTabToUrl';

export function CustomerReservationsPage() {
    const { t } = useI18n();

    const { activeTab, updateActiveTab } = useSyncTabToUrl();

    const session = useSession();
    // TODO(Morris): Why does this default to `'unknown'`? Seems magical to me.
    const { primaryAccountID = 'unknown' } = session.getSession();
    const {
        currentReservations,
        upcomingReservations,
        pastReservations,
        cancelledReservations,
        declinedReservations,
        errorMessage,
        loading,
    } = useReservationsPageData(primaryAccountID);
    const { errorMessage: dialogErrorMessage, dismissError: dismissDialogError } = useDialogContext();

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    useEffect(() => {
        if (!!(dialogErrorMessage || errorMessage)) {
            setIsAlertOpen(true);
        }
    }, [dialogErrorMessage, errorMessage]);

    return (
        <>
            <Box mb={5}>
                <Text variant="h1">{t('reservation_table.title')}</Text>
            </Box>

            <Collapse in={isAlertOpen}>
                <Alert
                    severity="error"
                    onClose={() => {
                        setIsAlertOpen(false);
                        dismissDialogError();
                    }}
                    sx={{ mb: 4 }}
                >
                    {dialogErrorMessage || errorMessage}
                </Alert>
            </Collapse>

            <Paper elevation={0}>
                <ReservationTabs activeTab={activeTab} onChange={updateActiveTab} sx={{ mx: 6, mb: 4 }} />

                {activeTab === 'current' && (
                    <PanelWithZeroState
                        loading={loading}
                        shouldShowZeroState={currentReservations.length === 0}
                        zeroState={
                            <ZeroState
                                title={t('reservation_table.zero_state.current.title')}
                                message={t('reservation_table.zero_state.current.message')}
                            />
                        }
                    >
                        <ReservationAccordionList reservations={currentReservations} />
                    </PanelWithZeroState>
                )}

                {activeTab === 'upcoming' && (
                    <PanelWithZeroState
                        loading={loading}
                        shouldShowZeroState={upcomingReservations.length === 0}
                        zeroState={
                            <ZeroState
                                title={t('reservation_table.zero_state.upcoming.title')}
                                message={t('reservation_table.zero_state.upcoming.message')}
                            />
                        }
                    >
                        <ReservationAccordionList reservations={upcomingReservations} />
                    </PanelWithZeroState>
                )}

                {activeTab === 'past' && (
                    <PanelWithZeroState
                        loading={loading}
                        shouldShowZeroState={pastReservations.length === 0}
                        zeroState={
                            <ZeroState
                                title={t('reservation_table.zero_state.past.title')}
                                message={t('reservation_table.zero_state.past.message')}
                            />
                        }
                    >
                        <ReservationAccordionList reservations={pastReservations} />
                    </PanelWithZeroState>
                )}

                {activeTab === 'cancelled' && (
                    <PanelWithZeroState
                        loading={loading}
                        shouldShowZeroState={cancelledReservations.length === 0}
                        zeroState={
                            <ZeroState
                                title={t('reservation_table.zero_state.cancelled.title')}
                                message={t('reservation_table.zero_state.cancelled.message')}
                            />
                        }
                    >
                        <ReservationAccordionList reservations={cancelledReservations} />
                    </PanelWithZeroState>
                )}

                {activeTab === 'declined' && (
                    <PanelWithZeroState
                        loading={loading}
                        shouldShowZeroState={declinedReservations.length === 0}
                        zeroState={
                            <ZeroState
                                title={t('reservation_table.zero_state.declined.title')}
                                message={t('reservation_table.zero_state.declined.message')}
                            />
                        }
                    >
                        <ReservationAccordionList reservations={declinedReservations} />
                    </PanelWithZeroState>
                )}
            </Paper>
        </>
    );
}
