import { ComponentProps } from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import Input, { useInputForAutocomplete } from 'app/components/primitives/interactive/Input';

import { GetMoxionRepsQuery, useGetMoxionRepsQuery } from 'generated/graphql';

interface MoxionRepSelectorProps
    extends Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'value' | 'onChange' | 'sx'> {
    /**
     * The selected value is the userID
     */
    value?: string;

    /**
     * Event handler to be called when the value changes
     */
    onChange: (x: { value: { id: string; name: string } | null }) => void;
}

export default function MoxionRepSelector({
    id,
    fullWidth = false,
    fieldName,
    caption,
    required,
    valid,
    error,
    name,
    value,
    readOnly,
    disabled,
    placeholder,
    onChange = x => {},
    ...props
}: MoxionRepSelectorProps) {
    const renderInput = useInputForAutocomplete({
        fieldName,
        caption,
        required,
        error,
        valid,
        name,
        placeholder,
        readOnly,
        disabled,
        fullWidth,
        ...props,
    });

    const { data } = useGetMoxionRepsQuery();

    type userWithOptionalName = GetMoxionRepsQuery['listAllowedMoxionReps'][0];
    const _users: userWithOptionalName[] = data?.listAllowedMoxionReps ?? [];

    // NOTE(will): "User"."name" is now optional because of invited users.
    //
    // We should consider returning different types to the FE in cases where we *know* certain fields
    // . will / should be present e.g. "name" for an active user.
    type user = userWithOptionalName & { name: string };
    const usersWithNames = _users.filter(user => !!user.name) as user[];

    const usersByID = usersWithNames.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {} as { [key: string]: user });
    const options = usersWithNames.map(({ id }) => id);

    return (
        <Autocomplete<string, false, true>
            id={id}
            fullWidth={fullWidth}
            autoHighlight
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            disableClearable
            forcePopupIcon={!readOnly}
            isOptionEqualToValue={(option, currentUserID) => {
                return option === currentUserID;
            }}
            options={options}
            getOptionLabel={option => {
                return usersByID[option]?.name ?? '';
            }}
            renderInput={renderInput}
            renderOption={(props, id) => (
                <li {...props} key={id}>
                    {usersByID[id].name}
                    {usersByID[id].roles.length > 0 && ` – ${usersByID[id].roles[0].name}`}
                </li>
            )}
            onChange={(_event, newValue) => {
                const user = usersByID[newValue];
                onChange({ value: user ? { id: user.id, name: user.name } : null });
            }}
        />
    );
}
