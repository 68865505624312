import { DefaultPrivacyLevel } from '@datadog/browser-rum';

export function getGitSHA(): string {
    return process.env.REACT_APP_GIT_SHA ?? 'unknown';
}

export function getGitRef(): string {
    return process.env.REACT_APP_GIT_REF ?? 'unknown';
}

export function gmapsApiKey() {
    return process.env.REACT_APP_GMAPS_API_KEY;
}

export function mapTilerKey() {
    return process.env.REACT_APP_MAP_TILER_KEY;
}

export function getMapTilerStyle() {
    return `https://api.maptiler.com/maps/voyager/style.json?key=${mapTilerKey()}`;
}

export function getIsSignupEnabled() {
    const isSignupEnabled = process.env.REACT_APP_ENABLE_SIGNUP === 'true';
    return isSignupEnabled;
}

export function getGQLOrigin() {
    return process.env.REACT_APP_BASE_API;
}

export function getReactAppEnv() {
    return process.env.REACT_APP_ENV ?? 'dev';
}

export function getSupportEmail() {
    return 'support@moxionpower.com';
}

export function getSupportPhoneNumber() {
    return '833-669-4661';
}

export function getDatadogApplicationID() {
    return process.env.REACT_APP_DATADOG_APPLICATION_ID;
}

export function getDatadogClientToken() {
    return process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
}

export function getDatadogMaskUserInput(): DefaultPrivacyLevel {
    return (process.env.REACT_APP_DATADOG_PRIVACY_LEVEL as DefaultPrivacyLevel | undefined) ?? 'mask-user-input';
}
