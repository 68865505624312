import { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { array, boolean, date, number, object, ref, string } from 'yup';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import BusinessDateTimeRangePicker from 'app/components/primitives/interactive/BusinessDateTimeRangePicker';
import Button from 'app/components/primitives/interactive/Button';
import NumberField from 'app/components/primitives/interactive/NumberField';
import Textarea from 'app/components/primitives/interactive/Textarea';
import Text from 'app/components/primitives/Text';
import AccountSelector from 'app/components/widgets/AccountSelector';
import AccountUserSelector from 'app/components/widgets/AccountUserSelector';
import { DEFAULT_TIME_ZONE } from 'app/core/date-time';

import { getSupportEmail, getSupportPhoneNumber } from 'environment';

import { useI18n } from 'i18n';

import PickupDropoffSelector from './PickupDropoffSelector';

interface Location<T = any> {
    /**
     * Account location id for the pickup
     */
    pickupID: string | T;

    /**
     * Account location id for the dropoff
     */
    dropoffID: string | T;

    /**
     * Whether the pickup and dropoff locations should be kept in sync
     */
    isLinked: boolean | T;
}

/**
 * TODO(Morris): Refactor this so we don't have to set the empty value with a generic.
 */
export interface ReservationRequest<T = any> {
    /**
     * The account identifier to make the request on behalf of
     */
    accountID: string;

    /**
     * The user identifier to make the request on behalf of
     */
    userID: string;

    /**
     * The name for the reservatoin
     */
    reservationName: string;

    /**
     * The number of units to include in the reservation request
     */
    unitCount: number;

    /**
     * What day the units should be delivered
     *
     * TODO(Morris): Refactor to use plain ISO 8601 strings
     */
    startDate: DateTime | T;

    /**
     * What hour are the units needed by
     *
     * TODO(Morris): Consider refactoring to use an IS0 8601 time string instead.
     */
    startHour: number | T;

    /**
     * What timezone to use as reference for start time
     */
    startTimeZone: string | T;

    /**
     * What day the units should be picked up
     *
     * TODO(Morris): Refactor to use plain ISO 8601 strings
     */
    endDate: DateTime | T;

    /**
     * What hour are the units need to be picked up by
     *
     * TODO(Morris): Consider refactoring to use an IS0 8601 time string instead.
     */
    endHour: number | T;

    /**
     * What timezone to use as reference for end time
     */
    endTimeZone: string | T;

    /**
     * The associated delivery locations for each of the units
     */
    locations: Location<T>[];

    /**
     * General reservation notes for the request
     */
    notes: string;
}

interface Props {
    /**
     * Unique id identifying the form in the app
     */
    id?: string;

    /**
     * Whether to hide the CTA in the form. If true the submit button must be provided
     * elsewhere and associated to the form with the id prop.
     */
    hideCta?: boolean;

    /**
     * Whether to include account / user selection. To make a request on behalf of another user
     */
    enableAccountSelection?: boolean;

    /**
     * The minimum date to allow
     */
    minDate?: DateTime;

    /**
     * The account ID to submit a reservation on behalf of
     */
    accountID?: string | null | undefined;

    /**
     * The user to make the request on behalf of
     */
    userID?: string | null | undefined;

    /**
     * Event handler to be called on form submission
     */
    onSubmit: (event: { value: ReservationRequest }) => Promise<void> | void;

    onValidityChange?: (event: { isValid: boolean }) => void;
}

function fromLuxon(value, originalValue, context) {
    if (context.isType(value)) return value;

    const luxonDate = DateTime.fromJSDate(originalValue);

    return luxonDate?.isValid ? luxonDate.toJSDate() : new Date('');
}

function resetLocations(previouslySetLocations: Location[]): Location[] {
    return previouslySetLocations.map(x => ({ ...x, pickupID: null, dropoffID: null }));
}

const noop = () => {};

/**
 * Responsible for collecting user information to submit a reservation request for Moxion units
 */
export default function ReservationRequestForm({
    id,
    hideCta,
    accountID: _accountID,
    userID: _userID,
    enableAccountSelection,
    minDate,
    onSubmit,
    onValidityChange = noop,
}: Props): ReactElement {
    const { t } = useI18n();

    const [candidateDate, setCandidateDate] = useState<string | DateTime | null | undefined>();
    const [isSameLocation, setIsSameLocation] = useState<boolean>(true);

    const supportEmail = getSupportEmail();
    const supportPhoneNumber = getSupportPhoneNumber();

    const defaultMinDate = DateTime.now().startOf('day').plus({ day: 1 });
    const minStartDate = minDate ?? defaultMinDate;

    type FormValues = ReservationRequest<null>;
    /**
     * This type is necessary due to a bug in Formik where `useFormik` returns an `any` type
     * @see https://github.com/jaredpalmer/formik/issues/2023
     */
    type TFormik = ReturnType<typeof useFormik<FormValues>>;

    const formik: TFormik = useFormik<FormValues>({
        enableReinitialize: true,

        initialValues: {
            accountID: _accountID ?? '',
            userID: _userID ?? '',
            reservationName: '',
            unitCount: 1,
            startDate: null,
            startHour: null,
            startTimeZone: DEFAULT_TIME_ZONE,
            endDate: null,
            endHour: null,
            endTimeZone: DEFAULT_TIME_ZONE,
            locations: [{ pickupID: null, dropoffID: null, isLinked: true }],
            notes: '',
        },

        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        validationSchema: object().shape({
            accountID: enableAccountSelection ? string().required() : string().optional(),
            userID: enableAccountSelection
                ? string().required(t('renter_reservation_form.user_id_required'))
                : string().optional(),
            reservationName: string().optional(),
            unitCount: number().nullable().required(t('renter_reservation_form.unit_count_required')),
            startDate: date().transform(fromLuxon).min(minStartDate.toJSDate()).max(ref('endDate')).required(),
            startHour: number().required(),
            startTimeZone: string().required(),
            endDate: date()
                .transform(fromLuxon)
                // NOTE: .min() is not inclusive and will allow when the start and end date are the same :/
                // we don't set an error message here since the validation is done elsewhere...
                .test('isAfterStartDate', '', value => {
                    const { startDate, startHour, endHour } = formik.values;

                    if (!startDate || !value) return false;

                    const endDate = DateTime.fromJSDate(value);

                    if (endDate > startDate) return true;

                    if (startHour === null || endHour === null) return false;

                    return endDate.hasSame(startDate, 'day') && startHour < endHour;
                })
                .required(),

            endHour: number().required(),
            endTimeZone: string().required(),
            locations: array()
                .of(
                    object().shape({
                        pickupID: string().nullable().required(t('renter_reservation_form.pickup_location_required')),
                        dropoffID: string().nullable().required(t('renter_reservation_form.dropoff_location_required')),
                        isLinked: boolean(),
                    }),
                )
                .required(),
            notes: string().optional(),
        }),

        async onSubmit(values) {
            await onSubmit({ value: values });
        },
    });

    const { isValid } = formik;

    useEffect(() => {
        onValidityChange({ isValid });
    }, [onValidityChange, isValid]);

    const {
        userID,
        accountID,
        reservationName,
        unitCount,
        startDate,
        startHour,
        startTimeZone,
        endDate,
        endHour,
        endTimeZone,
        locations,
        notes,
    } = formik.values;

    function makeRentalDurationErrorText(): string {
        const hasRentalDurationError =
            !!formik.errors.startDate ||
            !!formik.errors.startHour ||
            !!formik.errors.startTimeZone ||
            !!formik.errors.endDate ||
            !!formik.errors.endHour ||
            !!formik.errors.endTimeZone;

        if (!!formik.touched.startDate && hasRentalDurationError) {
            return t('renter_reservation_form.rental_duration_required');
        }

        return '';
    }

    function getLocationError(index, key) {
        return formik.touched.locations?.[index]?.[key]
            ? /** @ts-ignore formik fail*/
              formik.errors?.locations?.[index]?.[key]
            : '';
    }

    return (
        <Box>
            <form id={id} onSubmit={formik.handleSubmit}>
                <Box mb={4}>
                    <Text as="p" mb={1}>
                        {t('renter_reservation_form.name_label')}
                    </Text>
                    <TextField
                        name="reservationName"
                        placeholder={t('renter_reservation_form.name_placeholder')}
                        value={reservationName}
                        onChange={formik.handleChange}
                        inputProps={{ maxLength: 50 }}
                    />
                </Box>

                {enableAccountSelection && (
                    <Box mb={6}>
                        <Text as="p" mb={2}>
                            {t('renter_reservation_form.account_and_user_label')}
                        </Text>

                        <Stack spacing={3} maxWidth="sm">
                            <AccountSelector
                                fullWidth
                                fieldName="Account"
                                placeholder="Account Name"
                                name="accountID"
                                value={accountID ?? ''}
                                onChange={x => {
                                    formik.setFieldValue('accountID', x.value?.id);
                                    formik.setFieldValue('locations', resetLocations(locations));
                                    formik.setFieldValue('userID', '');
                                }}
                            />

                            <AccountUserSelector
                                fieldName="User"
                                caption={formik.errors.userID}
                                error={!!formik.errors.userID}
                                placeholder="User Name"
                                required
                                fullWidth
                                accountID={accountID ?? ''}
                                name="userID"
                                value={userID ?? ''}
                                onChange={x => {
                                    formik.setFieldValue('userID', x.value?.id ?? '');
                                }}
                                onBlur={() => {
                                    formik.setFieldTouched('userID');
                                    formik.validateField('userID');
                                }}
                            />
                        </Stack>
                    </Box>
                )}

                <Box mb={4}>
                    <Text as="p" mb={1}>
                        {t('renter_reservation_form.unit_count_label')}
                    </Text>
                    <NumberField
                        required
                        min={1}
                        error={!!formik.touched.unitCount && !!formik.errors.unitCount}
                        caption={formik.touched.unitCount ? formik.errors?.unitCount : ''}
                        value={unitCount}
                        onBlur={() => formik.setFieldTouched('unitCount')}
                        onChange={({ value }) => {
                            formik.setFieldValue('unitCount', value, true);

                            if (value && locations.length > value) {
                                formik.setFieldValue('locations', locations.slice(0, value), true);
                            } else if (value && locations.length < value) {
                                const nullLocation = {
                                    pickupID: null,
                                    dropoffID: null,
                                    isLinked: true,
                                };
                                const firstLocation = locations[0] ?? nullLocation;

                                const newLocations = new Array(value - locations.length).fill(
                                    isSameLocation ? firstLocation : nullLocation,
                                );

                                formik.setFieldValue('locations', locations.concat(newLocations), true);
                            }
                        }}
                    />
                </Box>

                <Box mb={4}>
                    <Text as="p" mb={1}>
                        {t('renter_reservation_form.duration_label')}
                    </Text>
                    <BusinessDateTimeRangePicker
                        name="rentalDuration"
                        minDate={minStartDate}
                        // We let the minDate handle disabling days in the date range picker in this case
                        disablePast={false}
                        candidate={candidateDate}
                        start={startDate}
                        startHour={startHour}
                        startTimeZone={startTimeZone ?? undefined}
                        end={endDate}
                        endHour={endHour}
                        endTimeZone={endTimeZone ?? undefined}
                        caption={makeRentalDurationErrorText()}
                        hasStartDateError={!!formik.touched.startDate && !!formik.errors.startDate}
                        hasStartTimeError={!!formik.touched.startHour && !!formik.errors.startHour}
                        hasEndDateError={!!formik.touched.endDate && !!formik.errors.endDate}
                        hasEndTimeError={!!formik.touched.endHour && !!formik.errors.endHour}
                        onBlur={() => {
                            // Here we're checking for `null` or `undefined` for each field's value, because
                            // the `onChange` handler sets the value for every field belonging to the date-time
                            // range at the same time. As a result, validation is triggered for each field,
                            // even if the user hasn't interacted with it.
                            //
                            // This occurs, because Formik performs a strict value comparison that's doesn't recognize
                            // the implicit equality of `DateTime` instances. In turn, new `DateTime` instances are created
                            // for each change due to the conversion to and from `DateTime` and ISO 8601 strings
                            // within the `BusinessDateTimeRangePicker` component.
                            //
                            // As a result, when the user changes any date-time range field for the first time,
                            // a validation error will always appear, even though they've not interacted with the field.
                            //
                            // This issue will go away after this form is refactored to use ISO 8601 strings,
                            // and Formik can perform a strict value comparison on strings instead of `DateTime` instances.
                            //
                            // TODO(Morris): Refactor form to use plain ISO 8601 strings

                            if (formik.values.startDate != null) {
                                formik.setFieldTouched('startDate');
                            }
                            if (formik.values.startHour != null) {
                                formik.setFieldTouched('startHour');
                            }
                            if (formik.values.endDate != null) {
                                formik.setFieldTouched('endDate');
                            }
                            if (formik.values.endHour != null) {
                                formik.setFieldTouched('endHour');
                            }
                        }}
                        onChange={({ value }) => {
                            formik.setFieldValue('startDate', value.start, true);
                            formik.setFieldValue('startHour', value.startHour, true);
                            formik.setFieldValue('startTimeZone', value.startTimeZone, true);

                            formik.setFieldValue('endDate', value.end, true);
                            formik.setFieldValue('endHour', value.endHour, true);
                            formik.setFieldValue('endTimeZone', value.endTimeZone, true);

                            setCandidateDate(value.candidate);
                        }}
                    />
                </Box>

                <Collapse in={unitCount > 1}>
                    <Box mb={4}>
                        <Text as="p" mb={1}>
                            {t('renter_reservation_form.same_location_label')}
                        </Text>
                        <RadioGroup
                            sx={{ flexDirection: 'row', gap: 4 }}
                            name="isSameLocation"
                            value={isSameLocation ? 'yes' : 'no'}
                            onChange={event => {
                                const isSameLocation = event?.target?.value === 'yes';

                                if (isSameLocation) {
                                    formik.setFieldValue(
                                        'locations',
                                        locations.map(x => locations[0]),
                                    );
                                }

                                setIsSameLocation(isSameLocation);
                            }}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Box>
                </Collapse>

                <Box mb={3}>
                    {isSameLocation && (
                        <PickupDropoffSelector
                            accountID={accountID}
                            {...locations[0]}
                            pickupError={getLocationError(0, 'pickupID')}
                            dropoffError={getLocationError(0, 'dropoffID')}
                            onPickupBlurred={() => formik.setFieldTouched('locations[0].pickupID')}
                            onDropoffBlurred={() => formik.setFieldTouched('locations[0].dropoffID')}
                            unitCount={unitCount}
                            isSameLocation={isSameLocation}
                            onChange={({ value }) => {
                                formik.setFieldValue(
                                    'locations',
                                    locations.map(x => value),
                                );
                            }}
                        />
                    )}

                    {!isSameLocation && (
                        <Stack divider={<Divider />} spacing={5}>
                            {locations.map((location, index) => (
                                <PickupDropoffSelector
                                    key={index}
                                    accountID={accountID}
                                    {...location}
                                    unitNumber={index}
                                    isSameLocation={isSameLocation}
                                    pickupError={getLocationError(index, 'pickupID')}
                                    dropoffError={getLocationError(index, 'dropoffID')}
                                    onPickupBlurred={() => formik.setFieldTouched(`locations[${index}].pickupID`)}
                                    onDropoffBlurred={() => formik.setFieldTouched(`locations[${index}].dropoffID`)}
                                    onChange={({ value }) => {
                                        const newLocations = [...locations];

                                        newLocations[index] = {
                                            dropoffID: value.dropoffID ?? null,
                                            pickupID: value.pickupID ?? null,
                                            isLinked: value.isLinked ?? null,
                                        };

                                        formik.setFieldValue('locations', newLocations);
                                    }}
                                />
                            ))}
                        </Stack>
                    )}
                </Box>

                <Box mb={4}>
                    <Text as="p" mb={1}>
                        {t('renter_reservation_form.notes_label')}
                    </Text>
                    <Textarea
                        fullWidth
                        name="notes"
                        placeholder={t('renter_reservation_form.notes_placeholder')}
                        value={notes}
                        rows={6}
                        onChange={formik.handleChange}
                    />
                </Box>

                {!hideCta && (
                    <Box mb={4}>
                        <Button type="submit" disabled={!formik.isValid}>
                            {t('renter_reservation_form.request_reservation_cta')}
                        </Button>
                    </Box>
                )}

                <Text
                    variant="detail"
                    as="p"
                    renderLink={({ id, text }) => {
                        const href = id === 'supportEmail' ? `mailto:${supportEmail}` : `tel:${supportPhoneNumber}`;

                        return <Link href={href}>{text}</Link>;
                    }}
                >
                    {t('renter_reservation_form.get_help_message', {
                        email: supportEmail,
                        phoneNumber: supportPhoneNumber,
                    })}
                </Text>
            </form>
        </Box>
    );
}
