import Button from 'app/components/primitives/interactive/Button';
import Text from 'app/components/primitives/Text';

import { useI18n } from 'i18n';

import { useLayer } from './LayerProvider';
import { LayerItemFacade } from './types';

/**
 * Responsible for presenting the user with a choice and triggering logic for confirming or denying that choice
 * Similiar to window.confirm
 *
 * @example
 *   const confirm = useConfirm();
 *
 *   confirm({
 *      title: "Confirm Title",
 *      message: "Would you like to proceed?"
 *      onConfirm: doSomething,
 *   });
 *
 */
export default function useConfirm(): (props: {
    title?: string;
    message: string;
    confirmLabel?: string;
    denyLabel?: string;
    onConfirm: (event: { close: () => void }) => void;
    onDeny?: (event: { close: () => void }) => void;
}) => LayerItemFacade {
    const { dialog } = useLayer();
    const { t } = useI18n();

    return function confirm({
        title,
        message,
        confirmLabel = t('cta.confirm'),
        denyLabel = t('cta.cancel'),
        onConfirm,
        onDeny,
    }) {
        return dialog.add(item => {
            const onClickConfirm = () => {
                onConfirm({ close: item.close.bind(item) });
            };
            const onClickDeny = () => {
                onDeny?.({ close: item.close.bind(item) });

                if (typeof onDeny !== 'function') {
                    item.close();
                }
            };

            return {
                title,
                children: <Text>{message}</Text>,
                primaryCTA: <Button onClick={onClickConfirm}>{confirmLabel}</Button>,
                tertiaryCTA: <Button onClick={onClickDeny}>{denyLabel}</Button>,
            };
        });
    };
}
