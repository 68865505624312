import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime, Duration } from 'luxon';

import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { useNowPoller } from 'app/core/data';
import { ISO8601 } from 'app/core/types';

import {
    DeviceInstancePaginationFilters,
    ListDeviceInstancesQueryResult,
    useListDeviceInstancesQuery,
    useListLatestMpuLocationsQuery,
} from 'generated/graphql';

import { useI18n } from 'i18n';

import DataGridToGQLAdapter from './DataGridToGQLAdapter';
import toMPUData from './toMPUData';
import { MPU } from './types';

/**
 * an outlandishly large limit, to return all results
 */
const FALLBACK_PAGINATION_LIMIT = 1_000_000;

interface Input {
    /**
     * Whether to retrieve rental related fields
     */
    enableRentalManagement?: boolean;

    /**
     * The accounts to retrieve MPUs for. Passing an empty array or nothing at all will
     * result in trying to fetch all MPUs and may fail if the user does not have the proper
     * permissions
     */
    ownerAccountIDs?: string[];

    /**
     * the number of results to return
     */
    limit?: number;

    /**
     * the pagination cursor to start from a.k.a. 'after' in relay parlance
     */
    cursor?: string;

    /**
     * sort data from the data grid
     */
    sortModel?: GridSortModel;

    /**
     * filter data from the data grid
     */
    filterModel?: GridFilterModel;

    filter?: DeviceInstancePaginationFilters;
}

interface Result extends Pick<ListDeviceInstancesQueryResult, 'loading' | 'error'> {
    lastUpdateTimestamp: ISO8601 | null;

    refetch: () => Promise<void>;

    data: {
        mpus: MPU[];

        /**
         * The total unit count
         */
        totalCount: number;

        /**
         * The unit count with known locations
         */
        shownUnitCount: number;
    };

    /**
     * Fetches next page of paginated data from server
     */
    fetchNextPage: () => void;

    /**
     * Fetches previous page of paginated data from server
     */
    fetchPrevPage: () => void;

    /**
     * Whether there is a next page of data that may be fetched
     */
    hasNextPage: boolean;

    /**
     * Index of page in paginated data set
     */
    pageIndex: number;

    /**
     * Resets the pagination to the first page
     */
    resetPageIndex: () => void;
}

const UPDATE_INTERVAL = Duration.fromObject({ minutes: 5 }).toMillis();

export default function useMPUs({
    enableRentalManagement,
    ownerAccountIDs = [],
    limit = FALLBACK_PAGINATION_LIMIT,
    cursor,
    sortModel,
    filterModel,
    filter,
}: Input = {}): Result {
    const { t } = useI18n();
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<ISO8601 | null>(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [isRefetching, setIsRefetching] = useState(false);

    const now = useNowPoller({ pollInterval: UPDATE_INTERVAL });

    const resetPageIndex = useCallback(() => setPageIndex(0), []);

    // cannot jump to a given page, so reset on sort/filter change
    useEffect(resetPageIndex, [filterModel, resetPageIndex, sortModel]);

    // DataGrid Filters
    // IMPORTANT(will): ownerAccountIDs must not be overwritten by the results of the DataGridToGQLAdapter's paginationVariables
    const filters = DataGridToGQLAdapter.getGqlFilter(filterModel, { ownerAccountIDs });

    // Filters Feature
    if (ownerAccountIDs.length > 0 && filter) {
        filter.ownerAccountIDs = ownerAccountIDs;
    }

    const paginationVariables = {
        first: limit,
        after: cursor,
        sortBy: DataGridToGQLAdapter.getGqlSortBy(sortModel),
        start: now.toISO() ?? '', // NOTE(will): why is start here?
        end: now.plus({ second: 1 }).toISO() ?? '', // NOTE(will): why is end here?
        filters: filter ?? filters, // @DEAN: TODO, clean this up when extracting DataGridFilters
    };

    const {
        loading,
        error,
        data,
        refetch: refetchBasics,
    } = useListDeviceInstancesQuery({
        variables: {
            ...paginationVariables,
        },
        pollInterval: UPDATE_INTERVAL,
        onCompleted: () => {
            setLastUpdateTimestamp(DateTime.now().toISO());
        },
    });

    const { data: mpuLocations, refetch: refetchLocations } = useListLatestMpuLocationsQuery({
        variables: { ownerAccountIDs },
        pollInterval: UPDATE_INTERVAL,
    });

    const locations = mpuLocations?.listDeviceLocations;
    const deviceInstances = data?.listDeviceInstances.edges;

    const mpus = useMemo(() => {
        const locationsByID = (locations ?? []).reduce((acc, cur) => {
            return {
                ...acc,
                [cur.deviceID]: cur,
            };
        }, {});

        return (deviceInstances ?? []).map(({ node: mpu }) => ({
            ...mpu,
            latestDeviceLocation: {
                // these were fetched in batch but shim here as if via deviceInstance
                lat: locationsByID[mpu.id]?.lat,
                lon: locationsByID[mpu.id]?.lon,
                time: locationsByID[mpu.id]?.time,
            },
            ...locationsByID[mpu.id],
        }));
    }, [deviceInstances, locations]);

    const { endCursor, hasNextPage, hasPreviousPage, startCursor } = data?.listDeviceInstances.pageInfo ?? {};

    const fetchNextPage = useCallback(
        async function fetchNextPage() {
            if (isRefetching ?? !hasNextPage) return;

            const nextCursor = endCursor;

            setIsRefetching(true);

            await refetchBasics({
                last: undefined,
                before: undefined,
                first: limit,
                after: nextCursor,
            });

            setIsRefetching(false);

            setPageIndex(pageIndex + 1);
            setLastUpdateTimestamp(DateTime.now().toISO());
        },
        [hasNextPage, endCursor, isRefetching, limit, pageIndex, refetchBasics],
    );

    const fetchPrevPage = useCallback(
        async function fetchPrevPage() {
            if (isRefetching ?? !hasPreviousPage) return;

            const prevCursor = startCursor;

            setIsRefetching(true);

            await refetchBasics({
                last: limit,
                before: prevCursor,
                first: undefined,
                after: undefined,
            });

            setIsRefetching(false);

            setPageIndex(pageIndex - 1);
            setLastUpdateTimestamp(DateTime.now().toISO());
        },
        [hasPreviousPage, startCursor, isRefetching, limit, pageIndex, refetchBasics],
    );

    const refetch = useCallback(async () => {
        await Promise.all([refetchBasics(), refetchLocations()]);

        setLastUpdateTimestamp(DateTime.now().toISO());
    }, [refetchBasics, refetchLocations]);

    const mpuData = useMemo(() => {
        return {
            ...toMPUData(mpus, {
                t,
                detailPath: 'MPU_DETAIL',
            }),
            totalCount: data?.listDeviceInstances.pageInfo.totalEdges ?? 0,
        };
    }, [data, mpus, t]);

    return {
        loading: loading || isRefetching,
        error,
        refetch,
        lastUpdateTimestamp,
        // TODO(Derek): Unify Unit detail pages
        data: mpuData,
        fetchNextPage,
        fetchPrevPage,
        hasNextPage: !!hasNextPage,
        pageIndex,
        resetPageIndex,
    };
}
