import { ApolloClient, createHttpLink, from, InMemoryCache, Operation } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getGQLOrigin } from 'environment';

interface MakeApolloClientOptions {
    onGQLErrors?: (params: { graphQLErrors: GraphQLErrors; operation: Operation }) => void;
}

export default function makeApolloClient({ onGQLErrors = () => {} }: MakeApolloClientOptions = {}) {
    const httpLink = createHttpLink({
        uri: `${getGQLOrigin()}/graphql`,

        // NOTE(will): needed to make cross-origin requests; since our app and our api are separate subdomains I *believe* we need this.
        // see: https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
        // see: https://www.apollographql.com/docs/react/networking/authentication#cookie
        credentials: 'include',
    });

    const errorLink = onError(({ graphQLErrors, operation }) => {
        if (!!graphQLErrors) {
            onGQLErrors({ graphQLErrors, operation });
        }
    });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
            },
        };
    });

    const client = new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        defaultOptions: {
            watchQuery: {
                initialFetchPolicy: 'network-only',
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
        },

        cache: new InMemoryCache(),
    });

    return client;
}
