import { MouseEventHandler, ReactElement } from 'react';
import { To } from 'react-router-dom';

import Box from '@mui/material/Box';

import { Link } from 'app/components/primitives/Link';
import Text from 'app/components/primitives/Text';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import { MpuScheduleEventSchedule } from '../types';

interface MpuScheduleTooltipContentProps {
    end: ISO8601 | undefined;
    heading: string;
    onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    serviceAreaName: string | null | undefined;
    start: ISO8601 | undefined;
    to?: To;
}

function MpuScheduleTooltipContent({
    end,
    heading,
    onLinkClick,
    serviceAreaName,
    start,
    to,
}: MpuScheduleTooltipContentProps): ReactElement | null {
    const { t, format } = useI18n();

    return (
        <Box>
            <Link onClick={onLinkClick} to={to ?? '#'} sx={{ color: 'inherit', textDecorationColor: 'inherit' }}>
                <Text variant="detailMedium" as="h4">
                    {heading}
                </Text>
            </Link>
            <Text variant="detail" as="div">
                {t('mpu_schedule.tooltip.dates')}: {format.dateRangeSentence({ end, start })}
            </Text>
            <Text variant="detail" as="div">
                {t('mpu_schedule.tooltip.service_area')}: {serviceAreaName || t('unknown')}
            </Text>
        </Box>
    );
}

interface TooltipContentPresenterProps {
    onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    schedule: MpuScheduleEventSchedule;
}

export function ReservedTooltip({ onLinkClick, schedule }: TooltipContentPresenterProps): ReactElement | null {
    const { t } = useI18n();
    const { end, reservationItem, start } = schedule;
    const { reservation } = reservationItem ?? {};
    const { name, sequentialID, serviceArea } = reservation ?? {};
    const heading = t('mpu_schedule.tooltip.reservation', { name, sequentialID });

    return (
        <MpuScheduleTooltipContent
            end={end}
            heading={heading}
            onLinkClick={onLinkClick}
            serviceAreaName={serviceArea?.name}
            start={start}
            to={schedule.link}
        />
    );
}

export function ChargingTooltip({ onLinkClick, schedule }: TooltipContentPresenterProps): ReactElement | null {
    const { t } = useI18n();
    const { reservationItem } = schedule;
    const { end, start } = schedule;
    const { reservation } = reservationItem ?? {};
    const { sequentialID, serviceArea } = reservation ?? {};
    const heading = t('mpu_schedule.tooltip.charging', { sequentialID });

    return (
        <MpuScheduleTooltipContent
            end={end}
            heading={heading}
            onLinkClick={onLinkClick}
            serviceAreaName={serviceArea?.name}
            start={start}
            to={schedule.link}
        />
    );
}

export function DowntimeTooltip({ schedule, onLinkClick }: TooltipContentPresenterProps): ReactElement | null {
    const { t } = useI18n();
    const { end, reservationItem, start } = schedule;
    const { serviceArea } = reservationItem?.reservation ?? {};

    return (
        <MpuScheduleTooltipContent
            end={end}
            heading={t('mpu_schedule.tooltip.downtime')}
            onLinkClick={onLinkClick}
            serviceAreaName={serviceArea?.name}
            start={start}
            to={schedule.link}
        />
    );
}

/**
 * TODO(Morris): Add "to" and "from" locations when the data is available on the entity.
 * Locations attached to the reservation item aren't applicable.
 */
export function TransitTooltip({ onLinkClick, schedule }: TooltipContentPresenterProps): ReactElement | null {
    const { t } = useI18n();
    const { end, reservationItem, start } = schedule;
    const { serviceArea } = reservationItem?.reservation ?? {};

    return (
        <MpuScheduleTooltipContent
            end={end}
            heading={t('mpu_schedule.tooltip.transit')}
            onLinkClick={onLinkClick}
            serviceAreaName={serviceArea?.name}
            start={start}
            to={schedule.link}
        />
    );
}

export function UnknownScheduleTooltip({ onLinkClick, schedule }: TooltipContentPresenterProps): ReactElement | null {
    const { t } = useI18n();
    const { end, reservationItem, start } = schedule;
    const { serviceArea } = reservationItem?.reservation ?? {};

    return (
        <MpuScheduleTooltipContent
            end={end}
            heading={t('unknown')}
            onLinkClick={onLinkClick}
            serviceAreaName={serviceArea?.name}
            start={start}
            to={schedule.link}
        />
    );
}
