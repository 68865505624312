import { ComponentProps, ReactElement } from 'react';

import { ContentProps } from 'app/components/compounds/MultiGrid';
import { ScheduleInitialDateButton, ScheduleToolbarWrapper } from 'app/components/compounds/Schedule';
import { Button, Icon } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { UnitAssignmentItemData } from './types';

/**
 * TODO(Morris): This doesn't match the initial design for simplicity sake
 * for both the user and implementation.
 *
 * The design calls for a link to return the user to the previous screen outside
 * of the component at the bottom of the page. This should be revisited.
 */
function CancelButton({ onClick }: ComponentProps<typeof Button>): ReactElement {
    const { t } = useI18n();

    return (
        <Button color="utility" ctaType="secondary" icon={<Icon name="chevron-back" />} onClick={onClick}>
            {t('unit_assignment_schedule.cancel')}
        </Button>
    );
}

interface UnitAssignmentScheduleToolbarLeftProps extends ContentProps<UnitAssignmentItemData> {
    /**
     * A callback to be invoked when the assignment is cancelled.
     */
    onCancel?: () => void;
}

/**
 * Left toolbar for the `UnitAssignmentSchedule` component.
 */
export function UnitAssignmentToolbarLeft({ onCancel }: UnitAssignmentScheduleToolbarLeftProps): ReactElement {
    return (
        <ScheduleToolbarWrapper variant="left">
            <CancelButton onClick={onCancel} />
            <ScheduleInitialDateButton />
        </ScheduleToolbarWrapper>
    );
}
