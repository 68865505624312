import { ReactElement } from 'react';
import { t } from 'i18next';

import Box from '@mui/material/Box';

import { Text } from 'app/components/primitives';

import CSSDimension from 'design-system/CSSDimension';

import CopyTooltip from '../CopyTooltip';

interface MPUTileSerialNumberProps {
    /**
     * The serial number of the MPU.
     *
     * @example
     *
     * "ABF0000000007845023"
     */
    serialNumber?: string;
}

const detailTextLineHeight = CSSDimension.fromPixels(20).as('rem');

export function MPUTileSerialNumber({ serialNumber }: MPUTileSerialNumberProps): ReactElement | null {
    if (!serialNumber) return null;

    return (
        <CopyTooltip
            content={<CopyTooltip.TextContent heading={t('mpu_tile.tooltip.external_id')} description={serialNumber} />}
            textToCopy={serialNumber}
        >
            <Box
                dir="rtl"
                sx={{
                    height: detailTextLineHeight,
                    lineHeight: detailTextLineHeight,
                    minWidth: '8ch',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                <Text color="secondary" variant="detail">
                    {serialNumber}
                </Text>
            </Box>
        </CopyTooltip>
    );
}
