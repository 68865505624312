import { FocusEventHandler, ReactElement, ReactNode, useCallback } from 'react';

import { useSession } from 'app/core/Session';

import { useListRolesQuery } from 'generated/graphql';

import { MUIMarginProps } from 'styles/theme/types';

import CompactRoleSelector from './CompactRoleSelector';
import InlineRoleSelector from './InlineRoleSelector';

interface Props extends MUIMarginProps {
    /**
     * The presentational variant for role selection / assignement
     */
    variant?: 'inline' | 'compact';

    /**
     * The form control label
     */
    fieldName?: ReactNode;

    /**
     * Additional helper text or inline errors
     */
    caption?: ReactNode;

    /**
     * The general name describing what the value represents
     */
    name?: string;

    /**
     * The selected value
     */
    value?: string[];

    /**
     * The ID of the form to include the control into when rendered outside of the form
     */
    form?: string;

    /**
     * Whether the role selection is in an error state
     */
    error?: boolean;

    /**
     * Whether the roles field is required
     */
    required?: boolean;

    /**
     * Whether to disable the control
     */
    disabled?: boolean;

    /**
     * Whether to render the input as read only
     */
    readOnly?: boolean;

    /**
     * Event handler to be called when the value changes
     */
    onChange?: (event: { name?: string; value: string[] }) => void;

    /**
     * Event handler to be called when the control is blurred
     */
    onBlur?: FocusEventHandler<HTMLElement>;
}

/**
 * Responsible for surfacing the roles available to assign to a User
 */
export default function RoleSelector({
    variant = 'inline',
    fieldName,
    caption,
    name,
    value,
    disabled,
    readOnly,
    error,
    required,
    onChange,
    onBlur,
}: Props): ReactElement {
    const { id: userID } = useSession().getSession();

    const { data } = useListRolesQuery({ variables: { grantorUserID: userID } });

    const allUserRoles = (data?.listGrantableRoles ?? []).map(x => x.name).filter(x => !!x);

    const handleChange = useCallback(
        ({ name: rolesName, value: newValue }: { name?: string; value: string[] }) => {
            onChange?.({
                name: rolesName,
                value: newValue,
            });
        },
        [onChange],
    );

    if (variant === 'compact' || readOnly) {
        return (
            <CompactRoleSelector
                options={allUserRoles}
                fieldName={fieldName}
                caption={caption}
                name={name}
                value={value}
                error={error}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                onChange={handleChange}
                onBlur={onBlur}
            />
        );
    }

    return (
        <InlineRoleSelector
            options={allUserRoles}
            fieldName={fieldName}
            caption={caption}
            name={name}
            value={value}
            error={error}
            required={required}
            disabled={disabled}
            onChange={handleChange}
            onBlur={onBlur}
        />
    );
}
