import { createContext, useContext, useState } from 'react';

import Button from '@mui/material/Button';

import { ConfirmDialog } from 'app/components';

const DialogContext = createContext<{
    errorMessage: string | undefined;
    dismissError: () => void;
    showDialog: (title: string, message: string, onConfirmClick: () => void) => void;
}>({
    errorMessage: '',
    dismissError: () => undefined,
    showDialog: () => undefined,
});

interface DialogProviderProps {
    children: React.ReactNode;
}

export function DialogProvider({ children }: DialogProviderProps) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [onDialogConfirmClick, setOnDialogConfirmClick] = useState<() => void>(() => undefined);
    const [errorMessage, setErrorMessage] = useState<string>();

    function showDialog(title: string, message: string, onConfirmClick: () => void) {
        setDialogTitle(title);
        setDialogMessage(message);
        setOnDialogConfirmClick(() => async () => {
            try {
                await onConfirmClick();
            } catch (error) {
                if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else if (typeof error === 'string') {
                    setErrorMessage(error);
                }
            }
        });
        setIsDialogOpen(true);
    }

    return (
        <DialogContext.Provider
            value={{
                errorMessage,
                dismissError: () => setErrorMessage(undefined),
                showDialog,
            }}
        >
            {children}
            <ConfirmDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title={dialogTitle}
                message={dialogMessage}
                confirmCta={<Button onClick={onDialogConfirmClick}>{dialogTitle}</Button>}
            />
        </DialogContext.Provider>
    );
}

export function useDialogContext() {
    return useContext(DialogContext);
}
