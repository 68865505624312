import { Alert } from 'app/core/Alert/types';

import { AlertFilter } from './config';
import { FieldFilterCriterion, FilterCriteria, IFilterStorage } from './types';

function getFilterCriteriaTags(filters: FilterCriteria): FieldFilterCriterion[] {
    const seed: FieldFilterCriterion[] = [];

    return Object.keys(filters).reduce((acc, cur) => {
        const value = filters[cur];

        return acc.concat(
            value.map(x => ({
                field: cur,
                id: x.id,
                label: x.label,
            })),
        );
    }, seed);
}

function filter({ filters, predicate }): boolean {
    if (!filters || filters?.length === 0) return true;

    return !!filters?.find(f => predicate(f.id));
}

function isFiltered({ filters, alert }): boolean {
    return (
        filter({
            filters: filters?.alertSeverities,
            predicate: id => new RegExp(`^${id}$`, 'i').test(alert?.severity),
        }) &&
        filter({ filters: filters?.reservationIDs, predicate: id => id === alert?.reservation?.id }) &&
        filter({
            filters: filters?.rentalAccountIDs,
            predicate: id => id === alert?.reservation?.requesterAccountID,
        }) &&
        filter({
            filters: filters?.serviceAreaIDs,
            predicate: id =>
                id === 'unassigned'
                    ? alert?.reservation?.serviceAreaID == null
                    : id === alert?.reservation?.serviceAreaID,
        })
    );
}

/**
 * Provides an API for updating filter criteria for alerts
 */
export default function useAlertsFilter({ filterStorage }: { filterStorage: IFilterStorage }): {
    activeFilter: string;
    customFilterCriteria: FilterCriteria;
    customFieldFilterCriteria: FieldFilterCriterion[];

    setActiveFilter: (activeFilterName: string) => void;
    resetFilter: () => void;
    removeFilterCriterion: (f: Pick<FieldFilterCriterion, 'field' | 'id'>) => void;
    commitCustomFilterCriteria: (fc: FilterCriteria) => void;
    filterAlerts: (a: Alert[]) => Alert[];
} {
    const activeFilter = filterStorage.getActiveFilter();
    const customFilterCriteria = filterStorage.getCustomFilterCriteria();
    const isCustomFilterActive = activeFilter === AlertFilter.custom;

    const allFilters = getFilterCriteriaTags(customFilterCriteria);
    const filterLength = allFilters.length;

    const resetFilter = () => {
        filterStorage.save({ activeFilter: AlertFilter.all });
    };

    const setQuickFilter = filterID => {
        filterStorage.save({
            activeFilter: filterID,
        });
    };

    const removeFilterCriterion = ({ field, id }: Pick<FieldFilterCriterion, 'field' | 'id'>) => {
        const filterField = customFilterCriteria[field];
        const newValue = filterField.filter(y => y.id !== id);

        const updatedFilters = {
            ...customFilterCriteria,
            [field]: newValue,
        };

        filterStorage.save({
            activeFilter: filterLength === 1 ? AlertFilter.all : AlertFilter.custom,
            customFilterCriteria: updatedFilters,
        });
    };

    const commitCustomFilterCriteria = filterCriteria => {
        filterStorage.save({
            activeFilter: AlertFilter.custom,
            customFilterCriteria: filterCriteria,
        });
    };

    const filterAlerts = (alerts: Alert[]): Alert[] => {
        return alerts.filter(alert => {
            if (activeFilter === AlertFilter.all) return true;

            if (activeFilter === AlertFilter.critical) return /^critical$/i.test(alert?.severity);

            if (activeFilter === AlertFilter.custom) {
                return isFiltered({ filters: customFilterCriteria, alert: alert });
            }

            return true;
        });
    };

    return {
        activeFilter,
        customFilterCriteria,
        customFieldFilterCriteria: isCustomFilterActive ? allFilters : [],

        setActiveFilter: setQuickFilter,
        resetFilter,
        removeFilterCriterion,
        commitCustomFilterCriteria,
        filterAlerts,
    };
}
