import { Deferred } from 'ts-deferred';

import { DateTimeSpan } from 'app/core/types';

import { TimeConverter } from './TimeConverter';
import {
    ScheduleCellCoordinates,
    ScheduleCellDetails,
    ScheduleCellDetailsAccessor,
    ScheduleCellLayer,
    ScheduleColumnMarker,
    ScheduleColumnRange,
    ScheduleConfig,
    ScheduleItemMapKey,
} from './types';

function getEventColumnIndexes(
    timeConverter: TimeConverter,
    event: DateTimeSpan,
): { startColumnIndex: number; endColumnIndex: number } {
    const { end, start } = event;
    const startColumnIndex = timeConverter.startTimestampToColumnIndex(start);
    const endColumnIndex = timeConverter.endTimestampToColumnIndex(end);

    return { startColumnIndex, endColumnIndex };
}

interface GetCellLayersParams {
    config: Pick<
        ScheduleConfig,
        'daysDisplayed' | 'events' | 'futureLength' | 'historyLength' | 'initialDate' | 'getCellAvailability'
    >;
    coords: ScheduleCellCoordinates;
    timeConverter: TimeConverter;
}

async function getCellLayers({
    config,
    coords: cellCoords,
    timeConverter,
}: GetCellLayersParams): Promise<ScheduleCellLayer[]> {
    const { daysDisplayed, events, futureLength, historyLength, initialDate, getCellAvailability } = config;
    const { columnIndex: cellColumnIndex, rowIndex: cellRowIndex } = cellCoords;
    const cellLayers: ScheduleCellLayer[] = [];
    const columnLayers: ScheduleCellLayer[] = [];

    for (const event of events) {
        const {
            color,
            expandedOffset,
            hasTail: eventHasTail,
            iconName,
            id: eventId,
            isEndCentered,
            isStartCentered,
            rowIndex: eventRowIndex,
            secondaryColor,
            variant,
        } = event;

        const isCellWithinEventRow = cellRowIndex === eventRowIndex;
        const { endColumnIndex, startColumnIndex } = getEventColumnIndexes(timeConverter, event);
        const isColumnWithinEvent = cellColumnIndex >= startColumnIndex && cellColumnIndex < endColumnIndex;

        const layer: ScheduleCellLayer = {
            color,
            eventEndColumnIndex: endColumnIndex,
            eventHasTail,
            eventId: eventId,
            eventStartColumnIndex: startColumnIndex,
            expandedOffset,
            iconName,
            isEventEndCentered: isEndCentered,
            isEventStartCentered: isStartCentered,
            rowIndex: cellRowIndex,
            secondaryColor,
            variant,
        };

        if (isColumnWithinEvent) {
            columnLayers.push(layer);
        }

        if (!isCellWithinEventRow || !isColumnWithinEvent) {
            continue;
        }

        cellLayers.push(layer);
    }

    const { isAvailable, layer } = await getCellAvailability(cellCoords, {
        cellLayers,
        columnLayers,
        daysDisplayed,
        events,
        futureLength,
        historyLength,
        initialDate,
        timeConverter,
    });

    if (isAvailable && layer) {
        cellLayers.push(layer);
    }

    return cellLayers;
}

async function getColumnRanges({
    config,
    coords,
    timeConverter,
}: {
    config: Pick<ScheduleConfig, 'timeRanges'>;
    coords: Pick<ScheduleCellCoordinates, 'columnIndex'>;
    timeConverter: TimeConverter;
}): Promise<ScheduleColumnRange[]> {
    const { columnIndex: cellColumnIndex } = coords;
    const { timeRanges } = config;
    const columnRanges: ScheduleColumnRange[] = [];

    for (const timeRange of timeRanges) {
        const startColumnIndex = timeConverter.startTimestampToColumnIndex(timeRange.start);
        const endColumnIndex = timeConverter.endTimestampToColumnIndex(timeRange.end);

        if (cellColumnIndex < startColumnIndex || cellColumnIndex >= endColumnIndex) {
            continue;
        }

        columnRanges.push({
            color: timeRange.color,
            endColumnIndex,
            startColumnIndex,
        });
    }

    return columnRanges;
}

async function getColumnMarkers({
    config,
    coords,
    timeConverter,
}: {
    config: Pick<ScheduleConfig, 'dayMarkers'>;
    coords: Pick<ScheduleCellCoordinates, 'columnIndex'>;
    timeConverter: TimeConverter;
}): Promise<ScheduleColumnMarker[]> {
    const { columnIndex: cellColumnIndex } = coords;
    const { dayMarkers } = config;
    const columnMarkers: ScheduleColumnMarker[] = [];

    for (const dayMarker of dayMarkers) {
        const { color, timestamp } = dayMarker;
        const columnIndex = timeConverter.startTimestampToColumnIndex(timestamp);

        if (cellColumnIndex === columnIndex) {
            columnMarkers.push({
                color,
                columnIndex,
            });
        }
    }

    return columnMarkers;
}

function createCellKey({ columnIndex, rowIndex }: ScheduleCellCoordinates): ScheduleItemMapKey {
    return `${columnIndex},${rowIndex}`;
}

/**
 * Creates a memoized accessor to retrieve cell details from given coordinates.
 */
export function makeCellDetailsAccessor(
    config: ScheduleConfig,
    timeConverter: TimeConverter,
): ScheduleCellDetailsAccessor {
    const cache = new Map<ScheduleItemMapKey, Promise<ScheduleCellDetails>>();

    /**
     * A wait function based on `requestAnimationFrame` to allow the
     * browser to repaint in between calculating cell details.
     */
    const queueAnimationTask: typeof queueMicrotask = callback => {
        requestAnimationFrame(callback);
    };

    return async function getCellDetails(coords, { waitForAnimationFrame } = {}): Promise<ScheduleCellDetails> {
        const key = createCellKey(coords);
        const cellDetails = cache.get(key);

        if (cellDetails) return cellDetails;

        const deferred = new Deferred<ScheduleCellDetails>();

        cache.set(key, deferred.promise);

        await new Promise<void>(waitForAnimationFrame ? queueAnimationTask : queueMicrotask);

        deferred.resolve({
            layers: await getCellLayers({ coords, config, timeConverter }),
            markers: await getColumnMarkers({ coords, config, timeConverter }),
            ranges: await getColumnRanges({ coords, config, timeConverter }),
        });

        return deferred.promise;
    };
}
