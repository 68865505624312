import React, { ReactElement } from 'react';

import Box from '@mui/material/Box';
import {
    getGridStringOperators,
    GridColDef,
    GridFilterModel,
    GridFilterOperator,
    GridRenderCellParams,
    GridRowsProp,
    GridSortModel,
    GridValidRowModel,
} from '@mui/x-data-grid';

import { DataGrid, DataGridCellContent, Layer, Link, LoadingIndicator, ZeroState } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

import { Account } from './types';

export const ACCOUNTS_TABLE_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

const STRING_OPERATOR = {
    contains: getGridStringOperators().find(operator => operator.value === 'contains') as GridFilterOperator<
        Account,
        string | number | null,
        any
    >,
    isAnyOf: getGridStringOperators().find(operator => operator.value === 'isAnyOf') as GridFilterOperator<
        Account,
        string | number | null,
        any
    >,
};

interface Props {
    accounts: Account[];
    loading?: boolean;
    sortModel?: GridSortModel;
    onSortModelChange: (sortModel: GridSortModel) => void;
    filterModel?: GridFilterModel;
    onFilterModelChange: (sortModel: GridFilterModel) => void;
    pageSize: number;
    onPageSizeChange: (pageSize: number) => void;
    pageIndex: number;
    onPageChange: (pageIndex: number) => void;
    totalRowCount: number;
}

function LoadingOverlay() {
    return (
        <Layer fill="anchor" display="flex" alignItems="center" justifyContent="center">
            <LoadingIndicator />
        </Layer>
    );
}

function NoRowsOverlay() {
    const { t } = useI18n();

    return (
        <Box m={6}>
            <ZeroState
                title={t('admin_account_index_page.no_rows_title')}
                message={t('admin_account_index_page.no_rows_message')}
            />
        </Box>
    );
}

/**
 * Responsible for presenting accounts data as a interactive table
 */
export default function AccountsTable({
    accounts,
    loading,
    sortModel,
    onSortModelChange,
    filterModel,
    onFilterModelChange,
    pageSize,
    onPageSizeChange,
    pageIndex,
    onPageChange,
    totalRowCount,
}: Props): ReactElement {
    const { t } = useI18n();

    const rows: GridRowsProp<Account> = accounts.slice();
    const columns: GridColDef<Account>[] = [
        {
            field: 'name',
            headerName: t('admin_account_index_page.table.header.company_name'),
            flex: 1,
            hideable: false,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            renderCell(params: GridRenderCellParams<GridValidRowModel, number>) {
                const account = params.row;

                return (
                    <Link to={getPathByName('ACCOUNT_MGMT_ACCOUNT', { params: { accountID: account.id } })}>
                        <DataGridCellContent line1={account.name} />
                    </Link>
                );
            },
        },
        {
            field: 'industry',
            headerName: t('admin_account_index_page.table.header.industry'),
            flex: 1,
            hideable: true,
            filterOperators: [STRING_OPERATOR.contains, STRING_OPERATOR.isAnyOf],
            renderCell(params: GridRenderCellParams<GridValidRowModel, number>) {
                const account = params.row;

                return <DataGridCellContent line1={account.industry} />;
            },
        },
    ];

    // render the grid
    return (
        <DataGrid
            loading={loading}
            density="comfortable"
            autoHeight
            // rendering
            // pagination
            paginationModel={{ page: pageIndex, pageSize }}
            onPaginationModelChange={paginationModel => {
                if (paginationModel.page !== pageIndex) {
                    onPageChange(paginationModel.page);
                }
                if (paginationModel.pageSize !== pageSize) {
                    onPageSizeChange(paginationModel.pageSize);
                }
            }}
            pageSizeOptions={ACCOUNTS_TABLE_ROWS_PER_PAGE_OPTIONS}
            pagination
            paginationMode="server"
            // data
            rows={rows}
            rowCount={totalRowCount}
            columns={columns}
            sx={{ minWidth: 300 * columns.length, minHeight: '80vh' }}
            disableRowSelectionOnClick={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            sortingMode="server"
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            filterMode="server"
            components={{
                LoadingOverlay,
                NoRowsOverlay,
            }}
        />
    );
}
