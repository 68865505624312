/**
 * Responsible for getting all focusabled children within a given element. The first, last and start elements are also provided.
 * The start element is determined as the first element which has the `data-focus-start` attribute.
 *
 * This method may be overly simplified and does not handle visibility cases.
 */
export default function getFocusableChildren(parentElement) {
    const focusableElements =
        parentElement?.querySelectorAll(
            'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])',
        ) ?? [];
    const children = [...focusableElements];

    return {
        startChild: children.find(x => x.dataset.focusStart),
        firstChild: children?.[0],
        lastChild: children?.[children.length - 1],
        children,
    };
}
