import { ReactElement } from 'react';
import { t } from 'i18next';

import { Text } from 'app/components/primitives';

import CopyTooltip from '../CopyTooltip';

interface MPUTileExternalIDProps {
    /**
     * The external ID of the MPU.
     *
     * @example
     *
     * "10873305"
     */
    externalID?: string;
}

export function MPUTileExternalID({ externalID }: MPUTileExternalIDProps): ReactElement | null {
    if (!externalID) return null;

    return (
        <CopyTooltip
            content={<CopyTooltip.TextContent heading={t('mpu_tile.tooltip.external_id')} description={externalID} />}
            textToCopy={externalID}
        >
            <Text color="secondary" maxLines={1} variant="detail">
                {externalID}
            </Text>
        </CopyTooltip>
    );
}
