import { useState } from 'react';

import Stack from '@mui/material/Stack';

import { Button, Icon, Text, Tooltip } from 'app/components';
import { useLayer } from 'app/core/layers';
import { useSession } from 'app/core/Session';

import { useI18n } from 'i18n';

import { getItem as getLocalStorageItem } from 'utils/localStorage';

import { filterFeatureKey } from './constants';
import { EditMPUFilter } from './forms/MPUFilter';
import type { FilterType } from './types';
import useFilters from './useFilters';

const filterMapToFilters = (savedFilters: any): FilterType[] | null => {
    if (!savedFilters) return null;

    const filters: FilterType[] = Object.entries(savedFilters).map(([name, filter]) => {
        // @ts-ignore
        const { meta = {}, fields = {} } = filter ?? { meta: {}, fields: {} };

        return { meta, fields };
    });

    return filters;
};

export const getSavedFilters = (): FilterType[] => {
    const savedFilters = getLocalStorageItem(filterFeatureKey); // Ensure this returns a type compatible with filterMapToFilters' expected input
    const rawFilters: FilterType[] = filterMapToFilters(savedFilters) || [];
    const adaptedFilters: FilterType[] = rawFilters.map(filter => ({
        // Ensure default objects for meta and fields if they are missing
        meta: filter.meta ?? { updatedAt: new Date().toISOString() }, // Provide reasonable defaults for meta if necessary
        fields: filter.fields ?? {},
    }));

    return adaptedFilters;
};

const SavedFilter = ({ filter, onEdit, onCopy, onApply, onRemove, active }) => {
    const { meta: { name: filterName = null } = {} } = filter;
    const { t } = useI18n();
    const name = filterName ?? t('filters.filter');

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
            sx={{
                borderRadius: '8px',
                padding: '8px 24px 8px 24px',
                backgroundColor: active ? '#E8F3F5' : 'transparent',
                border: active ? '1px solid #17768D' : '1px solid #E2DEDB',
            }}
        >
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
                <Text variant="bodyMedium">{name}</Text>
                {active ? (
                    <Text variant="detail" color="info">
                        {t('filters.applied')}
                    </Text>
                ) : null}
            </Stack>
            <Tooltip content={<Text>{t('filters.copy_filter_link')}</Text>}>
                <Button ctaType="tertiary" onClick={onCopy} icon={<Icon name="copy" />} />
            </Tooltip>
            <Button ctaType="tertiary" onClick={onEdit}>
                {t('filters.edit')}
            </Button>

            {active ? (
                <Button ctaType="tertiary" onClick={onRemove} color="danger">
                    {t('filters.clear_filter')}
                </Button>
            ) : (
                <Button ctaType="primary" onClick={onApply}>
                    {t('filters.apply')}
                </Button>
            )}
        </Stack>
    );
};
// todo(logan) type the props
const SavedFilters = ({ filterKey, ownerAccountIDs }) => {
    const {
        addFilter,
        filter: activeFilter = {},
        deleteFilter,
        removeFilter,
        saveFilter,
        addFilterToURL,
    } = useFilters(filterKey);
    const [editFilter, setEditFilter] = useState<any>(null);

    const session = useSession();
    const { primaryAccountID } = session.getSession();
    const { t } = useI18n();

    const filterTrucationLength = 4;
    const [showOtherFilters, setShowOtherFilters] = useState(false);
    const savedFiltersFromLocalStorage = getSavedFilters();
    const hasOtherFilters = savedFiltersFromLocalStorage.length > filterTrucationLength;

    const savedFilters = getSavedFilters();
    const filtersFilteredByAccountID = savedFilters.filter(filter => filter.meta?.accountID === primaryAccountID);
    filtersFilteredByAccountID.sort(
        // @ts-ignore
        (a, b) => new Date(b?.meta?.updatedAt).getTime() - new Date(a?.meta?.updatedAt).getTime(),
    );
    const recentFilters = showOtherFilters
        ? filtersFilteredByAccountID
        : filtersFilteredByAccountID.slice(0, filterTrucationLength);
    const { toast } = useLayer();

    if (!savedFilters.length) {
        return null;
    }

    return (
        <div>
            <Text variant="h5">{t('filters.saved_filters')}</Text>
            <Stack spacing={2} sx={{ paddingBlockStart: '16px', paddingBlockEnd: '16px' }}>
                {recentFilters.map(filter => {
                    // @ts-ignore
                    const name = filter?.meta?.name;
                    const id = filter?.meta?.id;
                    if (!filter || name === undefined || id === undefined) return null;

                    // @ts-ignore
                    const isActive = id === activeFilter?.meta?.id;

                    if (editFilter?.meta?.id === id) {
                        return (
                            <Stack
                                key={id}
                                spacing={2}
                                style={{
                                    borderRadius: '8px',
                                    border: '1px solid rgb(226, 222, 219)',
                                    boxShadow: 'rgba(16, 24, 40, 0.05) 0px 1px 2px;}}',
                                    padding: '16px',
                                }}
                            >
                                {/* @ts-ignore */}
                                <Text variant="bodyMedium">{filter?.meta?.name}</Text>
                                <EditMPUFilter
                                    ownerAccountIDs={ownerAccountIDs}
                                    filter={filter}
                                    onSubmit={values => {
                                        // @ts-ignore
                                        const savedFilter = saveFilter({ fields: values, meta: filter.meta }, name);
                                        setEditFilter(null);

                                        toast.add({
                                            message: t('filters.notifications.filter_updated'),
                                            severity: 'info',
                                        });

                                        if (isActive) {
                                            // @ts-ignore
                                            addFilter(savedFilter);
                                        }
                                    }}
                                    onDelete={() => {
                                        deleteFilter(id);

                                        toast.add({
                                            message: t('filters.notifications.filter_deleted'),
                                            severity: 'info',
                                        });

                                        setEditFilter(null);
                                    }}
                                    onCancel={() => setEditFilter(null)}
                                />
                            </Stack>
                        );
                    }

                    return (
                        <SavedFilter
                            filter={filter}
                            /* @ts-ignore */
                            key={filter?.meta?.name || JSON.stringify(filter)}
                            // @ts-ignore
                            onApply={() => addFilter(filter)}
                            onCopy={() => {
                                // TODO: @DEAN - Add message to filter
                                // @ts-ignore
                                const filterURL = addFilterToURL(window.location.href, filter);

                                // Copy to clipboard
                                navigator.clipboard
                                    .writeText(filterURL)
                                    .then(() => {
                                        // Show toast
                                        toast.add({
                                            message: t('filters.notifications.filter_copied'),
                                            severity: 'info',
                                        });
                                    })
                                    .catch(() => {
                                        // Show toast
                                        toast.add({
                                            message: t('filters.notifications.filter_copied_failure'),
                                            severity: 'error',
                                        });
                                    });
                            }}
                            onEdit={() => setEditFilter(filter)}
                            active={isActive}
                            onRemove={() => removeFilter()}
                        />
                    );
                })}

                {hasOtherFilters && (
                    <Stack direction={'row'} spacing={2} justifyContent="flex-end">
                        <Button
                            ctaType="tertiary"
                            sx={{ color: '#17768D' }}
                            onClick={() => setShowOtherFilters(!showOtherFilters)}
                        >
                            <Stack direction="row" spacing={3} alignItems="center">
                                <span>Other Filters</span>
                                <Icon
                                    name="chevron-forward"
                                    sx={{
                                        transition: 'rotate 300ms ease-in-out 0s',
                                        rotate: showOtherFilters ? '-90deg' : 0,
                                    }}
                                />
                            </Stack>
                        </Button>
                    </Stack>
                )}
            </Stack>
        </div>
    );
};

export default SavedFilters;
