import { MutableRefObject, ReactElement, useCallback, useMemo, useRef } from 'react';

import { MultiGridController } from 'app/components/compounds/MultiGrid';
import { Schedule } from 'app/components/compounds/Schedule';
import { useScheduleDaysFromTimeSpan } from 'app/components/compounds/Schedule/hooks';

import { ReservationScheduleReservationItemFragment } from 'generated/graphql';

import useTheme from 'styles/theme';

import { dimensions } from './constants';
import { useReservationDateTimeSpan } from './hooks';
import { ReservationScheduleHeadingCell } from './presenters';
import { ReservationScheduleAsideCell } from './ReservationScheduleAsideCell';
import { ReservationScheduleProvider, useReservationScheduleInit } from './ReservationScheduleContext';
import { selectHasSchedules, selectUnits } from './selectors';
import { useReservationScheduleSideEffects } from './side-effects';
import { unitsToReservationScheduleEvents } from './transformers';
import {
    AssignAllocationRequestHandler,
    DeleteAllocationHandler,
    PersistAllocationHandler,
    RefreshReservationItemsHandler,
    ReservationScheduleController,
} from './types';

interface ReservationScheduleProps {
    controllerRef?: MutableRefObject<ReservationScheduleController | undefined>;
    handleDeleteAllocation?: DeleteAllocationHandler;
    handlePersistAllocation?: PersistAllocationHandler;
    handleRefreshReservationItems?: RefreshReservationItemsHandler;
    height: number;
    loading?: boolean;
    onAssignAllocationRequest?: AssignAllocationRequestHandler;
    reservationItems?: ReservationScheduleReservationItemFragment[];
    width: number;
}

/**
 * A widget that wraps the `Schedule` component to display a schedule for a collection of MPUs.
 */
export function ReservationSchedulePresenter({
    controllerRef,
    handleDeleteAllocation,
    handlePersistAllocation,
    handleRefreshReservationItems,
    height,
    loading,
    onAssignAllocationRequest,
    reservationItems,
    width,
}: ReservationScheduleProps): ReactElement {
    const theme = useTheme();
    const getContext = useReservationScheduleInit({
        defaultReservationItems: reservationItems || [],
    });
    const { getState } = getContext();
    const units = selectUnits(getState());

    const events = useMemo(() => unitsToReservationScheduleEvents(theme, units), [theme, units]);
    const defaultExpandedRows = selectHasSchedules(getState()) ? [] : [0];
    const rowCount = units.length;
    const reservationDateTimeSpan = useReservationDateTimeSpan(units);

    const { daysDisplayed, futureLength, initialDate } = useScheduleDaysFromTimeSpan({
        ...reservationDateTimeSpan,
        maxDaysDisplayed: 20,
        minDaysDisplayed: 1,
        paddingDays: 1,
    });

    const getRowHeight = useCallback(
        (rowIndex: number, expandedRowIndexes: number[]): number => {
            const unit = selectUnits(getState()).at(rowIndex);
            const isCollapsed = !expandedRowIndexes.includes(rowIndex);

            if (isCollapsed || !unit) {
                return dimensions.ROW_COLLAPSED_HEIGHT.asValue('px');
            }

            const allocationCount = unit?.allocations.length;

            return (
                dimensions.ROW_EXPANDED_HEIGHT.asValue('px') +
                allocationCount * dimensions.ALLOCATION_HEIGHT.asValue('px')
            );
        },
        [getState],
    );

    const scheduleControllerRef = useRef<MultiGridController<void> | undefined>();

    const controller = useReservationScheduleSideEffects({
        getContext,
        handleDeleteAllocation,
        handlePersistAllocation,
        handleRefreshReservationItems,
        onAssignAllocationRequest,
        reservationItems,
        scheduleControllerRef,
    });

    if (controllerRef) {
        controllerRef.current = controller;
    }

    return (
        <ReservationScheduleProvider value={getContext}>
            <Schedule<void>
                controllerRef={scheduleControllerRef}
                asideGridSize={5}
                daysDisplayed={daysDisplayed}
                defaultExpandedRows={defaultExpandedRows}
                events={events}
                futureLength={futureLength}
                getRowHeight={getRowHeight}
                headingHeight={dimensions.HEADING_HEIGHT.asValue('px')}
                height={height}
                historyLength={0}
                initialDate={initialDate}
                itemData={undefined}
                loading={loading}
                rowCount={rowCount}
                slots={{
                    asideCell: ReservationScheduleAsideCell,
                    headingCell: ReservationScheduleHeadingCell,
                }}
                width={width}
            />
        </ReservationScheduleProvider>
    );
}
