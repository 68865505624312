import { ReactElement } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { PageHeader } from 'app/components';
import getPathByName from 'app/core/Navigation/getPathByName';

import { useI18n } from 'i18n';

/**
 * The root page for account management in the app
 */
export default function AccountManagementIndexPage(): ReactElement {
    const { t } = useI18n();

    return (
        <>
            <PageHeader title={t('acounts_and_users_index_page.title')} />

            <Box display="flex" justifyContent="center" mb={5} sx={{ width: { xs: '100%', md: 300 }, mx: 'auto' }}>
                <ToggleButtonGroup fullWidth exclusive>
                    <ToggleButton component={NavLink} value="accounts" to={getPathByName('ACCOUNT_MGMT_ACCOUNTS')}>
                        {t('acounts_and_users_index_page.accounts_label')}
                    </ToggleButton>
                    <ToggleButton component={NavLink} value="user" to={getPathByName('ACCOUNT_MGMT_USERS')}>
                        {t('acounts_and_users_index_page.users_label')}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Outlet />
        </>
    );
}
