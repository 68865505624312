import { areDatesEqual } from './date-helpers';

export default function getDayColor({
    day,
    start,
    end,
    candidate,
    hasStartError,
    hasEndError,
    color1,
    color2,
    color3,
    color4,
}) {
    const isEndDay = areDatesEqual(day, end);
    const isStartDay = areDatesEqual(day, start);
    const isCandidateDay = areDatesEqual(day, candidate);

    if (
        (end && start && end < start && (isStartDay || isEndDay)) ||
        (isStartDay && hasStartError) ||
        (isEndDay && hasEndError)
    ) {
        return color4;
    }

    if (isCandidateDay && (isStartDay || isEndDay)) {
        return color2;
    }

    if (isEndDay || isStartDay) {
        return color1;
    }

    if (isCandidateDay) {
        return color3;
    }

    return null;
}
