import { ReactElement, useState } from 'react';
import { DateTime } from 'luxon';

import { MetricChartType } from 'app/components/widgets/telemetry/types';
import { MetricName, useChartDataLazy } from 'app/core/data/deviceTelemetry';
import { ISO8601, Series } from 'app/core/types';

import { useI18n } from 'i18n';

import resolveMetricNamesFromType from '../resolveMetricNamesFromType';
import DownloadMenu from './DownloadMenu';
import makeDownloadableFile from './makeDownloadableFile';

interface Props {
    /**
     * The actual device / MPU ID
     */
    mpuID?: string;

    /**
     * The id for the reservation item / unit (can represent multiple MPU ids over time as they are swapped)
     */
    unitID?: string;

    /**
     * The custom label to use for the download CTA
     */
    label?: string;

    /**
     * Which charts to download
     */
    includeCharts?: `${MetricChartType}`[];

    /**
     * The start date time to pull metrics
     */
    start?: DateTime | ISO8601;

    /**
     * The end date time to pull metrics
     */
    end?: DateTime | ISO8601;

    /**
     * If the series data is known it can be passed directly via this prop.
     */
    series?: Series[];

    /**
     * Whether the download should be disabled
     */
    disabled?: boolean;
}

function createChartDownloadFileName({ id, start, end, chartNames = 'chart' }) {
    return `${id} [${start} - ${end}] ${chartNames}`;
}

export default function MetricChartDownloader({
    mpuID,
    unitID,
    label,
    includeCharts = ['all'],
    start,
    end,
    series,
    disabled = false,
}: Props): ReactElement {
    const { t } = useI18n();

    const [isDownloadPending, setIsDownloadPending] = useState<boolean>(false);

    const metricNamesSeed: MetricName[] = [];
    const metricNames = includeCharts.reduce((acc, chartName) => {
        const chartType = MetricChartType.parse(chartName);

        return acc.concat(resolveMetricNamesFromType(chartType));
    }, metricNamesSeed);
    const chartNames = includeCharts.join(',');

    const fetchChartData = useChartDataLazy();

    const downloadFile = async ({ value: fileFormat }) => {
        let data = series ?? [];

        if (isDownloadPending) return;

        setIsDownloadPending(true);

        if (!series) {
            const result = await fetchChartData({
                mpuID,
                unitID,
                metricNames,
                start,
                end,
            });

            data = result.data;
        }

        const fileName = createChartDownloadFileName({
            id: mpuID || unitID,
            start,
            end,
            chartNames,
        });

        const file = makeDownloadableFile({ name: fileName, data, fileFormat });

        file.download();

        setIsDownloadPending(false);
    };

    return (
        <DownloadMenu
            label={isDownloadPending ? t('cta.downloading') : label ?? t('cta.download')}
            chartNames={chartNames}
            onDownload={downloadFile}
            disabled={disabled}
        />
    );
}
