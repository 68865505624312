import React from 'react';
import { Document, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';

import { Layer, ZeroState } from 'app/components';

import { useI18n } from 'i18n';

import MoxionLogoPDF from '../MoxionLogoPDF';
import { BaseText, BoldText, Table, TableCell, TableRow } from '../TablePDF';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 48,
    },
    document: {
        width: '100%',
        height: '100%',
    },
    title: {
        fontSize: 14,
        marginTop: 8,
        marginBottom: 8,
    },
});

const subColumnWidth = 260;

// Create Document Component
export default function BillOfLadingInternalTemplate({ data }) {
    const { t } = useI18n();

    if (!data) {
        return (
            <Layer anchor>
                <ZeroState
                    title="No Document Data"
                    message="Please select a document type and fill out the form to generate a document."
                />
            </Layer>
        );
    }

    const {
        billOfLadingNumber = '',
        moxionAddress = '',
        clientAddress = '',
        mpu = '',
        direction = '',
        specialInstructions = '',
        trailerNumber = '',
        lineItems = [],
    } = data;

    // Break up addresses into lines
    const formattedMoxionAddress = moxionAddress?.replace(', ', ',\n');
    const formattedClientAddress = clientAddress?.replace(', ', ',\n');

    // Determine ship from and ship to addresses
    const shipFromAddress = direction === 'outbound' ? formattedMoxionAddress : formattedClientAddress;
    const shipToAddress = direction === 'outbound' ? formattedClientAddress : formattedMoxionAddress;

    return (
        <Layer fill="screen">
            <PDFViewer style={{ width: '100%', minHeight: '100%' }}>
                <Document style={styles.document}>
                    <Page orientation="landscape" style={styles.page}>
                        <MoxionLogoPDF style={{ width: subColumnWidth }} />
                        <Text style={styles.title}>{t('documents.bill_of_lading')}</Text>

                        <Table>
                            <TableRow>
                                <TableCell
                                    style={{
                                        width: subColumnWidth,
                                        textAlign: 'center',
                                    }}
                                >
                                    <BoldText>{t('documents.components.ship_from')}:</BoldText>
                                </TableCell>
                                <TableCell style={{ flex: '1', textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.bill_of_lading_number')}:</BoldText>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ width: subColumnWidth }}>
                                    <BaseText>{shipFromAddress}</BaseText>
                                </TableCell>

                                <TableCell style={{ flex: 1, textAlign: 'center' }}>
                                    <BaseText>{billOfLadingNumber}</BaseText>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <View style={{ width: subColumnWidth, flexDirection: 'column' }}>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <BoldText>{t('documents.components.ship_to')}:</BoldText>
                                    </TableCell>
                                    <TableCell style={{ width: subColumnWidth }}>
                                        <BaseText>{shipToAddress}</BaseText>
                                    </TableCell>
                                </View>
                                <TableCell style={{ flex: 1 }}>
                                    <BaseText>
                                        {t('documents.components.carrier_name')}:{' '}
                                        {t('documents.components.carrier_name_moxion')} {'\n'}
                                        {t('documents.components.trailer_number')}: {trailerNumber} {'\n'}
                                        {t('documents.components.serial_number')}: {mpu} {'\n'}
                                    </BaseText>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ flex: '1', height: 40 }}>
                                    <BoldText style={{ marginBottom: 3 }}>
                                        {t('documents.components.special_instructions')}:
                                    </BoldText>
                                    <BaseText>{specialInstructions}</BaseText>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    style={{
                                        flex: 1,
                                        textAlign: 'center',
                                    }}
                                >
                                    <BoldText>{t('documents.components.carrier_information')}</BoldText>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ width: subColumnWidth }}>
                                    <BoldText>{t('documents.components.package')}</BoldText>
                                </TableCell>
                                <TableCell style={{ flex: 1 }} />
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ width: subColumnWidth / 4, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.quantity')}</BoldText>
                                </TableCell>
                                <TableCell style={{ width: subColumnWidth / 4, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.type')}</BoldText>
                                </TableCell>
                                <TableCell style={{ width: subColumnWidth / 4, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.weight')}</BoldText>
                                </TableCell>
                                <TableCell style={{ width: subColumnWidth / 4, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.hmx')}</BoldText>
                                </TableCell>
                                <TableCell style={{ flex: 1, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.commodity_description')}</BoldText>
                                </TableCell>
                            </TableRow>

                            {lineItems.map((lineItem, i) => (
                                <TableRow key={i}>
                                    <TableCell style={{ width: subColumnWidth / 4 }}>
                                        <BoldText>{lineItem?.quantity}</BoldText>
                                    </TableCell>
                                    <TableCell style={{ width: subColumnWidth / 4 }}>
                                        <BoldText>{lineItem?.type}</BoldText>
                                    </TableCell>
                                    <TableCell style={{ width: subColumnWidth / 4 }}>
                                        <BoldText>{lineItem?.weight}</BoldText>
                                    </TableCell>
                                    <TableCell style={{ width: subColumnWidth / 4 }}>
                                        <BoldText>{lineItem?.hmx}</BoldText>
                                    </TableCell>
                                    <TableCell style={{ flex: 1 }}>
                                        <BoldText>{lineItem?.description}</BoldText>
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow style={{ height: 60 }}>
                                <TableCell style={{ flex: 1, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.shipper_signature_date')}</BoldText>
                                </TableCell>
                                <TableCell style={{ flex: 1, textAlign: 'center' }}>
                                    <BoldText>{t('documents.components.carrier_signature_date')}</BoldText>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{ flex: 1 }}>
                                    <BaseText style={{ textAlign: 'center', fontSize: 8 }}>
                                        {t('documents.components.shipper_copy')}
                                    </BaseText>
                                </TableCell>

                                <TableCell style={{ flex: 1 }}>
                                    <BaseText style={{ textAlign: 'center', fontSize: 8 }}>
                                        {t('documents.components.carrier_copy')}
                                    </BaseText>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Page>
                </Document>
            </PDFViewer>
        </Layer>
    );
}
