import { ComponentProps, ReactElement, useId, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { Button, EditableSection, SectionHeader } from 'app/components';

import { useI18n } from 'i18n';

import EditAccountForm from './EditAccountForm';

interface AccountDetailsProps extends Omit<ComponentProps<typeof EditAccountForm>, 'formID'> {}

export default function AccountDetails({
    accountID,
    name,
    industry,
    creationDate,
    netsuiteCustomerIDs,
    moxionRepID,
    pointOfContact,
    contactPhone,
    billingAddressLocationID,
    moxionInternalNotes,

    excludeFields,

    onFormStateChange,
    onSubmit,
}: AccountDetailsProps): ReactElement {
    const [disableEditCta, setDisableEditCta] = useState(false);
    const editFormID = useId();
    const { t } = useI18n();

    return (
        <Paper variant="outlined" sx={{ p: 4 }}>
            <EditableSection
                header={<SectionHeader title={t('admin_account_page.details.heading')} />}
                saveCTA={<Button type="submit" form={editFormID} disabled={disableEditCta} />}
            >
                {({ isEditing }) => (
                    <Box pt={4}>
                        <EditAccountForm
                            formID={editFormID}
                            hideSubmitCTA
                            readOnly={!isEditing}
                            accountID={accountID}
                            name={name}
                            industry={industry}
                            creationDate={creationDate}
                            netsuiteCustomerIDs={netsuiteCustomerIDs}
                            moxionRepID={moxionRepID}
                            pointOfContact={pointOfContact}
                            contactPhone={contactPhone}
                            billingAddressLocationID={billingAddressLocationID}
                            moxionInternalNotes={moxionInternalNotes}
                            excludeFields={excludeFields}
                            onFormStateChange={event => {
                                const { shouldDisableCTA } = event;

                                setDisableEditCta(shouldDisableCTA);
                                onFormStateChange?.(event);
                            }}
                            onSubmit={onSubmit}
                        />
                    </Box>
                )}
            </EditableSection>
        </Paper>
    );
}
