import { FormStateChange, saveFunction } from '../types';

/**
 * Responsible for tracking changes and providing a unified save method across multiple forms
 */
export default class UnsavedChangesTracker {
    /**
     * Form State indexed by formID
     */
    private forms: Record<string, FormStateChange> = {};

    /**
     * Save functions indexed by formID
     */
    private saveFns: Record<string, saveFunction> = {};

    updateFormStateFor<T>(
        formID: string,
        { formState, save }: { formState: FormStateChange<T>; save: saveFunction<T> },
    ): void {
        this.forms = {
            ...this.forms,
            [formID]: formState,
        };
        this.saveFns = {
            ...this.saveFns,
            [formID]: save,
        };
    }

    async saveAll(): Promise<void> {
        await Promise.all(
            Object.keys(this.forms).map(formID => {
                const form = this.forms[formID];

                if (!form.isValid || !form.isDirty) return Promise.resolve();

                return this.saveFns[formID]?.({ value: form.values });
            }),
        );
    }

    get hasValidationErrors(): boolean {
        return Object.keys(this.forms).reduce((acc, cur) => {
            const form = this.forms[cur];

            return acc || !form.isValid;
        }, false);
    }

    get hasUnsavedChanges(): boolean {
        return Object.keys(this.forms).reduce((acc, cur) => {
            const form = this.forms[cur];

            return acc || form.isDirty;
        }, false);
    }
}
