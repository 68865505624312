import { ReactElement, useCallback, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Text } from 'app/components/primitives';
import { resolveDateTime } from 'app/core/date-time';
import { ISO8601 } from 'app/core/types';

import { CHART_BODY_MAX_HEIGHT, CHART_MIN_BAR_WIDTH, CHART_ROW_HEIGHT, CHART_Y_AXIS_WIDTH } from './config';
import HistoricalFaultsChart from './HistoricalFaultsChart';
import Legend from './Legend';
import TimeScrubber from './TimeScrubber';
import { FaultGroup } from './types';
import useResizeObserver from './useResizeObserver';

interface Props {
    data?: FaultGroup[];

    start?: ISO8601 | null;

    end?: ISO8601 | null;
}

/**
 * Responsible for composing collaborating components into an interactive chart for displaying
 * historical faults. Adds:
 *
 * 1. Vertical scroll on faults overflow
 * 2. Active fault scrubbing
 */
export default function ComposedChart({ data, start, end }: Props): ReactElement {
    const primaryChartRef = useRef<HTMLElement>();

    const _start = resolveDateTime(start);
    const _end = resolveDateTime(end);

    const [widthWithoutScrollbar, setWidthWithoutScrollbar] = useState<number>(0);
    const [chartX, setChartX] = useState<number | null>(_start.toMillis());
    const [xPos, setXPos] = useState<number | null>(null);
    const [isScrubberLocked, setIsScrubberLocked] = useState<boolean>(false);

    const minFaultDuration =
        ((_end.toSeconds() - _start.toSeconds()) / (widthWithoutScrollbar - CHART_Y_AXIS_WIDTH)) * CHART_MIN_BAR_WIDTH;

    useResizeObserver({
        ref: primaryChartRef,
        onResize: (x: { value: { width: number } }) => {
            setWidthWithoutScrollbar(x.value?.width);
        },
    });

    const onScrubTime = useCallback(({ value }) => {
        setChartX(value.timestamp);
        setXPos(value.x);
    }, []);

    const onScrubberLockChange = useCallback(({ value }) => {
        setIsScrubberLocked(value);
    }, []);

    return (
        <Box
            component={Text}
            variant="detail"
            sx={{ display: 'flex', width: '100%', flexDirection: 'column', minWidth: 800 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                }}
            >
                <Stack width="100%" pr={3}>
                    {/* Scrollable view */}
                    <Box
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            maxHeight: CHART_BODY_MAX_HEIGHT,
                        }}
                    >
                        <Box
                            ref={primaryChartRef}
                            sx={{
                                display: 'flex',
                                minHeight: 300,
                                height: (data?.length ?? 0) * CHART_ROW_HEIGHT,
                                width: '100%',
                            }}
                        >
                            <HistoricalFaultsChart
                                start={start}
                                end={end}
                                data={data}
                                isScrubberLocked={isScrubberLocked}
                                onScrubTime={onScrubTime}
                                onScrubberLockChange={onScrubberLockChange}
                                xAxisOnly={false}
                            />
                        </Box>
                    </Box>

                    {/* X-Axis */}
                    <Box sx={{ width: widthWithoutScrollbar ?? '100%' }}>
                        <HistoricalFaultsChart
                            start={start}
                            end={end}
                            data={data}
                            isScrubberLocked={isScrubberLocked}
                            onScrubTime={onScrubTime}
                            onScrubberLockChange={onScrubberLockChange}
                            xAxisOnly={true}
                        />
                    </Box>
                </Stack>

                <TimeScrubber
                    minFaultDuration={minFaultDuration}
                    width={widthWithoutScrollbar}
                    data={data}
                    x={xPos}
                    timestamp={chartX ?? 0}
                    isLocked={isScrubberLocked}
                />
            </Box>

            <Box mt={4} mb={2} mx="auto">
                <Legend />
            </Box>
        </Box>
    );
}
