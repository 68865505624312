import { isWeekEnd, isWeekStart } from './date-helpers';

export default function getRangeRadii({ day }) {
    const roundCap = '999px';
    const leftRoundness = isWeekStart(day) ? roundCap : 0;
    const rightRoundness = isWeekEnd(day) ? roundCap : 0;

    return {
        borderTopLeftRadius: leftRoundness,
        borderBottomLeftRadius: leftRoundness,

        borderTopRightRadius: rightRoundness,
        borderBottomRightRadius: rightRoundness,
    };
}
