import { RechartsPoint, Series } from 'app/core/types';

type PartialSeries = Pick<Series, 'seriesName' | 'data' | 'units'>;

/**
 * Formats multi-series telemetry data for display with Recharts
 * @param {Array<Series>} series
 * @returns {Array<RechartsPoint>}
 */
export default function mergeSeries(series: PartialSeries[], { shouldAddUnitToLabel = false } = {}): RechartsPoint[] {
    const timeValuesMap: Map<number, Map<string, number | null | undefined>> = new Map();

    for (const s of series) {
        for (const p of s.data) {
            const values = timeValuesMap.get(new Date(p.timestamp).getTime());
            const seriesName = s.seriesName + (shouldAddUnitToLabel ? ` (${s.units})` : '');

            if (!values) {
                timeValuesMap.set(new Date(p.timestamp).getTime(), new Map([[seriesName, p.value]]));
                continue;
            } else {
                values.set(seriesName, p.value);
            }
        }
    }

    const points: RechartsPoint[] = [];

    for (const [ts, values] of Array.from(timeValuesMap.entries())) {
        const point: RechartsPoint = {
            timestamp: ts,
            ...Object.fromEntries(values),
        };

        points.push(point);
    }

    // TODO(derek): the datapoints should really be sorted by the backend
    return points.sort((a, b) => {
        if (a.timestamp > b.timestamp) return 1;
        if (b.timestamp > a.timestamp) return -1;

        return 0;
    });
}
