import { useState } from 'react';
import { DateTime as LuxonDateTime } from 'luxon';

import { ValueOptions } from '@mui/x-data-grid';

import { useListAllRolesLazyQuery } from 'generated/graphql';

export default function useRoleFilterOptions() {
    const [fetchTime, setFetchTime] = useState(LuxonDateTime.now());

    const [listAllRoles, { data: listAllRolesData }] = useListAllRolesLazyQuery();

    const roleValueOptions: ValueOptions[] =
        listAllRolesData?.listRoles.map(role => ({ value: role.name, label: role.name })) ?? [];

    async function fetchRoleValueOptions() {
        // refresh the data if older than 1 minute
        const isOutdated = Math.abs(fetchTime.diffNow().as('seconds')) > 60;

        if (roleValueOptions.length === 0 || isOutdated) {
            await listAllRoles();
            setFetchTime(LuxonDateTime.now());
        }
    }

    return { fetchRoleValueOptions, roleValueOptions };
}
