import { ReactElement } from 'react';
import { DateTime } from 'luxon';

import Paper from '@mui/material/Paper';

import { Icon, IconAndText } from 'app/components/primitives';

import { useI18n } from 'i18n';

interface Props {
    /**
     * The current inspected timestamp
     */
    timestamp?: number | null;

    /**
     * Whether the scrubber reference line is locked
     */
    isLocked?: boolean;
}

/**
 * Responsible for displaying the state of the scrubber
 */
export default function TimeMarker({ isLocked, timestamp }: Props): ReactElement {
    const { format } = useI18n();

    if (typeof timestamp !== 'number') return <></>;

    return (
        <Paper
            variant="outlined"
            sx={theme => ({
                display: 'inline-flex',
                borderRadius: theme.shape.pillBorderRadius,
                px: 3,
                wordBreak: 'keep-all',
                whiteSpace: 'nowrap',
            })}
        >
            <IconAndText
                icon={<Icon size="sm" name={`lock-${isLocked ? 'closed' : 'open'}`} />}
                text={format.timeAxis(DateTime.fromMillis(timestamp))}
            />
        </Paper>
    );
}
