import type * as CSS from 'csstype';
import { DateTime } from 'luxon';
import { v4 as uuidV4 } from 'uuid';

import { IconName } from 'app/assets/icons/types';
import { MergeEventsPayload, mergeOverlappingEvents, ScheduleCellLayer } from 'app/components/compounds/Schedule';
import { extractUnitStatusesFromTelemetry } from 'app/core/data';
import { byStartingTimestamp } from 'app/core/date-time';
import getPathByName from 'app/core/Navigation/getPathByName';
import { ISO8601 } from 'app/core/types';

import {
    Assignment,
    MpuScheduleDeviceInstanceFragment,
    MpuScheduleDeviceInstanceScheduleFragment,
} from 'generated/graphql';

import { Theme } from 'styles/theme';

import { MpuScheduleEventType, RowIndex } from './constants';
import { DeviceInstanceWithSchedules, MpuScheduleEvent, MpuScheduleEventSchedule, MpuScheduleFilter } from './types';

const eventColorAccessorByEventType: Record<MpuScheduleEventType, (theme: Theme) => CSS.DataType.Color> = {
    [MpuScheduleEventType.Allocation]: theme => theme.palette.background.contrast.main,
    [MpuScheduleEventType.Available]: theme => theme.palette.background.successDark.main,
    [MpuScheduleEventType.Charging]: theme => theme.palette.background.contrast.faded!,
    [MpuScheduleEventType.Downtime]: theme => theme.palette.background.infoDark.main,
    [MpuScheduleEventType.Reserved]: theme => theme.palette.background.contrast.main,
    [MpuScheduleEventType.Swap]: theme => theme.palette.background.contrast.faded!,
    [MpuScheduleEventType.Transit]: theme => theme.palette.background.contrast.faded!,
};

const rowIndexByAssignment: Record<Assignment, RowIndex> = {
    [Assignment.Charging]: RowIndex.Reserved,
    [Assignment.Discharging]: RowIndex.Reserved,
    [Assignment.Maintenance]: RowIndex.Downtime,
    [Assignment.Transit]: RowIndex.Reserved,
};

const eventTypeByAssignment: Record<Assignment, MpuScheduleEventType> = {
    [Assignment.Charging]: MpuScheduleEventType.Charging,
    [Assignment.Discharging]: MpuScheduleEventType.Reserved,
    [Assignment.Maintenance]: MpuScheduleEventType.Downtime,
    [Assignment.Transit]: MpuScheduleEventType.Transit,
};

export const iconNameByEventType: Readonly<Record<MpuScheduleEventType, IconName | undefined>> = {
    [MpuScheduleEventType.Allocation]: 'calendar',
    [MpuScheduleEventType.Available]: undefined,
    [MpuScheduleEventType.Charging]: 'lightning',
    [MpuScheduleEventType.Downtime]: 'build',
    [MpuScheduleEventType.Reserved]: 'calendar',
    [MpuScheduleEventType.Swap]: 'lightning',
    [MpuScheduleEventType.Transit]: 'car',
};

function assignmentToRowIndex(assignment?: Assignment | null): RowIndex {
    return assignment ? rowIndexByAssignment[assignment] : RowIndex.Reserved;
}

/**
 * Determines the event type for MPU schedule component from the given assignment.
 */
export function assignmentToEventType(assignment?: Assignment | null): MpuScheduleEventType {
    return assignment ? eventTypeByAssignment[assignment] : MpuScheduleEventType.Reserved;
}

/**
 * Determines the color of an event's bar from an event type.
 */
export function eventTypeToColor(eventType: MpuScheduleEventType, theme: Theme): CSS.DataType.Color {
    return eventColorAccessorByEventType[eventType](theme);
}

function makeReservationLink(schedule: MpuScheduleDeviceInstanceScheduleFragment): string | undefined {
    const reservationID = schedule.reservationItem?.reservation.id;

    return reservationID && getPathByName('RENTAL_MGMT_RESERVATION_DETAIL', { params: { reservationID } });
}

function makeMpuLink(schedule: MpuScheduleDeviceInstanceScheduleFragment): string | undefined {
    return getPathByName('MPU_DETAIL', {
        params: { mpuID: schedule.deviceInstance.id },
        // TODO(Morris): Reference/reuse this value
        hash: '#schedule',
    });
}

const linkCreatorByAssignment: Record<
    Assignment,
    (schedule: MpuScheduleDeviceInstanceScheduleFragment) => string | undefined
> = {
    [Assignment.Charging]: makeReservationLink,
    [Assignment.Discharging]: makeReservationLink,
    [Assignment.Maintenance]: makeMpuLink,
    [Assignment.Transit]: makeMpuLink,
};

function makeMpuScheduleEventScheduleLink(schedule: MpuScheduleDeviceInstanceScheduleFragment): string | undefined {
    const { assignment } = schedule;
    const linkCreator = assignment && linkCreatorByAssignment[assignment];

    return linkCreator?.(schedule);
}

function makeMpuScheduleEventSchedule(schedule: MpuScheduleDeviceInstanceScheduleFragment): MpuScheduleEventSchedule {
    return {
        ...schedule,
        link: makeMpuScheduleEventScheduleLink(schedule),
    };
}

export function deviceInstanceScheduleToMpuScheduleEvent({
    deviceInstance,
    schedule: targetSchedule,
    theme,
}: {
    deviceInstance: DeviceInstanceWithSchedules;
    schedule: MpuScheduleDeviceInstanceScheduleFragment;
    theme: Theme;
}): MpuScheduleEvent {
    const { assignment, end, id, start } = targetSchedule;
    const rowIndex = assignmentToRowIndex(assignment);
    const eventType = assignmentToEventType(assignment);
    const color = eventTypeToColor(eventType, theme);
    const iconName = iconNameByEventType[eventType];
    const targetScheduleEnd = DateTime.fromISO(targetSchedule.end);
    // This is an array, because `MpuScheduleEvent`s that occur entirely within the same day,
    // are later merged into a single event. This array will be appended with additional schedules.
    const schedules = [makeMpuScheduleEventSchedule(targetSchedule)];

    /**
     * The event has a tail if it is either an allocation or reserved event,
     * and its associated MPU has another schedule, where the MPU is charging or in transit.
     * The other schedule must also start after the target schedule, start within the next 24 hours,
     * and end before the start of the second day after the target schedule ends.
     */
    const hasTail =
        [MpuScheduleEventType.Allocation, MpuScheduleEventType.Reserved].includes(eventType) &&
        !!deviceInstance.schedules?.find(schedule => {
            const scheduleStart = DateTime.fromISO(schedule.start);
            const scheduleEnd = DateTime.fromISO(schedule.end);

            return (
                targetSchedule.id !== schedule.id &&
                (schedule?.assignment === Assignment.Charging || schedule?.assignment === Assignment.Transit) &&
                scheduleStart > targetScheduleEnd &&
                scheduleStart < targetScheduleEnd.plus({ days: 1 }) &&
                scheduleEnd < targetScheduleEnd.startOf('day').plus({ days: 2 })
            );
        });

    return {
        color,
        end,
        hasTail,
        iconName,
        id,
        rowIndex,
        schedules,
        start: start,
        type: eventType,
    };
}

const priorityByMpuScheduleEventType: Record<MpuScheduleEventType, number> = Object.fromEntries(
    Object.entries([
        MpuScheduleEventType.Reserved,
        MpuScheduleEventType.Downtime,
        MpuScheduleEventType.Charging,
        MpuScheduleEventType.Transit,
        MpuScheduleEventType.Allocation,
        MpuScheduleEventType.Swap,
        MpuScheduleEventType.Available,
    ])
        // Swap the index with the element
        .map(entry => entry.reverse()),
);

function mergeMpuScheduleEvents({
    earliestStart,
    eventA,
    eventB,
    latestEnd,
}: MergeEventsPayload<MpuScheduleEvent>): MpuScheduleEvent {
    const nextSchedules = [...eventA.schedules, ...eventB.schedules].sort(byStartingTimestamp);
    const eventATakesPriority =
        priorityByMpuScheduleEventType[eventA.type] < priorityByMpuScheduleEventType[eventB.type];
    const baseEvent = eventATakesPriority ? eventA : eventB;

    return {
        ...baseEvent,
        end: latestEnd,
        schedules: nextSchedules,
        start: earliestStart,
    };
}

export function createDeviceInstanceTransformer({
    deviceInstanceIndex,
    transform,
}: {
    deviceInstanceIndex: number;
    transform: (params: {
        deviceInstance: DeviceInstanceWithSchedules;
        deviceInstanceIndex: number;
        schedule: MpuScheduleDeviceInstanceScheduleFragment;
        theme: Theme;
    }) => MpuScheduleEvent | MpuScheduleEvent[];
}) {
    return function deviceInstanceToScheduleEvents(
        theme: Theme,
        deviceInstance: DeviceInstanceWithSchedules,
        filter?: MpuScheduleFilter,
    ): MpuScheduleEvent[] {
        const events = (deviceInstance.schedules ?? []).reduce<MpuScheduleEvent[]>((events, schedule) => {
            if (filter && !filter(schedule)) return events;

            return events.concat(
                transform({
                    deviceInstance,
                    deviceInstanceIndex,
                    schedule,
                    theme,
                }),
            );
        }, []);

        return mergeOverlappingEvents(mergeMpuScheduleEvents, events);
    };
}

export const deviceInstanceToMpuScheduleEvents = createDeviceInstanceTransformer({
    // The value is `0`, because the `MpuSchedule` component only presents events for one device instance.
    deviceInstanceIndex: 0,
    transform: deviceInstanceScheduleToMpuScheduleEvent,
});

/**
 * Extends the given availability layer with properties presentation
 */
export function makeMpuScheduleAvailabilityLayer(theme: Theme, layer: ScheduleCellLayer): ScheduleCellLayer {
    return {
        ...layer,
        color: eventTypeToColor(MpuScheduleEventType.Available, theme),
        iconName: iconNameByEventType[MpuScheduleEventType.Available],
    };
}

/**
 * Transforms query specific device instance into generic device instance with schedules.
 */
export function deviceInstanceFragmentToDeviceInstanceWithSchedules(
    { serviceArea, ...deviceInstance }: MpuScheduleDeviceInstanceFragment,
    deps: Parameters<typeof extractUnitStatusesFromTelemetry>[1],
): DeviceInstanceWithSchedules {
    return {
        ...deviceInstance,
        locationTimestamp: deviceInstance.latestDeviceLocation?.time,
        modelID: deviceInstance.deviceModel.id,
        modelName: deviceInstance.deviceModel?.name,
        ownerCompanyName: deviceInstance.owner?.name,
        ownerID: deviceInstance.owner?.id,
        serviceArea: serviceArea?.name,
        ...extractUnitStatusesFromTelemetry<'stateOfCharge' | 'timeToEmpty'>(
            deviceInstance.latestTelemetry?.telemetryPoints ?? [],
            deps,
        ),
    };
}

/**
 * Create an unassociated `MpuScheduleEvent`.
 */
export function makeMpuScheduleEvent({
    assignment,
    end,
    start,
    theme,
}: {
    assignment: Assignment;
    end: ISO8601;
    start: ISO8601;
    theme: Theme;
}): MpuScheduleEvent {
    const eventType = assignmentToEventType(assignment);

    return {
        color: eventTypeToColor(eventType, theme),
        end,
        id: uuidV4(),
        rowIndex: assignmentToRowIndex(assignment),
        schedules: [],
        start,
        type: assignmentToEventType(assignment),
    };
}
