import { useCallback } from 'react';

import { ScheduleEventVariant } from 'app/components/compounds/Schedule';
import { isScheduleConflictError, useToastErrorHandler } from 'app/core/error';

import { useI18n } from 'i18n';

import useTheme from 'styles/theme';

import { DowntimeDialogSubmitHandler } from './DowntimePeriodDialog';
import { useCreateDowntimePeriodMutation } from './operations';
import { formValuesToDowntimePeriod } from './transformers';
import { ScheduleChangeEvent } from './types';
import { useDowntimePeriodDialog } from './useDowntimePeriodDialog';

interface AddDowntimePeriodDialogProps {
    /**
     * A callback invoked after the downtime period has been added.
     */
    onScheduleChange?: (event: ScheduleChangeEvent) => void;
}

interface OpenHandlerProps {
    /**
     * The ID of the MPU associated with the downtime period.
     */
    mpuID: string;
}

/**
 * Handlers for opening and closing a dialog for adding or editing a downtime period.
 */
interface AddDowntimePeriodDialogHandlers {
    open: (props: OpenHandlerProps) => void;
    close: () => void;
}

/**
 * @returns A function to open and close a dialog for adding a downtime period.
 */
export function useAddDowntimePeriodDialog({
    onScheduleChange,
}: AddDowntimePeriodDialogProps): AddDowntimePeriodDialogHandlers {
    const [createDowntimePeriod] = useCreateDowntimePeriodMutation();
    const handleError = useToastErrorHandler();
    const { t } = useI18n();
    const theme = useTheme();

    const handleSubmit = useCallback<DowntimeDialogSubmitHandler>(
        async ({ values, dialog, mpuID }) => {
            try {
                await createDowntimePeriod({
                    ...formValuesToDowntimePeriod(values),
                    mpuID,
                });

                onScheduleChange?.({ value: { mpuID } });
                dialog.close();
            } catch (error) {
                const message = isScheduleConflictError(error)
                    ? t('downtime_period_dialog.error.schedule_conflict')
                    : t('downtime_period_dialog.error.create_failed');

                handleError(error, message);
            }
        },
        [createDowntimePeriod, handleError, onScheduleChange, t],
    );

    return useDowntimePeriodDialog({
        onScheduleChange,
        onSubmit: handleSubmit,
        previewColor: theme.palette.warning.main,
        previewVariant: ScheduleEventVariant.Outline,
        saveText: t('downtime_period_dialog.cta.add'),
        title: t('downtime_period_dialog.title.add'),
    });
}
