import { useEffect } from 'react';

/**
 * Quick and dirty resize observer to notify of dimension changes for a given DOM element ref
 */
export default function useResizeObserver({ ref, onResize }) {
    useEffect(() => {
        const handleResize = () => {
            onResize({ value: ref.current?.getBoundingClientRect() });
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        handleResize();

        return () => {
            resizeObserver.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onResize]);
}
