// types
import { Series } from 'app/core/types';

import { Telemetry } from 'generated/graphql';

import { TranslateFunction } from 'i18n';

import getUnitForMetric from './getUnitForMetric';
import { scaleToKilo } from './scaleToKilo';

const METRIC_TEMP_MAX_OLD = 'ess_temp_max';
const METRIC_TEMP_MAX_NEW = 'temp_ess_max';

/**
 * Responsible for formatting metric data for display in ReCharts
 */
export default function toSeries(metricData: Telemetry[], { t }: { t: TranslateFunction }): Series[] {
    // this metric has two names and should only ever show up as one for any
    // given device with a specific FW version
    const oldTempEssMaxMetric = metricData.filter(({ metric }) => metric === METRIC_TEMP_MAX_OLD)[0];

    return metricData
        .filter(({ metric }) => !(metric === METRIC_TEMP_MAX_OLD))
        .map(({ metric: metricId, datapoints }) => {
            const unit = getUnitForMetric(metricId);
            const isScaled = /^(w|wh)$/i.test(unit);
            let _datapoints = datapoints ?? [];

            if (metricId === METRIC_TEMP_MAX_NEW) {
                _datapoints = (oldTempEssMaxMetric?.datapoints ?? []).concat(datapoints);
            }

            return {
                metricId,
                seriesName: t(`metric.${metricId}.label`),
                units: isScaled ? `k${unit}` : unit,
                data: _datapoints.map(({ value, timestamp }) => {
                    if (isScaled) {
                        return {
                            value: scaleToKilo(value),
                            timestamp,
                        };
                    }

                    return { value, timestamp };
                }),
            };
        });
}
