import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { useI18n } from 'i18n';

export type TabOption = 'spec' | 'operation' | null | undefined;

interface Props {
    /**
     * The currently selected Tab's identifier
     */
    value: TabOption;

    /**
     * Event handler to receive intent to change tabs
     */
    onChange: (event: { value: TabOption }) => void;
}

/**
 * Tabbed control for switching between operation and spec panels
 */
export default function TabControl({ value, onChange }: Props): ReactElement {
    const { t } = useI18n();

    return (
        <Box>
            <Tabs
                value={value}
                onChange={(_, newActiveSection) => {
                    onChange({ value: newActiveSection });
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label={t('renter_reservation_form.screen_reader.specs_tab_description')}
            >
                {['operation', 'spec'].map(option => (
                    <Tab
                        key={option}
                        aria-controls={`${option}-tabbed-content`}
                        aria-expanded={value === option}
                        value={option}
                        label={t(`renter_reservation_form.${option}_tab_label`)}
                    />
                ))}
            </Tabs>

            <Divider sx={{ mt: '-1px', mb: 5 }} />
        </Box>
    );
}
