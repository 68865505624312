import { generatePath } from 'react-router-dom';

const HOME = '/home';

const RENTER_DASHBOARD = '/dashboard';
const CUSTOMER_RESERVATIONS = '/reservations';
const RENTER_RESERVATION_MONITORING = '/reservations/:reservationID/monitor';
const RENTER_RESERVATION_REQUEST_FORM = '/reserve';

const LOGIN_CALLBACK_ROUTE = '/login/callback';
const OKTA_AUTH_ROUTE = '/protected';

const SIGN_IN = '/signin';
const SIGN_UP = '/signup';
const PASSWORD_RESET = '/reset-password';

const PROFILE = '/profile';
const ALERTS = '/alerts';

const MPUS_INDEX = `/mpus` as const;
const MPU_DETAIL = `/mpus/:mpuID` as const;
const MPU_FAULTS = `${MPUS_INDEX}/faults` as const;

const RENTAL_MGMT_ROOT = '/rental-management' as const;

const RENTAL_MGMT_TRANSITIONS = `${RENTAL_MGMT_ROOT}/transitions` as const;

const RENTAL_MGMT_RESERVATIONS = `${RENTAL_MGMT_ROOT}/reservations` as const;
const RENTAL_MGMT_RESERVATIONS_TABLE = `${RENTAL_MGMT_ROOT}/reservations/table` as const;
const RENTAL_MGMT_RESERVATIONS_KANBAN = `${RENTAL_MGMT_ROOT}/reservations/kanban` as const;
const RENTAL_MGMT_RESERVATION_DETAIL = `${RENTAL_MGMT_RESERVATIONS}/:reservationID` as const;
const RENTAL_MGMT_UPDATE_UNIT_COUNT = `${RENTAL_MGMT_RESERVATION_DETAIL}/update-unit-count`;

const ACCOUNT_MGMT_INDEX = `/account-management` as const;
const ACCOUNT_MGMT_ACCOUNTS = `${ACCOUNT_MGMT_INDEX}/accounts` as const;
const ACCOUNT_MGMT_ACCOUNT = `${ACCOUNT_MGMT_INDEX}/accounts/:accountID` as const;
const ACCOUNT_MGMT_USERS = `${ACCOUNT_MGMT_INDEX}/users` as const;
const ACCOUNT_MGMT_USER = `${ACCOUNT_MGMT_INDEX}/users/:userID` as const;
const CREATE_ACCOUNT = `${ACCOUNT_MGMT_INDEX}/accounts/create` as const;
const INVITE_USER = `${ACCOUNT_MGMT_USERS}/invite` as const;
const INVITE_USER_TO_ACCOUNT = `${ACCOUNT_MGMT_ACCOUNT}/invite-user` as const;

const DOCUMENTS_CREATE = '/documents/:documentType/create';

const STATIC_ROUTE = {
    HOME,
    RENTER_DASHBOARD,
    CUSTOMER_RESERVATIONS,
    RENTER_RESERVATION_REQUEST_FORM,
    LOGIN_CALLBACK_ROUTE,
    OKTA_AUTH_ROUTE,
    SIGN_IN,
    SIGN_UP,
    PASSWORD_RESET,
    PROFILE,
    ALERTS,

    RENTAL_MGMT_TRANSITIONS,

    RENTAL_MGMT_ROOT,

    MPUS_INDEX,
    MPU_FAULTS,

    RENTAL_MGMT_RESERVATIONS,
    RENTAL_MGMT_RESERVATIONS_TABLE,
    RENTAL_MGMT_RESERVATIONS_KANBAN,

    ACCOUNT_MGMT_INDEX,
    ACCOUNT_MGMT_ACCOUNTS,
    ACCOUNT_MGMT_USERS,
    CREATE_ACCOUNT,
    INVITE_USER,
} as const;

const DYNAMIC_ROUTE = {
    RENTER_RESERVATION_MONITORING,
    MPU_DETAIL,

    RENTAL_MGMT_RESERVATION_DETAIL,
    RENTAL_MGMT_UPDATE_UNIT_COUNT,

    ACCOUNT_MGMT_ACCOUNT,
    ACCOUNT_MGMT_USER,
    INVITE_USER_TO_ACCOUNT,

    DOCUMENTS_CREATE,
} as const;

interface Options {
    search?: ConstructorParameters<typeof URLSearchParams>[0];
    hash?: string;
    params?: {
        [key: string]: string | null | undefined;
    };
}

type StaticRouteName = keyof typeof STATIC_ROUTE;
type DynamicRouteName = keyof typeof DYNAMIC_ROUTE;

export type RouteName = StaticRouteName | DynamicRouteName;

function castUndefinedValuesToNull(obj: { [key: string]: string | null | undefined }): {
    [key: string]: string | null;
} {
    return Object.keys(obj).reduce((acc, cur) => {
        return {
            ...acc,
            [cur]: obj[cur] ?? null,
        };
    }, {});
}

/**
 * Given parts of the URL representing the pages state serialize into a string representing a URL (without origin)
 */
export function formatURLState({
    path = '',
    hash,
    search,
}: {
    path?: string;
    hash?: string;
    search?: ConstructorParameters<typeof URLSearchParams>[0];
}): string {
    const FAKE_BASE = 'http://replace-me';
    const isAbsolutePath = path[0] === '/';

    const url = new URL(path, FAKE_BASE);

    url.search = new URLSearchParams(search).toString();
    url.hash = hash ?? '';

    return url.toString().replace(FAKE_BASE + (isAbsolutePath ? '' : '/'), '');
}

/**
 * Constructs a path using the routes canonical name.
 *
 * @param name Required to identify route
 * @param options
 * @returns Route path
 */
export default function getPathByName(name: RouteName, options?: Options): string {
    const { params = {}, search, hash } = options ?? {};
    const targetPathPattern = STATIC_ROUTE[name] || DYNAMIC_ROUTE[name];

    let path = generatePath(targetPathPattern, castUndefinedValuesToNull(params));

    return formatURLState({ path, search, hash });
}
