import { ReactElement, useEffect, useRef, useState } from 'react';
/* eslint-disable */
// @ts-expect-error NOTE(will): this an inline webpack loader disable import; we also have to _rename_ the import back in the jest config
import maplibregl from '!maplibre-gl';
/* eslint-enable */

import { Expression } from 'mapbox-gl';
import {
    GeoJSONSource,
    Layer,
    LayerProps,
    LngLatBoundsLike,
    Map,
    MapRef,
    NavigationControl,
    Source,
} from 'react-map-gl';

import { Box, ClickAwayListener } from '@mui/material';

import { getMapTilerStyle } from 'environment';

import useTheme from 'styles/theme';
import { MuiCssDimension, MUIResponsiveValue } from 'styles/theme/types';

import enableHoverOnMarkers from './enableHoverOnMarkers';
import Popup from './MapPopup';
import UnitPopupContent from './UnitPopupContent';
import useBounds from './useBounds';

interface LatLon {
    lat: number;
    lon: number;
}

interface Unit {
    id: string;
    name: string;
    modelName?: string | null | undefined;
    timeToEmpty?: number | null | undefined;
    stateOfCharge?: number | null | undefined;
    href?: string;
    currentLocation?: LatLon | null;
    locationTimestamp?: string | null | undefined;
}

interface Props {
    /**
     * Unique identifier on the page for the map containing element
     */
    id?: string;

    /**
     * The units to display on the map
     */
    units?: Unit[];

    /**
     * An SOC percentage below this value will trigger the alert status
     */
    socAlertBoundary?: number;

    /**
     * The size of the map to use
     * - "preview" is used for maps that appear at the top of a page as a part of a larger page
     * - "fill" will fill the parent element (note caveats about percentage based dimensions)
     */
    size?: 'preview' | 'fill';
}

const MAP_STYLE = getMapTilerStyle();

function resolveHeight(size: string): MuiCssDimension | MUIResponsiveValue<MuiCssDimension> {
    if (size === 'fill') return '100%';

    // default is "preview"
    return { xs: 250, sm: 300, lg: 400 };
}

function toGEOJSON(units: Unit[]): GeoJSON.FeatureCollection<GeoJSON.Point> {
    return {
        type: 'FeatureCollection',
        features: units.map(({ currentLocation, ...properties }) => ({
            id: properties.id,
            type: 'Feature',
            properties,
            geometry: {
                type: 'Point',
                coordinates: [currentLocation!.lon, currentLocation!.lat],
            },
        })),
    };
}

const hover = (hoverValue, defaultValue): Expression => [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    hoverValue,
    defaultValue,
];

const getProperty = (key: string): Expression => ['get', key, ['properties']];

const isClustered: Expression = ['has', 'point_count'];
const isNotClustered: Expression = ['!', isClustered];

export const clusterLayer = ({ theme }): LayerProps => ({
    id: 'clusters',
    type: 'circle',
    source: 'units',
    filter: isClustered,
    paint: {
        'circle-color': [
            'case',
            ['>=', ['number', ['get', 'socAlertCount']], 1],
            theme.palette.background.dangerDark.main,
            theme.palette.background.contrast.main,
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
});

export const clusterCountLayer = ({ theme }): LayerProps => ({
    id: 'cluster-count',
    type: 'symbol',
    source: 'units',
    filter: isClustered,
    layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Inter'],
        'text-size': 16,
    },
    paint: {
        'text-color': theme.palette.text.contrast,
    },
});

export const unclusteredPointLayer = ({ theme, socAlertBoundary }): LayerProps => ({
    id: 'unclustered-point',
    type: 'circle',
    source: 'units',
    interactive: true,
    filter: isNotClustered,
    paint: {
        'circle-color': [
            'case',
            ['<=', ['number', getProperty('stateOfCharge')], socAlertBoundary],
            theme.palette.background.dangerDark.main,
            theme.palette.background.contrast.main,
        ],
        'circle-radius': hover(8, 6),
        'circle-stroke-width': hover(4, 2),
        'circle-stroke-color': theme.palette.background.contrast.contrastText,
    },
});

export const unclusteredPointShadowLayer = (): LayerProps => ({
    id: 'unclustered-point-shadow',
    type: 'circle',
    source: 'units',
    filter: isNotClustered,
    paint: {
        'circle-color': 'rgba(0,0,0,0.4)',
        'circle-radius': hover(24, 0),
        'circle-blur': 2,
    },
});

/**
 * Responsible for displaying and exploring units on a map. All units should be within view
 * unless panned away and / or zoomed
 */
export default function UnitsMap({ id, units, socAlertBoundary = 20, size = 'preview' }: Props): ReactElement {
    const theme = useTheme();

    const disableAutoFitBounds = useRef(false);
    const [activeUnit, setActiveUnit] = useState<Unit | undefined>();
    const [map, setMap] = useState<MapRef | null>(null);

    const unitsWithLocations = (units ?? []).filter(x => !!x?.currentLocation?.lat && !!x?.currentLocation?.lon);

    const mapViewBox: any = useBounds(unitsWithLocations.map(x => x.currentLocation ?? { lat: 0, lon: 0 }));
    const key = unitsWithLocations.map(x => x.id).join();

    const onManualInteract = ({ originalEvent }) => {
        // Programattic zooms and pans do not have an "originalEvent"
        if (!!originalEvent) {
            disableAutoFitBounds.current = true;
        }
    };

    const resolveClusterZoomLevel = event => {
        const feature = event?.features?.[0];
        const clusterId = feature?.properties?.cluster_id;

        if (!clusterId) return;

        const mapboxSource = map?.getSource('units') as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }

            map?.easeTo({
                center: feature?.geometry?.coordinates,
                zoom,
                duration: 500,
            });
        });
    };

    /**
     * Make sure we're showing all units even if some are added after initial render, but do not refit bounds
     * if the units haven't changed as it's quite disruptive
     */
    useEffect(() => {
        if (!disableAutoFitBounds.current) {
            map?.fitBounds(mapViewBox.bounds as LngLatBoundsLike, mapViewBox.fitBoundsOptions);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [key]);

    // Add interactivity to the map markers rendered as layers
    useEffect(() => {
        if (!map) return;

        function onClickPoint(e) {
            setActiveUnit({
                ...e?.features?.[0]?.properties,
                currentLocation: { lat: e.lngLat.lat, lon: e.lngLat.lng },
            });
        }

        map.on('click', 'unclustered-point', onClickPoint);

        const disableHover = enableHoverOnMarkers('unclustered-point', map);

        return () => {
            map.off('click', 'unclustered-point', onClickPoint);
            disableHover();
        };
    }, [map]);

    return (
        <Box
            id={id}
            position="relative"
            overflow="hidden"
            width="100%"
            sx={theme => ({
                borderRadius: `${theme.shape.borderRadius}px`,
                height: resolveHeight(size),
                backgroundColor: theme.palette.grey[100],

                '& .maplibregl-canvas:focus-visible': {
                    outline: 'none',
                },

                '& .maplibregl-ctrl-zoom-in:focus, & .maplibregl-ctrl-zoom-out:focus': {
                    outline: `2px solid ${theme.palette.primary.main}`,
                },
            })}
        >
            <ClickAwayListener onClickAway={() => setActiveUnit(undefined)}>
                <Map
                    mapLib={maplibregl}
                    initialViewState={mapViewBox}
                    mapStyle={MAP_STYLE}
                    attributionControl={false}
                    style={{ height: '100%' }}
                    ref={setMap}
                    dragRotate={false}
                    touchZoomRotate={false}
                    touchPitch={false}
                    cooperativeGestures
                    onZoomStart={onManualInteract}
                    onMoveStart={onManualInteract}
                    interactiveLayerIds={['clusters']}
                    onClick={resolveClusterZoomLevel}
                >
                    <Source
                        id="units"
                        type="geojson"
                        data={toGEOJSON(unitsWithLocations)}
                        cluster
                        clusterProperties={{
                            // Count how many of the points in a cluster have a low SOC, so we can update paint properties accordingly
                            socAlertCount: [
                                '+',
                                ['case', ['<', ['get', 'stateOfCharge', ['properties']], socAlertBoundary], 1, 0],
                            ],
                        }}
                        clusterMaxZoom={14}
                        clusterRadius={50}
                    >
                        {/* Controls Cluster styling and display */}
                        <Layer {...clusterLayer({ theme })} />
                        <Layer {...clusterCountLayer({ theme })} />

                        {/* Controls Marker styling and display */}
                        <Layer {...unclusteredPointShadowLayer()} />
                        <Layer {...unclusteredPointLayer({ theme, socAlertBoundary })} />
                    </Source>

                    {!!activeUnit?.currentLocation && (
                        <Popup
                            latitude={activeUnit?.currentLocation?.lat}
                            longitude={activeUnit?.currentLocation?.lon}
                            onClose={() => setActiveUnit(undefined)}
                            closeOnClick
                            closeButton={false}
                        >
                            <UnitPopupContent
                                name={activeUnit?.name}
                                modelName={activeUnit?.modelName}
                                stateOfCharge={activeUnit?.stateOfCharge}
                                timeToEmpty={activeUnit?.timeToEmpty}
                                socAlertBoundary={socAlertBoundary}
                                locationTimestamp={activeUnit?.locationTimestamp}
                                href={activeUnit?.href}
                            />
                        </Popup>
                    )}

                    <NavigationControl position="bottom-right" showCompass={false} />
                </Map>
            </ClickAwayListener>
        </Box>
    );
}
