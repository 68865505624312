import { ReactElement, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { Button, Input, ServiceAreaSelector, Text } from 'app/components';
import { useLayer } from 'app/core/layers';
import { LayerItemFacade } from 'app/core/layers/types';

import { ReservationStatus, ReservationType } from 'generated/graphql';

import { useI18n } from 'i18n';

import ReservationServiceTypeSelector from '../ReservationServiceTypeSelector';
import EditReservationDialog from './EditReservationDialog';

interface Props {
    reservationID: string;
    name?: string;
    serviceAreaID?: string;
    status: ReservationStatus;
    userNotes: string;
    unitCount: number;
    fulfillerNotes?: string;
    requestDate: DateTime;
    startDate: DateTime;
    endDate: DateTime;
    accountID: string;
    serviceType: ReservationType;
    onUpdate: (data: any) => void;
}

export default function ReservationInfo({
    reservationID,
    name,
    serviceAreaID,
    startDate,
    endDate,
    userNotes,
    unitCount,
    requestDate,
    fulfillerNotes,
    status,
    accountID,
    serviceType,
    onUpdate,
}: Props): ReactElement {
    const { t, format } = useI18n();
    const { dialog } = useLayer();
    const dialogRef = useRef<LayerItemFacade | null>(null);

    useEffect(() => {
        if (!dialogRef.current) return;

        dialogRef.current.update({
            reservationID,
            name,
            serviceType,
            startDate,
            endDate,
            status,
            serviceAreaID,
            unitCount,
            accountID,
            userNotes,
            fulfillerNotes,
        });
    }, [
        accountID,
        endDate,
        fulfillerNotes,
        name,
        reservationID,
        serviceAreaID,
        serviceType,
        startDate,
        status,
        unitCount,
        userNotes,
    ]);

    return (
        <Paper
            variant="outlined"
            sx={{
                p: 5,
                pt: 3,
            }}
        >
            <Box display="flex" flex-direction="row" justifyContent="space-between" alignItems="center" mb={4}>
                <Text variant="h3" as="h2">
                    {t('admin_reservation_page.heading.reservation_info')}
                </Text>

                <Button
                    ctaType="secondary"
                    onClick={() => {
                        dialogRef.current = dialog.add(
                            ({ close }) => ({
                                close,
                                onUpdate: values => {
                                    onUpdate(values);
                                    close();
                                },

                                reservationID,
                                name,
                                serviceType,
                                startDate,
                                endDate,
                                status,
                                serviceAreaID,
                                unitCount,
                                accountID,
                                userNotes,
                                fulfillerNotes,
                            }),
                            { Component: EditReservationDialog },
                        );
                    }}
                >
                    {t('admin_reservation_page.cta.edit_details')}
                </Button>
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={3}>
                    <ReservationServiceTypeSelector
                        fieldName={t('admin_reservation_page.label.service_type')}
                        value={serviceType}
                        fullWidth
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Input
                        fieldName={t('admin_reservation_page.label.name')}
                        value={name ?? t('admin_reservation_page.not_set_yet')}
                        fullWidth
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Input
                        fieldName={t('admin_reservation_page.label.number_of_units')}
                        value={unitCount}
                        fullWidth
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Input
                        fieldName={t('admin_reservation_page.label.request_date')}
                        value={format.date(requestDate)}
                        fullWidth
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Input
                        fieldName={t('admin_reservation_page.label.start_date')}
                        value={format.dateTime(startDate)}
                        fullWidth
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Input
                        fieldName={t('admin_reservation_page.label.end_date')}
                        value={format.dateTime(endDate)}
                        fullWidth
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <ServiceAreaSelector
                        accountID={accountID}
                        value={serviceAreaID}
                        fieldName={t('admin_reservation_page.label.service_area')}
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Input fieldName={t('admin_reservation_page.label.id')} value={reservationID} fullWidth readOnly />
                </Grid>

                {/* Grid Break, to ensure notes section layout starts on it's own row */}
                <Grid item xs={12} mt={-4}></Grid>

                <Grid item xs={12} lg={6}>
                    <Input
                        fieldName={t('admin_reservation_page.label.notes')}
                        value={userNotes}
                        fullWidth
                        multiline
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Input
                        fieldName={t('admin_reservation_page.label.fulfiller_notes')}
                        value={fulfillerNotes}
                        fullWidth
                        multiline
                        readOnly
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
