import { ComponentProps, MouseEventHandler, ReactElement, useCallback } from 'react';

import { Button } from 'app/components/primitives';

import { useI18n } from 'i18n';

import { scrollToDate } from './actions';
import { useScheduleContext } from './ScheduleContext';

interface ScheduleInitialDateButtonProps extends ComponentProps<typeof Button> {
    /**
     * Determines if the button should display the text for today or returning to start.
     */
    isToday?: boolean;
}

/**
 * Presentational component for adding a search box and filter menu to the `Schedule` component.
 *
 * @example
 *
 * <ScheduleInitialDateButton onClick={() => console.log('Today button clicked')} />
 */
export function ScheduleInitialDateButton({
    isToday,
    onClick,
    ...otherProps
}: ScheduleInitialDateButtonProps): ReactElement {
    const { t } = useI18n();
    const { data, dispatch } = useScheduleContext();
    const { initialDate } = data.config;

    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        event => {
            onClick?.(event);

            if (event.defaultPrevented) return;

            dispatch(scrollToDate(initialDate));
        },
        [dispatch, initialDate, onClick],
    );

    const message = isToday ? t('schedule_view.today') : t('schedule_view.back_to_start');

    return (
        <Button ctaType="secondary" onClick={handleClick} {...otherProps}>
            {message}
        </Button>
    );
}
