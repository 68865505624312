import { ReactElement } from 'react';
import { YAxis } from 'recharts';

import { CHART_Y_AXIS_WIDTH } from '../config';

interface Props {
    isSpacer?: boolean;
}

/**
 * Render function to produce the YAxis for historical faults chart
 * Note: this needs to be a render function for ReCharts to work properly.
 */
export default function renderFaultNameAxis({ isSpacer }: Props): ReactElement {
    return (
        <YAxis
            width={CHART_Y_AXIS_WIDTH}
            axisLine={false}
            mirror={false}
            dataKey="name"
            type="category"
            orientation="left"
            minTickGap={8}
            tickLine={!isSpacer}
            tickFormatter={isSpacer ? () => '' : undefined}
        />
    );
}
