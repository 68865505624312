import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';

import FieldLabel from 'app/components/primitives/FieldLabel';

import { useI18n } from 'i18n';

interface DirectionSelectorProps {
    value: string;
    onChange: (value: string) => void;
    fieldName?: string;
    name?: string;
}

const DirectionSelector: React.FC<DirectionSelectorProps> = ({ value, onChange, fieldName, name }) => {
    const { t } = useI18n();

    return (
        <Stack spacing={2}>
            <FieldLabel fieldName={fieldName || t('documents.components.direction_selector')} />

            <RadioGroup
                sx={{ flexDirection: 'row', gap: 4 }}
                name={name || 'direction-selector'}
                value={value}
                onChange={event => {
                    onChange(event.target.value);
                }}
                id="direction-selector"
            >
                <FormControlLabel
                    value="outbound"
                    control={<Radio />}
                    label={t('documents.components.direction_selector_options.outbound')}
                />

                <FormControlLabel
                    value="inbound"
                    control={<Radio />}
                    label={t('documents.components.direction_selector_options.inbound')}
                />
            </RadioGroup>
        </Stack>
    );
};

export default DirectionSelector;
