import { useId, useState } from 'react';
import { DateTime } from 'luxon';

import { Button, Dialog } from 'app/components';

import { ReservationStatus, ReservationType } from 'generated/graphql';

import { useI18n } from 'i18n';

import EditReservationForm, { UpdateReservationInput } from './EditReservationForm';

interface EditReservationDialogProps {
    close: () => void;

    reservationID: string;
    name?: string;
    serviceType: ReservationType;
    serviceAreaID?: string;
    unitCount: number;
    status: ReservationStatus;
    userNotes: string;
    fulfillerNotes?: string;
    startDate: DateTime;
    endDate: DateTime;
    accountID: string;
    onUpdate: (data: UpdateReservationInput) => void;
}

export default function EditReservationDialog({
    close,
    onUpdate,

    reservationID,
    name = '',
    serviceType,
    startDate,
    endDate,
    status,
    serviceAreaID,
    unitCount,
    accountID,
    userNotes,
    fulfillerNotes,
}: EditReservationDialogProps) {
    const { t } = useI18n();
    const formID = useId();

    const [shouldDisableCTA, setShouldDisableCTA] = useState<boolean>(false);

    const shouldDisableDurationEditing =
        status === ReservationStatus.Cancelled || status === ReservationStatus.Completed;

    return (
        <Dialog
            title={t('admin_reservation_page.heading.edit_reservation_info')}
            onClose={close}
            primaryCTA={
                <Button ctaType="primary" type="submit" form={formID} disabled={shouldDisableCTA}>
                    {t('cta.save')}
                </Button>
            }
        >
            <EditReservationForm
                reservationID={reservationID}
                serviceType={serviceType}
                name={name}
                startDate={startDate}
                endDate={endDate}
                userNotes={userNotes}
                fulfillerNotes={fulfillerNotes}
                status={status}
                serviceAreaID={serviceAreaID}
                unitCount={unitCount}
                accountID={accountID}
                shouldDisableDurationEditing={shouldDisableDurationEditing}
                onSubmit={values => {
                    onUpdate(values);
                    close();
                }}
                formID={formID}
                onFormStateChange={({ shouldDisableCTA }) => setShouldDisableCTA(shouldDisableCTA)}
            />
        </Dialog>
    );
}
