import { ReactElement, ReactNode, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import EditAddressModal, { SelectedLocation } from 'app/components/compounds/EditAddressModal';
import Icon from 'app/components/primitives/Icon';
import Text from 'app/components/primitives/Text';
import { useLayer } from 'app/core/layers';

import {
    AccountLocation,
    useAccountLocationsByAccountQuery,
    useCreateAccountLocationMutation,
} from 'generated/graphql';

import { useI18n } from 'i18n';

import { useInputForAutocomplete } from '../../primitives/interactive/Input';

export type Location = Pick<AccountLocation, 'id' | 'contactName' | 'formattedAddress'>;

interface Props {
    /**
     * ID used for a11y features, if not provided one will be generated
     */
    id?: string;

    /**
     * The accountID used to look up associated account locations tied to an account.
     * The accountID is allowed to be undefined to handle transient loading / resolution of the accountID.
     */
    accountID?: string | null | undefined;

    /**
     * The primary label for the form control, identifying what the entered value is for
     */
    fieldName?: ReactNode;

    /**
     * Provides the name for the value to access at the form level
     */
    name?: string;

    /**
     * The selected location (null if unset)
     */
    value?: string | undefined | null;

    /**
     * Whether the field is required
     */
    required?: boolean;

    /**
     * Whether the field contains a validation error or not
     */
    error?: boolean;

    /**
     * The light side of the error prop. Feedback to the user that the input is valid / acceptable
     */
    valid?: boolean;

    /**
     * Whether the input is disabled
     */
    disabled?: boolean;

    /**
     * Whether the input is static / read only
     */
    readOnly?: boolean;

    /**
     * Whether to fill the parent elements width
     */
    fullWidth?: boolean;

    /**
     * Validation messaging or other helpful text. When error prop is true message will be formatted as an error.
     */
    caption?: ReactNode;

    /**
     * Event handler to be called when the form control is focused
     */
    onFocus?: (event: any) => void;

    /**
     * Event handler to be called when a user selects a location or clears the location
     */
    onBlur?: (event: any) => void;

    /**
     * Event handler to be called when a user selects a location or clears the location
     */
    onChange: (event: { name: string | undefined; value: Location | null }) => void;

    /**
     * Whether to include location icon as startAdornment in input
     */
    showIcon?: boolean;
}

const createLocationCtaID = 'create_new_location';

/**
 * Responsible for providing an input control for users to select an account location from a list of saved
 * account locations on a specific account. This is a controlled input and requires value and onChange to be wired up.
 */
export default function AccountLocationSelector({
    id,
    required = false,
    error = false,
    valid = false,
    readOnly = false,
    disabled = false,
    accountID = null,
    fullWidth = true,
    fieldName,
    caption,
    name,
    value,
    showIcon = false,

    onFocus,
    onBlur,
    onChange,
}: Props): ReactElement {
    const { t } = useI18n();
    const { toast } = useLayer();
    const [isEnteringNewLocation, setIsEnteringNewLocation] = useState<boolean>(false);

    const renderInput = useInputForAutocomplete({
        error,
        valid,
        fieldName,
        caption,
        readOnly,
        required,
        disabled,
        fullWidth,
        startAdornment: showIcon ? <Icon name="location" /> : undefined,
    });
    const [createAccountLocation] = useCreateAccountLocationMutation();
    const { data, refetch } = useAccountLocationsByAccountQuery({
        skip: !accountID,
        variables: { accountId: accountID ?? '' },
    });

    async function saveEditAddress(selectedLocation: SelectedLocation) {
        let result;

        try {
            // TODO(derek): expose error to consumers
            const { data } = await createAccountLocation({
                variables: {
                    accountLocationInput: {
                        accountId: accountID ?? '',
                        ...selectedLocation,
                    },
                },
            });

            result = data?.createAccountLocation;
        } catch (error) {
            toast.add({ severity: 'error', message: t('error.save_location_failed') });

            throw error;
        }

        onChange({
            name,
            value: {
                id: result?.id ?? '',
                contactName: result?.contactName ?? '',
                formattedAddress: result?.formattedAddress,
            },
        });

        await refetch();
        setIsEnteringNewLocation(false);
    }

    const locations = data?.accountLocationsByAccount ?? [];
    const locationsByID = locations.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    const options = [''].concat(locations.map(x => x.id));

    return (
        <>
            <Autocomplete
                id={id}
                fullWidth={fullWidth}
                disabled={disabled || !accountID}
                readOnly={readOnly}
                autoHighlight
                clearOnBlur={false}
                value={value ?? ''}
                options={options}
                disableClearable
                forcePopupIcon={!readOnly}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={renderInput}
                renderOption={(props, option) => {
                    const location = locationsByID[option];

                    return (
                        <li {...props} key={option}>
                            {option === createLocationCtaID ? (
                                <Stack direction="row" sx={{ color: 'text.info', gap: 2 }}>
                                    <Icon name="add-circle" />
                                    <div>{t('cta.create_location')}</div>
                                </Stack>
                            ) : (
                                <Stack>
                                    <Text as="p">{location.contactName}</Text>
                                    <Text variant="detail" as="p" color="secondary">
                                        {location.formattedAddress}
                                    </Text>
                                </Stack>
                            )}
                        </li>
                    );
                }}
                getOptionLabel={option => {
                    const location = locationsByID[option] ?? {};

                    return `${location.contactName ?? ''}${
                        location.contactName && location.formattedAddress ? ' — ' : ''
                    }${location.formattedAddress ?? ''}`;
                }}
                filterOptions={(options, params) => {
                    const inputValue = params.inputValue.toLowerCase();
                    const filteredOptions = options.filter(item => {
                        const location = locationsByID[item];

                        return (
                            location?.contactName?.toLowerCase().includes(inputValue) ||
                            location?.formattedAddress?.toLowerCase().includes(inputValue)
                        );
                    });

                    if (!inputValue) {
                        return [createLocationCtaID].concat(filteredOptions);
                    }

                    return filteredOptions.concat(createLocationCtaID);
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(event, newValue) => {
                    if (newValue === createLocationCtaID) {
                        setIsEnteringNewLocation(true);
                    } else {
                        onChange({ name, value: locationsByID[newValue ?? ''] });
                    }
                }}
            />

            <EditAddressModal
                isOpen={isEnteringNewLocation}
                onClose={() => setIsEnteringNewLocation(false)}
                onSave={saveEditAddress}
            />
        </>
    );
}
