import { useListServiceAreasForSelectorQuery } from 'generated/graphql';

import { TranslateFunction } from 'i18n';

import { UNASSIGNED_SERVICE_AREA_OPTION_ID } from './config';

interface ServiceArea {
    id: string;
    name: string;
}

interface Output {
    loading: boolean;
    serviceAreas: ServiceArea[];
    serviceAreaByID: { [id: string]: ServiceArea };
}

/**
 * Retrieves all service ares for a given account
 */
export default function useServiceAreas({ accountID, t }: { accountID?: string; t: TranslateFunction }): Output {
    const { loading, data } = useListServiceAreasForSelectorQuery({
        skip: !accountID,
        variables: {
            accountID: '', // HERE BE DRAGONS: we don't support fulfiller accountID yet, so keep it empty to get them all
        },
    });

    const serviceAreas = (data?.listServiceAreas ?? [])
        .map(({ id, name }) => ({ id, name }))
        .concat({
            id: UNASSIGNED_SERVICE_AREA_OPTION_ID,
            name: t('service_area_selector.unassigned'),
        });

    const serviceAreaByID = serviceAreas.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});

    return {
        loading,
        serviceAreas,
        serviceAreaByID,
    };
}
