import { ComponentProps, ReactElement, useId } from 'react';
import { motion } from 'framer-motion';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import Icon from 'app/components/primitives/Icon';
import Checkbox from 'app/components/primitives/interactive/Checkbox';
import Input, { useInputForAutocomplete } from 'app/components/primitives/interactive/Input';
import Cluster from 'app/components/primitives/layout/Cluster';
import Tag from 'app/components/primitives/Tag';
import Text from 'app/components/primitives/Text';

import { MUIMarginProps } from 'styles/theme/types';

interface Option {
    label: string;

    id: string;
}

interface Props
    extends MUIMarginProps,
        Pick<ComponentProps<typeof Autocomplete>, 'loading' | 'loadingText' | 'noOptionsText'>,
        Omit<ComponentProps<typeof Input>, 'select' | 'children' | 'sx' | 'value' | 'onChange'> {
    /**
     * The selected value
     */
    value?: string[];

    /**
     * The role options to choose from
     */
    options: Option[];

    /**
     * Event handler to be called when the value changes
     */
    onChange?: (event: {
        name?: string;
        value: string[];
        selectedOptions: { id: string; label: string }[];
        added?: string;
        removed?: string;
    }) => void;

    /**
     * Options that cannot be checked or unchecked
     */
    disabledOptions?: string[];
}
/**
 * Responsible for surfacing multiple options of the same type to a user
 */
export default function MultiSelect({
    fieldName,
    caption,
    name,
    value,
    required,
    readOnly,
    error,
    disabled,
    valid,
    fullWidth = true,
    id,
    options,
    loading,
    loadingText,
    noOptionsText,
    placeholder,
    onChange,
    disabledOptions,
}: Props): ReactElement {
    const _id = useId();
    const resolvedID = id || _id;
    const optionByID = options.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.id]: cur,
        };
    }, {});

    const renderInput = useInputForAutocomplete({
        id,
        fieldName,
        caption,
        name,
        required,
        readOnly,
        disabled,
        fullWidth,
        valid,
        error,
        placeholder,
    });

    return (
        <Autocomplete
            loading={loading}
            loadingText={loadingText}
            noOptionsText={noOptionsText}
            id={resolvedID}
            disabled={disabled}
            openOnFocus
            readOnly={readOnly}
            fullWidth={fullWidth}
            autoHighlight
            value={value ?? []}
            multiple={true}
            disableCloseOnSelect
            disableClearable={(disabledOptions && disabledOptions.length > 0) || required || readOnly}
            disablePortal={false}
            forcePopupIcon={!readOnly}
            sx={{
                '.MuiInputBase-root': {
                    flexWrap: 'wrap',

                    '> input': {
                        maxWidth: 200,
                    },
                },
            }}
            isOptionEqualToValue={(option, currentOption) => {
                return option === currentOption;
            }}
            options={options.map(x => x.id)}
            renderTags={(value, getTagProps) => {
                return (
                    <Cluster my={2}>
                        {value.map((x, i) => {
                            const label = optionByID[x]?.label;
                            const props = getTagProps({ index: i });
                            const isOptionDisabled = disabledOptions?.includes(x);
                            const isOptionDeletable = !(isOptionDisabled || readOnly);

                            if (!label) return null;

                            return (
                                <motion.div key={x} layoutId={`${resolvedID}-${x}`} layout="position">
                                    <Tag
                                        outlined
                                        label={label}
                                        onClick={isOptionDeletable ? props.onDelete : undefined}
                                        endIcon={isOptionDeletable ? <Icon name="close" /> : undefined}
                                        disabled={disabled || isOptionDisabled}
                                    />
                                </motion.div>
                            );
                        })}
                    </Cluster>
                );
            }}
            renderOption={(props, option, { selected }) => {
                const isOptionDisabled = disabledOptions?.includes(option);
                return (
                    <Box component="li" key={option} {...props} onClick={isOptionDisabled ? undefined : props.onClick}>
                        <Checkbox
                            as="div"
                            checked={selected}
                            disabled={isOptionDisabled}
                            label={<Text maxLines={2}>{optionByID[option]?.label}</Text>}
                        />
                    </Box>
                );
            }}
            getOptionLabel={option => {
                return optionByID[option]?.label ?? '';
            }}
            renderInput={renderInput}
            onChange={(event, newValue, action, change) => {
                onChange?.({
                    name,
                    value: newValue,
                    selectedOptions: newValue.map(x => optionByID[x]),
                    added: action === 'selectOption' ? change?.option : undefined,
                    removed: action === 'removeOption' ? change?.option : undefined,
                });
            }}
        />
    );
}
