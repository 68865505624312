import React from 'react';
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import TableCell from './TableCell';
import TableHead from './TableHead';
import TableRow from './TableRow';
import { BaseText, BoldText } from './Text';

const tableStyles: Style = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
};

interface TableProps {
    children: React.ReactNode;
    style?: Style;
}

const Table: React.FC<TableProps> = ({ children, style = {} }) => {
    return <View style={{ ...tableStyles, ...style }}>{children}</View>;
};

export { TableCell, TableHead, TableRow, BaseText, BoldText, Table };
