import { generatePath, matchPath, redirect, RouteObject } from 'react-router-dom';

import { formatURLState } from './getPathByName';

/**
 * Makes sure both param keys exist so paths with dynamic segments can be generated properly
 */
function mergeParams({
    mapParams,
    params,
}: {
    mapParams?: { [key: string]: string };
    params?: { [key: string]: string | undefined };
}): { [key: string]: string | undefined } {
    return Object.keys(mapParams ?? {}).reduce((acc, cur) => {
        if (!mapParams?.[cur]) return acc;

        return {
            ...acc,
            [mapParams[cur]]: params?.[cur],
        };
    }, params ?? {});
}

/**
 * Creates a route for the pure purpose of redirecting to another route
 */
export default function makeRedirectRoute({
    path,
    target,
    mapParams,
}: {
    path: string;
    target: string;
    mapParams?: { [key: string]: string };
}): RouteObject {
    return {
        loader: ({ request, params }) => {
            const url = new URL(request.url);

            if (matchPath(path, url.pathname)) {
                url.pathname = target;

                return redirect(
                    formatURLState({
                        path: generatePath(url.toString().replace(url.origin, ''), mergeParams({ mapParams, params })),
                        hash: window.location.hash,
                        search: window.location.search,
                    }),
                );
            }

            return true;
        },
        path,
        element: <></>,
    };
}
