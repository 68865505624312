/**
 * This represents our global palette of colors. It should not be used in the application directly
 * @link {Figma | https://www.figma.com/file/YciLfT6CX2LH3S1zhqeaaD/Moxie-DS-Components?type=design&node-id=1%3A8688&t=E7qqiRgx475A6oIO-1}
 */
const COLOR_PALETTE = {
    PRIMARY_DARKEST: '#2c3733',
    PRIMARY_DARKER: '#565f5c',
    PRIMARY: '#9ea3a1',
    PRIMARY_LIGHTER: '#bec1c0',
    PRIMARY_LIGHTEST: '#eaebeb',

    SECONDARY_DARKEST: '#0b3742',
    SECONDARY_DARKER: '#115C6f',
    SECONDARY: '#17768D',
    SECONDARY_LIGHTER: '#96c6d2',
    SECONDARY_LIGHTEST: '#e8f3f5',

    TERTIARY_DARKEST: '#2f6a58',
    TERTIARY_DARKER: '#4fb394',
    TERTIARY: '#6ffcd1',
    TERTIARY_LIGHTER: '#bdfeea',
    TERTIARY_LIGHTEST: '#f1fffa',

    SUCCESS_DARKER: '#387f69',
    SUCCESS: '#4fb394',
    SUCCESS_LIGHTER: '#aedcce',
    SUCCESS_LIGHTEST: '#edf7f4',

    WARNING_DARKEST: '#6b4805',
    WARNING_DARKER: '#9b6b08',
    WARNING: '#ffac0c',
    WARNING_LIGHTER: '#ffd98f',
    WARNING_LIGHTEST: '#fff7e7',

    DANGER_DARKEST: '#7e1111',
    DANGER_DARKER: '#a31616',
    DANGER: '#e51f1f',
    DANGER_LIGHTER: '#f39898',
    DANGER_LIGHTEST: '#fce9e9',

    // Grey scale
    BLACK: '#121212',
    GREY_DARKEST: '#414141',
    GREY_DARKER: '#686665',
    GREY: '#b0adab',
    GREY_LIGHTER: '#e2dedb',
    GREY_LIGHTEST: '#fcfaf9',
    WHITE: '#fff',
} as const;

export default COLOR_PALETTE;
