import { ComponentProps, ReactElement, ReactNode, useMemo } from 'react';
import { DateTime } from 'luxon';

import { DateField as DateFieldBase } from '@mui/x-date-pickers/DateField';

import { IANATimeZone, ISO8601 } from 'app/core/types';

import FieldCaption from '../../FieldCaption';
import FieldLabel from '../../FieldLabel';
import { DateChangeEvent, nullifyTime, useDateFieldValue } from '../date-time-inputs';

type BaseProps = ComponentProps<typeof DateFieldBase<DateTime>>;

interface DateFieldProps
    extends Pick<
        BaseProps,
        | 'disabled'
        | 'disablePast'
        | 'focused'
        | 'fullWidth'
        | 'id'
        | 'inputRef'
        | 'onBlur'
        | 'onError'
        | 'onFocus'
        | 'onKeyDown'
        | 'onKeyUp'
        | 'onSelectedSectionsChange'
        | 'required'
        | 'sx'
    > {
    /**
     * Caption supplies hints and inline error messages related to the
     * Input field
     *
     * @see `Input` component
     */
    caption?: ReactNode;

    /**
     * Content to display at the end of the Input.
     */
    endAdornment?: ReactNode;

    /**
     * Whether the date field value has validation errors
     */
    error?: boolean;

    /**
     * Field name is the primary text for the Input's label.
     * It is optional so the Input can be used outside of forms
     * as a more "app-y" general purpose input
     *
     * @see `Input` component
     */
    fieldName?: ReactNode;

    /**
     * Determines whether the caption is hidden
     */
    hideCaption?: boolean;

    /**
     * Maximal selectable date.
     * @example
     * "2021-01-01"
     */
    maxDate?: ISO8601 | null;

    /**
     * Minimal selectable date.
     * @example
     * "2021-01-01"
     */
    minDate?: ISO8601 | null;

    /**
     * The name to be used for the change event target.
     *
     * @remarks
     *
     * This allows the component to directly interface with libraries
     * like `Formik`.
     */
    name?: string;

    /**
     * Event handler to be called when either the date or the hour changes
     */
    onChange?: (event: DateChangeEvent) => void;

    /**
     * Content to display at the start of the Input.
     */
    startAdornment?: ReactNode;

    /**
     * The time zone to use when parsing and formatting the date.
     *
     * @default "America/Los_Angeles"
     */
    timeZone?: IANATimeZone;

    /**
     * @example
     * "2021-01-01"
     */
    value: ISO8601 | null | undefined;
}

/**
 * A date field that allows the user to select a date.
 *
 * @remarks
 *
 * - This component should be used in place of the native `<input type="date">` element.
 *
 * @privateRemarks
 *
 * - ISO8601 Date strings are used as the value type instead of Luxon `DateTime` objects.
 * - The use of Luxon is considered a private API detail of this component.
 *
 * TODO(Morris): Add support for `readOnly` prop.
 */
export default function DateField(props: DateFieldProps): ReactElement {
    const {
        caption,
        endAdornment,
        error,
        fieldName,
        hideCaption,
        maxDate,
        minDate,
        name,
        onChange,
        required,
        startAdornment,
        timeZone,
        value,
        ...otherProps
    } = props;

    const { dateTime, handleChange } = useDateFieldValue({ name, onChange, timeZone, value });

    const maxDateTime = useMemo(() => {
        return maxDate ? nullifyTime(maxDate, timeZone) : undefined;
    }, [maxDate, timeZone]);

    const minDateTime = useMemo(() => {
        return minDate ? nullifyTime(minDate, timeZone) : undefined;
    }, [minDate, timeZone]);

    return (
        <DateFieldBase
            {...otherProps}
            helperText={hideCaption ? undefined : <FieldCaption caption={caption} error={error} />}
            InputProps={{ error }}
            label={fieldName ? <FieldLabel fieldName={fieldName} gutterBottom={false} required={required} /> : null}
            maxDate={maxDateTime}
            minDate={minDateTime}
            onChange={handleChange}
            required={required}
            slotProps={{
                textField: {
                    InputProps: {
                        endAdornment,
                        startAdornment,
                    },
                },
            }}
            value={dateTime}
        />
    );
}
