import { useMemo, useState } from 'react';
import { DateTime as LuxonDateTime } from 'luxon';

import { ValueOptions } from '@mui/x-data-grid';

import { useListServiceAreasLazyQuery } from 'generated/graphql';

export default function useServiceAreaFilterOptions() {
    const [fetchTime, setFetchTime] = useState(LuxonDateTime.now());
    const [listServiceAreas, { data }] = useListServiceAreasLazyQuery();

    const serviceAreaValueOptions: ValueOptions[] = useMemo(() => {
        const serviceAreaMapBase: { [key: string]: string } = {};
        return Object.entries(
            (data?.listServiceAreas ?? []).reduce((acc, serviceArea) => {
                if (serviceArea.name) {
                    acc[serviceArea.id] = serviceArea.name;
                }
                return acc;
            }, serviceAreaMapBase),
        ).map(([id, name]) => ({ value: id, label: name }));
    }, [data?.listServiceAreas]);

    async function fetchServiceAreaValueOptions() {
        // refresh the data if older than 1 minute
        const isOutdated = Math.abs(fetchTime.diffNow().as('seconds')) > 60;

        if (serviceAreaValueOptions.length === 0 || isOutdated) {
            await listServiceAreas();
            setFetchTime(LuxonDateTime.now());
        }
    }

    return { fetchServiceAreaValueOptions, serviceAreaValueOptions };
}
