import { ReactNode, useCallback } from 'react';

import Stack from '@mui/material/Stack';

import { TooltipContentRendererPayload } from 'app/components/compounds/Schedule';

import { MpuScheduleEvent } from '../types';
import { ScheduleNavigationEvent, TooltipContentSection } from './TooltipContentSection';

/**
 * @returns A function that renders the tooltip content for a given MPU schedule event.
 */
export function useMpuTooltipContentRenderer({
    onScheduleNavigation,
}: {
    onScheduleNavigation?: (event: ScheduleNavigationEvent) => void;
} = {}) {
    return useCallback(
        function renderTooltipContent({ event }: TooltipContentRendererPayload<MpuScheduleEvent>): ReactNode {
            if (!event?.schedules.length) return null;

            return (
                <Stack spacing={4} maxHeight="600px" sx={{ overflowY: 'auto' }}>
                    {event.schedules.map(schedule => (
                        <TooltipContentSection
                            key={schedule.id}
                            onScheduleNavigation={onScheduleNavigation}
                            schedule={schedule}
                            scheduleEvent={event}
                        />
                    ))}
                </Stack>
            );
        },
        [onScheduleNavigation],
    );
}
