import { ElementType, FC, useEffect, useState } from 'react';
import { InViewHookResponse, useInView } from 'react-intersection-observer';

function loadIcon(type) {
    return import(`app/assets/icons/react/${type}`).then(result => {
        return result.default;
    });
}

type SVGIconComponent = FC | ElementType;

interface Output {
    ref: InViewHookResponse['ref'];
    IconSvg: SVGIconComponent;
}

/**
 * Note(Derek): I was hoping to use the module cache instead but relying on the async import causes
 * the icons to blink as they are changed even if they had been previously loaded.
 */
const loadedIcons = {};

export default function useLazyIcon(name?: string, options: { skip?: boolean } = {}): Output {
    const [IconSvg, setIconSvg] = useState<SVGIconComponent>('span');
    const { ref, inView: isInView } = useInView({ triggerOnce: true, skip: options.skip });

    useEffect(() => {
        if (!isInView || !name || loadedIcons[name]) return;

        loadIcon(name).then(I => {
            setIconSvg(I);
            loadedIcons[name] = I;
        });
    }, [name, isInView]);

    return {
        ref,
        IconSvg: loadedIcons[name ?? ''] ?? IconSvg,
    };
}
