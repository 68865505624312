import { Popup } from 'react-map-gl';
import styled from 'styled-components';

const StyledPopup = styled(Popup)`
    max-width: 300px !important;

    > .maplibregl-popup-content {
        overflow: hidden;
        border-radius: 24px;
        padding: 0;
    }
`;

export default StyledPopup;
