import { useLayoutEffect } from 'react';

function allowPageScroll() {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = 'unset';
}

/**
 * Prevents page scrolling when enabled
 */
export default function usePageScrollLock({ enabled }: { enabled: boolean }): void {
    useLayoutEffect(() => {
        const hasScrollbar = window.document.body.scrollHeight > window.document.body.clientHeight;

        if (enabled) {
            document.body.style.overflow = 'hidden';

            if (hasScrollbar) {
                // Preserve scrollbar width
                document.body.style.paddingRight = '15px';
            }
        } else {
            allowPageScroll();
        }

        return () => {
            allowPageScroll();
        };
    }, [enabled]);
}
