import React from 'react';

import { Button, Dialog } from 'app/components';

import { useI18n } from 'i18n';

import SaveFilterForm from './forms/SaveFilter';

const SaveFilterModal = ({ onClose, formId, onSubmit }) => {
    const { t } = useI18n();

    return (
        <Dialog
            onClose={onClose}
            title={t('filters.save_filter')}
            primaryCTA={
                <Button ctaType="primary" type="submit" form={formId}>
                    {t('filters.save_filter')}
                </Button>
            }
        >
            <SaveFilterForm formId={formId} onSubmit={onSubmit} />
        </Dialog>
    );
};

export default SaveFilterModal;
