import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Text from 'app/components/primitives/Text';

import { UserStatus } from 'generated/graphql';

import { useI18n } from 'i18n';

// types
import { TeamMember } from './types';

interface Props extends TableContainerProps {
    members?: TeamMember[];
    onInvite?: ({ email }: { email: string }) => Promise<void>;
}

/**
 * Display of all account team members and important information about each member
 */
const TeamMembers = ({ members = [], onInvite = () => Promise.resolve(), ...props }: Props): JSX.Element => {
    const { t } = useI18n();

    return (
        <TableContainer {...props}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('account_settings.label.user_name')}</TableCell>
                        <TableCell>{t('account_settings.label.user_member_status')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {members?.map(user => {
                        return (
                            <TableRow key={user.email}>
                                <TableCell>
                                    <Stack>
                                        <Text
                                            as="p"
                                            sx={{ position: 'relative', top: !user.name ? '0.6rem' : 0 }}
                                            color={user.status === UserStatus.Deactivated ? 'disabled' : 'primary'}
                                        >
                                            {user.name ?? user.email}
                                        </Text>
                                        <Text
                                            variant="detail"
                                            as="p"
                                            color={user.status === UserStatus.Deactivated ? 'disabled' : 'secondary'}
                                        >
                                            {user.name ? user.email : <>&nbsp;</>}
                                        </Text>
                                    </Stack>
                                </TableCell>
                                <TableCell> {t(`account_settings.user_member_status.${user.status}`)}</TableCell>
                                <TableCell>
                                    {user.status === UserStatus.Invited && (
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    onInvite({ email: user.email });
                                                }}
                                            >
                                                {t('cta.resend_invite')}
                                            </Button>
                                        </Box>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TeamMembers;
