import EventEmitter from './EventEmitter';
import { makeGQLClient } from './network';
import { Session } from './Session';

/**
 * Bootstraps app wide dependencies
 */
export default function initAppCore() {
    const sessionEventEmitter = new EventEmitter();

    const notifyWhenAPIUnauthenticated = ({ graphQLErrors, operation }) => {
        if (
            !Session.isSessionOperationName(operation.operationName) &&
            Session.hasUnauthenticatedError(graphQLErrors)
        ) {
            sessionEventEmitter.emit('signedOut', undefined);
        }
    };

    const gqlClient = makeGQLClient({
        onGQLErrors: notifyWhenAPIUnauthenticated,
    });

    const session = new Session({ client: gqlClient, eventEmitter: sessionEventEmitter });

    return {
        client: gqlClient,
        session,
    };
}
