import { DateTime as LuxonDateTime } from 'luxon';

interface ReservationItem {
    deviceInstanceSchedule: {
        start?: string;
        end?: string;
    }[];
    start: string;
    end: string;
}

function isLuxonDateSameDayOrAfter(date1: LuxonDateTime, date2: LuxonDateTime) {
    return date1.startOf('day') >= date2.startOf('day');
}

export function getDaysUntilNextUnallocatedDay(
    reservationItem: Pick<ReservationItem, 'deviceInstanceSchedule' | 'start' | 'end'>,
): number | null {
    if (reservationItem.deviceInstanceSchedule === undefined) {
        return LuxonDateTime.fromISO(reservationItem.start).diffNow().as('days');
    }

    const lastDay = LuxonDateTime.fromISO(reservationItem.end);
    let foundLastDay: LuxonDateTime | undefined;
    for (const schedule of reservationItem.deviceInstanceSchedule) {
        if (!schedule.end) continue;

        const tmpLastDay = LuxonDateTime.fromISO(schedule.end);
        if (!foundLastDay || (tmpLastDay.isValid && tmpLastDay > foundLastDay)) {
            foundLastDay = tmpLastDay;
        }
    }

    if (!foundLastDay) {
        const diffInDays = LuxonDateTime.fromISO(reservationItem.start).diffNow().as('days');

        if (diffInDays < 1) return null;
        return diffInDays;
    }

    if (isLuxonDateSameDayOrAfter(foundLastDay, lastDay)) return null;

    return foundLastDay.diffNow().as('days');
}

export function isAllocationRequired(
    reservationItem: Pick<ReservationItem, 'deviceInstanceSchedule' | 'end'>,
): boolean {
    if (reservationItem.deviceInstanceSchedule === undefined) {
        return true;
    }

    const lastDay = LuxonDateTime.fromISO(reservationItem.end);
    let foundLastDay: LuxonDateTime | undefined;

    for (const schedule of reservationItem.deviceInstanceSchedule) {
        if (!schedule.end) continue;

        const tmpLastDay = LuxonDateTime.fromISO(schedule.end);
        if (!foundLastDay || (tmpLastDay && tmpLastDay.toMillis() > foundLastDay.toMillis())) {
            foundLastDay = tmpLastDay;
        }
    }

    return !foundLastDay || !isLuxonDateSameDayOrAfter(foundLastDay, lastDay);
}
