import Stack from '@mui/material/Stack';

import { Button, Icon, Text } from 'app/components';

import { useI18n } from 'i18n';

import { filtersButtonTestID } from './constants';
import useFilters from './useFilters';

const FilterButton = ({ filterKey, onClick }) => {
    const { tags } = useFilters(filterKey);
    const isActive = tags.length > 0;

    const { t } = useI18n();

    return (
        <Button
            aria-haspopup="dialog"
            ctaType="secondary"
            icon={<Icon name="filter" />}
            onClick={onClick}
            data-testid={filtersButtonTestID}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Text variant="inherit">{t('filters.filter')}</Text>

                {isActive && <Icon name="checkmark" />}
            </Stack>
        </Button>
    );
};

export default FilterButton;
