import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Link, Text } from 'app/components/primitives';

import { useI18n } from 'i18n';

interface Props {
    /**
     * Severity represents a grouping of faults by some criteria
     */
    severity: 'critical' | 'info';

    /**
     * The fault codes to display
     */
    faultCodes?: string[];

    /**
     * Where to link to, to lead the user to more information on the faults surfaced in the list. If omitted no
     * linking behavior is present
     */
    faultsHREF?: string;
}

/**
 * Responsible for laying out a specific grouping of faults by the given severity
 */
export default function ActiveFaultsBySeverity({ severity, faultCodes, faultsHREF }: Props): ReactElement {
    const { t } = useI18n();
    const ListTitle = faultsHREF ? Link : Box;

    if ((faultCodes?.length ?? 0) === 0) return <></>;

    return (
        <Box>
            <Box>
                <ListTitle color="inherit" to={faultsHREF}>
                    <Text variant="detail" fontWeight="bold">
                        {t('admin_unit_index_page.table.faults_count_tooltip', {
                            severity: t(`fault_severity.${severity}`),
                            count: faultCodes?.length,
                        })}
                    </Text>
                </ListTitle>
            </Box>

            <List disablePadding>
                {faultCodes?.map((code: string) => (
                    <ListItem key={code} disableGutters disablePadding>
                        <Text as="div" variant="detail">
                            {t(`device_fault.${code.toLowerCase()}.name`)}
                        </Text>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}
