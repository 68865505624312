import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabOption, toTabOption } from './ReservationTabs';

interface Output {
    /**
     * The currently active tab
     */
    activeTab: TabOption;

    /**
     * Will update the active tab and sync the query param
     */
    updateActiveTab: (event: { value: TabOption }) => void;
}

/**
 * Responsible for managing activeTab state and syncing to the url via search params
 */
export default function useSyncTabToUrl(): Output {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabQueryParam = searchParams.get('tab');
    const [activeTab, setActiveTab] = useState<TabOption>(toTabOption(tabQueryParam));

    useEffect(() => {
        setActiveTab(toTabOption(tabQueryParam, activeTab));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabQueryParam]);

    function updateActiveTab({ value }) {
        setSearchParams({ tab: value });
        setActiveTab(value);
    }

    return {
        activeTab,
        updateActiveTab,
    };
}
