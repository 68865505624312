import mp75ProductImage from 'app/assets/images/mp-75-1704x1052.png';

export default {
    image: mp75ProductImage,

    modelNumber: 'MP-75',

    // Runtime calculation
    // = availableEnergy / chargePower
    dcChargeTime: {
        // ~40kW charger
        value: 13,
        unit: 'hours',
        isApproximate: true,

        chargePower: {
            value: 40,
            unit: 'kW',
            isApproximate: true,
        },
    },

    availableEnergy: {
        value: 530,
        unit: 'kWh',
    },

    efficiency: {
        value: 0.92,
        unit: '%',
    },

    // Runtime calculation
    // = availableEnergy * efficiency / averageLoad
    exampleRuntimes: [
        {
            value: 12,
            unit: 'hours',
            isApproximate: true,
            averageLoad: {
                value: 40,
                unit: 'kVA',
            },
        },
        {
            value: 24,
            unit: 'hours',
            isApproximate: true,
            averageLoad: {
                value: 20,
                unit: 'kVA',
            },
        },
        {
            value: 48,
            unit: 'hours',
            isApproximate: true,
            averageLoad: {
                value: 10,
                unit: 'kVA',
            },
        },
    ],

    configurations: [
        {
            voltage: {
                value: 480,
                unit: 'V',
                phase: 3,
            },

            voltageTolerance: {
                minValue: 432,
                maxValue: 528,
                unit: 'Vac',
            },

            maxPhaseCurrent: {
                value: 100,
                unit: 'A',
            },
            maxNeutralCurrent: {
                value: 100,
                unit: 'A',
            },
            powerFactor: {
                value: 1.0,
            },

            continuousPower: {
                value: 40,
                unit: 'kW',
            },
            maxPower: {
                value: 75,
                unit: 'kW',
            },
        },
        {
            voltage: {
                value: 208,
                unit: 'V',
                phase: 3,
            },

            voltageTolerance: {
                minValue: 187,
                maxValue: 228.8,
                unit: 'Vac',
            },

            maxPhaseCurrent: {
                value: 100,
                unit: 'A',
            },
            maxNeutralCurrent: {
                value: 100,
                unit: 'A',
            },
            powerFactor: {
                value: 1.0,
            },

            continuousPower: {
                value: 35,
                unit: 'kW',
            },
            maxPower: {
                value: 35,
                unit: 'kW',
            },
        },
        {
            voltage: {
                value: 240,
                unit: 'V',
                phase: 1,
            },

            voltageTolerance: {
                minValue: 216,
                maxValue: 264,
                unit: 'Vac',
            },

            maxPhaseCurrent: {
                value: 100,
                unit: 'A',
            },
            maxNeutralCurrent: {
                value: 100,
                unit: 'A',
            },
            powerFactor: {
                value: 1.0,
            },

            continuousPower: {
                value: 24,
                unit: 'kW',
            },
            maxPower: {
                value: 24,
                unit: 'kW',
            },
        },
    ],

    dieselGeneratorComp: {
        value: 75,
        unit: 'kVA',
    },
    frequency: {
        value: 60,
        unit: 'hz',
    },

    unitDimensions: {
        length: { value: 103, unit: '"' },
        width: { value: 38, unit: '"' },
        height: { value: 62, unit: '"' },
        isApproximate: true,
    },
    outsideDimensions: {
        length: { value: 162.9, unit: '"' },
        width: { value: 69, unit: '"' },
        height: { value: 79, unit: '"' },
        isApproximate: true,
    },

    totalWeight: {
        value: 12000,
        unit: 'pounds',
        isApproximate: true,
    },
};
