import { useMemo, useState } from 'react';
import { DateTime as LuxonDateTime } from 'luxon';

import { ValueOptions } from '@mui/x-data-grid';

import { useListAllAccountsLazyQuery } from 'generated/graphql';

export default function useAccountFilterOptions() {
    const [fetchTime, setFetchTime] = useState(LuxonDateTime.now());
    const [listAllAccounts, { data }] = useListAllAccountsLazyQuery();

    const accountValueOptions: ValueOptions[] = useMemo(() => {
        const accountMapBase: { [key: string]: string } = {};
        return Object.entries(
            (data?.listAccounts.edges ?? []).reduce((acc, { node }) => {
                if (node.name) {
                    acc[node.id] = node.name;
                }
                return acc;
            }, accountMapBase),
        ).map(([id, name]) => ({ value: id, label: name }));
    }, [data?.listAccounts.edges]);

    async function fetchAccountValueOptions() {
        // refresh the data if older than 1 minute
        const isOutdated = Math.abs(fetchTime.diffNow().as('seconds')) > 60;

        if (accountValueOptions.length === 0 || isOutdated) {
            await listAllAccounts();
            setFetchTime(LuxonDateTime.now());
        }
    }

    return { fetchAccountValueOptions, accountValueOptions };
}
