import { useState } from 'react';

import { SxProps } from '@mui/material';

import Toast from 'app/components/compounds/Toast';
import { useSendAccountInviteTo } from 'app/components/widgets/AccountInviteForm';

import { useI18n } from 'i18n';

import TeamMembers from './TeamMembers';
import useAccountTeam from './useAccountTeam';

interface Props {
    sx?: SxProps;
    accountID: string;
}

export default function StandaloneAccountTeamMembers({ accountID, sx }: Props) {
    const { t } = useI18n();
    const { members } = useAccountTeam({ accountID });
    const sendInviteTo = useSendAccountInviteTo();
    const [isResendInviteErrorToastOpen, setIsResendInviteErrorToastOpen] = useState<boolean>(false);
    const [isResendInviteSuccessToastOpen, setIsResendInviteSuccessToastOpen] = useState<boolean>(false);

    return (
        <>
            <TeamMembers
                sx={sx}
                members={members}
                onInvite={async ({ email }) => {
                    try {
                        await sendInviteTo({ email, accountID });
                        setIsResendInviteSuccessToastOpen(true);
                    } catch (error) {
                        setIsResendInviteErrorToastOpen(true);
                    }
                }}
            />

            <Toast
                type="error"
                message={t('account_settings.error.retry_invite_failed')}
                isOpen={isResendInviteErrorToastOpen}
                onClose={() => setIsResendInviteErrorToastOpen(false)}
            />

            <Toast
                type="success"
                message={t('account_settings.retry_invite_succeeded')}
                isOpen={isResendInviteSuccessToastOpen}
                onClose={() => setIsResendInviteSuccessToastOpen(false)}
            />
        </>
    );
}
