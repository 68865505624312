import { ComponentProps, ReactElement } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import useAccounts from './useAccounts';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {}

/**
 * Provides ability to select multiple accounts
 */
export default function MultiServiceAreaSelector({ value, onChange, ...props }: Props): ReactElement {
    const { loading, accounts } = useAccounts();
    const options = accounts
        .map(x => ({ id: x?.id, label: x?.name ?? x?.id }))
        .sort((a, b) => a.label.localeCompare(b.label));
    return (
        <MultiSelect
            {...props}
            loading={loading}
            disabled={!props?.readOnly && !!props?.disabled}
            value={value}
            options={options}
            onChange={onChange}
        />
    );
}
