import { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Grid from '@mui/material/Grid';

import { Button, DomainInput, Input } from 'app/components';

import { useI18n } from 'i18n';

interface FormFields {
    name: string;
    industry: string;
    restrictedUserDomains: string[];
}
interface Props {
    id?: string;

    hideSubmitCTA?: boolean;

    onFormStateChange?: (event: { shouldDisableCTA: boolean }) => void;

    onSubmit: (values: FormFields) => Promise<{ id: string; name: string } | null>;
}

/**
 * Responsible for presenting the create account form and collecting user input
 */
export default function CreateAccountForm({
    id,
    hideSubmitCTA = false,
    onFormStateChange,
    onSubmit,
}: Props): ReactElement {
    const { t } = useI18n();
    const tDialogNS = 'admin_account_index_page.create_account_dialog';

    const [shouldShowDomainError, setShouldShowDomainError] = useState<boolean>(false);
    const [pendingRestrictedDomainError, setPendingRestrictedDomainError] = useState<string>('');
    const [pendingRestrictedDomain, setPendingRestrictedDomain] = useState<string>('');

    function hasErrors(errors) {
        return (errors ?? []).length > 0;
    }

    function resolvePendingDomainErrorMessage(errors) {
        const validationErrors = errors ?? [];

        const errorMessage = validationErrors.includes('DOMAIN_ALREADY_INCLUDED')
            ? t('domain_input.validation_hint.DOMAIN_ALREADY_INCLUDED')
            : t('domain_input.validation_hint.GENERAL');

        return hasErrors(errors) ? errorMessage : '';
    }

    const formik = useFormik<FormFields>({
        enableReinitialize: true,
        initialValues: {
            name: '',
            industry: '',
            restrictedUserDomains: [],
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: object().shape({
            name: string().required(t(`${tDialogNS}.name_validation_hint`)),
            industry: string().optional(),
            restrictedUserDomains: array().of(string().domain()).optional(),
        }),
        onSubmit,
    });

    const { isValid, dirty, errors, touched, values } = formik;
    const { name, industry, restrictedUserDomains } = values;

    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({ shouldDisableCTA });
    }, [onFormStateChange, shouldDisableCTA]);

    return (
        <Grid
            id={id}
            container
            component="form"
            spacing={4}
            rowSpacing={6}
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
        >
            <Grid item xs={12} md={6}>
                <Input
                    fullWidth
                    required
                    fieldName={t(`${tDialogNS}.name_field_label`)}
                    placeholder={t(`${tDialogNS}.name_field_placeholder`)}
                    name="name"
                    value={name}
                    maxLength={50}
                    error={touched.name && !!errors.name}
                    caption={touched.name && errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Input
                    fullWidth
                    fieldName={t(`${tDialogNS}.industry_field_label`)}
                    placeholder={t(`${tDialogNS}.industry_field_placeholder`)}
                    name="industry"
                    value={industry}
                    maxLength={50}
                    onChange={formik.handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <DomainInput
                    fieldName={t(`${tDialogNS}.restricted_domains_label`)}
                    placeholder={t(`${tDialogNS}.restricted_domains_placeholder`)}
                    caption={
                        shouldShowDomainError
                            ? pendingRestrictedDomainError
                            : restrictedUserDomains.length > 0
                            ? ''
                            : t(`${tDialogNS}.restricted_domain_explainer`)
                    }
                    domainsLabel={t(`${tDialogNS}.staged_restricted_domains_label`)}
                    name="pendingRestrictedDomain"
                    value={pendingRestrictedDomain}
                    domains={restrictedUserDomains}
                    error={shouldShowDomainError && !!pendingRestrictedDomainError}
                    onChange={event => {
                        setPendingRestrictedDomain(event.value);
                    }}
                    onAdd={event => {
                        formik.setFieldValue('restrictedUserDomains', event.value);

                        setPendingRestrictedDomainError(resolvePendingDomainErrorMessage(event.errors));
                        setShouldShowDomainError(hasErrors(event.errors));
                    }}
                    onRemove={event => {
                        formik.setFieldValue('restrictedUserDomains', event.value);
                    }}
                    onValidationError={event => {
                        setPendingRestrictedDomainError(resolvePendingDomainErrorMessage(event.errors));
                    }}
                />
            </Grid>

            {!hideSubmitCTA && (
                <Grid item xs={12}>
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_account_index_page.cta.create_account')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
