export default {
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                ...theme.typography.h4,
                textTransform: 'capitalize',
            }),
        },
    },
};
