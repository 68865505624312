import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import Box from '@mui/material/Box';

import { Button, DomainInput } from 'app/components';

import { useI18n } from 'i18n';

import { FormStateChange } from '../../types';

interface UpdateAccountInput {
    id: string;
    restrictedUserDomains: string[];
}

interface EditAccountDomainsFormProps {
    /**
     * The unique ID for the account
     */
    accountID: string;

    /**
     * The unique ID for the form
     */
    formID: string;

    /**
     * Whether the form is readOnly or not
     */
    readOnly?: boolean;

    /**
     * Whether to hide the submit button if being provided elsewhere outside the form
     */
    hideSubmitCTA?: boolean;

    /**
     * Whether to reset the form when going from edit mode to readOnly
     */
    enableResetOnModeChange?: boolean;

    /**
     * Event handler called when the form state changes
     */
    onFormStateChange?: (event: FormStateChange<UpdateAccountInput>) => void;

    /**
     * Event handler called when the user initiates the form submission
     */
    onSubmit: (input: { value: UpdateAccountInput }) => Promise<void>;

    restrictedUserDomains?: string[];
}

export default function EditAccountDomainsForm({
    accountID,
    formID,
    restrictedUserDomains = [],

    readOnly = false,
    hideSubmitCTA = false,
    enableResetOnModeChange = true,

    onFormStateChange,
    onSubmit,
}: EditAccountDomainsFormProps) {
    const [candidateDomain, setCandidateDomain] = useState('');
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [hasError, setHasError] = useState(false);

    const { t } = useI18n();

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            restrictedUserDomains,
        },
        validateOnMount: false,
        validateOnChange: true,
        validationSchema: object().shape({
            restrictedUserDomains: array().of(string()),
        }),
        onSubmit: async values => {
            await onSubmit({ value: { id: accountID, restrictedUserDomains: values.restrictedUserDomains } });
            form.resetForm();
        },
    });

    const { isValid, dirty, values } = form;
    const shouldDisableCTA = !isValid || !dirty;

    useEffect(() => {
        onFormStateChange?.({
            shouldDisableCTA,
            isDirty: dirty,
            isValid,
            values: { id: accountID, ...values },
        });
    }, [shouldDisableCTA, dirty, onFormStateChange, isValid, values, accountID]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resetForm = useRef(form.resetForm.bind(form));

    useEffect(() => {
        if (enableResetOnModeChange && readOnly) {
            resetForm.current();
        }
    }, [readOnly, resetForm, enableResetOnModeChange]);

    const caption = useMemo(() => {
        if (hasError) {
            return t(`domain_input.validation_hint.${validationErrors[0]}`);
        }

        return undefined;
    }, [hasError, t, validationErrors]);

    return (
        <form id={formID} onSubmit={form.handleSubmit}>
            <Box maxWidth="sm">
                <DomainInput
                    name="restrictedUserDomains"
                    fieldName={t('admin_account_page.domains.label.restricted_domains_input')}
                    caption={caption}
                    value={candidateDomain}
                    domains={values.restrictedUserDomains}
                    domainsLabel={t('admin_account_page.domains.label.domain_tags_label')}
                    onChange={({ value }) => {
                        setHasError(false);
                        setCandidateDomain(value);
                    }}
                    onAdd={({ name, value }) => name && form.setFieldValue(name, value)}
                    onRemove={({ name, value }) => name && form.setFieldValue(name, value)}
                    onValidationError={({ errors }) => errors && setValidationErrors(errors)}
                    error={hasError}
                    onBlur={() => {
                        setHasError(candidateDomain.length > 0 && validationErrors.length > 0);
                    }}
                    readOnly={readOnly}
                    required
                />
            </Box>

            {!hideSubmitCTA && (
                <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" disabled={shouldDisableCTA}>
                        {t('admin_account_page.cta.save_changes')}
                    </Button>
                </Box>
            )}
        </form>
    );
}
