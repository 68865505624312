import { FocusEventHandler, ReactElement, ReactNode, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { MUIMarginProps } from 'styles/theme/types';

import FieldCaption from '../../primitives/FieldCaption';
import FieldLabel from '../../primitives/FieldLabel';
import Checkbox from '../../primitives/interactive/Checkbox';

interface Props extends MUIMarginProps {
    variant?: 'inline' | 'compact';

    /**
     * The form control label
     */
    fieldName?: ReactNode;

    /**
     * Additional helper text or inline errors
     */
    caption?: ReactNode;

    /**
     * The general name describing what the value represents
     */
    name?: string;

    /**
     * The selected value
     */
    value?: string[];

    /**
     * Whether the roles field is required
     */
    required?: boolean;

    /**
     * Whether to disable the control
     */
    disabled?: boolean;

    /**
     * The role options to render out
     */
    options?: string[];

    /**
     * Whether to apply the error treatment (i.e. when a validation error is present)
     */
    error?: boolean;

    /**
     * Event handler to be called when the value changes
     */
    onChange?: (event: { name?: string; value: string[]; added?: string; removed?: string }) => void;

    /**
     * Event handler to be called when control is blurred.
     */
    onBlur?: FocusEventHandler<HTMLElement>;
}

/**
 * Responsible for layout option selection inline
 */
export default function InlineRoleAssignment({
    fieldName,
    caption,
    name,
    value,
    error,
    disabled,
    required,
    options = [],
    onChange,
    onBlur,
}: Props): ReactElement {
    const adaptChangeEvent = useCallback(
        ({ target: { name: roleName, checked } }) => {
            const newValue = (checked ? value?.concat(roleName) : value?.filter(x => x !== roleName)) ?? [];

            onChange?.({
                name,
                value: newValue,
                added: checked ? roleName : undefined,
                removed: !checked ? roleName : undefined,
            });
        },
        [onChange, name, value],
    );

    return (
        <Box name={name} disabled={disabled} component="fieldset" sx={{ border: 'none', p: 0 }} onBlur={onBlur}>
            <FieldLabel as="legend" fieldName={fieldName} required={required} />

            <Stack ml={1} spacing={2}>
                {options.map(role => (
                    <Checkbox
                        key={role}
                        name={role}
                        label={role}
                        disabled={disabled}
                        checked={value?.includes(role) ?? false}
                        onChange={adaptChangeEvent}
                    />
                ))}
            </Stack>

            <FieldCaption caption={caption} error={error} />
        </Box>
    );
}
