import { ComponentProps, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

interface Props extends ComponentProps<typeof Stack> {
    /**
     * Slot in layout to render the run mode indicator
     */
    runMode: ReactElement;

    /**
     * Slot in layout to render the voltage selection indicator
     */
    voltageSelection: ReactElement;

    /**
     * Slot in layout to render the TGU connection signal strength indicator
     */
    tguConnection: ReactElement;

    /**
     * Slot in layout to render the charging status indicator
     */
    chargingStatus: ReactElement;

    /**
     * Slot in layout to render the power in stats
     */
    powerIn: ReactElement;

    /**
     * Slot in layout to render the power out stats
     */
    powerOut: ReactElement;

    /**
     * Slot in layout to render the state of charge stats
     */
    stateOfCharge: ReactElement;

    /**
     * Slot in layout to render the available energy / state of energy stats
     */
    stateOfEnergy: ReactElement;

    /**
     * Slot in layout to render the netPower stats
     */
    netPower: ReactElement;

    /**
     * Slot in layout to render the line load charts
     */
    lineLoads: ReactElement;
}

/**
 * Responsible for defining the layout for the System status section of the reservation monitoring page
 */
export default function Layout({
    runMode,
    voltageSelection,
    tguConnection,
    chargingStatus,
    powerIn,
    powerOut,
    stateOfCharge,
    stateOfEnergy,
    netPower,
    lineLoads,
    ...props
}: Props): ReactElement {
    return (
        <Stack {...props} spacing={5}>
            <Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={3}>
                        {runMode}
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        {voltageSelection}
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        {tguConnection}
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        {chargingStatus}
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        {stateOfCharge}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        {stateOfEnergy}
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={4}>
                        {powerIn}
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        {powerOut}
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                        {netPower}
                    </Grid>
                </Grid>
            </Box>

            <Box>{lineLoads}</Box>
        </Stack>
    );
}
