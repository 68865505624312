import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { GridSortModel } from '@mui/x-data-grid';

import { Button, ZeroState } from 'app/components';
import { useSession } from 'app/core/Session';

import { FilterMode } from 'generated/graphql';

import { useI18n } from 'i18n';

import { ReservationTable } from './ReservationTable';
import { ReservationTabs } from './ReservationTabs';
import useReservations from './useReservations';

function getSortModelForFilterMode(filterMode): GridSortModel {
    switch (filterMode) {
        case FilterMode.Requested:
            return [{ field: 'requestDate', sort: 'asc' }];
        case FilterMode.Confirmed:
            return [{ field: 'start', sort: 'asc' }];
        case FilterMode.Active:
            return [{ field: 'end', sort: 'asc' }];
        case FilterMode.All:
            return [{ field: 'requestDate', sort: 'desc' }];
        case FilterMode.Past:
            return [{ field: 'end', sort: 'desc' }];
    }

    return [];
}

function parseFilterMode(string?: string | null): FilterMode {
    const s = (string ?? '').toLowerCase();

    switch (s) {
        case 'requested':
            return FilterMode.Requested;
        case 'confirmed':
            return FilterMode.Confirmed;
        case 'active':
            return FilterMode.Active;
        case 'past':
            return FilterMode.Past;
        case 'all':
            return FilterMode.All;
    }

    return FilterMode.Requested;
}

export default function ReservationsTablePage() {
    const { onLastUpdateTimestampChange, lastRequestedReservationID } = useOutletContext<any>() ?? {};
    const [searchParams, setSearchParams] = useSearchParams();

    const { primaryAccountID } = useSession().getSession();

    const [filterMode, setFilterMode] = useState<FilterMode>(parseFilterMode(searchParams.get('filter')));
    const [sortModel, setSortModel] = useState<GridSortModel>(getSortModelForFilterMode(filterMode));

    const { data, countsByStatus, loading, lastUpdateTimestamp, error, refetch } = useReservations({
        filterMode,
        fulfillerAccountID: primaryAccountID,
    });

    useEffect(() => {
        onLastUpdateTimestampChange({ value: lastUpdateTimestamp });
    }, [onLastUpdateTimestampChange, lastUpdateTimestamp]);

    useEffect(() => {
        refetch();
    }, [lastRequestedReservationID, refetch]);

    const { t } = useI18n();

    function onFilterReservation(filterMode: FilterMode) {
        setFilterMode(filterMode);
        setSortModel(getSortModelForFilterMode(filterMode));
        setSearchParams({ filter: filterMode });
    }

    return (
        <Box>
            {!!error && (
                <Grid container my={6}>
                    <Grid item xs={0} md={2} />

                    <Grid item xs={12} md={8}>
                        <ZeroState
                            title={t('admin_reservation_index_page.zero_state.fetch_error.title')}
                            message={t('admin_reservation_index_page.zero_state.fetch_error.message')}
                            cta={<Button onClick={() => refetch()}>{t('cta.retry')}</Button>}
                        />
                    </Grid>
                </Grid>
            )}

            {!error && (
                <Stack spacing={4}>
                    <ReservationTabs
                        onFilter={onFilterReservation}
                        filterMode={filterMode}
                        requestedCount={countsByStatus.requested}
                        confirmedCount={countsByStatus.confirmed}
                        startedCount={countsByStatus.started}
                    />

                    <Paper sx={{ overflowX: 'auto', position: 'relative' }} elevation={0}>
                        <ReservationTable
                            hideStatus={!/^(all|past)$/i.test(filterMode)}
                            reservations={data}
                            loading={loading}
                            sortModel={sortModel}
                            onSortModelChange={model => setSortModel(model)}
                        />
                    </Paper>
                </Stack>
            )}
        </Box>
    );
}
