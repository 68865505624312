import { ComponentProps, ReactElement, useCallback, useState } from 'react';
/**
 * Note(Derek): Authors of react-router-dom do not recommend using `useBlocker` but there are no
 * equivalent options provided. Generally it works fairly well but we'll likely need to
 * document caveats as we continue to use it or modify our designs to persist changes locally
 * which is the recommended path forward.
 */
import { useParams } from 'react-router-dom';

import { Button, ConfirmDialog, ModalDialog } from 'app/components';
import { useLayer } from 'app/core/layers';

import { useI18n } from 'i18n';

import useUnsavedChangesBlocker from '../useUnsavedChangesBlocker';
import BasicInfoSection from './BasicInfoSection';
import { UserPage } from './presentation';
import RolesSection from './RolesSection';
import { useActivateUser, useDeactivateUser } from './useActivateUser';
import useUpdateRoles from './useUpdateRoles';
import useUpdateUserDetails from './useUpdateUserDetails';
import useUserDetails from './useUserDetails';

type Params = {
    userID: string;
};

/**
 * Responsible for coordinating all page level actions and state for dealing with individual user administration
 */
export function UserPageContainer(): ReactElement {
    const { userID = '' } = useParams<Params>();

    const { t } = useI18n();
    const { toast } = useLayer();

    function notifyUserOfResult(
        asyncFunc,
        { successMessage, errorMessage }: { successMessage?: string; errorMessage?: string } = {},
    ) {
        return async function x(...args) {
            try {
                await asyncFunc(...args);

                if (successMessage) {
                    toast.add({ severity: 'success', message: successMessage });
                }
            } catch (error: unknown) {
                setIsConfirmActivateUserDialogOpen(false);

                if (errorMessage) {
                    toast.add({ severity: 'error', message: errorMessage });
                }

                throw error;
            }
        };
    }

    // dialogs
    const [isConfirmDeactivateUserDialogOpen, setIsConfirmDeactivateUserDialogOpen] = useState(false);
    const [isConfirmActivateUserDialogOpen, setIsConfirmActivateUserDialogOpen] = useState(false);

    const { data: user, loading: isUserLoading, error: userError } = useUserDetails({ id: userID });

    const activateUser = notifyUserOfResult(useActivateUser(userID), {
        successMessage: t('admin_user_page.activate_user_success'),
        errorMessage: t('admin_user_page.activate_user_failed'),
    });
    const deactivateUser = notifyUserOfResult(useDeactivateUser(userID), {
        successMessage: t('admin_user_page.deactivate_user_success'),
        errorMessage: t('admin_user_page.deactivate_user_failed'),
    });
    const updateUserDetails = notifyUserOfResult(useUpdateUserDetails(), {
        successMessage: t('admin_user_page.save_user_success'),
        errorMessage: t('admin_user_page.save_user_failed'),
    });
    // TODO:(sam) delete once roles can be passed to updateUserMutation()
    const updateRoles = notifyUserOfResult(useUpdateRoles(), {
        errorMessage: t('admin_user_page.save_user_roles_failed'),
    });

    const saveUserDetailsAndRoles: ComponentProps<typeof RolesSection>['onSubmit'] = useCallback(
        async ({ value }) => {
            await Promise.all([
                updateUserDetails({
                    current: { id: user.id, accountID: user.accountID },
                    updates: { id: value.id, accountID: value.accountID },
                }),
                updateRoles({ id: user.id, currentRoles: user.roles, newRoles: value.roles }),
            ]);
        },
        [updateUserDetails, updateRoles, user],
    );

    const saveBasicUserInfo: ComponentProps<typeof BasicInfoSection>['onSubmit'] = useCallback(
        async ({ value }) => {
            await updateUserDetails({
                current: { id: user.id, name: user.name, email: user.email, phoneNumber: user.phoneNumber },
                updates: { id: value.id, name: value.name, email: value.email, phoneNumber: value.phoneNumber },
            });
        },
        [updateUserDetails, user],
    );

    const { remindOfUnsavedChanges, stashChangesFor, stayOnPage, proceedWithoutSaving, saveAndProceed } =
        useUnsavedChangesBlocker();

    return (
        <>
            <UserPage
                isLoading={isUserLoading}
                error={!!userError}
                user={user}
                userInfoSection={
                    <BasicInfoSection
                        userID={user?.id}
                        name={user?.name}
                        email={user?.email}
                        phoneNumber={user?.phoneNumber}
                        onFormStateChange={stashChangesFor<Parameters<typeof saveBasicUserInfo>['0']['value']>(
                            'basic-info',
                            saveBasicUserInfo,
                        )}
                        onSubmit={saveBasicUserInfo}
                    />
                }
                rolesSection={
                    <RolesSection
                        userID={user?.id}
                        accountID={user?.accountID}
                        roles={user?.roles}
                        onFormStateChange={stashChangesFor<Parameters<typeof saveUserDetailsAndRoles>['0']['value']>(
                            'roles',
                            saveUserDetailsAndRoles,
                        )}
                        onSubmit={saveUserDetailsAndRoles}
                    />
                }
                onActivateUser={() => setIsConfirmActivateUserDialogOpen(true)}
                onDeactivateUser={() => setIsConfirmDeactivateUserDialogOpen(true)}
            />

            <ConfirmDialog
                isOpen={isConfirmDeactivateUserDialogOpen}
                onClose={() => setIsConfirmDeactivateUserDialogOpen(false)}
                title={t('admin_user_page.confirm_deactivate_user_title')}
                message={t('admin_user_page.confirm_deactivate_user_message')}
                confirmCta={<Button onClick={deactivateUser}>{t('admin_user_page.cta.deactivate_user')}</Button>}
            />

            <ConfirmDialog
                isOpen={isConfirmActivateUserDialogOpen}
                onClose={() => setIsConfirmActivateUserDialogOpen(false)}
                title={t('admin_user_page.confirm_activate_user_title')}
                message={t('admin_user_page.confirm_activate_user_message')}
                confirmCta={<Button onClick={activateUser}>{t('admin_user_page.cta.activate_user')}</Button>}
            />

            <ModalDialog
                open={remindOfUnsavedChanges}
                onClose={stayOnPage}
                title={t('admin_user_page.unsaved_changes_dialog_title')}
                tertiaryCTA={<Button onClick={proceedWithoutSaving}>{t('admin_user_page.cta.do_not_save')}</Button>}
                primaryCTA={<Button onClick={saveAndProceed}>{t('cta.save')}</Button>}
            >
                {t('admin_user_page.unsaved_changes_dialog_message')}
            </ModalDialog>
        </>
    );
}
