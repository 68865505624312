import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { LoadingOverlay } from 'app/components/primitives';
import { ISO8601 } from 'app/core/types';

import { useI18n } from 'i18n';

import ComposedChart from './ComposedChart';
import useHistoricalFaults from './useHistoricalFaults';

interface Props {
    /**
     * The unique identifier for an MPU
     */
    mpuID?: string;

    /**
     * The start of the interval to review historical faults
     */
    start?: ISO8601 | null;

    /**
     * The end of the interval to review historical faults
     */
    end?: ISO8601 | null;
}

export default function HistoricalFaults({ mpuID, start, end }: Props): ReactElement {
    const { t } = useI18n();
    const { loading, data } = useHistoricalFaults({
        mpuID,
        start,
        end,
        t,
    });

    return (
        <Box sx={{ position: 'relative', overflowX: 'auto' }}>
            <ComposedChart data={data} start={start} end={end} />

            <LoadingOverlay loading={loading} />
        </Box>
    );
}
