import { AnyAction } from 'typescript-fsa';

import { collapseRow, endBarHover, expandRow, startBarHover, toggleRowExpansion } from './actions';
import { ScheduleEvent, ScheduleOptions, ScheduleState } from './types';

function reduceCollapseRowPayload(state: ScheduleState, payload: number): ScheduleState {
    return {
        ...state,
        expandedRowIndexes: state.expandedRowIndexes.filter(index => index !== payload),
    };
}

function reduceExpandRowPayload(state: ScheduleState, payload: number): ScheduleState {
    return {
        ...state,
        expandedRowIndexes: [...state.expandedRowIndexes, payload],
    };
}

export function scheduleReducer(state: ScheduleState, action: AnyAction): ScheduleState {
    if (collapseRow.match(action)) {
        const { rowIndex } = action.payload;

        return reduceCollapseRowPayload(state, rowIndex);
    }
    if (expandRow.match(action)) {
        const { rowIndex } = action.payload;

        return reduceExpandRowPayload(state, rowIndex);
    }
    if (toggleRowExpansion.match(action)) {
        const { rowIndex } = action.payload;

        if (state.expandedRowIndexes.includes(rowIndex)) {
            return reduceCollapseRowPayload(state, rowIndex);
        } else {
            return reduceExpandRowPayload(state, rowIndex);
        }
    }
    if (startBarHover.match(action)) {
        const { eventId } = action.payload;

        return { ...state, hoverTargetId: eventId };
    }
    if (endBarHover.match(action)) {
        return { ...state, hoverTargetId: undefined };
    }

    return state;
}

export function createDefaultScheduleState<E extends ScheduleEvent>(options: ScheduleOptions<E>): ScheduleState {
    return {
        expandedRowIndexes: options.defaultExpandedRows || [],
    };
}
