import { cloneElement, ReactElement } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Icon from 'app/components/primitives/Icon';
import MoxLogo from 'app/components/primitives/MoxLogo';

import CSSDimension from 'design-system/CSSDimension';

export const TOP_NAV_HEIGHT = CSSDimension.fromPixels(80).as('rem');

interface Props {
    /**
     * slot to render the controls for opening the primary nav
     */
    openMenuCTA: ReactElement;

    /**
     * Nav item for rendering alert-related info
     */
    alertsItem?: ReactElement;

    /**
     * Nav item for rendering settings-related info
     */
    settings: ReactElement;

    /**
     * Width accomodations for the primary nav (desktop only)
     */
    widthAdjustment: string | number | null;
}

function resolveCSSLength(x?: string | number | null) {
    if (!x) return '0px';

    if (typeof x === 'string') return x;

    return `${x}px`;
}

/**
 * Top Nav is responsible for providing a space to dock persitent controls for accessing
 * user settings (and future notifications) as well as the controls for opening the mobile menu
 */
export default function TopNav({ openMenuCTA, alertsItem, settings, widthAdjustment }: Props): ReactElement {
    return (
        <>
            <Box
                position="fixed"
                width={{ xs: '100%', md: `calc(100% - ${resolveCSSLength(widthAdjustment)})` }}
                sx={theme => ({ zIndex: theme.zIndex.appBar, backgroundColor: 'background.default' })}
            >
                <Container maxWidth="xxl">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        minHeight={TOP_NAV_HEIGHT}
                        sx={{ py: 3 }}
                        spacing={4}
                    >
                        <Box flex={{ xs: 1, md: 'auto' }} width={{ xs: 'auto', md: '100%' }}>
                            <Box display={{ xs: 'flex', md: 'none' }}>
                                {cloneElement(openMenuCTA, {}, <Icon name="menu" />)}
                            </Box>

                            <Stack
                                id="page-header"
                                display={{ xs: 'none', lg: 'flex' }}
                                width="100%"
                                flexDirection="column"
                            />
                        </Box>

                        <Box
                            flex={{ xs: 1, md: 'auto' }}
                            display={{ xs: 'flex', md: 'none' }}
                            justifyContent={{ xs: 'center', md: undefined }}
                        >
                            <MoxLogo type="h4" color1="text.primary" color2="text.primary" />
                        </Box>

                        <Box
                            flex={{ xs: 1, md: 'auto' }}
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignContent="center"
                        >
                            <Box>{alertsItem}</Box>
                            <Box ml={2}>{settings}</Box>
                        </Box>
                    </Stack>
                </Container>

                <Divider />
            </Box>

            {/* Spacer to maintain proper spacing to push content down below the top nav */}
            <Box visibility="hidden" minHeight={TOP_NAV_HEIGHT} />
        </>
    );
}
