import { DEFAULT_TIME_ZONE } from 'app/core/date-time';
import { IANATimeZone } from 'app/core/types';

interface BusinessHoursConfig {
    /**
     * The inclusive closing hour for selecting a time
     */
    closingHour: number;
    /**
     * The inclusive opening hour for selecting a time
     */
    openingHour: number;
    /**
     * The time zone to use for the business hours
     */
    timeZone: IANATimeZone;
}

export default class BusinessHours {
    private openingHour: number;
    private closingHour: number;

    public timeZone: IANATimeZone;

    /**
     * The hour slots available for selection
     */
    public hourSlots: number[];

    constructor({
        openingHour = 6,
        closingHour = 18,
        timeZone = DEFAULT_TIME_ZONE,
    }: Partial<BusinessHoursConfig> = {}) {
        this.openingHour = openingHour;
        this.closingHour = closingHour;
        this.timeZone = timeZone;

        this.hourSlots = this.makeHourSlots();
    }

    private makeHourSlots() {
        let hourOptions: number[] = [];

        for (let i = this.openingHour; i <= this.closingHour; i += 1) {
            hourOptions.push(i);
        }

        return hourOptions;
    }

    public isOpen(hour) {
        return hour >= this.openingHour && hour <= this.closingHour;
    }
}
