import { ComponentProps, ReactElement } from 'react';

import MultiSelect from 'app/components/primitives/interactive/MultiSelect';

import useNetsuiteCustomers from './useNetsuiteCustomers';

interface Props extends Omit<ComponentProps<typeof MultiSelect>, 'options'> {}

/**
 * Responsible for rending a form control with allows users to select from a list of known accounts
 */
export default function NetsuiteCustomerSelector({
    value,
    disabled,
    readOnly,
    required = false,
    ...props
}: Props): ReactElement {
    const { loading, netsuiteCustomersByID } = useNetsuiteCustomers();
    const options: { label: string; id: string }[] = Object.entries(netsuiteCustomersByID)
        .map(([id, customer]) => ({
            id,
            label: `${customer.name} (${customer.id})`,
        }))
        .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));

    return (
        <MultiSelect
            {...props}
            required={required}
            loading={loading}
            disabled={!readOnly && !!disabled}
            readOnly={readOnly}
            value={value}
            options={options}
        />
    );
}
