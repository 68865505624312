import { DateTime } from 'luxon';

import { MetricName } from 'app/core/data/deviceTelemetry';
import { resolveDateTime } from 'app/core/date-time';
import { ISO8601 } from 'app/core/types';

import {
    GetDeviceTelemetryQueryVariables,
    GetReservationItemTelemetryQueryHookResult,
    GetReservationItemTelemetryQueryVariables,
    Telemetry,
    useGetDeviceTelemetryLazyQuery,
    useGetDeviceTelemetryQuery,
    useGetReservationItemTelemetryLazyQuery,
    useGetReservationItemTelemetryQuery,
} from 'generated/graphql';

import resolveGroupBy from './resolveGroupBy';
import useNowPoller from './useNowPoller';

interface Input {
    /**
     * The reservation item's id to pull telemetry for
     */
    unitID?: string | undefined;

    /**
     * The device instance id to pull telemetry for
     */
    mpuID?: string | undefined;

    /**
     * Which telemetry to pull
     */
    metricNames: MetricName[];

    /**
     * Whether to enable polling for updated unit status
     */
    enablePolling?: boolean;

    /**
     * How frequently to check for updated status
     */
    pollInterval?: number;

    /**
     * When to pull telemetry from
     */
    start?: DateTime | null;

    /**
     * The end of the interval to pull telemetry from, if not present or end is in the future now will be used
     */
    end?: DateTime | null;

    /**
     * Whether to skip the operation or not
     */
    skip?: boolean;
}

interface Output extends Pick<GetReservationItemTelemetryQueryHookResult, 'loading' | 'error'> {
    data: Telemetry[];
}

/**
 * Responsible for fetching telemetry for a device either by mpuID or as part of a reservation via
 * unitID. Only one ID must be provided.
 */
export default function useTelemetryData({
    metricNames,
    unitID,
    mpuID,
    enablePolling,
    pollInterval,
    start,
    end,
    skip,
}: Input): Output {
    const now = useNowPoller({ enablePolling, pollInterval });

    const _start = start || now.minus({ hour: 1 });
    const _end = end || now;
    const groupBy = resolveGroupBy({ start: _start, end: _end });

    const {
        loading: reservationTelemetryLoading,
        error: reservationTelemetryError,
        data: reservationTelemetryData,
    } = useGetReservationItemTelemetryQuery({
        skip: skip || !!mpuID || !unitID,
        errorPolicy: 'all',
        variables: {
            input: {
                metricNames,
                reservationItemID: unitID ?? '',
                groupBy,
                start: _start.toISO() ?? '',
                end: _end.toISO() ?? '',
            },
        },
    });

    const {
        loading: deviceTelemetryLoading,
        error: deviceTelemetryError,
        data: deviceTelemetryData,
    } = useGetDeviceTelemetryQuery({
        skip: skip || !!unitID || !mpuID,
        errorPolicy: 'all',
        variables: {
            input: {
                metricNames,
                deviceInstanceID: mpuID ?? '',
                groupBy,
                start: _start.toISO() ?? '',
                end: _end.toISO() ?? '',
            },
        },
    });

    const data = reservationTelemetryData?.reservationTelemetry || deviceTelemetryData?.deviceTelemetry;
    const error = reservationTelemetryError || deviceTelemetryError;

    return {
        loading: reservationTelemetryLoading || deviceTelemetryLoading,
        error: !!data && !!error ? undefined : error,
        data: data?.telemetries ?? [],
    };
}

/**
 * Responsible for retrieving telemetry data for an MPU or a Unit associated with a reservation
 * on demand
 */
export function useTelemetryDataLazy() {
    const [fetchUnitTelemetry] = useGetReservationItemTelemetryLazyQuery();
    const [fetchMPUTelemetry] = useGetDeviceTelemetryLazyQuery();

    async function _fetchUnitTelemetry(
        input: Omit<GetReservationItemTelemetryQueryVariables['input'], 'reservationItemID'> & { id: string },
    ): Promise<{ data: Telemetry[] }> {
        const { id, ...rest } = input;

        const { data } = await fetchUnitTelemetry({
            fetchPolicy: 'cache-first',
            variables: {
                input: {
                    ...rest,
                    reservationItemID: id,
                },
            },
        });

        return { data: data?.reservationTelemetry?.telemetries ?? [] };
    }

    async function _fetchMPUTelemetry(
        input: Omit<GetDeviceTelemetryQueryVariables['input'], 'deviceInstanceID'> & { id: string },
    ): Promise<{ data: Telemetry[] }> {
        const { id, ...rest } = input;

        const { data } = await fetchMPUTelemetry({
            fetchPolicy: 'cache-first',
            variables: {
                input: {
                    ...rest,
                    deviceInstanceID: id,
                },
            },
        });

        return { data: data?.deviceTelemetry?.telemetries ?? [] };
    }

    return async function fetchTelemetryData({
        metricNames,
        unitID,
        mpuID,
        start,
        end,
    }: {
        metricNames: MetricName[];
        unitID?: string;
        mpuID?: string;
        start?: DateTime | ISO8601;
        end?: DateTime | ISO8601;
    }): Promise<{ data: Telemetry[] }> {
        const now = DateTime.now();

        const _start = start ? resolveDateTime(start) : now.minus({ hour: 1 });
        const _end = end ? resolveDateTime(end) : now;
        const groupBy = resolveGroupBy({ start: _start, end: _end });

        if (unitID) {
            const { data } = await _fetchUnitTelemetry({
                metricNames,
                id: unitID,
                groupBy,
                start: _start.toISO() ?? '',
                end: _end.toISO() ?? '',
            });

            return { data };
        }

        if (mpuID) {
            const { data } = await _fetchMPUTelemetry({
                metricNames,
                id: mpuID,
                groupBy,
                start: _start.toISO() ?? '',
                end: _end.toISO() ?? '',
            });

            return { data };
        }

        return { data: [] };
    };
}
